import {memo, useEffect, useRef, useState} from 'react';
import styled, {CSSProperties} from 'styled-components';
import {capitalize} from '../../../utils/capitalize';
import {getColorFromTheme} from '../../ui-utils';

const Wrapper = styled.div<{background?: string}>`
  position: relative;
  display: inline-flex;
  .container .tooltip {
    visibility: hidden;
    width: auto;
    background-color: ${({background}) =>
      `${background ? background : 'rgba(0, 0, 0, 0.7)'}`};
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;

    bottom: 20px;
    right: -45px;
  }
  .container:hover .tooltip {
    visibility: visible;
    opacity: 1;
    z-index: 999999;
  }

  .container .tooltip::after {
    content: ' ';
    position: absolute;
    top: 99%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
const TooltipIconBlackWrapper = styled.div<{background?: string}>`
  position: relative;
  display: inline-flex;
  .container .tooltip {
    visibility: hidden;
    width: auto;
    background-color: ${({background}) =>
      `${background ? background : '#1E1E2F'}`};
    font-size: 12px;
    color: #fff;
    padding: 12px;
    border-radius: 6px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    bottom: 38px;
    right: -45px;
  }
  .container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .container .tooltip::after {
    content: ' ';
    position: absolute;
    top: 99%;
    left: 50%;
    border-left: 8px;
    border-right: 8px;
    border-top: 16px;
    margin-left: -5px;
    border-style: solid;
    border-color: #1e1e2f transparent transparent transparent;
    transition: opacity 0.3s;
  }
`;

export const QuestionIconWrapper = styled.button<{tooltip?: boolean}>`
  margin-left: 8px;
  outline: none;
  position: relative;
  // z-index: 99999999;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${getColorFromTheme('purple300')};
    margin-bottom: -2px;
  }
  @media (max-width: 500px) {
    display: none;
  }
  .tooltip {
    visibility: hidden;
    width: 399px;
    background-color: #fafafa;
    font-size: 14px;
    color: #5f5f8c;
    text-align: center;
    padding: 16px 16px 16px 16px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    // z-index: 99999999;
    left: 0;
    margin-top: -100px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip ? 'visible' : '')};
    opacity: 1;
    position: absolute;
    bottom: 150%;
    left: -245%;
    max-width: 300px;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 11.5%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: #e4e5fb transparent transparent transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 11.7%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #fafafa transparent transparent transparent;
  }
`;

export const HeaderTooltip = styled.button<{tooltip?: boolean}>`
  outline: none;
  position: relative;
  z-index: 2;
  margin-left: 100%;

  @media (max-width: 500px) {
    display: none;
  }
  .tooltip {
    visibility: ${(props: any) =>
      props.tooltip === true ? 'visible' : 'hidden'};
    width: 399px;
    background-color: #fafafa;
    font-size: 14px;
    color: #5f5f8c;
    text-align: center;
    padding: 20px 10px 20px 10px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    position: absolute;
    bottom: 30px;
    right: -55%;
    max-width: 400px;
    opacity: 1;
    transition: opacity 0.3s;
    z-index: 4;
  }

  .tooltip::before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 11.5%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent #e4e5fb transparent;
  }

  .tooltip::after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 11.8%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fafafa transparent;
  }
`;

export interface TooltipProps {
  BodyTextNodeType?: any;
  maxLength?: number | undefined;
  text: string;
  tooltipBody?: any;
  withEllipsis?: boolean;
  noCapitalize?: boolean;
  bodyStyle?: any;
  tooltipStyle?: (width?: any) => CSSProperties;
  bodyTextNodeKind?: string;
}

export const Tooltip = memo(function ({
  BodyTextNodeType,
  bodyTextNodeKind,
  maxLength,
  text,
  tooltipBody,
  withEllipsis,
  noCapitalize,
  bodyStyle,
  tooltipStyle,
}: TooltipProps) {
  const capit = noCapitalize ? String : capitalize;

  const tooltipRef = useRef(null);

  const [tooltipWidth, setTooltipWidth] = useState();

  useEffect(() => {
    if (tooltipRef && tooltipRef?.current) {
      setTooltipWidth((tooltipRef as any)?.current?.offsetWidth);
    }
  }, []);

  const TextNode = BodyTextNodeType ? BodyTextNodeType : 'span';

  return (
    <Wrapper>
      <div className="container">
        <TextNode style={bodyStyle} kind={bodyTextNodeKind}>
          {maxLength &&
            text?.length > maxLength &&
            `${capit(text.substr(0, maxLength))} ${withEllipsis ? ' ...' : ''}`}
          {(!maxLength || (maxLength && text?.length <= maxLength)) &&
            capit(text)}
        </TextNode>
        {maxLength && text && text?.length > maxLength && (
          <span
            className="tooltip"
            style={tooltipStyle ? tooltipStyle(tooltipWidth) : undefined}
            ref={tooltipRef}>
            {tooltipBody ? tooltipBody : text}
          </span>
        )}
        {!maxLength && (
          <span
            className="tooltip"
            style={
              tooltipStyle
                ? tooltipStyle((tooltipRef as any)?.current?.clientWidth)
                : undefined
            }>
            {tooltipBody ? tooltipBody : text}
          </span>
        )}
      </div>
    </Wrapper>
  );
});

export interface TooltipIconBlackProps {
  Icon: any;
  optionalStyles?: any;
  parentStyles?: any;
  tooltipBody?: any;
  noCapitalize?: boolean;
  tooltipWidthExtendedStyle?: (
    width: number,
    optionalStyles?: CSSProperties,
  ) => CSSProperties;
}

export const TooltipIconBlack = memo(function ({
  optionalStyles,
  parentStyles,
  Icon,
  tooltipBody,
  tooltipWidthExtendedStyle,
}: TooltipIconBlackProps) {
  const tooltipIconRef = useRef(null);
  const [tooltipWidth, setTooltipWidth] = useState<any>();

  useEffect(() => {
    if (tooltipIconRef && tooltipIconRef?.current) {
      setTooltipWidth((tooltipIconRef as any)?.current?.offsetWidth);
    }
  }, []);
  return (
    <TooltipIconBlackWrapper style={parentStyles}>
      <div className="container">
        <span>
          <Icon />
        </span>

        <div
          className="tooltip"
          ref={tooltipIconRef}
          style={
            tooltipWidthExtendedStyle
              ? tooltipWidthExtendedStyle(tooltipWidth, optionalStyles)
              : optionalStyles
          }>
          {tooltipBody}
        </div>
      </div>
    </TooltipIconBlackWrapper>
  );
});

export interface TooltipIconProps {
  background?: string;
  Icon: any;
  text: string;
}
export const TooltipIcon = memo(function ({Icon, text}: TooltipIconProps) {
  const [isTooltipFilter, setIsTooltipFilter] = useState(false);
  return (
    <QuestionIconWrapper
      onMouseEnter={() => setIsTooltipFilter(isTooltipFilter ? false : true)}
      onMouseLeave={() => setIsTooltipFilter(false)}
      tooltip={isTooltipFilter}>
      <Icon />
      <span className="tooltip">{text}</span>
    </QuestionIconWrapper>
  );
});
