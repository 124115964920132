import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeCardElementOptions,
  StripeElements,
} from '@stripe/stripe-js/types/stripe-js';
import {observer} from 'mobx-react';
import {useMemo, useState} from 'react';
import {Controller} from 'react-hook-form';
import {parseCurrencyValue} from '../../../../../../../../../constants';
import {useStoreContext} from '../../../../../../../../../store/store-context';
import {Button} from '../../../../../../../../../ui/atoms/button';
import {Helper} from '../../../../../../../../../ui/atoms/helper';
import dayjs from 'dayjs';
import {ThickCheckIcon} from '../../../../../../../../../ui/atoms/icons/check';
import {FlexRowSpaceBetween, FlexRowEnd, FlexRow} from '@ui/style/styles';
import {NoBorderButton} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/styles';
import {Label} from '../../../../../../../../../ui/atoms/label';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../../../../../ui/atoms/spacer';
import {
  Body1,
  Body2,
  CTA,
  Headline2,
  Headline3,
  Headline4,
} from '../../../../../../../../../ui/atoms/typography';
import {SecondaryBreadcrumb} from '../../../../../../../../../ui/molecules/breadcrumb';
import {RadioField} from '../../../../../../../../../ui/molecules/field/radiofield';
import {TextField} from '../../../../../../../../../ui/molecules/field/textfield';
import {SelectField} from '../../../../../../../../../ui/molecules/select/selectfield';
import {useNavigate} from 'react-router';
import {useBreakpoints} from '@utils/use-breakpoints';
import {useSearchParams} from 'react-router-dom';
import {useBillingHook} from '../../../admin-billing.hooks';
import {
  BillingCycleInnerWrapper,
  BillingCycleOuterWrapper,
  CardInfoDiv,
  MultiRadioSelectDiv,
  PriceBoxDiv,
  ResponsiveFlexRowBetween,
  PriceHelperInfoDiv,
} from '../billing-not-active.styles';
import {ICity, ICountry, IState} from 'country-state-city/dist/lib/interface';
import {CancelIcon} from '../../../../../../../../../ui/atoms/icons';
import {CardDetailsDiv} from '../../billing-active/billing-active.styles';
import {capitalize} from '@utils';
import {Link} from '@ui/atoms/link';
import {UpdateCard} from '../../billing-active/components/update-card';
import {useBillingCycleState} from './billing-cycle-state-hook';
import {calculateBillingPrices} from '@utils/billing';

interface IInjectedBillingCycle {
  stripe: Stripe | null;
  elements: StripeElements | null;
}
export const CARD_OPTIONS: StripeCardElementOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      // iconColor: '#F79E1B',
      color: '#5F5F8C',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#5F5F8C',
      },
      '::placeholder': {
        color: '#5F5F8C',
      },
      backgroundColor: '#ffffff',
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

export const InjectedBillingCycle = observer(
  ({stripe, elements}: IInjectedBillingCycle) => {
    const {
      billingRoutesStore: {activateInactiveBillingTabs},
      billingsStore: {
        selectedBillingPlan,
        setSelectedBillingPlan,
        updateShowNonActiveBilling,
      },
    } = useStoreContext();

    const [searchParams] = useSearchParams();

    const {
      handleSubmitCard,
      errorsCard,
      controlCard,
      isSubmittingCard,
      handleFormValueChangeCard,
      stripeError,
      setStripeError,
      setCardComplete,
      updateAddressDetails,
      addressDetails,
      handleSubmitPaymentMethod,
      validateCoupon,
      couponDiscount,
      setCouponDiscount,
      setValidCoupon,
    } = useBillingHook();

    const {
      countries,
      setSelectedCountry,
      states,

      setSelectedState,
      cities,
      addressSaved,
      setAddressSaved,
      line1,
      setLine1,
      openDrawer,
      setOpenDrawer,
      coupon,
      setCoupon,
    } = useBillingCycleState();

    const {billingsStore} = useStoreContext();

    const billingPlan = searchParams.get('billingPlan');

    const chosenBillingPlanType =
      billingPlan || billingsStore.chosenBillingPlanType;

    const isLifeTimePlan = chosenBillingPlanType === 'lifetime';
    const isFreeTierSubscription = searchParams.get('free-trial');

    const navigate = useNavigate();

    const currencySymbol = parseCurrencyValue(
      (billingsStore.billingPlans as any)?.[chosenBillingPlanType]?.currency,
    );

    const numSeats = isLifeTimePlan ? 500 : billingsStore.numSeats || 1;

    const calculatePrices = () => {
      if (billingsStore.billingPlans)
        return calculateBillingPrices({
          billingPlans: billingsStore.billingPlans,
          chosenBillingPlanType,
          selectedBillingCycle: billingsStore.selectedBillingPlan,
          numSeats,
          couponDiscount: couponDiscount,
        });

      return {
        subtotal: 0,
        couponDiscountAmount: 0,
        dueToday: 0,
      };
    };

    const {
      subtotal = 0,
      couponDiscountAmount = 0,
      dueToday = 0,
    } = calculatePrices();

    const handleAddressSave = () => {
      setAddressSaved(true);
    };

    const handleSubmit = handleSubmitCard(
      handleSubmitPaymentMethod(
        stripe,
        elements,
        chosenBillingPlanType,
        numSeats,
        billingsStore.billingMethods?.data?.length > 0,
      ),
    );

    const planName = (plan: string) => {
      switch (plan) {
        case 'growth':
          return 'Standard';

        default:
          return 'Lifetime';
      }
    };

    const breadcrumbNav = useMemo(() => {
      const nav = [
        {
          title: 'Choose a plan',
          href: '#',
          id: '1',
          onClick: () => {
            navigate('/workspace-settings/billing');

            updateShowNonActiveBilling(true);
          },
        },
        {
          title: planName(chosenBillingPlanType),
          href: '#',
          id: '2',
          isCurrent: true,
          onClick: () => {
            activateInactiveBillingTabs('billingCycle');
          },
        },
      ];
      return nav;
    }, [
      chosenBillingPlanType,
      navigate,
      updateShowNonActiveBilling,
      activateInactiveBillingTabs,
    ]);

    const disableSavedButton =
      !addressDetails.country || !line1 || !addressDetails.state;

    const [numSeatsFormIsVisible] = useState<boolean>(false);

    const formatMoney = (money: number) => {
      return ((money * 100) / 100).toFixed(2).toLocaleString();
    };

    const disabledPaymentButton = !stripe;

    const {isXs} = useBreakpoints();

    return (
      <BillingCycleOuterWrapper onSubmit={handleSubmit}>
        <div>
          <SecondaryBreadcrumb
            wrapperStyles={{
              padding: isXs ? '16px ' : '20px 32px 16px 200px',
              marginBottom: 0,
            }}
            navigation={breadcrumbNav}
          />
        </div>
        <BillingCycleInnerWrapper>
          <div>
            <Headline3 kind="textDark">Select billing cycle</Headline3>
            <VerticalSpacer size="8px" />
            <Body1 kind="textBody">
              Choose how you want to make your payment
            </Body1>
            <VerticalSpacer size="24px" />
            {isLifeTimePlan ? (
              <div className="flex flex-row justify-between items-center border border-borderDark rounded-[10px] p-4 mb-4">
                <FlexRow>
                  <Body2 weight={'semibold'} kind={'textDark'}>
                    Lifetime{' '}
                  </Body2>{' '}
                  <CTA style={{fontSize: 12}} kind="purple300" className="ml-1">
                    (save 75%)
                  </CTA>
                </FlexRow>
                <RadioField id="lifetime" checked />{' '}
              </div>
            ) : (
              <MultiRadioSelectDiv>
                <div className="firstDiv">
                  <Body2
                    weight={
                      selectedBillingPlan === 'monthly' ? 'semibold' : 'regular'
                    }
                    kind={
                      selectedBillingPlan === 'monthly'
                        ? 'textDark'
                        : 'textBody'
                    }>
                    {/* Monthly - {currencySymbol}
                  {monthlyUnitCost} */}
                    Pay monthly
                  </Body2>{' '}
                  <RadioField
                    id="monthly"
                    checked={selectedBillingPlan === 'monthly'}
                    onChange={() => setSelectedBillingPlan('monthly')}
                  />{' '}
                </div>
                <div className="secondDiv">
                  <div className="flex">
                    <Body2
                      weight={
                        selectedBillingPlan === 'yearly'
                          ? 'semibold'
                          : 'regular'
                      }
                      kind={
                        selectedBillingPlan === 'yearly'
                          ? 'textDark'
                          : 'textBody'
                      }>
                      Pay annually
                    </Body2>{' '}
                    <CTA
                      style={{fontSize: 12}}
                      kind="purple300"
                      className="ml-1">
                      (save 25%)
                    </CTA>
                  </div>
                  <div>
                    {' '}
                    <RadioField
                      id="annually"
                      checked={selectedBillingPlan === 'yearly'}
                      onChange={() => setSelectedBillingPlan('yearly')}
                    />
                  </div>
                </div>
              </MultiRadioSelectDiv>
            )}

            {!isFreeTierSubscription && !isLifeTimePlan && (
              <>
                <VerticalSpacer size="21px" />
                <Label>Discount code</Label>
                <VerticalSpacer size="8px" />
                <TextField
                  placeholder="Enter code here"
                  type="text"
                  name="discount_code"
                  actionStyle={{width: 60}}
                  value={coupon}
                  onChange={(e) => {
                    setCoupon(e.target.value);
                  }}
                  action={(ref) => (
                    <>
                      {coupon.length > 0 && couponDiscount > 0 && (
                        <span
                          ref={ref}
                          onClick={() => {
                            setCoupon('');
                            setCouponDiscount(0);
                            setValidCoupon('');
                          }}
                          style={{cursor: 'pointer'}}>
                          <CancelIcon />
                        </span>
                      )}
                      {coupon.length > 0 && couponDiscount === 0 && (
                        <Button
                          kind="primary"
                          width="xm"
                          onClick={() => validateCoupon(coupon)}
                          isLoading={
                            billingsStore.loader?.validateCoupon as boolean
                          }
                          type="button">
                          Apply
                        </Button>
                      )}
                    </>
                  )}
                />
              </>
            )}
            {billingsStore.billingMethods?.data?.length > 0 ? (
              <>
                <CardDetailsDiv>
                  <div className="title">
                    <Headline4 kind="textDark">Payment method</Headline4>
                  </div>
                  <div className="table-body">
                    <div className="header">
                      <Body2 kind="textBody">Card</Body2>
                      <Body2 kind="textBody">Expiry </Body2>
                      <Body2 kind="textBody">Actions </Body2>
                    </div>
                    {billingsStore.billingMethods?.data?.map(
                      (billingMethod: any) => (
                        <div className="content" key={billingMethod?.id}>
                          <Body2 kind="textBody">
                            {capitalize(billingMethod?.card?.brand)} ending in{' '}
                            {billingMethod?.card?.last4}
                          </Body2>
                          <Body2 kind="textBody">
                            {dayjs(
                              `2000-${billingMethod?.card?.exp_month}-01`,
                            ).format('MMMM')}
                            , {billingMethod?.card?.exp_year}
                          </Body2>
                          <Link
                            kind="blue"
                            style={{
                              color: '#585ADF',
                              textDecoration: 'none',
                              fontWeight: 500,
                            }}
                            onClick={() => setOpenDrawer(true)}
                            to="#">
                            Update Card
                          </Link>
                        </div>
                      ),
                    )}
                  </div>
                </CardDetailsDiv>
              </>
            ) : (
              <>
                <VerticalSpacer size="32px" />
                <FlexRowSpaceBetween>
                  <Headline3 kind="textDark">
                    Enter billing information
                  </Headline3>
                  {addressSaved && (
                    <NoBorderButton onClick={() => setAddressSaved(false)}>
                      <Body2 weight="bold" kind="purple300">
                        Edit
                      </Body2>
                    </NoBorderButton>
                  )}
                </FlexRowSpaceBetween>
                {!addressSaved && (
                  <>
                    <VerticalSpacer size="8px" />
                    <Body1 kind="textBody">
                      Used to calculate tax and will appear on billing
                      documents.
                    </Body1>
                  </>
                )}
                <VerticalSpacer size="24px" />
                <div className="address">
                  {!addressSaved ? (
                    <>
                      <Label>Address</Label>
                      <VerticalSpacer size="8px" />
                      <Controller
                        control={controlCard}
                        name="billing_details.address.line1"
                        defaultValue={line1}
                        render={({onBlur, value}) => {
                          return <></>;
                        }}
                      />
                      <TextField
                        placeholder="Enter here"
                        type="text"
                        value={line1}
                        inputStyle={{borderRadius: '10px'}}
                        onChange={(event) => {
                          setLine1(event.target.value);

                          handleFormValueChangeCard(
                            event.target.value,
                            'billing_details.address.line1',
                          );
                        }}
                        helper={
                          errorsCard.billing_details?.address?.line1?.message ||
                          ''
                        }
                        state={
                          errorsCard.billing_details?.address?.line1
                            ? 'error'
                            : 'default'
                        }
                      />
                      <Label>Select country</Label>
                      <VerticalSpacer size="8px" />
                      <Controller
                        control={controlCard}
                        name="billing_details.address.country"
                        render={({onBlur, value}) => {
                          return (
                            <SelectField
                              borderRadius="10px"
                              onBlur={onBlur}
                              name="billing_details.address.country"
                              defaultValue={addressDetails.country}
                              helper={
                                errorsCard.billing_details?.address?.country
                                  ?.message || ''
                              }
                              state={
                                errorsCard.billing_details?.address?.country
                                  ? 'error'
                                  : 'default'
                              }
                              onChange={(data: {
                                value: string;
                                isoCode: string;
                              }) => {
                                if (data?.value) {
                                  handleFormValueChangeCard(
                                    data?.value,
                                    'billing_details.address.country',
                                  );
                                  setSelectedCountry(data.isoCode);
                                  updateAddressDetails('country', data?.value);
                                } else {
                                  handleFormValueChangeCard(
                                    null,
                                    'billing_details.address.country',
                                  );
                                  updateAddressDetails('country', null);
                                }
                              }}
                              placeholder="Select / search a country"
                              options={countries?.map((country: ICountry) => ({
                                value: country?.isoCode,
                                label: country?.name,
                                isoCode: country?.isoCode,
                              }))}
                            />
                          );
                        }}
                      />

                      <ResponsiveFlexRowBetween>
                        <div className="first-div">
                          <Label>State / Province / Region</Label>
                          <VerticalSpacer size="8px" />
                          <Controller
                            control={controlCard}
                            name="billing_details.address.state"
                            render={({onBlur, value}) => {
                              return (
                                <SelectField
                                  onBlur={onBlur}
                                  name="billing_details.address.state"
                                  value={value}
                                  borderRadius="10px"
                                  defaultValue={addressDetails.state}
                                  helper={
                                    errorsCard.billing_details?.address?.state
                                      ?.message || ''
                                  }
                                  state={
                                    errorsCard.billing_details?.address?.state
                                      ? 'error'
                                      : 'default'
                                  }
                                  onChange={(data: {
                                    value: string;
                                    isoCode: string;
                                  }) => {
                                    if (data?.value) {
                                      handleFormValueChangeCard(
                                        data?.value,
                                        'billing_details.address.state',
                                      );
                                      setSelectedState(data.isoCode);
                                      updateAddressDetails(
                                        'state',
                                        data?.value,
                                      );
                                    } else {
                                      handleFormValueChangeCard(
                                        null,
                                        'billing_details.address.state',
                                      );
                                      updateAddressDetails('state', null);
                                    }
                                  }}
                                  placeholder="Select / search a state"
                                  options={states?.map((state: IState) => ({
                                    label: state.name,
                                    value: state.name,
                                    isoCode: state.isoCode,
                                  }))}
                                />
                              );
                            }}
                          />
                        </div>
                        <div className="s-div">
                          <Label>Select city / Town / Village</Label>
                          <VerticalSpacer size="8px" />
                          <Controller
                            control={controlCard}
                            name="billing_details.address.city"
                            render={({onBlur, value}) => {
                              return (
                                <SelectField
                                  onBlur={onBlur}
                                  borderRadius="10px"
                                  name="billing_details.address.city"
                                  value={value}
                                  defaultValue={addressDetails.city}
                                  helper={
                                    errorsCard.billing_details?.address?.city
                                      ?.message || ''
                                  }
                                  state={
                                    errorsCard.billing_details?.address?.city
                                      ? 'error'
                                      : 'default'
                                  }
                                  onChange={(data: {value: string}) => {
                                    handleFormValueChangeCard(
                                      data?.value,
                                      'billing_details.address.city',
                                    );
                                    updateAddressDetails('city', data?.value);
                                  }}
                                  placeholder="Select / search a city"
                                  options={cities?.map((city: ICity) => ({
                                    label: city.name,
                                    value: city.name,
                                  }))}
                                />
                              );
                            }}
                          />
                        </div>
                      </ResponsiveFlexRowBetween>
                      <VerticalSpacer size="16px" />
                      <FlexRowEnd>
                        <Button
                          style={{padding: '8px 16px'}}
                          // width="full"
                          disabled={disableSavedButton}
                          onClick={() => {
                            handleAddressSave();
                          }}>
                          Save
                        </Button>
                      </FlexRowEnd>
                    </>
                  ) : (
                    <>
                      <Body2
                        weight="bold"
                        style={{textTransform: 'capitalize'}}>
                        {line1}, {addressDetails.city}, {addressDetails.state},{' '}
                        {addressDetails.country}
                      </Body2>
                    </>
                  )}
                </div>

                <VerticalSpacer size="32px" />
                <Headline3 kind="textDark">Enter card details</Headline3>
                <VerticalSpacer size="16px" />
                <CardInfoDiv>
                  <TextField
                    placeholder="Name on Card"
                    // defaultValue=""
                    type="text"
                    // ref={register}
                    // helper={errors.name?.message || ''}
                    // state={!!errors.name ? 'error' : 'default'}
                    name="name"
                  />
                  <div className="stripeCard">
                    <CardElement
                      options={CARD_OPTIONS}
                      onChange={(e) => {
                        setStripeError(e.error?.message);
                        setCardComplete(e.complete);
                      }}
                    />
                  </div>
                  <Helper
                    aria-label="helper"
                    state={stripeError ? 'error' : 'default'}
                    children={stripeError || ''}
                  />
                </CardInfoDiv>
              </>
            )}

            <VerticalSpacer size="54px" />

            <Button
              width="full"
              type={isSubmittingCard ? 'button' : 'submit'}
              onClick={isSubmittingCard ? () => false : undefined}
              disabled={
                billingsStore.billingMethods?.data?.length > 0
                  ? false
                  : disabledPaymentButton ||
                    !addressSaved ||
                    numSeatsFormIsVisible
              }
              isLoading={isSubmittingCard}
              style={{cursor: isSubmittingCard ? 'not-allowed' : undefined}}>
              {isFreeTierSubscription ? 'Start free trial' : 'Complete payment'}
            </Button>

            <VerticalSpacer size="24px" />
          </div>
          <div>
            <PriceBoxDiv>
              <div className="top">
                <Body1 weight="bold" kind="green400" style={{fontSize: 12}}>
                  {capitalize(planName(chosenBillingPlanType))} Plan
                </Body1>
                <VerticalSpacer size="16px" />
                <FlexRowSpaceBetween>
                  <Body2>
                    Includes <span className="font-semibold">{numSeats}</span>{' '}
                    seat(s) -{' '}
                    {!isLifeTimePlan && (
                      <span
                        className="text-[#585ADF] font-semibold cursor-pointer"
                        onClick={() => navigate('/workspace-settings/members')}>
                        Manage
                      </span>
                    )}
                  </Body2>

                  <Body2 weight="bold" className="mt-2">
                    {currencySymbol} {formatMoney(subtotal)} USD
                  </Body2>
                </FlexRowSpaceBetween>
                <Body2 className="text-[10px]" kind="textBody">
                  {currencySymbol}
                  {subtotal / (numSeats || 1)} USD each
                </Body2>
              </div>
              <div className="bottom">
                {!isLifeTimePlan && (
                  <div className="items">
                    <Body2 kind="textBody">Discount</Body2>
                    <CTA kind="textDark">
                      -{currencySymbol}
                      {couponDiscount !== 0
                        ? formatMoney(couponDiscountAmount)
                        : 0}{' '}
                      USD
                    </CTA>
                  </div>
                )}

                <div className="items">
                  <Body2 kind="textBody" weight="semibold">
                    Sub total
                  </Body2>
                  <CTA kind="textDark">
                    {currencySymbol}
                    {formatMoney(subtotal - couponDiscountAmount)}
                  </CTA>
                </div>
                <div className="items">
                  <Body2 kind="textBody">Tax</Body2>
                  <CTA kind="textDark">No tax</CTA>
                </div>

                {/* <div className="hr" /> */}
                <div className="items border-t border-t-[#EDEDF2] pt-2">
                  <Body2 kind="textBody">Due Today</Body2>
                  <Headline2 kind="textDark">
                    {currencySymbol}{' '}
                    {isFreeTierSubscription ? 0 : formatMoney(dueToday)} USD
                  </Headline2>
                </div>
              </div>
            </PriceBoxDiv>
            <VerticalSpacer size="16px" />
            <PriceHelperInfoDiv>
              {isFreeTierSubscription && (
                <>
                  <div className="info">
                    <span>
                      <ThickCheckIcon style={{stroke: '#585ADF'}} />
                    </span>
                    <HorizontalSpacer size="5px" />
                    <Body2 kind="textBody">
                      There is a{' '}
                      <span className="price">{currencySymbol}1 </span>
                      card validation fee, but rest assured, it will be
                      refunded.
                    </Body2>
                  </div>
                  <VerticalSpacer size="16px" />

                  <div className="info">
                    <span>
                      <ThickCheckIcon style={{stroke: '#585ADF'}} />
                    </span>
                    <HorizontalSpacer size="5px" />
                    <Body2 kind="textBody">
                      Starting {dayjs().add(14, 'days').format('MMMM DD')}, you
                      plan will begin and you will be charged{' '}
                      <span className="price">
                        {currencySymbol}
                        {formatMoney(dueToday)}
                      </span>{' '}
                      /{selectedBillingPlan} until when cancelled.
                    </Body2>
                  </div>
                  <VerticalSpacer size="16px" />

                  <div className="info">
                    <span>
                      <ThickCheckIcon style={{stroke: '#585ADF'}} />
                    </span>
                    <HorizontalSpacer size="5px" />
                    <Body2 kind="textBody">
                      You will be reminded 7 days before your trial ends.
                    </Body2>
                  </div>
                </>
              )}

              <UpdateCard
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                stripe={stripe}
                elements={elements}
              />

              {!isFreeTierSubscription && (
                <div className="info">
                  <span>
                    <ThickCheckIcon style={{stroke: '#585ADF'}} />
                  </span>
                  <HorizontalSpacer size="5px" />
                  <Body2 kind="textBody">
                    Your card would be charged{' '}
                    <span className="price">
                      {currencySymbol}
                      {formatMoney(dueToday)}
                    </span>{' '}
                    now and each{' '}
                    {selectedBillingPlan === 'monthly' ? ' month' : ' year'}{' '}
                    after.
                  </Body2>
                </div>
              )}

              <VerticalSpacer size="16px" />
              <div className="info">
                <span>
                  <ThickCheckIcon style={{stroke: '#585ADF'}} />
                </span>
                <HorizontalSpacer size="5px" />
                <Body2 kind="textBody">
                  If a new member is added you will be automatically charged for
                  the user and your monthly billing will adjusted for the new
                  payment date.
                </Body2>
              </div>
              <VerticalSpacer size="16px" />
              <div className="info">
                <span>
                  <ThickCheckIcon style={{stroke: '#585ADF'}} />
                </span>
                <HorizontalSpacer size="5px" />
                <Body2 kind="textBody">
                  Your account will be instantly updated. You can upgrade,
                  downgrade or cancel anytime.
                </Body2>
              </div>
            </PriceHelperInfoDiv>
            <VerticalSpacer size="16px" />
          </div>
        </BillingCycleInnerWrapper>
      </BillingCycleOuterWrapper>
    );
  },
);

export const BillingCycle = observer(() => {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <InjectedBillingCycle stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
});
