import {useCallback, useState} from 'react';
import {IMeetingResponse, IMeetingSummary} from '@hooks';
import {useGCalenderHook} from '@hooks/gcalender';
import {useActionItems} from '@hooks/features-action-items';
import {useStoreContext} from '@store/store-context';
import dayjs from 'dayjs';
import {MeetingController} from '../meeting-controller';
import {MeetingRequestImpl} from '../meeting.request';
import {authStore} from '@store/stores/auth-store';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {parseRecurrenceRule} from '@utils/recurrence';

interface GMeetingReponse {
  reminders: any;
  created: string;
  updated: string;
  creator: any;
  recurringEventId?: string;
  organizer: any;
  attendees: {email: string; responseStatus: string}[];
  hangoutLink: string;
  id: string;
  summary: string;
  start: {dateTime: string; date?: string};
  end: {dateTime: string; date?: string};
  status: string;
  recurrence: any[];
  description: string;
}
export const useUpcomingMeetingHook = () => {
  const {
    listCalendarEvents: getEvents,
    isSigned,
    deleteEvent,
    updateEvent,
    getEvent,
    getEmail,
    getCalendarEventById,
  } = useGCalenderHook();

  const [sortByDate, setSortByDate] = useState<'today' | 'week'>('week');

  const {
    usersStore: {users},
  } = useStoreContext();

  const handleDateRange = (type: 'week' | 'today') => {
    const weekStartDate = dayjs().startOf('week');
    const weekEndDate = dayjs().endOf('week');

    if (type === 'week') {
      return {
        starts: weekStartDate.format('MM/DD/YYYY'),
        ends: weekEndDate.format('MM/DD/YYYY'),
      };
    }
    return {
      starts: dayjs().startOf('day').format('MM/DD/YYYY'),
      ends: dayjs().endOf('day').format('MM/DD/YYYY'),
    };
  };

  const [dateRange, setDateRange] = useState(handleDateRange(sortByDate));
  const [filterBy, setFilterBy] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const getMeetings = useCallback(
    async (filters: {
      startDate: string;
      endDate: string;
      user?: string;
      status?: string;
      limit?: number;
      participant?: string;
      label?: string;
      paginate?: boolean;
      excludeGoogle?: boolean;
      showCanceledMeetings?: boolean;
      showDeclinedMeetings?: boolean;
      page?: number;
    }) => {
      if (!isSigned) {
        return {
          limit: filters.limit || 10,
          results: [],
          totalPages: 0,
          totalResults: 0,
          page: filters.page || 1,
        };
      }

      try {
        const timeMin = dayjs(filters.startDate).startOf('day').toISOString();
        const timeMax = dayjs(filters.endDate).endOf('day').toISOString();
        const response = await getEvents(
          200,
          timeMin,
          timeMax,
          'primary',
          filters.showCanceledMeetings,
        );
        const connectedEmail = await getEmail();

        if (!response?.result?.items) {
          return {
            limit: filters.limit || 10,
            results: [],
            totalPages: 0,
            totalResults: 0,
            page: 1,
          };
        }

        const allUsersEmail = users.map((user) => user.email);
        const allMeetings: GMeetingReponse[] = response.result.items;

        // Process each event into our meeting format
        const meetings = await Promise.all(
          allMeetings
            .filter((event) => event.hangoutLink)
            .map(async (event) => {
              const startDateTime = dayjs(
                event.start.dateTime || event.start.date,
              );

              const endDateTime = dayjs(event.end.dateTime || event.end.date);

              // Find current user's attendance status
              const currentUserAttendance =
                event.attendees?.find(
                  (attendee) => attendee.email === connectedEmail,
                )?.responseStatus || 'needsAction';

              // Process participants
              const participants = (event.attendees || [])
                .filter((attendee) => allUsersEmail.includes(attendee.email))
                .map((attendee) =>
                  users.find((user) => user.email === attendee.email),
                )
                .filter(Boolean);

              let recurrence;

              const emailParticipants = (event.attendees || [])
                .filter((attendee) => !allUsersEmail.includes(attendee.email))
                .map((attendee) => attendee.email);

              if (event.recurrence) {
                // This is the main recurring event
                recurrence = parseRecurrenceRule(event.recurrence[0]);
              } else if (event.recurringEventId) {
                // This is an instance of a recurring event

                // Optionally fetch the main recurring event to get the pattern
                try {
                  const mainEvent = await getCalendarEventById(
                    event.recurringEventId,
                    'primary',
                  );

                  if (mainEvent?.result?.recurrence) {
                    const mainRecurrence = parseRecurrenceRule(
                      mainEvent.result.recurrence[0],
                    );
                    recurrence = {
                      ...mainRecurrence,
                      isRecurringInstance: true,
                      recurringEventId: event.recurringEventId,
                    };
                  }
                } catch (error) {
                  // Continue without the main event details if fetch fails
                  console.warn(
                    'Could not fetch main recurring event details',
                    error,
                  );
                }
              }

              // Build meeting object
              const meeting: IMeetingResponse & {
                userAttendance?: string;

                notAttending: boolean;
              } = {
                id: event.id,
                title: event.summary || '',
                startDate: startDateTime.format(),
                endDate: endDateTime.format(),
                time: event.start.dateTime
                  ? startDateTime.format('hh:mm A')
                  : '',
                endTime: event.end.dateTime
                  ? endDateTime.format('hh:mm A')
                  : '',
                isAllDay: !event.start.dateTime,
                status:
                  event.status === 'cancelled' ? 'cancelled' : 'scheduled',
                meetingLink: event.hangoutLink || '',
                type: 'team',
                calendarId: event.id,
                recurrence,
                userAttendance: currentUserAttendance,
                notAttending: currentUserAttendance === 'declined',
                participant: participants,
                extraParticipant:
                  event.attendees?.filter(
                    (attendee) => !allUsersEmail.includes(attendee.email),
                  ) || [],
                emailParticipant: emailParticipants,
                hostAgenda: [],
                participantAgenda: [],
                hostNote: '',
                participantNote: '',
                nextSteps: [],
                feedback: '',
                user: [connectedEmail, authStore.auth.user.email].includes(
                  event.creator?.email,
                )
                  ? authStore.auth.user
                  : ({
                      email: event.creator?.email || '',
                    } as any),
                shareAgenda: false,
                shareHostAgenda: false,
                shareHostNote: false,
                shareParticipantNote: false,
                shareParticipantAgenda: [],
                shareNextStep: false,
                shareNote: false,
                meta: {
                  calendarEventId: event.id,
                  recurrenceRule: event.recurrence
                    ? event.recurrence[0]
                    : undefined,
                },
                googleEvent: event,
                eventId: event.id,
                label: [],
                reasonForCancellation: event.description?.includes(
                  'Cancellation reason:',
                )
                  ? event.description.split('Cancellation reason:')[1]?.trim()
                  : undefined,
                addBeamAI: event.description?.includes('BeamAI'),
                frequency: event.recurrence ? 'recurring' : 'once',
                reminder: event.reminders?.overrides?.map(
                  (r: {minutes: number}) => r.minutes.toString(),
                ) || ['24'],
                meetingCode: '',
                workspace: '',
                createdAt: event.created || '',
                updatedAt: event.updated || '',
                createdBy: event.creator?.email || '',
                updatedBy: event.organizer?.email || '',
              };

              return meeting;
            }),
        );

        // Handle user filtering if specified
        let filteredMeetings = meetings;

        if (filters.user) {
          const userEmails = filters.user.split(',');

          filteredMeetings = meetings.filter((meeting) =>
            meeting.participant.some((participant: {email: string}) =>
              userEmails.includes(participant.email),
            ),
          );
        }

        if (!filters.showDeclinedMeetings) {
          filteredMeetings = filteredMeetings.filter(
            (meeting) => !meeting.notAttending,
          );
        }

        if (filters.showCanceledMeetings) {
        }

        // Handle pagination
        const page = filters.page || 1;
        const limit = filters.limit || 10;
        const startIndex = (page - 1) * limit;
        const endIndex = startIndex + limit;
        const paginatedMeetings = filteredMeetings.slice(startIndex, endIndex);

        return {
          limit,
          results: paginatedMeetings,
          totalPages: Math.ceil(filteredMeetings.length / limit),
          totalResults: filteredMeetings.length,
          page,
        };
      } catch (error) {
        console.error('Error fetching meetings from Google Calendar:', error);
        return {
          limit: filters.limit || 10,
          results: [],
          totalPages: 0,
          totalResults: 0,
          page: 1,
        };
      }
    },
    [isSigned, getEvents, getEmail, users, getCalendarEventById],
  );
  const {tasks} = useActionItems({
    userId: '',
    itemId: '',
    source: 'meeting',
    orderBy: {
      key: 'source',
      value: 'meeting',
    },
  });

  const deleteMeeting = useCallback(
    async (calendarId?: string, id?: string): Promise<any> => {
      if (!isSigned) return;
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      try {
        if (calendarId) await deleteEvent(calendarId, 'primary');

        if (id) await controller.deleteMeeting(id);

        activateNotification({
          kind: 'success',
          content: 'Deleted successfully',
          title: 'Success',
        });
        if (id) return {success: true};
      } catch (error) {
        activateNotification({
          kind: 'error',
          content: 'Error deleting event',
          title: 'Success',
        });
        throw error;
      }
    },
    [deleteEvent, isSigned],
  );

  const getMeetingsSummary = useCallback(
    async (filters: {
      startDate: string;
      endDate: string;
      user?: string;
      status?: string;
      participant?: string;
    }): Promise<IMeetingSummary[]> => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const response = await controller.getMeetingsSummary(filters);

      if (response) {
        return response.map((user: {id: any}) => {
          const actionItems = tasks.filter((task) => task.assignee === user.id);

          return {
            ...user,
            actionItems,
          };
        });
      }
      return [];
    },
    [tasks],
  );

  const onCancel = useCallback(
    async (id: string, reason: string): Promise<any> => {
      if (!isSigned) return;

      try {
        const response = await getEvent(id, 'primary');
        const event = response.result;

        await updateEvent(
          {
            description: `Cancellation reason: ${reason}`,
            summary: `Canceled: ${event.summary}`,
            attendees:
              event.attendees?.map((attendee: any) => ({
                ...attendee,
                responseStatus: 'declined',
              })) || [],
          },
          id,
          'primary',
          'all',
        );

        return {success: true};
      } catch (error) {
        throw error;
      }
    },
    [isSigned, getEvent, updateEvent],
  );

  return {
    dateRange,
    getMeetings,
    setDateRange,
    deleteMeeting,
    onCancel,
    setFilterBy,
    filterBy,
    setSortByDate,
    sortByDate,
    handleDateRange,
    selectedUsers,
    getMeetingsSummary,
    setSelectedUsers,
    tasks,
  };
};
