import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {memo, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {ViewMeetings} from './view-meetings';
import {useQuery} from 'react-query';
import {useViewMeetingHook} from './view-meeting-hook';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {FlexRow, FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {ArrowLeftIcon, GoogleMeetIcon} from '@ui/atoms/icons';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {Body2, CTA, Headline2, Headline3} from '@ui/atoms/typography';
import {useSearchParams} from 'react-router-dom';
import {ItemLoader} from '@ui/organisms/item-loader';
import MeetingView from './components/series-view';
import {Button} from '@ui/atoms/button';
import {TextField} from '@ui/molecules/field/textfield';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {TextArea} from '@ui/molecules/field/textArea';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import {useStoreContext} from '@store/store-context';
import {Tooltip} from '@ui/molecules/tooltip';

export const ViewMeetingsPage = memo(() => {
  const navigate = useNavigate();

  const {id} = useParams();

  const [searchParams] = useSearchParams();

  const [showSeriesSettings, setShowSeriesSettings] = useState(false);

  const viewMode = searchParams.get('view');

  const [showGoBack] = useState(!!viewMode);

  const {
    fetchMeetingDetails,
    syncGoogleMeeting,
    handleUpdateMeeting,
    isGoogleMeeting,
    isCreatingNewNote,
    handleCreateNewNote,
    getEmail,
    isSigned,
  } = useViewMeetingHook();

  const {data: connectedEmail = ''} = useQuery({
    queryFn: getEmail,
    queryKey: 'google-email',
    enabled: isSigned,
  });

  const {isLoading: isSyncing} = useQuery({
    queryKey: ['google-meeting-sync', id],
    queryFn: syncGoogleMeeting,
    enabled: isGoogleMeeting && !!id && isSigned,
    staleTime: 0,
    onError: () => {
      navigate('/meetings');
    },
  });

  const {
    data: meeting,
    isLoading: isLoadingMeeting,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['view-meeting', id],
    queryFn: () => fetchMeetingDetails(id),
    enabled: !isGoogleMeeting,
    refetchOnWindowFocus: false,
  });

  const connectedCalendar = meeting
    ? meeting.primaryMeeting?.connectedCalendar
    : null;

  const isLoading = isSyncing || isLoadingMeeting;

  const parentMeetingDetails = useMemo(() => (meeting ? meeting : null), [
    meeting,
  ]);

  const [isEditingSettings, setIsEditingSettings] = useState(false);

  const handleEditMeetingSeries = async (data: {
    title: string;
    description: string;
    enableBeamAI: boolean;
  }) => {
    try {
      if (meeting) {
        setIsEditingSettings(true);

        const primaryMeeting = meeting.primaryMeeting;

        if (primaryMeeting) {
          const item = primaryMeeting;
          const updateMeeting = await handleUpdateMeeting(item.id, {
            title: data.title || undefined,
            description: data.description || undefined,
            addBeamAI: data.enableBeamAI || false,
            meetingLink: primaryMeeting.meetingLink,
          } as any);

          if (updateMeeting) {
            await refetch();
          }
          if (item) {
            setShowSeriesSettings(false);
          }
        }
      }
    } catch (error) {
    } finally {
      setIsEditingSettings(false);
    }
  };

  const title = parentMeetingDetails
    ? parentMeetingDetails?.primaryMeeting?.title || parentMeetingDetails.title
    : meeting
    ? meeting.title
    : '';

  const description = parentMeetingDetails
    ? parentMeetingDetails?.primaryMeeting?.description ||
      parentMeetingDetails.description ||
      ''
    : '';

  const primaryMeeting = useMemo(
    () => connectedCalendar?.find((calendar) => calendar.isPrimary),
    [connectedCalendar],
  );

  return (
    <PageLayoutTemplate
      onClick={() => {
        navigate('/meetings/');
      }}
      leftAction={
        !showGoBack ? null : (
          <PlainButton onClick={() => navigate(-1)}>
            <FlexRow>
              <ArrowLeftIcon />
              <HorizontalSpacer size="10px" />
              <Body2 weight="semibold">Go back</Body2>
            </FlexRow>
          </PlainButton>
        )
      }
      titleMargin={showGoBack ? '1.1%' : '6.7%'}
      title={'Meetings'}
      bodyStyle={{margin: 0}}>
      <div className="mt-[75px] ">
        <div className="flex sm:flex-col flex-row w-full   justify-center gap-10">
          {meeting && connectedCalendar && (
            <div className="w-[230px] sm:w-full">
              <MeetingView
                meetings={connectedCalendar}
                selectedMeeting={meeting.calendarId || meeting.id}
                defaultTitle={title}
                isLoading={isLoading || isRefetching}
                primaryMeeting={primaryMeeting ? {...primaryMeeting} : null}
                onClickMeeting={(_meeting) => {
                  if (_meeting.meetingId) {
                    navigate(`/view-meetings/${_meeting.meetingId}`);
                    return;
                  }

                  if (_meeting.calendarId !== meeting.calendarId)
                    navigate(
                      `/view-meetings/${_meeting.calendarId}?meetingType=google`,
                    );
                }}
              />
            </div>
          )}

          <div className={'form min-w-[65%] max-w-[80%]'}>
            {(isLoading || isGoogleMeeting) && (
              <FlexRowCenter className="h-[50vh]">
                <ItemLoader />
              </FlexRowCenter>
            )}

            {!isLoading && !isGoogleMeeting && (
              <FlexRowSpaceBetween className="mb-2">
                <div className="w-full">
                  <div className="max-w-[65%]">
                    <Headline2>{title}</Headline2>
                  </div>
                  <button
                    disabled={Boolean(description)}
                    onClick={() =>
                      !Boolean(description) ? setShowSeriesSettings(true) : null
                    }>
                    {description ? (
                      <Tooltip
                        text={description}
                        bodyStyle={{}}
                        BodyTextNodeType={Body2}
                        withEllipsis
                        maxLength={50}
                        tooltipBody={<span>{description}</span>}
                      />
                    ) : (
                      <Body2 kind="textBody" className="mt-2">
                        {description ? description : 'Add description here'}
                      </Body2>
                    )}
                  </button>
                </div>
                <FlexRow className="gap-2">
                  <Button
                    style={{padding: '12px 24px'}}
                    isLoading={isCreatingNewNote}
                    onClick={() => {
                      if (meeting)
                        handleCreateNewNote({
                          ...meeting,
                          connectedCalendar:
                            parentMeetingDetails?.connectedCalendar,
                        });
                    }}>
                    New Note
                  </Button>
                </FlexRow>
              </FlexRowSpaceBetween>
            )}

            {!isLoading && (
              <ViewMeetings
                connectedGoogleEmail={connectedEmail}
                handleSeriesSettings={() =>
                  setShowSeriesSettings(!showSeriesSettings)
                }
              />
            )}
          </div>
        </div>
      </div>

      {showSeriesSettings && (
        <SeriesSettings
          isLoading={isEditingSettings}
          defaultData={{
            title: title,
            description: description,
          }}
          isOpen={showSeriesSettings}
          handleClose={() => setShowSeriesSettings(false)}
          handleEditSettings={handleEditMeetingSeries}
        />
      )}
    </PageLayoutTemplate>
  );
});

interface EditSeriesSettingsProps {
  handleEditSettings: (value: {
    title: string;
    description: string;
    enableBeamAI: boolean;
  }) => Promise<void>;
  isOpen: boolean;
  isLoading?: boolean;
  defaultData?: {
    title?: string;
    description?: string;
  };
  handleClose: () => void;
}

const SeriesSettings = ({
  handleClose,
  isOpen,
  isLoading,
  defaultData,
  handleEditSettings,
}: EditSeriesSettingsProps) => {
  const [formData, setFormData] = useState({
    title: defaultData?.title || '',
    description: defaultData?.description || '',
    enableBeamAI: false,
  });

  const handleUpdateForm = (
    field: keyof typeof formData,
    value: string | boolean,
  ) => {
    setFormData((prev) => ({...prev, [field]: value}));
  };
  const {
    integrationStore: {activeIntegrations = []},
  } = useStoreContext();

  const googleCalendarConfig = activeIntegrations.find(
    (integration) => integration.app === 'Google Calendar',
  );

  // Get BeamAI configuration settings
  const beamAiSettings = googleCalendarConfig?.data?.beamAI || [];

  const hasAutoForVideoLinks = beamAiSettings.includes(
    'all-meetings-with-video-link',
  );

  const hasAutoForUser = beamAiSettings.includes('organized-by-me');

  // Determine if BeamAI should be auto-enabled and toggle disabled
  const shouldAutoEnableAndDisable = hasAutoForVideoLinks || hasAutoForUser;

  return (
    <DrawerModal open={isOpen} onClose={handleClose}>
      <div>
        <Headline3>Settings</Headline3>

        <VerticalSpacer size="40px" />

        <TextField
          label="Discussion series Name"
          value={formData.title}
          inputStyle={{borderRadius: '10px'}}
          placeholder="Content meeting"
          onChange={(event) => handleUpdateForm('title', event.target.value)}
          name="name"
        />

        <TextArea
          label="Description"
          maxLength={300}
          inputStyle={{borderRadius: '10px', padding: '16px'}}
          value={formData.description}
          placeholder="Add a description (max. 300 characters)"
          onChange={(event) => {
            const newValue = event.target.value;
            if (newValue.length <= 300) {
              handleUpdateForm('description', newValue);
            }
          }}
          name="name"
        />

        <div className="rounded-[10px] p-6 mb-4 border border-borderLight">
          <Body2 weight="bold" className="mb-2">
            Auto record & Summarise
          </Body2>

          <FlexRow className="border border-borderDark rounded-[10px] p-4 gap-2 mt-2">
            {shouldAutoEnableAndDisable ? (
              <CustomColorIcon
                color="#47B881"
                width="12.8px"
                height="12.8px"
                margin={0}
              />
            ) : (
              <ToggleSwitch
                checked={formData.enableBeamAI}
                onChange={() => {
                  handleUpdateForm('enableBeamAI', !formData.enableBeamAI);
                }}
                switchId={Math.random()}
              />
            )}

            <CTA>
              {shouldAutoEnableAndDisable
                ? 'BeamAI will join'
                : 'Invite BeamAI to join'}
            </CTA>
          </FlexRow>

          <FlexRow className="border-t border-t-borderLight gap-2 mt-2 pt-2">
            <GoogleMeetIcon />

            <Body2 weight="bold">Google Meet</Body2>
          </FlexRow>
        </div>

        <Button
          width="full"
          onClick={() => handleEditSettings(formData)}
          isLoading={isLoading}>
          Save
        </Button>
      </div>
    </DrawerModal>
  );
};
