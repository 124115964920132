import styled from 'styled-components';
import {CSSProperties, ReactNode, memo} from 'react';
import {Avatar} from '../../../atoms/avatar';
import {HorizontalSpacer} from '../../../atoms/spacer';
import {Body2} from '../../../atoms/typography/body2';
import {Body1} from '../../../atoms/typography/body1';
import {Tooltip} from '../../tooltip';

export interface UserListCardProps {
  type: 'primary' | 'secondary' | 'aligned';
  name: string;
  avatar?: string;
  kind?: string;
  avatarName?: any;
  reviewer?: any;
  users?: any;
  userId: string;
  optional?: string;
  avatarIcon?: ReactNode;
  textStyle?: CSSProperties;
  tooltip: boolean;
  TextComponent?: any;
  avatarSize?: 'sm' | 'md' | 'lg' | 'xs';
  textKind?: string;
  tooltipText?: boolean;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 8px 1fr;
  align-items: center;
`;
export const UserListCard = memo(
  ({
    type,
    avatar,
    name,
    kind,
    avatarName,
    users,
    userId,
    tooltip,
    TextComponent,
    avatarIcon,
    textKind,
    textStyle,
    avatarSize,
    optional,
    tooltipText,
  }: UserListCardProps) => {
    const TextComponentControllerPrimary = TextComponent
      ? TextComponent
      : Body1;
    const TextComponentControllerAligned = TextComponent
      ? TextComponent
      : Body1;
    const TextComponentControllerSecondary = TextComponent
      ? TextComponent
      : Body2;

    switch (type) {
      case 'primary':
        return (
          <Wrapper>
            <Avatar
              tooltip={tooltip}
              size="md"
              src={avatar}
              name={name}
              avatarIcon={avatarIcon}
              alt={name}
              userId={userId}
            />
            <HorizontalSpacer size="8px" />
            <TextComponentControllerPrimary
              style={textStyle}
              kind={
                kind === 'admin' && !textKind
                  ? 'textDark'
                  : textKind
                  ? textKind
                  : 'textBody'
              }>
              {name}
            </TextComponentControllerPrimary>
          </Wrapper>
        );
      case 'aligned':
        return (
          <Wrapper>
            <Avatar
              tooltip={tooltip}
              size="sm"
              src={avatar}
              name={name}
              alt={name}
              userId={userId}
            />
            <HorizontalSpacer size="8px" />

            {tooltipText ? (
              <Tooltip
                text={name}
                tooltipBody={name}
                maxLength={13}
                BodyTextNodeType={TextComponentControllerAligned}
                bodyTextNodeKind={
                  kind === 'admin' && !textKind
                    ? 'textDark'
                    : textKind
                    ? textKind
                    : 'textBody'
                }
                bodyStyle={{fontWeight: 600, fontSize: '10px'}}
                noCapitalize
                withEllipsis
              />
            ) : (
              <TextComponentControllerAligned
                kind={
                  kind === 'admin' && !textKind
                    ? 'textDark'
                    : textKind
                    ? textKind
                    : 'textBody'
                }
                style={textStyle || {fontWeight: 600, fontSize: '10px'}}>
                {name}
              </TextComponentControllerAligned>
            )}
          </Wrapper>
        );

      case 'secondary':
        return (
          <Wrapper>
            <Avatar
              tooltip={tooltip}
              src={avatar}
              name={avatarName || name}
              optional={optional}
              customUsers={users}
              size={avatarSize}
              alt={avatarName || name}
              avatarIcon={avatarIcon}
              userId={userId}
            />
            <HorizontalSpacer size="8px" />

            {tooltipText ? (
              <Tooltip
                text={name}
                tooltipBody={name}
                maxLength={13}
                BodyTextNodeType={TextComponentControllerSecondary}
                bodyTextNodeKind={textKind ? textKind : 'textDark'}
                noCapitalize
                withEllipsis
              />
            ) : (
              <TextComponentControllerSecondary
                style={textStyle}
                kind={textKind ? textKind : 'textDark'}>
                {name}
              </TextComponentControllerSecondary>
            )}
          </Wrapper>
        );
      default:
        throw new Error(
          'Invalid list card type, you must specify primary|secondary',
        );
    }
  },
);

UserListCard.displayName = 'UserListCard';
