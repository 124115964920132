import {QuestionIconWrapper} from '../insights/styles';
import {ScrollIcon} from '@ui/atoms/icons/scroll-icon';
import {List} from '@ui/atoms/list';
import {ListKind} from '@ui/atoms/list/list';
import {Body2} from '@ui/atoms/typography';
import React, {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import {Insight} from '../insights/interface';
import {usePeriodHook} from '@hooks/period-hook';
import {formatConfigDate} from '../insights/insight-options';
import dayjs from 'dayjs';

interface VerticalInsightConfigProps {
  additionalFilters: string[];
  config: Insight['config'];
  disableVerticalScroll?: boolean;
  comparisonText?: string;
}
export const VerticalInsightConfig = ({
  config,
  additionalFilters,
  disableVerticalScroll,
  comparisonText,
}: VerticalInsightConfigProps) => {
  const [showTopScroll, setShowTopScroll] = useState(false);

  const [showBottomScroll, setShowBottomScroll] = useState(false);

  const containerRef = useRef<any>(null);

  const contentRef = useRef<any>(null);

  const formatDate = useMemo(() => formatConfigDate(config), [config]);

  const {getPeriod} = usePeriodHook();

  const period = getPeriod(formatDate.startDate);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    const checkScroll = () => {
      if (container && content) {
        setShowTopScroll(container.scrollTop > 0);
        setShowBottomScroll(
          container.scrollTop + Math.max(container.clientHeight, 50) <
            content.clientHeight,
        );
      }
    };

    const preventScroll = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };

    checkScroll();
    container.addEventListener('scroll', checkScroll);
    container.addEventListener('wheel', preventScroll, {passive: false});
    container.addEventListener('touchmove', preventScroll, {passive: false});
    window.addEventListener('resize', checkScroll);

    return () => {
      container.removeEventListener('scroll', checkScroll);
      container.removeEventListener('wheel', preventScroll);
      container.removeEventListener('touchmove', preventScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const scroll = (direction: number) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        top: direction * 35,
        behavior: 'smooth',
      });
    }
  };

  const formatCustomRange = (value: string) => {
    const dates = value.split(' - ');

    const startDate =
      dates?.[0] && dates?.[1]
        ? dayjs(dates?.[0], 'DD-MM-YY').format('DD MMM. YYYY')
        : '';

    const endDate = dates?.[1]
      ? dayjs(dates?.[1], 'DD-MM-YY').format('DD MMM. YYYY')
      : '';

    return {
      startDate,
      endDate,
    };
  };

  const rangeValueFormat = useCallback(() => {
    if (config.type === 'custom') {
      const date = formatCustomRange(config.range.value);

      if (!date.startDate) {
        return '';
      }

      return `${date.startDate} - ${date.endDate}`;
    }

    if (config.range.type === 'day') {
      return `Last ${config.range.value} days`;
    }
    if (config.type === 'periods') {
      return period?.label;
    }
    return config.range.value;
  }, [config.range.type, config.range.value, config.type, period?.label]);

  const showFormat =
    config.type === 'custom'
      ? !!formatCustomRange(config.range.value).startDate &&
        !!formatCustomRange(config.range.value).endDate
      : !!config.range.value;

  return (
    <div className="relative w-full h-[30px]">
      <div
        ref={containerRef}
        className="overflow-y-auto h-full scrollbar-hide"
        style={{
          scrollbarWidth: 'none', // Firefox
          msOverflowStyle: 'none', // Internet Explorer 10+
          WebkitOverflowScrolling: 'touch', // iOS momentum scrolling
        }}>
        <div ref={contentRef} className="flex flex-row flex-wrap gap-2 pb-6">
          {showFormat && (
            <div className="bg-backgroundDark py-1 px-2 rounded-[6px]">
              <Body2 style={{fontSize: '12px'}} kind="textBody" weight="bold">
                {rangeValueFormat()}
              </Body2>
            </div>
          )}

          {(config.comparison.value || comparisonText) && (
            <div className="bg-backgroundDark py-1 px-2 rounded-[6px]">
              <Body2 style={{fontSize: '12px'}} kind="textBody" weight="bold">
                {comparisonText ||
                  `Compared to | Previous ${config.comparison.value} days`}
              </Body2>
            </div>
          )}

          {additionalFilters.length ? (
            <div className="relative">
              <QuestionIconWrapper tooltip={true}>
                <div className="bg-backgroundDark py-1 px-2 rounded-[6px]">
                  <Body2 kind="textBody" weight="semibold">
                    Filters ({additionalFilters.length})
                  </Body2>
                </div>
                <span
                  className="tooltip"
                  style={{
                    width: '300px',
                    padding: '16px',
                  }}>
                  <Body2 weight="semibold" kind="textBody">
                    Filtered by:
                  </Body2>
                  <List items={additionalFilters} kind={ListKind.DISC} />
                </span>
              </QuestionIconWrapper>
            </div>
          ) : null}
        </div>
      </div>
      {(showBottomScroll || showTopScroll) && !disableVerticalScroll && (
        <button
          onClick={() => scroll(showTopScroll ? -1 : 1)}
          className="absolute -right-[10px] top-1 transform -translate-x-1/2 rounded-full p-1">
          <ScrollIcon />
        </button>
      )}
    </div>
  );
};
