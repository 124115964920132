import {AgendaTemplateResponse, MeetingBotResponse} from '@hooks';
import {BaseRequest} from '../../configs/request';
import {authStore} from '../../store/stores/auth-store';

interface IMeetingRequest extends BaseRequest {
  postMeeting(data: any): Promise<any>;
  patchMeeting(data: any, meetingId: string): Promise<any>;
  getMeetings(filters: any): Promise<any>;
  updateMeetingBotResponse(id: string, data: any): Promise<any>;
  getMeetingBotData(id: string): Promise<any>;
  getMeetingBotResponse(id: string): Promise<MeetingBotResponse | undefined>;
  getMeetingsSummary(filters: any): Promise<any>;
  getMeetingTags(): Promise<any>;
  getAgendaTemplates(): Promise<AgendaTemplateResponse>;
  fetchOneOnOneReporting(filters: any): Promise<any>;
}

export class MeetingRequestImpl extends BaseRequest implements IMeetingRequest {
  url: string = '/one-on-one';

  private requestHeader = {
    headers: {
      Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
    },
  };

  async postMeeting(data: any) {
    const {data: response} = await this.request.post(this.url, data, {
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
    });
    return response;
  }
  async patchMeeting(data: any, meetingId: string) {
    const {data: response} = await this.request.patch(
      `${this.url}/${meetingId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async getMeetings(filters: any) {
    const {
      startDate,
      endDate,
      participant,
      user,
      status,
      users,
      paginate,
      hasLabel,
      label,
      meetingCode,
      calendarId,
      limit,
      page,
      type,
    } = filters;

    const {data: response} = await this.request.get(
      `${this.url}?${startDate ? `startDate=${startDate}` : ''}&${
        endDate ? `endDate=${endDate}` : ''
      }${participant ? `&participant=${participant}` : ''}${
        user ? `&user=${user}` : ''
      }${label ? `&label=${label}` : ''}${page ? `&page=${page}` : ''}${
        limit ? `&limit=${limit}` : ''
      }${
        paginate !== undefined ? `&paginate=${paginate}` : ''
      }&sortBy=createdAt:desc${
        status ? `&status=${status}` : ''
      }&include=participant,user${users ? `&users=${users}` : ''}${
        type ? `type=${type}` : ''
      }${meetingCode ? `&meetingCode=${meetingCode}` : ''}${
        hasLabel ? `&hasLabel=true` : ''
      }${calendarId ? `&calendarId=${calendarId}` : ''}`,

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
        useCache: false,
      },
    );
    return response;
  }

  async getMeetingsSummary(filters: any) {
    const {
      startDate,
      endDate,
      participant,
      user,
      status,
      users,
      type,
    } = filters;

    const {data: response} = await this.request.get(
      `${this.url}/summary?startDate=${startDate}&endDate=${endDate}${
        participant ? `&participant=${participant}` : ''
      }${user ? `&user=${user}` : ''}&sortBy=createdAt:desc${
        status ? `&status=${status}` : ''
      }&include=participant,user${users ? `&users=${users}` : ''}&type=${type}`,

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
        useCache: false,
      },
    );
    return response;
  }

  async getMeeting(id: any, startMeeting?: boolean) {
    const {data: response} = await this.request.get(
      `${this.url}/${id}?${
        startMeeting ? `start=true` : ''
      }&include=participant`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
        useCache: false,
      },
    );
    return response;
  }
  async getMeetingBotResponse(id: string) {
    const {data: response} = await this.request.get(
      `${this.url}/${id}/bot-response`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async getMeetingBotData(id: string) {
    const {data: response} = await this.request.get(
      `${this.url}/${id}/bot-data`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async updateMeetingBotResponse(id: string, data: any) {
    const {data: response} = await this.request.patch(
      `${this.url}/${id}/bot-response`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async getMeetingTags(filter?: {startDate?: string; endDate?: string}) {
    const searchParams = new URLSearchParams();

    if (filter?.startDate) {
      searchParams.set('startDate', filter?.startDate);
    }

    if (filter?.endDate) {
      searchParams.set('endDate', filter?.endDate);
    }

    const {data: response} = await this.request.get(
      `${this.url}/get-tags?startDate=${filter?.startDate}&endDate=${filter?.endDate}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async getAgendaTemplates(limit?: number) {
    const {data: response} = await this.request.get(
      `/agenda-templates?${limit ? `limit=${limit}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async deleteMeeting(meetingId: string) {
    const {data: response} = await this.request.delete(
      `${this.url}/${meetingId}`,

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async fetchOneOnOneReporting(data: any) {
    const {
      impression,
      manager,
      startDate,
      endDate,
      user,
      groupBy,
      sortByCount,
      participant,
    } = data;
    const {data: response} = await this.request.get(
      `/reports/one-on-one${startDate ? `?startDate=${startDate}` : ''}${
        endDate ? `&endDate=${endDate}` : ''
      }${impression ? `&impression=${impression}` : ''}${
        groupBy ? `&groupBy=${groupBy}` : ''
      }${sortByCount ? `&sortByCount=${sortByCount}` : ''}${
        user ? `&user=${user}` : ''
      }${participant ? `&participant=${participant}` : ''}${
        manager ? `&manager=${manager}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
}
