import {ModalCard} from '@ui/layouts/modal-card';
import {Modal} from '@ui/molecules/modal';
import React, {useCallback, useEffect, useState} from 'react';
import {ModalWrapper} from './styles';
import {Body1, Body2, Headline2, Headline3} from '@ui/atoms/typography';
import {TextField} from '@ui/molecules/field/textfield';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Button} from '@ui/atoms/button';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {useStoreContext} from '@store/store-context';
import {MultiUserSelectField} from '@ui/molecules/select/multi-user';
import {userName} from '@utils/user-name';
import dayjs from 'dayjs';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {SelectField} from '@ui/molecules/select/selectfield';
import {Label} from '@ui/atoms/label';
import {useGCalenderHook} from '@hooks/gcalender';
import {IAPIUserFormat, IMeetingResponse} from '@hooks';
import {authStore} from '@store/stores/auth-store';
import {get} from 'idb-keyval';
import {MeetingController} from '../meeting-controller';
import {MeetingRequestImpl} from '../meeting.request';

interface ICreateNoteTopic {
  handleCreate: (data: {title: string; private: boolean}) => void;
  onClose: () => void;
}
export const CreateNoteTopic = ({handleCreate, onClose}: ICreateNoteTopic) => {
  const [formFields, setFormFields] = useState({
    title: '',
    private: false,
  });

  const handleUpdate = (field: keyof typeof formFields, value: any) => {
    setFormFields((prev) => ({...prev, [field]: value}));
  };

  return (
    <div>
      <Modal open={true} onClose={() => onClose()}>
        <ModalWrapper>
          <div className="flex justify-end mb-3">
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => onClose()}
              width="md"
            />
          </div>
          <ModalCard title="">
            <div>
              <Headline2 className="mb-3">Add note to topic</Headline2>
              <Body2 weight="bold">Title</Body2>
              <TextField
                placeholder="Enter title (max 25 characters)"
                value={formFields.title}
                onChange={(event) => handleUpdate('title', event.target.value)}
                inputStyle={{borderRadius: '10px'}}
              />
              <VerticalSpacer size="10px" />
              <Body2 weight="bold">Visibility settings</Body2>
              <div className="flex justify-between items-center border border-[#CECEDE] p-4 rounded-[10px]">
                <Body2 weight="semibold">
                  Make topic & its contents private
                </Body2>
                <ToggleSwitch
                  switchId={23445}
                  checked={formFields.private}
                  onChange={() => handleUpdate('private', !formFields.private)}
                />
              </div>
              <VerticalSpacer size="32px" />
              <Button
                width="full"
                onClick={() => handleCreate(formFields)}
                disabled={!formFields.title || formFields.title.length > 25}>
                Create
              </Button>
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

interface IDeleteTopic {
  onClose: () => void;
  handleDelete: () => void;
  topic: string;
}
export const DeleteTopic = ({handleDelete, onClose, topic}: IDeleteTopic) => {
  return (
    <div>
      <CustomIconModal
        open
        onClose={onClose}
        customButton={
          <Button
            kind="secondary"
            width="full"
            onClick={handleDelete}
            type="button">
            Delete
          </Button>
        }
        body={<></>}
        onClick={() => {}}
        title={'Delete Note?'}
        description={
          <div>
            <Body2 align="center">
              Are you sure you want to delete{' '}
              <span className="text-[#585ADF] font-medium text-[16px]">
                {topic}
              </span>
              ? This action cannot be undone.
            </Body2>
          </div>
        }
      />
    </div>
  );
};

interface IEditTopic {
  handleEdit: (field: {title: string}) => void;
  onClose: () => void;
}
export const EditTopic = ({handleEdit, onClose}: IEditTopic) => {
  const [formFields, setFormFields] = useState({
    title: '',
  });

  const handleUpdate = (field: keyof typeof formFields, value: any) => {
    setFormFields((prev) => ({...prev, [field]: value}));
  };

  return (
    <div>
      <Modal open={true} onClose={() => onClose()}>
        <ModalWrapper>
          <div className="flex justify-end mb-3">
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => onClose()}
              width="md"
            />
          </div>
          <ModalCard title="">
            <div>
              <Headline2 className="mb-3">Edit topic</Headline2>
              <Body2 weight="bold">Title</Body2>
              <TextField
                placeholder="Enter title (max 25 characters)"
                value={formFields.title}
                onChange={(event) => handleUpdate('title', event.target.value)}
                inputStyle={{borderRadius: '10px'}}
              />

              <VerticalSpacer size="32px" />
              <Button
                width="full"
                onClick={() => handleEdit(formFields)}
                disabled={!formFields.title || formFields.title.length > 25}>
                Create
              </Button>
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

interface IShareMeeting {
  handleAdd: (value: string[]) => Promise<void>;
  onClose: () => void;
  meeting: {
    title: string;
    startDate: string;
    endDate?: string;
    time: string;
  };
}
interface TLabel {
  name: string;
  avatar: {src: string; name: string};
  id: string;
}

export const ShareMeeting = ({handleAdd, onClose, meeting}: IShareMeeting) => {
  const {
    usersStore: {users},
  } = useStoreContext();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const members = users.map((user) => ({
    value: user.id,
    label: {
      avatar: {src: user.avatar?.url, name: userName(user)},
      id: user.id,
      name: userName(user),
    },
  }));

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (value: string[]) => {
    setIsLoading(true);
    try {
      await handleAdd(value);
      onClose();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Modal open={true} onClose={() => onClose()}>
        <ModalWrapper>
          <div className="flex justify-end mb-3">
            <BackAction
              icon={<CancelIcon />}
              title="Cancel"
              onClick={() => onClose()}
              width="md"
            />
          </div>
          <ModalCard title="" modalStyle={{padding: '24px'}}>
            <div>
              <FlexRowSpaceBetween>
                <Headline2 className="mb-3">Share note</Headline2>
              </FlexRowSpaceBetween>
              <div className="flex items-center border border-borderDark rounded-[10px] py-3 px-4 gap-3 w-fit mb-4">
                <Body2 kind="textBody">{meeting.title}</Body2>
                <CustomColorIcon
                  height="8px"
                  width="8px"
                  margin={0}
                  color="#D9D9D9"
                />

                <Body2 kind="textBody">
                  {dayjs(meeting.startDate).format("ddd. D MMM. 'YY")}
                </Body2>

                {meeting?.time && !meeting?.title?.includes('Notes with') && (
                  <>
                    <CustomColorIcon
                      height="8px"
                      width="8px"
                      margin={0}
                      color="#D9D9D9"
                    />
                    <Body2 kind="textBody">
                      {' '}
                      {dayjs(meeting.startDate).format('hh:mm a')}{' '}
                      {meeting?.endDate
                        ? `- ${dayjs(meeting.endDate).format('hh:mm a')}`
                        : null}
                    </Body2>
                  </>
                )}
              </div>
              <div className="border border-borderLight p-4 rounded-[10px]">
                <Label>Add team members</Label>
                <MultiUserSelectField
                  name="users"
                  margin
                  value={selectedUsers}
                  reporting
                  performance
                  onChange={(data: {value: string; label: TLabel}[]) => {
                    setSelectedUsers(data.map((obj) => obj.value));
                  }}
                  label=""
                  placeholder="Select members"
                  options={members}
                />
              </div>

              <VerticalSpacer size="32px" />
              <Button
                width="full"
                isLoading={isLoading}
                onClick={() => handleSubmit(selectedUsers)}
                disabled={!selectedUsers.length}>
                Share
              </Button>
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

interface ILinkToMeeting {
  handleSave: (field: string[]) => void;
  onClose: () => void;
  currentMeetingId: string;
}

interface ILinkToMeeting {
  handleSave: (field: string[]) => void;
  onClose: () => void;
  currentMeetingId: string;
  currentMeeting: IMeetingResponse & {primaryMeeting: {id: string}};
}

export const LinkToMeeting = ({
  handleSave,
  onClose,
  currentMeeting,
}: ILinkToMeeting) => {
  const [selectedMeeting, setSelectedMeeting] = useState<string>('');

  const [meetings, setMeetings] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const {listCalendarEvents, isSigned} = useGCalenderHook();

  // Fetch Google Calendar events
  const fetchGoogleEvents = useCallback(async () => {
    if (!isSigned) return;

    try {
      const events = await listCalendarEvents(
        100,
        dayjs().subtract(2, 'months').format(),
        dayjs().format(),
        'primary',
      );

      if (events?.result?.items) {
        // Filter to only include events that are meetings (have conferenceData)
        const meetingEvents = events.result.items.filter(
          (event: any) => event.conferenceData || event.hangoutLink,
        );

        setMeetings(meetingEvents);
      }
    } catch (error) {
      console.error('Error fetching Google Calendar events:', error);
    }
  }, [isSigned, listCalendarEvents]);

  useEffect(() => {
    fetchGoogleEvents();
  }, [fetchGoogleEvents]);

  const handleLinkMeeting = async () => {
    if (!selectedMeeting) return;

    try {
      setIsLoading(true);
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const selectedEvent = meetings.find(
        (meeting) => meeting.id === selectedMeeting,
      );

      if (!selectedEvent) return;

      if (currentMeeting.primaryMeeting) {
        const parentMeeting = await controller.getMeeting(
          currentMeeting.primaryMeeting.id,
        );

        if (parentMeeting) {
          const connectedCalendar = parentMeeting.connectedCalendar;

          const updatedConnectedCalendar = connectedCalendar.filter(
            (calendar: {calendarId: string; meetingId: string}) =>
              calendar?.calendarId !== currentMeeting.calendarId &&
              calendar.meetingId !== currentMeeting.id,
          );

          await controller.patchMeeting(
            {
              connectedCalendar: updatedConnectedCalendar.map(
                (calendar: any) => ({
                  ...calendar,
                  _id: undefined,
                }),
              ),
            },
            parentMeeting.id,
          );
        }
      }

      // Check if meeting already exists in Beam
      // Check the meeting with startDate
      const existingMeetings = await controller.getMeetings({
        calendarId: selectedEvent.id,
      });

      const startDateTime =
        currentMeeting.startDate || selectedEvent.start.date;

      // Get workspace users
      const users: IAPIUserFormat[] | undefined = await get(
        `${authStore.auth.user.workspace.id}/users`,
      );

      const attendees =
        selectedEvent.attendees?.map(
          (attendee: {email: string}) => attendee.email,
        ) || [];

      // Find workspace users from attendees
      const participantIds = users
        ?.filter((user) => attendees.includes(user.email))
        .map((user) => user.id);

      if (existingMeetings?.results?.length > 0) {
        // Meeting exists - update connectedCalendar
        const existingMeeting = existingMeetings.results[0];
        const updatedConnectedCalendar = [
          ...(existingMeeting.connectedCalendar || []).map((calendar: any) => ({
            ...calendar,
            _id: undefined,
          })),
          {
            meetingId: currentMeeting.id,
            isPrimary: false,
            startDate: dayjs(startDateTime).format(),
            title: currentMeeting.title,
          },
        ];

        await controller.patchMeeting(
          {
            connectedCalendar: updatedConnectedCalendar,
          },
          existingMeeting.id,
        );

        await controller.patchMeeting(
          {
            connectedCalendar: updatedConnectedCalendar,
          },
          currentMeeting.id,
        );
      } else {
        const eventTime =
          selectedEvent.start.date || selectedEvent.start.dateTime;

        const endEventTime =
          selectedEvent.end.date || selectedEvent.end.dateTime;

        const isAllDay = !!selectedEvent.start.date;
        // Create new meeting
        const connections = [
          {
            isPrimary: true,
            calendarId: selectedEvent.id,
            startDate: isAllDay
              ? dayjs(eventTime).format('YYYY-MM-DD')
              : dayjs(eventTime).format(),
            title: selectedEvent.summary,
          },
          {
            meetingId: currentMeeting.id,
            isPrimary: false,
            startDate: dayjs(startDateTime).format(),
            title: currentMeeting.title,
          },
        ];

        const newMeeting: any = {
          title: selectedEvent.summary || 'Untitled Meeting',
          startDate: isAllDay
            ? dayjs(eventTime).format('YYYY-MM-DD')
            : dayjs(eventTime).format(),
          endDate: isAllDay
            ? dayjs(endEventTime).format('YYYY-MM-DD')
            : dayjs(endEventTime).format(),
          participant: participantIds,
          calendarId: selectedEvent.id,
          connectedCalendar: connections,
        };

        if (!isAllDay) {
          newMeeting.time = dayjs(eventTime).format('hh:mm A');
        }

        await controller.postMeeting(newMeeting);

        await controller.patchMeeting(
          {
            connectedCalendar: connections,
          },
          currentMeeting.id,
        );
      }

      handleSave([selectedEvent.id]);
      onClose();
    } catch (error) {
      console.error('Error linking meeting:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal open={true} onClose={onClose}>
        <ModalWrapper>
          <div className="flex justify-end mb-3">
            <BackAction
              icon={<CancelIcon />}
              title="Cancel"
              onClick={() => onClose()}
              width="md"
            />
          </div>

          <ModalCard title="" modalStyle={{padding: '24px'}}>
            <div>
              <FlexRowSpaceBetween>
                <Headline3 className="mb-3">Link note to a meeting</Headline3>
              </FlexRowSpaceBetween>

              <div className="border border-borderLight p-4 mt-4 rounded-[10px]">
                <Label>Choose from an event in your calendar</Label>
                <VerticalSpacer size="8px" />

                <SelectField
                  placeholder="Select a meeting"
                  performance
                  type="dropdownOnly"
                  borderRadius="10px"
                  excludeSearchBox={false}
                  showCancelIcon={false}
                  value={selectedMeeting}
                  isLoading={isLoading}
                  noOptionMessage={
                    isLoading ? 'Loading meetings...' : 'No meetings found'
                  }
                  options={meetings.map((meeting) => ({
                    value: meeting.id,
                    label: `${meeting.summary || 'Untitled'} - ${dayjs(
                      meeting.start.dateTime || meeting.start.date,
                    ).format('MMM D, YYYY h:mm A')}`,
                  }))}
                  onChange={(data: {value: string}) => {
                    setSelectedMeeting(data?.value || '');
                  }}
                />
              </div>

              <VerticalSpacer size="32px" />
              <Button
                width="full"
                onClick={handleLinkMeeting}
                disabled={!selectedMeeting || isLoading}
                isLoading={isLoading}>
                Save
              </Button>
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

interface IDeleteNote {
  handleDelete: () => Promise<void>;
  onClose: () => void;
}
export const DeleteNote = ({handleDelete, onClose}: IDeleteNote) => {
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const response = await handleDelete();
      onClose();
      return response;
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Modal open={true} onClose={() => onClose()}>
        <ModalWrapper style={{maxWidth: '500px'}}>
          <div className="flex justify-end mb-3">
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={() => onClose()}
              width="md"
            />
          </div>
          <ModalCard title="">
            <div>
              <Headline2 className="mb-3" align="center">
                {' '}
                Delete Note ?{' '}
              </Headline2>
              <Body1 kind="textBody" align="center">
                This note and it’s contents will be permanently deleted.
              </Body1>
              <Body1 align="center" className="mt-2" kind="textBody">
                This action cannot be undone. Proceed?
              </Body1>
              <VerticalSpacer size="32px" />
              <Button width="full" isLoading={isLoading} onClick={onSubmit}>
                Delete
              </Button>
            </div>
          </ModalCard>
        </ModalWrapper>
      </Modal>
    </div>
  );
};
