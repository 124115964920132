import {GoalsController} from '@api/goals-api/goals-controller';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {useStoreContext} from '@store/store-context';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {transformOptions} from '@pages/dashboard/reporting/reporting-pages/reporting-overview/components/kpi-board';
import {useQuery} from 'react-query';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {useActionItems} from './features-action-items';
import {userName} from '@utils/user-name';
import {useFetchGoalById} from '@pages/dashboard/you/you-components/insights/hooks/insight-hook';
import {useFirebaseFetch} from './query-hook';
import {get} from 'idb-keyval';
import {useSections} from './action-items-sections';
import dayjs from 'dayjs';
import {IAPIUserFormat, IMeetingResponse} from './interface';

export const useActionItemsPage = (
  selectedManager: string,
  selectedUser: string,
) => {
  const [actionItems, setActionItems] = useState<any[]>([]);

  const meetingsRequest = useMemo(() => new MeetingRequestImpl(), []);

  const meetingsController = useMemo(
    () => new MeetingController(meetingsRequest),
    [meetingsRequest],
  );

  const {
    usersStore: {users},
    groupStore: {groups},
    groupTypeStore: {groupType},
    authStore: {auth},
  } = useStoreContext();

  const findUser = useCallback(
    (id: string) => users.find((user) => user.id === id),
    [users],
  );

  const {handleChange, tasks: allTasks, isLoading} = useActionItems({
    userId: '',
    itemId: '',
    source: '',
    fetchAll: true,
    callback: () => {},
  });

  const [tasks, setTasks] = useState(allTasks);

  useEffect(() => {
    if (!tasks.length && allTasks.length) {
      setTasks(allTasks);
    }
  }, [allTasks, tasks.length]);

  function removeDuplicateKeys(array: any[]) {
    // Create an object to store unique _id values as keys
    const uniqueIds: any = {};

    // Filter the array to include only objects with unique _id values
    const uniqueArray = array.filter((obj) => {
      if (!uniqueIds[obj.key]) {
        uniqueIds[obj.key] = true;
        return true;
      }

      return false;
    });

    return uniqueArray;
  }

  const fetchSpecifiedGoals = useCallback(
    async (filters: any, view?: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      const response: {goals: any[]} = await controller.fetchSpecifiedGoals(
        filters,
        view,
      );
      const formatGoal = {
        ...response,
        goals: response.goals.map((goal) => {
          if (goal.goalType === 'group') {
            const group = groups.filter((group) =>
              goal.group.includes(group.id),
            );

            return {
              ...goal,
              group,
            };
          }
          return {
            ...goal,
          };
        }),
      };
      return formatGoal;
    },
    [groups],
  );

  const {getGoalDetails} = useFetchGoalById();

  const dateRange = {
    startDate: dayjs().subtract(2, 'year').format('MM-DD-YYYY'),
    endDate: dayjs().format('MM-DD-YYYY'),
  };

  const {data: goals = {goals: []}, isLoading: goalsIsLoading} = useQuery(
    ['action-items-goals', selectedManager, selectedUser],
    () =>
      fetchSpecifiedGoals({
        ...dateRange,
        filterBy: 'weeks',
        removeKpiFilter: true,
        member: selectedUser,
        manager: selectedManager,
      }),
  );

  const {data: meetings, isLoading: meetingsIsLoading} = useQuery(
    ['action-items-meetings', selectedManager, selectedUser],
    async () => {
      const response = await meetingsController.getMeetings({
        ...dateRange,
        user: selectedUser,
        participant: selectedUser,
      });

      if (response.results) return response.results as IMeetingResponse[];
      return [];
    },
  );

  const initGoals = useCallback(
    async (page: number, filter?: string) => {
      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);

      const response = await controller.fetchGoals(page, filter, 10000);
      const groups = await get(`${auth.user.workspace.id}/groups`);

      const unfurledData = response.results;

      const transformGoalOptions = unfurledData.map((goal: any) =>
        transformOptions(goal, groups, groupType),
      );

      setGoalOptions(transformGoalOptions);
    },
    [auth.user.workspace.id, groupType],
  );

  const sources = useMemo(() => new Map(), []);

  const filteredTasks = useMemo(
    () =>
      tasks
        .filter((task) =>
          !selectedUser
            ? true
            : [task.assignee?.id, task?.user].includes(selectedUser),
        )
        .map((task) =>
          typeof task.assignee === 'string'
            ? {
                ...task,
                assignee: users.find((user) => user.id === task.assignee),
              }
            : task,
        ),
    [selectedUser, tasks, users],
  );

  const handleSource = useOptimizedHandleSource(
    filteredTasks,
    sources,
    goals,
    getGoalDetails,
    meetings,
    meetingsController,
    findUser,
    setTasks,
  );

  const [goalOptions, setGoalOptions] = useState<any[]>([]);

  return {
    actionItems,
    setActionItems,
    sources,
    allTasks,
    setTasks,
    handleSource,
    isLoading: isLoading || meetingsIsLoading || goalsIsLoading,
    allGoals: goals,
    allMeetings: meetings,
    goalOptions,
    initGoals,
    meetingsController,
    findUser,
    removeDuplicateKeys,
    handleChange,
    firebaseActionItems: filteredTasks,
  };
};

export const useActionItemsWithSortFilter = (
  selectedManager: string,
  selectedUser: string,
  defaultShowCompleted: boolean = false,
) => {
  const {firebaseActionItems: data, ...rest} = useActionItemsPage(
    selectedManager,
    selectedUser,
  );

  const [sortBy, setSortBy] = useState<'createdAt' | 'dueDate'>('createdAt');

  const [showCompleted, setShowCompleted] = useState(defaultShowCompleted);

  const filterPath = `users/${selectedUser}/action_items/filter`;

  const {data: actionItemsFilterPath} = useFirebaseFetch(filterPath);

  const isCustomFilter = actionItemsFilterPath?.type === 'custom';

  const {computeSections, ...section} = useSections();

  const sortedData = useMemo(() => {
    const sortData = data
      ?.filter((task) => showCompleted || !task.completed)
      .slice();

    return sortData;
  }, [data, showCompleted]);

  const groupBySections = useMemo(() => {
    const formattedItems = sortedData.length
      ? sortedData
      : [{text: '', completed: false}];

    const groupedItems = formattedItems.reduce((acc, item) => {
      const itemSection = computeSections.find(
        (_section) => _section.slug === item?.section,
      );

      let section = itemSection?.name || 'Inbox';

      if (itemSection) {
        item.section_slug = itemSection.slug;
      }

      if (!acc[section]) {
        acc[section] = [];
      }

      acc[section].push(item);

      return acc;
    }, {} as Record<string, any[]>);

    // Ensure all sections are initialized in acc
    computeSections.forEach((_section) => {
      if (!groupedItems[_section.name]) {
        groupedItems[_section.name] = [];
      }
    });

    let entries = Object.entries(groupedItems);

    // Sort entries based on multiple criteria
    entries.sort(([a], [b]) => {
      if (a === 'Inbox') return -1;
      if (b === 'Inbox') return 1;

      return 0;
    });

    return entries;
  }, [computeSections, sortedData]);

  const completedTasks = data.filter((task) => !!task.completed);

  return {
    ...rest,
    firebaseActionItems: data,
    completedTasks,
    updateShowCompleted: setShowCompleted,
    ...section,
    sortedData,
    computeSections,
    sortBy,
    showCompleted,
    groupBySections,
    isCustomFilter,
    setSortBy,
  };
};

interface Goal {
  id: string;
  name: string;
}

interface Meeting extends IMeetingResponse {}

interface Task {
  id: string;
  source: 'goal' | 'meeting';
  sourceId: string;
  sourceTitle?: string;
  isSourceDeleted?: boolean;
}

interface Source {
  title: string;
  isDeleted: boolean;
  goal?: Goal;
  meeting?: Meeting;
}

type Sources = Map<string, Source>;

const useOptimizedHandleSource = (
  filteredTasks: Task[],
  sources: Sources,
  goals: {goals?: Goal[]} | null,
  getGoalDetails: (id: string) => Promise<Goal>,
  meetings: Meeting[] | null | undefined = [],
  meetingsController: any,
  findUser: (id: string) => IAPIUserFormat | undefined,
  setTasks: React.Dispatch<React.SetStateAction<Task[]>>,
) => {
  // Memoize goals and meetings for faster lookups
  const goalsMap = useMemo(
    () => new Map(goals?.goals?.map((g) => [g.id, g]) || []),
    [goals],
  );
  const meetingsMap = useMemo(
    () => new Map(meetings?.map((m) => [m.id, m]) || []),
    [meetings],
  );

  const handleSource = useCallback(async () => {
    const updatedTasks = new Map<string, Partial<Task>>();
    const missingGoals = new Set<string>();
    const missingMeetings = new Set<string>();

    // First pass: identify missing data
    filteredTasks.forEach((task) => {
      if (
        task.source === 'goal' &&
        !goalsMap.has(task.sourceId) &&
        !sources.has(task.sourceId)
      ) {
        missingGoals.add(task.sourceId);
      } else if (
        task.source === 'meeting' &&
        !meetingsMap.has(task.sourceId) &&
        !sources.has(task.sourceId)
      ) {
        missingMeetings.add(task.sourceId);
      }
    });

    // Batch fetch missing data
    const [fetchedGoals, fetchedMeetings] = await Promise.all([
      Promise.all(
        Array.from(missingGoals).map((id) =>
          getGoalDetails(id).catch(() => null),
        ),
      ),
      Promise.all(
        Array.from(missingMeetings).map((id) =>
          meetingsController.getMeeting(id, undefined, true).catch(() => null),
        ),
      ),
    ]);

    // Update maps with fetched data
    fetchedGoals.forEach((goal) => {
      if (goal) goalsMap.set(goal.id, goal);
    });
    fetchedMeetings.forEach((meeting) => {
      if (meeting) meetingsMap.set(meeting.id, meeting);
    });

    // Process tasks
    filteredTasks.forEach((task) => {
      if (task.source === 'goal') {
        const goal =
          goalsMap.get(task.sourceId) || sources.get(task.sourceId)?.goal;
        const sourceTitle = goal?.name || 'Deleted goal';
        updatedTasks.set(task.id, {sourceTitle, isSourceDeleted: !goal});
        sources.set(task.sourceId, {
          title: sourceTitle,
          isDeleted: !goal,
          goal,
        });
      } else if (task.source === 'meeting' && task.sourceId) {
        const meeting =
          meetingsMap.get(task.sourceId) || sources.get(task.sourceId)?.meeting;
        if (meeting) {
          const participant =
            typeof meeting.participant === 'string'
              ? findUser(meeting.participant)
              : meeting.participant;
          const sourceTitle =
            meeting.title || `1:1 meeting with ${userName(participant)}`;
          updatedTasks.set(task.id, {sourceTitle, isSourceDeleted: false});
          sources.set(task.sourceId, {
            title: sourceTitle,
            isDeleted: false,
            meeting: {
              ...meeting,
              user: meeting.user
                ? typeof meeting.user === 'string'
                  ? (findUser(meeting.user) as any)
                  : meeting.user
                : meeting.user,
              participant: participant,
            },
          });
        } else {
          updatedTasks.set(task.id, {
            sourceTitle: 'Deleted meeting',
            isSourceDeleted: true,
          });
          sources.set(task.sourceId, {
            title: 'Deleted meeting',
            isDeleted: true,
            meeting: undefined,
          });
        }
      }
    });

    // Update tasks state
    setTasks((prevTasks) =>
      prevTasks.map((task) => {
        const update = updatedTasks.get(task.id);
        return update ? {...task, ...update} : task;
      }),
    );
  }, [
    filteredTasks,
    sources,
    goalsMap,
    meetingsMap,
    getGoalDetails,
    meetingsController,
    findUser,
    setTasks,
  ]);

  return handleSource;
};
