import {IMeetingResponse} from '@hooks';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {Button} from '@ui/atoms/button';
import {SelectField} from '@ui/molecules/select/selectfield';
import {DatePicker} from '@ui/molecules/date-picker';
import {FREQUENCY_OPTIONS} from '../create-meetings/options';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import dayjs from 'dayjs';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {Body2, Headline3, CTA} from '@ui/atoms/typography';
import {useState, memo} from 'react';
import {Label} from '@ui/atoms/label';
import {TimeSelect} from '@ui/atoms/time-select';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import {RecurringMeetingSettings} from './recurring-meeting';
import {Checkbox} from '@ui/atoms/checkbox';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {GlobeIcon} from '@ui/atoms/icons/globe';
import {FlexRowSpaceBetween, FlexRow, FlexRowCenter} from '@ui/style/styles';

import {
  useBeamMeetingAIConfiguration,
  useMeetingUpdate,
  useParticipants,
  useTimezone,
  useRescheduleMeetingForm,
  useTimeManagement,
} from './reschedule-meeting-hook';
import {useStoreContext} from '@store/store-context';
import {TrashIcon} from '@ui/atoms/icons/trash';

interface RescheduleProps {
  meeting: IMeetingResponse;
  open: boolean;
  onClose: () => void;
  handleShowDeleteModal?: () => void;

  onUpdated?: () => void;
}

export const RescheduleMeeting = memo(
  ({
    open,
    meeting,
    onUpdated,
    onClose,
    handleShowDeleteModal,
  }: RescheduleProps) => {
    const [showRecurringSettings, setShowRecurringSettings] = useState(false);
    const isRecurring = meeting.frequency !== 'once';

    const {
      authStore: {auth},
    } = useStoreContext();

    const {timeZone} = useTimezone();

    const {MEETING_WITH} = useParticipants(meeting.participant);

    const {values, isUpdated, handleFormValueChange} = useRescheduleMeetingForm(
      meeting,
    );
    const {
      selectedTime,
      handleAllDayToggle,
      handleDateChange,
      updateSelectedTime,
    } = useTimeManagement(values, handleFormValueChange);

    const {
      shouldAutoEnableAndDisable,
      disableUpdateBeamAI,
      isBeforeMeetingEnd,
    } = useBeamMeetingAIConfiguration(meeting);

    const {loading, onSubmit} = useMeetingUpdate(
      meeting,
      values,
      onClose,
      onUpdated,
    );

    // Computed values
    const isOwner = meeting.user?.id === auth.user.id;
    const canEditOthers = isOwner && isBeforeMeetingEnd;

    const enableNextButton =
      values.startDate &&
      values.frequency &&
      isUpdated &&
      !selectedTime.ends.error &&
      !selectedTime.starts.error &&
      values.title;

    const handleRecurringConfirm = (editType: string) => {
      onSubmit(editType);
    };

    return (
      <DrawerModal open={open} onClose={onClose}>
        <Headline3>Meeting settings</Headline3>
        <VerticalSpacer size="32px" />

        <Label>Meeting topic</Label>
        <VerticalSpacer size="8px" />

        <TextField
          inputStyle={{borderRadius: '10px'}}
          value={values.title}
          disabled={!isOwner}
          placeholder="e.g. Weekly catch up "
          onChange={(event) => {
            handleFormValueChange(event.target.value, 'title');
          }}
        />

        {meeting.status !== 'completed' && (
          <>
            <Label>Auto record</Label>

            <Body2 kind="textBody" className="mt-1">
              Set the BeamAI to join, record and summarise your events
              automatically.
            </Body2>

            <div className="border border-[#CECEDE] mt-1 py-[14px] px-4 rounded-[10px] gap-2 flex-row items-center flex">
              {shouldAutoEnableAndDisable ? (
                <CustomColorIcon
                  color="#47B881"
                  width="12.8px"
                  height="12.8px"
                  margin={0}
                />
              ) : (
                <ToggleSwitch
                  checked={values.enableBeamAI}
                  onChange={() =>
                    handleFormValueChange(!values.enableBeamAI, 'enableBeamAI')
                  }
                />
              )}
              <CTA kind={disableUpdateBeamAI ? 'textMuted' : 'textDark'}>
                {shouldAutoEnableAndDisable
                  ? 'BeamAI will join'
                  : 'Invite BeamAI to join'}
              </CTA>
            </div>
          </>
        )}
        <VerticalSpacer size="24px" />

        <Label>Add/remove members from this meeting</Label>

        <MultiOptionsSelectField
          name="participant"
          defaultValue={values.participant.map((participant: string) => ({
            value: participant,
          }))}
          onChange={(data: {value: string}) => {
            if (Array.isArray(data)) {
              handleFormValueChange(
                data?.map((option) => option.value),
                'participant',
              );

              return;
            }
            handleFormValueChange(data?.value, 'participant');
          }}
          placeholder={`Select members`}
          searchPlaceholder={'Search'}
          options={MEETING_WITH}
          fieldNotFoundPlaceHolder={(searchTerm?: string) =>
            `Oops! Seems there is no ${searchTerm} found in this workspace`
          }
        />

        {canEditOthers && (
          <>
            <FlexRowSpaceBetween>
              <Label>At what date?</Label>
              <FlexRow className="gap-2">
                <Checkbox
                  checked={values.isAllDay}
                  onChange={(e) => handleAllDayToggle(e.target.checked)}
                />
                <Body2>All day</Body2>
              </FlexRow>
            </FlexRowSpaceBetween>

            <VerticalSpacer size="8px" />

            <DatePicker
              label="Date"
              value={values.startDate}
              onChange={(date) => handleDateChange(date, 'startDate')}
              disabledBefore={dayjs().subtract(1, 'day').format()}
            />

            {values.isAllDay && (
              <>
                <VerticalSpacer size="16px" />
                <Label>End Date</Label>
                <VerticalSpacer size="8px" />
                <DatePicker
                  label="Date"
                  value={values.endDate}
                  onChange={(date) => handleDateChange(date, 'endDate')}
                  disabledBefore={values.startDate}
                />
              </>
            )}

            {!values.isAllDay && (
              <>
                <VerticalSpacer size="24px" />
                <FlexRowSpaceBetween className="mb-2">
                  <Label>At what time?</Label>
                  {timeZone && (
                    <FlexRow className="gap-2">
                      <GlobeIcon />
                      <Body2>{timeZone}</Body2>
                    </FlexRow>
                  )}
                </FlexRowSpaceBetween>

                <div className="grid grid-cols-2 gap-4">
                  <TimeSelect
                    selectedTime={selectedTime.starts}
                    type="starts"
                    updateSelectedTime={updateSelectedTime}
                  />
                  <TimeSelect
                    selectedTime={selectedTime.ends}
                    type="ends"
                    updateSelectedTime={updateSelectedTime}
                  />
                </div>
              </>
            )}

            <VerticalSpacer size="24px" />
            <Label>Meeting frequency</Label>
            <VerticalSpacer size="8px" />

            <SelectField
              name="user"
              defaultValue={values.frequency}
              excludeSearchBox
              onChange={(data: {value: string}) => {
                handleFormValueChange(data?.value, 'frequency');
              }}
              borderRadius="10px"
              placeholder="Select frequency"
              options={FREQUENCY_OPTIONS}
              fieldNotFoundPlaceHolder={(searchTerm?: string) =>
                `Oops! Seems there is no ${searchTerm} found in this workspace`
              }
            />
          </>
        )}

        <Button
          width="full"
          onClick={() =>
            isRecurring ? setShowRecurringSettings(true) : onSubmit()
          }
          isLoading={loading}
          disabled={!enableNextButton}>
          Save
        </Button>

        {handleShowDeleteModal && (
          <FlexRowCenter className="mt-10">
            <button onClick={() => handleShowDeleteModal()}>
              <FlexRowCenter className="gap-1">
                <TrashIcon fill="#D52A2A" />
                <Body2 kind="red400" weight="semibold">
                  Delete Event
                </Body2>
              </FlexRowCenter>
            </button>
          </FlexRowCenter>
        )}

        {showRecurringSettings && (
          <RecurringMeetingSettings
            isOpen={showRecurringSettings}
            onClose={() => setShowRecurringSettings(false)}
            onConfirm={handleRecurringConfirm}
          />
        )}
      </DrawerModal>
    );
  },
);
