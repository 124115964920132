import React, {FC, useState, useMemo, useCallback, memo} from 'react';
import {Avatar} from '../avatar';
import {IAPIUserFormat} from '@hooks';
import {userName} from '@utils/user-name';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {UserProfile} from '../icons/user-profile';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Body2} from '../typography';
import {SearchBox2} from '@ui/molecules/search-box';
import {CancelIcon} from '../icons';
import {PlainButton} from '../plain-button/plain-button';

interface FieldUserProps {
  user?: IAPIUserFormat;
  assigneeOptions?: {label: string; value: string; avatar?: any}[];
  disabled?: boolean;
  handleChange?: (user: string) => void;
}

export const FieldUser: FC<FieldUserProps> = memo(
  ({user, assigneeOptions, disabled, handleChange}) => {
    const [searchValue, setSearchValue] = useState('');

    const computeUsers = useMemo(
      () =>
        assigneeOptions &&
        assigneeOptions.filter((option) =>
          option.label.toLowerCase().includes(searchValue),
        ),
      [assigneeOptions, searchValue],
    );

    const handleClear = useCallback(() => {
      handleChange && handleChange('');
    }, [handleChange]);

    const menuUser = useCallback(
      (handleClose: () => void) => (
        <div className="mx-1">
          <Body2 weight="semibold">Assigned to</Body2>
          <FlexRowSpaceBetween className="mt-4">
            <FlexRow className="gap-4 ">
              <Avatar
                src={user?.avatar ? user.avatar.url : ''}
                name={userName(user)}
                userId={user?.id || ''}
                tooltip={true}
              />
              <Body1>{userName(user)}</Body1>
            </FlexRow>
            <PlainButton
              onClick={() => {
                handleClear();
                handleClose();
              }}>
              <CancelIcon />
            </PlainButton>
          </FlexRowSpaceBetween>
        </div>
      ),
      [user, handleClear],
    );

    const customComponentUser = useCallback(
      (handleOpen: (event: any) => void) => (
        <button disabled={disabled} onClick={handleOpen}>
          <Avatar
            src={user?.avatar ? user.avatar.url : ''}
            name={userName(user)}
            userId={user?.id || ''}
            tooltip={true}
          />
        </button>
      ),
      [user, disabled],
    );

    const menuNoUser = useCallback(
      (handleClose: () => void) => (
        <div>
          <div className="mx-2 mb-2">
            <SearchBox2
              placeholder="Search member"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              handleCancel={() => setSearchValue('')}
            />
          </div>
          <div>
            {computeUsers &&
              computeUsers.map((option) => (
                <DropdownItem
                  key={option.value}
                  onClick={() => {
                    handleClose();
                    handleChange && handleChange(option.value);
                  }}>
                  <FlexRow className="gap-4">
                    <Avatar
                      src={option?.avatar ? option.avatar.url : ''}
                      name={option.label}
                      userId={option.value || ''}
                      tooltip={true}
                    />
                    <Body1>{option.label}</Body1>
                  </FlexRow>
                </DropdownItem>
              ))}
          </div>
        </div>
      ),
      [computeUsers, handleChange, searchValue],
    );

    const customComponentNoUser = useCallback(
      (handleOpen: (event: any) => void) => (
        <button disabled={disabled} onClick={handleOpen}>
          <UserProfile />
        </button>
      ),
      [disabled],
    );

    const customDropdownWrapperStylesUser = useMemo(
      () => ({
        top: '130%',
        maxHeight: '300px',
        right: 0,
        minWidth: '400px',
      }),
      [],
    );

    const customDropdownWrapperStylesNoUser = useMemo(
      () => ({
        top: '-700%',
        padding: '10px 0px',
        maxHeight: '310px',
        right: 0,
        width: '290px',
      }),
      [],
    );

    if (user) {
      return (
        <DropdownCustom
          collapseOnClick={true}
          menu={menuUser}
          customComponent={customComponentUser}
          customDropdownWrapperStyles={customDropdownWrapperStylesUser}
        />
      );
    }

    return (
      <div className="relative">
        <DropdownCustom
          collapseOnClick={true}
          menu={menuNoUser}
          customComponent={customComponentNoUser}
          customDropdownWrapperStyles={customDropdownWrapperStylesNoUser}
        />
      </div>
    );
  },
);
