import {ReactElement} from 'react';
import {EntryComponentProps} from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry';
import {MentionContainer} from '../comments-style';

export function Entry(props: EntryComponentProps): ReactElement {
  const {mention, theme, isFocused, ...parentProps} = props;

  return (
    <MentionContainer {...parentProps}>
      <div
        className={'mentionSuggestionsEntryContainer'}
        style={
          isFocused
            ? {
                background: '#F6F6F8',
                color: 'white',
                borderRadius: '8px',
                paddingLeft: '5px',
                zIndex: 5,
              }
            : {
                zIndex: 5,
              }
        }>
        <div></div>
        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div
            className={'mentionSuggestionsEntryText'}
            style={
              isFocused
                ? {
                    color: 'black',
                    fontWeight: 500,
                    zIndex: 5,
                  }
                : {
                    zIndex: 5,
                  }
            }>
            {mention.name}
          </div>
        </div>
      </div>
    </MentionContainer>
  );
}
