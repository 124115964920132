import {weekdays} from '@constants';
import {Button} from '@ui/atoms/button';
import {Headline4, CTA} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {SelectField} from '@ui/molecules/select/selectfield';
import {capitalize} from '@utils';
import {checkinName} from '@utils/checkin-name';

import React, {useState, FC} from 'react';
import {SectionComponentWrapper} from '../admin-checkins.styles';
import {useBreakpoints} from '@utils/use-breakpoints';

interface CheckinDueDateProps {
  value: string;
  errors?: any;
  handleFormValueChange: (data: any, value: string) => void;
}

export const CheckinDueDateSettings: FC<CheckinDueDateProps> = ({
  value = '',
  errors,
  handleFormValueChange,
}) => {
  const [isEditingCheckinsDueDay, setIsEditingCheckinsDueDate] = useState(
    false,
  );

  const {isXs} = useBreakpoints();

  return (
    <div>
      <SectionCard
        title="Due day"
        headerPadding={isXs ? 'small' : undefined}
        action={
          <Button
            kind={isEditingCheckinsDueDay ? 'primary' : 'secondary'}
            style={{padding: '8px 16px'}}
            onClick={() => {
              setIsEditingCheckinsDueDate(!isEditingCheckinsDueDay);
            }}
            type={!isEditingCheckinsDueDay ? 'submit' : 'button'}>
            {isEditingCheckinsDueDay ? 'Save' : 'Edit'}
          </Button>
        }
        contentStyling={{
          padding: isXs ? '0px' : '0px 0px 10px 0px',
        }}
        CustomHeaderTitle={
          <div className="flex items-center">
            <Headline4 kind="textDark">Due day </Headline4>
          </div>
        }>
        <SectionComponentWrapper>
          <>
            <CTA kind="textDark" className="mb-1">
              What day of the week should {checkinName()}s be due?
            </CTA>
            <SelectField
              margin
              value={value}
              helper={(errors?.generalFrequency as any)?.message || ''}
              state={(errors as any)?.generalFrequency ? 'error' : 'default'}
              onChange={(data: {value: string}) => {
                handleFormValueChange(data?.value, `generalFrequency.dueDay`);
              }}
              placeholder={'Friday'}
              options={weekdays.map((day) => ({
                ...day,
                value: capitalize(day?.value),
              }))}
              disabled={!isEditingCheckinsDueDay}
            />
          </>
        </SectionComponentWrapper>
      </SectionCard>
    </div>
  );
};
