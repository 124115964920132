import {useGCalenderHook} from '@hooks/gcalender';
import {Button} from '@ui/atoms/button';
import {GoogleCalenderIcon} from '@ui/atoms/icons/apps/google-calender';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2, Headline2, Headline3} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {ItemLoader} from '@ui/organisms/item-loader';
import {FlexRow, FlexRowCenter} from '@ui/style/styles';
import {memo, useCallback, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router';
import {IntegrationsController} from '../../components/app.controller';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {useDebouncedCallback} from 'use-debounce';
import {Helper} from '@ui/atoms/helper';
import {useOneTimeFetch} from '@hooks/query-hook';
import {RadioSm} from '@ui/atoms/radio';
import {observer} from 'mobx-react';

const beamAIOptions = [
  {
    value: 'all-meetings-with-video-link',
    label: 'All meetings on my calendar with a video link',
  },
  {
    value: 'organized-by-me',
    label: 'All meetings organised by me',
  },
  {
    value: 'manual-toggle',
    label: 'Only meetings I manually toggle',
  },
];

const recapMeetingOptions = [
  {
    value: 'all',
    label: 'All attendees',
  },
  {
    value: 'beam-attendees',
    label: 'All attendees in Beam',
  },
  {
    value: 'me',
    label: 'Just me',
  },
];

export const EditGoogleCalender = observer(() => {
  const navigate = useNavigate();

  const breadcrumbNav = useMemo(() => {
    const nav = [
      {
        title: 'Apps',
        href: '#',
        id: '1',
        onClick: () => {
          navigate(-1);
        },
      },
      {
        title: 'Meetings settings',
        href: '#',
        id: '2',
        isCurrent: true,
        onClick: () => {},
      },
    ];
    return nav;
  }, [navigate]);

  const {
    handleAuthClick,
    isSigned,
    handleLogOut,
    googleRefreshToken,
    loader,
    getEmail,
    isCalendarConnected,
    fetchGoogleToken,
    setConnectedScope,
    calendarScope,
    setIsSigned,
    connectedScope,
  } = useGCalenderHook();

  const {data: connectedEmail = ''} = useQuery({
    queryFn: getEmail,
    queryKey: 'google-email',
    enabled: isSigned,
  });

  const {isLoading: loadingScope} = useQuery({
    queryFn: fetchGoogleToken,
    queryKey: 'google-scope',
  });

  const scopeError =
    !!connectedScope &&
    isSigned &&
    !connectedScope.includes(calendarScope) &&
    !loadingScope;

  const [selectedBeamAiOptions, setSelectedBeamAiOptions] = useState<string[]>([
    'all-meetings-with-video-link',
  ]);

  const [selectedRecapOptions, setSelectedRecapOptions] = useState<string[]>([
    'all',
  ]);

  const getActiveIntegrations = useCallback(async () => {
    const controller = new IntegrationsController();
    const response = await controller.getIntegrations();

    if (response) {
      const googleCalenderApp = response?.find(
        (app: {app: string}) => app.app === 'Google Calendar',
      );

      if (googleCalenderApp && googleCalenderApp?.data) {
        setSelectedBeamAiOptions(googleCalenderApp.data.beamAI);

        setSelectedRecapOptions(googleCalenderApp.data.meetingRecap);
      }
      return response;
    }
  }, []);

  useOneTimeFetch('activeApps-Integrations', getActiveIntegrations);

  const handleUpdateSettings = async () => {
    const controller = new IntegrationsController();

    const response = await controller.editAppIntegrations(
      {
        beamAI: selectedBeamAiOptions,
        meetingRecap: selectedRecapOptions,
      },
      'Google Calendar',
    );

    if (response) {
      activateNotification({
        content: 'Config saved successfully',
        title: 'Success',
        kind: 'success',
      });
    }
  };

  const updateSettings = useDebouncedCallback(handleUpdateSettings, 2000);

  const textColor = !isCalendarConnected ? 'textMuted' : 'textDark';

  const subTextColor = !isCalendarConnected ? 'textMuted' : 'textBody';

  const handleConnect = async () => {
    const controller = new IntegrationsController();

    await handleAuthClick().then(async (response: any) => {
      if (response.scope?.includes(calendarScope)) {
        setConnectedScope(response.scope);
        setIsSigned(true);

        await controller.postIntegrations({
          app: 'Google Calendar',
          token: response?.refresh_token || googleRefreshToken,
          data: {
            beamAI: selectedBeamAiOptions,
            meetingRecap: selectedRecapOptions,
          },
        } as any);
      }
    });
  };

  const handleDisconnect = async () => {
    const controller = new IntegrationsController();

    handleLogOut();

    await controller.deleteIntegrations('Google Calendar');
    setConnectedScope('');
    setIsSigned(false);
  };

  return (
    <div className="">
      <div className="border-b border-b-borderLight">
        <SecondaryBreadcrumb
          wrapperStyles={{padding: '16px 32px 12px', marginBottom: 0}}
          navigation={breadcrumbNav}
        />
      </div>

      <div className="py-8 pl-8">
        <Headline2 className="mb-4">Meeting settings</Headline2>

        <SectionCard
          contentStyling={{padding: '16px'}}
          CustomHeaderTitle={
            <div>
              <Body1 weight="bold" data-testid="summary-text">
                Calendar
              </Body1>
            </div>
          }
          variant="columnStart">
          <div className="p-4 border rounded-[10px] border-borderLight flex items-center justify-between ">
            <div>
              <FlexRow className="gap-3">
                <FlexRowCenter className="rounded-full border border-borderLight w-10 h-10 ">
                  <GoogleCalenderIcon />
                </FlexRowCenter>

                <Headline3>Google Calendar</Headline3>
              </FlexRow>{' '}
              {isSigned && (
                <Body2 kind="textBody" className="mt-4">
                  Connected to {connectedEmail}
                </Body2>
              )}
              {scopeError && (
                <Helper
                  state="error"
                  children={
                    'Google Calendar access not granted. Please reconnect and grant the required permissions to continue.'
                  }
                />
              )}
            </div>

            <CustomButton
              isSigned={isSigned}
              needsReconnection={scopeError}
              loader={loader}
              onDisconnect={handleDisconnect}
              onConnect={handleConnect}
            />
          </div>
        </SectionCard>
        <VerticalSpacer size="24px" />

        <SectionCard
          contentStyling={{padding: '16px'}}
          CustomHeaderTitle={
            <div>
              <Body1 weight="bold" kind={textColor} data-testid="summary-text">
                BeamAI
              </Body1>
              <Body2 kind={subTextColor}>Meetings BeamAI will join</Body2>
            </div>
          }
          variant="columnStart">
          <div className="gap-3">
            {beamAIOptions.map((option) => (
              <FlexRow key={option.value} className="gap-2">
                <RadioSm
                  onChange={() => {
                    if (isCalendarConnected) {
                      setSelectedBeamAiOptions([option.value]);

                      updateSettings();
                    }
                  }}
                  checked={
                    isCalendarConnected
                      ? selectedBeamAiOptions.includes(option.value)
                      : false
                  }
                  readOnly={!isCalendarConnected}
                />
                <Body1 kind={textColor}>{option.label}</Body1>
              </FlexRow>
            ))}
          </div>
        </SectionCard>

        <VerticalSpacer size="24px" />

        <SectionCard
          contentStyling={{padding: '16px'}}
          CustomHeaderTitle={
            <div>
              <Body1 weight="bold" kind={textColor} data-testid="summary-text">
                Meeting recaps
              </Body1>
              <Body2 kind={subTextColor}>Send recaps to</Body2>
            </div>
          }
          variant="columnStart">
          <div className="gap-3">
            {recapMeetingOptions.map((option) => (
              <FlexRow key={option.value} className="gap-2">
                <RadioSm
                  onChange={() => {
                    if (isCalendarConnected) {
                      setSelectedRecapOptions([option.value]);
                      updateSettings();
                    }
                  }}
                  checked={
                    isCalendarConnected
                      ? selectedRecapOptions.includes(option.value)
                      : false
                  }
                  readOnly={!isCalendarConnected}
                />

                <Body1 kind={textColor}>{option.label}</Body1>
              </FlexRow>
            ))}
          </div>
        </SectionCard>
      </div>
    </div>
  );
});

interface CustomButtonProps {
  onConnect: () => void;
  onDisconnect: () => void;
  loader: boolean;
  needsReconnection: boolean;
  isSigned: boolean;
}

const CustomButton = memo(
  ({
    loader,
    isSigned,
    onConnect,
    needsReconnection,
    onDisconnect,
  }: CustomButtonProps) => {
    if (loader) {
      return <ItemLoader />;
    }

    if (needsReconnection) {
      return (
        <Button
          kind="secondary"
          className="min-w-[118px] transform"
          style={{padding: '6px 16px'}}
          onClick={() => {
            onDisconnect();
            onConnect();
          }}>
          Re-connect
        </Button>
      );
    }
    if (isSigned) {
      return (
        <Button
          onClick={onDisconnect}
          kind="secondary"
          style={{padding: '6px 16px', columnGap: '5px'}}>
          Disconnect
        </Button>
      );
    }

    return (
      <Button
        kind="secondary"
        className="min-w-[118px] transform"
        style={{padding: '6px 16px'}}
        onClick={onConnect}>
        Connect
      </Button>
    );
  },
);
