import {SectionCard} from '@ui/layouts/section-card';
import {Headline3} from '@ui/atoms/typography';
import {useStoreContext} from '@store/store-context';
import {useCallback, useMemo, useState} from 'react';
import {useBreakpoints} from '@utils/use-breakpoints';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {userName} from '@utils/user-name';
import {observer} from 'mobx-react';
import {useActionItems} from '@hooks/features-action-items';
import {useQuery} from 'react-query';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import {ViewActionItemsSource} from '@pages/dashboard/action-items/view-source';

type steps = {
  completed: boolean;
  text: string;
  updatedBy?: string;
  dueDate?: string;
  id?: string;
};
interface NextStepsProps {
  steps: steps[];
  isReadOnly?: boolean;
  meetingId?: string;
  canCopyItems?: boolean;
  meetingCode?: string;
  bringPreviousItems?: boolean;
  showSource?: boolean;
  handleCopyActionItems?: (
    steps: {text: string; completed: boolean}[],
    id: string,
    currentSteps: {text: string; completed: boolean}[],
  ) => void;
  type?: 'meeting-notes';
  updateMeeting: (data: any[]) => Promise<void>;
  users: string[];
}
export const NextSteps = observer(
  ({
    meetingId = '',
    users: meetingUsers,
    bringPreviousItems,
    meetingCode = '',
    updateMeeting,
    steps,
    showSource,
    isReadOnly,
    type,
  }: NextStepsProps) => {
    const {
      authStore: {auth},
      usersStore: {users},
    } = useStoreContext();

    const {handleChange, tasks} = useActionItems({
      source: 'meeting',
      userId: '',
      itemId: meetingId,
      orderBy: {
        value: bringPreviousItems && meetingCode ? meetingCode : meetingId,
        key: bringPreviousItems && meetingCode ? 'meetingCode' : 'sourceId',
      },
    });

    const sources = useMemo(() => new Map(), []);

    const getSourceDetail = (sourceId: string) => {
      return sources.get(sourceId);
    };

    const [viewSource, setViewSource] = useState({
      id: '',
      source: '',
    });

    const sourceItem = !!viewSource.source
      ? viewSource.source === 'goal'
        ? (getSourceDetail(viewSource.id) as any)?.goal
        : (getSourceDetail(viewSource.id) as any)?.meeting
      : undefined;

    const findUser = useCallback(
      (id: string) => users.find((user) => user.id === id),
      [users],
    );

    const handleSource = useCallback(async () => {
      const updatedTasks = [...tasks];

      if (!showSource) return updatedTasks;

      const allTasks = await Promise.all(
        updatedTasks.map(async (task, index) => {
          if (
            task.source === 'meeting' &&
            !!task.sourceId &&
            task.isBroughtForwardTo === meetingId &&
            task.sourceId !== meetingId
          ) {
            const request = new MeetingRequestImpl();
            const meetingsController = new MeetingController(request);

            let meeting = await meetingsController.getMeeting(
              task.sourceId,
              undefined,
              true,
            );

            const participant =
              typeof meeting.participant[0] === 'string'
                ? findUser(meeting.participant[0])
                : meeting.participant[0];

            const sourceTitle = meeting
              ? meeting.title ||
                `1:1 meeting with ${userName(participant) || ''}`
              : 'Deleted meeting';

            updatedTasks[index].sourceTitle = sourceTitle;

            updatedTasks[index].isSourceDeleted = !meeting;

            sources.set(task.sourceId, {
              title: meeting
                ? meeting.title || `1:1 meeting with ${userName(participant)}`
                : 'Deleted meeting',

              isDeleted: !meeting,

              meeting: {
                ...meeting,
                participant: meeting?.participant ? participant : null,
              },
            });

            return {...task, sourceTitle};
          }
          return task;
        }),
      );

      return allTasks;
    }, [findUser, meetingId, showSource, sources, tasks]);

    const {data: allTasks = tasks} = useQuery(['sources', tasks], () =>
      handleSource(),
    );

    const computeSteps = useMemo(() => {
      if (type === 'meeting-notes') {
        return steps.map((step) => ({...step, key: step.id}));
      }

      if (bringPreviousItems) {
        return allTasks.filter((task) =>
          task.sourceId !== meetingId && task.isBroughtForwardTo !== meetingId
            ? !task.completed
            : true,
        );
      }

      return allTasks;
    }, [bringPreviousItems, meetingId, steps, allTasks, type]);

    const {isXs} = useBreakpoints();

    return (
      <div>
        <SectionCard
          title="What are the next steps?"
          headerPadding={isXs ? 'small' : undefined}
          contentStyling={{padding: isXs ? '12px' : '24px 24px 24px 16px'}}
          CustomHeaderTitle={
            <div>
              <Headline3 kind="textDark">Action items</Headline3>
            </div>
          }>
          <div>
            <ActionItems
              isReadOnly={isReadOnly}
              disableCheckbox={isReadOnly}
              showSource={showSource}
              handleChange={async (value, actionType, fieldId, item) => {
                if (type === 'meeting-notes') {
                  return await updateMeeting(value);
                }

                const updatedItem: any = {
                  ...item,
                  meetingCode,
                  assignee:
                    actionType === 'user'
                      ? item?.assignee
                      : item?.assignee || auth.user.id,
                };

                if (bringPreviousItems)
                  updatedItem.isBroughtForwardTo = meetingId;

                await handleChange(
                  value,
                  actionType,
                  fieldId,
                  updatedItem as any,
                );

                await updateMeeting(
                  value.map((value) => ({
                    text: value.text,
                    completed: value.completed,
                    // assignee: value.assignee,
                  })),
                );
              }}
              users={users
                .filter((user) => meetingUsers.includes(user.id))
                .map((user) => ({
                  label: userName(user),
                  value: user.id,
                  avatar: user.avatar,
                }))}
              source="meeting"
              sourceId={''}
              canDelete={(fieldId) => {
                const task = tasks.find((task) => task.key === fieldId);

                if (task) {
                  // allow admin delete action item
                  if (auth.user.role === 'admin' && !!task) {
                    return true;
                  }

                  // allow manager of task users delete task
                  const usersInvolved = users
                    .filter((user) =>
                      [task?.user, task?.updatedBy, task?.assignee].includes(
                        user.id,
                      ),
                    )
                    .map((user) => user.reviewer.id);

                  if (usersInvolved.includes(auth.user.id)) {
                    return true;
                  }
                }
                return false;
              }}
              value={computeSteps || []}
              userId={auth.user.id}
              handleViewSource={(data) =>
                data.source !== 'url' ? setViewSource(data) : null
              }
            />
          </div>
        </SectionCard>

        {!!viewSource.id && (
          <ViewActionItemsSource
            userName={userName(auth.user)}
            onClose={() => setViewSource({id: '', source: ''})}
            type={viewSource.source}
            goal={sourceItem}
            meeting={sourceItem}
          />
        )}
      </div>
    );
  },
);
