import {makeAutoObservable, observable, action} from 'mobx';
import {TNotificationProps} from '@ui/interface';

export class NotificationStore {
  @observable showNotification = false;
  @observable appIsOffline = false;

  @observable notificationData: any = {
    content: '',
    kind: '',
    title: '',
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action handleShowNotification = (show: boolean) => {
    this.showNotification = show;
  };

  @action handleAppNetwork = (status: boolean) => {
    this.appIsOffline = status;
  };
  @action setNotificationData = (data: TNotificationProps) => {
    this.notificationData = data;
  };
}

export const notificationStore = new NotificationStore();
