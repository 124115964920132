import {useNavigate} from 'react-router';
import {FC, memo, useMemo, useState} from 'react';
import {Button} from '@ui/atoms/button';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2, Headline2, Headline3} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {userName} from '@utils/user-name';
import {useStoreContext} from '@store/store-context';
import {CreateMeetingNoteData, useCreateMeetingNoteHook} from './meeting-hook';
import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {PageWrapper} from '../create-meetings/styles';
import {
  FlexRow,
  FlexRowCenter,
  FlexRowSpaceBetween,
  ResponsiveFlexRow,
} from '@ui/style/styles';
import {MeetingForm} from './meeting-form';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {
  ManagerViewUser,
  MobileWrapper,
  ModalWrapper,
  ResponsiveFlexStart,
} from '../view-meetings/styles';
import {Avatar} from '@ui/atoms/avatar';
import {DesktopWrapper} from '@ui/organisms/meetings/meeting-card/meeting-card';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import dayjs from 'dayjs';
import {authStore} from '@store/stores/auth-store';
import {Notes} from '../view-meetings/components/notes';
import {useFeedbackHook} from '../view-meetings/components/feedback-hook';
import {Feedback} from '../view-meetings/components/feedback';
import {ViewUser} from '../view-meetings/components/view-user';
import {useBreakpoints} from '@utils/use-breakpoints';
import styled from 'styled-components';
import {capitalize} from '@utils';
import {checkinName} from '@utils/checkin-name';
import {NextSteps} from '../view-meetings/components/next-steps';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {ArrowHeadDownIcon, PlusIcon} from '@ui/atoms/icons';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {MeetingRequestImpl} from '../meeting.request';
import {MeetingController} from '../meeting-controller';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {Templates} from '@ui/organisms/templates';
import {ModalCard} from '@ui/layouts/modal-card';
import {useQuery} from 'react-query';
import {Modal} from '@ui/molecules/modal';
import {MeetingAgenda} from '../view-meetings/components/agenda';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {ItemLoader} from '@ui/organisms/item-loader';

export const FormWrapper = styled.div<{wideWidth: boolean; split?: boolean}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({split}) => (split ? 'space-between' : 'center')};
  height: ${({split}) => (split ? '90vh' : '100%')};
  overflow: ${({split}) => (split ? 'hidden' : '100%')};
  margin-top: ${({split}) => (split ? '30px' : '0px')};

  position: relative;
  @media (max-width: 768px) {
    padding: 0px 16px;
  }
  .split-form {
    width: 50%;
    opacity: 0.5;
    padding: 3% 2% 0%;
    border-right: 1px solid #ededf2;
    pointer-events: none;

    .summary-grid {
      grid-template-columns: 50% 50%;
    }

    @media (min-width: 1100px) and (max-width: 1320px) {
      padding: 3% 4% 0%;

      width: 50%;
    }

    @media (min-width: 1000px) and (max-width: 1100px) {
      padding: 3% 3% 0%;

      width: 50%;
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .form {
    width: ${({wideWidth}) => (wideWidth ? '60%' : '35%')};
    margin-top: 90px;
    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: ${({wideWidth}) => (wideWidth ? '60%' : '40%')};
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const MeetingNotes = () => {
  const navigate = useNavigate();

  const [showTemplates, setShowTemplates] = useState(false);

  const {
    handleFormValueChange,
    values,
    onSubmit,
    participant,
    isLoading: isSubmitting,
    loading,
  } = useCreateMeetingNoteHook();

  const [activeTab] = useState({
    create: !participant,
    addNote: !!participant,
  });

  const request = new MeetingRequestImpl();
  const controller = new MeetingController(request);

  const [limit, setLimit] = useState(10);

  const {isSm, isMd, isXs} = useBreakpoints();

  const {data, isLoading} = useQuery(['agenda-templates', limit], () =>
    controller.getAgendaTemplates(limit),
  );

  const {
    feedbackValues,
    handleFeedbackFormValueChange,
    feedbackError,
  } = useFeedbackHook();

  const TemplatesComponents = () => (
    <Templates
      title="Browse meeting templates"
      onClose={() => setShowTemplates(false)}
      isLoading={isLoading}
      templateName="Agenda"
      totalResults={data?.totalResults || 10}
      handleClick={(data) => {
        const agendas = data.children.map((agenda) => ({
          text: agenda,
          completed: false,
        }));

        handleFormValueChange(agendas as any, 'hostAgenda');

        setShowTemplates(false);
      }}
      templates={
        data?.results.map((result) => ({
          children: result.agenda,
          id: result.id,
          title: result.title,
        })) || []
      }
      templatesType={[
        {
          value: '1:1',
          label: '1-on-1s',
        },
      ]}
      limit={limit}
      updateLimit={(value) => setLimit(value)}
    />
  );

  if (isSubmitting) {
    return (
      <FlexRowCenter style={{width: '100%', height: '100vh'}}>
        <ItemLoader />
      </FlexRowCenter>
    );
  }
  return (
    <PageWrapper>
      <PageLayoutTemplate
        title="Add Meeting Note"
        guideUrl="https://peoplebeam.myhcpage.com/en-us/"
        onClick={() => {
          navigate(-1);

          if (activeTab.addNote) {
            onSubmit(feedbackValues);
          }
        }}
        bodyStyle={{padding: 0, margin: 0, marginTop: '5px'}}>
        <FormWrapper wideWidth={activeTab.addNote} split={showTemplates}>
          <div className={showTemplates ? 'split-form' : 'form'}>
            {activeTab.create && (
              <div>
                <MeetingForm
                  values={values}
                  handleFormValueChange={handleFormValueChange}
                />
                <Button
                  width="full"
                  className="mt-8"
                  isLoading={loading}
                  disabled={!values.participant || !values.startDate}
                  onClick={() => onSubmit()}>
                  Next
                </Button>
              </div>
            )}

            {activeTab.addNote && (
              <>
                <div>
                  <MeetingNote
                    handleShowTemplates={() => setShowTemplates(true)}
                    handleFormValueChange={handleFormValueChange}
                    values={values}
                    feedbackValues={feedbackValues}
                    feedbackError={feedbackError}
                    handleFeedbackFormValueChange={
                      handleFeedbackFormValueChange
                    }
                    onSubmit={async (feedback) => onSubmit(feedback)}
                    loading={loading}
                  />
                </div>
              </>
            )}
          </div>

          {showTemplates && (
            <>
              <DesktopWrapper>
                <TemplatesComponents />
              </DesktopWrapper>

              <MobileWrapper>
                <Modal open={!!isSm || !!isMd || !!isXs} onClose={() => null}>
                  <ModalWrapper>
                    <ModalCard title="">
                      <TemplatesComponents />
                    </ModalCard>
                  </ModalWrapper>
                </Modal>
              </MobileWrapper>
            </>
          )}
        </FormWrapper>
      </PageLayoutTemplate>
    </PageWrapper>
  );
};

interface IMeetingAgenda {
  onSubmit: (feedbackValues: {
    feedbackValue: string;
    impression: string;
    feedbackSharingType: string;
    values: string[];
  }) => Promise<any>;
  loading: boolean;
  handleShowTemplates?: () => void;
  values: CreateMeetingNoteData;
  handleFeedbackFormValueChange: any;
  feedbackError: any;
  feedbackValues: any;
  handleFormValueChange: (value: string | any, name: string) => void;
}

const MeetingNote: FC<IMeetingAgenda> = memo(
  ({
    values,
    handleFormValueChange,
    loading,
    onSubmit,
    feedbackValues,
    handleShowTemplates,
    feedbackError,
    handleFeedbackFormValueChange,
  }) => {
    const {isXs} = useBreakpoints();

    const [showViewUser, setShowViewUser] = useState<
      'objective' | 'check-in' | 'feedback' | '' | '1:1'
    >('');

    const {
      usersStore: {users},
    } = useStoreContext();

    const {
      isPerformanceActive,
      isCheckinsEnabled,
      isFeedBackEnabled,
    } = useWorkspaceUseCase();

    const managerViewUserOptions = useMemo(() => {
      const options = [];

      if (isPerformanceActive) {
        options.unshift({
          value: 'objective',
          label: 'Goals',
        });
      }

      if (isCheckinsEnabled) {
        options.push({
          value: 'check-in',
          label: `${capitalize(checkinName())}s`,
        });
      }
      if (isFeedBackEnabled) {
        options.push({
          value: 'feedback',
          label: 'Feedback',
        });
      }
      options.push({
        value: '1:1',
        label: '1:1s',
      });
      return options;
    }, [isPerformanceActive, isFeedBackEnabled, isCheckinsEnabled]);

    const findField = (key: string) => {
      return values.nextSteps.find((field) => field.key === key);
    };

    const computeActionItem = (data: any, removeSource?: boolean) => {
      const assignee = data?.assignee;
      const id = data?.id || data?.key || generateRandomDigits(24);

      const computedItem = {
        completed: data.completed,
        id,
        meta: findField(id)?.meta || null,
        text: data.text,
        source: removeSource ? '' : findField(id)?.source || 'meeting',
        sourceId: removeSource
          ? ''
          : findField(id)?.sourceId || data.sourceId || '',
        updatedBy: data.updatedBy || authStore.auth.user.id,
        createdBy:
          findField(id)?.createdBy || data.createdBy || authStore.auth.user.id,
        order: data?.order || findField(data.key)?.order || null,
        dueDate: data?.dueDate || null,
        createdAt:
          findField(id)?.createdAt || data?.createdAt || dayjs().format(),
        user: data?.user || findField(id)?.userId || authStore.auth.user.id,
        assignee:
          typeof assignee === 'string' ? assignee : assignee?.id || null,
      };

      return computedItem;
    };

    const [leaveFeedback, setLeaveFeedback] = useState(false);

    const participant = users.find((user) => user.id === values.participant[0]);

    return (
      <div>
        <Headline2>Notes with {userName(participant)}</Headline2>
        <VerticalSpacer size="8px" />

        <Body2 kind="textBody">
          Write your agenda, action points and take notes for this meeting.
        </Body2>
        <VerticalSpacer size="24px" />
        <ManagerViewUser className="bg-white mb-6">
          <ResponsiveFlexStart>
            <ResponsiveFlexRow>
              <FlexRow className="first-child">
                <Avatar
                  src=""
                  name={userName(participant)}
                  isUserDeleted={!!participant?.deletedAt}
                  userId={participant?.id || ''}
                  tooltip={true}
                />
                <HorizontalSpacer size="16px" />
                <Body1 kind="textDark" weight="bold">
                  {participant?.firstName}`s growth journey
                </Body1>
              </FlexRow>

              <DesktopWrapper>
                <HorizontalSideRule size="16px" />
              </DesktopWrapper>
              <FlexRow>
                <Body2 kind="textBody">
                  {dayjs(values.startDate).format('D MMM, YYYY')}
                </Body2>
              </FlexRow>
            </ResponsiveFlexRow>
            <DropdownCustom
              collapseOnClick={true}
              menu={(handleClose: () => void) => (
                <>
                  {managerViewUserOptions.map((options, idx) => (
                    <DropdownItem
                      key={idx}
                      onClick={() => {
                        setShowViewUser(options.value as any);
                      }}
                      style={{marginRight: '12px'}}>
                      {options.label}
                    </DropdownItem>
                  ))}
                </>
              )}
              customComponent={(
                handleOpen: (event: any) => void,
                open: boolean,
              ) => (
                <>
                  <Button
                    kind="secondary"
                    onClick={(event) => {
                      handleOpen(event as any);
                    }}
                    style={{padding: '8px 16px'}}>
                    <FlexRowSpaceBetween style={{width: '100%'}}>
                      <Body2 weight="bold">View history</Body2>

                      <ArrowHeadDownIcon
                        style={{
                          marginLeft: 14,
                          stroke: 'black',
                          transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                      />
                    </FlexRowSpaceBetween>
                  </Button>
                </>
              )}
              customDropdownWrapperStyles={{
                top: '130%',
                right: 0,
                width: '100%',
              }}
            />
          </ResponsiveFlexStart>
        </ManagerViewUser>

        {participant && (
          <MeetingAgenda
            handleUseTemplates={handleShowTemplates}
            isMeetingActive={true}
            isHostSharing={values.shareHostAgenda}
            excludeComments
            users={{
              user: authStore.auth.user.id,
              participant: [participant.id],
            }}
            participantSharing={[]}
            updateMeeting={async (value, field) => {
              handleFormValueChange(value, field);
            }}
            agendas={{
              host: {
                user: authStore.auth.user,
                agenda: values.hostAgenda,
              },
              participant: {
                user: [participant],
                agenda: [],
              },
            }}
          />
        )}

        {participant && (
          <div className="mt-6">
            <NextSteps
              steps={values.nextSteps}
              type="meeting-notes"
              users={[authStore.auth.user.id, participant.id]}
              updateMeeting={async (data) => {
                handleFormValueChange(
                  data.map((item) => computeActionItem(item)),
                  'nextSteps',
                );
              }}
            />
          </div>
        )}

        <VerticalSpacer size="24px" />

        <Notes
          user={'user'}
          isHostSharing={values.shareHostNote}
          isParticipantSharing={false}
          note={values.hostNote}
          updateMeeting={(value, field) => {
            handleFormValueChange(value, field);
          }}
        />

        <VerticalSpacer size="20px" />
        <>
          <VerticalSpacer size="20px" />
          {!leaveFeedback && (
            <div className="border border-borderLight rounded-lg p-6 flex items-center justify-between">
              <Headline3>Leave Feedback</Headline3>
              <PlainButton onClick={() => setLeaveFeedback(true)}>
                <FlexRow>
                  <PlusIcon styles={{stroke: '#585ADF'}} />

                  <Body2 weight="bold" kind="purple300" className="ml-2">
                    Add feedback
                  </Body2>
                </FlexRow>
              </PlainButton>
            </div>
          )}
          {leaveFeedback && (
            <SectionCard
              headerPadding={isXs ? 'small' : undefined}
              contentStyling={{padding: isXs ? '12px' : '24px'}}
              CustomHeaderTitle={<Headline3>Leave feedback</Headline3>}>
              <div>
                <Feedback
                  handleFeedbackFormValueChange={handleFeedbackFormValueChange}
                  feedbackValues={feedbackValues}
                  feedbackError={feedbackError}
                />
              </div>
            </SectionCard>
          )}
        </>

        {!!showViewUser && participant && (
          <ViewUser
            open={!!showViewUser}
            name={userName(participant)}
            user={participant?.id}
            type={showViewUser}
            onClose={() => setShowViewUser('')}
          />
        )}
      </div>
    );
  },
);
