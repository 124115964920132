import {VerticalSpacer} from '../../../../../../../../ui/atoms/spacer';
import {
  Headline2,
  Headline4,
} from '../../../../../../../../ui/atoms/typography';
import {Button} from '../../../../../../../../ui/atoms/button';
import {useEffect, useState} from 'react';
import {TextField} from '../../../../../../../../ui/molecules/field/textfield';
import SettingsPageLayout from '@ui/templates/page-template/page-layout';
import {SelectField} from '../../../../../../../../ui/molecules/select/selectfield';
import {Flex, FlexRowEnd} from '@ui/style/styles';
import {TextTag2} from '@ui/atoms/tag';
import {Label} from '../../../../../../../../ui/atoms/label';
import {
  LeftWrapper,
  LeftWrapperInnerChild,
  LeftChildInput,
  LeftChildSpan,
} from '../../../../../../../dashboard/member-profile/member-profile-style';
import {Avatar} from '../../../../../../../../ui/atoms/avatar';
import React from 'react';
import {authStore} from '../../../../../../../../store/stores/auth-store';
import {useAdminSettingsHook} from './admin-settings-hooks';
import {SideNavigation} from '@ui/molecules/side-nav';
import {WorkspaceWrapperChild} from '@pages/dashboard/admin/admin-components/admin-workspace/admin-workspace-styled';
import {Controller} from 'react-hook-form';
import {timezones} from '../../../../../../../../constants';
import {activateNotification} from '../../../../../../../../ui/molecules/notification/activate-notification';
import {ItemLoader} from '../../../../../../../../ui/organisms/item-loader';

import {UserSelectField} from '../../../../../../../../ui/molecules/select/user';
import {useStoreContext} from '../../../../../../../../store/store-context';
import {BaseURL} from '../../../../../../../../configs/request';
import {useNavigate} from 'react-router';
import {
  PageLayout,
  RightWrapper,
  CustomButton,
  RightChildWrapper,
  RightChildSpan,
  ButtonWrapper,
  EmailButton,
  CancelButton,
  HeaderWrapper,
} from './admin-settings.styles';

import {Image} from '../../../../../../../../ui/atoms/image';
import {capitalize} from '../../../../../../../../utils';
import {GroupsMultiselect} from '../../../../../../member-profile/member-profile-component/personal';
import {ItemLoaderLine} from '../../../../../../../../ui/organisms/item-loader-line';
import {observer} from 'mobx-react';
import {PhoneSelectField} from '../../../../../../../../ui/molecules/select/phone-select-field';
import {phoneCodes} from '../../../../../../../../utils/countries';
import {getStrBtwCharacters} from '../../../../../../../../utils/strings';

export const AdminSettingsForm = observer(() => {
  const {
    register,
    errors,
    isSubmitting,
    isSubmittingEmail,
    submitForm,
    submitEmail,
    handleSelectChange,
    handleSelectChangeGroups,
    handleSelectChangeReviewer,
    handleSubmit,
    control,
    reviewerControl,
    image,
    handleChange,
    isDisabled,
    emailRegister,
    emailErrors,
    reviewerErrors,
    emailHandleSubmit,
    users,
    profile,
    profileAvatar,
    isLoading,
    progress,
    uploadAvatar,
    handleSetPrefix,
  } = useAdminSettingsHook();
  const {
    analyticsStore: {filterGroup: group},
    usersStore: {usersGroups},
  } = useStoreContext();

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = (e: any) => {
    hiddenFileInput?.current?.click();
    e.preventDefault();
  };

  // reset password function
  const passwordReset = (
    email: string,
    firstName: string,
    lastName: string,
  ) => {
    let passwordResetData = {email: email};
    fetch(`${BaseURL}/auth/forgot-password`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
      body: JSON.stringify(passwordResetData),
    })
      .then((res) => {
        if (res.ok) {
          activateNotification({
            title: 'Password Reset Sent',
            content: `"${firstName} ${lastName}" password has been reset`,
            kind: 'success',
          });
        } else {
          activateNotification({
            title: 'Error',
            content: res.statusText,
            kind: 'error',
          });
        }

        return res.json();
      })
      .then(() => {})
      .catch(() => {});
  };

  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState('');
  const isDisabledEmail =
    data === profile.email || data.length === 0 ? true : false;
  const getEmail = (val: any) => {
    setData(val.target.value);
  };
  const emailChange = (e: any) => {
    e.preventDefault();
    setDisabled(!disabled);
  };
  const noReload = (e: any) => {
    e.preventDefault();
  };
  const [countryCode, setCountryCode] = useState<string>(
    profile?.countryCode ? profile?.countryCode : '+1',
  );
  const onChangeCountryCode = (prefix: {label: string; value: string}) => {
    setCountryCode(prefix.value);
  };
  useEffect(() => {
    if (profile?.countryCode && countryCode !== profile?.countryCode) {
      setCountryCode(profile?.countryCode);
    }
  }, [countryCode, profile?.countryCode]);

  const userProfileNavigations = [
    {
      active: true,
      href: '#',
      id: '1',
      type: 'workspace',
      onClick: () => null,
      title: 'Personal',
    },
  ];

  const navigate = useNavigate();

  return (
    <>
      <SettingsPageLayout
        bodyStyle={{margin: 0}}
        title={`Edit ${profile.firstName}'s Profile`}
        onClick={() => navigate(-1)}>
        <WorkspaceWrapperChild>
          <SideNavigation
            navigations={userProfileNavigations}
            type="workspace"
          />
          <div>
            {isLoading && (
              <>
                <PageLayout>
                  <RightWrapper>
                    <form method="patch" onSubmit={handleSubmit(submitForm)}>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        sm>
                        <Flex alignItems="center">
                          <LeftWrapper>
                            <LeftWrapperInnerChild>
                              {image.preview ? (
                                <Image
                                  src={image.preview}
                                  alt="preview"
                                  width="100"
                                  height="100"
                                  style={{
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                  }}
                                />
                              ) : (
                                <>
                                  <Avatar
                                    alt="Image"
                                    name={
                                      profile.firstName + ' ' + profile.lastName
                                    }
                                    size="xl"
                                    src={profileAvatar}
                                    userId={profile.id}
                                    tooltip={true}
                                  />
                                </>
                              )}
                              <LeftChildSpan>
                                {image.preview ? (
                                  <Button
                                    width="xm"
                                    kind="primary"
                                    onClick={uploadAvatar}
                                    style={{
                                      padding: '0px 12px',
                                      borderRadius: '6px',
                                    }}
                                    isLoading={progress}
                                    aria-busy="true">
                                    Save
                                  </Button>
                                ) : (
                                  <Button
                                    width="xm"
                                    kind="secondary"
                                    onClick={handleClick}
                                    style={{
                                      padding: '0px 12px',
                                      borderRadius: '6px',
                                    }}>
                                    Edit
                                  </Button>
                                )}
                              </LeftChildSpan>
                              <LeftChildInput
                                type="file"
                                ref={hiddenFileInput}
                                id="edit"
                                accept="image/*"
                                onChange={handleChange}
                              />
                              <VerticalSpacer size="8px" />
                            </LeftWrapperInnerChild>
                          </LeftWrapper>
                          <div style={{marginLeft: '10px'}}>
                            <TextTag2
                              style={{position: 'relative', top: '-3.5px'}}>
                              {profile?.role && capitalize(profile?.role)}
                            </TextTag2>
                            <Headline2 style={{marginTop: '10px'}}>
                              {profile?.firstName && profile?.lastName
                                ? capitalize(profile?.firstName) +
                                  ' ' +
                                  capitalize(profile?.lastName)
                                : '----- -----'}{' '}
                              &nbsp;
                            </Headline2>
                          </div>
                        </Flex>
                        <FlexRowEnd style={{width: '100%'}}>
                          <CustomButton
                            type="submit"
                            width="sm"
                            disabled={isDisabled}
                            data-form-action={true}
                            isLoading={isSubmitting}
                            aria-busy={isSubmitting}>
                            Save changes
                          </CustomButton>
                        </FlexRowEnd>
                      </Flex>

                      <RightChildWrapper>
                        <RightChildSpan>
                          <VerticalSpacer size="10px" />
                          <TextField
                            defaultValue={profile.firstName || ''}
                            label="First Name"
                            placeholder="First Name"
                            type="text"
                            autoComplete="text"
                            ref={register}
                            helper={errors.firstName?.message || ''}
                            state={errors.firstName ? 'error' : 'default'}
                            name="firstName"
                          />
                        </RightChildSpan>
                        <RightChildSpan>
                          <VerticalSpacer size="10px" />
                          <TextField
                            defaultValue={profile.lastName || ''}
                            label="Last Name"
                            placeholder="Last Name"
                            type="text"
                            autoComplete="text"
                            ref={register}
                            helper={errors.lastName?.message || ''}
                            state={errors.lastName ? 'error' : 'default'}
                            name="lastName"
                          />
                        </RightChildSpan>
                        <RightChildSpan>
                          <VerticalSpacer size="20px" />
                          <div>
                            <Label>Phone Number</Label>
                            <Controller
                              control={control}
                              name="countryCode"
                              defaultValue={countryCode}
                              render={({onBlur, value}) => (
                                <PhoneSelectField
                                  placeholder="Phone number"
                                  name="countryCode"
                                  onChange={onChangeCountryCode}
                                  select={value}
                                  defaultValue={countryCode}
                                  selectOptions={phoneCodes
                                    .map(
                                      (country: {
                                        dial_code: string;
                                        name: string;
                                      }) => ({
                                        label: country.name,
                                        value: country.dial_code,
                                      }),
                                    )
                                    .sort((a: any, b: any) =>
                                      a.label.localeCompare(b.label),
                                    )}
                                  setSelect={(prefix: string) => {
                                    setCountryCode(prefix);
                                    handleSetPrefix(prefix);
                                  }}
                                  type="phoneNumberDropdownInput"
                                  phoneNumberInputInfo={{
                                    defaultValue: profile?.phoneNumber || '',
                                    label: 'Phone Number',
                                    placeholder: 'Phone Number',
                                    type: 'text',
                                    autoComplete: 'text',
                                    ref: register,
                                    helper: errors.phoneNumber?.message || '',
                                    state: errors.phoneNumber
                                      ? 'error'
                                      : 'default',
                                    name: 'phoneNumber',
                                  }}
                                />
                              )}
                            />
                          </div>
                        </RightChildSpan>
                        <RightChildSpan>
                          <VerticalSpacer size="20px" />
                          <form method="patch" onSubmit={noReload}>
                            <div>
                              <Label>Email Address</Label>
                              {disabled ? (
                                <ButtonWrapper>
                                  <EmailButton
                                    kind="small"
                                    onClick={emailChange}>
                                    Change Email
                                  </EmailButton>
                                </ButtonWrapper>
                              ) : (
                                <ButtonWrapper>
                                  <CancelButton
                                    kind="small"
                                    onClick={emailChange}>
                                    Cancel
                                  </CancelButton>
                                  <EmailButton
                                    kind="small"
                                    onClick={emailHandleSubmit(submitEmail)}
                                    disabled={isDisabledEmail}
                                    data-form-action={true}
                                    isLoading={isSubmittingEmail}
                                    aria-busy={isSubmittingEmail}>
                                    Save
                                  </EmailButton>
                                </ButtonWrapper>
                              )}
                            </div>
                            <TextField
                              disabled={disabled}
                              defaultValue={profile.email || ''}
                              placeholder="Email address"
                              type="email"
                              autoComplete="email"
                              ref={emailRegister}
                              inputStyle={
                                disabled
                                  ? {background: '#FAFAFA', color: '#5F5F8C'}
                                  : undefined
                              }
                              onChange={getEmail}
                              helper={emailErrors.email?.message || ''}
                              state={emailErrors.email ? 'error' : 'default'}
                              name="email"
                            />
                          </form>
                        </RightChildSpan>
                        <RightChildSpan>
                          <VerticalSpacer size="20px" />
                          <TextField
                            defaultValue={
                              profile.jobTitle
                                ? capitalize(profile.jobTitle)
                                : ''
                            }
                            label="Job Title"
                            placeholder="Eg. Software developer"
                            type="text"
                            autoComplete="text"
                            ref={register}
                            helper={errors.jobTitle?.message || ''}
                            state={errors.jobTitle ? 'error' : 'default'}
                            name="jobTitle"
                          />
                        </RightChildSpan>
                        <RightChildSpan>
                          <VerticalSpacer size="20px" />
                          {group.length === 0 &&
                            Object.keys(usersGroups).length > 0 &&
                            usersGroups[profile.id].length === 0 && (
                              <ItemLoaderLine />
                            )}
                          {group.length > 0 &&
                            Object.keys(usersGroups).length > 0 &&
                            usersGroups.hasOwnProperty(profile.id) &&
                            usersGroups[profile.id].length > 0 && (
                              <GroupsMultiselect
                                control={control}
                                handleSelectChangeGroups={
                                  handleSelectChangeGroups
                                }
                                group={group}
                                currentUser={profile}
                                currentUserGroups={usersGroups[profile.id]}
                              />
                            )}
                        </RightChildSpan>
                        <RightChildSpan>
                          <VerticalSpacer size="20px" />
                          <Label>Time zone</Label>
                          <Controller
                            control={control}
                            name="timezone"
                            defaultValue={profile.timezone}
                            render={({onBlur, value}) => (
                              <SelectField
                                onBlur={onBlur}
                                name="timezone"
                                placeholder="Select a time zone"
                                value={value}
                                label="Time Zone"
                                options={timezones.map(
                                  (timezone: {
                                    label: string;
                                    value: string;
                                  }) => ({
                                    label: `${
                                      timezone?.label
                                    } (${getStrBtwCharacters(
                                      timezone?.value,
                                      '(',
                                      ')',
                                    )})`,
                                    value: timezone?.value,
                                  }),
                                )}
                                helper={errors.timezone?.message || ''}
                                state={errors.timezone ? 'error' : 'default'}
                                onChange={(data: {value: string}) =>
                                  data !== null &&
                                  handleSelectChange(data.value)
                                }
                              />
                            )}
                          />
                        </RightChildSpan>
                        <RightChildSpan>
                          <Controller
                            control={reviewerControl}
                            name="reviewer"
                            defaultValue={profile.reviewer?.id}
                            render={({onBlur, value}) => (
                              <UserSelectField
                                onBlur={onBlur}
                                name="reviewer"
                                value={value}
                                helper={reviewerErrors.reviewer?.message || ''}
                                state={
                                  reviewerErrors.reviewer
                                    ? 'error'
                                    : 'default'
                                }
                                onChange={(data: {value: string}) =>
                                  data !== null &&
                                  handleSelectChangeReviewer(data.value)
                                }
                                label=" Manager"
                                placeholder={
                                  profile.reviewer
                                    ? profile.reviewer.firstName +
                                      ' ' +
                                      profile.reviewer.lastName
                                    : 'Select Manager'
                                }
                                options={users}
                              />
                            )}
                          />
                        </RightChildSpan>
                      </RightChildWrapper>
                    </form>
                    <VerticalSpacer size="24px" />
                    <HeaderWrapper>
                      <Headline4>Security</Headline4>
                      <CustomButton
                        type="submit"
                        width="sm"
                        onClick={() => {
                          passwordReset(
                            profile.email,
                            profile.firstName,
                            profile.lastName,
                          );
                        }}>
                        Send password reset link
                      </CustomButton>
                    </HeaderWrapper>
                    <VerticalSpacer size="24px" />
                  </RightWrapper>
                </PageLayout>
              </>
            )}
            {!isLoading && <ItemLoader />}
          </div>
        </WorkspaceWrapperChild>
      </SettingsPageLayout>
    </>
  );
});
