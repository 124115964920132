import React from 'react';

export const MagicPenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none">
      <path
        d="M11.3196 7.18094L12.9996 5.50094C13.553 4.9476 13.553 4.05427 12.9996 3.50094C12.4463 2.9476 11.553 2.9476 10.9996 3.50094L2.33297 12.1676C1.77964 12.7209 1.77964 13.6143 2.33297 14.1676C2.8863 14.7209 3.77964 14.7209 4.33297 14.1676L9.6663 8.83427"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0068 6.49219L10.0068 4.49219"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.37366 2.83203L6.66699 3.83203L5.66699 3.5387L4.66699 3.83203L4.96033 2.83203L4.66699 1.83203L5.66699 2.12536"
        stroke="currentColor"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6.12536L4 5.83203L3.70667 6.83203L4 7.83203L3 7.5387L2 7.83203L2.29333 6.83203L2 5.83203L3 6.12536Z"
        stroke="currentColor"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2933 10.168L12 9.16797L13 9.4613L14 9.16797L13.7067 10.168L14 11.168L13 10.8746"
        stroke="currentColor"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
