import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Resolver, useForm} from 'react-hook-form';
import {useStoreContext} from '@store/store-context';
import {useNavigate} from 'react-router';
import {useDebounce} from '@utils/debounce';
import {useLocation} from 'react-router';
import dayjs from 'dayjs';
import {beamMasteryTasks} from '@utils/firebase-request';
import {capitalize} from '@utils';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {CompanyCheckinsController} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.controller';
import {ICurrentCheckinsForm} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.interface';
import {FeedbackController} from '@pages/feedback/feedback-controller';
import {FeedbackRequestImpl} from '@pages/feedback/feedback-requests';
import {CompanyCheckinsRequestImpl} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.request';
import {useSearchParams} from 'react-router-dom';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {ViewUserCheckinsValidatorSchema} from './view-user-checkins.validator';
import {usePostHogHook} from '@hooks/post-hog';

export function useViewUserCheckinsHook(checkinsId: string) {
  const [loadingPage, setLoadingPage] = useState(false);
  const request = useMemo(() => new CompanyCheckinsRequestImpl(), []);
  const controller = useMemo(() => new CompanyCheckinsController(request), [
    request,
  ]);
  const feedbackRequest = useMemo(() => new FeedbackRequestImpl(), []);
  const feedbackController = useMemo(
    () => new FeedbackController(feedbackRequest),
    [feedbackRequest],
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const showLoadNextUser = searchParams.get('nextUser');
  const {isPerformanceActive} = useWorkspaceUseCase();
  const {state}: any = useLocation();

  const {
    authStore: {auth},
    usersStore: {getUsers},
    groupStore: {groups},
    checkinsStore: {
      specificUserCheckins,
      setSpecificUserCheckinsGoals,
      setSpecificUserCheckin,
      setUsersCheckins,
      specificUserGoalsPaginator,
      modifySpecificUserGoalsPaginator,
      usersCheckins,
      checkinsDates,
      setSpecificUserCheckinsIsReviewed,
    },
    analyticsStore: {goalsDate},
  } = useStoreContext();

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  ////// CHECKINS

  const isEditable = Object.keys(specificUserCheckins).length > 0;
  const parseEditValues = useCallback(() => {
    if (isEditable) {
      return {
        pulse: {
          mood: specificUserCheckins?.responses?.pulse?.mood,
          feedback: specificUserCheckins?.responses?.pulse?.feedback,
          id: specificUserCheckins?.responses?.pulse?.id,
        },
        priorities: {
          currentCheckin:
            specificUserCheckins?.responses?.priorities?.currentCheckin,
          nextCheckin: specificUserCheckins?.responses?.priorities?.nextCheckin,
          id: specificUserCheckins?.responses?.priorities?.id,
        },
        questions: {
          questions: specificUserCheckins?.responses?.questions?.questions,
          id: specificUserCheckins?.responses?.questions?.id,
        },
        feedback: specificUserCheckins?.feedback,
      };
    }
  }, [
    isEditable,
    specificUserCheckins?.feedback,
    specificUserCheckins?.responses?.priorities?.currentCheckin,
    specificUserCheckins?.responses?.priorities?.id,
    specificUserCheckins?.responses?.priorities?.nextCheckin,
    specificUserCheckins?.responses?.pulse?.feedback,
    specificUserCheckins?.responses?.pulse?.id,
    specificUserCheckins?.responses?.pulse?.mood,
    specificUserCheckins?.responses?.questions?.id,
    specificUserCheckins?.responses?.questions?.questions,
  ]);

  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    clearErrors,
    reset,
  } = useForm<ICurrentCheckinsForm>({
    resolver: yupResolver(
      ViewUserCheckinsValidatorSchema.viewUserCheckins,
    ) as Resolver<ICurrentCheckinsForm, object>,
    mode: 'all',
    defaultValues: {
      pulse: {
        mood: isEditable ? parseEditValues()?.pulse?.mood : null,
        feedback: isEditable ? parseEditValues()?.pulse?.feedback : null,
        id: isEditable ? parseEditValues()?.pulse?.id : '',
      },
      priorities: {
        currentCheckin: isEditable
          ? parseEditValues()?.priorities?.currentCheckin
          : [],
        nextCheckin: isEditable
          ? parseEditValues()?.priorities?.nextCheckin
          : [],
        id: isEditable ? parseEditValues()?.priorities?.id : '',
      },
      questions: {
        questions: isEditable ? parseEditValues()?.questions?.questions : [],
        id: isEditable ? parseEditValues()?.questions?.id : '',
      },
      feedback: isEditable ? parseEditValues()?.feedback : '',
    },
  });

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const hasError = useMemo(() => {
    return formState.submitCount > 1 && formState.errors;
  }, [formState.errors, formState.submitCount]);

  const handleFormValueChange = useCallback(
    (value: any, name: string) => {
      setValue(name, value);
    },
    [setValue],
  );

  const fetchSpecificUserCheckin = useCallback(
    async (checkinId: string) => {
      setLoadingPage(true);
      const response: any = await controller.getCheckinByIdAndFetchResponses(
        checkinId,
      );

      if (response) {
        const pulses = await controller.getPulses({
          user: response.user,
          weekOfYear: response.weekOfYear,
        });

        if (pulses) {
          response.mood =
            pulses.data?.[0]?.mood || pulses.summary?.averagePulse;
        }

        setSpecificUserCheckin(response as any);
      }
      setLoadingPage(false);
    },
    [controller, setSpecificUserCheckin],
  );

  useEffect(() => {
    fetchSpecificUserCheckin(checkinsId);
    return () => {
      setSpecificUserCheckin({});
    };
  }, [checkinsId, fetchSpecificUserCheckin, setSpecificUserCheckin]);

  const [feedbackValues, setFeedBackValues] = useState({
    impression: '',
    feedbackValue: '',
    values: [],
    feedbackSharingType: 'private',
  });

  const insertDefaultCheckinsData = useCallback(async () => {
    reset({
      pulse: {
        mood: specificUserCheckins?.responses?.pulse?.mood,
        feedback: specificUserCheckins?.responses?.pulse?.feedback,
        id: specificUserCheckins?.responses?.pulse?.id,
      },
      priorities: {
        currentCheckin: specificUserCheckins?.responses?.priorities
          ?.currentCheckin
          ? specificUserCheckins?.responses?.priorities?.currentCheckin
          : [],
        nextCheckin: specificUserCheckins?.responses?.priorities?.nextCheckin
          ? specificUserCheckins?.responses?.priorities?.nextCheckin
          : [],
        id: specificUserCheckins?.responses?.priorities?.id,
      },
      questions: {
        questions: specificUserCheckins?.responses?.questions?.questions,
        id: specificUserCheckins?.responses?.questions?.id,
      },
      feedback: parseEditValues()?.feedback ? parseEditValues()?.feedback : '',
    });
  }, [
    parseEditValues,
    reset,
    specificUserCheckins?.responses?.priorities?.currentCheckin,
    specificUserCheckins?.responses?.priorities?.id,
    specificUserCheckins?.responses?.priorities?.nextCheckin,
    specificUserCheckins?.responses?.pulse?.feedback,
    specificUserCheckins?.responses?.pulse?.id,
    specificUserCheckins?.responses?.pulse?.mood,
    specificUserCheckins?.responses?.questions?.id,
    specificUserCheckins?.responses?.questions?.questions,
  ]);

  useEffect(() => {
    insertDefaultCheckinsData();
  }, [insertDefaultCheckinsData]);
  ////// CheckinsForm

  ////
  const updatePulse = useCallback(
    async (data: {
      pulse: {mood: string; feedback: string};
      pulseId: string;
    }) => {
      await controller.updateCheckinsPulseResponse(
        {
          feedback:
            data.pulse.feedback !== null ? data.pulse.feedback : undefined,
          mood: data.pulse.mood !== null ? data.pulse.mood : undefined,
        },
        data.pulseId,
      );
    },
    [controller],
  );
  const debounceUpdatePulse = useDebounce(updatePulse, 1000);
  const updatePriorities = useCallback(
    async (data: {
      priorities: {
        currentCheckin?: {
          text?: string;
          completed?: boolean;
        }[];
        nextCheckin?: {
          text?: string;
          completed?: boolean;
        }[];
      };
      prioritiesId: string;
    }) => {
      await controller.updateCheckinsPrioritiesResponse(
        {
          currentCheckin: data.priorities?.currentCheckin
            ?.filter(
              (checkin: {text?: string; completed?: boolean}) =>
                checkin?.text !== '',
            )
            .map((checkin: {text?: string; completed?: boolean}) => ({
              text: checkin?.text,
              completed: checkin?.completed,
            })),
          nextCheckin: data.priorities?.nextCheckin
            ?.filter(
              (checkin: {text?: string; completed?: boolean}) =>
                checkin?.text !== '',
            )
            .map((checkin: {text?: string; completed?: boolean}) => ({
              text: checkin?.text,
              completed: checkin?.completed,
            })),
        },
        data.prioritiesId,
      );
    },
    [controller],
  );
  const debounceUpdatePriorities = useDebounce(updatePriorities, 2000);
  const updateQuestions = useCallback(
    async (data: {
      questions: {
        questions: {
          question: string;
          response?: null | string;
          target: {type: string; ids: string[]};
        }[];
      };
      questionId: string;
    }) => {
      await controller.updateCheckinsQuestionsResponse(
        {
          questions: data.questions.questions.map(
            (question: {
              question: string;
              response?: null | string;
              target: {type: string; ids: string[]};
            }) => ({
              ...question,
              _id: undefined,

              response:
                question.response === null ? undefined : question.response,
            }),
          ),
        },
        data.questionId,
      );
    },
    [controller],
  );
  const debounceUpdateQuestions = useDebounce(updateQuestions, 2000);

  const updateFeedback = useCallback(
    async (feedback) => {
      await controller.updateCheckinById(feedback, checkinsId);
    },
    [checkinsId, controller],
  );
  const isFeedBackEnabled = useMemo(
    () => auth.user.workspace.settings.feedback === 'enabled',
    [auth.user.workspace.settings.feedback],
  );

  const [feedbackError, setFeedbackError] = useState({
    impression: '',
    feedbackSharingType: '',
    feedbackValue: '',
  });

  const handleError = (field: string, value: string) => {
    setFeedbackError((prev) => ({...prev, [field]: value}));
  };

  const showFeedbackError = useMemo(() => formState.submitCount > 0, [
    formState.submitCount,
  ]);

  const isErrorField = useCallback(() => {
    const {feedbackValue, impression, feedbackSharingType} = feedbackValues;
    if (feedbackValue.length < 30) {
      handleError(
        'feedbackValue',
        'Error: Feedback must be at least 30 characters.',
      );
      return true;
    } else if (feedbackError.feedbackValue) {
      handleError('feedbackValue', '');
    }
    if (!impression) {
      handleError('impression', 'Error: Theme must be selected');
      return true;
    } else if (feedbackError.impression) {
      handleError('impression', '');
    }
    if (!feedbackSharingType) {
      handleError(
        'feedbackSharingType',
        ' Error: Sharing type must be selected',
      );
      return true;
    } else if (feedbackError.feedbackSharingType) {
      handleError('feedbackSharingType', '');
    }
  }, [feedbackError, feedbackValues]);

  const handleFeedbackFormValueChange = useCallback(
    (value: any, name: string) => {
      setFeedBackValues((prev) => ({...prev, [name]: value}));
      isErrorField();
    },
    [isErrorField],
  );

  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [feedback, setFeedback] = useState([]);

  const getUserFeedBack = useCallback(async () => {
    try {
      if (specificUserCheckins?.user) {
        setLoadingFeedback(true);
        const response = await feedbackController.getFeedback(
          {subjectId: checkinsId, subjectType: 'check-in'},
          specificUserCheckins?.user,
        );
        response && setFeedback(response);
        setLoadingFeedback(false);
      }
    } catch {}
  }, [checkinsId, specificUserCheckins?.user, feedbackController]);

  useEffect(() => {
    getUserFeedBack();
  }, [getUserFeedBack]);

  const findNextCheckins = useMemo(
    () =>
      usersCheckins?.data.find(
        (report: any) =>
          report?.isSubmitted &&
          !report?.isReviewed &&
          report?.id !== specificUserCheckins.id,
      ),
    [specificUserCheckins.id, usersCheckins?.data],
  );

  const {postHogCapture} = usePostHogHook();
  const submitForm = useCallback(
    async (data) => {
      try {
        const submitCheckins = async () => {
          updateFeedback({});
          const response = await controller.submitSpecificCheckin(
            {},
            specificUserCheckins.id as string,
            'report',
          );
          !!response && setSpecificUserCheckinsIsReviewed(data?.feedback);
          !!response && beamMasteryTasks('review_checkin', true);

          if (response) {
            postHogCapture('frontend - Checkins review');
            setUsersCheckins({
              ...usersCheckins,
              data: usersCheckins.data.map((checkins: {id: string}) => {
                if (checkins.id === specificUserCheckins.id) {
                  return {
                    ...checkins,
                    isReviewed: true,
                  };
                }
                return checkins;
              }),
            });
          }
          if (showLoadNextUser) {
            return navigate(`/view-checkins/${findNextCheckins?.id}/direct`, {
              state: {
                user: findNextCheckins?.user?.id,
              },
            });
          }
          !!response &&
            navigate(
              `/checkins/direct-reports?week=${checkinsDates.weekOfYear}&year=${checkinsDates.year}`,
            );
        };

        if (isFeedBackEnabled && feedbackValues.feedbackValue?.length > 0) {
          if (isErrorField()) return;

          const {
            feedbackValue,
            impression,
            feedbackSharingType,
            values,
          } = feedbackValues;
          const response = await feedbackController.postFeedback(
            {
              feedback: feedbackValue,
              impression: impression,
              subjectType: 'check-in',
              subjectName: `${dayjs(checkinsDates.starts).format(
                'MMM. DD',
              )} - ${capitalize(
                dayjs(checkinsDates.ends).format('MMM. DD YYYY'),
              )}`,
              subjectId: checkinsId,
              visibility: feedbackSharingType,
              values: values,
            },
            specificUserCheckins?.user,
          );
          postHogCapture('frontend - feedback Submission');
          response && response && submitCheckins();
          return;
        } else {
          submitCheckins();
        }
      } catch (error) {}
    },
    [
      controller,
      setSpecificUserCheckinsIsReviewed,
      checkinsDates.weekOfYear,
      isFeedBackEnabled,
      specificUserCheckins?.user,
      postHogCapture,
      checkinsDates.ends,
      showLoadNextUser,
      findNextCheckins,
      setUsersCheckins,
      usersCheckins,
      checkinsDates.starts,
      checkinsId,
      feedbackController,
      feedbackValues,
      isErrorField,
      checkinsDates.year,
      specificUserCheckins.id,
      navigate,
      updateFeedback,
    ],
  );

  const debounceUpdateFeedback = useDebounce(updateFeedback, 800);

  //////
  // Init Workspace Checkins Goals
  const [loadingGoals, setLoadingGoals] = useState(false);

  const fetchSpecifiedGoals = useCallback(
    async (filters: any, view?: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);

      setLoadingGoals(true);
      const response = await controller.fetchSpecifiedGoals(filters, view);

      const includeGroups = async (goalsResponse: any) => {
        const groupsIncluded = await Promise.all(
          goalsResponse &&
            goalsResponse?.map(async (goal: any) => {
              if (goal.goalType === 'group') {
                const group = goal.group.map((grp: string) => {
                  return groups.find((_group) => _group.id === grp);
                });

                return {
                  ...goal,
                  group,
                };
              } else
                return {
                  ...goal,
                };
            }),
        );
        return groupsIncluded;
      };

      if (response.goals) {
        delete filters.member;

        const periodRange = {
          ...filters,
          startDate: goalsDate.starts,
          endDate: goalsDate.ends,
        };

        const keyresults = await controller.fetchSpecifiedKpis(
          periodRange,
          true,
        );

        const goals = await controller.fetchSpecifiedGoals(
          periodRange,
          view,
          true,
        );

        const unfurledData = await includeGroups(response?.goals);

        const computeGoalsWithKeyResults = unfurledData.map((goal: any) => {
          const __goal = goals.goals?.find(
            (_goal: any) => _goal.id === goal.id,
          );
          return {
            ...goal,
            activities: __goal?.activities || goal.activites,
            keyResults: goal.keyResults?.map((keyresult: any) => {
              const getKeyResult = keyresults.find(
                (kr: any) => kr.id === keyresult.id,
              );

              return {
                ...keyresult,
                activities: getKeyResult?.activities,
              };
            }),
          };
        });

        modifySpecificUserGoalsPaginator('totalPages', response.totalPages);
        modifySpecificUserGoalsPaginator('limit', response.limit);
        modifySpecificUserGoalsPaginator('totalResults', response.totalResults);

        response && setSpecificUserCheckinsGoals(computeGoalsWithKeyResults);
      }
      // response && setSpecificUserCheckinsGoals(response?.results);

      response && setLoadingGoals(false);
    },
    [
      groups,
      goalsDate.starts,
      goalsDate.ends,
      modifySpecificUserGoalsPaginator,
      setSpecificUserCheckinsGoals,
    ],
  );
  useEffect(() => {
    if (isPerformanceActive) {
      fetchSpecifiedGoals({
        //DO NOT DELETE --- UNCOMMENT ANY IF A FUTURE FEATURE UPDATE REQUIRES INCLUSION OF ANY FILTER
        // goalPage: goalPaginator.page,
        // state: 'active',
        // status: filterStatus.goals,
        startDate: checkinsDates?.starts,
        endDate: checkinsDates?.ends,
        goalPage: specificUserGoalsPaginator.page,
        limit: specificUserGoalsPaginator.limit,
        filterBy: 'weeks',
        removeKpiFilter: true,
        newReport: true,
        // goalType: searchable.goalType,
        member: specificUserCheckins?.user || state?.user,
        // manager: searchable.managers,
        // department: searchable.department,
        // departmentMembers: searchable.departmentMembers,
        // deactivatedMembers: searchable.deactivatedMembers,
      });
    }

    return () => {
      setSpecificUserCheckinsGoals([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth.user.id,
    checkinsDates?.ends,
    // state?.user,
    checkinsDates?.starts,
    specificUserGoalsPaginator.page,
    specificUserGoalsPaginator.limit,
    fetchSpecifiedGoals,
    setSpecificUserCheckinsGoals,
    specificUserCheckins?.user,
  ]);

  //////
  return {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    clearErrors,
    reset,
    isSubmitting,
    handleFeedbackFormValueChange,
    feedbackValues,
    findNextCheckins,
    loadingFeedback,
    hasError,
    feedback,
    showFeedbackError,
    feedbackError,
    handleFormValueChange,
    submitForm,
    updatePriorities,
    debounceUpdatePulse,
    debounceUpdatePriorities,
    debounceUpdateQuestions,
    debounceUpdateFeedback,
    loadingGoals,
    loadingPage,
  };
}
