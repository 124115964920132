import {InsightCard} from '@pages/dashboard/reporting/dashboard/insight-card';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {useStoreContext} from '@store/store-context';
import {capitalize} from '@utils';
import {cfv} from '@utils/framework';
import dayjs from 'dayjs';
import {FC, memo, useCallback} from 'react';
import {useQuery} from 'react-query';
import styled from 'styled-components';

const GridItem = styled.div<{w: number}>`
  width: 100%;
  height: 100%;
  min-height: 180px;
  grid-column: span 1 / span 1;

  @media (min-width: 768px) {
    grid-column: span ${(props) => Math.min(2, props.w / 3)} / span
      ${(props) => Math.min(2, props.w / 3)};
  }

  @media (min-width: 1200px) {
    grid-column: span ${(props) => Math.min(12, props.w)} / span
      ${(props) => Math.min(12, props.w)};
  }
`;

interface GoalsWidgetProps {
  activeTab: 'you' | 'team' | 'custom';
  selectedTeams?: string;
  selectedGroups?: string;
  selectedMembers?: string;
  range: {
    comparisonType: string;
    current: {
      startDate: string;
      endDate: string;
    };
    comparison: {
      startDate: string;
      endDate: string;
    };
  };
}

export const GoalsWidget: FC<GoalsWidgetProps> = memo(
  ({activeTab, range, selectedGroups, selectedMembers, selectedTeams}) => {
    const {
      authStore: {auth},
    } = useStoreContext();

    const fetchSpecifiedGoals = useCallback(
      async (filters: any, view?: string) => {
        const request = new AnalyticsRequestImpl();
        const controller = new AnalyticsController(request);

        const response = await controller.fetchSpecifiedGoals(
          filters,
          view,
          true,
        );
        if (response) {
          const prevResponse = await controller.fetchSpecifiedGoals(
            {
              ...filters,
              startDate: dayjs(range.comparison.startDate).format('MM/DD/YYYY'),
              endDate: dayjs(range.comparison.endDate).format('MM/DD/YYYY'),
            },
            view,
            true,
          );
          const currentGoalCount = response.summary.goals?.count || 0;

          const previousGoalCount = prevResponse.summary.goals?.count || 0;

          const currentAlignedGoalCount = response.summary.alignedGoals || 0;
          const previousAlignedGoalCount =
            prevResponse.summary.alignedGoals || 0;

          const goalCountDiff =
            previousGoalCount === 0
              ? 0
              : ((currentGoalCount - previousGoalCount) / previousGoalCount) *
                100;

          const alignedGoalCountDiff =
            previousAlignedGoalCount === 0
              ? 0
              : ((currentAlignedGoalCount - previousAlignedGoalCount) /
                  previousAlignedGoalCount) *
                100;

          const computeResponse = {
            ...response.summary,
            fulfillmentProgress:
              response.summary.goals?.fulfillment -
              prevResponse.summary.goals?.fulfillment,
            goalCountProgress: Math.round(goalCountDiff),
            alignedGoalsDiff: alignedGoalCountDiff,
          };

          return computeResponse;
        }
      },
      [range.comparison.endDate, range.comparison.startDate],
    );

    const {
      data = {
        goals: {
          count: 0,
          fulfillment: 0,
        },
        goalCountProgress: 0,
        fulfillmentProgress: 0,
        alignedGoals: 0,
        alignedGoalsDiff: 0,
      },
      isLoading,
    } = useQuery(
      [
        'goal-widget',
        range,
        activeTab,
        selectedTeams,
        selectedMembers,
        selectedGroups,
      ],
      () =>
        fetchSpecifiedGoals({
          startDate: dayjs(range.current.startDate).format('MM-DD-YYYY'),
          endDate: dayjs(range.current.endDate).format('MM-DD-YYYY'),
          newReport: true,
          isKpi: false,
          state: 'active,past_due',
          department: selectedGroups || undefined,
          manager: selectedTeams
            ? selectedTeams
            : activeTab === 'team'
            ? auth.user.id
            : undefined,
          member: selectedMembers
            ? selectedMembers
            : activeTab === 'you'
            ? auth.user.id
            : undefined,
        }),
      {
        refetchOnWindowFocus: false,
      },
    );

    const goalDetails = [
      {
        name: `${capitalize(cfv().g_oal)}s`,
        showPercentProgress: true,
        value: `${data.goals?.count ?? ''}`,
        percentProgress: data.goalCountProgress,
      },
      {
        name: `${capitalize(cfv().g_oal)} progress`,
        showPercentProgress: true,
        value: `${data.goals?.fulfillment ?? ''}%`,
        percentProgress: data.fulfillmentProgress,
      },
      {
        name: `Aligned to company ${cfv().g_oal}s`,
        showPercentProgress: true,
        value: data.alignedGoals ?? '',
        percentProgress: data.alignedGoalsDiff,
      },
    ];

    return (
      <div
        className={`grid grid-flow-row gap-5 w-full h-auto grid-cols-2 sm:grid-cols-1 md:grid-cols-2 laptop:grid-cols-12`}>
        {goalDetails.map((goal) => (
          <GridItem w={4} key={goal.name}>
            <InsightCard
              key={goal.name}
              {...goal}
              isLoading={isLoading}
              showRangeType
              comparisonText={range.comparisonType}
              disableEdit
              config={{
                comparison: {
                  type: '',
                  value: '',
                },
                type: '',
                range: {
                  type: '',
                  value: '',
                },
              }}
            />
          </GridItem>
        ))}
      </div>
    );
  },
);
