import {ReactElement, useCallback, useMemo, useState} from 'react';
import {
  Wrapper,
  MobileWrapper,
  DesktopWrapper,
  StyledContainer,
  MediumWrapper,
  StyledEndWrapper,
  NameWrapper,
  IconWrapper,
  UpdateWrapper,
  TooltipWrapper,
  TitleWrapper,
  StyledEndWrapper2,
  ProgressWrapper,
  ActionWrapper,
  AvatarWrapper,
} from './styles';
import {AnimatePresence} from 'framer-motion';
import {Body2, Body1} from '../../../atoms/typography';
import {ProgressBar} from '../../progress-bar';
import {Avatar} from '../../../atoms/avatar';
import {MeasurementType} from '../../../interface';
import {Button} from '../../../atoms/button';
import {NestedViewIcon} from '@ui/atoms/icons';
import {UpdateMetricGoal} from '../update-goal';
import {QuestionIconWrapper} from '@pages/create-goal/create-goal.styles';
import {RadioChildSm} from '@ui/atoms/radio/radio';
import {useBreakpoints} from '../../../../utils/use-breakpoints';
import {StatusType} from '../../../../hooks';
import {observer} from 'mobx-react';
import {HorizontalSideRule, HorizontalSpacer} from '@ui/atoms/spacer/spacer';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Tooltip} from '../../tooltip/tooltip';
import {useFetchUserGoals} from '@hooks/goals.hook';
import {ArrowIcons} from '@ui/molecules/arrow-icons/arrow-icons';
import {KeyResultContext} from '@ui/atoms/context-wrapper/key-result-context';
import dayjs from 'dayjs';
import {
  Flex,
  FlexRowSpaceBetween,
  FlexRow,
  FlexRowEnd,
} from '../../../style/styles';
import {capitalize} from '@utils';
import {cfv} from '@utils/framework';
import {HubspotIcon} from '@ui/atoms/icons/apps/hubspot';
import {useStoreContext} from '../../../../store/store-context';
import {parseCurrencyValue} from '../../../../constants';

const commaNumber = require('comma-number');

export interface KeyMetricProps {
  goalId: string;
  keyResult: {
    id: string;
    goal: string;
    measurement: MeasurementType;
    percent: number;
    activities: any[];
    slug: string;
    context: null | string;
    source: any;
    state: string;
    keyResultAlignment?: {
      keyResult?: string;
      value?: string;
    };
    updatedAt: string;
    alignedKeyResults?: any[];
    title: string;
    user: {
      avatar: string;
      id: string;
      isUserDeleted?: boolean;
      name: string;
      reviewer: string;
    };
    currentValue: number;
    alignmentTarget?: number;
    startValue: number;
    percentageProgress: number;
    targetValue: number;
    currencySymbol?: any;
    goodValue?: number;
    stretchValue?: number;
  };
  goalAssignee: string;
  goalCreatedBy: string;
  showKeyResultProgress?: boolean;
  goalStatus: string;
  tabKind: string | undefined;
  variantType?: string;
  index: number;
  variant?: string;
  youCheckinsKind?: string;
}

export const EditableKeyMetric = observer(function ({
  keyResult,
  goalId,
  goalAssignee,
  showKeyResultProgress,
  goalStatus,
  tabKind,
  variant,
  youCheckinsKind,
}: KeyMetricProps) {
  const {
    id,
    measurement,
    title,
    user,
    currentValue,
    targetValue,
    goodValue,
    stretchValue,
    startValue,
    currencySymbol,
  } = keyResult;

  const [isEditing, setIsEditing] = useState(false);

  const screenSize = useBreakpoints();

  const getLastActivity =
    keyResult.activities?.[keyResult.activities.length - 1];

  const {refetch: goalsRefetch} = useFetchUserGoals();

  const {
    storeYouGoals: {youGoalsFilter, saveUpdate, updateGoalValue},
    checkinsStore: {saveUpdateV2},
    goalsState: {apps},
  } = useStoreContext();

  const updateEditing = useCallback(() => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      // TODO: manage updates of few information here and validation of fields
      setIsEditing(false);
    }
  }, [isEditing]);

  const metrics = {
    name: id,
    measurement,
    goalId: goalId,
    currentValue,
    targetValue,
    startValue,
    currencySymbol,
  };

  const [storeCurrentValue, setStoreCurrentValue] = useState(currentValue);

  const renderUpdate = useMemo(() => {
    return (
      <UpdateWrapper>
        <UpdateMetricGoal
          goalId={metrics.goalId}
          measurement={metrics.measurement}
          kind="metric"
          saveChanges={() => {
            updateEditing();
            if (isEditing) {
              if (tabKind === 'youcheckins') {
                saveUpdateV2(goalId);
              } else
                saveUpdate(goalId).then(() => {
                  goalsRefetch();
                });
            }
          }}
          name={metrics.name}
          currencySymbol={metrics.currencySymbol}
          storeCurrentValue={storeCurrentValue}
          currentValue={metrics.currentValue}
          targetValue={metrics.targetValue}
        />
      </UpdateWrapper>
    );
  }, [
    metrics.goalId,
    metrics.measurement,
    isEditing,
    tabKind,
    saveUpdateV2,
    goalId,
    saveUpdate,
    storeCurrentValue,
    goalsRefetch,
    updateEditing,
    metrics.name,
    metrics.currencySymbol,
    metrics.currentValue,
    metrics.targetValue,
  ]);

  const UpdateButton = () => {
    return (
      <Button
        kind={isEditing ? 'primary' : 'secondary'}
        width="sm"
        aria-label="update key metric"
        title="Update progress"
        type="button"
        disabled={
          goalStatus !== StatusType.INPROGRESS ||
          (user.id !== auth?.user.id && auth?.user.id !== goalAssignee) ||
          youGoalsFilter === 'archived' ||
          youCheckinsKind === 'viewuser' ||
          !!keyResult?.alignmentTarget
        }
        style={{
          padding: '8px 16px',

          marginRight: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
        onClick={() => {
          updateEditing();
          if (isEditing) {
            if (tabKind === 'youcheckins') {
              saveUpdateV2(goalId);
            } else saveUpdate(goalId).then(() => goalsRefetch());
            setStoreCurrentValue(currentValue);
          }
        }}>
        {isEditing ? 'Save changes' : 'Update '}
      </Button>
    );
  };
  const teamKeyResultBulletSvg = (
    <span>
      <svg
        width="33"
        height="26"
        viewBox="0 0 33 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="21"
          y="18"
          width="6.03553"
          height="6.03553"
          rx="0.5"
          transform="rotate(-45 21 18)"
          fill="#CECEDE"
        />
        <path d="M1 0V11.5C1 15.9183 4.58172 19.5 9 19.5H17" stroke="#CECEDE" />
      </svg>
    </span>
  );
  const {auth} = useStoreContext().authStore;
  const progress = keyResult.percentageProgress;

  const cancel = (measurement: MeasurementType) => {
    switch (measurement) {
      case MeasurementType.BINARY:
        if (storeCurrentValue === 1) {
          updateGoalValue(goalId, id, 'binary', 1);
        } else {
          updateGoalValue(goalId, id, 'binary', 0);
        }
        break;
      case MeasurementType.PERCENTAGE:
        updateGoalValue(goalId, id, 'percent', storeCurrentValue);
        break;
      case MeasurementType.CURRENCY:
        updateGoalValue(goalId, id, 'currency', storeCurrentValue);
        break;
      case MeasurementType.NUMERIC:
        updateGoalValue(goalId, id, 'numeric', storeCurrentValue);
        break;
      // no-default
    }
  };
  const keyResultValue = () => {
    return (
      <>
        <FlexRow>
          <span
            style={{
              color: '#5f5f8c',
              textOverflow: 'ellipsis',
              marginRight: 4,
              fontSize: '14px',
            }}>
            {(function () {
              const progressText = `${
                measurement.toUpperCase() === MeasurementType.CURRENCY &&
                currencySymbol
                  ? parseCurrencyValue(currencySymbol)
                  : measurement.toUpperCase() === MeasurementType.CURRENCY &&
                    !currencySymbol
                  ? parseCurrencyValue('')
                  : ''
              }${commaNumber(
                keyResult?.currentValue && keyResult.measurement !== 'BINARY'
                  ? keyResult?.currentValue || storeCurrentValue
                  : keyResult?.currentValue === 1 &&
                    keyResult.measurement === 'BINARY'
                  ? 'complete'
                  : !keyResult?.currentValue &&
                    keyResult.measurement === 'BINARY'
                  ? 'Incomplete'
                  : keyResult?.currentValue === 0 &&
                    keyResult.measurement === 'BINARY'
                  ? 'Incomplete'
                  : 0,
              )}${
                measurement.toUpperCase() === MeasurementType.PERCENTAGE
                  ? '%'
                  : ''
              } ${
                !keyResult?.currentValue && keyResult.measurement === 'BINARY'
                  ? ''
                  : keyResult?.currentValue === 1 &&
                    keyResult.measurement === 'BINARY'
                  ? ''
                  : 'of'
              }${' '}${
                measurement.toUpperCase() === MeasurementType.CURRENCY &&
                currencySymbol
                  ? parseCurrencyValue(currencySymbol)
                  : measurement.toUpperCase() === MeasurementType.CURRENCY &&
                    !currencySymbol
                  ? parseCurrencyValue('')
                  : ''
              }${commaNumber(
                keyResult.targetValue === 1 &&
                  keyResult.measurement === 'BINARY'
                  ? ''
                  : keyResult.targetValue,
              )}${
                measurement.toUpperCase() === MeasurementType.PERCENTAGE
                  ? '%'
                  : ''
              }`;

              return (
                <div>
                  {screenSize.isXs ? (
                    <>
                      <span>{'('}</span>
                      <Tooltip
                        text={progressText}
                        tooltipBody={progressText}
                        maxLength={25}
                      />
                      <span>{progressText.length > 25 ? ' ...)' : ')'}</span>
                    </>
                  ) : (
                    <>
                      <span>{'('}</span>
                      <Tooltip
                        text={progressText}
                        tooltipBody={progressText}
                        maxLength={15}
                      />
                      <span>{progressText.length > 15 ? ' ...)' : ')'}</span>
                    </>
                  )}
                </div>
              );
            })()}
          </span>
        </FlexRow>
      </>
    );
  };

  const isValuePositive = (value: number): boolean => {
    if (value > 0) {
      return true;
    }
    return false;
  };
  const CancelButton = () => {
    return (
      <>
        <Button
          kind={'secondary'}
          width="sm"
          aria-label="cancel"
          title="Cancel"
          type="button"
          disabled={
            goalStatus !== StatusType.INPROGRESS ||
            (user.id !== auth?.user.id && auth?.user.id !== goalAssignee) ||
            youGoalsFilter === 'archived'
          }
          style={{
            padding: '8px 16px',
          }}
          onClick={() => {
            updateEditing();
            cancel(measurement);
          }}>
          Cancel
        </Button>
      </>
    );
  };
  const SourceButton = ({
    defaultButton,
    showIconOnly,
  }: {
    defaultButton: ReactElement | null;
    showIconOnly?: boolean;
  }) => {
    const hubspot = apps?.find((app: any) => app.app === 'Hubspot');

    switch (keyResult?.source?.app) {
      case 'Hubspot':
        return (
          <TooltipWrapper>
            <div className="tooltip">
              {showIconOnly ? (
                <div
                  style={{
                    width: '36px',
                    height: '28px',
                    display: 'flex',
                    borderRadius: '10px',
                    padding: '4px 8px',
                    border:
                      keyResult?.source?.error !== null || hubspot?.error
                        ? '1px solid #D52A2A'
                        : undefined,
                    color:
                      keyResult?.source?.error !== null || hubspot?.error
                        ? '#D52A2A'
                        : '#5F5F8C',

                    background:
                      keyResult?.source?.error !== null || hubspot?.error
                        ? 'white'
                        : '#F6F6FE',
                    alignItems: 'center',

                    justifyContent: 'center',
                  }}>
                  <IconWrapper showIconOnly={showIconOnly}>
                    <HubspotIcon />
                  </IconWrapper>
                </div>
              ) : (
                <Button
                  disabled={true}
                  className="flex items-center justify-center overflow-hidden"
                  style={{
                    width: '82px',
                    height: '38px',

                    border:
                      keyResult?.source?.error !== null || hubspot?.error
                        ? '1px solid #D52A2A'
                        : undefined,
                    color:
                      keyResult?.source?.error !== null || hubspot?.error
                        ? '#D52A2A'
                        : '#5F5F8C',
                  }}>
                  View
                  <IconWrapper>
                    <HubspotIcon />
                  </IconWrapper>
                </Button>
              )}
              <div className="tooltiptext">
                <Flex>
                  <HubspotIcon />{' '}
                  <Body1 weight="semibold" style={{marginLeft: '10px'}}>
                    Hubspot
                  </Body1>
                </Flex>
                <div style={{marginTop: '10px'}}>
                  {keyResult?.source?.error !== null || hubspot?.error ? (
                    <Body2 style={{fontSize: '12px'}} kind="textBody">
                      There was an error updating this{' '}
                      {cfv(undefined, true).k_r}. Please reconnect and we’ll try
                      again.
                    </Body2>
                  ) : (
                    <Body2 style={{fontSize: '12px'}} kind="textBody">
                      {capitalize(cfv(undefined, true).k_r)} is updated
                      automatically.
                      <br />
                      {keyResult.updatedAt === null
                        ? 'No Sync records'
                        : ` Last sync on ${dayjs(keyResult.updatedAt).format(
                            `DD MMM. YYYY`,
                          )} at ${dayjs(keyResult.updatedAt).format(
                            `hh:mma`,
                          )}  `}
                    </Body2>
                  )}
                </div>
              </div>
            </div>
          </TooltipWrapper>
        );
      case null:
        return isEditing ? <></> : defaultButton;

      case undefined:
        return isEditing ? <></> : defaultButton;
      default:
        return null;
    }
  };
  const excludeUpdateButton =
    (tabKind && tabKind === 'team') ||
    (variant &&
      ['companyReportPDF', 'companyTeams', 'all-objectives'].includes(
        variant,
      )) ||
    showKeyResultProgress;

  const shouldRenderButton =
    variant === 'companyTeams' && keyResult?.source?.app;

  const showSourceButton = shouldRenderButton ? (
    <SourceButton defaultButton={null} showIconOnly={true} />
  ) : (
    <HorizontalSpacer size="30px" />
  );

  return (
    <Wrapper>
      <StyledContainer
        tabKind={
          variant && variant === 'companyReportPDF'
            ? 'companyReportPDF'
            : variant && variant === 'companyTeams'
            ? 'companyTeams'
            : tabKind
        }
        isEditing={isEditing}>
        <DesktopWrapper>
          <TitleWrapper>
            <Body2 kind="textBody">
              {tabKind && tabKind === 'team' && teamKeyResultBulletSvg} {title}
            </Body2>
            {(keyResult.alignmentTarget ||
              keyResult.keyResultAlignment?.keyResult) && (
              <QuestionIconWrapper tooltip={true}>
                <RadioChildSm bg="#084B8A" checked style={{padding: '2px'}} />
                <span
                  className="tooltip"
                  style={{
                    top: '0%',
                    left: '-62px',
                    width: '230px',
                    padding: '10px',
                  }}>
                  <Body1 kind="textBody">
                    This {cfv().k_r} is connected to other {cfv().k_rs}.
                  </Body1>
                  <VerticalSpacer size="12px" />
                  <Body2 kind="textBody">
                    Click <NestedViewIcon /> on goal page to view.
                  </Body2>
                </span>
              </QuestionIconWrapper>
            )}
          </TitleWrapper>
        </DesktopWrapper>
        <div>
          <StyledEndWrapper
            tabKind={
              variant && variant === 'companyReportPDF'
                ? 'companyReportPDF'
                : variant && variant === 'companyTeams'
                ? 'companyTeams'
                : tabKind
            }
            isEditing={isEditing}>
            {variant && variant === 'companyReportPDF' ? (
              <NameWrapper>
                <span>{user.name}</span>
              </NameWrapper>
            ) : (
              <AvatarWrapper>
                <Avatar
                  tooltip={true}
                  name={user.name}
                  src={user.avatar}
                  isUserDeleted={user?.isUserDeleted}
                  alt={user.name}
                  userId={user.id}
                />
              </AvatarWrapper>
            )}

            <div className="action_wrapper-container">
              <div
                style={
                  variant === 'companyTeams'
                    ? {}
                    : {
                        display: 'flex',
                        alignItems: 'center',
                      }
                }>
                <FlexRowEnd>
                  <ProgressWrapper>
                    <ProgressBar
                      state={progress + '%'}
                      percent={progress}
                      measurement={measurement}
                      kpi={{
                        current: currentValue,
                        base: targetValue,
                        good: goodValue,
                        stretch: stretchValue,
                      }}
                    />
                  </ProgressWrapper>
                  <div style={{marginLeft: '8px'}}>{keyResultValue()}</div>
                </FlexRowEnd>
              </div>
              <div style={{display: 'flex'}}>
                {excludeUpdateButton
                  ? showSourceButton
                  : !isEditing && (
                      <ActionWrapper isEditing={isEditing} tabKind={tabKind}>
                        <SourceButton defaultButton={<UpdateButton />} />
                      </ActionWrapper>
                    )}
                {!(tabKind && tabKind === 'team') && isEditing && (
                  <ActionWrapper>
                    <CancelButton />
                  </ActionWrapper>
                )}
              </div>
            </div>
          </StyledEndWrapper>
        </div>
      </StyledContainer>
      <DesktopWrapper>
        {youCheckinsKind === 'viewuser' && keyResult.context && (
          <>
            <Body2 weight="semibold">More context about the update:</Body2>
            <VerticalSpacer size="8px" />
            <KeyResultContext text={keyResult.context} />
          </>
        )}
        {youCheckinsKind === 'viewuser' && (
          <>
            <VerticalSpacer size="7px" />

            <FlexRowSpaceBetween>
              <Body2 kind="textBody" style={{fontSize: '12px'}}>
                Last updated {dayjs(keyResult.updatedAt).format('MMM DD. YYYY')}{' '}
              </Body2>
              {showKeyResultProgress && (
                <FlexRow>
                  <Body2 style={{fontSize: '12px'}} kind="textMedium">
                    {' '}
                    {isValuePositive(getLastActivity?.changeInValue)
                      ? `+${commaNumber(getLastActivity?.changeInValue)}`
                      : `${commaNumber(getLastActivity?.changeInValue || 0)}`}
                  </Body2>
                  <HorizontalSideRule
                    size="16px"
                    style={{margin: '0px 12px'}}
                  />
                  <FlexRow>
                    <ArrowIcons
                      value={getLastActivity?.percentageChangeInValue}
                    />
                    <Body2
                      style={{marginLeft: '1px'}}
                      kind={
                        getLastActivity?.percentageChangeInValue > 0
                          ? 'green300'
                          : getLastActivity?.percentageChangeInValue < 0
                          ? 'red400'
                          : 'textMedium'
                      }>
                      {getLastActivity?.percentageChangeInValue || 0}%
                    </Body2>
                  </FlexRow>
                </FlexRow>
              )}
            </FlexRowSpaceBetween>
          </>
        )}
        {tabKind && tabKind === 'team' ? null : (
          <AnimatePresence>{isEditing && renderUpdate}</AnimatePresence>
        )}
      </DesktopWrapper>

      <StyledEndWrapper2>
        <Flex alignItems="center" justifyContent="space-between">
          <TitleWrapper>
            <Body2 kind="textBody">
              {tabKind && tabKind === 'team' && teamKeyResultBulletSvg} {title}
            </Body2>
          </TitleWrapper>
          <MobileWrapper>
            <Avatar
              tooltip={true}
              name={user.name}
              src={user.avatar}
              isUserDeleted={user?.isUserDeleted}
              alt={user.name}
              userId={user.id}
            />
          </MobileWrapper>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          style={{marginTop: '10px'}}>
          <FlexRow
            style={{
              paddingTop: '5px',
              width: '100%',
            }}>
            <ProgressBar
              state={progress + '%'}
              percent={progress}
              type={'keyResult'}
              measurement={measurement}
              kpi={{
                current: currentValue,
                base: targetValue,
                good: goodValue,
                stretch: stretchValue,
              }}
            />
            <HorizontalSpacer size="4px" />
            <div>{keyResultValue()}</div>
          </FlexRow>
        </Flex>
        <FlexRowEnd style={{width: '100%'}}>
          <div
            style={{
              display: 'flex',
              // marginLeft: 7,
            }}>
            <div>
              {excludeUpdateButton ? (
                showSourceButton
              ) : (
                <>
                  {!isEditing ? (
                    <>
                      <SourceButton defaultButton={<UpdateButton />} />
                    </>
                  ) : (
                    <MediumWrapper>
                      <SourceButton defaultButton={<UpdateButton />} />
                    </MediumWrapper>
                  )}
                </>
              )}
            </div>
            <div>
              {!(tabKind && tabKind === 'team') && isEditing && (
                <CancelButton />
              )}
            </div>
          </div>
        </FlexRowEnd>
        <MobileWrapper>
          <div className="render-update">
            {tabKind && tabKind === 'team' ? null : (
              <AnimatePresence>{isEditing && renderUpdate}</AnimatePresence>
            )}
          </div>
        </MobileWrapper>
      </StyledEndWrapper2>
    </Wrapper>
  );
});
// EditableKeyMetric.displayName = 'EditableKeyMetric';
