import PageLayout from '@ui/templates/page-template/page-layout';
import styled from 'styled-components';
import {Controller} from 'react-hook-form';
import {Label} from '@ui/atoms/label';
import SelectCompanyValues from '../components/select-values';
import {FeedBackType} from '../components/feedback-type';
import FeedBackInput from '../components/feedback';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {observer} from 'mobx-react-lite';
import {MultiOptionsSelectField} from '@ui/molecules/select/multi-options';
import {TextField} from '@ui/molecules/field/textfield';
import {useFetchHook} from '@hooks/query-hook';
import {GoalsSelectField} from '@ui/molecules/select/goal-select-field/goal-select-field';
import {Button} from '@ui/atoms/button';
import {SelectField} from '@ui/molecules/select/selectfield';
import {UserSelectField} from '@ui/molecules/select/user';
import useCreateFeedbackHook from './create-feedback-hook';
import {useNavigate} from 'react-router';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';

import {useStoreContext} from '@store/store-context';

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 40%;

    margin-bottom: 30px;
    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 45%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
const CreateFeedback = () => {
  const navigate = useNavigate();
  const {
    authStore: {auth},
    groupStore: {groups},
    usersStore: {users: allUsers},
    groupTypeStore: {groupType},
  } = useStoreContext();
  const {isPerformanceActive} = useWorkspaceUseCase();

  const {
    handleFormValueChange,
    users,
    isSubmitting,
    errors,
    handleSubmit,
    onSubmit,
    feedbackFor,
    initGoals,
    FeedbackIsPraise,
    selectedUsers,
    id,
    isDisabled,
    control,
    feedbackForOptions,
  } = useCreateFeedbackHook();

  const {data} = useFetchHook(
    ['feedback-init-goals', groups, groupType],
    () => initGoals(1, 'networkLevel'),
    {
      enabled:
        groups?.length > 0 && groupType?.length > 0 && isPerformanceActive,
    },
  );

  const feedbackName = FeedbackIsPraise ? 'praise' : 'feedback';

  return (
    <PageLayout
      title={`Write ${feedbackName}`}
      guideUrl="https://peoplebeam.myhcpage.com/en-us/"
      onClick={() => navigate(-1)}>
      <FormWrapper>
        <div className="form">
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <Label>Who is this {feedbackName} for?</Label>
            <VerticalSpacer size="8px" />

            <Controller
              control={control}
              name="users"
              render={({onBlur, value}) =>
                FeedbackIsPraise ? (
                  <MultiOptionsSelectField
                    select="people"
                    name="users"
                    performance={true}
                    showCancelIcon={false}
                    noOptionMessage={'No Member Found'}
                    helper={errors.users?.[0]?.message || ''}
                    state={errors.users?.[0] ? 'error' : 'default'}
                    type="dropdownOnly"
                    placeholder="Select member"
                    options={users.map((user) => {
                      return {
                        value: user.value,
                        label: user.label.name,
                      };
                    })}
                    onChange={(data: {value: string; label: string}[]) => {
                      handleFormValueChange(
                        data?.map((d) => d.value),
                        'users',
                      );
                    }}
                  />
                ) : (
                  <UserSelectField
                    onBlur={onBlur}
                    name="users"
                    defaultValue={value?.[0]}
                    disabled={!!id}
                    helper={errors.users?.[0]?.message || ''}
                    state={errors.users?.[0] ? 'error' : 'default'}
                    onChange={(data: {value: string}) => {
                      handleFormValueChange([data.value], 'users');

                      if (data?.value) {
                        const getUser = allUsers.find(
                          (_user) => _user.id === data?.value,
                        );

                        if (getUser?.reviewer?.id === auth.user.id) {
                          handleFormValueChange('private', 'sharing');
                          return;
                        }
                        if (getUser?.reviewer?.id !== auth.user.id) {
                          handleFormValueChange('manager', 'sharing');
                          return;
                        }
                      }
                    }}
                    placeholder="Select member"
                    options={users}
                    fieldNotFoundPlaceHolder={(searchTerm?: string) =>
                      `Oops! Seems there is no ${searchTerm} found in this workspace`
                    }
                  />
                )
              }
            />

            <Label>What’s the {feedbackName} for?</Label>
            <VerticalSpacer size="8px" />
            <Controller
              control={control}
              name="feedbackFor"
              render={({onBlur, value}) =>
                isPerformanceActive ? (
                  <SelectField
                    onBlur={onBlur}
                    name="feedbackFor"
                    value={value}
                    defaultValue={value}
                    borderRadius={10}
                    disabled={!!id}
                    helper={errors.feedbackFor?.message || ''}
                    state={errors.feedbackFor ? 'error' : 'default'}
                    onChange={(data: {value: string}) => {
                      handleFormValueChange(data?.value, 'feedbackFor');
                      handleFormValueChange(null, 'subjectName');
                    }}
                    placeholder="Select "
                    options={feedbackForOptions}
                    fieldNotFoundPlaceHolder={(searchTerm?: string) =>
                      `Oops! Seems there is no ${searchTerm} found`
                    }
                  />
                ) : (
                  <></>
                )
              }
            />

            {feedbackFor === 'goal' && data && (
              <Controller
                control={control}
                name="subjectId"
                render={({onBlur, value}) => (
                  <>
                    <GoalsSelectField
                      placeholder={`Search goal`}
                      defaultValue={value}
                      disabled={!!id}
                      //   ref={UserSelect3Ref}
                      options={data}
                      onChange={(data: {value: string; label: string}) => {
                        handleFormValueChange(data.label, 'subjectName');
                        handleFormValueChange(data.value, 'subjectId');
                      }}
                    />
                  </>
                )}
              />
            )}

            {feedbackFor === 'custom' && (
              <Controller
                control={control}
                name="subjectName"
                render={({onBlur, value}) => (
                  <>
                    <TextField
                      onChange={(event) =>
                        handleFormValueChange(event.target.value, 'subjectName')
                      }
                      value={value}
                      onBlur={onBlur}
                      disabled={!!id}
                      maxLength={45}
                      placeholder={`Please provide a ${feedbackName} subject (max. 45 characters).`}
                      inputStyle={{borderRadius: 10}}
                    />
                  </>
                )}
              />
            )}

            <Controller
              control={control}
              name="subjectName"
              render={({onBlur, value}) => <></>}
            />
            <Controller
              control={control}
              name="impression"
              render={({onBlur, value}) => <></>}
            />
            <>
              <Controller
                control={control}
                name="feedback"
                render={({onBlur, value}) => (
                  <FeedBackInput
                    error={errors.feedback?.message || ''}
                    updateImpression={(value) => {
                      handleFormValueChange(value, 'impression');
                    }}
                    updateFeedbackInput={(value) => {
                      handleFormValueChange(value, 'feedback');
                    }}
                  />
                )}
              />

              <VerticalSpacer size="32px" />
              {auth.user.workspace.settings.companyValues.values?.length >
                0 && (
                <>
                  <Label>
                    Give praise based on values{' '}
                    <span
                      style={{
                        fontWeight: 400,
                        color: '#5F5F8C',
                      }}>
                      (optional){' '}
                    </span>
                  </Label>
                  <VerticalSpacer size="8px" />

                  <Controller
                    control={control}
                    name="values"
                    render={({onBlur, value}) => (
                      <SelectCompanyValues
                        updateValue={(value) =>
                          handleFormValueChange(value, 'values')
                        }
                      />
                    )}
                  />
                </>
              )}

              <Label>Sharing</Label>
              <VerticalSpacer size="8px" />
              <Controller
                control={control}
                name="sharing"
                render={({onBlur, value}) => (
                  <FeedBackType
                    error={errors.sharing?.message || ''}
                    value={value}
                    disabled={FeedbackIsPraise}
                    user={selectedUsers?.[0]}
                    includePublic={FeedbackIsPraise}
                    updateValue={(value) =>
                      handleFormValueChange(value, 'sharing')
                    }
                  />
                )}
              />
            </>
            <VerticalSpacer size="16px" />
            <Button
              width="full"
              type="submit"
              data-form-action={true}
              isLoading={isSubmitting}
              disabled={isSubmitting || isDisabled}
              aria-busy={isSubmitting}>
              Send {feedbackName}
            </Button>
          </form>
        </div>
      </FormWrapper>
    </PageLayout>
  );
};
export default observer(CreateFeedback);
