import {Button} from '@ui/atoms/button';
import {CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';
import {TextField} from '@ui/molecules/field/textfield';
import {Modal} from '@ui/molecules/modal';
import React, {useState, useCallback} from 'react';
import {ModalRenameWrapper} from '../insights/styles';

interface RenameSectionProps {
  renameSectionId: string;
  defaultTitle?: string;
  handleRenameSection: (data: {name: string; slug: string}) => void;
  setRenameSectionModal: (value: string) => void;
}
export const RenameSection = ({
  renameSectionId,
  handleRenameSection,
  defaultTitle = '',
  setRenameSectionModal,
}: RenameSectionProps) => {
  const [text, setText] = useState(defaultTitle);

  return (
    <div>
      {' '}
      <Modal open={!!renameSectionId} onClose={() => setRenameSectionModal('')}>
        <ModalRenameWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={() => setRenameSectionModal('')}
            width="md"
          />
          <VerticalSpacer size="16px" />
          <ModalCard title="">
            <div>
              <Headline2>Rename section </Headline2>

              <VerticalSpacer size="20px" />
              <TextField
                maxLength={25}
                label="Title"
                value={text}
                placeholder="Enter title (max 25 characters)"
                onChange={(event) => setText(event.target.value)}
                name="name"
              />

              <VerticalSpacer size="14px" />

              <Button
                kind="primary"
                width="full"
                onClick={() => {
                  handleRenameSection({name: text, slug: renameSectionId});

                  setRenameSectionModal('');

                  setText('');
                }}
                data-form-action={true}
                disabled={!text || text.length > 25}>
                Rename
              </Button>
            </div>
          </ModalCard>
        </ModalRenameWrapper>
      </Modal>
    </div>
  );
};

interface CreateDashboardProps {
  isOpen: boolean;
  handleClose: (value: string) => void;
}

export const CreateDashboardModal = ({
  isOpen,
  handleClose,
}: CreateDashboardProps) => {
  const [text, setText] = useState('');

  const handleModalClose = useCallback(() => {
    handleClose('');
  }, [handleClose]);

  const handleCreate = useCallback(() => {
    handleClose(text);
    setText('');
  }, [handleClose, text]);

  return (
    <div>
      <Modal open={isOpen} onClose={handleModalClose}>
        <ModalRenameWrapper>
          <BackAction
            icon={<CancelIcon />}
            title="Close"
            onClick={handleModalClose}
            width="md"
          />
          <VerticalSpacer size="16px" />
          <ModalCard title="">
            <div>
              <Headline2>New dashboard</Headline2>

              <VerticalSpacer size="20px" />
              <TextField
                maxLength={25}
                label="Title"
                value={text}
                placeholder="Enter title (max 25 characters)"
                onChange={(event) => setText(event.target.value)}
                name="name"
              />

              <VerticalSpacer size="14px" />

              <Button
                kind="primary"
                width="full"
                onClick={handleCreate}
                data-form-action={true}
                disabled={!text || text.length > 25}>
                Create
              </Button>
            </div>
          </ModalCard>
        </ModalRenameWrapper>
      </Modal>
    </div>
  );
};
