import {authStore} from '../store/stores/auth-store';

export const workspaceFramework = authStore?.auth?.user?.workspace?.framework;
export const cfv = (abbrev?: boolean, display?: boolean) => {
  const framework = authStore?.auth?.user?.workspace?.framework;
  const goalName = authStore?.auth?.user?.workspace?.config.goalName;
  const keyResultName = authStore?.auth?.user?.workspace?.config.keyResultName;

  switch (framework) {
    case 'okr':
      if (abbrev && !display) {
        return {
          g_oals: `${goalName}s`,
          g_oal: goalName === 'goal' ? 'objective' : goalName,
          k_rs: `${keyResultName}s`,
          k_r: keyResultName,
        };
      } else {
        if (display) {
          return {
            g_oals: `goals`,
            g_oal: 'goal',
            k_rs: `key results`,
            k_r: 'key result',
          };
        } else {
          return {
            g_oals: `${goalName}s`,
            g_oal: goalName,
            k_rs: `${keyResultName}s`,
            k_r: keyResultName,
          };
        }
      }
    case 'kpi':
      if (goalName === 'goal' && abbrev) {
        return {
          g_oals: 'focus areas',
          g_oal: 'focus area',
          k_rs: "KPI's",
          k_r: 'KPI',
        };
      } else if (abbrev && goalName !== 'goal' && !display) {
        return {
          g_oals: `${goalName}s`,
          g_oal: goalName,
          k_rs: `${keyResultName}s`,
          k_r: keyResultName,
        };
      } else {
        if (goalName !== 'goal' && !display) {
          return {
            g_oals: `${goalName}s`,
            g_oal: goalName,
            k_rs: `${keyResultName}s`,
            k_r: keyResultName,
          };
        } else {
          if (display) {
            return {
              g_oals: 'focus areas',
              g_oal: 'focus area',
              k_rs: 'key performance indicators',
              k_r: 'key performance indicator',
            };
          } else {
            return {
              g_oals: 'focus areas',
              g_oal: 'focus area',
              k_rs: 'key performance indicators',
              k_r: 'key performance indicator',
            };
          }
        }
      }
    default:
      return {
        g_oals: `${goalName}s`,
        g_oal: goalName,
        k_rs: `${keyResultName}s`,
        k_r: keyResultName,
      };
  }
};
export const computeFrameworkVars = cfv;
export const kpiValueWeighting = (currentValue: any, kpi: any) => {
  if (currentValue) {
    if (kpi?.base) {
      if (currentValue <= kpi?.base) {
        return 'Base';
      } else {
        if (kpi?.good && kpi?.stretch) {
          if (currentValue > kpi.base && currentValue < kpi.good) {
            return 'Base';
          } else if (currentValue >= kpi.good && currentValue < kpi.stretch) {
            return 'Good';
          } else if (currentValue >= kpi.stretch) {
            return 'Stretch';
          }
        } else {
          return 'Base';
        }
      }
    }
  } else {
    return 'Base';
  }
};
