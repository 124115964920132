import {forwardRef, ReactElement, Ref, useMemo, ReactNode} from 'react';
import styled from 'styled-components';
import ReactSelect, {
  components,
  SingleValueProps,
  PlaceholderProps,
  IndicatorProps,
} from 'react-select';
import {motion} from 'framer-motion';
import {SelectFieldProps} from '../selectfield/selectfield';
import {Label} from './style';
import {Body1} from '../../../atoms/typography/body1';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {getCurrencyStyles, IndicatorWrapper} from '../shared';

import {Helper} from '../../../atoms/helper';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {InputState} from '../../../../hooks';
import {getColorFromTheme} from '../../../ui-utils';
import {CurrencyType} from '../../../interface';

export const Currencies: Map<CurrencyType, string> = new Map([
  [CurrencyType.DOLLAR, '$ Dollars'],
  [CurrencyType.EURO, '€ Euros'],
  [CurrencyType.POUND, '£ Pounds'],
  [CurrencyType.NAIRA, '₦ Naira'],
  [CurrencyType.BITCOIN, '₿ Bitcoin'],
]);

const {
  DropdownIndicator: SelectDropdownIndicator,
  Placeholder: SelectPlaceholder,
  SingleValue: SelectSingleValue,
} = components;
export interface CurrencySelectProps extends SelectFieldProps {
  label?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

export interface TOption {
  value: string;
  label: string | ReactNode;
}

const Wrapper = styled.label`
  position: relative;
  display: flex;
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 10px;
  cursor: pointer;
  justify-content: space-between;
  margin-top: 8px;
  min-height: 52px;
  outline: 0 !important;
  position: relative;
  transition: 200ms ease all;
  box-sizing: border-box;
  color: #1e1e2f;

  &:hover,
  &:focus,
  &:active {
    border-color: #e4e5fb;
    box-shadow: 0 0 0 2px #e4e5fb;
  }
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  display: inline-block;
  left: 0;
  width: 100%;
  border-radius: 8px;
  background-color: ${getColorFromTheme('white')};
  z-index: 3;
`;

const CustomBody1 = styled(Body1)`
  width: max-content;
`;

const SingleValue = (props: SingleValueProps<TOption>) => {
  return (
    <SelectSingleValue {...props}>
      <Body1 kind="textDark">{props.children as string}</Body1>
    </SelectSingleValue>
  );
};

const Placeholder = (props: PlaceholderProps<TOption, false>) => {
  return (
    <SelectPlaceholder {...props}>
      <CustomBody1 kind="textBody">{props.children}</CustomBody1>
    </SelectPlaceholder>
  );
};

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <SelectDropdownIndicator {...props}>
      <IndicatorWrapper>
        <motion.span
          initial={{rotate: 0}}
          animate={{
            rotate: props.selectProps.menuIsOpen ? 180 : 0,
            marginTop: props.selectProps.menuIsOpen ? '-4px' : '1px',
          }}
          transition={{
            duration: 0.3,
          }}
          key="select-toggled">
          <ArrowHeadDownIcon />
        </motion.span>
      </IndicatorWrapper>
    </SelectDropdownIndicator>
  );
};

export const CurrencySelect = forwardRef(function (
  {placeholder, state, helper, options, onChange, ...rest}: CurrencySelectProps,
  ref: Ref<ReactSelect<TOption>>,
) {
  const styles = useMemo(() => getCurrencyStyles<TOption, false>(), []);

  return (
    <FieldWrapper>
      <Wrapper>
        <Label>
          <Body1>Currency</Body1>
        </Label>
        <div style={{width: '100%'}}>
          <ReactSelect
            {...rest}
            isClearable={false}
            isSearchable={false}
            placeholder={placeholder}
            onChange={onChange}
            ref={ref}
            components={{
              DropdownIndicator,
              IndicatorSeparator: null,
              Placeholder,
              SingleValue,
            }}
            options={options}
            styles={styles}
            theme={(theme) => ({
              ...theme,
              borderRadius: 10,
              spacing: {
                baseUnit: 6,
                controlHeight: 40,
                menuGutter: 6,
              },
            })}
          />
        </div>
      </Wrapper>
      <Helper aria-label="helper" state={state} children={helper} />
    </FieldWrapper>
  );
});

CurrencySelect.displayName = 'CurrencySelect';
