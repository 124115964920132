import React, {memo, useCallback, useMemo, useState} from 'react';
import {Body2, Headline3} from '../../../../../../../../ui/atoms/typography';
import {
  TableContainer,
  SecondRowWrapper,
  HeadlineWrapperContainer,
  HeadlineWrapper,
  ItemWrapperContainer,
  ItemWrapper,
  ItemLoaderWrapper,
  ModalCardWrapper,
  ActionWrapper,
} from './general-questions.styles';
import {Button} from '../../../../../../../../ui/atoms/button';
import {Modal} from '../../../../../../../../ui/molecules/modal';
import {ModalCard} from '../../../../../../../../ui/layouts/modal-card';
import dayjs from 'dayjs';
import {UserListCard} from '@ui/molecules/user/list-card';
import {CreateQuestionForm} from '../create-questions/create-question.form';
import {useCompanyCheckinsHook} from '../../../company-checkins.hooks';
import {useStoreContext} from '../../../../../../../../store/store-context';
import {ICreateQuestionResponseDataSingle} from '../../../company-checkins.interface';
import {CompanyCheckinsRequestImpl} from '../../../company-checkins.request';
import {CompanyCheckinsController} from '../../../company-checkins.controller';
import {observer} from 'mobx-react';
import {EmptyPlaceholder} from '../../../../../../../../ui/atoms/empty-placeholder';
import {cloudinary} from '../../../../../../../../assets/images/cloudinary';
import {TextTag2Group} from '@ui/atoms/tag/tag';
import {Avatars} from '@ui/molecules/avatars';
import {useQuery} from 'react-query';
import {DropdownVerticalSecondary} from '@ui/molecules/dropdown-vertical';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {ItemLoader} from '@ui/organisms/item-loader';
import {FlexRowCenter} from '@ui/style/styles';

export const GeneralQuestions = observer(({status}: {status?: string}) => {
  const {getCheckinsQuestions} = useCompanyCheckinsHook();
  const {
    checkinsStore: {questions},
  } = useStoreContext();

  const {isLoading} = useQuery(['general-questions', status], () =>
    getCheckinsQuestions({status}),
  );

  const [editModalOpen, setEditModalOpen] = useState(false);

  const [modalBatch, setModalBatch] = useState<null | string>(null);

  const [batchType, setBatchType] = useState<null | string>(null);

  const openModal = (batch: string, type: string) => {
    setModalBatch(batch);
    setBatchType(type);
    setEditModalOpen(true);
  };

  const closeModal = () => {
    setModalBatch(null);
    setBatchType(null);
    setEditModalOpen(false);
  };

  const EmptyGeneralQuestions = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: cloudinary.emptyGoals,
      style: {height: '50%', width: '50%'},
    },
    size: 'empty',
    subtitle: '',
    title: `No questions found`,
  };

  return (
    <>
      <TableContainer>
        <div className="w-full flex items-start">
          <div className="w-[90%] overflow-hidden">
            <SecondRowWrapper>
              <HeadlineWrapperContainer className="header">
                <HeadlineWrapper>
                  <Body2 kind="textBody">Participants</Body2>
                  <Body2 kind="textBody">Number of questions</Body2>
                  <Body2 kind="textBody">Status</Body2>
                  <Body2 kind="textBody">Frequency</Body2>
                  <Body2 kind="textBody">Last run</Body2>
                </HeadlineWrapper>
              </HeadlineWrapperContainer>

              {!isLoading &&
                questions?.map(
                  (
                    question: ICreateQuestionResponseDataSingle,
                    key: number,
                  ) => <QuestionItem key={key + 'yyyy'} question={question} />,
                )}
            </SecondRowWrapper>
          </div>
          <div className="w-[15%]">
            <HeadlineWrapperContainer className="pl-10">
              <FlexRowCenter className="w-full">
                <Body2 kind="textBody" align="center">
                  Actions
                </Body2>
              </FlexRowCenter>
            </HeadlineWrapperContainer>

            {!isLoading &&
              questions?.map(
                (question: ICreateQuestionResponseDataSingle, key: number) => (
                  <QuestionActions
                    key={key + 'yyyy'}
                    question={question}
                    openModal={openModal}
                  />
                ),
              )}
          </div>
        </div>

        {isLoading && (
          <ItemWrapperContainer>
            <ItemLoaderWrapper>
              <ItemLoader />
            </ItemLoaderWrapper>
          </ItemWrapperContainer>
        )}
        {!isLoading && questions?.length === 0 && (
          <div
            style={{
              textAlign: 'center',
            }}>
            <EmptyPlaceholder {...EmptyGeneralQuestions} />
          </div>
        )}
      </TableContainer>
      <Modal
        open={editModalOpen && modalBatch !== null && batchType !== null}
        onClose={closeModal}
        fullHeight>
        <ModalCardWrapper>
          <div className="child">
            <ModalCard align="center" title="" fullHeight>
              <CreateQuestionForm
                modalBatch={modalBatch}
                batchType={batchType}
                editMode
                onCloseEditModal={closeModal}
              />
            </ModalCard>
          </div>
        </ModalCardWrapper>
      </Modal>
    </>
  );
});

interface QuestionItemProps {
  question: ICreateQuestionResponseDataSingle;
}
const QuestionItem = memo(({question}: QuestionItemProps) => {
  const {
    groupStore: {groups},
    usersStore: {users},
  } = useStoreContext();

  const questionGroups = question?.target?.ids || [];
  const isGroup = question.target.type === 'group';
  const isGeneral = question.target.type === 'general';
  const isIndividual = question.target.type === 'individual';

  const isUpcoming = question?.status === 'upcoming';

  const parseSingleUser = (id: string) => {
    return users?.filter((user) => user?.id === id) as any;
  };

  const singleIndividualUserFromArr = (individual: any) => {
    return parseSingleUser(individual?.target?.ids[0])[0] as any;
  };

  const parseName = (data: any) => {
    return data?.firstName && data?.lastName
      ? `${data?.firstName} ${data?.lastName}`
      : data?.email;
  };

  const generateAvatars = (ids: string[]) => {
    return ids.map((id) => ({
      src: parseSingleUser(id)[0]?.avatar?.url,
      name: parseName(parseSingleUser(id)[0]),
      id,
    }));
  };

  return (
    <ItemWrapperContainer>
      <ItemWrapper>
        <div>
          {isGroup && (
            <TextTag2Group
              list={questionGroups.map((grp: string, key: number) =>
                groups?.find((grdId) => grdId.id === grp)
                  ? groups?.find((grdId) => grdId.id === grp)?.name || ''
                  : 'Deleted group',
              )}
            />
          )}
          {isIndividual && (
            <>
              {question?.target?.ids?.length === 1 ? (
                <UserListCard
                  tooltip={true}
                  userId={singleIndividualUserFromArr(question)?.id}
                  type="secondary"
                  reviewer={parseName(
                    singleIndividualUserFromArr(
                      singleIndividualUserFromArr(question)?.reviewer,
                    ),
                  )}
                  avatar={singleIndividualUserFromArr(question)?.avatar?.url}
                  name={parseName(singleIndividualUserFromArr(question))}
                />
              ) : (
                <Avatars
                  items={generateAvatars(question?.target?.ids)}
                  total={question?.target?.ids?.length}
                />
              )}
            </>
          )}
          {isGeneral && (
            <Body2 kind="textDark" weight="semibold">
              Company-wide
            </Body2>
          )}
        </div>
        <div className="column2">
          <Body2 kind="textBody">
            {' '}
            {question.questions ? question?.questions.length : 0}
          </Body2>
        </div>

        <div>
          <Body2 kind="textBody" align="center" className="capitalize">
            {question?.status || '-'}{' '}
            {isUpcoming
              ? `on ${
                  question?.startDate
                    ? dayjs(question?.startDate).format("DD MMM. 'YY")
                    : dayjs()
                        .add(1, 'week')
                        .startOf('week')
                        .format("DD MMM. 'YY")
                }`
              : ''}
          </Body2>
        </div>
        <div>
          <Body2 kind="textBody">
            {question.questions[0].frequency === 'recurring'
              ? 'Weekly'
              : 'Just once'}
          </Body2>
        </div>

        <div>
          <Body2 kind="textBody">
            {' '}
            {question?.lastRun
              ? dayjs(question.lastRun).format("DD MMM. 'YY")
              : '-'}
          </Body2>
        </div>
      </ItemWrapper>
    </ItemWrapperContainer>
  );
});

interface QuestionActionItemProps {
  question: ICreateQuestionResponseDataSingle;
  openModal: any;
}

const QuestionActions = ({question, openModal}: QuestionActionItemProps) => {
  const isInActive = question?.status === 'inactive';

  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const [showSuspendModal, setShowSuspendModal] = useState(false);

  const {
    checkinsStore: {removeQuestionBatch, updateQuestionStatus},
  } = useStoreContext();

  const [, setIsLoading] = useState(false);

  const checkinsRequest = useMemo(() => new CompanyCheckinsRequestImpl(), []);

  const checkinsController = useMemo(
    () => new CompanyCheckinsController(checkinsRequest),
    [checkinsRequest],
  );

  const updateQuestion = useCallback(
    async (status: string) => {
      setIsUpdatingStatus(true);
      const response = await checkinsController.updateCheckinBatch(
        {
          questions: question.questions.map((_question) => ({..._question})),
          status,

          target: {
            type: question.target.type,
            ids: question.target.ids,
          },
        } as any,
        question.batch,
      );
      response && updateQuestionStatus(question.batch, status);
      response && setShowSuspendModal(false);
      setIsUpdatingStatus(false);
    },
    [checkinsController, updateQuestionStatus, question],
  );

  const removeQuestion = useCallback(
    async (batch) => {
      setIsLoading(true);
      const response = await checkinsController.deleteCheckinsBatch(batch);
      response && removeQuestionBatch(batch);
      setIsLoading(false);
    },
    [checkinsController, removeQuestionBatch],
  );

  return (
    <>
      <ActionWrapper>
        <FlexRowCenter className="">
          <DropdownVerticalSecondary
            customIcon={<MoreVerticalIcon />}
            collapseOnClick
            dropdownWrapperStyle={{right: '0px'}}
            menu={(handleClose: () => void) => (
              <>
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    openModal(question?.batch, question?.target?.type);
                  }}>
                  Edit
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    if (isInActive) {
                      updateQuestion('upcoming');
                    } else {
                      setShowSuspendModal(true);
                    }
                    removeQuestion(question?.batch);
                  }}>
                  {isInActive ? ' Add to queue' : ' Suspend'}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    handleClose();
                    removeQuestion(question?.batch);
                  }}>
                  Delete
                </DropdownItem>
              </>
            )}
          />
        </FlexRowCenter>
      </ActionWrapper>

      <CustomIconModal
        open={showSuspendModal}
        modalStyle={{padding: '40px  10px'}}
        customButton={
          <Button
            width="full"
            type="button"
            onClick={() => {
              updateQuestion('inactive');
            }}
            kind="secondary"
            style={{padding: '12px 24px', minWidth: '78px'}}
            isLoading={isUpdatingStatus}>
            Suspend
          </Button>
        }
        onClose={() => setShowSuspendModal(false)}
        body={<></>}
        onClick={() => {}}
        title={''}
        description={
          <>
            <Headline3 align="center">Suspend question set?</Headline3>
            <Body2 align="center" className="mt-4">
              Proceeding will pause the question set, preventing it from
              appearing in the next update.
            </Body2>
          </>
        }
      />
    </>
  );
};
