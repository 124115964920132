import {useNavigate} from 'react-router';
import {Body1, Body2} from '@ui/atoms/typography';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {cloudinary} from '@assets/images/cloudinary';
import {CalenderIcon} from '@ui/atoms/icons/calender-icon';
import {useQuery} from 'react-query';
import {ItemLoader} from '@ui/organisms/item-loader';
import {SectionCard} from '@ui/layouts/section-card';
import {useUpcomingMeetingHook} from '../individual-meetings/individual-hook';
import {FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {useBreakpoints} from '@utils/use-breakpoints';
import {MeetingCard} from '@ui/organisms/meetings/meeting-card/meeting-card';
import {IMeetingResponse} from '@hooks';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {useState, useCallback, useMemo} from 'react';
import {userName} from '@utils/user-name';
import {RescheduleMeeting} from '../reschedule-meeting/reschedule-meeting';
import {Button} from '@ui/atoms/button';
import {CalendarView} from '../calendar-view';
import dayjs from 'dayjs';
import {TeamMeetingFilter} from '@ui/organisms/meetings/filter/team-meetings';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {theme} from '@ui/style/theme';
import {RawButtonLink} from '@ui/atoms/button-link';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {setFirebaseData} from '@utils/firebase-handler';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {FilterIcon} from '@ui/atoms/icons/filter-icon';
import {useFirebaseFetch} from '@hooks/query-hook';
import {authStore} from '@store/stores/auth-store';
import {Checkbox} from '@ui/atoms/checkbox';

const {emptyGoals} = cloudinary;

const disconnectedPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  subtitle:
    'Sync your calendar to access smart scheduling, AI-generated meeting recaps, and task tracking.',
  title: 'Sync your calendar to begin',
  action: (
    <RawButtonLink
      style={{padding: '8px 16px'}}
      href="/edit/apps/gcalender-configure">
      Connect
    </RawButtonLink>
  ),
};

interface MeetingProps {
  isGoogleCalenderConnected?: boolean;
}
const TeamMeetings = ({isGoogleCalenderConnected}: MeetingProps) => {
  const navigate = useNavigate();

  const {
    dateRange,
    getMeetings,
    filterBy,
    setSortByDate,
    sortByDate,
    tasks,
    setDateRange,
    handleDateRange,
    deleteMeeting,
    selectedUsers,
    onCancel,
  } = useUpcomingMeetingHook();

  const filteredUsers = useMemo(
    () => (selectedUsers.length ? selectedUsers.join(',') : undefined),
    [selectedUsers],
  );

  const [duplicateAgenda, setDuplicateAgenda] = useState<{
    agenda: string;
    name: string;
    title?: string;
    participant: string;
    label: string;
  }>();

  const [showDuplicateModal, toggleDuplicateModal] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [cancelReason, setCancelReason] = useState('');

  const [showCancelModal, setCancelModal] = useState('');

  const [modalLoader, setModalLoader] = useState(false);

  const [selectedMeeting, setSelectedMeeting] = useState<any>();

  const [showCalander, setShowCalendar] = useState(false);

  const {data: filterPreferences} = useFirebaseFetch(
    `/users/${authStore.auth.user.id}/meetings/filter`,
  );

  const showCanceled = useMemo(() => filterPreferences?.showCanceled ?? true, [
    filterPreferences?.showCanceled,
  ]);

  const showDeclined = useMemo(() => filterPreferences?.showDeclined ?? true, [
    filterPreferences?.showDeclined,
  ]);

  const filter = useMemo(
    () => ({
      startDate: dateRange.starts,
      endDate: dateRange.ends,
      user: !filterBy ? filteredUsers : '',
      showCanceledMeetings: showCanceled,
      showDeclinedMeetings: showDeclined,
    }),
    [
      dateRange.starts,
      dateRange.ends,
      filterBy,
      filteredUsers,
      showCanceled,
      showDeclined,
    ],
  );

  const {
    data = {
      limit: 10,
      results: [] as IMeetingResponse[],
      totalPages: 0,
      totalResults: 10,
    },
    isLoading,
    refetch,
  } = useQuery(
    ['meetings-summary-team', filter, tasks, showCanceled, showDeclined],
    () => getMeetings(filter),
  );

  const {isXs} = useBreakpoints();

  const emptyPlaceholder = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: emptyGoals,
      style: {height: '30%', width: '30%'},
    },
    subtitle: 'Click "Create" to begin setting one up.',
    title: 'No meetings Scheduled',
  };

  const updateFilterPreferences = (
    newShowCanceled: boolean,
    newShowDeclined: boolean,
  ) => {
    const newPreferences = {
      showCanceled: newShowCanceled,
      showDeclined: newShowDeclined,
    };

    setFirebaseData(
      `/users/${authStore.auth.user.id}/meetings/filter`,
      newPreferences,
    );
  };

  const getFilterLabel = useCallback(() => {
    if (showCanceled && showDeclined) return 'All';

    return 'custom';
  }, [showCanceled, showDeclined]);

  // Memoize the action prop
  const action = useMemo(
    () => (
      <div className="flex flex-row items-center gap-3 sm:mt-2 mt-0">
        {isGoogleCalenderConnected && (
          <>
            <DropdownCustom
              collapseOnClick={true}
              menu={() => (
                <div>
                  <DropdownItem
                    onClick={() => {
                      const newValue = !showCanceled;

                      updateFilterPreferences(newValue, showDeclined);
                    }}>
                    <div className="flex items-center gap-2">
                      <Checkbox checked={showCanceled} onChange={() => {}} />
                      <span>Cancelled meetings</span>
                    </div>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      const newValue = !showDeclined;

                      updateFilterPreferences(showCanceled, newValue);
                    }}>
                    <div className="flex items-center gap-2">
                      <Checkbox checked={showDeclined} onChange={() => {}} />
                      <span>Declined meetings</span>
                    </div>
                  </DropdownItem>{' '}
                </div>
              )}
              customComponent={(handleOpen: (event: any) => void) => (
                <button onClick={handleOpen} style={{border: 0}}>
                  <FlexRowSpaceBetween>
                    <Body2
                      weight="bold"
                      className="whitespace-nowrap"
                      kind={'purple300'}
                      style={{marginRight: '6px'}}>
                      Showing: {getFilterLabel()}
                    </Body2>
                    <FilterIcon />
                  </FlexRowSpaceBetween>
                </button>
              )}
              customDropdownWrapperStyles={{
                top: '130%',

                padding: '8px',
                right: 0,
              }}
            />
            <HorizontalSideRule size="16px" />

            <Body2 kind="textBody" data-testid="summary-text">
              {sortByDate === 'today'
                ? dayjs(dateRange.starts).format('ddd. DD')
                : `${dayjs(dateRange.starts).format('ddd. DD')} -  ${dayjs(
                    dateRange.ends,
                  ).format('ddd. DD')}`}
            </Body2>

            <HorizontalSideRule size="16px" />
          </>
        )}

        <Button
          kind="secondary"
          disabled={!isGoogleCalenderConnected}
          onClick={() => setShowCalendar(true)}
          style={{padding: '6px 16px'}}
          className="flex items-center before gap-2">
          <span className="mb-[2px] text-base">
            <CalenderIcon
              stroke={
                isGoogleCalenderConnected ? 'black' : theme.colors.textMuted
              }
            />
          </span>
          Calendar view
        </Button>
      </div>
    ),
    [
      isGoogleCalenderConnected,
      sortByDate,
      dateRange.starts,
      dateRange.ends,
      showCanceled,
      showDeclined,
      getFilterLabel,
    ],
  );

  const handleFilterChange = useCallback(
    (data) => {
      const filter = data.sortBy === 'week' ? 'week' : 'today';

      setSortByDate(filter);
      setDateRange(handleDateRange(filter));
    },
    [setSortByDate, setDateRange, handleDateRange],
  );
  // Memoize the CustomHeaderTitle prop
  const CustomHeaderTitle = useMemo(
    () => (
      <TeamMeetingFilter
        selectedUsers={selectedUsers}
        disabled={!isGoogleCalenderConnected}
        sortBy={sortByDate}
        onChange={handleFilterChange}
      />
    ),
    [selectedUsers, isGoogleCalenderConnected, sortByDate, handleFilterChange],
  );

  // Memoize the onClick for CustomIconModal
  const handleDuplicateClick = useCallback(() => {
    navigate(`/create-1:1?action=duplicate&type=teams`, {
      state: {
        ...duplicateAgenda,
      },
    });
  }, [navigate, duplicateAgenda]);

  // Memoize the onClose for CalendarView
  const handleCalendarClose = useCallback(() => {
    setShowCalendar(false);
  }, []);

  // Handlers for MeetingCard props
  const handleCancelClick = useCallback((id: string) => {
    setCancelModal(id);
  }, []);

  const handleDeleteClick = useCallback(
    (calendarId: string) => {
      deleteMeeting(calendarId).then(() => refetch());
    },
    [deleteMeeting, refetch],
  );

  const handleStartMeeting = useCallback(
    (id: string) => {
      navigate(`/view-meetings/${id}?start=true`);
    },
    [navigate],
  );

  const handleReschedule = useCallback((meeting: IMeetingResponse) => {
    setSelectedMeeting(meeting);
    setOpenDrawer(true);
  }, []);

  const handleViewMeeting = useCallback(
    (id: string) => {
      navigate(`/view-meetings/${id}?meetingType=google`);
    },
    [navigate],
  );

  const handleDuplicate = useCallback(() => {
    toggleDuplicateModal(true);
    setDuplicateAgenda({
      agenda: JSON.stringify(selectedMeeting.hostAgenda),
      name: userName(selectedMeeting.participant),
      title: selectedMeeting.title,
      participant: JSON.stringify(
        selectedMeeting.participant.map(
          (participant: {id: string}) => participant.id,
        ),
      ),
      label: JSON.stringify(selectedMeeting.label),
    });
  }, [selectedMeeting]);

  return (
    <div>
      <div>
        <SectionCard
          headerPadding={isXs ? 'small' : undefined}
          contentStyling={{padding: '16px'}}
          CustomHeaderTitle={CustomHeaderTitle}
          variant="columnStart"
          action={action}>
          <div>
            {isGoogleCalenderConnected && (
              <div>
                {!isLoading &&
                  (data.results as any).map((meeting: any) => (
                    <MeetingCard
                      key={meeting.id}
                      excludeAddToCalendar
                      onCancel={() => handleCancelClick(meeting.id)}
                      onDelete={() => handleDeleteClick(meeting.calendarId)}
                      startMeeting={() => handleStartMeeting(meeting.id)}
                      onReschedule={() => handleReschedule(meeting)}
                      viewMeeting={() => handleViewMeeting(meeting.id)}
                      {...meeting}
                      participant={meeting.participant}
                      extraParticipant={meeting.extraParticipant}
                      onDuplicate={handleDuplicate}
                    />
                  ))}

                <CustomIconModal
                  open={showDuplicateModal}
                  onClose={() => toggleDuplicateModal(false)}
                  body={
                    <Body1 kind="textBody" align="center">
                      Click{' '}
                      <span style={{fontWeight: 700, color: 'black'}}>
                        “confirm”
                      </span>{' '}
                      to continue.
                    </Body1>
                  }
                  onClick={handleDuplicateClick}
                  title={'Duplicate Meeting'}
                  description={
                    <Body1 kind="textBody" align="center">
                      Are you sure you want to duplicate{' '}
                      <span style={{color: '#585ADF', fontWeight: 600}}>
                        {duplicateAgenda?.title ||
                          ` 1:1 with ${duplicateAgenda?.name}`}
                      </span>
                      ?
                    </Body1>
                  }
                />

                {openDrawer && (
                  <RescheduleMeeting
                    open={openDrawer}
                    onUpdated={refetch}
                    meeting={selectedMeeting}
                    onClose={() => setOpenDrawer(false)}
                  />
                )}

                <CustomIconModal
                  open={!!showCancelModal}
                  onClose={() => setCancelModal('')}
                  disabled={!cancelReason}
                  loading={modalLoader}
                  body={
                    <div className="mt-4">
                      <TextField
                        multiple
                        inputStyle={{minHeight: '20px'}}
                        placeholder="Add reason"
                        value={cancelReason}
                        onChange={(event) =>
                          setCancelReason(event.target.value)
                        }
                      />
                    </div>
                  }
                  onClick={() => {
                    setModalLoader(true);
                    onCancel(showCancelModal, cancelReason).then(async () => {
                      await refetch();

                      setModalLoader(false);
                      setCancelModal('');
                    });
                  }}
                  title={'Cancel Meeting'}
                  description={
                    <Body1 kind="textBody" align="center">
                      Provide reason, click{' '}
                      <span style={{color: 'black', fontWeight: 600}}>
                        “confirm”
                      </span>{' '}
                      to continue. The attendee will be notified.
                    </Body1>
                  }
                />

                <CalendarView
                  open={showCalander}
                  type="team"
                  onClose={handleCalendarClose}
                />

                {!data.results.length && !isLoading ? (
                  <EmptyPlaceholder {...emptyPlaceholder} />
                ) : null}

                {isLoading && (
                  <FlexRowCenter>
                    <ItemLoader />
                  </FlexRowCenter>
                )}
              </div>
            )}

            {!isGoogleCalenderConnected && (
              <EmptyPlaceholder {...disconnectedPlaceholder} />
            )}
          </div>
        </SectionCard>
      </div>
    </div>
  );
};

export default TeamMeetings;
