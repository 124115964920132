import dayjs from 'dayjs';
import {makeAutoObservable, observable, action} from 'mobx';
import {LogoutRequest} from '../../api/logout';
import {AdminProfileResponse} from '../../pages/admin/admin-profile/admin-profile-interface';
import {SignInResponse} from '../../pages/sign-in/sign-in-interface';
import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {calendar} from '../../utils/date';

import {set, get} from 'idb-keyval';
import {parseErrorContent} from '../../utils/error-handling';

const initalProfile = {
  profile: {
    name: '',
    avatar: '',
    url: '#',
    id: '',
    role: 'ADMIN',
  },
  reportee: {
    name: '',
    avatar: '',
    id: '',
    url: '#',
  },
  workspace: {
    name: '',
    logo: '',
    email: '',
    id: '',
    link: '',
  },
};
export class AuthStore {
  @action
  fetchAuthFromLocalStorage = () => {
    const localStorageAuth = localStorage.getItem('auth');
    return localStorageAuth ? JSON.parse(localStorageAuth) : null;
  };

  @action fetchUsersKeysFromIDB = async () => {
    const getKeys = await get(
      `${this.fetchAuthFromLocalStorage().user.workspace.id}/${
        this.fetchAuthFromLocalStorage().user.id
      }/currentStateKeyUsers`,
    );
    return getKeys;
  };

  @observable
  auth: SignInResponse | null | any = this.fetchAuthFromLocalStorage();
  @observable skipInvitation: boolean = false;
  @observable featureLimitStatus: any = null;
  @observable showNotification: boolean = true;
  // Global User Invite Form
  //Calendar and Reviewees
  @observable globalUserInvitationPopupState: boolean = false;
  @observable open: boolean = false;
  @observable openSmallModal: boolean = false;
  @observable openTooltip: boolean = false;
  @observable currentStateKey = {
    groups: '',
    groupsType: '',
    user: '',
    app: '',
    settings: '',
  };

  @observable currentStateKeyUsers = {
    goals: '',
    tasks: '',
  };

  @observable workspaceSetupStep: any = 1;
  @observable calendarView: string = localStorage.calendarView || 'month';
  @observable reviewees: Map<string, any> = new Map();
  @observable year: number = Number(dayjs().format('YYYY'));
  @observable errors: any = [];
  @observable month: number = Number(dayjs().format('M'));
  @observable calendar: any = calendar();

  constructor() {
    makeAutoObservable(this);
  }

  setSkipInvitation = () => {
    this.skipInvitation = true;
  };

  @action
  handleModalClose = () => {
    this.open = false;
    this.openSmallModal = true;
  };
  @action
  handleOpen = () => {
    this.open = true;
  };
  @action setShowNotification = (status: boolean) => {
    this.showNotification = status;
  };
  @action
  handleSmallModalClose = () => {
    this.openSmallModal = false;
    this.openTooltip = true;
    setTimeout(() => {
      this.openTooltip = false;
    }, 4000);
  };

  async fetchUsersKeys() {
    this.currentStateKeyUsers = await this.fetchUsersKeysFromIDB();
  }

  @action handleCurrentStateKey = (data: any) => {
    this.currentStateKey = data;
    set(`${this.auth.user.workspace.id}/currentStateKey`, this.currentStateKey);
  };

  @action handleCurrentStateKeyUsers = (data: any) => {
    this.currentStateKeyUsers = data;
    set(`${this.auth.user.id}/currentStateKeyUsers`, this.currentStateKeyUsers);
  };

  @action
  setWorkspaceSetupStep = (step: number) => {
    this.workspaceSetupStep = step;
  };
  @action handleFirebaseErrors = (data: any) => {
    this.errors = data;
  };
  @action
  setAuthResponse = (response: SignInResponse | any) => {
    localStorage.clear();
    this.auth = {...response, ...initalProfile};
    this.storeAuthInLocalStorage(this.auth);
  };

  @action
  storeAuthInLocalStorage = (auth: AdminProfileResponse | SignInResponse) => {
    localStorage.setItem('auth', JSON.stringify(auth));
  };

  @action
  getAuthFromLocalStorage = () => {
    const localStorageAuth = localStorage.getItem('auth');
    this.auth = localStorageAuth ? JSON.parse(localStorageAuth) : undefined;
  };

  @action
  clearAuth = (callback?: () => void) => {
    const logoutRequest = new LogoutRequest();

    try {
      this.auth && logoutRequest.logout(this.auth?.tokens.refresh.token);
      localStorage.removeItem('auth');
      localStorage.clear();

      this.auth = null;
      if (callback) {
        callback();
      }
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  };

  @action
  setInitResponse = (response: any) => {
    localStorage.setItem('initResponse', JSON.stringify(response));
  };
  @action setAuth = (data: SignInResponse | null | any) => {
    this.auth = data;
  };

  @action
  setInitResponseAsAuth = () => {
    if (localStorage && localStorage.workspaceObject) {
      const initResponse = localStorage.getItem('initResponse');
      const workspaceObject = localStorage.getItem('workspaceObject');
      initResponse &&
        workspaceObject &&
        this.setAuthResponse({
          ...JSON.parse(initResponse),
          user: {
            ...JSON.parse(initResponse)['user'],
            workspace: JSON.parse(workspaceObject),
          },
        });
    } else {
      const initResponse = localStorage.getItem('initResponse');
      initResponse && this.setAuthResponse(JSON.parse(initResponse));
    }
  };
  @action
  setLocalStorageAuthAsAuth = () => {
    if (localStorage && localStorage.auth) {
      const authObject = localStorage.getItem('auth');
      authObject && this.setAuth(authObject);
    }
  };

  @action
  setFrameworkInAuth = (framework: string) => {
    if (
      !!this.auth &&
      this.auth?.user &&
      this.auth?.user?.workspace &&
      this.auth?.user?.workspace?.framework
    ) {
      this.auth.user.workspace.framework = framework;
    }
  };
  @action
  setNameInAuth = (name: any, keyName: any) => {
    if (
      !!this.auth &&
      this.auth?.user &&
      this.auth?.user?.workspace &&
      this.auth?.user?.workspace?.config
    ) {
      this.auth.user.workspace.config = {
        goalName: name,
        keyResultName: keyName,
      };
    }
  };

  @action
  setWorkspaceNameInStore = (name: string) => {
    if (this.auth) {
      this.auth.user.workspace.name = name;
    }
  };

  // Global User Invite Form
  @action
  openGlobalUserInvitationModal = () => {
    this.globalUserInvitationPopupState = true;
  };
  @action
  closeGlobalUserInvitationModal = () => {
    this.globalUserInvitationPopupState = false;
  };
  @action
  toggleGlobalUserInvitationModal = () => {
    this.globalUserInvitationPopupState = !this.globalUserInvitationPopupState;
  };

  // Calender and Reviewees
  @action
  setCalendarView = (view: string) => {
    this.calendarView = view;
  };

  @action
  setRevieweesInStore = async (data: any) => {
    data.results.forEach((reviewee: any) => {
      this.reviewees.set(reviewee.id, reviewee);
    });
  };

  @action
  nextYear = () => {
    this.year = this.year + 1;
  };

  @action
  prevYear = () => {
    this.year = this.year - 1;
  };
  @action
  setYear = (year: number) => {
    this.year = year;
  };

  @action
  initializeMonthInStore = (month: number, year: number) => {
    this.year = year;
    this.month = month;
    this.calendar = calendar(year, month);
  };

  @action
  nextMonth = () => {
    if (this.month === 12) {
      this.calendar = calendar(this.year + 1, 1);
      this.month = 1;
      this.year = this.year + 1;
    } else {
      this.calendar = calendar(this.year, this.month + 1);
      this.month = this.month + 1;
    }
  };

  @action
  prevMonth = () => {
    if (this.month === 1) {
      this.calendar = calendar(this.year - 1, 12);
      this.month = 12;
      this.year = this.year - 1;
    } else {
      this.calendar = calendar(this.year, this.month - 1);
      this.month = this.month - 1;
    }
  };
  @action
  setMonth = (month: number) => {
    this.month = month;
  };
  @action
  setBillingInAuth = (data: any) => {
    this.auth.user.workspace.billing = data;
  };
  @action
  setFeatureLimitStatus = (data: any) => {
    this.featureLimitStatus = data;
  };
  /////
}

export const authStore = new AuthStore();
