import {FilterComponent} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-shared-components/filterbox/filterbox';
import {DateRangeInput} from '@ui/molecules/date-range-input';
import {Checkbox} from '@ui/atoms/checkbox';
import {useState, useMemo} from 'react';
import {Body2} from '@ui/atoms/typography';
import {OptionSelectField} from '@ui/molecules/select/option';
import {observer} from 'mobx-react';
import {useStoreContext} from '@store/store-context';
import dayjs from 'dayjs';
import {usePeriodHook} from '@hooks/period-hook';
import {LockIcon} from '@ui/atoms/icons';
import {AnalyticsTodayDate} from '@utils/date';
import {Flex} from '@ui/style/styles';
import styled from 'styled-components';

const DateWrapper = styled.span`
  width: 100%;
`;

interface IDates {
  showCompare?: boolean;
  date: {
    starts: string;
    ends: string;
    compare?: {
      starts: string;
      ends: string;
    };
  };
  disableFuturePeriods?: boolean;
  disablePeriods?: boolean;
  handleClose: () => void;
  onChangeDateRange: (e: any) => void;
}

export const DateFilters = observer(
  ({
    date,
    onChangeDateRange,
    disablePeriods,
    showCompare,
    handleClose,
    disableFuturePeriods,
  }: IDates) => {
    const {periods, isPeriodEnabled, currentMonthRange} = usePeriodHook(
      disableFuturePeriods,
    );

    const {
      analyticsStore: {
        selectedDateFilterValue: selectedValue,
        updateDateValue: setSelectedValue,
      },
      billingsStore: {updateShowFreeTierModals},
    } = useStoreContext();

    const [compare, setCompare] = useState(
      typeof date?.compare?.starts === 'string' && date?.compare?.starts
        ? true
        : false,
    );

    const isCustomDateRange = selectedValue === 'custom' || disablePeriods;

    const [showDateRange, setShowDateRange] = useState(
      isPeriodEnabled && !isCustomDateRange ? false : true,
    );

    const [selectedFilterRange, setSelectedFilterRange] = useState({
      starts: date.starts,
      ends: date.ends,
    });

    const handlePeriodChange = (value: string) => {
      setSelectedValue(value);

      if (value === 'custom') {
        setShowDateRange(true);
        return;
      }

      setShowDateRange(false);

      const findPeriod = periods.find((period) => period.value === value);

      setSelectedFilterRange({
        starts: findPeriod.starts || '',
        ends: findPeriod.ends || '',
      });
    };

    const startDate = useMemo(() => date.starts, [date.starts]);

    const endDate = useMemo(() => date.ends, [date.ends]);

    const applyState = () => {
      if (isPeriodEnabled) {
        return !(
          currentMonthRange().starts !== startDate ||
          currentMonthRange().ends !== endDate
        );
      }
      return !(
        AnalyticsTodayDate().AnalyticsDefaultStartDate !== date.starts ||
        AnalyticsTodayDate().AnalyticsDefaultEndDate !== date.ends
      );
    };
    const [showApply, setShowApply] = useState(() => applyState());

    const [dates, setDates] = useState({
      starts: date.starts,
      ends: date.ends,
      compare: {
        starts: date?.compare?.starts,
        ends: date?.compare?.ends,
      },
    });

    const handleApply = () => {
      setShowApply(false);
      handleClose();

      if (isPeriodEnabled && !isCustomDateRange) {
        const findPeriod = periods.find(
          (period) => period.value === selectedFilterRange.starts,
        );

        onChangeDateRange({
          starts: selectedFilterRange.starts,
          ends: selectedFilterRange.ends,
          period: findPeriod.period,
        });
        return;
      }
      onChangeDateRange(dates);
    };

    const resetFilter = () => {
      setCompare(false);

      const resetDate = {
        starts: AnalyticsTodayDate().AnalyticsDefaultStartDate,
        ends: AnalyticsTodayDate().AnalyticsDefaultEndDate,
        compare: {
          starts: '',
          ends: '',
        },
      };

      if (isPeriodEnabled) {
        onChangeDateRange({...currentMonthRange()});

        setSelectedFilterRange({
          ...currentMonthRange(),
        });

        setShowDateRange(false);
      } else {
        onChangeDateRange(resetDate);
      }
      setSelectedValue('');
      setShowApply(true);

      setDates(resetDate);
    };

    const formatDate = (date: string) => {
      return date.replaceAll('-', '/');
    };

    const disableButton = useMemo(() => {
      if (new Date(dates.ends) < new Date(dates.starts)) {
        return true;
      } else if (
        !isCustomDateRange &&
        (formatDate(selectedFilterRange.starts) === formatDate(date.starts) ||
          formatDate(selectedFilterRange.ends) === formatDate(date.ends))
      ) {
        return true;
      } else if (
        isCustomDateRange &&
        dates.starts === date.starts &&
        dates.ends === date.ends
      ) {
        return true;
      } else {
        return false;
      }
    }, [
      dates.ends,
      dates.starts,
      isCustomDateRange,
      selectedFilterRange.starts,
      selectedFilterRange.ends,
      date.starts,
      date.ends,
    ]);

    return (
      <>
        <FilterComponent
          title="Date"
          showFilter={true}
          type="date"
          showApplyButton={{
            show: applyState() || showApply,
            action: handleApply,
          }}
          resetDisabled={disableButton}
          resetDateDisabled={false}
          resetDateFilter={resetFilter}
          handleClose={handleClose}
          boxStyle={{
            right: -40,
            left: -40,
            zIndex: 2,
          }}
          resetFilter={() => null}
          setView={() => null}>
          {isPeriodEnabled && (
            <OptionSelectField
              options={periods}
              placeholder={'Select option'}
              name="questionType"
              value={isCustomDateRange ? 'custom' : date.starts}
              customOptionName="Choose custom timeline"
              maxHeight={400}
              disabledIcon={<LockIcon />}
              onChange={(data: {value: string; disabled?: boolean}) => {
                if (data?.disabled) {
                  updateShowFreeTierModals(
                    'date_filter',
                    data?.value === 'custom' ? 'custom' : 'date',
                  );

                  return;
                }
                handlePeriodChange(data.value);
                setShowApply(true);
              }}
              fieldNotFoundPlaceHolder={() =>
                `Oops! this period does not exist`
              }
              optionsSelectType="period"
            />
          )}

          {showDateRange && (
            <DateWrapper>
              <DateRangeInput
                name="date"
                value={{starts: dates.starts, ends: dates.ends}}
                block={true}
                helper={
                  new Date(dates.ends) < new Date(dates.starts)
                    ? 'Start date must be before end date'
                    : ''
                }
                state={
                  new Date(dates.ends) < new Date(dates.starts)
                    ? 'error'
                    : 'default'
                }
                disabledAfter={
                  showCompare
                    ? dayjs(Date.now()).subtract(1, 'day').format()
                    : undefined
                }
                admin={true}
                placeholder="Select Date"
                setValue={(e) => {
                  setShowApply(true);
                  setDates({
                    ...dates,
                    starts: dayjs(e.starts).format('MM/DD/YYYY'),
                    ends: dayjs(e.ends).format('MM/DD/YYYY'),
                  });
                }}
              />
            </DateWrapper>
          )}
          {showCompare && (
            <Flex style={{marginTop: '8px'}}>
              <Checkbox
                width={'14px'}
                height="14px"
                checked={compare}
                kind="import"
                onChange={(e) => setCompare(e.target.checked)}
              />
              <Body2
                weight="semibold"
                style={{marginLeft: '5px', cursor: 'pointer'}}
                onClick={() => setCompare(!compare)}>
                Compare
              </Body2>
            </Flex>
          )}
        </FilterComponent>
      </>
    );
  },
);
