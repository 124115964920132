import './wdyr';
import * as Sentry from '@sentry/react';
import {render} from 'react-dom';
import {installClient} from './install';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import TagManager from 'react-gtm-module';
import userflow from 'userflow.js';
import {authStore} from './store/stores/auth-store';
import dayjs from 'dayjs';

declare const window: any;

export const renderClient = () => {
  const {Page} = installClient();

  if (process.env.REACT_APP_ENABLE_HOTJAR === 'enabled') {
    const {hotjar} = require('react-hotjar');

    hotjar.initialize(2700624, 6);

    hotjar.event('button-click');

    hotjar.stateChange(window.location.pathname);
  }

  if (process.env.REACT_APP_ENABLE_LOGROCKET === 'enabled') {
    const logRocketId: string = process.env.REACT_APP_LOGROCKET_ID || '';
    LogRocket.init(logRocketId);

    LogRocket.getSessionURL(() => {});
  }
  if (
    process.env.REACT_APP_ENABLE_SENTRY === 'enabled' &&
    process.env.REACT_APP_ENABLE_LOGROCKET === 'enabled'
  ) {
    Sentry.init({
      beforeSend(event: any) {
        const logRocketSession = LogRocket.sessionURL;
        if (logRocketSession !== null) {
          event.extra['LogRocket'] = logRocketSession;
          return event;
        } else {
          return event;
        }
      },
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: 'beam-app@' + process.env.npm_package_version,
    });
  }
  const tagManagerArgs = {
    gtmId: 'GTM-KH6XHRH',
  };

  TagManager.initialize(tagManagerArgs);

  setupLogRocketReact(LogRocket);

  render(Page, document.getElementById('root'));

  const {REACT_APP_USERFLOW_TOKEN} = process.env;
  const {auth} = authStore;

  userflow.init(`${REACT_APP_USERFLOW_TOKEN}`);

  userflow.identify(`${auth?.user.id}`, {
    name: `${auth?.user.firstName} ${auth?.user.lastName}`,
    email: `${auth?.user.email}`,
    signed_up_at: `${dayjs(auth?.user.createdAt).toISOString()}`,
  });
};
