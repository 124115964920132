import dayjs from 'dayjs';

const getDayOfWeek = (day: string): string => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days[['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].indexOf(day)];
};

const getOrdinal = (n: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

const formatRecurrenceToEnglish = (recurrence: any): string => {
  const {frequency, interval, until, count} = recurrence;
  let formatted = '';

  switch (frequency) {
    case 'daily':
      formatted = 'Daily';
      break;
    case 'weekly':
      formatted = 'Weekly';
      break;
    case 'monthly':
      formatted = 'Monthly';
      break;
    case 'yearly':
      formatted = 'Yearly';
      break;
  }

  if (interval && interval > 1) {
    formatted = `Every ${interval} ${formatted.toLowerCase()}`;
  }

  if (until) {
    formatted += ` until ${dayjs(until).format('MMMM D, YYYY')}`;
  } else if (count) {
    formatted += ` for ${count} occurrence${count > 1 ? 's' : ''}`;
  }

  return formatted;
};

export const parseRecurrenceRule = (rrule: string) => {
  const rule = rrule.replace('RRULE:', '');
  const parts = rule.split(';');

  const recurrence: {
    pattern: string;
    frequency: string;
    interval?: number;
    until?: string;
    count?: number;
    formatted?: string;
  } = {
    pattern: 'custom',
    frequency: '',
    formatted: '',
  };

  parts.forEach((part) => {
    const [key, value] = part.split('=');

    switch (key) {
      case 'FREQ':
        recurrence.frequency = value.toLowerCase();
        if (value === 'WEEKLY' && !rule.includes('BYDAY')) {
          recurrence.pattern = 'weekly';
        } else if (value === 'MONTHLY' && !rule.includes('BYDAY')) {
          recurrence.pattern = 'monthly';
        } else if (value === 'DAILY') {
          recurrence.pattern = 'daily';
        }
        break;
      case 'INTERVAL':
        recurrence.interval = parseInt(value);
        break;
      case 'UNTIL':
        recurrence.until = value;
        break;
      case 'COUNT':
        recurrence.count = parseInt(value);
        break;
      case 'BYDAY':
        if (recurrence.frequency === 'weekly') {
          recurrence.pattern = 'weekly';

          const day = getDayOfWeek(value);

          recurrence.formatted = `${recurrence.formatted || ''} on ${day}`;
        } else if (recurrence.frequency === 'monthly') {
          recurrence.pattern = 'monthly';
          const [week, dayCode] = value.match(/(-?\d+)(\w+)/)?.slice(1) || [];
          const day = getDayOfWeek(dayCode);
          const weekOrdinal = getOrdinal(parseInt(week));
          recurrence.formatted = `${
            recurrence.formatted || ''
          } on the ${weekOrdinal} ${day}`;
        }
        break;
    }
  });

  recurrence.formatted =
    formatRecurrenceToEnglish(recurrence) + (recurrence.formatted || '');

  return recurrence;
};
