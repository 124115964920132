import React, {FC, useMemo} from 'react';
import {InsightCard} from '../../dashboard/insight-card';
import {Insight} from '../interface';
import {useInsightActionItemHook} from './action-items-insight-hook';
import {formatConfigDate, comparisonConfigDate} from '../insight-options';

interface ActionItemCompletionRateProps extends Insight {
  showRangeType?: boolean;
  overallDateRange?: null | {startDate: string; endDate: string};
}

export const ActionItemCompletionRate: FC<ActionItemCompletionRateProps> = ({
  overallDateRange,
  ...rest
}) => {
  const config = rest.config;

  const formatDate = useMemo(
    () =>
      overallDateRange ? overallDateRange : formatConfigDate(config) || {},
    [config, overallDateRange],
  );

  const comparisonDate = useMemo(
    () => comparisonConfigDate(config, formatDate.startDate),
    [config, formatDate.startDate],
  );

  const {summary} = useInsightActionItemHook({
    members: config.users || [],
    sections: config.list || [],
    date: formatDate,
    comparisonDate: comparisonDate,
  });

  return (
    <div>
      <InsightCard
        {...rest}
        showFlashIcon={Boolean(overallDateRange)}
        value={`${summary.completionRate.current}%`}
        percentProgress={summary.completionRate.change}
      />
    </div>
  );
};
