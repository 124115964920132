export function userInitials(
  user: {firstName: string; lastName: string} | string,
) {
  function getInitials() {
    if (typeof user === 'string') {
      let initial = user.match(/\b\w/g) || [];
      return (initial.shift() || '') + (initial.pop() || '');
    } else {
      const _user = user;
      if (
        _user &&
        _user.hasOwnProperty('firstName') &&
        _user.hasOwnProperty('lastName')
      ) {
        return `${user.firstName.substring(0, 1)}${user.lastName.substring(
          0,
          1,
        )}`;
      }
      return '';
    }
  }

  return getInitials().toUpperCase();
}
