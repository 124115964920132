import {ActionType} from '@api/action-items-api/action-items-request';
import {IAPIUserFormat, useDropDown} from '@hooks';
import {usePostHogHook} from '@hooks/post-hog';
import {PlusIconWrapper} from '@pages/1:1s/calendar-view';
import {authStore} from '@store/stores/auth-store';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {TickIcon} from '@ui/atoms/icons/tick';
import {UserTickIcon} from '@ui/atoms/icons/user-tick';
import {PlusSVG} from '@ui/atoms/svg';
import {Body2} from '@ui/atoms/typography';
import {Calendar} from '@ui/molecules/calendar';
import {Dropdown} from '@ui/molecules/dropdown';
import {MultiField} from '@ui/molecules/field/multifield';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';
import {UserSelect3} from '@ui/molecules/select/user-select-3';
import {FlexRow} from '@ui/style/styles';
import dayjs from 'dayjs';
import {FC, memo, ReactNode, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useDebouncedCallback} from 'use-debounce';

export const DropdownWrapper = styled.div`
  position: absolute;
  display: inline-block;
  bottom: 100%;
  left: 0;
  z-index: 3;
`;

interface ValueProps extends ActionType {
  user?: string | IAPIUserFormat;
}
interface ActionItemProps {
  value: ActionType[];
  disableCheckbox?: boolean;
  removeAddToList?: boolean;
  handleChange?: (
    value: ValueProps[],
    actionType?: 'dueDate' | 'user' | 're-sort' | '' | 'remove' | 'remove-link',
    fieldId?: string,
    item?: ValueProps,
  ) => Promise<void>;
  source: string;
  showSource?: boolean;
  handleCustomAdd?: (fieldId: ActionType) => void;
  goalOptions?: any[];
  section?: string;
  sourceId: string;
  pagination?: number;
  users?: {value: string; label: string}[];
  excludeCheckBox?: boolean;
  handleOnRemove?: (fieldId: string) => void;
  dropdownComponents?: any;
  onDrag?: () => void;
  onDueDateChange?: (
    fieldId: string,
    date: string,
    prevDueDate?: string,
  ) => void;
  onActivityLog?: (activity: {
    type:
      | 'assign'
      | 'complete'
      | 'incomplete'
      | 'addToBoard'
      | 'delete'
      | 'create';
    actionItem: string;
    assignee?: string;
    message: string;
    board?: string;
  }) => void;
  canDelete?: (fieldId: string) => boolean;
  sortBy?: 'createdAt' | 'dueDate' | 'order';
  handleLinkGoal?: (fieldId: string, goalId: string) => void;
  onHandleDropdownClick?: (fieldId: string, key: string) => void;
  handleViewSource?: (data: {id: string; source: string}) => void;
  isReadOnly?: boolean;
  userId?: string;
}

export const ActionItems: FC<ActionItemProps> = memo(
  ({
    value = [],
    handleChange,
    sourceId,
    dropdownComponents,
    handleCustomAdd,
    sortBy,
    excludeCheckBox,
    pagination,
    handleViewSource,
    goalOptions,
    removeAddToList,
    onDrag,
    section,
    canDelete,
    handleOnRemove,
    handleLinkGoal,
    onDueDateChange,
    users,
    onHandleDropdownClick,
    onActivityLog,
    showSource,
    source,
    userId,
    isReadOnly,
  }) => {
    const [limit, setLimit] = useState(pagination);

    const computeValue = useMemo(() => {
      const _value = value.map((item) => ({
        ...item,
        key: item?.id || item.key,
      }));

      if (limit) {
        return _value.slice(0, limit);
      }

      return _value.length
        ? _value.sort((a, b) => {
            if (sortBy === 'order') {
              const aOrder = a[sortBy] || Infinity;
              const bOrder = b[sortBy] || Infinity;
              return aOrder - bOrder;
            }
            if (sortBy) {
              const aDate = a[sortBy] || Infinity;
              const bDate = b[sortBy] || Infinity;
              return new Date(bDate).valueOf() - new Date(aDate).valueOf();
            }

            return 0;
          })
        : [{text: '', completed: false}];
    }, [limit, sortBy, value]);

    const handleActivityLogCallback = useCallback(
      (
        type:
          | 'assign'
          | 'complete'
          | 'incomplete'
          | 'addToBoard'
          | 'delete'
          | 'create',
        actionItem: string,
        message: string,
        assignee?: string,
        board?: string,
      ) => {
        onActivityLog &&
          onActivityLog({type, actionItem, assignee, message, board});
      },
      [onActivityLog],
    );

    const getActionItemCallback = useCallback(
      (id: string) => {
        return value.find((item) => item.key === id);
      },
      [value],
    );

    const computeActionItemCallback = useCallback(
      (data: any[], action?: 'addText'): ActionType[] => {
        return data.map((value) => ({
          ...value,
          completed: value.completed,
          _id: value?._id || value?.key,
          meta: getActionItemCallback(value?.key)?.meta || null,
          text: value.text,
          source: getActionItemCallback(value?.key)?.source || source,
          sourceId: getActionItemCallback(value?.key)?.sourceId || sourceId,
          section,
          updatedBy: value.updatedBy,
          dueDate:
            action === 'addText'
              ? getActionItemCallback(value?.key)?.dueDate
              : value?.dueDate || null,
          user: value.user,
        }));
      },
      [getActionItemCallback, section, source, sourceId],
    );

    const debounceActionItems = useDebouncedCallback(
      async (data, action, key, updatedField) => {
        if (handleChange) {
          await handleChange(
            computeActionItemCallback(data, 'addText'),
            action,
            key,
            updatedField
              ? computeActionItemCallback([updatedField], 'addText')[0]
              : undefined,
          );
        }
      },
      1000,
    );

    const {postHogCapture} = usePostHogHook();
    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();

    const [showGoalDropdown, setShowGoalDropdown] = useState('');

    const formatSourceTitle = useCallback((text: string) => {
      return text.length > 22 ? `${text.slice(0, 22)}...` : text;
    }, []);

    const dropDownOptionsMemo = useCallback(
      (dueDateExists?: boolean, _id?: string) => {
        const options: {
          content: string;
          id: string;
          icon: null | ReactNode;
        }[] = [
          {
            content: `Delete`,
            id: 'removeCurrent',
            icon: null,
          },
        ];

        if (dropdownComponents) {
          options.push(...dropdownComponents(_id));
        }

        if (_id) {
          const updatedItemIndex = getActionItemCallback(_id)?.subscribers;

          const actionItemsSubscribers: string[] = updatedItemIndex || [];

          const isFollowing = actionItemsSubscribers?.includes(
            authStore.auth.user.id,
          );

          options.push({
            content: isFollowing ? 'Following' : `Follow`,
            id: 'follow',
            icon: isFollowing ? <TickIcon fill="#5F5F8C" /> : null,
          });
        }

        if (dueDateExists) {
          options.push({
            content: `Remove due date`,
            id: 'removeDueDate',
            icon: null,
          });
        }
        return options;
      },
      [dropdownComponents, getActionItemCallback],
    );

    const onCreateNewItem = useCallback(
      (actionItem) => {
        postHogCapture('action-items-creation');

        handleActivityLogCallback(
          'create',
          actionItem.key,
          `[${authStore.auth.user.id}] added _(${actionItem.text})_ to ${section}`,
        );
      },
      [postHogCapture, handleActivityLogCallback, section],
    );

    const onCompleteItem = useCallback(
      (actionItem) => {
        if (actionItem.completed) {
          postHogCapture('action-items-completion', null, {
            date: dayjs().format(),
            ...actionItem,
          });
        }

        handleActivityLogCallback(
          actionItem.completed ? 'complete' : 'incomplete',
          actionItem.key,
          `[${authStore.auth.user.id}] marked _(${actionItem.text})_ as  ${
            actionItem.completed ? 'completed' : 'not completed'
          } `,
        );
      },
      [postHogCapture, handleActivityLogCallback],
    );

    const updateValueCallback = useCallback(
      async (value_: ActionType[], action, key) => {
        const formattedValue =
          value_ &&
          value_.map((item) => ({
            ...item.val,
            key: item.key,
            updatedBy: userId,
          }));

        if (action === 're-sort') {
          onDrag && onDrag();
        }

        const updatedField = formattedValue?.find((value) => value.key === key);

        if (handleChange && formattedValue) {
          await debounceActionItems(formattedValue, action, key, updatedField);
        }
      },
      [handleChange, debounceActionItems, onDrag, userId],
    );

    const handleUserSelectCallback = useCallback(
      (user, fieldId) => {
        const updatedItemIndex = value.findIndex(
          (item) => item.key === fieldId,
        );

        if (updatedItemIndex !== -1) {
          // Update the specific item directly in the array
          const assignee = value[updatedItemIndex].assignee;

          const prevAssignee =
            typeof assignee === 'string' ? assignee : assignee?.id;

          value[updatedItemIndex].assignee = user || '';

          const updatedItem = value[updatedItemIndex];

          handleChange &&
            handleChange(
              computeActionItemCallback(value),
              'user',
              fieldId,
              updatedItem,
            );

          if (prevAssignee === authStore.auth.user.id) {
            handleActivityLogCallback(
              'assign',
              updatedItem.key,
              `[${authStore.auth.user.id}] left  _(${updatedItem.text})_ `,
            );

            return;
          }

          handleActivityLogCallback(
            'assign',
            updatedItem.key,
            `[${authStore.auth.user.id}] ${
              updatedItem.assignee ? 'added' : 'removed'
            } [${user || prevAssignee}] ${
              updatedItem.assignee ? 'to' : 'from'
            } _(${updatedItem.text})_ `,
          );
        }
      },
      [
        value,
        handleChange,
        computeActionItemCallback,
        handleActivityLogCallback,
      ],
    );

    const footerComponentCallback = useCallback(
      (fieldId: string) => {
        const actionItem = getActionItemCallback(fieldId);

        const showActionItemSource =
          showSource && (!!actionItem?.sourceTitle || !!actionItem?.meta);

        return (
          <>
            {actionItem && (
              <div className="flex gap-2 items-center mt-2">
                {actionItem?.subscribers?.includes(authStore.auth.user.id) && (
                  <HoverTooltip tooltipText="Following" className="z-10">
                    <UserTickIcon fill={'#CECEDE'} />
                  </HoverTooltip>
                )}

                {showActionItemSource && (
                  <div
                    className="border border-[#CECEDE] rounded-lg py-1 px-2 cursor-pointer "
                    onClick={() => {
                      const isDeleted = actionItem?.isSourceDeleted;

                      if (handleViewSource && !isDeleted) {
                        handleViewSource({
                          id: actionItem?.sourceId || '',
                          source: actionItem?.source || '',
                        });
                      }
                    }}>
                    {actionItem?.meta?.url ? (
                      <Body2
                        weight="semibold"
                        as="a"
                        target="_blank"
                        href={actionItem?.meta?.url}>
                        {' '}
                        #{formatSourceTitle(actionItem?.meta?.title)}
                      </Body2>
                    ) : (
                      <Body2 weight="semibold">{actionItem?.sourceTitle}</Body2>
                    )}
                  </div>
                )}

                <DueDate
                  value={actionItem?.dueDate || ''}
                  disabled={isReadOnly}
                  handleChange={(date) => {
                    const updatedItemIndex = value.findIndex(
                      (item) => item.key === fieldId,
                    );

                    if (updatedItemIndex !== -1) {
                      const prevDueDate = value[updatedItemIndex].dueDate;

                      value[updatedItemIndex].dueDate = date;

                      const updatedItem = value[updatedItemIndex];
                      handleChange &&
                        handleChange(
                          computeActionItemCallback(value),
                          'dueDate',
                          fieldId,
                          updatedItem,
                        );

                      onDueDateChange?.(fieldId, date, prevDueDate);
                    }
                  }}
                />

                {open && actionItem?.key === showGoalDropdown && (
                  <div
                    className="absolute top-[10%] right-0 bg-white border border-borderLight z-10 rounded-lg"
                    ref={dropRef}>
                    <UserSelect3
                      onChange={(value: any) => {
                        handleClose();
                        handleLinkGoal &&
                          handleLinkGoal(showGoalDropdown, value.value);
                      }}
                      useAllOptions
                      placeholder={'Search objectives or KPI'}
                      options={goalOptions}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
      [
        getActionItemCallback,
        showSource,
        formatSourceTitle,
        isReadOnly,
        open,
        showGoalDropdown,
        dropRef,
        goalOptions,
        handleViewSource,
        value,
        handleChange,
        computeActionItemCallback,
        onDueDateChange,
        handleClose,
        handleLinkGoal,
      ],
    );

    const dropdownComponentCallback = useCallback(
      (onHandleRemove: () => void, fieldId: string) => (
        <FlexRow>
          {handleCustomAdd && (
            <PlusIconWrapper
              className="ml-3 mr-5"
              onClick={() => {
                const item = value.find((item) => item.key === fieldId);
                if (item) handleCustomAdd(item);
              }}>
              <HoverTooltip tooltipText="Add to note">
                <PlusSVG />
              </HoverTooltip>
            </PlusIconWrapper>
          )}
          {getActionItemCallback(fieldId)?.updatedBy === userId ||
          (canDelete && canDelete(fieldId)) ? (
            <Dropdown
              dropdownItemStyle={{whiteSpace: 'nowrap', display: 'flex'}}
              menu={dropDownOptionsMemo(
                !!getActionItemCallback(fieldId)?.dueDate,
                fieldId,
              )}
              disableCloseOnClick={(id) => id === 'follow'}
              onClick={(id, event) => {
                const updatedItemIndex = value.findIndex(
                  (item) => item.key === fieldId,
                );

                if (id === 'goal') {
                  handleOpen(event as any);
                  setShowGoalDropdown(
                    getActionItemCallback(fieldId)?.key || '',
                  );
                }
                if (id === 'remove-link') {
                  if (updatedItemIndex !== -1) {
                    // Update the specific item directly in the array
                    value[updatedItemIndex] = {
                      ...value[updatedItemIndex],
                      source: '',
                      sourceId: '',
                    };

                    const updatedItem = value[updatedItemIndex];

                    handleChange &&
                      handleChange(
                        computeActionItemCallback(value),
                        'remove-link',
                        fieldId,
                        updatedItem,
                      );
                  }
                }

                if (id === 'removeCurrent') {
                  onHandleRemove();

                  handleOnRemove && handleOnRemove(fieldId);

                  handleChange &&
                    handleChange(
                      computeActionItemCallback(value),
                      'remove',
                      fieldId,
                    );
                }

                if (id === 'removeDueDate') {
                  value[updatedItemIndex].dueDate = '';

                  const updatedItem = value[updatedItemIndex];

                  handleChange &&
                    handleChange(
                      computeActionItemCallback(value),
                      '',
                      fieldId,
                      updatedItem,
                    );
                }
                onHandleDropdownClick && onHandleDropdownClick(fieldId, id);
              }}
            />
          ) : (
            <MoreVerticalIcon style={{stroke: '#BFBFD4'}} />
          )}
        </FlexRow>
      ),
      [
        handleCustomAdd,
        value,
        getActionItemCallback,
        userId,
        canDelete,
        dropDownOptionsMemo,
        handleOpen,
        handleOnRemove,
        handleChange,
        computeActionItemCallback,
        onHandleDropdownClick,
      ],
    );

    const memoizedAssigneeOptions = useMemo(() => users, [users]);
    const memoizedCheckedIndexes = useMemo(() => [0], []);

    return (
      <>
        <MultiField
          checkedIndexes={memoizedCheckedIndexes}
          margin
          label=""
          value={computeValue}
          placeholder="Add an item"
          onCreateNewItem={onCreateNewItem}
          onCompleteItem={onCompleteItem}
          updateValue={updateValueCallback as any}
          id=""
          returnValueKey
          fontWeight={400}
          showCheckbox={excludeCheckBox}
          disableCheckbox={excludeCheckBox}
          assigneeOptions={memoizedAssigneeOptions}
          disabled={isReadOnly}
          readonly={isReadOnly}
          userCanReorder
          handleUserSelect={handleUserSelectCallback}
          removeAddToList={removeAddToList}
          userId={userId}
          updateValueInstantly
          addAvatar
          excludeDefaultUser
          colorString="textMedium"
          variant="youcheckins"
          footerComponent={footerComponentCallback}
          dropdownComponent={dropdownComponentCallback}
        />

        {limit && limit < value.length && (
          <button
            type="button"
            className="ml-5"
            onClick={() => setLimit((prev) => (prev ? prev + 10 : 10))}>
            <Body2 weight="bold" kind="purple300">
              + 10 more items
            </Body2>
          </button>
        )}
      </>
    );
  },
);

interface IDueDate {
  value: string;
  disabled?: boolean;
  handleChange: (value: string) => void;
}
const DueDate: FC<IDueDate> = memo(({value, handleChange, disabled}) => {
  const {handleOpen, ref, open, handleClose} = useDropDown();

  const handleDueDay = () => {
    const dueDay = dayjs(value);
    const formatDate = `ddd, DD MMM ${
      dueDay.year() === dayjs().year() ? '' : "'YY"
    }`;

    if (dueDay.isToday()) {
      return {
        color: 'red400',
        text: 'Today',
      };
    }
    if (dueDay.isBefore(dayjs())) {
      return {
        color: 'red400',
        text: dueDay.format(formatDate),
      };
    }
    if (
      dueDay.isSame(dayjs(), 'week') ||
      dueDay.isSame(dayjs().add(1, 'week'), 'week')
    ) {
      return {
        color: 'orange300',
        text: dueDay.format(formatDate),
      };
    }
    if (dueDay.isSame(dayjs().add(1, 'day'), 'day')) {
      return {
        color: 'orange300',
        text: 'Tomorrow',
      };
    }
    return {
      color: 'textDark',
      text: dueDay.format(formatDate),
    };
  };

  return (
    <>
      <button
        disabled={disabled}
        className="flex flex-row items-center"
        onClick={handleOpen as any}>
        <CustomColorIcon height="8px" width="8px" color="#D9D9D9" margin={5} />
        {value ? (
          <Body2 weight="bold" kind={handleDueDay().color as any}>
            {handleDueDay().text}
          </Body2>
        ) : (
          <Body2 kind="textMuted" weight="bold">
            due date
          </Body2>
        )}
      </button>
      {open && (
        <DropdownWrapper ref={ref}>
          <Calendar
            onChange={(value) => {
              handleChange(dayjs(value).format());
              handleClose();
            }}
            value={value}
            disabledBefore={dayjs().subtract(1, 'day').format()}
          />
        </DropdownWrapper>
      )}
    </>
  );
});

ActionItems.whyDidYouRender = true;
