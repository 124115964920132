import {useState} from 'react';
import {RadioSm} from '@ui/atoms/radio';
import {Body1, Body2} from '@ui/atoms/typography';
import {useGCalenderHook} from '@hooks/gcalender';

type ResponseStatus = 'accepted' | 'declined' | 'tentative' | 'needsAction';
type DisplayStatus = 'Yes' | 'No' | 'Maybe' | '';

interface MeetingRSVPProps {
  initialStatus?: ResponseStatus;
  onStatusChange?: (status: ResponseStatus) => void;
  disabled?: boolean;
  calendarId: string;
  attendees?: any[];
  connectedEmail?: string;
}

const statusMap: Record<ResponseStatus, DisplayStatus> = {
  accepted: 'Yes',
  declined: 'No',
  tentative: 'Maybe',
  needsAction: '',
};

const displayOptions: DisplayStatus[] = ['Yes', 'No', 'Maybe'];

const MeetingRSVP = ({
  initialStatus = 'needsAction',
  calendarId,
  attendees = [],
  connectedEmail = '',
}: MeetingRSVPProps) => {
  const [responseStatus, setResponseStatus] = useState<ResponseStatus>(
    initialStatus,
  );
  const [, setUpdating] = useState(false);
  const {updateEvent, isSigned} = useGCalenderHook();

  const handleStatusChange = async (newStatus: string) => {
    if (!calendarId || !isSigned) return;

    const apiStatus = Object.keys(statusMap).find(
      (key) => (statusMap as any)[key] === newStatus,
    );

    if (!apiStatus) return;

    try {
      setUpdating(true);

      await updateEvent(
        {
          attendees: attendees.map((attendee) =>
            attendee.email === connectedEmail
              ? {
                  ...attendee,
                  responseStatus: apiStatus,
                }
              : attendee,
          ),
        },
        calendarId,
        'primary',
      );

      setResponseStatus(apiStatus as any);
    } catch (error) {
      console.error('Error updating RSVP status:', error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="mt-4">
      <Body2 weight="bold" className="mb-2">
        Going?
      </Body2>
      <div className="flex gap-6">
        {displayOptions.map((option) => (
          <label
            key={option}
            className={`flex items-center gap-2 
            cursor-pointer
            `}>
            <RadioSm
              checked={statusMap[responseStatus] === option}
              onChange={() => handleStatusChange(option)}
            />
            <Body1>{option}</Body1>
          </label>
        ))}
      </div>
    </div>
  );
};

export default MeetingRSVP;
