import {forwardRef, ReactElement, Ref, useCallback} from 'react';
import ReactSelect, {ValueType, ActionMeta} from 'react-select';
import {motion} from 'framer-motion';
import {
  DropdownWrapper,
  DropWrapper,
  DropdownHandler,
  HandlerWrapper,
  ArrowIconWrapper,
  AvatarWrapper,
} from './styled';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {HorizontalSpacer} from '../../../atoms/spacer';
import {Avatar} from '../../../atoms/avatar';
import {Body2, CTA} from '../../../atoms/typography';

import {
  UserSelect,
  SelectFieldProps,
  TOption,
} from '../user-select/user-select';
import {InputState} from '../../../interface';
import {useDropDown} from '../../../../hooks';
import {Helper} from '../../../atoms/helper';

export interface TUserDropSelectFieldProps extends SelectFieldProps {
  label?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

export const UserDropSelectField = forwardRef(
  (
    {state, helper, options, onChange, value, ...rest}: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();

    const onSelectChange = useCallback(
      (value: ValueType<TOption, false>, action: ActionMeta<TOption>) => {
        handleClose();
        if (onChange) {
          onChange(value, action);
        }
      },
      [handleClose, onChange],
    );

    return (
      <DropWrapper ref={dropRef}>
        <DropdownHandler open={open} onClick={handleOpen}>
          <HandlerWrapper>
            <AvatarWrapper>
              {value ? (
                <>
                  <Avatar
                    src={(value as TOption).label.avatar.src}
                    name={(value as TOption).label.avatar.name}
                    userId={(value as TOption).label.id}
                    size="md"
                    tooltip={true}
                  />
                  <HorizontalSpacer size="8px" />
                  <Body2>{(value as TOption).label.name}</Body2>
                </>
              ) : (
                <CTA kind="textDark">Who’s responsible for this?</CTA>
              )}
            </AvatarWrapper>
            <ArrowIconWrapper>
              <motion.span
                initial={{rotate: 0}}
                animate={{
                  rotate: open ? 180 : 0,
                  marginTop: open ? '-6px' : '0px',
                }}
                transition={{
                  duration: 0.3,
                }}
                key="user-select-toggled">
                <ArrowHeadDownIcon />
              </motion.span>
            </ArrowIconWrapper>
          </HandlerWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </DropdownHandler>
        {open && (
          <DropdownWrapper>
            <UserSelect
              {...rest}
              onChange={onSelectChange}
              value={value}
              ref={ref}
              options={options}
            />
          </DropdownWrapper>
        )}
      </DropWrapper>
    );
  },
);
