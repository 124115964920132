// useRescheduleMeetingForm.ts
import {useState, useCallback, useMemo} from 'react';
import dayjs from 'dayjs';
import {splitTimeFormat} from '@utils/date';
import {IMeetingResponse} from '@hooks';
import {useStoreContext} from '@store/store-context';
import {useGCalenderHook} from '@hooks/gcalender';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {MeetingController} from '../meeting-controller';
import {MeetingRequestImpl} from '../meeting.request';
import {useQuery} from 'react-query';
import {useCreateMeetingHook} from '../create-meetings/create-meetings.hook';

export const useRescheduleMeetingForm = (meeting: IMeetingResponse) => {
  const detectAllDay = useMemo(() => {
    if (!meeting.startDate || !meeting.endDate) return false;

    const startDate = dayjs(meeting.startDate);
    const endDate = dayjs(meeting.endDate);

    const isStartAtDayStart =
      startDate.hour() === 0 && startDate.minute() === 0;

    const isEndAtDayEnd = endDate.hour() === 23 && endDate.minute() === 59;
    const fullDaySpan = endDate.diff(startDate, 'days') >= 1;

    return isStartAtDayStart && isEndAtDayEnd && fullDaySpan;
  }, [meeting.startDate, meeting.endDate]);

  const [values, setValues] = useState(() => {
    const isAllDay = detectAllDay;
    let startTime = '';
    let endTime = '';

    if (!isAllDay && meeting.startDate && meeting.endDate) {
      startTime = dayjs(meeting.startDate).format('hh:mm A');
      endTime = dayjs(meeting.endDate).format('hh:mm A');
    }

    return {
      startDate: meeting.startDate,
      endDate: meeting.endDate,
      endTime,
      frequency: meeting.frequency,
      title: meeting?.title || '',
      label: meeting.label,
      participant: meeting.participant.map((p: {id: any}) =>
        typeof p === 'string' ? p : p.id,
      ),
      emailParticipant: [],
      isAllDay,
      enableBeamAI: !!meeting.meta?.botId,
      time: startTime,
      reminder: meeting.reminder,
      meta: meeting.meta || {},
    };
  });

  const [isUpdated, setIsUpdated] = useState(false);

  const handleFormValueChange = useCallback((value: any, name: string) => {
    setValues((prev) => ({...prev, [name]: value}));
    setIsUpdated(true);
  }, []);

  return {
    values,
    isUpdated,
    handleFormValueChange,
  };
};

// useTimeManagement.ts
export const useTimeManagement = (
  values: any,
  handleFormValueChange: Function,
) => {
  const [selectedTime, setSelectedTime] = useState(() => {
    if (values.isAllDay) {
      return {
        starts: {time: '', period: 'AM', error: ''},
        ends: {time: '', period: 'AM', error: ''},
      };
    }

    const startTimeFormat = splitTimeFormat(values.time);
    const endTimeFormat = splitTimeFormat(values.endTime);

    return {
      starts: {
        time: `${startTimeFormat.targetHour}:${startTimeFormat.targetMinute}`,
        period: startTimeFormat.targetPeriod,
        error: '',
      },
      ends: {
        time: `${endTimeFormat.targetHour}:${endTimeFormat.targetMinute}`,
        period: endTimeFormat.targetPeriod,
        error: '',
      },
    };
  });

  const handleAllDayToggle = useCallback(
    (isAllDay: boolean) => {
      handleFormValueChange(isAllDay, 'isAllDay');

      if (isAllDay) {
        const startOfDay = dayjs(values.startDate).startOf('day').format();
        const endOfDay = dayjs(values.startDate).endOf('day').format();

        handleFormValueChange(startOfDay, 'startDate');
        handleFormValueChange(endOfDay, 'endDate');
        handleFormValueChange('', 'time');
        handleFormValueChange('', 'endTime');
      } else {
        const defaultStart = dayjs(values.startDate)
          .set('hour', 9)
          .set('minute', 0);
        const defaultEnd = dayjs(values.startDate)
          .set('hour', 10)
          .set('minute', 0);

        handleFormValueChange(defaultStart.format(), 'startDate');
        handleFormValueChange(defaultEnd.format(), 'endDate');
        handleFormValueChange(defaultStart.format('hh:mm A'), 'time');
        handleFormValueChange(defaultEnd.format('hh:mm A'), 'endTime');
      }
    },
    [handleFormValueChange, values.startDate],
  );

  const updateSelectedTime = useCallback(
    (value: string, field: string, type: 'starts' | 'ends') => {
      setSelectedTime((prev) => ({
        ...prev,
        [type]: {...prev[type], [field]: value, error: ''},
      }));

      const getSelectedTime = {
        ...selectedTime,
        [type]: {...selectedTime[type], [field]: value},
      };

      handleFormValueChange(
        `${getSelectedTime[type].time} ${getSelectedTime[type].period}`,
        type === 'starts' ? 'time' : 'endTime',
      );
    },
    [handleFormValueChange, selectedTime],
  );

  const handleDateChange = useCallback(
    (date: string, type: 'startDate' | 'endDate') => {
      const newDate = dayjs(date);

      if (values.isAllDay) {
        handleFormValueChange(
          type === 'startDate'
            ? newDate.startOf('day').format()
            : newDate.endOf('day').format(),
          type,
        );
      } else {
        if (type === 'startDate') {
          const existingTime = splitTimeFormat(values.time);
          const newDateTime = newDate
            .set('hour', existingTime.targetHour)
            .set('minute', existingTime.targetMinute)
            .format();
          handleFormValueChange(newDateTime, type);
        } else {
          const existingTime = splitTimeFormat(values.endTime);
          const newDateTime = newDate
            .set('hour', existingTime.targetHour)
            .set('minute', existingTime.targetMinute)
            .format();
          handleFormValueChange(newDateTime, type);
        }
      }
    },
    [handleFormValueChange, values.isAllDay, values.time, values.endTime],
  );

  return {
    selectedTime,
    handleAllDayToggle,
    handleDateChange,
    updateSelectedTime,
  };
};

export const useParticipants = (meetingParticipants: string[]) => {
  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const MEETING_WITH = useMemo(() => {
    const computeValue = (user: {
      firstName: string;
      lastName: string;
      email: string;
      avatar: {url: string};
      id: string;
    }) => ({
      label:
        user && user.firstName
          ? user.firstName + ' ' + user.lastName
          : user.email + ' (pending invitation)',
      value: user.id,
      disabled: meetingParticipants.includes(user.id),
    });

    return users
      .filter((user) => user.id !== auth.user.id)
      .map((user) => computeValue(user));
  }, [auth.user.id, meetingParticipants, users]);

  return {MEETING_WITH};
};

export const useBeamMeetingAIConfiguration = (meeting?: IMeetingResponse) => {
  const {
    integrationStore: {activeIntegrations = []},
  } = useStoreContext();

  const [isBotChecked, setIsBotChecked] = useState(!!meeting?.meta?.botId);

  const googleCalendarConfig = activeIntegrations.find(
    (integration) => integration.app === 'Google Calendar',
  );

  const beamAiSettings = googleCalendarConfig?.data?.beamAI || [];
  const hasAutoForVideoLinks = beamAiSettings.includes(
    'all-meetings-with-video-link',
  );
  const hasAutoForUser = beamAiSettings.includes('organized-by-me');

  const isBeforeMeetingEnd = useMemo(
    () => dayjs().isBefore(dayjs(meeting?.startDate).add(1, 'hour')),
    [meeting?.startDate],
  );

  const disableUpdateBeamAI = useMemo(
    () => !meeting?.id || !meeting?.calendarId || !isBeforeMeetingEnd,
    [meeting?.id, meeting?.calendarId, isBeforeMeetingEnd],
  );

  return {
    shouldAutoEnableAndDisable: hasAutoForVideoLinks || hasAutoForUser,
    disableUpdateBeamAI,
    isBeforeMeetingEnd,
    isBotChecked,
    setIsBotChecked,
  };
};

// useMeetingUpdate.ts
export const useMeetingUpdate = (
  meeting: IMeetingResponse,
  values: any,
  onClose: () => void,
  onUpdated?: () => void,
) => {
  const [loading, setLoading] = useState(false);
  const {isSigned, updateEvent} = useGCalenderHook();
  const {
    usersStore: {users},
  } = useStoreContext();

  const onSubmit = useCallback(
    async (editType?: string) => {
      if (!isSigned) {
        alert('Please sign in to Google Calendar first');
        return;
      }

      setLoading(true);

      try {
        let startDateTime, endDateTime;

        if (values.isAllDay) {
          startDateTime = dayjs(values.startDate).startOf('day');
          endDateTime = dayjs(values.endDate).endOf('day');
        } else {
          const {
            targetHour: startHour,
            targetMinute: startMinute,
            targetPeriod: startPeriod,
          } = splitTimeFormat(values.time);

          const {
            targetHour: endHour,
            targetMinute: endMinute,
            targetPeriod: endPeriod,
          } = splitTimeFormat(values.endTime);

          const start24Hour =
            startPeriod.toLowerCase() === 'pm' && startHour <= 12
              ? startHour + 12
              : startPeriod.toLowerCase() === 'am' && startHour === 12
              ? 0
              : startHour;

          const end24Hour =
            endPeriod.toLowerCase() === 'pm' && endHour <= 12
              ? endHour + 12
              : endPeriod.toLowerCase() === 'am' && endHour === 12
              ? 0
              : endHour;

          startDateTime = dayjs(values.startDate)
            .hour(start24Hour)
            .minute(startMinute);

          endDateTime = dayjs(values.startDate)
            .hour(end24Hour)
            .minute(endMinute);
        }

        const eventObject: any = {
          start: values.isAllDay
            ? {date: startDateTime.format('YYYY-MM-DD')}
            : {
                dateTime: startDateTime.format(),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
          end: values.isAllDay
            ? {date: endDateTime.format('YYYY-MM-DD')}
            : {
                dateTime: endDateTime.format(),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
          summary: values.title,
          description: meeting.description || '',
          reminders: {
            useDefault: false,
            overrides: values.reminder.map((reminder: string) => ({
              method: 'email',
              minutes: Number(reminder) * 60,
            })),
          },
          attendees: [
            ...values.emailParticipant.map((participant: string) => ({
              email: participant,
              organizer: false,
              self: false,
              responseStatus: 'needsAction',
            })),
            ...values.participant.map((participant: string) => ({
              email:
                users.find((user) => user.id === participant)?.email ||
                participant,
              organizer: false,
              self: false,
              responseStatus: 'needsAction',
            })),
          ],
        };

        // Update recurrence rule if it exists
        if (values.meta?.recurrenceRule && values.frequency !== 'once') {
          const rule = values.meta.recurrenceRule;
          let recurrenceRule = `FREQ=${rule.type.toUpperCase()};INTERVAL=${
            rule.interval
          }`;

          if (rule.type === 'weekly' && rule.weeklyDays.length > 0) {
            const days = rule.weeklyDays
              .map((day: number) => {
                const dayNames = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
                return dayNames[day];
              })
              .join(',');
            recurrenceRule += `;BYDAY=${days}`;
          }

          if (rule.end.type === 'on') {
            recurrenceRule += `;UNTIL=${dayjs(rule.end.date, 'DD-MM-YY').format(
              'YYYYMMDD',
            )}`;
          } else if (rule.end.type === 'after') {
            recurrenceRule += `;COUNT=${rule.end.occurrences}`;
          }

          eventObject.recurrence = [`RRULE:${recurrenceRule}`];
        }

        // Update Google Calendar event
        if (meeting?.calendarId) {
          await updateEvent(
            eventObject,
            meeting.calendarId,
            'primary',
            editType || 'all',
          );
        }

        // Update Beam backend
        const request = new MeetingRequestImpl();
        const controller = new MeetingController(request);

        const beamData = {
          ...values,
          endDate: dayjs(values.endDate).isValid() ? values.endDate : undefined,
          endTime: undefined,
          time: values.time || undefined,
          isAllDay: undefined,
          addBeamAI: values.enableBeamAI,
          enableBeamAI: undefined,
          meetingLink: values.meetingLink || undefined,
          id: undefined,
          meta: {
            ...values.meta,
            recurringEditType: editType,
          },
        };

        await controller.patchMeeting(beamData, meeting.id);

        if (onUpdated) onUpdated();

        activateNotification({
          content: 'Meeting updated successfully.',
          title: 'Success',
          kind: 'success',
        });

        onClose();
      } catch (error) {
        activateNotification({
          content: 'Failed to update meeting. Please try again.',
          title: 'Error',
          kind: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [isSigned, values, meeting, users, updateEvent, onUpdated, onClose],
  );

  return {
    loading,
    onSubmit,
  };
};

export const useTimezone = () => {
  const {getGoogleCalendarTimeZone} = useCreateMeetingHook();

  const {data: timeZone = ''} = useQuery({
    queryFn: getGoogleCalendarTimeZone,
    queryKey: 'google-timezone',
  });

  return {timeZone};
};
