import {memo, useEffect, useRef, useState} from 'react';
import {Helper} from '../../../../../../../../../ui/atoms/helper';
import {CancelIcon, MinusIcon} from '../../../../../../../../../ui/atoms/icons';
import {GreenWhitePlusIcon} from '../../../../../../../../../ui/atoms/icons/greenWhitePlus';
import {
  VerticalSpacer,
  HorizontalSpacer,
} from '../../../../../../../../../ui/atoms/spacer';
import {ToggleSwitch} from '../../../../../../../../../ui/atoms/toggle-switch';
import {CTA} from '../../../../../../../../../ui/atoms/typography';
import {TextField} from '../../../../../../../../../ui/molecules/field/textfield';
import {OptionSelectField} from '../../../../../../../../../ui/molecules/select/option';
import {capitalize} from '../../../../../../../../../utils';
import {DropdownWrapper} from '../create-question.styles';
import {QuestionSelect} from './questions';

type TQuestion = {
  questionTypes: any;
  questionTypeHelper: any;
  questionTypeState: any;
  key_: string;
  value: any;
  setValue: any;
  removeQuestion: any;
  index: number;
  questions_: any;
  questions__: any;
  setQuestions__: any;
  state: any;
  helper: any;
};

export const Question = memo(
  ({
    questionTypes,
    key_,
    questionTypeHelper,
    questionTypeState,
    value,
    setValue,
    removeQuestion,
    index,
    questions_,
    questions__,
    setQuestions__,
    state,
    helper,
  }: TQuestion) => {
    const [selectedQuestionType, setSelectedQuestionType] = useState(
      value?.type,
    );
    const [selectedQuestion, setSelectedQuestion] = useState(value?.text);
    const [showQuestionDropdown, setShowQuestionDropdown] = useState(false);
    const dropRef = useRef(null);
    const useOutsideAlerter = (ref: any) => {
      useEffect(() => {
        function handleClickOutside(event: any) {
          if (ref?.current && !ref?.current?.contains(event?.target)) {
            setShowQuestionDropdown(false);
          }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref]);
    };
    useOutsideAlerter(dropRef);

    return (
      <div>
        <VerticalSpacer size="8px" />
        <div
          className="bg-white"
          style={{
            padding: '8px 16px 8px',
            border: '1px solid #EDEDF2',
            borderRadius: 10,
            position: 'relative',
          }}>
          {selectedQuestionType !== undefined && (
            <OptionSelectField
              options={questionTypes}
              name="questionType"
              value={value?.type}
              defaultValue={{
                label: capitalize(selectedQuestionType),
                value: selectedQuestionType,
              }}
              helper={questionTypeHelper}
              state={questionTypeState}
              onChange={(data: any) => {
                setValue({
                  ...value,
                  type: data?.value,
                });
                setSelectedQuestionType(data.value);
              }}
              placeholder="Select type"
              fieldNotFoundPlaceHolder={(searchTerm?: string) =>
                `Oops! this question type does not exist`
              }
              optionsSelectType="checkins"
            />
          )}

          {selectedQuestion !== '' && selectedQuestionType !== 'custom' && (
            <>
              <div
                style={{
                  padding: '14px 16px',
                  borderRadius: 10,
                  border: '1px solid #EDEDF2',
                  background: '#FAFAFA',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <CTA kind="textDark">{selectedQuestion}</CTA>
                <div
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    if (selectedQuestionType) {
                      const newQuestions = questions__;
                      const index = newQuestions.findIndex(
                        (q: any) => q?.type?.value === selectedQuestionType,
                      );
                      newQuestions[index].question = [
                        ...newQuestions[index].question,
                        {
                          label: value?.text,
                          value: value?.type,
                        },
                      ];

                      setQuestions__(newQuestions);
                    }
                    if (!selectedQuestionType) {
                      setSelectedQuestionType('');
                    }

                    setSelectedQuestion('');
                    setValue({
                      ...value,
                      text: '',
                    });
                  }}>
                  <CancelIcon />
                </div>
              </div>
              <VerticalSpacer size="24px" />
            </>
          )}
          {selectedQuestionType && !showQuestionDropdown && (
            <>
              {selectedQuestionType === 'custom' && (
                <TextField
                  placeholder="How are you today?"
                  autoComplete="text"
                  type="text"
                  value={selectedQuestion}
                  onChange={(e: any) => {
                    setSelectedQuestion(e?.target?.value);
                    setValue({
                      ...value,
                      text: e?.target?.value,
                    });
                  }}
                  autoFocus
                  name="questionText"
                />
              )}
              {selectedQuestionType !== 'custom' && !selectedQuestion && (
                <div
                  style={{
                    marginTop: '-16px',
                    border: '1px dashed #CECEDE',
                    borderRadius: 10,
                    background: '#FAFAFA',
                    padding: '14px 16px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    opacity: selectedQuestion !== '' ? 0.6 : 1,
                  }}
                  onClick={() => {
                    if (selectedQuestion !== '') return false;
                    setShowQuestionDropdown(true);
                  }}>
                  <GreenWhitePlusIcon disabled={selectedQuestion !== ''} />{' '}
                  <HorizontalSpacer size="16px" />{' '}
                  <CTA kind="textBody">Select question</CTA>
                </div>
              )}
            </>
          )}
          <Helper
            aria-label="helper"
            state={
              helper?.length > index && !!helper[index]?.text?.message
                ? 'error'
                : 'default'
            }
            children={
              helper?.length > index ? helper[index]?.text?.message : undefined
            }
          />
          <VerticalSpacer size="16px" />
          {
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                width: '100%',
              }}>
              <ToggleSwitch
                switchId={index}
                onChange={(e) => {
                  setValue({
                    ...value,
                    optional: !e?.target?.checked,
                  });
                }}
                defaultChecked={!value?.optional}
                disabled={
                  false
                  // isEditingCheckinsFrequency
                }
              />
              <HorizontalSpacer size="8px" />
              <CTA kind="textDark" style={{fontWeight: 600}}>
                Required question
              </CTA>
            </div>
          }

          <VerticalSpacer size="16px" />
          {index !== 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                stroke: '#D52A2A',
                cursor: 'pointer',
                width: '100%',
              }}>
              <MinusIcon />
              <HorizontalSpacer size="8px" />
              <CTA
                kind="red400"
                onClick={() => {
                  if (selectedQuestionType) {
                    const newQuestions = questions__;
                    const index = newQuestions.findIndex(
                      (q: any) => q?.type?.value === selectedQuestionType,
                    );
                    newQuestions[index].question = [
                      ...newQuestions[index].question,
                      {
                        label: value?.text,
                        value: value?.type,
                      },
                    ];

                    setQuestions__(newQuestions);
                  }
                  removeQuestion(key_);
                }}>
                Remove
              </CTA>
            </div>
          )}
          {selectedQuestionType && showQuestionDropdown && (
            <DropdownWrapper style={{zIndex: 999999}} ref={dropRef}>
              <QuestionSelect
                name="question"
                onChange={(data: any) => {
                  setShowQuestionDropdown(false);
                  setSelectedQuestion(data.label);
                  setValue({
                    ...value,
                    text: data?.label,
                  });
                  const newQuestions = questions__;
                  const index = newQuestions.findIndex(
                    (q: any) => q?.type?.value === selectedQuestionType,
                  );
                  newQuestions[index].question = newQuestions[
                    index
                  ].question.filter((qq: any) => qq.value !== data?.value);
                  setQuestions__(newQuestions);
                }}
                options={questions_(selectedQuestionType)}
                placeholder="Search"
                fieldNotFoundPlaceHolder={(searchTerm: any) =>
                  searchTerm ? `${searchTerm} not found` : 'Question not found'
                }
                dropRef={dropRef}
              />
            </DropdownWrapper>
          )}
        </div>
      </div>
    );
  },
);
