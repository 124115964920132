/* eslint-disable react-hooks/rules-of-hooks */
import {Button} from '../../ui/atoms/button';
import {TextField} from '../../ui/molecules/field/textfield';
import {ErrorBadge} from '../../ui/molecules/error-badge';
import {useMemo, useEffect, useState} from 'react';
import {useCreateGoalHook} from './create-goal-hooks';
import {CreateGoalFormData} from './create-goal-interface';
import {Controller} from 'react-hook-form';
import {DateRangeInput} from '../../ui/molecules/date-range-input';
import {UserSelectField} from '../../ui/molecules/select/user';
import {VerticalSpacer} from '../../ui/atoms/spacer';
import AddDescription from './add-description';
import {SelectField} from '@ui/molecules/select/selectfield';
import {GoalKind} from '../../hooks/interface';
import {Label} from '../../ui/atoms/label';
import {ItemLoader} from '../../ui/organisms/item-loader';
import {useStoreContext} from '../../store/store-context';
import {useSearchParams} from 'react-router-dom';
import {observer} from 'mobx-react';
import {Helper} from '@ui/atoms/helper';
import {
  KpiMeasurement,
  KpiMeasurementSymbol,
  KpiTarget,
} from '@ui/molecules/goal/kpi-measurement/kpi-measurement';
import {Body2, Headline2} from '../../ui/atoms/typography';
import {KeyResults} from '../../ui/organisms/keyresults/keyresult';
import axios from 'axios';
import {BaseURL} from '../../configs/request';
import {authStore} from '../../store/stores/auth-store';
import {MeasurementWrapper, EditHeader} from './create-goal.styles';
import {GoalTypeField} from '../../ui/molecules/field/goal-type-field';
import {FlexRow, FlexRowEnd} from '@ui/style/styles';
import {cfv, computeFrameworkVars} from '../../utils/framework';
import {useShowGroupTypeDrawer} from '@pages/dashboard/groups/group-hook';
import {useNavigate} from 'react-router-dom';
import {MeasurementType} from '@ui/interface';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {MultiOptionsSelectFieldGroup} from '@ui/molecules/select/multi-options/multi-options';

export const CreateGoalForm = observer(
  ({
    onSubmit,
    loading: isSubmitting,
    editheaderShow,
    handleCloseDrawer,
  }: {
    onSubmit: (data: CreateGoalFormData) => Promise<void>;
    editheaderShow?: boolean;
    loading?: boolean;
    handleCloseDrawer?: any;
  }) => {
    const {
      handleSubmit,
      submitForm,
      register,
      errors,
      validateForm,
      control,
      hasError,
      handleFormValueChange,
      userGroup,
      watch,
      updateKeyResult,
      isFetching,
      editable,
      todayDate,
      users,
      submitEditedForm,
      isGroup,
      handleCurrencyChange,
      setIsGroup,
      setIsAlign,
      KpiMeasurementOption,
      KpiTypeOption,
      isGoalKpi,
      handleSelectGroup,
      handleSelectGroupType,
      loader,
    } = useCreateGoalHook(onSubmit);

    const {
      authStore: {auth},
      groupStore: {groups: group},
      groupTypeStore: {groupType},
    } = useStoreContext();

    const formatOptionsTypes = useMemo(() => {
      return groupType?.map((grpType) => {
        return {
          ...grpType,
          data: group
            .filter((group) => group.groupType === grpType.id)
            .map((group) => {
              return {
                label: group.name,
                value: group.id,
              };
            }),
        };
      });
    }, [group, groupType]);

    const activateGroupDrawer = useShowGroupTypeDrawer();

    const [searchParams] = useSearchParams();
    const duplicate_goal = searchParams.get('duplicate_goal');

    const {isEditing} = useStoreContext().goalsState;

    const [selectedGoalType, setSelectedGoalType] = useState<
      GoalKind | undefined | string
    >(isEditing ? undefined : GoalKind.GROUP);

    const [members, setMembers] = useState(users);

    useEffect(() => {
      const findUserGroup = group.find(
        (grp: any) => grp.id === userGroup?.[0]?.group,
      );

      if (
        selectedGoalType === GoalKind.GROUP &&
        !watch()?.group &&
        findUserGroup
      ) {
        handleSelectGroup(userGroup?.[0]?.group);
        handleSelectGroupType(userGroup?.[0]?.groupType);
      }
    }, [
      selectedGoalType,
      watch,
      group,
      userGroup,
      handleSelectGroup,
      handleSelectGroupType,
    ]);

    useEffect(() => {
      axios
        .get(`${BaseURL}/users?paginate=false`, {
          headers: {
            Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
          },
        })
        .then((response) => {
          let userData = response.data;
          setMembers(
            userData &&
              userData
                .filter((person: any) => person.status.includes('on'))
                // eslint-disable-next-line array-callback-return
                .map((member: any) => {
                  if (member) {
                    return {
                      label: {
                        avatar: {
                          name:
                            member && member.firstName
                              ? member.firstName + ' ' + member.lastName
                              : member.email + ' (pending invitation)',
                          src:
                            member.avatar && member.avatar.url
                              ? member.avatar.url
                              : '',
                        },
                        id: member.id,
                        name:
                          member && member.firstName
                            ? member.firstName + ' ' + member.lastName
                            : member.email + ' (pending invitation)',
                      },
                      value: member.id,
                    };
                  }
                }),
          );
        });
    }, []);

    useEffect(() => {
      if (isEditing || duplicate_goal) {
        const isDividual =
          editable?.goalType?.toLowerCase() === 'self-development';

        const goalType = isDividual ? 'individual' : editable?.goalType;

        setSelectedGoalType(goalType?.toUpperCase());
      }
    }, [editable?.goalType, isEditing, duplicate_goal]);

    useEffect(() => {
      if ((isEditing || duplicate_goal) && editable?.goalAlignment) {
        setIsAlign(true);
      }
    }, [editable, isEditing, setIsAlign, duplicate_goal]);

    const goalKindName = isGoalKpi
      ? `KPI`
      : computeFrameworkVars(auth?.user?.workspace?.framework)?.g_oal;

    const kpiMeasurement = useMemo(() => {
      return (
        <>
          <VerticalSpacer size="16px" />

          <Label>KPI type</Label>
          <VerticalSpacer size="8px" />
          <Controller
            control={control}
            name="meta.kpiType"
            render={({onBlur, value}) => (
              <SelectField
                options={KpiTypeOption}
                borderRadius={10}
                defaultValue={value}
                excludeSearchBox
                // helper={errors.measurement?.preference?.message || ''}
                // state={!!errors.measurement?.preference ? 'error' : 'default'}
                onChange={(data: {value: string}) =>
                  handleFormValueChange(data?.value, 'meta.kpiType')
                }
                placeholder={'Choose KPI type'}
              />
            )}
          />

          <VerticalSpacer size="16px" />
          <Label>How should the calculated KPI data be displayed?</Label>
          <VerticalSpacer size="8px" />
          <Controller
            control={control}
            name="measurement.preference"
            render={({onBlur, value}) => (
              <SelectField
                options={KpiMeasurementOption}
                borderRadius={10}
                defaultValue={value}
                helper={errors.measurement?.preference?.message || ''}
                state={errors.measurement?.preference ? 'error' : 'default'}
                onChange={(data: {value: string}) =>
                  handleFormValueChange(data?.value, 'measurement.preference')
                }
                placeholder={'Choose display type'}
              />
            )}
          />
        </>
      );
    }, [
      control,
      KpiTypeOption,
      handleFormValueChange,
      KpiMeasurementOption,
      errors.measurement?.preference,
    ]);

    const measurementElement = useMemo(() => {
      return (
        <>
          {isGoalKpi && (
            <>
              <Label>
                What kind of metric unit will be used to measure it?
              </Label>

              <MeasurementWrapper
                error={
                  !!errors?.measurement?.unit || !!errors?.measurement?.symbol
                }>
                <>
                  <Controller
                    control={control}
                    name="measurement.unit"
                    render={({onBlur, value}) => (
                      <KpiMeasurement
                        measurement={{unit: value}}
                        onChange={(e) =>
                          handleFormValueChange(e, 'measurement.unit')
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="measurement.symbol"
                    render={({onBlur, value}) => (
                      <KpiMeasurementSymbol
                        measurement={watch().measurement}
                        handleCurrencyChange={(value) =>
                          handleCurrencyChange(value)
                        }
                        errors={errors.measurement}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="range.target"
                    render={({onBlur, value}) => (
                      <>
                        <KpiTarget
                          targetValue={watch().range.target}
                          measurement={watch().measurement.unit}
                          onChangeTarget={(e) => {
                            handleFormValueChange(e, 'range.target');
                          }}
                        />
                      </>
                    )}
                  />
                </>
              </MeasurementWrapper>
              <Helper
                state={errors?.measurement ? 'error' : 'default'}
                children={
                  errors?.measurement?.unit?.message ||
                  errors?.measurement?.symbol?.message ||
                  ''
                }
              />
            </>
          )}
          {!isGoalKpi && (
            <Controller
              control={control}
              name="measurement.unit"
              defaultValue={MeasurementType.BINARY}
              render={({onBlur, value}) => <></>}
            />
          )}
        </>
      );
    }, [
      errors?.measurement,
      watch,
      control,
      isGoalKpi,
      handleCurrencyChange,
      handleFormValueChange,
    ]);

    const keyResultComps = useMemo(
      () => (
        <Controller
          control={control}
          name={`keyResults`}
          defaultValue={
            editable?.keyResults
              ? editable?.keyResults
              : watch().keyResults
              ? watch().keyResults
              : []
          }
          render={({value}) => (
            <>
              <KeyResults
                defaultValue={value}
                options={members}
                setValue={updateKeyResult}
                errors={errors.keyResults}
                validateForm={validateForm}
                helper={(errors as any).keyResults?.message || ''}
                state={errors.keyResults ? 'error' : 'default'}
              />
            </>
          )}
        />
      ),
      [
        control,
        editable?.keyResults,
        watch,
        members,
        updateKeyResult,
        errors,
        validateForm,
      ],
    );

    const editLoading: boolean = loader?.edit;

    const navigate = useNavigate();
    const explore_template = searchParams.get('explore-templates');

    if (isFetching) {
      return <ItemLoader />;
    }

    return (
      <form
        method={isEditing ? 'patch' : 'post'}
        onSubmit={handleSubmit(isEditing ? submitEditedForm : submitForm)}>
        <>
          {isEditing && editheaderShow && (
            <>
              <EditHeader style={{justifyContent: 'space-between'}}>
                <Headline2>Edit {isGoalKpi ? 'KPI' : cfv().g_oal}</Headline2>
              </EditHeader>
              <VerticalSpacer size="26px" />
            </>
          )}
        </>
        <div className="flex sm:flex-col flex-row items-center sm:items-start  justify-between">
          <FlexRow>
            <Label>
              What's the name of this {goalKindName}?{' '}
              <span className="required">*</span>
            </Label>
          </FlexRow>

          {!isGoalKpi && !duplicate_goal && (
            <FlexRowEnd className="sm:w-full ">
              <PlainButton
                type="button"
                disabled={!!explore_template}
                onClick={() => navigate({search: '?explore-templates=true'})}>
                <Body2
                  weight="bold"
                  kind={explore_template ? 'textMuted' : 'purple300'}>
                  Use a template?
                </Body2>
              </PlainButton>
            </FlexRowEnd>
          )}
        </div>

        <VerticalSpacer size="8px" />
        <TextField
          placeholder="e.g Increase global numbers"
          defaultValue=""
          type="text"
          ref={register}
          inputStyle={{borderRadius: '10px'}}
          readOnly={!!explore_template}
          helper={errors.name?.message || ''}
          state={errors.name ? 'error' : 'default'}
          name="name"
        />

        <Controller
          control={control}
          name="meta"
          render={({value}) => (
            <AddDescription
              value={value}
              isGoalKpi={!!isGoalKpi}
              initSavedMode={isEditing || !!duplicate_goal}
              onChange={(data: {tags: Array<string>; description: string}) =>
                handleFormValueChange(data, 'meta')
              }
            />
          )}
        />

        <VerticalSpacer size="24px" />

        <FlexRow>
          <Label>What kind of {goalKindName} is this?</Label>
        </FlexRow>
        <Controller
          control={control}
          name="goalType"
          defaultValue={
            isEditing || !!duplicate_goal ? selectedGoalType : GoalKind.GROUP
          }
          render={({value, onChange}) => {
            return (
              <GoalTypeField
                name="goalType"
                value={value}
                setValue={onChange}
                onClick={(data: any) => {
                  setIsGroup(data.target.value === GoalKind.GROUP && true);
                  setSelectedGoalType(data.target.value);
                }}
              />
            );
          }}
        />
        {(selectedGoalType === GoalKind.GROUP || isGroup) && (
          <>
            <Label>Select group</Label>
            <VerticalSpacer size="10px" />

            <Controller
              control={control}
              name="group"
              render={({onBlur, value}) => (
                <MultiOptionsSelectFieldGroup
                  control={control}
                  customOption={auth?.user?.role === 'admin'}
                  placeholder="Select Group"
                  searchPlaceholder="Search group"
                  groupDisplayLength={3}
                  maxSelection={3}
                  disabled={!!explore_template}
                  reporting
                  helper={errors.group?.message || ''}
                  state={errors.group?.message ? 'error' : 'default'}
                  defaultValue={
                    value &&
                    Array.isArray(value) &&
                    group
                      .filter((_group) =>
                        value.some((group_) =>
                          typeof group_ === 'string'
                            ? group_ === _group.id
                            : group_?.id === _group.id,
                        ),
                      )
                      ?.map((group) => ({
                        label: group.name,
                        value: group.id,
                      }))
                  }
                  onClickCustomOption={() => activateGroupDrawer()}
                  onChange={(data: {label: string; value: string}[]) => {
                    handleSelectGroup(data.map((group) => group.value));
                  }}
                  options={formatOptionsTypes}
                  feature="invite"
                />
              )}
            />
            <VerticalSpacer size="24px" />
          </>
        )}

        {/* {myMeasures} */}
        <FlexRow>
          <Label>Who's responsible for this {goalKindName}?</Label>
        </FlexRow>
        <Controller
          control={control}
          name="manager"
          defaultValue={editable ? editable?.manager : auth?.user?.id}
          render={({onBlur, value}) => (
            <UserSelectField
              onBlur={onBlur}
              name="manager"
              value={value}
              disabled={!!explore_template}
              defaultValue={editable ? editable?.manager : auth?.user?.id}
              helper={errors.manager?.message || ''}
              state={errors.manager ? 'error' : 'default'}
              onChange={(data: {value: string}) =>
                handleFormValueChange(data.value, 'manager')
              }
              placeholder="Select member"
              options={members}
              fieldNotFoundPlaceHolder={(searchTerm?: string) =>
                `Oops! Seems there is no ${searchTerm} found in this workspace`
              }
            />
          )}
        />

        {watch().goalType !== GoalKind.COMPANY && (
          <VerticalSpacer size="24px" />
        )}

        <FlexRow>
          <Label>What’s the timeline like?</Label>
        </FlexRow>
        <VerticalSpacer size="8px" />
        <Controller
          control={control}
          name="date"
          defaultValue={todayDate}
          render={({value}) => (
            <DateRangeInput
              name="date"
              value={value}
              dateAlign="left"
              placeholder={'Select'}
              helper={errors.date?.ends?.message || ''}
              state={errors.date ? 'error' : 'default'}
              setValue={(value: {starts: string; ends: string}) =>
                handleFormValueChange(value, 'date')
              }
            />
          )}
        />
        <VerticalSpacer size="8px" />

        {measurementElement}
        {isGoalKpi && kpiMeasurement}
        {!isGoalKpi && keyResultComps}

        {hasError && (
          <ErrorBadge>
            There are few errors in the form, check to fix the error
          </ErrorBadge>
        )}

        <VerticalSpacer size="18px" />

        {!isEditing && (
          <Button
            width="full"
            type="submit"
            data-form-action={true}
            isLoading={isSubmitting}
            disabled={isSubmitting || !!explore_template}
            aria-busy={isSubmitting}>
            Publish {goalKindName}
          </Button>
        )}
        {isEditing && (
          <>
            <Button
              width="full"
              style={{
                padding: editheaderShow ? '12px 24px' : '',
              }}
              onClick={() => !hasError}
              isLoading={editLoading}>
              Save changes
            </Button>
          </>
        )}
        {editheaderShow && (
          <>
            <Button
              kind="secondary"
              width="full"
              style={{
                marginTop: '10px',
                padding: '12px 24px',
              }}
              onClick={handleCloseDrawer}>
              Cancel
            </Button>
          </>
        )}
      </form>
    );
  },
);
