import {cloudinary} from '@assets/images/cloudinary';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {Body1, Body2, Headline2} from '@ui/atoms/typography';
import {memo, useEffect, useRef, useState} from 'react';
import {CopyNotification, ResponsiveFlexBetween} from './styles';
import {FlexRow} from '@ui/style/styles';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import dayjs from 'dayjs';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {MeetingBotResponse} from '@hooks';
import {CopyIcon} from '@ui/atoms/icons';
import {MeetingRequestImpl} from '../meeting.request';
import {MeetingController} from '../meeting-controller';
import {useDebouncedCallback} from 'use-debounce';
import {MeetingAiAgenda} from './components/ai/meeting-agenda';
import {MeetingAiActionItems} from './components/ai/action-items';
import {SectionMenu} from '@ui/layouts/section-card/section-toggle';
import {RichTextEditor} from '@ui/molecules/editor/editor-rich-text';
import {useQuery} from 'react-query';
import {ItemLoader} from '@ui/organisms/item-loader';

const {emptyGoals, sortImage} = cloudinary;

const inProgressPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: sortImage,
    style: {height: '90%', width: '90%'},
  },
  imports: true,
  subtitle:
    'Your meeting is being analysed by BeamAI to create a smart summary. Stay tuned!',
  title: 'AI meeting summary in progress',
};

const errorPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },
  imports: true,
  subtitle:
    "It might be because it hasn't happened yet or there was an issue with recording notes.",
  title: 'There are no meeting summaries available for this meeting ',
};

interface AiSummaryPageProps {
  meetingTitle: string;
  agendaType: string;
  agenda: {text: string; completed: boolean}[];
  meetingId: string;
  inProgress?: boolean;
  host: string;
  participants: string[];
}

const AiSummaryPage = memo(
  ({
    meetingId,
    meetingTitle,
    inProgress,
    host,
    agenda,
    agendaType,
    participants,
  }: AiSummaryPageProps) => {
    const request = new MeetingRequestImpl();
    const controller = new MeetingController(request);

    const {data: summary, refetch: refetchBotResponse, isLoading} = useQuery(
      ['view-meeting-bot-response', meetingId],
      () => controller.getMeetingBotResponse(meetingId),
    );

    if (isLoading) {
      return (
        <div className="p-6 rounded-lg border border-borderLight">
          <ItemLoader />
        </div>
      );
    }
    if (inProgress) {
      return (
        <div className="p-6 rounded-lg border border-borderLight">
          <EmptyPlaceholder {...inProgressPlaceholder} imgPosition="bottom" />
        </div>
      );
    }
    if (!summary || typeof summary === 'boolean') {
      return (
        <div className="p-6 rounded-lg border border-borderLight">
          <EmptyPlaceholder {...errorPlaceholder} imgPosition="bottom" />
        </div>
      );
    }

    const updateMeetingResponse = async (field: string, value: any) => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const updatedData = {
        actionItems: summary.actionItems,
        decisionsMade: summary.decisionsMade,
        agenda: summary.agenda,
        nextSteps: summary.nextSteps,
        summary: summary.summary,
        discussion: summary.discussion,
        additionalNotes: summary.additionalNotes,
        meetingDetails: summary.meetingDetails,
      };

      const response = await controller.updateMeetingBotResponse(meetingId, {
        ...updatedData,
        [field]: value,
      });

      await refetchBotResponse();

      return response;
    };

    const file = new Blob([summary.transcript], {type: 'text/plain'});

    return (
      <div>
        <Headline2>{meetingTitle}</Headline2>
        <VerticalSpacer size="8px" />

        <ResponsiveFlexBetween>
          <div>
            <FlexRow>
              <Body2 kind="textBody">AI generated meeting recap</Body2>

              <>
                <HorizontalSpacer size="10px" />
                <CustomColorIcon
                  height="8px"
                  width="8px"
                  margin={7}
                  color="#D9D9D9"
                />
                <Body2 kind="textBody">
                  {' '}
                  Added {dayjs(summary.createdAt).format(
                    "ddd. D MMM. 'YY",
                  )}{' '}
                </Body2>
              </>

              <HorizontalSideRule size="22px" style={{margin: '0px 15px'}} />
              <a
                download={`${meetingTitle}-${summary.createdAt}.txt`}
                target="_blank"
                rel="noreferrer"
                href={URL.createObjectURL(file)}>
                <Body2 kind="purple300">Download transcript</Body2>
              </a>
            </FlexRow>
          </div>
        </ResponsiveFlexBetween>

        <VerticalSpacer size="24px" />

        <MeetingSummary summary={summary.summary} />

        <VerticalSpacer size="24px" />
        <MeetingAiAgenda
          agenda={summary.agenda}
          host={host}
          updateMeetingResponse={(agenda) =>
            updateMeetingResponse(
              'agenda',
              agenda.map((_agenda) => ({
                text: _agenda.text,
                status: 'pending',
              })),
            )
          }
          meetingId={meetingId}
          prevAgenda={agenda}
          agendaType={agendaType}
          participants={participants}
        />

        <VerticalSpacer size="24px" />
        <MeetingAiActionItems
          participants={participants}
          updateMeetingResponse={(actionItems) =>
            updateMeetingResponse(
              'actionItems',
              actionItems.map((_agenda) => ({
                text: _agenda.text,
                status: 'pending',
              })),
            )
          }
          _actionItems={summary.actionItems}
          meetingId={meetingId}
        />
        <VerticalSpacer size="24px" />

        <MeetingDiscussion
          discussion={summary.discussion}
          updateMeetingResponse={(discussion) =>
            updateMeetingResponse('discussion', discussion)
          }
        />

        <VerticalSpacer size="24px" />

        <MeetingDecisions decisions={summary.decisionsMade} />
      </div>
    );
  },
);

interface MeetingSummaryProps {
  summary: MeetingBotResponse['summary'];
}

const MeetingSummary = ({summary}: MeetingSummaryProps) => {
  const formattedSummary = summary.replace(/\*_n_\*/g, ' ');

  return (
    <div>
      <SectionMenu title="Summary">
        <div className="m-6">
          <Body1>{formattedSummary}</Body1>
        </div>
      </SectionMenu>
    </div>
  );
};

interface MeetingDetailsProps {
  decisions: MeetingBotResponse['decisionsMade'];
}

const MeetingDecisions = memo(({decisions}: MeetingDetailsProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const [decisionValue, setDecisionValue] = useState(
    decisions.map((decision) => `- ${decision.text} \n `).join('\n \n'),
  );

  return (
    <div>
      <SectionMenu
        title="Decisions"
        action={
          <div className="relative">
            {isCopied && (
              <CopyNotification>
                <Body1>Copied!</Body1>
              </CopyNotification>
            )}
            <div
              className="cursor-pointer"
              onClick={() => {
                setIsCopied(true);
                navigator.clipboard.writeText(decisionValue).then(() => {
                  setTimeout(() => setIsCopied(false), 3000);
                });
              }}>
              <CopyIcon />
            </div>
          </div>
        }>
        <div className="p-6 ">
          <div className="border border-[#cecede] rounded-[10px]">
            <RichTextEditor
              placeholder="Write"
              value={decisionValue}
              onChange={(event) => {
                setDecisionValue(event.markdown);
              }}
            />
          </div>
        </div>
      </SectionMenu>
    </div>
  );
});
interface MeetingDiscussionProps {
  discussion: MeetingBotResponse['discussion'];
  updateMeetingResponse: (discussion: string) => void;
}

const MeetingDiscussion = memo(
  ({discussion, updateMeetingResponse}: MeetingDiscussionProps) => {
    const [isCopied, setIsCopied] = useState(false);

    const [discussionValue, setDiscussionValue] = useState(discussion);
    const debounceValue = useDebouncedCallback(
      (value) => updateMeetingResponse(value),
      2000,
    );
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const resizeTextArea = () => {
      if (textAreaRef.current) {
        const scrollHeight = textAreaRef.current.scrollHeight;

        textAreaRef.current!.style.height = 'auto';

        textAreaRef.current!.style.height = `${scrollHeight}px`;
      }
    };

    useEffect(() => {
      resizeTextArea();
    }, []);

    return (
      <div>
        <SectionMenu
          title="Discussion"
          action={
            <div className="relative">
              {isCopied && (
                <CopyNotification>
                  <Body1>Copied!</Body1>
                </CopyNotification>
              )}
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsCopied(true);
                  navigator.clipboard.writeText(discussionValue).then(() => {
                    setTimeout(() => setIsCopied(false), 3000);
                  });
                }}>
                <CopyIcon />
              </div>
            </div>
          }>
          <div className="p-6">
            <div className="border border-[#cecede] rounded-[10px] overflow-auto">
              <RichTextEditor
                placeholder="Write"
                value={discussionValue}
                onChange={(event) => {
                  setDiscussionValue(event.markdown);
                  debounceValue(event.markdown);
                }}
              />
            </div>
          </div>
        </SectionMenu>
      </div>
    );
  },
);

export default AiSummaryPage;
