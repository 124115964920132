import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {DropdownCustom} from './dropdown-custom';
import {ReactNode} from 'react';

interface DropdownProps {
  options: {label: string; onClick: () => void; disabled?: boolean}[];
  menu: (handleOpen: (event: any) => void, open: boolean) => ReactNode;
  menuOptions?: any;
  canClose?: (value: string) => boolean;
}

export const SimpleCustomDropdown = ({
  options,
  menu,
  canClose,
  menuOptions,
}: DropdownProps) => {
  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) =>
        menuOptions
          ? menuOptions
          : options.map((option) => (
              <DropdownItem
                key={option.label}
                hoverColor="#585ADF"
                disabled={option.disabled}
                onClick={() => {
                  option.onClick();

                  if (!!canClose) {
                    if (canClose(option.label)) {
                      handleClose();
                    }
                    return;
                  }
                  handleClose();
                }}
                style={{marginBottom: '5px', whiteSpace: 'nowrap'}}>
                {option.label}
              </DropdownItem>
            ))
      }
      customComponent={(handleOpen: (event: any) => void, open: boolean) =>
        menu(handleOpen, open)
      }
      customDropdownWrapperStyles={
        menuOptions
          ? {
              top: '130%',
              boxShadow: '0px',
              left: 0,
              right: 0,
              padding: 0,
              overflowY: 'auto',
              background: 'none',
              width: '380px',
              border: 0,
            }
          : {
              top: '130%',
              right: 0,
              // width: '100%',
            }
      }
    />
  );
};
