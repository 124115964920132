import {Checkbox} from '@ui/atoms/checkbox';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {Body2} from '@ui/atoms/typography';
import {FlexRow} from '@ui/style/styles';
import {FC, memo, useState} from 'react';
import {InsightTable} from './insight-table';
import {Insight} from './interface';
import {IDashboard} from '../dashboard/dashboard-hook';
import {SimpleCustomDropdown} from '@ui/molecules/dropdown-custom/simple-dropdown-custom';

interface InsightsOverviewProps {
  insights: Insight[];
  onEdit: (id: string) => void;
  dashboards: IDashboard[];
  handleAddToDashboard: (value: string[], dashboardId: string) => void;
  filterActive?: boolean;
}

export const InsightsOverview: FC<InsightsOverviewProps> = memo(
  ({insights, filterActive, onEdit, dashboards, handleAddToDashboard}) => {
    const [selectedInsights, setSelectedInsights] = useState<string[]>([]);

    const hasSelectedInsights = selectedInsights.length;

    const checkInsight = (id: string) =>
      selectedInsights.includes(id)
        ? setSelectedInsights((prev) =>
            prev.filter((insight) => insight !== id),
          )
        : setSelectedInsights((prev) => [...prev, id]);

    const handleToggleAll = () => {
      if (selectedInsights.length) {
        setSelectedInsights([]);
        return;
      }

      setSelectedInsights(
        insights.map((insight) => insight.firebaseId || insight.id),
      );
    };

    return (
      <div className="border border-borderLight rounded-[10px] bg-white mt-6">
        <FlexRow className="px-6 py-4 gap-4">
          <FlexRow className="gap-1">
            <Checkbox
              checked={!!hasSelectedInsights}
              kind={!hasSelectedInsights ? 'import' : 'indeterminate'}
              state={hasSelectedInsights ? 'error' : undefined}
              onChange={handleToggleAll}
            />
            {hasSelectedInsights ? (
              <Body2>Deselect</Body2>
            ) : (
              <Body2 kind="textBody">Select all</Body2>
            )}
          </FlexRow>

          <HorizontalSideRule size="22px" />

          {hasSelectedInsights ? (
            <SimpleCustomDropdown
              menu={(handleOpen) => (
                <button onClick={handleOpen}>
                  <FlexRow className="gap-1">
                    <SecondaryPlusIcon />
                    <Body2 kind="purple300">Add to dashboard</Body2>
                  </FlexRow>
                </button>
              )}
              options={dashboards.map((dashboard) => ({
                label: dashboard.name,
                onClick: () => {
                  handleAddToDashboard(selectedInsights, dashboard.key);
                  setSelectedInsights([]);
                },
              }))}
            />
          ) : (
            <Body2 kind="textBody">
              Showing <span className="font-semibold">{insights.length}</span>{' '}
              of <span className="font-semibold">{insights.length}</span>
            </Body2>
          )}
        </FlexRow>

        <InsightTable
          data={insights as any}
          filterActive={filterActive}
          onEdit={onEdit}
          selectedInsights={selectedInsights}
          onCheck={checkInsight}
        />
      </div>
    );
  },
);
