import React from 'react';

export const RedirectIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.3326 0.164062C9.05646 0.164062 8.8326 0.38792 8.8326 0.664062C8.8326 0.940205 9.05646 1.16406 9.3326 1.16406H11.9993C12.0491 1.16406 12.0979 1.16843 12.1453 1.17681L7.67827 5.64384C7.483 5.83911 7.483 6.15569 7.67827 6.35095C7.87353 6.54621 8.19011 6.54621 8.38537 6.35095L12.8279 1.90841C12.831 1.93765 12.8326 1.96734 12.8326 1.9974V4.66406C12.8326 4.9402 13.0565 5.16406 13.3326 5.16406C13.6087 5.16406 13.8326 4.9402 13.8326 4.66406V1.9974C13.8326 1.50326 13.6371 1.05479 13.3193 0.725096L13.317 0.722776C12.9837 0.378254 12.5165 0.164062 11.9993 0.164062H9.3326Z"
        fill="#28284D"
      />
      <path
        d="M6.66602 0.830851L6.64095 0.830851C5.54063 0.830848 4.68078 0.830845 3.993 0.893181C3.29313 0.956613 2.72249 1.08773 2.21602 1.38015C1.59274 1.74 1.07516 2.25757 0.715312 2.88085C0.422898 3.38733 0.291777 3.95796 0.228345 4.65783C0.166009 5.34562 0.166012 6.20547 0.166016 7.30578V7.35592C0.166012 8.45624 0.166009 9.31609 0.228345 10.0039C0.291777 10.7037 0.422898 11.2744 0.715312 11.7809C1.07516 12.4041 1.59274 12.9217 2.21602 13.2816C2.72249 13.574 3.29313 13.7051 3.993 13.7685C4.68078 13.8309 5.54062 13.8309 6.64093 13.8309H6.6911C7.79141 13.8309 8.65125 13.8309 9.33903 13.7685C10.0389 13.7051 10.6095 13.574 11.116 13.2816C11.7393 12.9217 12.2569 12.4041 12.6167 11.7809C12.9091 11.2744 13.0403 10.7037 13.1037 10.0039C13.166 9.31609 13.166 8.45625 13.166 7.35594V7.33085C13.166 7.05471 12.9422 6.83085 12.666 6.83085C12.3899 6.83085 12.166 7.05471 12.166 7.33085C12.166 8.46159 12.1655 9.27622 12.1078 9.91361C12.0506 10.5441 11.9406 10.9519 11.7507 11.2809C11.4786 11.7521 11.0873 12.1434 10.616 12.4155C10.2871 12.6054 9.87928 12.7155 9.24877 12.7726C8.61138 12.8304 7.79676 12.8309 6.66602 12.8309C5.53527 12.8309 4.72065 12.8304 4.08326 12.7726C3.45275 12.7155 3.04492 12.6054 2.71602 12.4155C2.24476 12.1434 1.85342 11.7521 1.58134 11.2809C1.39144 10.9519 1.28141 10.5441 1.22426 9.91361C1.16649 9.27622 1.16602 8.46159 1.16602 7.33085C1.16602 6.20011 1.16649 5.38548 1.22426 4.7481C1.28141 4.11758 1.39144 3.70976 1.58134 3.38085C1.85342 2.90959 2.24476 2.51825 2.71602 2.24617C3.04492 2.05628 3.45275 1.94624 4.08326 1.8891C4.72065 1.83133 5.53527 1.83085 6.66602 1.83085C6.94216 1.83085 7.16602 1.60699 7.16602 1.33085C7.16602 1.05471 6.94216 0.830851 6.66602 0.830851Z"
        fill="#28284D"
      />
    </svg>
  );
};
