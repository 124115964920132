import {CancelIcon} from '@ui/atoms/icons';
import {RadioSm} from '@ui/atoms/radio';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2, Body1} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {Modal} from '@ui/molecules/modal';
import {FlexRowSpaceBetween, FlexRow} from '@ui/style/styles';
import {useState} from 'react';
import {ModalWrapper} from '../view-meetings/styles';
import {Button} from '@ui/atoms/button';

interface RecurringMeetingSettingsProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (editType: string) => void;
}

export const RecurringMeetingSettings = ({
  isOpen,
  onClose,
  onConfirm,
}: RecurringMeetingSettingsProps) => {
  const [editType, setEditType] = useState('');

  const handleConfirm = () => {
    if (editType) {
      onConfirm(editType);
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalWrapper>
        <ModalCard title="" modalStyle={{padding: '24px'}}>
          <div>
            <FlexRowSpaceBetween>
              <Headline2>Edit recurring event</Headline2>
              <button onClick={onClose}>
                <CancelIcon
                  style={{
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px',
                    strokeWidth: 1.5,
                  }}
                />
              </button>
            </FlexRowSpaceBetween>

            <VerticalSpacer size="24px" />
            <FlexRow className="gap-3">
              <RadioSm
                checked={editType === 'single'}
                onChange={() => setEditType('single')}
              />
              <Body1>This event</Body1>
            </FlexRow>
            <VerticalSpacer size="24px" />

            <FlexRow className="gap-3">
              <RadioSm
                checked={editType === 'multiple-above'}
                onChange={() => setEditType('multiple-above')}
              />
              <Body1>This and following events</Body1>
            </FlexRow>
            <VerticalSpacer size="24px" />

            <FlexRow className="gap-3">
              <RadioSm
                checked={editType === 'all-events'}
                onChange={() => setEditType('all-events')}
              />
              <Body1>All events</Body1>
            </FlexRow>

            <VerticalSpacer size="24px" />
            <Button
              style={{padding: '12px 24px'}}
              onClick={handleConfirm}
              width="full"
              disabled={!editType}>
              Confirm
            </Button>
          </div>
        </ModalCard>
      </ModalWrapper>
    </Modal>
  );
};
