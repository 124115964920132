import {Body1, Body2} from '@ui/atoms/typography';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {userName} from '@utils/user-name';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {Button} from '@ui/atoms/button';
import {CalenderIcon} from '@ui/atoms/icons/calender-icon';
import {MoreVerticalIcon} from '@ui/atoms/icons';
import {motion} from 'framer-motion';
import {memo, useMemo, useState} from 'react';
import {useAnimation} from 'framer-motion';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer/spacer';
import {FlexRow, FlexRowEnd, FlexRowSpaceBetween} from '@ui/style/styles';
import {IMeetingResponse} from '@hooks';
import isToday from 'dayjs/plugin/isToday';
import Timezone from 'dayjs/plugin/timezone';
import ICalendarLink from 'react-icalendar-link';
import {authStore} from '@store/stores/auth-store';
import {timezones} from '@constants';
import {Avatars} from '@ui/molecules/avatars';
import {filterDuplicateItems} from '@utils/array';
import {getTime} from '@utils/date';
import {capitalize} from '@utils';
import {RecurringMeeting} from '@ui/atoms/icons/recurring';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';

dayjs.extend(isToday);
dayjs.extend(Timezone);

const MeetingTitle = styled(Body1)<{notAttending?: boolean}>`
  text-decoration: ${(props) => (props.notAttending ? 'line-through' : 'none')};
`;

export const ViewDetailsWrapper = styled(motion.div)<{
  open: boolean;
  variant?: string;
}>`
  background: #f6f6fe;
  border-radius: ${({open}) => (open ? '10px' : '20px')};
  padding: ${({open}) => (open ? '2px 8px 2px 2px' : '4px')};
  height: 28px;
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;

  justify-content: center;
  svg {
    path {
      stroke: #585adf;
    }
  }
  @media (max-width: 1285px) {
    margin-top: 14px;
  }
  @media (max-width: 768px) {
    margin-top: 9px;
  }
`;

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    div:first-child {
      margin-bottom: 12px;
    }
  }
`;
export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`;

export const DesktopWrapper = styled.div`
  display: none;
  @media (min-width: 500px) {
    display: block;
  }
`;

const Wrapper = styled.div`
  padding: 16px;
  border: 1px solid #ededf2;
  border-radius: 10px;
  margin-bottom: 16px;
  @media (max-width: 500px) {
    padding: 16px 8px;
  }
`;

interface IMeetingProps extends IMeetingResponse {
  onReschedule?: () => void;
  excludeAddToCalendar?: boolean;
  onCancel?: () => void;
  onDuplicate?: () => void;
  viewMeeting: () => void;
  startMeeting?: () => void;
  onDelete?: () => void;
  viewMeetingLabel?: (label: string) => void;
}

export const MeetingCard = memo(
  ({
    startDate,
    time,
    viewMeeting,
    title,
    endDate,
    excludeAddToCalendar,
    startMeeting,
    label,
    frequency,
    participant,
    recurrence,
    notAttending = false,
    extraParticipant = [],
    status,
    onDelete,
    onReschedule,
    user,
  }: IMeetingProps & {notAttending?: boolean}) => {
    const divAnimationVariants = {
      init: {
        width: '28px',
      },
      anim: {
        width: '140px',
        duration: 5,
      },
    };

    const [isTooltipFilter, setIsTooltipFilter] = useState(false);

    const _time = time?.toLowerCase().includes('invalid') ? '' : time;

    const divAnimationControls = useAnimation();

    const {targetHour, targetMinute} = getTime(_time);

    const getTimeZone = timezones.find(
      (timezone) => timezone.value === authStore.auth.user.timezone,
    );

    const updatedDate = _time
      ? dayjs(startDate)
          .set('hour', targetHour)
          .set('minute', targetMinute)
          .tz(getTimeZone?.label || '', true)
      : dayjs(startDate);

    const updatedEndDate = endDate
      ? dayjs(endDate).tz(getTimeZone?.label || '', true)
      : '';

    const calcTime = useMemo(() => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const targetHour = updatedDate.get('hour');
      const targetMinute = updatedDate.get('minutes');

      if (
        currentHour < targetHour ||
        (currentHour === targetHour && currentMinute < targetMinute)
      ) {
        return false;
      } else {
        return true;
      }
    }, [updatedDate]);

    const allParticipants = useMemo(() => {
      const options = [
        {
          id: user.id,
          src: user?.avatar?.url,
          name: userName(user),
          isUserDeleted: !!user?.deletedAt,
        },
      ];

      extraParticipant
        .filter(
          (_participant) =>
            !participant
              ?.map((user: {email: string}) => user.email)
              .includes(_participant.email),
        )
        .forEach((participant) => {
          options.push({
            name: participant.email,
            src: '',
            id: participant.email,
            isUserDeleted: false,
          });
        });

      participant.forEach(
        (_participant: {id: string; avatar: {url: any}; deletedAt: any}) => {
          options.push({
            id: _participant.id,
            src: _participant?.avatar?.url,
            name: userName(_participant),
            isUserDeleted: !!_participant?.deletedAt,
          });
        },
      );

      return filterDuplicateItems(options, 'id');
    }, [extraParticipant, participant, user]);

    const formatName = participant.some(
      (_participant: {id: string}) =>
        _participant.id === authStore.auth.user.id,
    )
      ? userName(user)
      : userName(participant[0]);

    const event = {
      title: title || `1:1 with ${formatName}`,
      description: '',
      startTime: updatedDate.format(),
      location: '',
      attendees: [
        `${userName(user)} <${user.email}>`,

        ...participant.map(
          (_participant: {email: any}) =>
            `${userName(_participant)} <${_participant?.email}>`,
        ),
      ],
    };
    const isDisabled = dayjs().isAfter(dayjs(updatedDate), 'day') && !calcTime;

    const Dropdown =
      authStore.auth.user.id !== user.id ? (
        <MoreVerticalIcon style={{stroke: '#BFBFD4'}} />
      ) : (
        <DropdownVertical
          customIcon={<MoreVerticalIcon />}
          collapseOnClick
          dropdownWrapperStyle={{
            right: '-83%',
            minWidth: 214,
            maxWidth: 214,
            overflow: 'initial',
          }}
          menu={(handleClose: () => void) => (
            <div>
              {authStore.auth.user.id === user.id && (
                <>
                  {onReschedule && !isDisabled && (
                    <DropdownItem
                      onClick={() => {
                        handleClose();
                        onReschedule();
                      }}>
                      Edit Event
                    </DropdownItem>
                  )}
                </>
              )}

              <DropdownItem
                onClick={() => {
                  handleClose();
                  onDelete && onDelete();
                }}>
                Delete Event
              </DropdownItem>
            </div>
          )}
        />
      );

    const canMeetingStart =
      dayjs(updatedDate).isToday() &&
      status === 'upcoming' &&
      calcTime &&
      authStore.auth.user.id === user.id;

    const showTime = !!_time && !title?.includes('Notes with');

    const showEndDate =
      endDate && dayjs(endDate).diff(dayjs(startDate), 'day') > 0;

    const isRecurring = frequency !== 'once' || Boolean(recurrence);

    const isCanceled = status === 'cancelled';

    return (
      <Wrapper data-testid="meeting-card">
        <FlexRowSpaceBetween>
          {isCanceled ? (
            <Body2 weight="semibold" kind="red400">
              Cancelled{' '}
            </Body2>
          ) : (
            <FlexRow>
              {label?.length > 0 && (
                <div className="flex items-center gap-2 ">
                  {label?.slice(0, 3).map((_label) => (
                    <div
                      key={_label}
                      className="bg-[#F6F6F8] py-1 px-2 rounded-lg flex gap-1 items-center cursor-pointer">
                      <Body2
                        weight="bold"
                        className="text-[12px]"
                        size="12px"
                        kind="textBody">
                        {_label}
                      </Body2>
                    </div>
                  ))}
                  <HorizontalSideRule size="16px" />
                </div>
              )}

              <div className="flex flex-row items-center gap-3 sm:flex-col ">
                <Body2 weight="semibold" kind="textBody">
                  {dayjs(updatedDate).format('ddd. D MMM.')}{' '}
                  {showEndDate && `- ${dayjs(endDate).format('ddd. D MMM.')} `}
                </Body2>

                {showTime && (
                  <FlexRow className="gap-2">
                    <CustomColorIcon
                      height="8px"
                      width="8px"
                      margin={3}
                      color="#D9D9D9"
                    />
                    <Body2 weight="semibold" kind="textBody">
                      {dayjs(updatedDate).format('hh:mm a')}{' '}
                      {updatedEndDate
                        ? `- ${dayjs(updatedEndDate).format('hh:mm a')}`
                        : null}
                    </Body2>
                  </FlexRow>
                )}

                {isRecurring && (
                  <HoverTooltip
                    className="bg-white border border-borderDark p-4 -top-14"
                    tooltipTextKind="textBody"
                    tooltipText={recurrence?.formatted || ''}>
                    <RecurringMeeting />
                  </HoverTooltip>
                )}
              </div>
            </FlexRow>
          )}

          <MobileWrapper>
            {authStore.auth.user.id === user.id && Dropdown}
          </MobileWrapper>
        </FlexRowSpaceBetween>
        <VerticalSpacer size="10px" />
        <StyledWrapper>
          <FlexRowSpaceBetween>
            <div className="max-w-[60%]">
              <MeetingTitle weight="bold" notAttending={notAttending}>
                {capitalize(title || `1:1 with ${formatName}`)}
              </MeetingTitle>
            </div>

            <div
              onMouseEnter={() =>
                setIsTooltipFilter(isTooltipFilter ? false : true)
              }
              onMouseLeave={() => setIsTooltipFilter(false)}
              className="relative">
              <Avatars
                showCount={4}
                tooltip={false}
                items={allParticipants}
                total={allParticipants?.length}
              />

              {isTooltipFilter && (
                <div className="absolute z-10 gap-2 flex flex-col bg-[#3A4050] rounded-[4px] min-w-[160px] py-4 px-6 border border-[#CECEDE]">
                  {allParticipants.map((participant) => (
                    <div key={participant.id} className="">
                      <Body2 className="text-[12px]" kind="white">
                        {participant.name}
                      </Body2>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </FlexRowSpaceBetween>
          <FlexRowEnd>
            {!excludeAddToCalendar && (
              <>
                <ICalendarLink event={event}>
                  <ViewDetailsWrapper
                    // onMouseOver={() => setViewDetails(true)}
                    // onMouseLeave={() => setViewDetails(false)}
                    className={'fadeIn w-[130px]'}
                    // animate={divAnimationControls}
                    onHoverStart={() =>
                      divAnimationControls.start(divAnimationVariants.anim)
                    }
                    onHoverEnd={() =>
                      divAnimationControls.start(divAnimationVariants.init)
                    }
                    open={true}>
                    <CalenderIcon />

                    {/* {viewDetails && ( */}
                    <span>
                      <Body2
                        kind="purple300"
                        weight={'bold'}
                        className="fadeIn"
                        style={{
                          marginLeft: '4px',
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                        }}>
                        Add to calendar
                      </Body2>
                    </span>
                    {/* )} */}
                  </ViewDetailsWrapper>
                </ICalendarLink>

                <HorizontalSpacer size="10px" />

                <HorizontalSideRule size="16px" />
              </>
            )}

            <HorizontalSpacer size="10px" />
            {canMeetingStart ? (
              <Button
                kind="primary"
                style={{padding: '8px 16px'}}
                onClick={startMeeting}>
                Start Meeting
              </Button>
            ) : (
              <Button
                kind="secondary"
                style={{padding: '8px 16px'}}
                onClick={viewMeeting}>
                View
              </Button>
            )}
            <HorizontalSpacer size="24px" />

            <DesktopWrapper>{Dropdown}</DesktopWrapper>
          </FlexRowEnd>
        </StyledWrapper>
      </Wrapper>
    );
  },
);
