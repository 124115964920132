import {useQuery, QueryKey, UseQueryOptions} from 'react-query';
import {ref, query} from 'firebase/database';
import {FIREBASE_BASE_URL, database} from '@utils/firebase-request';
import {useCallback, useMemo} from 'react';
import {authStore} from '@store/stores/auth-store';
import {useObject} from 'react-firebase-hooks/database';

// Generic fetch hook
export const useFetchHook = <TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: UseQueryOptions<TData, TError>,
) => {
  return useQuery<TData, TError>(queryKey, queryFn, options);
};

// One-time fetch hook
export const useOneTimeFetch = <TData = unknown, TError = unknown>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: UseQueryOptions<TData, TError>,
) => {
  return useFetchHook<TData, TError>(queryKey, queryFn, {
    ...options,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useFirebaseFetch = (path: string, filter?: any) => {
  // Create database reference
  const databaseRef = useMemo(
    () =>
      ref(
        database,
        `${FIREBASE_BASE_URL}${authStore.auth.user.workspace.id}/${path}`,
      ),
    [path],
  );

  const filteredQuery = useMemo(
    () => (filter?.equalTo ? query(databaseRef, filter) : databaseRef),
    [databaseRef, filter],
  );

  const [snapshot, loading, error] = useObject(filteredQuery);

  const data = useMemo(() => {
    if (!snapshot) return null;

    return snapshot.val();
  }, [snapshot]);

  const refetch = useCallback(() => {}, []);

  return {
    data,
    isLoading: loading,
    error,
    refetch,
  };
};
