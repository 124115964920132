import {CurrencySelect} from '@ui/molecules/select/currency-input';
import {MeasurementType} from '@hooks';
import {MeasurementTypeField} from '@ui/molecules/field/measurement-field';
import {CurrencySymbol} from '@constants';
import {useMemo} from 'react';
import {NumberInput} from '@ui/atoms/range/number';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {PercentIcon} from '@ui/atoms/icons';

interface IKpiMeasurement {
  measurement: {
    unit: string;
    symbol?: string;
  };
  onChange?: (e: any) => void;
}
interface IKpiTarget {
  targetValue?: number;
  measurement?: string;
  onChangeTarget?: (value: string) => void;
}
interface ISymbol extends IKpiMeasurement {
  handleCurrencyChange: (e: string) => void;

  errors: any;
}

export const KpiMeasurement = ({measurement, onChange}: IKpiMeasurement) => {
  return (
    <div>
      <MeasurementTypeField
        label=""
        value={measurement?.unit ? measurement.unit : ''}
        name={`kpi-measurement`}
        margin
        isKpi
        remove={['binary']}
        setValue={(innerValue: string) => {
          onChange && onChange(innerValue);
        }}
      />
    </div>
  );
};
export const KpiTarget = ({
  targetValue,
  onChangeTarget,
  measurement,
}: IKpiTarget) => {
  if (!measurement) return null;

  return (
    <>
      <VerticalSpacer size="16px" />
      <NumberInput
        label={''}
        icon={
          measurement?.toLowerCase() === 'percentage' ? (
            <PercentIcon />
          ) : undefined
        }
        value={targetValue}
        onChange={(event) =>
          onChangeTarget && onChangeTarget(event.target.value)
        }
        name="target"
        id="target"
        placeholder={'Target value'}
      />
    </>
  );
};

export const KpiMeasurementSymbol = ({
  measurement,
  handleCurrencyChange,
  errors,
}: ISymbol) => {
  const currencyResponse =
    measurement && measurement?.symbol === 'NGN'
      ? '₦ Naira'
      : measurement?.symbol === 'USD'
      ? '$ Dollars'
      : measurement?.symbol === 'EUR'
      ? '€ Euros'
      : measurement?.symbol === 'GBP'
      ? '£ Pounds'
      : '';

  const renderMeasurementValue = useMemo(() => {
    switch (measurement?.unit) {
      case MeasurementType.CURRENCY:
        return (
          <>
            <CurrencySelect
              options={CurrencySymbol}
              placeholder={currencyResponse}
              onChange={({value: userValue}: {value: string}) =>
                handleCurrencyChange(userValue)
              }
              value={
                measurement?.symbol ||
                CurrencySymbol.find((i) => i.value === measurement?.symbol)
              }
              // @ts-ignore
              state={errors?.measurement ? 'error' : 'default'}
              helper={
                // @ts-ignore
                errors?.measurement?.symbol?.message || ''
              }
            />
          </>
        );

      default:
        return <></>;
    }
  }, [
    currencyResponse,
    handleCurrencyChange,
    measurement?.unit,
    measurement?.symbol,
    errors,
  ]);
  return <>{renderMeasurementValue}</>;
};

export const KpiCalculation = () => {
  return <></>;
};
