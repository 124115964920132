import React from 'react';

export const RecurringMeeting = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7711 5.60818C12.5729 3.1208 10.4915 1.16406 7.95317 1.16406C6.42703 1.16406 5.06631 1.8708 4.17971 2.9768C4.0272 3.16704 3.88882 3.36901 3.76614 3.58109C3.62787 3.82012 3.322 3.9018 3.08297 3.76353C2.84394 3.62526 2.76226 3.3194 2.90053 3.08037C3.04863 2.82436 3.21558 2.58072 3.39946 2.35133C4.46769 1.01876 6.11079 0.164062 7.95317 0.164062C11.053 0.164062 13.5881 2.58199 13.7754 5.63471L14.2663 5.14384C14.4615 4.94858 14.7781 4.94858 14.9734 5.14384C15.1686 5.3391 15.1686 5.65569 14.9734 5.85095L13.6401 7.18428C13.4448 7.37954 13.1282 7.37954 12.9329 7.18428L11.5996 5.85095C11.4043 5.65569 11.4043 5.3391 11.5996 5.14384C11.7949 4.94858 12.1115 4.94858 12.3067 5.14384L12.7711 5.60818Z"
        fill="#5F5F8C"
      />
      <path
        d="M0.979821 6.14384C0.784558 6.3391 0.784558 6.65569 0.979821 6.85095C1.17508 7.04621 1.49167 7.04621 1.68693 6.85095L2.1778 6.36008C2.36506 9.4128 4.90018 11.8307 8.00004 11.8307C9.84242 11.8307 11.4855 10.976 12.5537 9.64347C12.7376 9.41408 12.9046 9.17043 13.0527 8.91443C13.1909 8.6754 13.1093 8.36953 12.8702 8.23126C12.6312 8.09299 12.3253 8.17467 12.1871 8.4137C12.0644 8.62578 11.926 8.82775 11.7735 9.01799C10.8869 10.124 9.52617 10.8307 8.00004 10.8307C5.46169 10.8307 3.38035 8.87399 3.18215 6.38661L3.64649 6.85095C3.84175 7.04621 4.15833 7.04621 4.35359 6.85095C4.54886 6.65569 4.54886 6.33911 4.35359 6.14384L3.02026 4.81051C2.825 4.61525 2.50842 4.61525 2.31315 4.81051L0.979821 6.14384Z"
        fill="#5F5F8C"
      />
    </svg>
  );
};
