import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {useNavigate} from 'react-router';
import {SelectField} from '@ui/molecules/select/selectfield';
import {ThickPlusIcon} from '@ui/atoms/icons/thick-plus';
import {InputNoBorder} from '@ui/atoms/input/input';
import {useState, useMemo, useEffect} from 'react';
import {
  Wrapper,
  Summary,
  ConfigureWrapper,
  TotalSum,
  ManagerAssessmentArea,
  Values,
} from './styles';
import {AdditionalOptionsWrapper} from '../create-review-categories/styles';
import {Helper} from '@ui/atoms/helper';
import {useFetchHook} from '@hooks/query-hook';
import {CcController} from '@api/cc-api/cc-controller';
import {useSearchParams} from 'react-router-dom';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {observer} from 'mobx-react-lite';
import {TextField} from '@ui/molecules/field/textfield';
import {LockIcon} from '@ui/atoms/icons/lock';
import {OptionSelectField} from '@ui/molecules/select/option';
import {useLocation} from 'react-router';
import {CompetenceCategories} from './competence-categories';
import {FlexRowSpaceBetween, FlexRow, FlexRowEnd} from '@ui/style/styles';
import {Button} from '@ui/atoms/button';
import {useStoreContext} from '@store/store-context';
import {useParams} from 'react-router';
import nextId from 'react-id-generator';
import {Body2} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {SecondaryMinusSVG} from '@ui/atoms/svg/minus';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';

type Parameter = 'goals' | 'default';
export interface CategValue {
  competence: string;
  id: string;
  keyAreas: {
    value: string;
    weighing: string;
    parameter: Parameter;
    id: string;
  }[];
}

const CreateReviewFrameworks = () => {
  const navigate = useNavigate();
  const emptyValue = {
    competence: '',
    id: nextId('competence'),
    keyAreas: [
      {
        value: '',
        parameter: 'default' as Parameter,
        weighing: '',
        id: nextId('competence-key-areas'),
      },
    ],
  };
  const {id} = useParams();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const {
    groupStore: {groups},
  } = useStoreContext();

  const [loading, setLoading] = useState(false);

  const request = new CcRequestImpl();
  const controller = new CcController(request);

  const [searchParams] = useSearchParams();
  const duplicateFramework = searchParams.get('duplicate');

  const {data: existingFrameworks} = useFetchHook(
    ['cc-review-frameworks'],
    () => controller.fetchCcFramework(),
  );

  const state: any = location.state;

  const getExistingFrameworks: any = existingFrameworks;

  const existingGroups = getExistingFrameworks
    ?.flatMap((frameworks: any) => frameworks.groups)
    ?.filter((group: string) => (id ? group !== state?.groups[0] : group));

  const {data} = useFetchHook('cc-review-setup', () =>
    controller.fetchCcReviewSetup(),
  );

  const requestData: any = data;

  const [valuesWeighing, setValuesWeighing] = useState('');

  const [competenceCategories, setCompetenceCategories] = useState(
    id || duplicateFramework ? [] : [emptyValue],
  );

  const categories = useMemo(() => {
    const computeSelectedCompetence = competenceCategories
      .map((categ) => categ.competence)
      .filter((categ) => categ);

    return requestData?.competenceCategories
      ?.filter((category: {name: string}) =>
        computeSelectedCompetence.length
          ? !computeSelectedCompetence.includes(category.name)
          : true,
      )
      .map((category: {name: string}) => ({
        label: category.name,
        value: category.name,
      }));
  }, [competenceCategories, requestData?.competenceCategories]);

  const getFramework = getExistingFrameworks?.find(
    (framework: any) => framework.id === id,
  );

  const [allGroupsSelected, setAllGroupsSelected] = useState(
    id && !!getFramework?.allGroups,
  );

  const [selectedGroup, setSelectedGroup] = useState('');

  const groupOptions = useMemo(() => {
    const options = [
      ...groups
        .filter((group) => !existingGroups?.includes(group.id))
        .map((group) => {
          return {
            value: group.id,
            label: group.name,
          };
        }),
    ];
    const allGroupsFrameworkExists = getExistingFrameworks?.find(
      (framework: {allGroups: boolean}) => framework.allGroups,
    );

    if (!allGroupsFrameworkExists) {
      options.unshift({
        label: 'All groups (General)',
        value: 'all',
      });
    }
    return options;
  }, [groups, existingGroups, getExistingFrameworks]);

  useEffect(() => {
    if (id || duplicateFramework) {
      const state: any = location.state;

      const {
        allGroups,
        groups,
        weighing,
        managerQuestionRequired,
        competenceMetrics,
        managerQuestion,
      } = state;

      setAllGroupsSelected(allGroups);

      id && setSelectedGroup(groups[0]);
      setValuesWeighing(weighing.value);

      if (managerQuestion) {
        setManagerSelectedValue(managerQuestion);
        setShowTextField(true);
        setTextFieldValue(managerQuestion);
        if (managerQuestionRequired)
          setManagerQuestionRequired(managerQuestionRequired);
      }

      setCompetenceCategories(
        competenceMetrics.map((metrics: any) => {
          return {
            competence: metrics.name,
            id: nextId('competence-key-areas'),
            keyAreas: metrics.components.map((keyArea: any) => {
              return {
                value: keyArea.name,
                weighing: keyArea.score,
                parameter: keyArea?.parameter,
                id: nextId('competence-key-areas'),
              };
            }),
          };
        }),
      );
    }
  }, [id, location.state, duplicateFramework]);

  const addCompetence = () => {
    setCompetenceCategories((prev) => [...prev, emptyValue]);
  };

  const disableAddCompetence = competenceCategories.some(
    (competence) =>
      !competence.competence ||
      competence.keyAreas.some(
        (keyArea) => !keyArea.value || !keyArea.weighing,
      ),
  );

  const getWeighings = () => {
    let sum = 0;

    competenceCategories.forEach((category) => {
      category.keyAreas.forEach((keyArea) => {
        const formattedWeight = parseInt(keyArea.weighing);
        if (!isNaN(formattedWeight)) {
          sum += formattedWeight;
        }
      });
    });
    return sum;
  };
  const getSpecificWeighing = (keyAreas: {weighing: string}[]) => {
    let sum = 0;

    keyAreas.forEach((keyArea) => {
      const formattedWeight = parseInt(keyArea.weighing);
      if (!isNaN(formattedWeight)) {
        sum += formattedWeight;
      }
    });
    return sum;
  };

  const saveCompetence = (value: CategValue) => {
    setCompetenceCategories((prev) =>
      prev.map((category) => (category.id === value.id ? value : category)),
    );
  };

  const formattedValueWeigh = parseInt(valuesWeighing);

  const totalSum = !isNaN(formattedValueWeigh)
    ? formattedValueWeigh + getWeighings()
    : getWeighings();

  const [managerQuestionRequired, setManagerQuestionRequired] = useState(false);

  const disableButton =
    (!allGroupsSelected && !selectedGroup) ||
    disableAddCompetence ||
    isNaN(formattedValueWeigh) ||
    totalSum !== 100;

  const deleteCompetence = (id: string) => {
    setCompetenceCategories((prev) =>
      prev.filter((category) => category.id !== id),
    );
  };
  const [textFieldValue, setTextFieldValue] = useState('');

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      allGroups: allGroupsSelected,
      groups: allGroupsSelected ? undefined : [selectedGroup],
      competenceMetrics: competenceCategories.map((competence) => {
        return {
          name: competence.competence,
          totalValue: getSpecificWeighing(competence.keyAreas),
          components: competence.keyAreas.map((keyArea) => {
            return {
              name: keyArea.value,
              parameter: keyArea.parameter,
              score: parseInt(keyArea.weighing),
            };
          }),
        };
      }),
      weighing: {
        value: parseInt(valuesWeighing),
        competence: getWeighings(),
      },
      managerQuestion: textFieldValue,
      managerQuestionRequired,
    };

    //@ts-ignore
    if (!textFieldValue) delete data.managerQuestion;

    const response = id
      ? await controller.updateCcFrameworkById(id, data)
      : await controller.updateCcFramework(data);

    setLoading(false);

    response &&
      activateNotification({
        title: `Success`,
        content: `Framework added`,
        kind: 'success',
      });
    response && navigate(-1);
  };

  const [managerSelectedValue, setManagerSelectedValue] = useState('');

  const [showTextField, setShowTextField] = useState(false);

  return (
    <PageLayoutTemplate
      title={`${
        id ? 'Edit' : duplicateFramework ? 'Duplicate' : 'Create'
      } framework`}
      onClick={() => navigate(-1)}>
      <Wrapper>
        <div className="form">
          <FlexRow>
            <Body2 weight="bold">What group is this framework for? </Body2>
          </FlexRow>
          <VerticalSpacer size="8px" />
          <SelectField
            borderRadius="10px"
            placeholder="Select Group"
            defaultValue={allGroupsSelected ? 'all' : selectedGroup}
            onChange={(data: {value: string; label: string}) => {
              if (data?.value === 'all') {
                setAllGroupsSelected(true);
                return;
              }

              setSelectedGroup(data?.value || '');
              setAllGroupsSelected(false);
            }}
            options={groupOptions}
          />
          <VerticalSpacer size="12px" />
          <Body2 weight="bold">Configure framework</Body2>
          <VerticalSpacer size="8px" />
          <ConfigureWrapper>
            <div className="total-sum">
              <Body2 weight="bold">Total sum (competencies)</Body2>
              <div className="number-count">
                <Body2
                  weight="bold"
                  kind={!getWeighings() ? 'textMuted' : 'textDark'}>
                  {getWeighings()}
                </Body2>
              </div>
            </div>
            <div className="body-card">
              <Body2 weight="bold">Competence category</Body2>
              <VerticalSpacer size="16px" />

              {competenceCategories.map((category, idx) => (
                <CompetenceCategories
                  key={idx}
                  category={category}
                  categories={
                    category.competence
                      ? [
                          ...categories,
                          {
                            label: category.competence,
                            value: category.competence,
                          },
                        ]
                      : categories
                  }
                  isEditingFramework={!!id || !!duplicateFramework}
                  canDeleteCategory={idx !== 0}
                  deleteCompetence={deleteCompetence}
                  saveCompetence={saveCompetence}
                />
              ))}

              <AdditionalOptionsWrapper
                display
                onClick={addCompetence}
                disabled={disableAddCompetence}>
                <ThickPlusIcon
                  color={disableAddCompetence ? '#BFBFD4' : '#47B881'}
                />
                <Body2
                  weight="bold"
                  kind={disableAddCompetence ? 'textMuted' : 'textBody'}
                  style={{marginLeft: '4px'}}>
                  Add category
                </Body2>
              </AdditionalOptionsWrapper>
            </div>
          </ConfigureWrapper>
          <VerticalSpacer size="24px" />
          <Body2 weight="bold">Summary</Body2>
          <VerticalSpacer size="8px" />
          <Summary>
            <div className="info">
              <Body2 kind="textBody">
                The sum of your{' '}
                <span style={{fontWeight: 500}}>values and competencies</span>{' '}
                should equal to 100
              </Body2>
            </div>
            <VerticalSpacer size="10px" />
            <FlexRowSpaceBetween>
              <Values>
                <div className="value">
                  <Body2 kind="textMedium">Values</Body2>
                </div>
                <div className="input">
                  <InputNoBorder
                    placeholder="Weighing"
                    min={0}
                    type={'number'}
                    style={{lineHeight: 0}}
                    onChange={(event) => setValuesWeighing(event.target.value)}
                    value={valuesWeighing}
                  />
                </div>
              </Values>
              <Values style={{justifyContent: 'flex-end'}}>
                <div className="value competence">
                  <Body2 kind="textMedium">Competence</Body2>
                </div>
                <div className="competence-value">
                  <Body2 kind="textMedium">{getWeighings()}</Body2>
                </div>
              </Values>
            </FlexRowSpaceBetween>
            <VerticalSpacer size="20px" />

            <TotalSum
              borderColor={
                totalSum > 100
                  ? '#D52A2A'
                  : totalSum === 100
                  ? '#47B881'
                  : '#ededf2'
              }>
              <Body2 weight="bold">Total sum of values + competencies</Body2>
              <div className="total">
                <Body2 weight="bold" kind="textBody">
                  {totalSum} {totalSum > 100 && '/100'}
                </Body2>
              </div>
            </TotalSum>
            {totalSum > 100 && (
              <Helper
                state={'error'}
                children="The total sum must be equal to 100"
              />
            )}
          </Summary>
          <VerticalSpacer size="24px" />
          <ManagerAssessmentArea className="bg-white">
            <FlexRowSpaceBetween>
              <FlexRow>
                <Body2 weight="bold">
                  Manager overall assessment
                  <span style={{fontWeight: 400, color: '#5F5F8C'}}>
                    {'  '}(optional)
                  </span>
                </Body2>
              </FlexRow>

              <LockIcon />
            </FlexRowSpaceBetween>

            <VerticalSpacer size="10px" />

            <OptionSelectField
              options={[{label: `Custom question`, value: 'custom'}]}
              placeholder={'Select question template'}
              name="questionType"
              inputStyle={{borderRadius: '10px'}}
              value={managerSelectedValue}
              customOptionName="Create your custom question"
              maxHeight={400}
              onChange={(data: {value: string; disabled?: boolean}) => {
                setManagerSelectedValue(data.value);
                setShowTextField(true);
              }}
              fieldNotFoundPlaceHolder={() => `Oops! no question found`}
              optionsSelectType="period"
            />

            {showTextField && (
              <>
                <TextField
                  placeholder="Enter here"
                  value={textFieldValue}
                  onChange={(event) => setTextFieldValue(event.target.value)}
                  multiple
                  inputStyle={{borderRadius: '10px', height: '76px'}}
                  margin
                />
                <VerticalSpacer size="14px" />
                <FlexRowSpaceBetween>
                  <FlexRow className="gap-2">
                    <ToggleSwitch
                      onChange={() =>
                        setManagerQuestionRequired((prev) => !prev)
                      }
                      checked={managerQuestionRequired}
                    />
                    <Body2 weight="bold">Required</Body2>
                  </FlexRow>
                  <PlainButton
                    style={{width: '100%'}}
                    onClick={() => {
                      setManagerSelectedValue('');
                      setTextFieldValue('');
                      setShowTextField(false);
                    }}>
                    <FlexRowEnd style={{width: '100%'}}>
                      <SecondaryMinusSVG />
                      <Body2
                        weight="bold"
                        style={{marginLeft: '5px'}}
                        kind={'red400'}>
                        Remove
                      </Body2>
                    </FlexRowEnd>
                  </PlainButton>
                </FlexRowSpaceBetween>
              </>
            )}
          </ManagerAssessmentArea>

          <VerticalSpacer size="24px" />
          <Button
            style={{padding: '16px 32px'}}
            width="full"
            onClick={handleSubmit}
            isLoading={loading}
            disabled={disableButton}>
            {id ? 'Save' : 'Create'} framework
          </Button>
        </div>
      </Wrapper>
    </PageLayoutTemplate>
  );
};

export default observer(CreateReviewFrameworks);
