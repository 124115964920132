import {
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactSelect, {ActionMeta} from 'react-select';
import {useDropDown} from '../../../../hooks';
import {motion} from 'framer-motion';
import {Body2} from '@ui/atoms/typography';
import {Tooltip} from '@ui/molecules/tooltip';
import {FlexRow} from '@ui/style/styles';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {
  Wrapper,
  DropdownWrapper,
  DropWrapper,
  DropdownHandler,
  HandlerWrapper,
  ArrowIconWrapper,
  OptionsWrapper,
} from './styled';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {GoalsMultiSelect} from '../goals-multi-select/goal-multi-select';
import {SelectFieldProps} from '../user-multi-select/user-multi-select';
import {InputState} from '../../../interface';
import {Groups} from '../../groups';

export interface TOptionsSelectFieldProps extends SelectFieldProps {
  label?: string;
  placeholder?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

export const GoalsMultiOptions = forwardRef(
  (
    {
      label,
      state,
      helper,
      placeholder,
      margin,
      options,
      onChange,
      noOptionsMessage,
      style,
      searchPlaceholder,
      useCustomTitle,
      defaultValue,
      height,
      disabled,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<any>>,
  ) => {
    const {handleOpen, ref: dropRef, open} = useDropDown();

    const getValue = options.filter((option: any) => {
      if (defaultValue) {
        return (
          defaultValue?.length > 0 &&
          defaultValue?.map((val: any) => val.value).includes(option.value)
        );
      }
      return null;
    });

    const [selectedOptions, setSelectedOptions] = useState<any>(() =>
      defaultValue ? getValue : [],
    );

    useEffect(() => {
      if (defaultValue) {
        setSelectedOptions(getValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const handleChange = useCallback(
      (options: any, action: ActionMeta<any>) => {
        const formatOptions = options.map((option: any) => {
          return {
            label: option.label?.goalName || option.label,
            value: option.value,
          };
        });
        setSelectedOptions(options);
        if (onChange) {
          onChange(formatOptions, action);
        }
      },
      [onChange],
    );
    const handleCancelFx = useCallback(
      (value: string) => {
        setSelectedOptions(
          selectedOptions.filter((option: any) => option.value !== value),
        );
      },
      [selectedOptions],
    );

    return (
      <FieldWrapper helper={helper} margin={margin}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              height={height}
              style={style}
              helper={helper}
              disabled={disabled}>
              <HandlerWrapper>
                <OptionsWrapper disabled={disabled}>
                  {selectedOptions?.length > 0 ? (
                    useCustomTitle ? (
                      <FlexRow>
                        <Tooltip
                          BodyTextNodeType={Body2}
                          maxLength={30}
                          text={
                            selectedOptions[0].label?.goalName ||
                            selectedOptions[0].label
                          }
                          withEllipsis
                          tooltipBody={
                            <span>
                              {selectedOptions[0].label?.goalName ||
                                selectedOptions[0].label}
                            </span>
                          }
                        />
                        {selectedOptions.length > 1 && (
                          <Body2
                            kind="purple300"
                            style={{marginLeft: '5px'}}
                            weight="semibold">
                            + {selectedOptions.length - 1} more
                          </Body2>
                        )}
                      </FlexRow>
                    ) : (
                      <Groups
                        total={selectedOptions.length}
                        textStyle={{
                          color: '#242424',
                          size: '14px',
                          weight: 400,
                        }}
                        items={selectedOptions.map(
                          (item: {
                            label: {goalName: string};
                            value: string;
                          }) => ({
                            name: item.label?.goalName || item?.label,
                            value: item.value,
                          }),
                        )}
                        showCancelIcon={true}
                        disabled={disabled}
                        cancelFx={!disabled ? handleCancelFx : undefined}
                      />
                    )
                  ) : (
                    <p className="greyOut">{placeholder}</p>
                  )}
                </OptionsWrapper>
                <ArrowIconWrapper disabled={disabled}>
                  {!disabled ? (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: open ? 180 : 0,
                        marginTop: open ? '-6px' : '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  ) : (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: 0,
                        marginTop: '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  )}
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            {open && (
              <DropdownWrapper>
                <GoalsMultiSelect
                  {...rest}
                  onChange={handleChange}
                  value={selectedOptions}
                  defaultValue={defaultValue}
                  searchPlaceholder={searchPlaceholder}
                  noOptionsMessage={noOptionsMessage}
                  ref={ref}
                  placeholder={placeholder}
                  options={options}
                  disabled={disabled}
                />
              </DropdownWrapper>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
