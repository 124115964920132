import React from 'react';
import Markdown from 'react-markdown';

interface CustomMarkdownProps {
  text: string;
}
export const CustomMarkdown = ({text}: CustomMarkdownProps) => {
  return (
    <Markdown
      className={'text-[16px] whitespace-prewrap '}
      components={{
        ul: ({...props}) => (
          <ul
            className="flex items-center"
            style={{
              display: 'block',
              listStyleType: 'disc',
              whiteSpace: 'normal',
              paddingInlineStart: '16px',
            }}
            {...props}
          />
        ),
        ol: ({...props}) => (
          <ol
            style={{
              display: 'block',
              listStyleType: 'decimal',
              paddingInlineStart: '16px',
            }}
            {...props}
          />
        ),
      }}>
      {text}
    </Markdown>
  );
};
