import {FC, memo, useCallback, useEffect, useMemo, useState} from 'react';
import nextId from 'react-id-generator';
import {ReactSortable} from 'react-sortablejs';
import {Insight} from '../insights/interface';
import isEqual from 'lodash/isEqual';
import {KpiInsight} from './kpi-insight';
import styled from 'styled-components';
import {ActionItemCompleted} from '../insights/action-items/action-items-completed';
import {ActionItemCompletionRate} from '../insights/action-items/action-items-completion';
import {ActionItemsDistribution} from '../insights/action-items/action-items-distribution';
import {ActionItemDistributionPerIndividual} from '../insights/action-items/action-items-distribution-individual';
import {KeyResultPerformance} from '../insights/objectives/key-result-performance';
import {ObjectiveStatus} from '../insights/objectives/objective-status';
import {ObjectiveCompletion} from '../insights/objectives/objective-completion';

const GridItem = styled.div<{w: number}>`
  width: 100%;
  height: 100%;
  min-height: 180px;
  grid-column: span 1 / span 1;

  @media (min-width: 768px) {
    grid-column: span ${(props) => Math.min(2, props.w / 3)} / span
      ${(props) => Math.min(2, props.w / 3)};
  }

  @media (min-width: 1200px) {
    grid-column: span ${(props) => Math.min(12, props.w)} / span
      ${(props) => Math.min(12, props.w)};
  }
`;

interface WidgetProps {
  widget: Insight[];
  isEditing: boolean;
  handleEditLayout: (id: string) => void;
  overallDateRange?: null | {startDate: string; endDate: string};
  onAdd?: (id: {firebaseId: string}[]) => void;
  onRemove?: (id: {firebaseId: string}[]) => void;
  sectionId: string;
}

export const WidgetLayout: FC<WidgetProps> = memo(
  ({
    widget,
    isEditing,
    onAdd,
    onRemove,
    handleEditLayout,
    overallDateRange,
  }) => {
    const formatWidget = useCallback((widgets: any[]) => {
      let formattedWidgets: any = [];
      let currentRow = 0;
      let currentColumn = 0;

      const addWidget = (widget: any, width: number) => {
        formattedWidgets.push({
          ...widget,
          i: widget.firebaseId || `widget-${formattedWidgets.length}`,
          x: currentColumn * 4,
          column: currentColumn,
          row: currentRow,
          y: currentRow * 4,
          w: width,
          h: 1,
          minW: width,
          maxW: width,
        });

        currentColumn += width / 4;
        if (currentColumn >= 3) {
          currentRow++;
          currentColumn = 0;
        }
      };

      const addPlaceholder = (width: number) => {
        formattedWidgets.push({
          i: `placeholder-${formattedWidgets.length}`,
          x: currentColumn * 4,
          y: currentRow * 4,
          w: width,
          h: 1,
          minW: width,
          maxW: width,
          isPlaceholder: true,
          // Add any other properties that might be required by your components
          type: 'placeholder', // This ensures it doesn't match any other widget type
        });

        currentColumn += width / 4;
        if (currentColumn >= 3) {
          currentRow++;
          currentColumn = 0;
        }
      };

      const halfSizedWidgets = [
        'action-items-distribution',
        'objective-status',
      ];

      widgets.forEach((widget, index) => {
        const prevItem = widgets[index - 1];

        const nextItem = widgets[index + 1];

        const nextDoubleItem = widgets[index + 2];

        if (widget.id === 'action-items-completion-per-individual') {
          currentRow++;
          currentColumn = 0;
          addWidget(widget, 12);
        } else if (
          halfSizedWidgets.includes(widget.id) ||
          widget.config.view === 'chart'
        ) {
          if (currentColumn !== 0) {
            currentRow++;
            currentColumn = 0;
          }

          addWidget(widget, 6);

          const nextItemIsHalfSized = halfSizedWidgets.includes(nextItem?.id);

          const prevItemIsHalfSized = halfSizedWidgets.includes(prevItem?.id);

          const isWidgetEven = (index + 1) % 2 === 0;

          if (
            !nextItemIsHalfSized &&
            !!nextItem &&
            (!isWidgetEven || !prevItemIsHalfSized)
          ) {
            addPlaceholder(6);
          }
        } else {
          addWidget(widget, 4);
          const nextItemIsHalfSized = halfSizedWidgets.includes(nextItem?.id);

          const next2ItemsIsHalfSized = halfSizedWidgets.includes(
            nextDoubleItem?.id,
          );

          if (nextItemIsHalfSized && currentRow !== 2 && currentRow !== 1) {
            addPlaceholder(4);
            if (next2ItemsIsHalfSized) {
              addPlaceholder(4);
            }
          }
        }
      });

      return formattedWidgets;
    }, []);

    const _layout = useMemo(() => formatWidget(widget), [widget, formatWidget]);

    const [widgets, setWidgets] = useState(_layout);

    useEffect(() => {
      if (!isEqual(_layout, widgets)) {
        setWidgets(_layout);
      }
    }, [_layout, widgets]);

    const WidgetType = ({...rest}: any) => {
      const commonStyles =
        'h-full border border-borderLight bg-backgroundDark shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] w-full rounded-[10px]';

      if (rest.isPlaceholder) {
        return <div className={`${commonStyles}`} />;
      }
      if (rest.type === 'kpi') {
        return (
          <KpiInsight
            overallDateRange={overallDateRange}
            {...rest}
            showRangeType
            handleEditLayout={handleEditLayout}
          />
        );
      }
      if (rest.id === 'action-items-completed') {
        return (
          <ActionItemCompleted
            overallDateRange={overallDateRange}
            {...rest}
            showRangeType
            handleEditLayout={handleEditLayout}
          />
        );
      }
      if (rest.id === 'key-results-performance') {
        return (
          <KeyResultPerformance
            overallDateRange={overallDateRange}
            {...rest}
            showRangeType
            handleEditLayout={handleEditLayout}
          />
        );
      }

      if (rest.id === 'objective-status') {
        return (
          <ObjectiveStatus
            overallDateRange={overallDateRange}
            {...rest}
            showRangeType
            handleEditLayout={handleEditLayout}
          />
        );
      }
      if (rest.id === 'objective-completion') {
        return (
          <ObjectiveCompletion
            overallDateRange={overallDateRange}
            {...rest}
            showRangeType
            handleEditLayout={handleEditLayout}
          />
        );
      }
      if (rest.id === 'action-items-completion-rate') {
        return (
          <ActionItemCompletionRate
            overallDateRange={overallDateRange}
            {...rest}
            showRangeType
            handleEditLayout={handleEditLayout}
          />
        );
      }
      if (rest.id === 'action-items-distribution') {
        return (
          <ActionItemsDistribution
            overallDateRange={overallDateRange}
            showRangeType
            {...rest}
            handleEditLayout={handleEditLayout}
          />
        );
      }
      if (rest.id === 'action-items-completion-per-individual') {
        return (
          <ActionItemDistributionPerIndividual
            overallDateRange={overallDateRange}
            showRangeType
            {...rest}
            handleEditLayout={handleEditLayout}
          />
        );
      }

      return <div className={`${commonStyles}`} />;
    };

    return (
      <div className="relative">
        <ReactSortable
          id={nextId('sortable')}
          className={`grid grid-flow-row gap-5 w-full h-auto grid-cols-2 sm:grid-cols-1 md:grid-cols-2 laptop:grid-cols-12`}
          group={'shared'}
          disabled={!isEditing}
          onAdd={() => {
            onAdd?.(widgets);
          }}
          onEnd={() => {
            onRemove?.(widgets);
          }}
          list={widgets}
          setList={(lists) => {
            setTimeout(
              () =>
                onAdd?.(
                  formatWidget(lists.filter((list) => !!list?.firebaseId)),
                ),
              10,
            );

            setWidgets(
              formatWidget(lists.filter((list) => !!list?.firebaseId)),
            );
          }}>
          {widgets.map((item: any) => (
            <GridItem key={item.i} w={item.w}>
              <WidgetType {...item} />
            </GridItem>
          ))}
        </ReactSortable>
      </div>
    );
  },
);
