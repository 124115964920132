import {CcController} from '@api/cc-api/cc-controller';
import {CcRequestImpl} from '@api/cc-api/cc-request';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {
  EmptyReviewWrapper,
  TableContainer,
  SecondMainChildHeader,
  HelpWidget,
  SecondWrapperChildHeader,
  ReviewCycleHeader,
} from './style';

import {useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router';
import {Body1, Body2} from '@ui/atoms/typography';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {CancelIcon, MoreVerticalIcon} from '@ui/atoms/icons';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {parseDateString} from '@utils/date';
import dayjs from 'dayjs';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {FlexRow} from '@ui/style/styles';
import {UserListCard} from '@ui/molecules/user/list-card';
import {SearchBox2} from '@ui/molecules/search-box';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {ItemLoader} from '@ui/organisms/item-loader';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {cloudinary} from '@assets/images/cloudinary';
import {observer} from 'mobx-react';

const {emptyGoals} = cloudinary;

const emptyPlaceholder = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoals,
    style: {height: '30%', width: '30%'},
  },

  subtitle: 'Create a review cycle to start a performance assessment exercise.',
  title: 'Your review cycle queue is empty',
};

export const ManageReviewCycle = observer(() => {
  const {
    usersStore: {users},
    ccStore: {resetCurrentReviewCycle},
  } = useStoreContext();

  const [searchValue, setSearchValue] = useState('');

  const [showHelp, setShowHelp] = useState(true);

  const navigate = useNavigate();

  const request = useMemo(() => new CcRequestImpl(), []);
  const controller = useMemo(() => new CcController(request), [request]);

  const {data: reviewCycles, isLoading, refetch} = useQuery(
    ['review-cycles'],
    () => controller.fetchReviewCycle(),
  );

  useEffect(() => {
    return () => resetCurrentReviewCycle();
  });
  const createCycle = useMemo(
    () => (
      <Button
        onClick={() => {
          navigate('/create-review-cycle');
        }}
        width="sm"
        style={{padding: '11px 24px'}}
        className="buttonDiv">
        Create a new cycle
      </Button>
    ),
    [navigate],
  );

  const computeReviewCyles = searchValue
    ? reviewCycles?.filter((cycle) => cycle.cycleName.includes(searchValue))
    : reviewCycles;

  const sortedReviewCycle = computeReviewCyles?.sort((a, b) => {
    return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
  });

  const getStatus = (status: string) => {
    switch (status) {
      case 'active':
        return 'Active';
      case 'upcoming':
        return 'Inactive';
      case 'ended':
        return 'Ended';
      default:
        return 'Draft';
    }
  };

  const [deleteModal, setDeleteModal] = useState({
    id: '',
    name: '',
    activeReview: false,
  });

  const handleDelete = async () => {
    const response = await controller.deleteReviewCycle(deleteModal.id);
    if (response) {
      await refetch();
      setDeleteModal({id: '', name: '', activeReview: false});

      activateNotification({
        title: 'Success',
        kind: 'success',
        content: 'Review cycle deleted',
      });
    }
  };

  if (isLoading) {
    return (
      <EmptyReviewWrapper>
        <ItemLoader />
      </EmptyReviewWrapper>
    );
  }

  return (
    <>
      <ReviewCycleHeader>
        <div className="search">
          <SearchBox2
            placeholder="Search"
            ref={null}
            value={searchValue}
            handleCancel={() => setSearchValue('')}
            onChange={(event) => setSearchValue(event.target.value)}
            inputStyle={{height: '50px', borderRadius: '10px'}}
          />
        </div>

        <div>{createCycle}</div>
      </ReviewCycleHeader>
      <TableContainer>
        <SecondMainChildHeader>
          <span>Name</span>
          <span>Created by</span>
          <span>Status</span>
          <span>Start - End date</span>
          <span>Actions</span>
        </SecondMainChildHeader>
        {!computeReviewCyles?.length && (
          <EmptyReviewWrapper>
            <div>
              <EmptyPlaceholder {...emptyPlaceholder} />
            </div>
          </EmptyReviewWrapper>
        )}
        {computeReviewCyles?.length ? (
          <div>
            {sortedReviewCycle?.map((review) => (
              <SecondWrapperChildHeader key={review.id}>
                <Body2 weight="semibold">
                  {review.cycleName}{' '}
                  <span style={{color: '#5F5F8C'}}>
                    (
                    {review.reviewType === '360'
                      ? '360 review'
                      : 'self-manager-review'}
                    )
                  </span>
                </Body2>
                <div>
                  {users
                    .filter((user) => user.id === review.createdBy)
                    .map((user, key) => (
                      <UserListCard
                        tooltip={true}
                        key={key}
                        userId={user?.id}
                        type="secondary"
                        users={users}
                        reviewer={user?.reviewer}
                        avatar={user.avatar ? user.avatar.url : ''}
                        name={
                          !user.firstName
                            ? user?.email
                              ? user?.email
                              : '----- -----'
                            : user.firstName
                        }
                      />
                    ))}
                </div>
                <FlexRow>
                  <CustomColorIcon
                    color={
                      review.status === 'active'
                        ? '#47B881'
                        : review.status === 'ended'
                        ? '#d52a2a'
                        : '#EDEDF2'
                    }
                    height="8px"
                    width="8px"
                    margin={5}
                  />
                  <Body2 weight="bold">{getStatus(review.status)}</Body2>
                </FlexRow>
                <Body2 weight="bold">
                  {review.milestones ? (
                    <>
                      {dayjs(review.milestones.startDate).format('DD MMM')} -{' '}
                      {dayjs(review.milestones.endDate).format('DD MMM')}{' '}
                    </>
                  ) : (
                    '-'
                  )}
                </Body2>
                <FlexRow>
                  <Button
                    style={{padding: '8px 12px'}}
                    kind="secondary"
                    onClick={() =>
                      navigate('/preview-review-cycle?type=view', {
                        state: {
                          data: JSON.stringify({
                            ...review,
                            period: parseDateString(review.period),
                          }),
                        },
                      })
                    }>
                    View
                  </Button>
                  <DropdownVertical
                    customIcon={<MoreVerticalIcon />}
                    dropdownWrapperStyle={{
                      right: '-83%',
                      minWidth: 214,
                    }}
                    collapseOnClick
                    menu={(handleClose: () => void) => (
                      <div onClick={handleClose}>
                        <DropdownItem
                          disabled={['ended', 'active'].includes(review.status)}
                          onClick={() => {
                            navigate('/create-review-cycle', {
                              state: {
                                data: JSON.stringify({
                                  ...review,
                                  period: parseDateString(review.period),
                                }),
                              },
                            });
                          }}>
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setDeleteModal({
                              id: review.id,
                              activeReview: ['ended', 'active'].includes(
                                review.status,
                              ),
                              name: review.cycleName,
                            });
                          }}>
                          Delete
                        </DropdownItem>
                      </div>
                    )}
                  />
                </FlexRow>
              </SecondWrapperChildHeader>
            ))}
          </div>
        ) : null}
      </TableContainer>

      <CustomIconModal
        open={!!deleteModal.id}
        onClose={() => {
          setDeleteModal({id: '', name: '', activeReview: false});
        }}
        body={
          <Body1 kind="textBody" align="center">
            Click{' '}
            <span style={{fontWeight: 700, color: 'black'}}>"delete"</span> to
            continue.
          </Body1>
        }
        onClick={() => {}}
        customButton={
          <Button
            onClick={() => handleDelete()}
            width="full"
            kind="secondary"
            type="button">
            Delete
          </Button>
        }
        title={'Delete cycle'}
        description={
          deleteModal.activeReview ? (
            <Body1 align="center" kind="textBody">
              Confirm deletion of{' '}
              <span style={{color: '#585ADF', fontWeight: 500}}>
                {deleteModal.name}
              </span>
              ? The cycle's submitted reviews and results will be deleted
              permanently.
            </Body1>
          ) : (
            <Body1 align="center" kind="textBody">
              Are you sure you want to delete{' '}
              <span style={{color: '#585ADF', fontWeight: 500}}>
                {deleteModal.name}
              </span>
              ? This cannot be undone.
            </Body1>
          )
        }
      />

      {showHelp && !computeReviewCyles?.length && !searchValue && (
        <HelpWidget>
          <div />
          <Body2 kind="textBody">
            Watch a tutorial on how to create review cycles.{' '}
            <span style={{fontWeight: 600, color: '#585ADF'}}>Watch here</span>
          </Body2>
          <PlainButton onClick={() => setShowHelp(false)}>
            <CancelIcon />
          </PlainButton>
        </HelpWidget>
      )}
    </>
  );
});
