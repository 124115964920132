import {BaseRequest} from '../../../../../configs/request';
import {authStore} from '../../../../../store/stores/auth-store';

export interface AnalyticsRequest extends BaseRequest {
  url: string;
  getAnalyticsGoals(goalPage?: number): Promise<any>;
  fetchSpecifiedGoals(
    filters: any,
    view?: string,
    notPaginated?: boolean,
  ): Promise<any>;
  fetchSpecifiedKpis(filters: any, notPaginated?: boolean): Promise<any>;
  fetchSpecifiedGoalsForExport(filters: any, view?: string): Promise<any>;
  fetchSpecifiedPeopleForStatus(filters: any): Promise<any>;
  fetchSpecifiedPeopleForOwnership(filters: any): Promise<any>;
  fetchSpecifiedGroups(filters: any): Promise<any>;
  fetchMembersAndManagers(): Promise<any>;
  fetchReportPulse(filters: any): Promise<any>;
  fetchReportKPIComparison(
    id: string,
    preference: 'sum' | 'average' | 'latest',
    firstDate: {startDate: string; endDate: string},
    secondDate?: {startDate: string; endDate: string},
  ): Promise<any>;
  fetchReportObjectives(filters: any): Promise<any>;
  fetchReportCompetence(filters: any): Promise<any>;
  fetchReportCheckinsQuestions(filters: any): Promise<any>;
  fetchReportGoalActivitiesById(id: string, limit: number): Promise<any>;
  getCompetenceReport(filter: any): Promise<any>;
  fetchGroupType(): Promise<any>;
  fetchReportGoalById(id: string, filter?: any): Promise<any>;
  fetchReportsKpis(id: any): Promise<any>;
  fetchReportsGoals(id: any): Promise<any>;
  fetchParentGoals(id: any): Promise<any>;
  fetchGroups(): Promise<any>;
  fetchDepartmentGroups(groupTypeId: string): Promise<any>;
  fetchGoalAlignmentTree(id: string): Promise<any>;
}

export class AnalyticsRequestImpl
  extends BaseRequest
  implements AnalyticsRequest {
  url: string = '/reports';
  bareUrl: string = '/';
  private requestHeader = {
    headers: {
      Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
    },
  };
  private isAdmin = authStore?.auth?.user?.role === 'admin';

  async getAnalyticsGoals(goalPage?: number) {
    if (goalPage) {
      const {data: response} = await this.request.get(
        `${this.url}/goals?page=${goalPage}&manager=${authStore?.auth?.user?.id}`,
        this.requestHeader,
      );
      return response;
    } else {
      //unpaginated
      const {data: response} = await this.request.get(
        `${this.url}/goals?manager=${authStore?.auth?.user?.id}`,
        this.requestHeader,
      );
      return response;
    }
  }
  async getCompetenceReport(filters: any) {
    const {startDate, endDate, user} = filters;

    const {data: response} = await this.request.get(
      `${this.url}/competence?${user ? `user=${user}` : ''}${
        startDate ? `&startDate=${startDate}` : ''
      }${endDate ? `&endDate=${endDate}` : ''}`,
      this.requestHeader,
    );
    return response;
  }

  async fetchSpecifiedGoals(
    filters: any,
    view?: string,
    notPaginated?: boolean,
  ) {
    const {
      goalPage,
      state,
      status,
      startDate,
      endDate,
      member,
      tag,
      filterBy,
      threshold,
      isKpi,
      newReport,
      limit,
      sortBy,
      goalIds,
      name,
      manager,
      goalType,
      removeKpiFilter,
      department,
      groupBy,
      departmentMembers,
      deactivatedMembers,
    } = filters;

    const includesPastDue = state?.includes('past_due');

    const _state = includesPastDue ? state.replace(',past_due', '') : state;

    const goalsUrl = `${this.url}/goals?include=assignee${
      goalPage ? `&page=${goalPage}` : ''
    }${limit ? `&limit=${limit}` : ''}${
      _state &&
      _state.length > 0 &&
      !(
        deactivatedMembers &&
        deactivatedMembers.length > 0 &&
        state === 'archived'
      )
        ? `&status=${_state}`
        : state &&
          state.length > 0 &&
          deactivatedMembers &&
          deactivatedMembers.length > 0 &&
          state === 'archived'
        ? ''
        : ''
    }${threshold ? `&threshold=${threshold}` : ''}${
      name ? `&name=${name}` : ''
    }${includesPastDue ? '&past_due=true' : ''}${tag ? `&tag=${tag}` : ''}${
      newReport ? '&newReport=true' : ''
    }${groupBy ? `&groupBy=${groupBy}` : ''}${
      goalIds ? `&goalId=${goalIds}` : ''
    }${filterBy ? `&filterBy=${filterBy}` : ''}${
      status && status.length > 0 ? `&state=${status}` : ''
    }${`&startDate=${startDate}&endDate=${endDate}`}${
      member && member.length > 0 ? `&user=${member}` : ''
    }${sortBy ? `&sortBy=${sortBy}` : ''}${
      deactivatedMembers && deactivatedMembers.length > 0
        ? `&user=${deactivatedMembers}&deactivated=true`
        : ''
    }${removeKpiFilter ? '' : isKpi ? '&isKpi=true' : '&isKpi=false'}${
      goalType && goalType.length > 0 ? `&goalType=${goalType}` : ''
    }${department && department.length > 0 ? `&group=${department}` : ''}${
      departmentMembers && departmentMembers.length > 0
        ? `&people_group=${departmentMembers}`
        : ''
    }${
      manager && manager.length > 0
        ? `&manager=${manager}`
        : !(manager && manager.length > 0) && !this.isAdmin
        ? ``
        : ''
    }${
      view === 'tree'
        ? '&parent_goals=true'
        : view === 'stacked'
        ? '&paginate=false'
        : ''
    }${notPaginated ? '&paginate=false' : ''}`;
    const {data: response} = await this.request.get(
      goalsUrl,
      this.requestHeader,
    );
    if (view === 'tree') {
      return {
        goals: response,
        totalResults: response.length,
      };
    } else if (view === 'stacked') {
      return {
        goals: response.goals,

        totalResults: response.goals.length,
        ...response,
      };
    } else return response;
  }
  async fetchSpecifiedKpis(filters: any, notPaginated?: boolean) {
    const {
      kpiPage,
      state,
      status,
      startDate,
      endDate,
      member,
      manager,
      newReport,
      goalIds,
      filterBy,
      name,
      kpiPageLimit,
      sortBy,
      threshold,
      department,
      departmentMembers,
      deactivatedMembers,
      target,
    } = filters;

    const kpisUrl = `/reports/kpis?include=assignee,goal,goalType,group,goalInfo${
      sortBy ? `&sortBy=${sortBy}` : ''
    }${goalIds ? `&goal=${goalIds}` : ''}${kpiPage ? `&page=${kpiPage}` : ''}${
      newReport ? '&newReport=true' : ''
    }${filterBy ? `&filterBy=${filterBy}` : ''}${
      kpiPageLimit ? `&limit=${kpiPageLimit}` : ''
    }${
      state &&
      state.length > 0 &&
      !(
        deactivatedMembers &&
        deactivatedMembers.length > 0 &&
        state === 'archived'
      )
        ? `&status=${state}`
        : state &&
          state.length > 0 &&
          deactivatedMembers &&
          deactivatedMembers.length > 0 &&
          state === 'archived'
        ? ''
        : ''
    }${threshold ? `&threshold=${threshold}` : ''}${
      status && status.length > 0 ? `&state=${status}` : ''
    }${name ? `&name=${name}` : ''}${
      target && target.length > 0 ? `&target=${target}` : ''
    }${`&startDate=${startDate}&endDate=${endDate}`}${
      member && member.length > 0 ? `&user=${member}` : ''
    }${
      deactivatedMembers && deactivatedMembers.length > 0
        ? `&user=${deactivatedMembers}&deactivated=true`
        : ''
    }${department && department.length > 0 ? `&group=${department}` : ''}${
      departmentMembers && departmentMembers.length > 0
        ? `&people_group=${departmentMembers}`
        : ''
    }${
      manager && manager.length > 0
        ? `&manager=${manager}`
        : !(manager && manager.length > 0) && !this.isAdmin
        ? `&manager=${authStore?.auth?.user?.id}`
        : ''
    }${notPaginated ? '&paginate=false' : ''}`;
    const {data: response} = await this.request.get(
      kpisUrl,
      this.requestHeader,
    );
    return response;
  }
  async fetchSpecifiedGoalsForExport(filters: any, view?: string) {
    const {
      startDate,
      endDate,
      member,
      department,
      departmentMembers,
      goalType,
      status,
      deactivatedMembers,
    } = filters;

    const goalsUrl = `${
      this.url
    }/goals${`?startDate=${startDate}&endDate=${endDate}`}${
      member && member.length > 0 ? `&user=${member}` : ''
    }${
      deactivatedMembers && deactivatedMembers.length > 0
        ? `&user=${deactivatedMembers}`
        : ''
    }${department && department.length > 0 ? `&group=${department}` : ''}${
      departmentMembers && departmentMembers.length > 0
        ? `&people_group=${departmentMembers}`
        : ''
    }${
      goalType ? `&goalType=${goalType}` : ''
    }${`&export=true&paginate=false`}${!this.isAdmin ? `` : ''}${
      status && status.length > 0 ? `&status=${status}` : ''
    }`;
    const {data: response} = await this.request.get(
      goalsUrl,
      this.requestHeader,
    );
    if (view === 'tree') {
      return {
        goals: response,
        totalResults: response.length,
      };
    } else return response;
  }
  async fetchReportKPIComparison(
    id: string,
    preference: 'sum' | 'average' | 'latest',
    firstDate: {startDate: string; endDate: string},
    secondDate?: {startDate: string; endDate: string},
  ) {
    const goalsUrl = `${
      this.url
    }/goals/${id}/compare${`?firstDate=${firstDate.startDate}to${firstDate.endDate}`}${
      secondDate
        ? `&secondDate=${secondDate.startDate}to${secondDate.endDate}`
        : ''
    }${preference ? `&preference=${preference}` : ''}`;

    const {data: response} = await this.request.get(
      goalsUrl,
      this.requestHeader,
    );
    return response;
  }

  async fetchSpecifiedPeopleForStatus(filters: any) {
    const {
      peoplePage,
      state,
      status,
      startDate,
      endDate,
      member,
      manager,
      department,
      departmentMembers,
      deactivatedMembers,
    } = filters;
    const statusPeopleUrl = `${
      this.url
    }/people?reviewer=true&page=${peoplePage}${
      state && state.length > 0 ? `&status=${state}` : ''
    }${
      status && status.length > 0 ? `&state=${status}` : ''
    }${`&startDate=${startDate}&endDate=${endDate}`}${
      member && member.length > 0 ? `&user=${member}` : ''
    }${
      deactivatedMembers && deactivatedMembers.length > 0
        ? `&user=${deactivatedMembers}`
        : ''
    }${department && department.length > 0 ? `&group=${department}` : ''}${
      departmentMembers && departmentMembers.length > 0
        ? `&people_group=${departmentMembers}`
        : ''
    }${
      manager && manager.length > 0
        ? `&manager=${manager}`
        : !(manager && manager.length > 0) && !this.isAdmin
        ? ``
        : ''
    }`;

    const {data: response} = await this.request.get(
      statusPeopleUrl,
      this.requestHeader,
    );

    return response;
  }

  async fetchSpecifiedPeopleForOwnership(filters: any) {
    const {
      peoplePage,
      state,
      status,
      startDate,
      endDate,
      member,
      deactivatedMembers,
      manager,
      department,
      departmentMembers,
      withGoals,
      withoutGoals,
      withKeyResults,
      withoutKeyResults,
    } = filters;
    const ownershipPeopleUrl = `${
      this.url
    }/people?reviewer=true&page=${peoplePage}${
      state && state.length > 0 ? `&status=${state}` : ''
    }${
      status && status.length > 0 ? `&state=${status}` : ''
    }${`&startDate=${startDate}&endDate=${endDate}`}${
      member && member.length > 0 ? `&user=${member}` : ''
    }${
      deactivatedMembers && deactivatedMembers.length > 0
        ? `&user=${deactivatedMembers}`
        : ''
    }${withGoals ? `&with_goals=true` : ''}${
      withKeyResults ? `&with_keyresults=true` : ''
    }${withoutGoals ? `&without_goals=true` : ''}${
      withoutKeyResults ? `&without_keyresults=true` : ''
    }${department && department.length > 0 ? `&group=${department}` : ''}${
      departmentMembers && departmentMembers.length > 0
        ? `&people_group=${departmentMembers}`
        : ''
    }${
      manager && manager.length > 0
        ? `&manager=${manager}`
        : !(manager && manager.length > 0) && !this.isAdmin
        ? ``
        : ''
    }`;

    const {data: response} = await this.request.get(
      ownershipPeopleUrl,
      this.requestHeader,
    );
    return response;
  }

  async fetchSpecifiedGroups(filters: any) {
    const {
      state,
      status,
      member,
      deactivatedMembers,
      manager,
      department,
      withGoals,
      startDate,
      endDate,
      withoutGoals,
      withKeyResults,
      withoutKeyResults,
    } = filters;
    const groupUrl = `${this.url}/group?page=1${
      member && member.length > 0 ? `&user=${member}` : ''
    }${
      deactivatedMembers && deactivatedMembers.length > 0
        ? `&user=${deactivatedMembers}`
        : ''
    }${
      manager && manager.length > 0
        ? `&manager=${manager}`
        : !(manager && manager.length > 0) && !this.isAdmin
        ? `&manager=${authStore?.auth?.user?.id}`
        : ''
    }${`&startDate=${startDate}&endDate=${endDate}`}${
      department && department.length > 0 ? `&group=${department}` : ''
    }${withGoals ? `&with_goals=true` : ''}${
      withKeyResults ? `&with_keyresults=true` : ''
    }${withoutGoals ? `&without_goals=true` : ''}${
      withoutKeyResults ? `&without_keyresults=true` : ''
    }${state && state.length > 0 ? `&status=${state}` : ''}${
      status && state.length > 0 ? `&state=${status}` : ''
    }`;

    const {data: response} = await this.request.get(
      groupUrl,
      this.requestHeader,
    );
    return response;
  }

  async fetchMembersAndManagers() {
    const {data: response} = await this.request.get(
      `/users?paginate=false`,
      this.requestHeader,
    );

    return response;
  }

  async fetchGroupType() {
    const {data: response} = await this.request.get(
      `/group-type`,
      this.requestHeader,
    );
    return response;
  }
  async fetchReportsKpis(date: any) {
    const {data: response} = await this.request.get(
      `/reports/kpis?startDate=${date?.starts}&endDate=${date?.ends}&paginate=false`,
      this.requestHeader,
    );
    return response;
  }
  async fetchReportsGoals(date: any) {
    const {data: response} = await this.request.get(
      `reports/goals?include=assignee&startDate=${date?.starts}&endDate=${date?.ends}&`,
      this.requestHeader,
    );
    return response;
  }
  async fetchParentGoals(id: any) {
    const {data: response} = await this.request.get(
      `goals/${id}?include=assignee`,
      this.requestHeader,
    );
    return response;
  }

  async fetchGroups() {
    const {data: response} = await this.request.get(
      `/groups?limit=1000000&include=groupType`,
      this.requestHeader,
    );
    return response;
  }
  async fetchDepartmentGroups(groupTypeId: string) {
    // Dont delete this comment
    // const {filterGroupTypeId} = analyticsState;
    // const url = `/group-type${
    //   filterGroupTypeId?.length > 1
    //     ? `/${filterGroupTypeId[0]}?include=groups`
    //     : ''
    // }`;
    //TODO: Workaround temporary fix
    const url = `/group-type/${groupTypeId[0]}?include=groups`;

    const {data: response} = await this.request.get(url, this.requestHeader);
    return response;
  }

  async fetchGoalAlignmentTree(id: string) {
    const url = `/goals/${id}/alignment`;
    const {data: response} = await this.request.get(url, this.requestHeader);
    return response;
  }
  async fetchReportGoalById(
    id: string,
    filter?: {startDate: string; endDate: string},
  ) {
    const url = `reports/goals/${id}?include=assignee,goalAlignment${`${
      filter?.startDate ? `&startDate=${filter.startDate}` : ''
    }${filter?.endDate ? `&endDate=${filter.endDate}` : ''}`}`;
    const {data: response} = await this.request.get(url, this.requestHeader);
    return response;
  }

  async fetchReportGoalActivitiesById(id: string, limit: number) {
    const url = `goals/${id}/activities?limit=${limit}`;
    const {data: response} = await this.request.get(url, this.requestHeader);
    return response;
  }
  async fetchReportPulse(filters: any) {
    const {
      startDate,
      endDate,
      member,
      manager,
      department,
      departmentMembers,
      group,
      pulse,
      groupBy,
    } = filters;
    const goalsUrl = `${
      this.url
    }/pulse${`?startDate=${startDate}&endDate=${endDate}`}${
      member && member.length > 0 ? `&user=${member}` : ''
    }${group ? `&group=${group}` : ''}${pulse ? `&pulse=${pulse}` : ''}${
      groupBy ? `&groupBy=${groupBy}` : ''
    }${manager && manager.length > 0 ? `&manager=${manager}` : ''}${
      department && department.length > 0 ? `&group=${department}` : ''
    }${
      departmentMembers && departmentMembers.length > 0
        ? `&people_group=${departmentMembers}`
        : ''
    }`;
    const {data: response} = await this.request.get(goalsUrl, {
      ...this.requestHeader,
      useCache: false,
    });
    return response;
  }
  async fetchReportObjectives(filters: any) {
    const {
      startDate,
      endDate,
      member,
      manager,
      status,
      state,
      department,
      departmentMembers,
    } = filters;
    const goalsUrl = `${
      this.url
    }/goal-status${`?startDate=${startDate}&endDate=${endDate}`}${
      member && member.length > 0 ? `&user=${member}` : ''
    }${status ? `&status=${status}` : ''}${state ? `&state=${state}` : ''}${
      manager && manager.length > 0 ? `&manager=${manager}` : ''
    }${department && department.length > 0 ? `&group=${department}` : ''}${
      departmentMembers && departmentMembers.length > 0
        ? `&people_group=${departmentMembers}`
        : ''
    }`;
    const {data: response} = await this.request.get(
      goalsUrl,
      this.requestHeader,
    );
    return response;
  }

  async fetchReportCompetence(filters: any) {
    const {
      startDate,
      endDate,
      member,
      manager,
      status,
      department,
      departmentMembers,
    } = filters;
    const goalsUrl = `${
      this.url
    }/competence${`?startDate=${startDate}&endDate=${endDate}`}${
      member && member.length > 0 ? `&user=${member}` : ''
    }${status ? `&status=${status}` : ''}${
      manager && manager.length > 0 ? `&manager=${manager}` : ''
    }${department && department.length > 0 ? `&group=${department}` : ''}${
      departmentMembers && departmentMembers.length > 0
        ? `&people_group=${departmentMembers}`
        : ''
    }`;
    const {data: response} = await this.request.get(
      goalsUrl,
      this.requestHeader,
    );
    return response;
  }
  async fetchReportCheckinsQuestions(filters: any) {
    const {
      startDate,
      endDate,
      member,
      manager,
      status,
      questionId,
      department,
      departmentMembers,
    } = filters;
    const goalsUrl = `${
      this.url
    }/checkin-questions${`?startDate=${startDate}&endDate=${endDate}`}${
      member && member.length > 0 ? `&user=${member}` : ''
    }${status ? `&status=${status}` : ''}${
      questionId ? `&questionId=${questionId}` : ''
    }${manager && manager.length > 0 ? `&manager=${manager}` : ''}${
      department && department.length > 0 ? `&group=${department}` : ''
    }${
      departmentMembers && departmentMembers.length > 0
        ? `&people_group=${departmentMembers}`
        : ''
    }`;
    const {data: response} = await this.request.get(
      goalsUrl,
      this.requestHeader,
    );
    return response;
  }
}
