import {TimeWrapper} from '@pages/1:1s/create-meetings/styles';
import {TextField} from '@ui/molecules/field/textfield';
import {SecondaryNavigation} from '@ui/molecules/nav/navigation';
import {formatTime} from '@utils/date';
import {Body1, Body2} from '../typography';

interface TimeSelectProps {
  selectedTime: SelectedTimeProps;
  addEndTime?: boolean;
  updateSelectedTime: (
    value: string,
    field: keyof SelectedTimeProps,
    type: TimeType,
    period?: string,
  ) => void;
  type: TimeType;
}

interface SelectedTimeProps {
  time: string;
  period: string;
  error: string;
}

type TimeType = 'starts' | 'ends';

interface TimeSelectProps {
  selectedTime: SelectedTimeProps;
  addEndTime?: boolean;
  updateSelectedTime: (
    value: string,
    field: keyof SelectedTimeProps,
    type: TimeType,
    period?: string,
  ) => void;
  type: TimeType;
  startTime?: string; // Add start time props
  startPeriod?: string;
}

export const TimeSelect = ({
  selectedTime,
  addEndTime,
  updateSelectedTime,
  type,
  startTime,
  startPeriod,
}: TimeSelectProps) => {
  const selectedPeriodIsPM = selectedTime.period?.toLowerCase() === 'pm';
  const selectedPeriodIsAM = selectedTime.period?.toLowerCase() === 'am';

  // Helper function to convert time to minutes since midnight
  const getTimeInMinutes = (time: string, period: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    let totalHours = hours;

    // Convert to 24 hour format
    if (period.toLowerCase() === 'pm' && hours !== 12) {
      totalHours += 12;
    } else if (period.toLowerCase() === 'am' && hours === 12) {
      totalHours = 0;
    }

    return totalHours * 60 + minutes;
  };

  // Validate end time is after start time
  const validateEndTime = (endTime: string, endPeriod: string) => {
    if (type === 'ends' && startTime && startPeriod) {
      const startMinutes = getTimeInMinutes(startTime, startPeriod);
      const endMinutes = getTimeInMinutes(endTime, endPeriod);

      if (endMinutes <= startMinutes) {
        return 'End time must be after start time';
      }
    }
    return '';
  };

  return (
    <TimeWrapper error={!!selectedTime.error} className="bg-white">
      <div className="start">
        <Body1
          kind={selectedTime.error ? 'red400' : 'textDark'}
          align="center"
          className="capitalize">
          {type}
        </Body1>
      </div>
      <div className="body">
        <div>
          <TextField
            className="input"
            value={selectedTime.time}
            onBlur={(event) => {
              const inputValue = event.target.value;

              if (inputValue) {
                let error = '';
                let period = selectedTime.period;

                const formattedTime = formatTime(
                  inputValue,
                  (_error) => {
                    error = _error;
                  },
                  (_period) => {
                    if (_period) {
                      period = _period;
                    }
                  },
                );

                if (error) {
                  updateSelectedTime(error, 'error', type);
                  return;
                }

                // Validate end time
                const endTimeError = validateEndTime(formattedTime, period);
                if (endTimeError) {
                  updateSelectedTime(endTimeError, 'error', type);
                  return;
                }

                updateSelectedTime(formattedTime, 'time', type);
                updateSelectedTime(period, 'period', type);

                // Add end time
                if (type === 'starts' && addEndTime) {
                  const [hours] = formattedTime.split(':');
                  const is24HourTime = Number(hours) + 1 > 12;

                  const addedHourToTime = formattedTime.replace(
                    hours,
                    String(
                      is24HourTime ? Number(hours) + 1 - 12 : Number(hours) + 1,
                    ),
                  );

                  const startPeriod = period;
                  const updatedPeriod = period === 'am' ? 'pm' : 'am';

                  if (Number(hours) < 11) {
                    updateSelectedTime(
                      addedHourToTime,
                      'time',
                      'ends',
                      startPeriod,
                    );
                  } else {
                    updateSelectedTime(
                      addedHourToTime,
                      'time',
                      'ends',
                      updatedPeriod,
                    );
                  }
                }
              }
            }}
            margin
            placeholder="00:00"
            onChange={(event) => {
              const inputValue = event.target.value;
              updateSelectedTime(inputValue, 'time', type);
            }}
          />
        </div>

        <div className="relative">
          <SecondaryNavigation
            navigations={[
              {
                title: (
                  <Body2
                    weight={selectedPeriodIsAM ? 'semibold' : 'regular'}
                    kind={selectedPeriodIsAM ? 'textDark' : 'textBody'}
                    className="text-[12px]">
                    AM
                  </Body2>
                ),
                active: selectedPeriodIsAM,
                id: '1',
                onClick: () => {
                  updateSelectedTime('AM', 'period', type);

                  // Revalidate time when period changes for end time
                  if (selectedTime.time) {
                    const endTimeError = validateEndTime(
                      selectedTime.time,
                      'AM',
                    );
                    if (endTimeError) {
                      updateSelectedTime(endTimeError, 'error', type);
                    }
                  }
                },
              },
              {
                title: (
                  <Body2
                    weight={selectedPeriodIsPM ? 'semibold' : 'regular'}
                    kind={selectedPeriodIsPM ? 'textDark' : 'textBody'}
                    className="text-[12px]">
                    PM
                  </Body2>
                ),
                active: selectedPeriodIsPM,
                id: '1',
                onClick: () => {
                  updateSelectedTime('PM', 'period', type);

                  // Revalidate time when period changes for end time
                  if (selectedTime.time) {
                    const endTimeError = validateEndTime(
                      selectedTime.time,
                      'PM',
                    );
                    if (endTimeError) {
                      updateSelectedTime(endTimeError, 'error', type);
                    }
                  }
                },
              },
            ]}
            bodyStyle={{
              padding: '9px 8px',
            }}
            activeStyle={{
              height: '20px',
              width: '30px',
              top: '8px',
              left: selectedPeriodIsAM ? '5px' : '3px',
              marginTop: '0px',
            }}
            navStyle={{
              border: 0,
              height: '30px',
              maxWidth: '72px',
              padding: '2px 6px 0px',
            }}
          />
        </div>
      </div>
    </TimeWrapper>
  );
};
