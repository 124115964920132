import {makeAutoObservable, observable, action} from 'mobx';

export class CompanyStore {
  @observable searchable = {
    manager: undefined,
    member: undefined,
  };
  @observable teamMemberGoals: any = null;
  @observable directReports: any = null;
  @observable fetchTeamUsers: any = null;
  @observable selectedOptions = 'people';
  @observable directReport: any = null;
  @observable commentRef: any = '';
  @observable reportData: any = null;
  @observable deleteLoading: any = [];
  @observable goalArray: Map<string, any> = new Map();
  @observable emptyGoals: any = false;
  @observable updateEditedGoal: any = [];
  @observable updatedGoal: any = false;
  @observable managersDirectReports: any = [];
  @observable groupsReport: any = {};
  @observable groupMembers: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setSearchable = (field: string, data: any) => {
    this.searchable = {
      ...this.searchable,
      [field]: data,
    };
  };

  @action
  setReportdata = (data: any) => {
    this.reportData = data;
  };

  @action
  setTeamMemberGoals = (data: any) => {
    this.teamMemberGoals = data;
  };

  @action setSelectedOptions = (option: string) => {
    this.selectedOptions = option;
  };

  @action updateGoalInDirectReport = (goalId: string, data: any) => {
    const reports = this.directReport;
    if (reports) {
      reports.map((report: any) =>
        report.goals.map((goal: any) => (goal.id === goalId ? data : goal)),
      );
    }
  };

  @action setUsers = (data: any) => {
    this.fetchTeamUsers = data;
  };

  @action
  setDirectReports = (data: any) => {
    this.directReports = data;
  };

  @action handleCommentRef = (ref: any) => {
    this.commentRef = ref;
  };
  @action
  handleEmptyGoals = (status: boolean) => {
    this.emptyGoals = status;
  };
  @action
  deleteGoalFromStore() {
    // Todo: remove goal with id from store
  }
  @action
  setGoalArray = (array: any) => {
    this.goalArray = array;
  };
  @action
  setupdatedGoal = () => {
    this.updatedGoal = true;
  };
  @action
  setDeleteLoading = (data: any) => {
    this.deleteLoading = [data];
  };

  @action
  setManagersDirectReport = (data: any) => {
    this.managersDirectReports = data;
  };

  @action
  endManagerDirectReportGoal = (goalId?: string, data?: any) => {
    this.managersDirectReports = this.managersDirectReports.map(
      (directReport: any) => {
        return {
          assignee: directReport.assignee,
          goals:
            directReport?.goals?.length > 0 &&
            directReport?.goals?.map((goal: any) => {
              if (goalId === goal?.id) {
                return {
                  ...goal,
                  status:
                    data.completed === true ? 'completed' : 'in_completed',
                };
              }
              return goal;
            }),
        };
      },
    );
  };

  @action
  endTeamMemberGoals = (goalId?: string, data?: any) => {
    this.teamMemberGoals = this.teamMemberGoals.map((directReport: any) => {
      return {
        assignee: directReport.assignee,
        goals:
          directReport?.goals?.length > 0 &&
          directReport?.goals?.map((goal: any) => {
            if (goalId === goal?.id) {
              return {
                ...goal,
                status: data.completed === true ? 'completed' : 'in_completed',
              };
            }
            return goal;
          }),
      };
    });
  };
  @action
  endDirectReportGoals = (goalId?: string, data?: any) => {
    this.directReports = this.directReports.map((directReport: any) => {
      return {
        assignee: directReport.assignee,
        goals:
          directReport?.goals?.length > 0 &&
          directReport?.goals?.map((goal: any) => {
            if (goalId === goal?.id) {
              return {
                ...goal,
                status: data.completed === true ? 'completed' : 'in_completed',
              };
            }
            return goal;
          }),
      };
    });
  };

  @action
  setGroupReport = (data: any) => {
    this.groupsReport = data;
  };
  @action
  setGroupMembers = (data: any) => {
    this.groupMembers = data;
  };
}

export const companyStore = new CompanyStore();
