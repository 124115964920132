import {observer} from 'mobx-react-lite';
import {useStoreContext} from '@store/store-context';
import {MoodDiv} from '../my_checkins-style';
import {Helper} from '@ui/atoms/helper';
import {Image} from '@ui/atoms/image';
import {TextField} from '@ui/molecules/field/textfield';
import {Headline3, CTA, Body1} from '@ui/atoms/typography';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {emojiList, IEmoji} from '@ui/assets/emojis';
import {ReactNode, useEffect, useState} from 'react';
import {daysLeftToNextCheckin} from '@utils/date';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {NextSubmission} from '@ui/organisms/pulse/next-submission';

export const MoodBox = observer(
  ({
    value,
    isReadOnly,
    handleFormValueChange,
    debounceUpdatePulse,
    updatePulse,
    isSubmitted,
    header,
  }: {
    value: {
      mood: string;
      feedback: string;
      id: string;
    };
    isReadOnly: boolean;
    isSubmitted?: boolean;

    header?: ReactNode;
    handleFormValueChange?: (value: any, field: string) => void;
    debounceUpdatePulse?: (value: any) => void;
    updatePulse?: (value: any) => void;
  }) => {
    const {
      checkinsStore: {
        workspaceCheckins,
        pulseValidation,
        updatePulseValidationError,
        configuration,
      },
    } = useStoreContext();

    const [mood, setMood] = useState<null | string>(value?.mood);

    useEffect(() => {
      setMood(value?.mood);
    }, [value?.mood]);

    const handleMood = (mood_: string, pulseId: string) => {
      if (!isReadOnly) {
        if (mood !== null && mood === mood_.toLowerCase()) {
          setMood(null);
          handleFormValueChange &&
            handleFormValueChange({...value, mood: null}, 'pulse');
        } else {
          updatePulseValidationError(null);
          updatePulse &&
            updatePulse({
              pulse: {...value, mood: mood_.toLowerCase()},
              pulseId: value.id,
            });
          setMood(mood_.toLowerCase());
          handleFormValueChange &&
            handleFormValueChange(
              {...value, mood: mood_.toLowerCase()},
              'pulse',
            );
        }
      }
    };

    const moods = [
      {
        emoji: emojiList.drained,
        text: 'Drained',
      },
      {
        emoji: emojiList.sad,
        text: 'Sad',
      },
      {
        emoji: emojiList.meh,
        text: 'Meh!',
      },
      {
        emoji: emojiList.happy,
        text: 'Happy',
      },
      {
        emoji: emojiList.elated,
        text: 'Elated',
      },
    ];

    const pulseFrequency =
      configuration?.generalFrequency?.frequency || 'weekly';

    return (
      <>
        <MoodDiv
          activated={mood !== null}
          error={pulseValidation?.error}
          className="bg-white">
          <div className="titleDiv">
            {header ? (
              header
            ) : (
              <>
                <FlexRowSpaceBetween>
                  <div>
                    <Headline3 kind="textDark">
                      Pulse<span className="required">*</span> {'  '}
                    </Headline3>
                    <VerticalSpacer size="5px" />
                    <Body1 kind="textBody">
                      Taking a quick mood check! How are you feeling?{' '}
                    </Body1>
                  </div>
                </FlexRowSpaceBetween>
              </>
            )}
          </div>
          {isSubmitted && (
            <div className="px-10 py-6 border-t border-t-[#ededf2] rounded-t-[10px]">
              <NextSubmission
                dueDay={daysLeftToNextCheckin(pulseFrequency as any)}
              />
            </div>
          )}
          {!isSubmitted && (
            <div className="contentDiv">
              <div className="inner-content">
                {moods.map((mood__: {emoji: IEmoji; text: string}, index) => (
                  <div
                    key={index + mood__.text}
                    className="emoji"
                    onClick={() => handleMood(mood__.text, '')}>
                    <div
                      className="halo"
                      style={{
                        background:
                          mood !== null && mood__.text.toLowerCase() === mood
                            ? '#585ADF'
                            : undefined,
                      }}>
                      <div className="img">
                        <Image {...mood__.emoji} />
                      </div>
                    </div>
                    <CTA kind="textMedium">{mood__.text}</CTA>
                  </div>
                ))}
              </div>
              {mood && (
                <>
                  <VerticalSpacer size="24px" />
                  <div className="second-inner-content">
                    <TextField
                      optional
                      placeholder="Share you more information about your mood here."
                      label={`${
                        ['elated', 'happy'].includes(mood as any)
                          ? 'Yay 🎉 '
                          : ''
                      }What made you ${mood} this week?`}
                      value={value.feedback ?? ''}
                      onChange={(e) => {
                        debounceUpdatePulse &&
                          debounceUpdatePulse({
                            pulse: {
                              ...value,
                              feedback: e?.target?.value,
                            },
                            pulseId: value.id,
                          });

                        handleFormValueChange &&
                          handleFormValueChange(
                            {...value, feedback: e?.target?.value},
                            'pulse',
                          );
                      }}
                      name="pulse.feedback"
                      key="feedback"
                      type="text"
                      readOnly={isReadOnly}
                      disabled={workspaceCheckins?.isReviewed || isReadOnly}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </MoodDiv>
        <Helper
          aria-label="helper"
          state={pulseValidation?.error ? 'error' : 'default'}
          children={pulseValidation?.error || ''}
        />
      </>
    );
  },
);
