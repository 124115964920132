import {observer} from 'mobx-react';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useNavigate} from 'react-router';
import {BaseURL} from '../../../../configs/request';
import {timezones} from '../../../../constants';
import {useStoreContext} from '../../../../store/store-context';
import {authStore} from '../../../../store/stores/auth-store';
import {Avatar} from '../../../../ui/atoms/avatar';
import {Button} from '../../../../ui/atoms/button';
import {Image} from '../../../../ui/atoms/image';
import {Label} from '../../../../ui/atoms/label';
import {VerticalSpacer} from '../../../../ui/atoms/spacer';
import {TextTag2} from '../../../../ui/atoms/tag';
import {Headline2} from '../../../../ui/atoms/typography';
import {Flex, FlexRowEnd} from '@ui/style/styles';
import {TextField} from '../../../../ui/molecules/field/textfield';
import {activateNotification} from '../../../../ui/molecules/notification/activate-notification';

import {SelectField} from '../../../../ui/molecules/select/selectfield';
import {PhoneSelectField} from '../../../../ui/molecules/select/phone-select-field';
import {ItemLoader} from '../../../../ui/organisms/item-loader';
import {ItemLoaderLine} from '../../../../ui/organisms/item-loader-line';
import {capitalize} from '../../../../utils';
import {useMemberProfileHook} from '../member-profile-hooks';
import {
  PageLayout,
  LeftWrapper,
  LeftWrapperInnerChild,
  LeftChildSpan,
  LeftChildInput,
  RightWrapper,
  CustomButton,
  RightChildWrapper,
  Wrapper,
  RightChildSpan,
  ButtonWrapper,
  EmailButton,
  CancelButton,
} from '../member-profile-style';
import {phoneCodes} from '../../../../utils/countries';
import {getStrBtwCharacters} from '../../../../utils/strings';
import {MultiOptionsSelectField2} from '../../../../ui/molecules/select/multi-options-2';

export const GroupsMultiselect = memo(
  ({
    control,
    handleSelectChangeGroups,
    group,
    currentUserGroups,
    currentUser,
    feature,
    disabled,
    name,
    label,
    placeholder,
    helper,
    state,
  }: any) => {
    return (
      <Controller
        control={control}
        name={name ? name : 'groups'}
        render={({onBlur, value}) => (
          <MultiOptionsSelectField2
            onBlur={onBlur}
            value={value}
            defaultValue={currentUserGroups?.map((grp: any) => ({
              label: grp.name,
              value: grp.id,
            }))}
            name={name ? name : 'groups'}
            label={
              feature === 'invite'
                ? undefined
                : feature !== 'invite' && label
                ? label
                : 'Group(s)'
            }
            onChange={(data: {value: string; label: string}[]) =>
              handleSelectChangeGroups(data)
            }
            helper={helper}
            state={state}
            placeholder={placeholder ? placeholder : 'Select groups'}
            options={group.filter((grp: any) => !grp.label.includes('General'))}
            disabled={disabled}
          />
        )}
      />
    );
  },
);

export const Personal = observer(() => {
  const {
    register,
    errors,
    isSubmitting,
    isSubmittingEmail,
    submitForm,
    submitEmail,
    handleSelectChange,
    handleSelectChangeGroups,
    handleSubmit,
    control,
    image,
    base64Upload,
    handleChange,
    isDisabled,
    emailRegister,
    emailErrors,
    emailHandleSubmit,
    //currentUser,
    isFetchingCurrentUser,
    group,
    currentUserGroups,
    handleSetPrefix,
  } = useMemberProfileHook();

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = (e: any) => {
    hiddenFileInput?.current?.click();
    e.preventDefault();
  };
  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();
  const {user} = useStoreContext().usersStore;
  const currentUser = user;

  //Upload avatar
  const uploadAvatar = async (e: any) => {
    e.preventDefault();
    let avatarData = {
      avatar: base64Upload,
    };
    setProgress(true);
    await fetch(`${BaseURL}/users/${authStore.auth?.user.id}/upload-avatar`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'content-Type': 'application/json',
        Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
      },
      body: JSON.stringify(avatarData),
    })
      .then((res) => {
        if (res.ok) {
          activateNotification({
            title: 'Success',
            content: 'Avatar uploaded successfully',
            kind: 'success',
          });
        } else {
          activateNotification({
            title: 'Error',
            content: res.statusText,
            kind: 'error',
          });
        }
        return res.json();
      })
      .then((res) => {
        navigate(-1);
      });
  };
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState('');
  const isDisabledEmail =
    data === currentUser?.email || data.length === 0 ? true : false;
  const getEmail = (val: any) => {
    setData(val.target.value);
  };
  const emailChange = (e: any) => {
    e.preventDefault();
    setDisabled(!disabled);
  };
  const noReload = (e: any) => {
    e.preventDefault();
  };

  const [countryCode, setCountryCode] = useState<string>(
    currentUser?.countryCode ? currentUser?.countryCode : '+1',
  );
  const onChangeCountryCode = (prefix: {label: string; value: string}) => {
    setCountryCode(prefix.value);
  };
  useEffect(() => {
    if (currentUser?.countryCode && countryCode !== currentUser?.countryCode) {
      setCountryCode(currentUser?.countryCode);
    }
  }, [countryCode, currentUser?.countryCode]);

  const GroupSelect = useCallback(
    ({control, currentUser, group, handleSelectChangeGroups}) => {
      return (
        <GroupsMultiselect
          control={control}
          handleSelectChangeGroups={handleSelectChangeGroups}
          group={group}
          currentUser={currentUser}
          currentUserGroups={currentUserGroups}
        />
      );
    },
    [currentUserGroups],
  );

  return (
    <>
      {!isFetchingCurrentUser && (
        <PageLayout>
          <Wrapper>
            <RightWrapper>
              <form method="patch" onSubmit={handleSubmit(submitForm)}>
                <Flex alignItems="center" justifyContent="space-between" sm>
                  <Flex alignItems="center">
                    <LeftWrapper>
                      <LeftWrapperInnerChild>
                        {image.preview ? (
                          <Image
                            src={image.preview}
                            alt="preview"
                            width="100"
                            height="100"
                            style={{borderRadius: '50%', objectFit: 'cover'}}
                          />
                        ) : (
                          <>
                            <Avatar
                              alt="Image"
                              name={
                                currentUser?.firstName +
                                ' ' +
                                currentUser?.lastName
                              }
                              size="xl"
                              src={currentUser?.avatar?.url}
                              userId={currentUser?.id}
                              tooltip={true}
                            />
                          </>
                        )}
                        <LeftChildSpan>
                          {image.preview ? (
                            <Button
                              width="xm"
                              kind="primary"
                              onClick={uploadAvatar}
                              style={{padding: '0px 12px', borderRadius: '6px'}}
                              isLoading={progress}
                              aria-busy="true">
                              Save
                            </Button>
                          ) : (
                            <Button
                              width="xm"
                              kind="secondary"
                              onClick={handleClick}
                              style={{
                                padding: '0px 12px',
                                borderRadius: '6px',
                              }}>
                              Edit
                            </Button>
                          )}
                        </LeftChildSpan>
                        <LeftChildInput
                          type="file"
                          ref={hiddenFileInput}
                          id="edit"
                          accept="image/*"
                          onChange={handleChange}
                        />
                        <VerticalSpacer size="8px" />
                      </LeftWrapperInnerChild>
                    </LeftWrapper>

                    <div style={{marginLeft: '10px'}}>
                      <TextTag2 style={{position: 'relative', top: '-3.5px'}}>
                        {currentUser?.role && capitalize(currentUser?.role)}
                      </TextTag2>
                      <Headline2 style={{marginTop: '10px'}}>
                        {currentUser?.firstName && currentUser?.lastName
                          ? capitalize(currentUser?.firstName) +
                            ' ' +
                            capitalize(currentUser?.lastName)
                          : '----- -----'}{' '}
                        &nbsp;
                      </Headline2>
                    </div>
                  </Flex>
                  <FlexRowEnd style={{width: '100%'}}>
                    <CustomButton
                      type="submit"
                      width="sm"
                      disabled={isDisabled || image.disable}
                      data-form-action={true}
                      isLoading={isSubmitting}
                      aria-busy={isSubmitting}>
                      Save changes
                    </CustomButton>
                  </FlexRowEnd>
                </Flex>

                <RightChildWrapper>
                  <RightChildSpan>
                    <VerticalSpacer size="10px" />
                    <TextField
                      label="First Name"
                      placeholder={currentUser?.firstName || 'First Name'}
                      type="text"
                      autoComplete="text"
                      defaultValue={currentUser?.firstName || ''}
                      ref={register}
                      helper={errors.firstName?.message || ''}
                      state={errors.firstName ? 'error' : 'default'}
                      name="firstName"
                    />
                  </RightChildSpan>
                  <RightChildSpan>
                    <VerticalSpacer size="10px" />
                    <TextField
                      label="Last Name"
                      placeholder={currentUser?.lastName || 'Last Name'}
                      defaultValue={currentUser?.lastName || ''}
                      type="text"
                      autoComplete="text"
                      ref={register}
                      helper={errors.lastName?.message || ''}
                      state={errors.lastName ? 'error' : 'default'}
                      name="lastName"
                    />
                  </RightChildSpan>
                  <RightChildSpan>
                    <div style={{marginTop: 20}}>
                      <Label>Phone Number</Label>
                      <Controller
                        control={control}
                        name="countryCode"
                        defaultValue={countryCode}
                        render={({onBlur, value}) => (
                          <PhoneSelectField
                            placeholder="Phone number"
                            name="countryCode"
                            onChange={onChangeCountryCode}
                            defaultValue={countryCode}
                            select={value}
                            selectOptions={phoneCodes
                              .map(
                                (country: {
                                  dial_code: string;
                                  name: string;
                                }) => ({
                                  label: country.name,
                                  value: country.dial_code,
                                }),
                              )
                              .sort((a: any, b: any) =>
                                a.label.localeCompare(b.label),
                              )}
                            setSelect={(prefix: string) => {
                              setCountryCode(prefix);
                              handleSetPrefix(prefix);
                            }}
                            type="phoneNumberDropdownInput"
                            phoneNumberInputInfo={{
                              defaultValue: currentUser?.phoneNumber || '',
                              label: 'Phone Number',
                              placeholder: 'Phone Number',
                              type: 'text',
                              autoComplete: 'text',
                              ref: register,
                              helper: errors.phoneNumber?.message || '',
                              state: errors.phoneNumber ? 'error' : 'default',
                              name: 'phoneNumber',
                            }}
                          />
                        )}
                      />
                    </div>
                  </RightChildSpan>
                  <RightChildSpan>
                    <VerticalSpacer size="20px" />
                    {currentUser?.role === 'admin' ? (
                      <form method="patch" onSubmit={noReload}>
                        <div>
                          <Label>Email Address</Label>
                          {disabled ? (
                            <ButtonWrapper>
                              <EmailButton kind="small" onClick={emailChange}>
                                Change Email
                              </EmailButton>
                            </ButtonWrapper>
                          ) : (
                            <ButtonWrapper>
                              <CancelButton kind="small" onClick={emailChange}>
                                Cancel
                              </CancelButton>
                              <EmailButton
                                kind="small"
                                onClick={emailHandleSubmit(submitEmail)}
                                disabled={isDisabledEmail}
                                data-form-action={true}
                                isLoading={isSubmittingEmail}
                                aria-busy={isSubmittingEmail}>
                                Save
                              </EmailButton>
                            </ButtonWrapper>
                          )}
                        </div>
                        <TextField
                          disabled={disabled}
                          defaultValue={currentUser?.email || ''}
                          placeholder={currentUser?.email || 'Email address'}
                          type="email"
                          onChange={getEmail}
                          autoComplete="email"
                          inputStyle={
                            disabled
                              ? {background: '#FAFAFA', color: '#5F5F8C'}
                              : undefined
                          }
                          ref={emailRegister}
                          helper={emailErrors.email?.message || ''}
                          state={emailErrors.email ? 'error' : 'default'}
                          name="email"
                        />
                      </form>
                    ) : (
                      <>
                        <Label>Email Address</Label>
                        <TextField
                          disabled={disabled}
                          defaultValue={currentUser?.email || ''}
                          placeholder={currentUser?.email || 'Email address'}
                          type="email"
                          onChange={getEmail}
                          autoComplete="email"
                          ref={emailRegister}
                          helper={emailErrors.email?.message || ''}
                          state={emailErrors.email ? 'error' : 'default'}
                          name="email"
                        />
                      </>
                    )}
                  </RightChildSpan>
                  <RightChildSpan>
                    <VerticalSpacer size="20px" />
                    <TextField
                      defaultValue={
                        currentUser?.jobTitle
                          ? capitalize(currentUser?.jobTitle)
                          : ''
                      }
                      label="Job Title"
                      placeholder={
                        currentUser?.jobTitle
                          ? capitalize(currentUser?.jobTitle)
                          : 'Eg. Software developer'
                      }
                      type="text"
                      autoComplete="text"
                      ref={register}
                      helper={errors.jobTitle?.message || ''}
                      state={errors.jobTitle ? 'error' : 'default'}
                      name="jobTitle"
                    />
                  </RightChildSpan>
                  <RightChildSpan>
                    <VerticalSpacer size="20px" />
                    {group.length === 0 && <ItemLoaderLine />}
                    {group.length > 0 && !!currentUserGroups && (
                      <GroupSelect
                        control={control}
                        handleSelectChangeGroups={handleSelectChangeGroups}
                        group={group}
                        currentUser={currentUser}
                      />
                    )}
                  </RightChildSpan>
                  <RightChildSpan>
                    <VerticalSpacer size="20px" />

                    <Label>Time zone</Label>

                    <Controller
                      control={control}
                      name="timezone"
                      defaultValue={currentUser?.timezone}
                      render={({onBlur, value}) => (
                        <SelectField
                          name="timezone"
                          placeholder="Select a time zone"
                          value={value}
                          label="Time Zone"
                          options={timezones.map(
                            (timezone: {label: string; value: string}) => ({
                              label: `${timezone?.label} (${getStrBtwCharacters(
                                timezone?.value,
                                '(',
                                ')',
                              )})`,
                              value: timezone?.value,
                            }),
                          )}
                          helper={errors.timezone?.message || ''}
                          state={errors.timezone ? 'error' : 'default'}
                          onChange={(data: {value: string}) => {
                            data !== null && handleSelectChange(data.value);
                          }}
                        />
                      )}
                    />
                  </RightChildSpan>
                </RightChildWrapper>
              </form>
            </RightWrapper>
          </Wrapper>
        </PageLayout>
      )}
      {isFetchingCurrentUser && <ItemLoader />}
    </>
  );
});
