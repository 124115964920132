import {AgendaTemplateResponse, MeetingBotResponse} from '@hooks';
import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../utils/error-handling';

import {MeetingRequestImpl} from './meeting.request';
export class MeetingController {
  constructor(private readonly request: MeetingRequestImpl) {}

  async postMeeting(data: any) {
    try {
      const response = await this.request.postMeeting(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async getMeetings(filters: any) {
    try {
      const response = await this.request.getMeetings(filters);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async getMeetingsSummary(filters: any) {
    try {
      const response = await this.request.getMeetingsSummary(filters);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async getMeetingTags(filter?: {startDate?: string; endDate?: string}) {
    try {
      const response = await this.request.getMeetingTags(filter);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async getAgendaTemplates(
    limit?: number,
  ): Promise<AgendaTemplateResponse | undefined> {
    try {
      const response = await this.request.getAgendaTemplates(limit);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
  async getMeeting(id: string, startMeeting?: boolean, hideNotif?: boolean) {
    try {
      const response = await this.request.getMeeting(id, startMeeting);
      return response;
    } catch (e: any) {
      if (!hideNotif)
        activateNotification({
          title: 'Error',
          content: parseErrorContent(e),
          kind: 'error',
        });
      return false;
    }
  }
  async getMeetingBotResponse(id: string): Promise<MeetingBotResponse | false> {
    try {
      const response = await this.request.getMeetingBotResponse(id);
      return response;
    } catch (e: any) {
      return false;
    }
  }

  async getMeetingBotData(
    id: string,
  ): Promise<
    | {id: string; status_changes: {code: string; sub_code: null | string}[]}
    | false
  > {
    try {
      const response = await this.request.getMeetingBotData(id);
      return response;
    } catch (e: any) {
      return false;
    }
  }
  async updateMeetingBotResponse(
    id: string,
    data: any,
  ): Promise<MeetingBotResponse | boolean> {
    try {
      const response = await this.request.updateMeetingBotResponse(id, data);
      return response;
    } catch (e: any) {
      // if (!hideNotif)
      // activateNotification({
      //   title: 'Error',
      //   content: parseErrorContent(e),
      //   kind: 'error',
      // });
      return false;
    }
  }
  async deleteMeeting(id: string) {
    try {
      const response = await this.request.deleteMeeting(id);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async patchMeeting(data: any, meetingId: string) {
    try {
      const response = await this.request.patchMeeting(data, meetingId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchOneOnOneReporting(data: any) {
    try {
      const response = await this.request.fetchOneOnOneReporting(data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return [];
    }
  }
}
