import React, {memo} from 'react';
import styled from 'styled-components';
import {getColorFromTheme} from '../../ui-utils';

const Green = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: ${getColorFromTheme('green400')};
`;

const Orange = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: ${getColorFromTheme('orange300')};
`;
const AboveStretch = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: #dbeaf7;
`;
const Red = styled.span<{margin: any; width?: string; height?: string}>`
  display: inline-block;
  border-radius: 10px;
  width: ${(props: any) => (props.width ? props.width : '10px')};
  height: ${(props: any) => (props.height ? props.height : '10px')};
  margin-right: ${(props: any) => props.margin}px;
  background-color: ${getColorFromTheme('red400')};
`;

const Purple300 = styled.span<{margin: any; width?: string; height?: string}>`
  display: inline-block;
  border-radius: 10px;
  width: ${(props: any) => (props.width ? props.width : '10px')};
  height: ${(props: any) => (props.height ? props.height : '10px')};
  margin-right: ${(props: any) => props.margin}px;
  background-color: ${getColorFromTheme('purple300')};
`;
const CustomColor = styled.span<{
  margin: any;
  width?: string;
  height?: string;
  color: string;
}>`
  display: block;
  border-radius: 100px;
  width: ${(props: any) => (props.width ? props.width : '10px')};
  height: ${(props: any) => (props.height ? props.height : '10px')};
  margin-right: ${(props: any) => props.margin}px;
  background-color: ${({color}) => color};
`;

const AtRisk = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: #f39c9a;
`;
const Blue = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: ${getColorFromTheme('blue500')};
`;
const NoPie = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: #e5e5e5;
`;

const SkyBlue = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: ${getColorFromTheme('blue200')};
`;

const Disable = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: ${getColorFromTheme('borderLight')};
`;

const DisableDark = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: ${getColorFromTheme('borderDark')};
`;
const PieDark = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: #28284d;
`;

const Behind = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: #ffd5ad;
`;
const PiePurple = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: #585adf;
`;
const PieBlue = styled.span<{margin: any}>`
  display: inline-block;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: ${(props: any) => props.margin.margin}px;
  background-color: #e4e5fb;
`;

export const ColorIconGreen = (margin?: any) => {
  return (
    <>
      <Green margin={margin} />
    </>
  );
};

export const ColorIconOrange = (margin?: any) => {
  return (
    <>
      <Orange margin={margin} />
    </>
  );
};

export const ColorIconRed = ({
  width,
  height,
  margin,
}: {
  width?: string;
  height?: string;
  margin?: string | number;
}) => {
  return (
    <>
      <Red margin={margin} width={width} height={height} />
    </>
  );
};

export const ColorIconBlue = (margin?: any) => {
  return (
    <>
      <Blue margin={margin} />
    </>
  );
};
export const ColorBehind = (margin?: any) => {
  return (
    <>
      <Behind margin={margin} />
    </>
  );
};

export const ColorAboveStretch = (margin?: any) => {
  return (
    <>
      <AboveStretch margin={margin} />
    </>
  );
};

export const ColorPurple300 = ({
  width,
  height,
  margin,
}: {
  width: string;
  height: string;
  margin: number;
}) => {
  return (
    <>
      <Purple300 margin={margin} width={width} height={height} />
    </>
  );
};
export const CustomColorIcon = memo(
  ({
    width,
    height,
    margin,
    color,
  }: {
    width: string;
    color: string;
    height: string;
    margin: number;
  }) => {
    return (
      <>
        <CustomColor
          margin={margin}
          width={width}
          height={height}
          color={color}
        />
      </>
    );
  },
);

export const ColorNoPie = (margin?: any) => {
  return (
    <>
      <NoPie margin={margin} />
    </>
  );
};

export const ColorIconSkyBlue = (margin?: any) => {
  return (
    <>
      <SkyBlue margin={margin} />
    </>
  );
};
export const ColorIconPieDark = (margin?: any) => {
  return (
    <>
      <PieDark margin={margin} />
    </>
  );
};
export const ColorIconPieBlue = (margin?: any) => {
  return (
    <>
      <PieBlue margin={margin} />
    </>
  );
};
export const ColorIconPiePurple = (margin?: any) => {
  return (
    <>
      <PiePurple margin={margin} />
    </>
  );
};

export const ColorIconDisable = (margin?: any) => {
  return (
    <>
      <Disable margin={margin} />
    </>
  );
};

export const ColorAtRisk = (margin?: any) => {
  return (
    <>
      <AtRisk margin={margin} />
    </>
  );
};

export const ColorIconDisableDark = (margin?: any) => {
  return (
    <>
      <DisableDark margin={margin} />
    </>
  );
};
