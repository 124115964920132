import {memo, SVGProps} from 'react';

export const CalenderIcon = memo((props: SVGProps<any>) => {
  return (
    <>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5719 2.66406H4.23861C3.50223 2.66406 2.90527 3.26102 2.90527 3.9974V13.3307C2.90527 14.0671 3.50223 14.6641 4.23861 14.6641H13.5719C14.3083 14.6641 14.9053 14.0671 14.9053 13.3307V3.9974C14.9053 3.26102 14.3083 2.66406 13.5719 2.66406Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...props}
        />
        <path
          d="M11.5732 1.33594V4.0026"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...props}
        />
        <path
          d="M6.2373 1.33594V4.0026"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...props}
        />
        <path
          d="M2.90527 6.66406H14.9053"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          {...props}
        />
      </svg>
    </>
  );
});
