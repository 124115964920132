import {CustomColorIcon} from '@ui/atoms/color-icons';
import {ArrowDownIcon} from '@ui/atoms/icons';
import {Body2} from '@ui/atoms/typography';
import {FlexRow} from '@ui/style/styles';
import {useGCalenderHook} from '@hooks/gcalender';
import dayjs from 'dayjs';
import React, {useState, useMemo, useEffect} from 'react';
import {useQueries} from 'react-query';
import {ItemLoader} from '@ui/organisms/item-loader';

interface Calendar {
  calendarId: string;
  meetingId?: string;
  isPrimary: boolean;
  startDate: string;
  title: string;
  _id: string;
}

interface EnhancedCalendar extends Calendar {
  notAttending?: boolean;
  isCanceled?: boolean;
  userAttendance?: string;
}

interface MeetingViewProps {
  meetings?: Calendar[];
  onClickMeeting?: (meeting: EnhancedCalendar) => void;
  selectedMeeting?: string;
  primaryMeeting: Calendar;
  defaultTitle?: string;
  isLoading?: boolean;
}

const MeetingView: React.FC<MeetingViewProps> = ({
  onClickMeeting,
  meetings = [],
  selectedMeeting,
  defaultTitle = '',
  isLoading: _isLoading = false,
  primaryMeeting,
}) => {
  const [isActive, setActive] = useState<string | null>(
    selectedMeeting || null,
  );

  useEffect(() => {
    if (selectedMeeting) setActive(selectedMeeting);
  }, [selectedMeeting]);

  const {getCalendarEventById, getEmail} = useGCalenderHook();

  // Query for user's email
  const emailQuery = useQueries([
    {
      queryKey: ['user-email'],
      queryFn: getEmail,
      staleTime: Infinity,
    },
  ])[0];

  // Queries for each meeting with calendarId
  const meetingQueries = useQueries(
    meetings
      .filter((meeting) => meeting.calendarId)
      .map((meeting) => ({
        queryKey: ['meeting-details', meeting.calendarId],
        queryFn: () => getCalendarEventById(meeting.calendarId, 'primary'),
        enabled: !!meeting.calendarId,
      })),
  );

  // Process meetings with fetched data
  const enhancedMeetings: EnhancedCalendar[] = useMemo(() => {
    const connectedEmail = emailQuery.data;

    const processedMeetings = meetings.map((meeting) => {
      if (!meeting.calendarId) return meeting;

      const meetingQuery = meetingQueries.find(
        (query) => query.data?.result.id === meeting.calendarId,
      );

      if (!meetingQuery?.data) return meeting;

      const event = meetingQuery.data.result;

      if (!event) return meeting;

      const currentUserAttendance =
        event.attendees?.find(
          (attendee: {email: string}) => attendee.email === connectedEmail,
        )?.responseStatus || 'needsAction';

      return {
        ...meeting,
        notAttending: currentUserAttendance === 'declined',
        isCanceled: event.status === 'cancelled',
        userAttendance: currentUserAttendance,
      };
    });

    return processedMeetings
      .sort((a, b) => {
        return dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? 1 : -1;
      })
      .sort((a, b) => {
        // Primary meeting goes first
        if (a.isPrimary) return 1;
        if (b.isPrimary) return -1;

        return 1;
      });
  }, [meetings, meetingQueries, emailQuery.data]);

  // Loading state
  const isLoading =
    emailQuery.isLoading ||
    meetingQueries.some((query) => query.isLoading) ||
    _isLoading;

  const titles = [primaryMeeting.title, defaultTitle];

  return (
    <div className="rounded-[10px] border border-borderLight">
      <div className="p-4">
        <div className="flex items-center justify-center">
          <Body2 weight="semibold">Series history</Body2>
          <ArrowDownIcon />
        </div>
      </div>

      <div className="bg-backgroundLight border-t border-t-borderLight overflow-scroll flex sm:flex-row flex-col p-2 gap-4 rounded-[10px]">
        {isLoading ? (
          <div className="flex justify-center items-center w-full p-4">
            <ItemLoader />
          </div>
        ) : enhancedMeetings.length > 0 ? (
          enhancedMeetings.map((meeting, index) => (
            <div
              key={meeting._id || index}
              className={`py-2 px-3 rounded-lg cursor-pointer 
                ${
                  isActive &&
                  [meeting.calendarId, meeting.meetingId].includes(isActive)
                    ? 'bg-white shadow-sm'
                    : ' hover:bg-white'
                }
                border border-borderLight `}
              onClick={() => {
                setActive(meeting.meetingId || meeting.calendarId);
                onClickMeeting?.(meeting as EnhancedCalendar);
              }}>
              <Body2
                weight="semibold"
                kind={
                  isActive &&
                  [meeting.calendarId, meeting.meetingId].includes(isActive)
                    ? 'textDark'
                    : 'textBody'
                }
                className={meeting.notAttending ? 'line-through' : ''}>
                {titles.includes(meeting.title) && Boolean(meeting.meetingId)
                  ? dayjs(meeting.startDate).format('ddd. DD MMM')
                  : meeting.title}
              </Body2>

              <FlexRow className="gap-3 mt-3">
                {meeting.isCanceled ? (
                  <Body2 kind="red400" style={{fontSize: '12px'}}>
                    Cancelled
                  </Body2>
                ) : (
                  <>
                    {titles.includes(meeting.title) &&
                    Boolean(meeting.meetingId) ? null : (
                      <Body2
                        kind="textBody"
                        className={`whitespace-nowrap `}
                        style={{fontSize: '12px'}}>
                        {dayjs(meeting.startDate).format('ddd. DD MMM')}
                      </Body2>
                    )}

                    {meeting.startDate && (
                      <>
                        {titles.includes(meeting.title) &&
                        Boolean(meeting.meetingId) ? null : (
                          <CustomColorIcon
                            color="#D9D9D9"
                            width="8px"
                            height="8px"
                            margin={0}
                          />
                        )}

                        <Body2
                          kind="textBody"
                          className={`whitespace-nowrap `}
                          style={{fontSize: '12px'}}>
                          {dayjs(meeting.startDate).format('hh:mm a')}
                        </Body2>
                      </>
                    )}
                  </>
                )}
              </FlexRow>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500 my-4">
            No meetings available
          </p>
        )}
      </div>
    </div>
  );
};

export default MeetingView;
