import {useMemo} from 'react';
import {useListKeys, useListVals} from 'react-firebase-hooks/database';
import {ref, query, orderByChild, equalTo} from 'firebase/database';
import dayjs from 'dayjs';
import {database} from '@utils/firebase-request';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {useStoreContext} from '@store/store-context';
import {ActionType} from '@api/action-items-api/action-items-request';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import {activateNotification} from '@ui/molecules/notification/activate-notification';

interface ActionItemsHookProps {
  itemId: string;
  source: string;
  userId: string;
  callback?: any;
  orderBy?: {
    key: string;
    value: string;
  };
  fetchAll?: boolean;
}

export const useActionItems = ({
  itemId,
  source,
  userId,
  orderBy,
  callback,
  fetchAll = false,
}: ActionItemsHookProps) => {
  const {
    authStore,
    usersStore: {users},
  } = useStoreContext();

  const actionItemsPath = useMemo(
    () =>
      `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/action_items/`,
    [authStore.auth.user.workspace.id],
  );

  const databaseRef = useMemo(() => ref(database, actionItemsPath), [
    actionItemsPath,
  ]);

  const baseQuery = useMemo(() => {
    if (fetchAll) {
      return databaseRef;
    }

    if (orderBy) {
      return query(
        databaseRef,
        orderByChild(orderBy.key),
        equalTo(orderBy.value),
      );
    }

    if (userId) {
      query(databaseRef, orderByChild('assignee'), equalTo(userId));
      return query(databaseRef, orderByChild('user'), equalTo(userId));
    }

    return databaseRef;
  }, [databaseRef, fetchAll, orderBy, userId]);

  const [values, loading, error] = useListVals(baseQuery);
  const [keys] = useListKeys(baseQuery);

  const tasks = useMemo(() => {
    if (!values || !keys) return [];

    return values
      .map((item: any, index) => ({
        ...item,
        firebaseId: keys[index],
        assignee: users.find((user) => user.id === item.assignee),
        key: item.id || item._id || item.key || '',
      }))
      .sort((a, b) => a.order - b.order);
  }, [values, keys, users]);

  const computeActionItem = (data: any, removeSource?: boolean) => {
    const assignee = data?.assignee;
    const id = data?.id || data?.key || generateRandomDigits(24);
    const existingTask = tasks.find((task) => task.key === id);

    return {
      completed: data.completed || false,
      id,
      meta: removeSource ? null : data?.meta || existingTask?.meta || null,
      text: data.text,
      source: removeSource ? '' : data.source || existingTask?.source || source,
      completedAt:
        existingTask?.completedAt || (data.completed ? dayjs().format() : ''),
      sourceId: removeSource
        ? ''
        : data.sourceId || existingTask?.sourceId || itemId,
      section: data?.section ?? existingTask?.section ?? '',
      meetingTag: data?.meetingTag || existingTask?.meetingTag || null,
      updatedBy: data.updatedBy || authStore.auth.user.id,
      createdBy:
        existingTask?.createdBy || data.createdBy || authStore.auth.user.id,
      order: data?.order || existingTask?.order || null,
      dueDate: data?.dueDate || null,
      createdAt: existingTask?.createdAt || data?.createdAt || dayjs().format(),
      user: data?.user || existingTask?.userId || userId,
      assignee: typeof assignee === 'string' ? assignee : assignee?.id || null,
    };
  };

  const handleChange = async (
    data: ActionType[],
    action?: 're-sort' | 'user' | 'dueDate' | '' | 'remove' | 'remove-link',
    fieldId?: string,
    item?: ActionType,
  ) => {
    try {
      if (action === 're-sort') {
        await Promise.all(
          data
            .filter((value) => !!value.text)
            .map((value, idx) => {
              const computedValue = computeActionItem({
                ...value,
                order: idx + 1,
              });
              const existingTask = tasks.find((task) => task.key === value.key);

              existingTask
                ? updateFirebaseData(
                    `action_items/${existingTask.firebaseId}`,
                    computedValue,
                  )
                : writeFirebaseData('action_items', computedValue);

              return null;
            }),
        );
        callback?.(
          data.map((value, idx) =>
            computeActionItem({...value, order: idx + 1}),
          ),
        );
        return;
      }

      const existingTask = tasks.find((task) => task.key === fieldId);

      if (action === 'remove' && existingTask) {
        await removeFirebaseData(`action_items/${existingTask.firebaseId}`);
        return;
      }

      if (item) {
        const actionItem = computeActionItem(item, action === 'remove-link');

        if (existingTask) {
          await updateFirebaseData(
            `action_items/${existingTask.firebaseId}`,
            actionItem,
          );
        } else if (actionItem.text) {
          await writeFirebaseData('action_items', actionItem);
        }
      }
    } catch (error) {
      activateNotification({
        title: 'Error',
        content: 'Something went wrong',
        kind: 'error',
      });
    }
  };

  return {
    handleChange,
    isLoading: loading,
    error,
    tasks,
  };
};
