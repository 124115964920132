import {ComponentPropsWithRef, forwardRef, ChangeEvent, ReactNode} from 'react';
import {
  Input,
  Label,
  Wrapper,
  SecondaryWrapper,
  SecondaryInput,
} from '../shared';
import {NumericFormat} from 'react-number-format';
import {InputState} from '../../../interface';
import styled from 'styled-components';
import {HashIcon} from '../../icons';

export interface TNumberInputProps extends ComponentPropsWithRef<'input'> {
  state?: InputState;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
}
type inputType = string | number | undefined;
const Icon = styled.span`
  z-index: 2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    stroke: #5f5f8c;
  }
`;
const LabelX = styled(Label)`
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NumberInput = forwardRef(
  ({
    id,
    disabled,
    state,
    value,
    defaultValue,
    icon,
    onChange,
    ...props
  }: TNumberInputProps) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value.replaceAll(',', '');
      event.target.value = number;

      onChange && onChange(event);
    };
    return (
      <Wrapper htmlFor={id} disabled={disabled} state={state}>
        <LabelX disabled={disabled}>
          <Icon>{icon || <HashIcon />}</Icon>
        </LabelX>
        <NumericFormat
          value={value as inputType}
          defaultValue={defaultValue as inputType}
          onChange={handleChange}
          {...props}
          disabled={disabled}
          customInput={Input}
          type={'text'}
          thousandSeparator=","
        />
      </Wrapper>
    );
  },
);
export const SecondaryNumberInput = forwardRef(
  ({
    id,
    disabled,
    state,
    value,
    defaultValue,
    onChange,
    ...props
  }: TNumberInputProps) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const number = event.target.value.replaceAll(',', '');
      event.target.value = number;

      onChange && onChange(event);
    };

    return (
      <SecondaryWrapper htmlFor={id} disabled={disabled} state={state}>
        <NumericFormat
          value={value as inputType}
          defaultValue={defaultValue as inputType}
          onChange={handleChange}
          {...props}
          disabled={disabled}
          customInput={SecondaryInput}
          type={'text'}
          thousandSeparator=","
        />

        <LabelX disabled={disabled} borderRadius="0px 7px 7px 0px">
          <Icon>
            <HashIcon />
          </Icon>
        </LabelX>
      </SecondaryWrapper>
    );
  },
);
NumberInput.displayName = 'NumberInput';
