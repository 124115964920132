import {BaseRequest, SSEFetch} from '../../configs/request';
import {FetchGoalResponse} from '../../pages/create-goal/create-goal-interface';
import {authStore} from '../../store/stores/auth-store';

interface IGoalsRequest extends BaseRequest {
  fetchGoals(pageNumber?: number): Promise<any>;
  fetchActiveParentGoals(
    pageNumber?: number,
    searchTerm?: string,
  ): Promise<any>;
  fetchGoalById(id: string): Promise<FetchGoalResponse>;
  fetchGoalTasksById(id: string): Promise<FetchGoalResponse>;
  updateGoalTasksById(id: string, data: any): Promise<FetchGoalResponse>;
  getKeyResult(id: string): Promise<any>;
  fetchAllGoals(
    filter: any,
    pageNumber: number,
    pageLimit?: number,
  ): Promise<any>;
  fetchAllKeyResults(
    filter: any,
    pageNumber: number,
    pageLimit?: number,
  ): Promise<any>;
  archiveGoal(data: any, goalId: string): Promise<any>;
  restoreGoal(data: any, goalId: string): Promise<any>;
  sseGoalListener(stream: any): Promise<any>;
}

export class GoalsRequestImpl extends BaseRequest implements IGoalsRequest {
  url: string = '/goals';

  private requestHeader = {
    headers: {
      Authorization: `Bearer ${authStore?.auth?.tokens.access.token}`,
    },
    useCache: false,
  };

  async updateGoal(goal: any, goalId: string) {
    const {data: response} = await this.request.patch(
      `${this.url}/${goalId}`,
      {
        assignee:
          typeof goal?.assignee === 'object'
            ? goal?.assignee?.id
            : goal?.assignee,
        endDate: goal?.endDate,
        measurement: goal?.measurement,
        name: goal?.name,
        performance: goal?.performance,
        startDate: goal?.startDate,
        completedAt: undefined,
        currentValue: goal?.isKpi
          ? goal?.value || goal?.currentValue
          : goal?.currentValue,
        startValue: goal?.startValue,
        value: goal?.valueUpdated ? goal?.value : undefined,
        isUpdate: goal?.isUpdate || undefined,
        context: goal?.context || undefined,
        isKpi: goal?.isKpi,
        goalType: goal?.goalType,
        goalAlignment: goal?.hasOwnProperty('goalAlignment')
          ? goal?.goalAlignment
          : undefined,
        goalMeasurement: goal?.goalMeasurement || undefined,
        percentageProgress: undefined,
        group: goal?.hasOwnProperty('group')
          ? goal?.group.map((group: {id: string} | string) =>
              typeof group === 'string' ? group : group.id,
            )
          : undefined,
        targetValue: goal?.targetValue,
        keyResults: goal?.keyResults?.map((keyResult: any) => ({
          ...keyResult,
          goal: undefined,
          createdBy: undefined,
          workspace: undefined,
          alignedKeyResults: undefined,
          updatedBy: undefined,
          createdAt: undefined,
          completedAt: undefined,
          updatedAt: undefined,
          percentageProgress: undefined,
          performance: undefined,
          currentValue: keyResult?.currentValue,
          status: undefined,
          measurement:
            keyResult?.measurement?.unit === 'currency' &&
            !keyResult?.measurement?.hasOwnProperty('symbol')
              ? {...keyResult?.measurement, symbol: 'USD'}
              : keyResult?.measurement,
          assignee:
            typeof keyResult?.assignee === 'object'
              ? keyResult?.assignee?.id
              : keyResult?.assignee,
        })),
      },

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async updateKeyResult(keyResult: any, keyResultId: string) {
    const {data: response} = await this.request.patch(
      `${this.url}/kpis/${keyResultId}`,
      {
        ...keyResult,
        goal: undefined,
        createdBy: undefined,
        workspace: undefined,
        updatedBy: undefined,
        createdAt: undefined,
        completedAt: undefined,
        updatedAt: undefined,
        percentageProgress: undefined,
        id: undefined,
        performance: undefined,
        currentValue:
          keyResult?.currentValue < keyResult?.startValue
            ? keyResult?.startValue
            : keyResult?.currentValue,
        status: undefined,
        measurement:
          keyResult?.measurement?.unit === 'currency' &&
          !keyResult?.measurement?.hasOwnProperty('symbol')
            ? {...keyResult?.measurement, symbol: 'USD'}
            : keyResult?.measurement,
        assignee:
          typeof keyResult?.assignee === 'object'
            ? keyResult?.assignee?.id
            : keyResult?.assignee,
        alignmentTarget: keyResult?.alignmentTarget || undefined,
        keyResultAlignment: keyResult?.keyResultAlignment
          ? {
              keyResult: keyResult?.keyResultAlignment?.keyResult || undefined,
              value: keyResult?.keyResultAlignment?.value || undefined,
            }
          : undefined,
      },

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async getKeyResult(id: string) {
    const {data: response} = await this.request.get(
      `${this.url}/kpis/${id}?include=assignee`,

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async endGoal(data: any, goalId: string) {
    const {data: response} = await this.request.post(
      `${this.url}/${goalId}/end`,
      data,

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async revertUpdate(goalId: string) {
    const {data: response} = await this.request.post(
      `${this.url}/${goalId}/revert-update`,
      null,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  async archiveGoal(data: any, goalId: string) {
    const {data: response} = await this.request.post(
      `${this.url}/${goalId}/archive-goal`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async restoreGoal(data: any, goalId: string) {
    const {data: response} = await this.request.post(
      `${this.url}/${goalId}/restore-goal`,
      data,

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }
  async reactivateGoal(goalId: string) {
    const {data: response} = await this.request.post(
      `${this.url}/${goalId}/reactivate-goal`,
      {},

      {
        headers: {
          Authorization: `Bearer ${authStore.auth?.tokens.access.token}`,
        },
      },
    );
    return response;
  }

  //HACKY WORKAROUND //TODO: Delete this hacky workaround and uncomment above after BE fix
  async fetchGoals(
    pageNumber?: number,
    filter?: string,
    limit?: number,
    date?: {startDate: string; endDate: string},
    goalName?: string | null,
    userId?: string,
    extras?: {
      isKpi?: boolean;
      sortBy?: string;
    },
  ) {
    const id = userId || authStore?.auth?.user?.id;

    const urlUserActiveGoals = pageNumber
      ? `/users/${id}/goals/active?page=${pageNumber}&include=assignee${
          limit ? `&limit=${limit}` : ''
        }${date?.startDate ? `&startDate=${date?.startDate}` : ''}${
          date?.endDate ? `&endDate=${date?.endDate}` : ''
        }${goalName ? `&name=${goalName}` : ''}`
      : `/users/${id}/goals/active?include=assignee`;

    const urlUserActiveCheckinsGoals = pageNumber
      ? `/users/${id}/goals/active?page=${pageNumber}&include=assignee${
          limit ? `&limit=${limit}` : ''
        }`
      : `/users/${id}/goals/active?limit=1000000&include=assignee`;

    const urlUpcomingGoals = pageNumber
      ? `/users/${id}/goals/upcoming?include=assignee`
      : `/users/${id}/goals/upcoming?include=assignee`;

    const urlArchivedGoals = pageNumber
      ? `/goals/archived-goals?include=assignee&user=${id}&sortBy=createdAt:${
          extras?.sortBy || 'desc'
        }${extras?.isKpi ? '&isKpi=true' : '&isKpi=false'}`
      : `/goals/archived-goals?include=assignee&user=${id}`;

    const networkLvlActiveGoals = pageNumber
      ? `/goals/active?page=${pageNumber}&limit=1000000&include=assignee`
      : '/goals/active?limit=1000000&include=assignee';

    const networkLvlAllGoals = pageNumber
      ? `/goals?page=${pageNumber}&limit=1000000&include=assignee`
      : '/goals?limit=1000000&include=assignee';

    const {data: response} = await this.request.get(
      filter === 'upcoming'
        ? urlUpcomingGoals
        : filter === 'archived'
        ? urlArchivedGoals
        : filter === 'networkLevel'
        ? networkLvlActiveGoals
        : filter === 'networkAllLevel'
        ? networkLvlAllGoals
        : filter === 'activeCheckins'
        ? urlUserActiveCheckinsGoals
        : urlUserActiveGoals,
      this.requestHeader,
    );
    return response;
  }

  async fetchAllGoals(filters: any, pageNumber: number, pageLimit?: number) {
    const {
      startDate,
      endDate,
      goalFilter,
      member,
      paginate,
      isKpi,
      manager,
      view,
      sortBy,
      name,
    } = filters;

    if (goalFilter === 'archived') {
      return await this.fetchGoals(
        pageNumber,
        'archived',
        pageLimit,
        {startDate, endDate},
        name,
        member,
        {
          isKpi,
          sortBy,
        },
      );
    }

    const isUpcoming = goalFilter === 'upcoming';
    const {data: response} = await this.request.get(
      `${this.url}?${startDate && !isUpcoming ? `startDate=${startDate}` : ''}${
        endDate && !isUpcoming ? `&endDate=${endDate}` : ''
      }${
        goalFilter === 'upcoming'
          ? '&status=pending'
          : goalFilter === 'ended'
          ? '&status=ended'
          : goalFilter === 'active'
          ? '&status=active,past_due'
          : ''
      }${member?.length > 0 ? `&user=${member}` : ''}${
        name ? `&name=${name}` : ''
      }&sortBy=createdAt:${sortBy || 'desc'}${
        manager ? `&manager=${manager}` : ''
      }${isKpi ? '&isKpi=true' : '&isKpi=false'}${
        view === 'tree' || paginate === false ? '&paginate=false' : ''
      }${pageNumber ? `&page=${pageNumber}` : ''}&include=assignee${
        pageLimit ? `&limit=${pageLimit}` : ''
      }`,
      this.requestHeader,
    );
    return response;
  }

  async fetchAllKeyResults(
    filters: any,
    pageNumber: number,
    pageLimit?: number,
  ) {
    const {goalFilter, member, manager, view} = filters;
    const {data: response} = await this.request.get(
      `${this.url}/kpis?include=assignee,goal${
        goalFilter === 'upcoming'
          ? '&status=pending'
          : goalFilter === 'ended'
          ? '&status=ended'
          : goalFilter === 'active'
          ? '&status=active'
          : ''
      }${member?.length > 0 ? `&user=${member}` : ''}${
        manager ? `&manager=${manager}` : ''
      }${view === 'tree' ? '&paginate=false' : ''}${
        pageNumber ? `&page=${pageNumber}` : ''
      }${pageLimit ? `&limit=${pageLimit}` : ''}`,
      this.requestHeader,
    );
    return response;
  }

  async fetchActiveParentGoals(pageNumber?: number, searchTerm?: string) {
    const {data: response} = await this.request.get(
      '/goals/active?parent_goals=true',
      this.requestHeader,
    );
    return response;
  }

  async fetchGoalById(id: string) {
    const {data: response} = await this.request.get(
      `${this.url}/${id}?include=assignee`,
      this.requestHeader,
    );
    return response;
  }

  async fetchGoalTasksById(id: string) {
    const {data: response} = await this.request.get(
      `${this.url}/${id}/tasks`,
      this.requestHeader,
    );
    return response;
  }
  async updateGoalTasksById(id: string, data: any) {
    const {data: response} = await this.request.patch(
      `${this.url}/${id}/tasks`,
      data,
      this.requestHeader,
    );
    return response;
  }

  async deleteGoal(id: string) {
    const {data: response} = await this.request.delete(
      `${this.url}/${id}`,
      this.requestHeader,
    );
    return response;
  }
  async sseGoalListener(stream: any) {
    const response = await SSEFetch({
      method: 'GET',
      url: '/goal-update-sse',
      headers: {
        Authorization: `Bearer ${authStore.auth?.tokens.refresh.token}`,
      },
      ...stream,
    });
    return response;
  }
}
