import {
  forwardRef,
  Fragment,
  ReactElement,
  Ref,
  useCallback,
  useState,
} from 'react';
import ReactSelect, {ValueType, ActionMeta} from 'react-select';
import {useDropDown} from '../../../../hooks';
import {motion} from 'framer-motion';
import {phoneCodes} from '../../../../utils/countries';

import {getColorFromTheme} from '../../../ui-utils';
import styled from 'styled-components';
import {Flex} from '../../../style/styles';
import {flagList} from '../../../assets/flags';
import {Image} from '../../../atoms/image';
import {ArrowIconWrapper} from '../phone-select-field/styled';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {Body1} from '../../../atoms/typography';
import {SelectFieldSecondary} from './secondary-option';
import {InputState} from '../../../interface';

import {SelectFieldProps, TOption} from '../option-select/option-select';

const DropdownWrapper = styled.div`
  position: absolute;
  display: block;
  width: 400px;
  top: 120%;
  border-radius: 8px;
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: white;
  box-shadow: ${({theme}) => theme.elevation.level2};
  z-index: 3;
  @media (max-width: 500px) {
    width: 250%;
  }
`;
const HeaderWrapper = styled.button<{state: string}>`
  background: #f6f6f8;
  border-width: 1px;
  border-style: solid;

  position: relative;
  height: 56px;
  padding: 0 10px;
  min-width: 100px;
  border-color: ${({state}) =>
    state === 'error' ? getColorFromTheme('red400') : '#cecede'};
  border-radius: 10px 0px 0px 10px;
`;

export interface TUserSelectFieldProps extends SelectFieldProps {
  label?: string;
  placeholder?: string;
  state?: InputState;
  helper?: string | ReactElement;
}

export const PhoneSelectFieldOption = forwardRef(
  (
    {
      state,
      options,
      onChange,
      value,
      defaultValue,
      fieldNotFoundPlaceHolder,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const getValue = options.find(
      // @ts-ignore
      (option) => option.value === defaultValue || option.value === value,
    );
    const [innerValue, setInnerValue] = useState<ValueType<TOption, false>>(
      // @ts-ignore
      () => getValue,
    );
    const [selectedValue, setSelectedValue] = useState<{
      code: string;
      dial_code: string;
      flag: string;
      name: string;
    }>();
    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();

    const onSelectChange = useCallback(
      (value: ValueType<TOption, false>, action: ActionMeta<TOption>) => {
        if (onChange) {
          onChange(value, action);
        }
        setInnerValue(value);
        setSelectedValue(
          phoneCodes.filter((phone) => phone.name === value?.label)?.[0],
        );
        handleClose();
      },
      [handleClose, onChange],
    );
    const FilterFlags: any = (flag: string) => {
      const flags: any = flagList;
      const res: any = Object.keys(flags)
        .filter((key) => key === flag)
        .reduce((obj: any, key: any) => {
          return Object.assign(obj, {
            [key]: flags[key],
          });
        }, {});
      return Object.values(res)?.[0];
    };

    return (
      <>
        <div ref={dropRef} style={{position: 'relative'}}>
          <HeaderWrapper onClick={handleOpen} state={state}>
            {selectedValue?.flag ? (
              <Flex alignItems="center" justifyContent="center">
                <Flex alignItems="center" justifyContent="center">
                  <div style={{marginTop: '2px'}}>
                    <Image
                      width="24px"
                      height={'18px'}
                      {...FilterFlags(selectedValue?.code.toLowerCase())}
                    />
                  </div>
                  <Body1 kind="textBody" style={{margin: '0 13px'}}>
                    {selectedValue?.dial_code}
                  </Body1>
                </Flex>
                <ArrowIconWrapper>
                  <motion.span
                    initial={{rotate: 0}}
                    animate={{
                      rotate: open ? 180 : 0,
                      marginTop: open ? '-6px' : '0px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                    key="user-select-toggled">
                    <ArrowHeadDownIcon />
                  </motion.span>
                </ArrowIconWrapper>
              </Flex>
            ) : (
              <Flex>
                {phoneCodes
                  ?.filter((code) => code.name === innerValue?.label)
                  .map((code) => (
                    <Fragment key={code.code}>
                      <Flex alignItems="center" justifyContent="center">
                        <div style={{marginTop: '2px'}}>
                          <Image
                            width="24px"
                            height={'18px'}
                            {...FilterFlags(code?.code.toLowerCase())}
                          />
                        </div>
                        <Body1 kind="textBody" style={{margin: '0 13px'}}>
                          {code?.dial_code}
                        </Body1>
                      </Flex>
                      <ArrowIconWrapper>
                        <motion.span
                          initial={{rotate: 0}}
                          animate={{
                            rotate: open ? 180 : 0,
                            marginTop: open ? '-6px' : '0px',
                          }}
                          transition={{
                            duration: 0.3,
                          }}
                          key="user-select-toggled">
                          <ArrowHeadDownIcon />
                        </motion.span>
                      </ArrowIconWrapper>
                    </Fragment>
                  ))}
              </Flex>
            )}
          </HeaderWrapper>
          {open && (
            <DropdownWrapper style={{zIndex: 99999}}>
              <SelectFieldSecondary
                {...rest}
                onChange={onSelectChange}
                value={innerValue}
                ref={ref as any}
                options={options}
                fieldNotFoundPlaceHolder={fieldNotFoundPlaceHolder}
              />
            </DropdownWrapper>
          )}
        </div>
      </>
    );
  },
);
