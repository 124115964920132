import {FC, useMemo} from 'react';
import {AnalyticsActionItemsOverviewPage} from '../../reporting-pages/admin-analytics/analytics-pages/analytics-action-items/overview';
import {Body1, Body2} from '@ui/atoms/typography';
import {Insight} from '../interface';
import {FlexRow, FlexRowEnd} from '@ui/style/styles';
import {formatConfigDate, comparisonConfigDate} from '../insight-options';
import {useInsightActionItemHook} from './action-items-insight-hook';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {PercentageProgress} from '../../dashboard/insight-card';
import {CandleVIcon} from '@ui/atoms/icons/candle-v';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';
import {getInitialAdditionalFilters} from '../edit-insight/edit-insight-filter';
import {VerticalInsightConfig} from '../../dashboard/insight-config';
import {Flash2Icon} from '@ui/atoms/icons/flash-icon';

interface ActionItemsDistributionProps extends Insight {
  showRangeType?: boolean;
  handleEditLayout?: (id: string) => void;
  overallDateRange?: null | {startDate: string; endDate: string};
}

type OriginalSummary = {
  [key: string]: {
    current: number;
    previous: number;
    change: number;
  };
};

type AnalyticsPageSummary = {
  [K in keyof OriginalSummary]: K extends 'completionRate' ? string : number;
} & {total: string};

function prepareAnalyticsSummary(
  summary: OriginalSummary,
): AnalyticsPageSummary {
  const prepared = Object.entries(summary).reduce((acc, [key, value]) => {
    if (key === 'completionRate') {
      acc.total = `${value.current}%`;
    } else {
      acc[key] = value.current;
    }
    return acc;
  }, {} as AnalyticsPageSummary);

  return prepared;
}

type SummaryItem = {
  current: number;
  previous: number;
  change: number;
};

type Summary = {
  [key: string]: SummaryItem;
};

type FormattedSummaryItem = {
  label: string;
  current: string | number;
  color: string;
  previous: string | number;
  change: string;
};

function formatActionItemsSummary(summary: Summary): FormattedSummaryItem[] {
  const labels = {
    active: {
      label: 'Open',
      color: '#E4E5FB',
    },
    completed: {
      label: 'Completed',
      color: '#585ADF',
    },
    overdue: {
      label: 'Overdue',
      color: '#28284D',
    },
    undated: {
      label: 'Undated',
      color: '#5E5E73',
    },
    completionRate: {
      label: 'Completion rate',
      color: '',
    },
  };

  return Object.entries(summary)
    .filter(([key]) => !!labels[key as keyof typeof labels])
    .map(([key, item]) => {
      const isPercentage = key === 'completionRate';
      const formatValue = (value: number) =>
        isPercentage ? `${value}%` : value;

      return {
        label: labels[key as keyof typeof labels].label || key,
        color: labels[key as keyof typeof labels].color,
        current: formatValue(item.current),
        previous: formatValue(item.previous),
        change: `${Math.abs(item.change)}`,
      };
    });
}

export const ActionItemsDistribution: FC<ActionItemsDistributionProps> = ({
  showRangeType,
  handleEditLayout,
  overallDateRange,
  name,
  ...rest
}) => {
  const config = rest.config;

  const additionalFilters = getInitialAdditionalFilters(
    config.list,
    config.users,
  );

  const formatDate = useMemo(
    () =>
      overallDateRange ? overallDateRange : formatConfigDate(config) || {},
    [config, overallDateRange],
  );

  const comparisonDate = useMemo(
    () => comparisonConfigDate(config, formatDate.startDate),
    [config, formatDate.startDate],
  );

  const {summary} = useInsightActionItemHook({
    members: config.users || [],
    sections: config.list || [],
    date: formatDate,
    comparisonDate: comparisonDate,
  });

  const hasComparison = !!config.comparison.value;

  const preparedSummary = prepareAnalyticsSummary(summary);

  const formattedSummary = formatActionItemsSummary(summary);

  return (
    <div className="bg-white shadow-sm p-4 md:p-6 border rounded-[10px] border-borderLight min-h-[150px] w-full h-full">
      <Body1 weight="bold" className="mb-2 text-base md:text-lg">
        {name}
      </Body1>

      {showRangeType && (
        <div className="w-full overflow-x-auto">
          <div className="flex items-center gap-2">
            {Boolean(overallDateRange) && <Flash2Icon />}

            <VerticalInsightConfig
              config={config}
              additionalFilters={additionalFilters}
            />
          </div>
        </div>
      )}

      {hasComparison ? (
        <div className="space-y-4">
          {formattedSummary.map((item, index) => (
            <div
              key={item.label}
              className={`flex flex-col sm:flex-row justify-between items-start sm:items-center py-3 md:py-4 ${
                index === formattedSummary.length - 1 ? '' : 'border-b'
              } border-b-borderLight space-y-2 sm:space-y-0`}>
              <div>
                <FlexRow className="gap-2 flex-wrap">
                  {item.color && (
                    <CustomColorIcon
                      color={item.color}
                      height="10px"
                      width="10px"
                      margin={0}
                    />
                  )}
                  <Body2
                    weight={
                      item.label.toLowerCase() === 'completion rate'
                        ? 'semibold'
                        : 'default'
                    }>
                    {item.label}
                  </Body2>
                </FlexRow>
                <Body2
                  kind="textBody"
                  className="mt-2 sm:mt-3 text-xs sm:text-sm">
                  Change
                </Body2>
              </div>
              <div className="w-full sm:w-auto">
                <FlexRow className="justify-between sm:justify-end space-x-2">
                  <Body2 kind="textBody" weight="semibold">
                    <span className="text-black">{item.current}</span>
                    <span className="text-sm"> vs. {item.previous}</span>
                  </Body2>
                </FlexRow>
                <div className="mt-2 sm:mt-3 flex flex-row justify-end items-center">
                  <PercentageProgress
                    value={item.change}
                    textStyle={{
                      fontSize: '12px',
                      fontWeight: 400,
                    }}
                    svgStyle={{
                      svgHeight: '8px',
                      svgWidth: '8px',
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="overflow-x-auto">
          <AnalyticsActionItemsOverviewPage
            summary={preparedSummary as any}
            pieTitle="Completion rate"
            extraPieSection={[
              {
                title: 'Undated',
                name: 'undated',
                key: 'undated',
                value: summary.undated.current,
                color: '#5E5E73',
                percent: 'undatedPercent',
              },
            ]}
            excludeCompletionRate
          />
        </div>
      )}

      {showRangeType && (
        <FlexRowEnd className="mt-4 md:mt-6">
          <button
            onClick={() => {
              if (rest.firebaseId && handleEditLayout)
                handleEditLayout(rest.firebaseId);
            }}
            className="p-2">
            <HoverTooltip tooltipText="Edit insight">
              <CandleVIcon />
            </HoverTooltip>
          </button>
        </FlexRowEnd>
      )}
    </div>
  );
};
