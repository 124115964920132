import PageLayoutTemplate from '@ui/templates/page-template/page-layout';
import {
  CascadeKeyResultWrapper,
  Hr,
  List,
  TextFieldWrapper,
} from './cascade-styles';
import {GoalsSelectField} from '@ui/molecules/select/goal-select-field/goal-select-field';
import {Label} from '@ui/atoms/label';
import {useCallback, useState, useEffect} from 'react';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Button} from '@ui/atoms/button';
import {MeasurementType} from '@hooks';
import {AddCascadeKeyResultWrapper} from './cascade-styles';
import {FlexRowEnd, Flex, FlexRowSpaceBetween} from '@ui/style/styles';
import {PlusIcon} from '@ui/atoms/icons';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {Body2, Body1} from '@ui/atoms/typography';
import {Helper} from '@ui/atoms/helper';
import {parseCurrencyValue} from '@constants';
import {CreateGoalForm} from '@pages/create-goal/create-goal-form';
import {CancelIcon} from '@ui/atoms/icons';
import {useCascadeKeyResult} from './cascade-keyresult-hook';
import {useQuery} from 'react-query';
import {CreateGoalRequestImpl} from '@pages/create-goal/create-goal-request';
import {CreateGoalController} from '@pages/create-goal/create-goal-controller';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {TextField} from '@ui/molecules/field/textfield';
import {KeyResultSelectField} from '@ui/molecules/select/keyresult-selectfield/keyresult-selectfield';
import {useNavigate} from 'react-router';
import {cfv} from '@utils/framework';
import {capitalize} from '@utils';

export const CascadeKeyResult = () => {
  const navigate = useNavigate();

  const {
    initGoals,
    transformKeyResult,
    cascadedKeyResults,
    selectedKeyResults,
    setKeyResult,
    save,
    setSave,
    goalId,
    getSymbol,
    fetchSpecificGoal,
    cascadeKeyResults,
    loading,
    disableSaveButton,
    editAlignment,
    disableCascadeKeyResults,
    onChangeKeyResult,
    selectedObjective,
    setObjective,
    open,
    setOpen,
    deleteKeyResult,
    addKeyResult,
  } = useCascadeKeyResult();

  const {data, refetch} = useQuery(['cascade-keyresult'], () =>
    initGoals(1, 'networkLevel'),
  );

  const [createGoalLoader, setCreateGoalLoader] = useState(false);

  const createGoal = async (data: any) => {
    const request = new CreateGoalRequestImpl();
    const controller = new CreateGoalController(request);
    setCreateGoalLoader(true);

    const data_ = data;

    const response: any = await controller.createGoal(data_);
    response && setOpen(false);
    response && (await refetch());
    setCreateGoalLoader(false);
  };

  useEffect(() => {
    if (goalId && data?.goals) {
      fetchSpecificGoal(goalId, data?.goals);
    }
  }, [fetchSpecificGoal, goalId, data?.goals]);

  const keyResultOptions = useCallback(
    (_goal: string) => {
      if (data && _goal) {
        const keyResults = data.goals
          .find((goal: any) => goal.id === _goal)
          ?.keyResults.map((keyResult: any) => transformKeyResult(keyResult));

        return keyResults || [];
      }
      return [];
    },
    [data, transformKeyResult],
  );

  const findPreviousAlignment = selectedKeyResults?.value
    ? data?.goals
        .filter((goal: any) =>
          goal.keyResults.some(
            (kr: any) =>
              kr.keyResultAlignment?.keyResult === selectedKeyResults?.value,
          ),
        )
        ?.map((goal: any) => goal.id)
    : [];

  const ObjectivesId = [
    ...cascadedKeyResults.map((kr) => kr.objective.id),
    selectedObjective,
    ...findPreviousAlignment,
  ];

  return (
    <PageLayoutTemplate
      onClick={() => navigate(-1)}
      guideUrl="https://peoplebeam.myhcpage.com/en-us/"
      title={`${editAlignment ? 'Edit' : ''} Cascade ${cfv().k_rs}`}>
      <div>
        <DrawerModal open={open} onClose={() => setOpen(false)}>
          <CreateGoalForm onSubmit={createGoal} loading={createGoalLoader} />
        </DrawerModal>
        <CascadeKeyResultWrapper>
          <div className="form">
            <Label>Parent {cfv().g_oal}</Label>
            <GoalsSelectField
              useDropdownIcon
              maxTitleLength={55}
              placeholder={`Select ${cfv().g_oal}`}
              customOptionName={`Create new ${cfv().g_oal}`}
              // value={value}
              defaultValue={selectedObjective}
              //   ref={UserSelect3Ref}
              options={data?.options || []}
              onChange={(data: {value: string; label: any}) => {
                if (data.label === 'custom') {
                  setOpen(true);
                  setObjective('');
                  return;
                }
                setObjective(data?.value);
                setKeyResult(null);
              }}
            />
            <Flex>
              <div style={{width: '90%', marginRight: '16px'}}>
                <Label>{capitalize(cfv().k_r)}</Label>
                <KeyResultSelectField
                  useDropdownIcon
                  placeholder={`Select ${cfv().k_r}`}
                  // value={value}
                  defaultValue={selectedKeyResults?.value || ''}
                  //   ref={UserSelect3Ref}
                  options={keyResultOptions(selectedObjective || '')}
                  onChange={(data: {value: string; label: any}) => {
                    setKeyResult(data);
                  }}
                />
              </div>
              <div>
                <Label>Target</Label>
                <TextField
                  placeholder={`Select ${cfv().k_r}`}
                  disabled={true}
                  inputStyle={{borderRadius: '10px', textAlign: 'right'}}
                  value={
                    selectedKeyResults?.label.target
                      ? getSymbol(
                          selectedKeyResults?.label?.measurement || '',
                          selectedKeyResults?.label.target,
                          selectedKeyResults?.label?.symbol,
                        )
                      : 0
                  }
                  onChange={(event) => console.log(event)}
                />
              </div>
            </Flex>
            <Hr />
            {!save ? (
              <AddCascadeKeyResultWrapper>
                <FlexRowSpaceBetween>
                  <Body2 weight="bold">Add cascaded {cfv().k_rs}</Body2>
                  <PlainButton onClick={addKeyResult}>
                    <Flex>
                      <PlusIcon
                        styles={{
                          stroke: '#585ADF',
                          strokeWidth: '1.5px',
                          marginRight: '6px',
                        }}
                      />
                      <Body2 weight="bold" kind="purple300">
                        Add {cfv().k_r}
                      </Body2>
                    </Flex>
                  </PlainButton>
                </FlexRowSpaceBetween>
                <VerticalSpacer size="16px" />
                {cascadedKeyResults.map((keyResult) => (
                  <AddCascadeKeyResultWrapper
                    style={{padding: '16px 12px', marginBottom: '16px'}}
                    key={keyResult.id}>
                    <FlexRowSpaceBetween style={{marginBottom: '16px'}}>
                      <div style={{width: '90%'}}>
                        <GoalsSelectField
                          useDropdownIcon
                          margin
                          placeholder={`Select ${cfv().g_oal}`}
                          defaultValue={keyResult.objective.id}
                          maxTitleLength={40}
                          options={
                            data?.options.filter(
                              (goal: any) => !ObjectivesId.includes(goal.value),
                            ) || []
                          }
                          onChange={(data: {value: string; label: string}) => {
                            onChangeKeyResult(keyResult.id, 'objective', {
                              name: data.label,
                              id: data.value,
                            });
                          }}
                        />
                      </div>
                      <PlainButton
                        onClick={() => deleteKeyResult(keyResult.id)}>
                        <CancelIcon />
                      </PlainButton>
                    </FlexRowSpaceBetween>
                    <Flex>
                      <div style={{width: '100%', marginRight: '16px'}}>
                        <KeyResultSelectField
                          useDropdownIcon
                          state={keyResult.error ? 'error' : 'default'}
                          helper={!!keyResult.error}
                          maxTitleLength={30}
                          margin
                          placeholder={`Select ${cfv().k_r}`}
                          defaultValue={keyResult.keyresult.id}
                          options={keyResultOptions(keyResult.objective.id)}
                          onChange={(data: {value: string; label: any}) => {
                            onChangeKeyResult(keyResult.id, 'keyresult', {
                              ...data.label,
                              id: data.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <TextFieldWrapper
                          state={keyResult.error ? 'error' : 'default'}>
                          <Body1>
                            {' '}
                            {selectedKeyResults?.label?.measurement?.toUpperCase() ===
                            MeasurementType.CURRENCY
                              ? parseCurrencyValue(
                                  selectedKeyResults?.label?.symbol || '',
                                )
                              : ''}{' '}
                          </Body1>
                          <TextField
                            margin
                            noBorder
                            placeholder={`Contribution`}
                            state={keyResult.error ? 'error' : 'default'}
                            inputStyle={{padding: 0}}
                            value={keyResult.contribution || ''}
                            onChange={(event) => {
                              onChangeKeyResult(
                                keyResult.id,
                                'contribution',
                                event.target.value,
                              );
                            }}
                          />
                          <Body1>
                            {' '}
                            {selectedKeyResults?.label?.measurement?.toUpperCase() ===
                            MeasurementType.PERCENTAGE
                              ? '%'
                              : ''}
                          </Body1>
                        </TextFieldWrapper>
                      </div>
                    </Flex>
                    {keyResult.error && (
                      <Helper state="error" children={keyResult.error} />
                    )}
                  </AddCascadeKeyResultWrapper>
                ))}
                <FlexRowEnd>
                  <Button
                    style={{padding: '8px 16px'}}
                    onClick={() => setSave(true)}
                    disabled={disableSaveButton()}>
                    Save
                  </Button>
                </FlexRowEnd>
              </AddCascadeKeyResultWrapper>
            ) : (
              <>
                <FlexRowSpaceBetween>
                  <Body2 weight="bold">Cascaded {cfv().k_rs}</Body2>
                  <PlainButton onClick={() => setSave(false)}>
                    <Body2 weight="bold" kind="purple300">
                      Edit
                    </Body2>
                  </PlainButton>
                </FlexRowSpaceBetween>
                <VerticalSpacer size="12px" />

                <AddCascadeKeyResultWrapper>
                  <ul>
                    {cascadedKeyResults.map((keyResult) => (
                      <List>
                        <FlexRowSpaceBetween>
                          <Body2 weight="bold">
                            {keyResult.keyresult?.keyResultName}
                          </Body2>
                          <div className="number">
                            {getSymbol(
                              selectedKeyResults?.label?.measurement || '',
                              keyResult.contribution,
                              selectedKeyResults?.label?.symbol,
                            )}
                          </div>
                        </FlexRowSpaceBetween>
                      </List>
                    ))}
                  </ul>
                </AddCascadeKeyResultWrapper>
              </>
            )}
            <VerticalSpacer size="32px" />
            <Button
              style={{padding: '16px 32px'}}
              width="full"
              isLoading={loading}
              onClick={() => cascadeKeyResults(data?.goals)}
              disabled={disableCascadeKeyResults}>
              Cascade {cfv().k_r}
            </Button>
          </div>
        </CascadeKeyResultWrapper>
      </div>
    </PageLayoutTemplate>
  );
};
