import ReactSelect, {
  components,
  OptionsType,
  ValueType,
  ActionMeta,
  Props,
  IndicatorProps,
  PlaceholderProps,
  SingleValueProps,
} from 'react-select';
import {MagnifyingGlassIcon} from '../../../atoms/icons/magnifying-glass';
import {IndicatorWrapper, getStyles, AvatarWrapper} from './styled';

import {Avatar} from '../../../atoms/avatar';
import {Body1, Body2} from '../../../atoms/typography';
import {forwardRef, Ref, useCallback, useMemo, useState} from 'react';
import {computeFrameworkVars} from '../../../../utils/framework';
import {authStore} from '../../../../store/stores/auth-store';

interface IOption {
  label: {goalName: string};
}

const filterUserSelectOptions = (option: {data: IOption}, rawInput: string) => {
  if (rawInput) {
    const label = option.data.label.goalName.toLowerCase();
    return rawInput
      .toLowerCase()
      .split(' ')
      .every((word) => label.includes(word));
  }
  return true;
};

const {
  DropdownIndicator: SelectDropdownIndicator,
  Option: SelectOption,
  Placeholder: SelectPlaceholder,
  SingleValue: SelectSingleValue,
} = components;

export interface TLabel {
  keyResultName: string;

  assigneeName: string;
  assigneeAvatar: {src: string; name: string};
  id: string;
}

export interface TOption {
  value: string;
  label: TLabel;
}

export interface SelectFieldProps extends Props<TOption> {
  options: OptionsType<TOption>;
  onChange?: (
    value: ValueType<TOption, false>,
    action: ActionMeta<TOption>,
  ) => void;
}

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <div style={{display: props.hasValue ? 'none' : ''}}>
      <SelectDropdownIndicator {...props}>
        <IndicatorWrapper>
          <MagnifyingGlassIcon />
        </IndicatorWrapper>
      </SelectDropdownIndicator>
    </div>
  );
};

const Placeholder = (props: PlaceholderProps<TOption, false>) => {
  return (
    <SelectPlaceholder {...props}>
      <Body1 kind="textBody">{props.children}</Body1>
    </SelectPlaceholder>
  );
};

const Option = (props: any) => {
  return (
    <>
      <SelectOption {...props}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Body2 kind="textDark" weight="semibold">
            {props?.label && ((props.label as unknown) as TLabel).keyResultName}
          </Body2>
          <AvatarWrapper>
            <Avatar
              src={
                props?.label
                  ? ((props.label as unknown) as TLabel).assigneeAvatar?.src
                  : ''
              }
              name={
                props?.label
                  ? ((props.label as unknown) as TLabel).assigneeAvatar?.name
                  : ''
              }
              userId={
                props?.label
                  ? ((props.label as unknown) as any).assigneeAvatar?.id
                  : ''
              }
              size="sm"
              tooltip={props?.label ? true : false}
            />
          </AvatarWrapper>
        </div>
      </SelectOption>
      {/* DO NOT DELETE */}
      {/* <AdditionalOptionsWrapper
        display={props.isSelected || props.data.isEditing}>
        <RadioWrapper
          onClick={() => {
            setIsChecked(isChecked === true ? false : true);
          }}>
          <RadioSm checked={!isChecked} />
          <HorizontalSpacer size="8px" />
          <Body2 kind={!isChecked ? 'textDark' : 'textBody'}>
            Align your progress to this parent goal
          </Body2>
        </RadioWrapper>
        <VerticalSpacer size="8px" />
        <RadioWrapper
          onClick={() => {
            setIsChecked(isChecked === true ? false : true);
          }}>
          <RadioSm checked={isChecked} />
          <HorizontalSpacer size="8px" />
          <Body2 kind={isChecked ? 'textDark' : 'textBody'}>
            Don't align your progress to this parent goal
          </Body2>
        </RadioWrapper>
      </AdditionalOptionsWrapper> */}
    </>
  );
};

const SingleValue = (props: SingleValueProps<TOption>) => {
  return (
    <SelectSingleValue {...props}>
      <Body1 kind="textDark">
        {((props.children as unknown) as TLabel).keyResultName}
      </Body1>
    </SelectSingleValue>
  );
};

export const KeyResultSelect = forwardRef(
  (
    {options, placeholder, onChange, value, useAllOptions, ...rest}: any,
    ref: Ref<any>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);

    const [innerValue, setInnerValue] = useState<ValueType<TOption, false>>(
      // @ts-ignore
      value,
    );

    const onSelectChange = useCallback(
      (value: ValueType<TOption, false>, action: ActionMeta<TOption>) => {
        if (onChange) {
          onChange(value, action);
        }
        setInnerValue(value);
      },
      [onChange],
    );

    return (
      <ReactSelect
        {...rest}
        isClearable
        // isMulti
        backspaceRemovesValue
        hideSelectedOptions={false}
        menuIsOpen
        filterOption={filterUserSelectOptions}
        isSearchable
        value={innerValue}
        noOptionsMessage={() =>
          `No ${
            computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
              ?.k_r
          } found`
        }
        tabSelectsValue={false}
        placeholder={placeholder}
        onChange={onSelectChange}
        ref={ref}
        maxMenuHeight={240}
        components={{
          DropdownIndicator,
          Option,
          Placeholder,
          SingleValue,
          IndicatorSeparator: null,
        }}
        options={
          !useAllOptions && innerValue && Object.keys(innerValue).length > 0
            ? [innerValue]
            : options
        }
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 40,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
