import {
  ComponentType,
  memo,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import {useSearchParams} from 'react-router-dom';
import {WORKSPACE_ROUTES} from '@pages/dashboard/admin/admin-components/admin-workspace/admin-workspace-page';
import {useLocation} from 'react-router';
import {CSSProperties} from 'react';
import {NotificationCard} from '@ui/molecules/notification/notification';
import {Navigation} from '@ui/molecules/nav';
import {NavigationOption} from '@ui/interface';
import {WorkspaceHeader} from '@ui/molecules/workspace/header';
import {useStoreContext} from '@store/store-context';
import {BillingType} from '@hooks';

const OuterWrapper = styled.div`
  max-width: 100%;

  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

export const AlertsIndicator = styled.div`
  background: #d52a2a;
  color: white;
  width: 18px;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  padding: 5px;
  margin-left: 6px;
  align-items: center;
  justify-content: center;
  height: 18px;
  border-radius: 20px;
`;

const Wrapper = styled.div`
  width: 100%;

  @media (max-width: 800px) {
  }
`;

const Header = styled.div`
  z-index: 3;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
`;

export interface DashboardTemplateProps extends PropsWithChildren<{}> {
  header: ReactNode;
  dashboardStyle?: CSSProperties;
  outerStyle?: CSSProperties;
  navigations: NavigationOption[];
  workspace?: {
    avatar?: string;
    name: string;
  };
  WorkspaceDropdown?: ComponentType<{onClose: () => void}>;
}

const exemptedRoutes = [
  ...Object.values(WORKSPACE_ROUTES).map(
    (route) => `/workspace-settings/${route}`,
  ),
  '/workspace-settings/cc-settings/cc-view-settings',
  '/workspace-settings/*',
  '/workspace-settings/checkins',
  '/workspace-settings/checkins/configure',
];

export const DashboardTemplate = memo(
  ({
    children,
    header,
    workspace,
    WorkspaceDropdown,
    dashboardStyle,
    outerStyle,
    navigations = [],
  }: DashboardTemplateProps) => {
    const location = useLocation();

    const [searchParams] = useSearchParams();

    const showGroupDrawer = searchParams.get('group-drawer');

    const {
      authStore: {auth},
    } = useStoreContext();

    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
      // Function to check if modal is visible
      const checkModalVisibility = () => {
        const modalCard = document.getElementById('modal-card');
        const modal = document.getElementById('modal');
        setIsModalVisible(!!modalCard && !!modal);
      };

      // Initial check
      checkModalVisibility();

      // Set up mutation observer to watch for modal changes
      const observer = new MutationObserver(checkModalVisibility);

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });

      return () => observer.disconnect();
    }, []);

    const trailHasEnded =
      auth.user.workspace.billing.trialCompleted &&
      auth.user.workspace.billing.activeTrial;

    const subscriptionHasEnded =
      auth.user.workspace.billing.plan === BillingType.FREE;

    const disableActions = subscriptionHasEnded || trailHasEnded;

    const isExemptedRoute = exemptedRoutes.some((route) => {
      if (route.includes('*')) {
        const pattern = new RegExp(`^${route.replace(/\*/g, '.*')}$`);
        return pattern.test(location.pathname);
      }

      return location.pathname === route;
    });

    return (
      <OuterWrapper style={outerStyle}>
        <div className="flex max-h-screen w-full overflow-hidden">
          {navigations.length ? (
            <div className="min-h-screen border-r border-r-[#E1E1EA] bg-white block sm:hidden xs:hidden z-[4] ">
              {WorkspaceDropdown && workspace && (
                <div className="px-6 py-[14px] min-h-[80px] block bg-transparent border-b border-b-[#ededf2]  ">
                  <WorkspaceHeader
                    workspace={workspace}
                    disabled={disableActions}
                    Dropdown={WorkspaceDropdown}
                  />
                </div>
              )}
              <div className="p-8 px-6 pt-[35px] ">
                <Navigation navigations={navigations} />
              </div>
            </div>
          ) : null}

          <Wrapper className="overflow-y-auto relative " id="dashboard-body">
            <Header className="">
              {header}
              <div
                className={`fixed top-20 ${
                  navigations.length ? 'w-[90%]' : 'w-full'
                } sm:w-full`}>
                {!isExemptedRoute && !showGroupDrawer && !isModalVisible && (
                  <NotificationCard />
                )}
              </div>
            </Header>

            <div
              className="px-10 sm:px-4 mt-4 pb-10 overflow-x-hidden min-h-[80vh]"
              style={dashboardStyle}>
              {children}
            </div>
          </Wrapper>
        </div>
      </OuterWrapper>
    );
  },
);

DashboardTemplate.displayName = 'DashboardTemplate';
