import {useCallback, useMemo} from 'react';
import {useState} from 'react';
import {useStoreContext} from '@store/store-context';
import {set, get} from 'idb-keyval';
import {useQuery} from 'react-query';
import {toJS as proxyToJS} from 'mobx';
import {useURLQuery} from '@utils/getURLQuery';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {GoalsController} from '@api/goals-api/goals-controller';
import {TGoalResponse} from './interface';

export const updateGoal = () => {
  const saveUpdate = async (goalId: string, goal: TGoalResponse) => {
    const updateGoal = goal;

    const filterUpdate = {
      ...updateGoal,
      keyResults: updateGoal.keyResults?.map((result: any) => {
        return {
          ...result,
          context: result?.contextValue || '',
          contextValue: undefined,
        };
      }),
    };

    const request = new GoalsRequestImpl();
    const controller = new GoalsController(request);

    const response = await controller.updateGoal(filterUpdate, goalId);

    return response;
  };

  return {
    saveUpdate,
  };
};
export const useFetchUserGoals = () => {
  const [searchValue, setSearchValue] = useState('');
  const {
    storeYouGoals: {
      modifyYouGoalsPaginator,
      youGoalsPaginator,
      sortBy,
      dateRange,
      selectedUsers,
      youGoalsFilter,
      replaceFetchedGoals,
    },
    usersStore: {users},
    groupStore: {groups},
    storeDashboardRoutes: {GoalViewTabs},

    authStore: {auth},
  } = useStoreContext();

  const getGoalsFilter = useMemo(() => {
    return `${youGoalsPaginator.page} - ${youGoalsFilter} - ${
      youGoalsPaginator.limit
    }-${GoalViewTabs.tree}-${
      GoalViewTabs.nested
    }  - ${searchValue} -${sortBy} - ${selectedUsers.join(',')} - ${
      dateRange.starts
    } - ${dateRange.ends}`;
  }, [
    youGoalsPaginator.page,
    youGoalsPaginator.limit,
    youGoalsFilter,
    GoalViewTabs.tree,
    dateRange,
    GoalViewTabs.nested,
    searchValue,
    sortBy,
    selectedUsers,
  ]);

  const removeDuplicatesInTree = useCallback(
    (goal: any, seenIds: Set<string> = new Set()) => {
      if (!seenIds.has(goal.id)) {
        seenIds.add(goal.id);
        const filteredAlignedGoals: any[] = [];

        for (const alignedGoal of goal.alignedGoals) {
          const filteredAlignedGoal = removeDuplicatesInTree(
            alignedGoal,
            seenIds,
          );
          filteredAlignedGoals.push(filteredAlignedGoal);
        }

        return {
          ...goal,
          alignedGoals: filteredAlignedGoals,
        };
      }

      return {
        ...goal,
      };
    },
    [],
  );

  const isAlignmentActive = auth?.user?.workspace?.settings?.goalAlignment;

  const initGoals = useCallback(
    async (page: number) => {
      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);

      const _dateRange = !['active'].includes(youGoalsFilter)
        ? {startDate: dateRange.starts, endDate: dateRange.ends}
        : {};

      const response = await controller.fetchAllGoals(
        page,
        {
          ..._dateRange,
          goalFilter: youGoalsFilter,
          isKpi: false,
          name: searchValue,
          sortBy,
          member: selectedUsers.length
            ? youGoalsFilter === 'archived'
              ? selectedUsers[0]
              : selectedUsers.join(',')
            : auth.user.id,
        },
        youGoalsPaginator.limit,
      );

      const users = await get(`${auth.user.workspace.id}/users`);

      const updateKeyResult = (data: any[]) => {
        return Promise.all(
          data.map(async (goal: any) => {
            const updatedKeyResults = await Promise.all(
              goal.keyResults.map(async (keyResult: any) => {
                if (keyResult?.alignmentTarget > 0 && isAlignmentActive) {
                  const getKr = await controller.getKeyResult(keyResult.id);
                  return {
                    ...getKr,
                    alignedKeyResults: getKr.alignedKeyResults.map(
                      (kr: any) => {
                        return {
                          ...kr,
                          assignee: users.find(
                            (user: any) => user.id === kr.assignee,
                          ),
                        };
                      },
                    ),
                  };
                }
                return {
                  ...keyResult,
                };
              }),
            );
            return {
              ...goal,
              keyResults: updatedKeyResults,
            };
          }),
        );
      };

      const resultsIncludingKeyResultAlignment = await updateKeyResult(
        response?.goals || response?.results,
      );

      if (GoalViewTabs.tree || GoalViewTabs.nested) {
        const request = new AnalyticsRequestImpl();
        const controller = new AnalyticsController(request);

        const treeArrangedGoalsResponse = await Promise.all(
          resultsIncludingKeyResultAlignment &&
            resultsIncludingKeyResultAlignment.map(async (goal: any) => {
              const response = await controller.fetchGoalAlignmentTree(goal.id);

              return {
                ...goal,
                ...response,
                alignedGoals: await updateKeyResult(
                  response.alignedGoals || [],
                ),
                keyResults: goal.keyResults,
              };
            }),
        );

        const filteredTreeArrangedGoalsResponse = treeArrangedGoalsResponse
          .map((goal) =>
            goal.alignedGoals.length > 0 && goal.goalAlignment
              ? null
              : removeDuplicatesInTree(goal),
          )
          .filter((goal) => goal !== null);

        return filteredTreeArrangedGoalsResponse;
      }

      return {
        ...response,
        results: resultsIncludingKeyResultAlignment,
      };
    },
    [
      youGoalsFilter,
      dateRange.starts,
      dateRange.ends,
      searchValue,
      sortBy,
      selectedUsers,
      auth.user.id,
      auth.user.workspace.id,
      youGoalsPaginator.limit,
      GoalViewTabs.tree,
      GoalViewTabs.nested,
      isAlignmentActive,
      removeDuplicatesInTree,
    ],
  );

  const {isLoading, refetch, data} = useQuery(
    ['initGoals', getGoalsFilter],
    () => initGoals(youGoalsPaginator.page),
    {
      retry: false,
      enabled: users.length > 0,
    },
  );
  const url = useURLQuery();

  useMemo(async () => {
    const request = new GoalsRequestImpl();
    const controller = new GoalsController(request);

    if (data && GoalViewTabs.flat) {
      replaceFetchedGoals(data?.results);

      const includeGroups = (goalsResponse: any) => {
        const groupsIncluded =
          goalsResponse?.results &&
          goalsResponse?.results?.map((goal: any) => {
            if (goal.goalType === 'group') {
              const group = goal.group.map((grp: string) => {
                return proxyToJS(groups.find((_group) => _group.id === grp));
              });

              return {
                ...goal,
                group: group,
              };
            } else
              return {
                ...goal,
              };
          });
        return groupsIncluded;
      };

      const unfurledData = includeGroups(data);

      replaceFetchedGoals(unfurledData);

      const totalResults = data.totalResults;
      const totalPages = data.totalPages;

      modifyYouGoalsPaginator('page', youGoalsPaginator.page);
      modifyYouGoalsPaginator('totalPages', totalPages);
      modifyYouGoalsPaginator('limit', data.limit);
      modifyYouGoalsPaginator('totalResults', totalResults);

      set(
        `${auth.user.workspace.id}/${auth.user.id}/active-goals`,
        unfurledData,
      );

      if (url?.taskGoalId) {
        if (unfurledData?.find((goal: any) => goal?.id === url?.taskGoalId)) {
          const getGoal: any = unfurledData?.find(
            (goal: any) => goal?.id === url?.taskGoalId,
          );

          const getGoals = unfurledData.map((value: any) => value);

          const filterGoal = getGoals.filter(
            (goal: any) => goal.id !== url?.taskGoalId,
          );

          return [{...getGoal, open: true}, ...filterGoal];
        } else {
          const res = await controller.fetchGoalById(url?.taskGoalId);

          res.open = true;

          if (unfurledData.length > 0) {
            const getGoals = unfurledData.map((value: any) => value);
            const filterGoal = getGoals.filter(
              (goal: any) => goal.id !== url?.taskGoalId,
            );

            return [res, ...filterGoal];
          } else {
            return [res];
          }
        }
      } else {
        return unfurledData;
      }
    }
  }, [
    data,
    replaceFetchedGoals,
    youGoalsPaginator.page,
    modifyYouGoalsPaginator,
    url?.taskGoalId,
    GoalViewTabs.flat,
    auth.user.id,
    auth.user.workspace.id,
    groups,
  ]);

  return {
    data,
    refetch,
    isLoading,
    searchValue,
    setSearchValue,
  };
};
