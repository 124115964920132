import styled from 'styled-components';
import {up, down} from 'styled-breakpoints';
import {getColorFromTheme} from '../../ui-utils';
import {TChildProps} from '../../interface';
import {Headline2} from '../../atoms/typography/headline2';
import {VerticalSpacer} from '../../atoms/spacer';
import {CSSProperties, memo, ReactNode} from 'react';
import {NotificationCard} from '@ui/molecules/notification/notification';

const OuterWrapper = styled.main<{fullHeight?: boolean}>`
  width: 100%;
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('white')};
  border-radius: ${({fullHeight}) =>
    fullHeight ? '0px 10px 10px 0px' : '10px'};
  min-height: ${({fullHeight}) => (fullHeight ? '100vh' : undefined)};
  position: relative;
  ${down('sm')} {
    border-radius: ${({fullHeight}) => (fullHeight ? ' 0px' : '10px')};
  }
`;

const Wrapper = styled.div<{gettingStarted: string; onBoarding: any}>`
  ${down('sm')} {
    padding: ${({gettingStarted, onBoarding}) =>
      `${
        gettingStarted === 'getStarted'
          ? '0px '
          : onBoarding
          ? '40px  16px'
          : ' 24px 24px'
      }`};
  }

  ${up('sm')} {
    padding: ${({gettingStarted, onBoarding}) =>
      `${
        gettingStarted === 'getStarted'
          ? '0px 10px'
          : gettingStarted === 'you'
          ? ''
          : onBoarding
          ? '40px 40px'
          : '34px 64px'
      }`};
  }
  /* 
  ${up('lg')} {
    padding: ${({gettingStarted}) =>
    gettingStarted === 'you' ? '' : '46px 94px'};
  }

  ${up('xl')} {
    padding: ${({gettingStarted}) =>
    gettingStarted === 'you' ? '' : '56px 104px'};
  } ;
  } */
`;
const FooterWrapper = styled.div`
  border-top: 1px solid ${getColorFromTheme('borderLight')};
  padding: 16px 30px;
`;
export interface TModalCardProps extends TChildProps {
  title: string;
  align?: 'right' | 'left' | 'center';
  customtitle?: ReactNode;
  notificationheader?: ReactNode;
  getStarted?: any;
  modalStyle?: CSSProperties;
  footer?: any;
  onBoarding?: any;
  fullHeight?: boolean;
  showNotification?: boolean;
}

export const ModalCard = memo(function ({
  children,
  title,
  customtitle,
  align,
  modalStyle,
  footer,
  getStarted,
  onBoarding,
  notificationheader,
  fullHeight,
  showNotification = true,
}: TModalCardProps) {
  return (
    <OuterWrapper fullHeight={fullHeight} id="modal-card">
      {showNotification && (
        <div className="overflow-hidden rounded-t-[10px] absolute w-full">
          <NotificationCard />
        </div>
      )}

      {notificationheader}
      <Wrapper
        style={modalStyle}
        gettingStarted={getStarted}
        onBoarding={onBoarding}>
        <Headline2 align={align}>
          {title} {customtitle}
        </Headline2>

        <VerticalSpacer size="10px" />

        {children}
      </Wrapper>
      {footer && <FooterWrapper>{footer}</FooterWrapper>}
    </OuterWrapper>
  );
});

ModalCard.displayName = 'ModalCard';
