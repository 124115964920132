import {useState, FC, ChangeEvent} from 'react';
import {CancelIcon} from '@ui/atoms/icons';
import {TextField} from '@ui/molecules/field/textfield';
import {UserListCard} from '@ui/molecules/user/list-card';
import {useDropDown} from '@hooks';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {Body2} from '@ui/atoms/typography';

type Option = {label: string; value: string; avatar: string};

interface MultiSelectInputProps {
  options: Option[];
  onChange?: (data: {value: string; label: string; isEmail: boolean}[]) => void;
  defaultValue?: {value: string; label: string; isEmail: boolean}[];
}

const EmailMultiSelectInput: FC<MultiSelectInputProps> = ({
  options,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState('');

  const [selectedOptions, setSelectedOptions] = useState<
    (Option & {isEmail: boolean})[]
  >([]);

  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [error, setError] = useState('');
  const {handleOpen, ref} = useDropDown();

  const isEmail = (value: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setError('');

    if (value.trim() === '') {
      setFilteredOptions([]);
    } else {
      const filtered = options.filter(
        (option) =>
          option.label.toLowerCase().includes(value.toLowerCase()) ||
          option.value.toLowerCase().includes(value.toLowerCase()),
      );

      setFilteredOptions(filtered);
    }

    handleOpen(e as any);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      if (isEmail(inputValue)) {
        addOption({
          value: inputValue,
          label: inputValue,
          avatar: '',
          isEmail: true,
        });
      } else {
        setError('Please enter a valid email address');
      }
    }
  };

  const addOption = (option: Option & {isEmail: boolean}) => {
    if (!selectedOptions.find((item) => item.value === option.value)) {
      const newOption = {...option, isEmail: isEmail(option.value)};
      const allOptions = [...selectedOptions, newOption];
      setSelectedOptions(allOptions);
      onChange && onChange(allOptions);

      setInputValue('');
      setFilteredOptions([]);
      setError('');
    }
  };

  const removeOption = (optionValue: string) => {
    const options = selectedOptions.filter(
      (item) => item.value !== optionValue,
    );

    setSelectedOptions(options);
    onChange && onChange(options);
  };

  return (
    <div className="w-full mt-2">
      <div className="relative w-full" ref={ref}>
        <TextField
          type="text"
          inputStyle={{borderRadius: '10px'}}
          value={inputValue}
          margin
          onChange={handleInputChange}
          onKeyDown={handleKeyDown as any}
          placeholder={'Add by name or email'}
        />

        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}

        {inputValue.trim() !== '' && filteredOptions.length > 0 && (
          <div className="absolute z-10 w-full bg-white border rounded-[10px] mt-1 max-h-60 p-2 overflow-auto">
            {filteredOptions.map((option) => (
              <DropdownItem
                key={option.value}
                onClick={() =>
                  addOption({...option, isEmail: isEmail(option.value)})
                }>
                <UserListCard
                  avatar={option.avatar}
                  userId={option.value}
                  name={option.label}
                  textKind="textBody"
                  avatarSize="xs"
                  tooltip
                  type="secondary"
                />
              </DropdownItem>
            ))}
          </div>
        )}
      </div>

      {selectedOptions.length ? (
        <div className="mt-2 flex flex-wrap gap-2">
          {selectedOptions.map((option) => (
            <div
              key={option.value}
              className={`flex items-center rounded px-2 py-1 
              bg-backgroundDark
              `}>
              <Body2>{option.label}</Body2>

              <button
                type="button"
                onClick={() => removeOption(option.value)}
                className="ml-1">
                <CancelIcon style={{strokeWidth: 1.5}} />
              </button>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default EmailMultiSelectInput;
