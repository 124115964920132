import {observer} from 'mobx-react';
import {useCallback, useState} from 'react';
import {ItemLoaderLine} from '../../../../../../../ui/organisms/item-loader-line';
import {PlusIcon} from '@ui/atoms/icons';
import {Body2} from '../../../../../../../ui/atoms/typography';
import TabSwitch from '../filterbox/tabSwitch';
import {ResponsiveFlexBetween, FlexRow, FlexRowEnd} from '@ui/style/styles';
import {cfv} from '@utils/framework';
import {GoalsController} from '@api/goals-api/goals-controller';
import {useQuery} from 'react-query';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {MinusIcon} from '@ui/atoms/icons';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import {EmptyPlaceholder} from '../../../../../../../ui/atoms/empty-placeholder';
import {TreeViewContainer} from './treeview-container';
import {useFirebaseFetch} from '@hooks/query-hook';
import {authStore} from '@store/stores/auth-store';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {AlertBadge} from '@ui/atoms/icons/alert-badge';
import {updateTooltipStatus} from '@utils/firebase-request';
import {
  FirstWrapper,
  SecondWrapperHeader,
  ZoomWrapper,
  ThirdWrapperHeaderRight,
  LineColor,
  SecondWrapperChildTreeGoals,
  ItemLoaderWrapper,
} from './treeview.styles';
import {cloudinary} from '../../../../../../../assets/images/cloudinary';

export const TreeView = observer(
  ({loading, data, filterComponentParser, bodyStyle, showTabsSwitch}: any) => {
    const {emptyGoals: emptyGoal} = cloudinary;

    const UserGoalHighlight = {
      image: {
        alt: 'alt text',
        shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
        src: emptyGoal,
        style: {height: '50%', width: '50%'},
      },
      subtitle: `See aligned ${
        cfv(undefined, true).g_oals
      } once they are available.`,
      title: `No aligned ${cfv(undefined, true).g_oals} .`,
    };

    const [isOpen, setIsOpen] = useState<any>(null);

    const [isExpand] = useState<any>(false);

    const [isGroup, setIsGroup] = useState(false);
    const [isIndividual, setIsIndividual] = useState(false);
    const [zoom, setZoom] = useState(100);

    const handleSelectZoom = (e: any) => {
      setZoom(e);
    };

    const initKeyResults = useCallback(async () => {
      const request = new GoalsRequestImpl();
      const controller = new GoalsController(request);

      const response = await controller.fetchGoals(1, 'networkLevel', 10000);

      return response?.goals;
    }, []);

    const {data: keyResults = []} = useQuery('key-results', () =>
      initKeyResults(),
    );

    const tooltipId = 'my_goal_view_tooltip';

    const {data: tooltipStatus} = useFirebaseFetch(
      `users/${authStore.auth.user.id}/onboarding/tooltip/${tooltipId}`,
    );

    const sortedTreeData = (data: any) => {
      const companyData = data.filter(
        (goal: any) => goal.goalType === 'company',
      );
      const groupData = data.filter((goal: any) => goal.goalType === 'group');
      const individualData = data.filter(
        (goal: any) => goal.goalType === 'individual',
      );
      return [...companyData, ...groupData, ...individualData];
    };

    return (
      <>
        <FirstWrapper style={{...bodyStyle, maxWidth: '100%'}}>
          <SecondWrapperHeader>
            <ResponsiveFlexBetween>
              <FlexRow>
                <ZoomWrapper
                  style={{
                    paddingRight: 0,
                    marginTop: '6px',
                    cursor: !data.length ? 'not-allowed' : 'pointer',
                  }}>
                  <div
                    onClick={() =>
                      zoom < 100 ? handleSelectZoom(zoom + 25) : null
                    }
                    style={{
                      marginBottom: '2px',
                      cursor: 'pointer',
                    }}>
                    <PlusIcon
                      styles={{stroke: !data.length ? '#BFBFD4' : 'black'}}
                    />
                  </div>

                  <Body2
                    weight="semibold"
                    kind={!data.length ? 'textMuted' : 'textDark'}>
                    {zoom}
                  </Body2>

                  <div
                    onClick={() =>
                      zoom > 50 ? handleSelectZoom(zoom - 25) : null
                    }
                    style={{
                      cursor: 'pointer',
                    }}>
                    <MinusIcon />
                  </div>
                </ZoomWrapper>
                <PlainButton
                  type="button"
                  style={{marginLeft: '6px', marginTop: '9px'}}
                  onClick={() =>
                    updateTooltipStatus(tooltipId, {isDismissed: false})
                  }>
                  <AlertBadge />
                </PlainButton>
              </FlexRow>

              <FlexRowEnd className="end">
                <FlexRow>
                  <LineColor bg="#1A9E68" />
                  <HorizontalSpacer size="8px" />
                  <Body2 weight="bold" style={{fontSize: '12px'}}>
                    Aligned objectives
                  </Body2>
                </FlexRow>
                <FlexRow style={{marginLeft: '16px'}}>
                  <LineColor bg="#084B8A" />
                  <HorizontalSpacer size="8px" />

                  <Body2 weight="bold" style={{fontSize: '12px'}}>
                    Cascaded key results
                  </Body2>
                </FlexRow>
              </FlexRowEnd>
            </ResponsiveFlexBetween>

            <ThirdWrapperHeaderRight>
              <div style={{marginLeft: '20px'}}>
                {showTabsSwitch && (
                  <TabSwitch {...filterComponentParser.nonShared()} />
                )}
              </div>
            </ThirdWrapperHeaderRight>
          </SecondWrapperHeader>

          <SecondWrapperChildTreeGoals
            style={{zoom: `${zoom}%`, overflow: 'hidden'}}>
            {loading && (
              <ItemLoaderWrapper style={{height: '100px'}}>
                <ItemLoaderLine />
              </ItemLoaderWrapper>
            )}
            {!loading && !data.length && (
              <div
                style={{
                  textAlign: 'center',
                }}>
                <EmptyPlaceholder {...UserGoalHighlight} />
              </div>
            )}
            <div style={{overflowX: 'auto'}}>
              {!loading &&
                data &&
                sortedTreeData(data).map((goal: any, index: number) => (
                  <TreeViewContainer
                    goal={goal}
                    index={goal.id}
                    isExpand={isExpand}
                    key={index}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    isGroup={isGroup}
                    keyResults={keyResults}
                    setIsGroup={setIsGroup}
                    isIndividual={isIndividual}
                    setIsIndividual={setIsIndividual}
                  />
                ))}
            </div>
          </SecondWrapperChildTreeGoals>
        </FirstWrapper>
      </>
    );
  },
);
