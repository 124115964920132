import {useFirebaseFetch} from '@hooks/query-hook';
import {useStoreContext} from '@store/store-context';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import dayjs from 'dayjs';
import {useCallback, useMemo} from 'react';
import nextId from 'react-id-generator';
import {Insight} from '../insights/interface';

type VisibilityType = 'private' | 'company-wide' | 'custom';

export interface Dashboard {
  id: string;
  name: string;
  isDefault?: boolean;
  createdAt: number;
  createdBy: string;
  visibility: VisibilityType;
  customAccess?: string[]; // Array of userIds for custom access
  sections: {[key: string]: any};
}

interface ISection {
  name: string;
  slug: string;
  order: number;
  id?: string;
}

const DEFAULT_SECTION: ISection = {
  name: 'Dashboard',
  slug: 'default',
  order: 1,
};

export type IDashboard = {key: string} & Dashboard;

export const useDashboardHook = (selectedDashboardId?: string) => {
  const {
    data: dashboards,
    isLoading: dashboardsLoading,
    refetch: refetchDashboard,
  } = useFirebaseFetch('dashboards');

  const {data: insights = {}, isLoading: insightsFetching} = useFirebaseFetch(
    'insights',
  );

  const {
    authStore: {auth},
  } = useStoreContext();

  const computeDashboards = useMemo((): IDashboard[] => {
    return dashboards
      ? Object.keys(dashboards)
          .map((_key) => ({
            ...dashboards[_key],
            key: _key,
            sections: dashboards[_key].sections || {},
          }))
          .filter((dashboard) => {
            if (auth.user.role === 'admin') return true;

            switch (dashboard.visibility) {
              case 'private':
                return dashboard.createdBy === auth.user.id;
              case 'team':
                return true;
              case 'custom':
                return (
                  dashboard.createdBy === auth.user.id ||
                  dashboard.customAccess?.includes(auth.user.id)
                );
              default:
                return false;
            }
          })
          .sort(
            (a, b) =>
              new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
          )
      : [];
  }, [auth.user.id, auth.user.role, dashboards]);

  const getDateRange = (quarterOffset: number = 0) => {
    const date = quarterOffset
      ? dayjs().subtract(quarterOffset, 'quarter')
      : dayjs();

    const start = date.startOf('quarter');
    const end = start.clone().endOf('quarter');

    return {
      start,
      end,
      formatted: `${start.format('MM/DD/YYYY')} - ${end.format('MM/DD/YYYY')}`,
    };
  };

  const formatInsight = useCallback((insight: Insight) => {
    const currentQuarter = getDateRange();
    const previousQuarter = getDateRange(1);

    const shouldUpdateToCurrentQuarter =
      (previousQuarter.formatted === insight.config.range.value &&
        insight.config.defaultPeriod === undefined) ||
      insight.config.defaultPeriod;

    return {
      ...insight,
      config: {
        ...insight.config,
        range: {
          ...insight.config.range,
          value: shouldUpdateToCurrentQuarter
            ? currentQuarter.formatted
            : insight.config.range.value,
        },
      },
    };
  }, []);

  const computeData = useMemo(() => {
    if (!dashboards || !selectedDashboardId) return [];

    const currentDashboard = computeDashboards.find(
      (dashboard) => dashboard.key === selectedDashboardId,
    );

    if (!currentDashboard?.sections) return [];

    return Object.entries(currentDashboard.sections)
      .map((section: any) => {
        const dashboardInsights = insights
          ? Object.entries(insights)
              .filter(
                (insight: any) =>
                  insight[1].section === section[1].slug &&
                  insight[1].dashboard?.includes(selectedDashboardId),
              )
              ?.map((insight) =>
                insight[1] ? formatInsight(insight[1] as any) : insight[1],
              )
          : [];

        return {
          firebaseId: section[0],
          ...section[1],
          canDelete: !dashboardInsights.length && section[1].slug !== 'default',
          widget: dashboardInsights.length
            ? dashboardInsights.sort(
                (a: any, b: any) => a.orderIndex - b.orderIndex,
              )
            : [],
        };
      })
      .sort((a, b) => a.order - b.order);
  }, [
    dashboards,
    selectedDashboardId,
    computeDashboards,
    insights,
    formatInsight,
  ]);

  const createDashboard = async (name: string) => {
    const timestamp = Date.now();
    const defaultSectionId = nextId('dashboard');

    const newDashboard: Dashboard = {
      name,
      id: name.toLowerCase(),
      createdAt: timestamp,
      isDefault: false,
      visibility: 'private',
      createdBy: auth.user.id,
      sections: {
        [defaultSectionId]: {
          ...DEFAULT_SECTION,
          id: defaultSectionId,
          createdAt: timestamp,
        },
      },
    };

    const dashboardRef = `dashboards/`;

    await writeFirebaseData(dashboardRef, newDashboard);
    return timestamp.toString();
  };

  const createSection = async (
    dashboardId: string,
    sectionData: {name: string; slug: string},
  ) => {
    const currentDashboard = dashboards[dashboardId];
    if (!currentDashboard) return;

    const sectionsCount = currentDashboard.sections
      ? Object.keys(currentDashboard.sections).length
      : 0;

    const newSection = {
      ...sectionData,
      order: sectionsCount + 1,
      createdAt: Date.now(),
    };

    const sectionRef = `dashboards/${dashboardId}/sections`;
    await writeFirebaseData(sectionRef, newSection);
  };

  const renameSection = async (sectionData: {name: string; slug: string}) => {
    if (selectedDashboardId) {
      const currentDashboard = dashboards[selectedDashboardId];
      if (!currentDashboard) return;

      const sections = currentDashboard.sections
        ? Object.keys(currentDashboard.sections).map((_key) => ({
            ...currentDashboard.sections[_key],
            key: _key,
          }))
        : [];

      const currentSection = sections.find(
        (section) => section.key === sectionData.slug,
      );

      if (currentSection) {
        const sectionRef = `dashboards/${selectedDashboardId}/sections/${currentSection.key}`;

        await updateFirebaseData(sectionRef, {
          ...currentSection,
          name: sectionData.name,
        });
      }
    }
  };

  const updateOrder = async (
    order: number,
    firebaseId: string,
    dashboardId: string,
  ) => {
    const currentDashboard = dashboards[dashboardId];
    if (!currentDashboard?.sections) return;

    const dashboard = currentDashboard.sections[firebaseId];
    const oldOrder = dashboard?.order;

    const allSections = Object.keys(currentDashboard.sections).map((key) => ({
      ...currentDashboard.sections[key],
      firebaseId: key,
    }));

    const _order =
      order > computeData.length ? order - 1 : order <= 1 ? 1 : order;

    const updatedData = allSections.map((item) => {
      if (item.firebaseId === firebaseId) {
        return {...item, order: _order};
      } else if (
        oldOrder < _order &&
        item.order > oldOrder &&
        item.order <= _order
      ) {
        return {...item, order: item.order - 1};
      } else if (
        oldOrder > _order &&
        item.order < oldOrder &&
        item.order >= _order
      ) {
        return {...item, order: item.order + 1};
      }
      return item;
    });

    updatedData.sort((a, b) => a.order - b.order);

    updatedData.forEach(async (data) => {
      await updateFirebaseData(
        `dashboards/${dashboardId}/sections/${data.firebaseId}`,
        data,
      );
    });
  };

  const deleteDashboard = async (dashboardId: string) => {
    const dashboard = dashboards[dashboardId];
    if (!dashboard || dashboard.isDefault) return;

    await removeFirebaseData(`dashboards/${dashboardId}`);
  };

  const deleteSection = async (sectionId: string) => {
    if (selectedDashboardId) {
      const sectionRef = `dashboards/${selectedDashboardId}/sections/${sectionId}`;

      await removeFirebaseData(sectionRef);
    }
  };

  return {
    updateFirebaseData,
    isLoading: dashboardsLoading || insightsFetching,
    dashboards: computeDashboards,
    selectedDashboard: selectedDashboardId
      ? dashboards?.[selectedDashboardId]
      : null,
    sections: computeData,
    insights,
    refetchDashboard,
    createDashboard,
    createSection,
    updateOrder,
    deleteDashboard,
    formatInsight,
    renameSection,
    deleteSection,
  };
};
