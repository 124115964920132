import {useStoreContext} from '@store/store-context';
import {useLocation} from 'react-router';
import dayjs from 'dayjs';
import {StatusType} from '@hooks';
import {useNavigate} from 'react-router';
import {cfv} from '@utils/framework';
import {ProgressType} from '@ui/interface';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {GoalsController} from '@api/goals-api/goals-controller';
import {useState, useCallback} from 'react';
import {updateKPITracker} from '@utils/firebase-request';
import {usePostHogHook} from '@hooks/post-hog';

interface EditableHeaderhook {
  title: string;
  goal: any;
  progress: {
    state: string;
    type: ProgressType;
    percent: number;
    due: string;
    status: string;
  };
  showObjectiveProgress?: boolean;
  onGoalUpdate?: (goalId: string) => void;
  onUpdateReverted?: (goalId: string) => void;
  isOpen: boolean | string;
  id: string;
  handleOpen: () => void;
  tabKind?: string;
  variant?: string;
}

export const useEditableHeaderHook = ({
  id,
  title,
  handleOpen,
  tabKind,
  goal,
  isOpen,
  onGoalUpdate,
  onUpdateReverted,
  variant,
  progress,
}: EditableHeaderhook) => {
  const {
    goalsState: {
      setEditing,
      setGoalFramework,
      setDeleteModal,
      setArchiveModal,
      setReactivateGoal,
      setIsManagerEditing,
      setEndModal,
    },

    authStore: {auth},
  } = useStoreContext();

  const goalFramework = goal?.isKpi ? 'KPI' : cfv().g_oal;

  const navigate = useNavigate();

  const [isUpdated, setIsUpdated] = useState(false);
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDuplicateGoalModal, setShowDuplicateGoalModal] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const [initialGoalPerformance, setInitialGoalPerformance] = useState('');

  const canMemberEditGoal = auth?.user?.workspace?.settings?.allowGoalUpdate;

  const [isUpdate, setIsUpdate] = useState(open ? true : false);

  const updateEditing = useCallback(() => {
    if (!isUpdate) {
      setIsUpdate(true);
      if (!isOpen) handleOpen();
    } else {
      // TODO: manage updates of few information here and validation of fields
      if (isOpen) handleOpen();
      setIsUpdate(false);
    }
  }, [isUpdate, handleOpen, isOpen]);

  const handleEditAndDelete = (option: string) => {
    switch (option) {
      case 'edit':
        setEditing(true);
        setOpen(true);
        setShowEditModal(true);
        break;

      case 'duplicate':
        setOpen(true);

        setShowDuplicateGoalModal(true);
        break;
      case 're-open':
        setReactivateGoal({
          id: id,
          name: goal?.name,
          reOpen: true,
        });
        setGoalFramework(goalFramework);
        break;
      case 'delete':
        setDeleteModal(id, true, title, goalFramework, goal.alignedGoals);
        break;
      case 'archive':
        setArchiveModal(id, true, title, goalFramework);
        break;
      case 'end':
        setEndModal(id, title, true, goalFramework);
        break;
      case 'restore':
        const request = new GoalsRequestImpl();
        const controller = new GoalsController(request);
        controller.restoreGoal({}, id);

        break;
      default:
    }
  };

  const endGoal = () => {
    setEndModal(id, title, true, goalFramework);
  };

  const {saveUpdate} = useStoreContext().storeYouGoals;

  const {
    checkinsStore: {updateUserGoals, saveUpdateCheckins},
  } = useStoreContext();

  const {postHogCapture} = usePostHogHook();

  const revertChanges = async () => {
    onUpdateReverted?.(goal.id);
  };

  const updateGoal = async (e?: any) => {
    e?.preventDefault();

    if (isUpdate) {
      if (!changesSaved) setChangesSaved(true);

      setInitialGoalPerformance('');

      goal.isKpi && updateKPITracker(goal.id, Date.now());

      setIsUpdated(false);

      postHogCapture('frontend - Goal update', undefined, {
        goalName: goal.name,
      });

      if (tabKind !== 'youcheckins') {
        if (onGoalUpdate) {
          onGoalUpdate(id);
        } else saveUpdate(id);
      } else {
        saveUpdateCheckins(id);
      }
    }

    isUpdate && tabKind === 'youcheckins' && updateUserGoals(id);
  };

  const handleManagerEdit = () => {
    setOpen(false);
    setIsManagerEditing(true);

    navigate(
      `${location.pathname}?managerEditGoal=true${
        goal?.isKpi ? '&kpi=true' : ''
      }`,
      {
        // replace: true,
        state: {
          id,
          data: JSON.stringify(goal),
        },
      },
    );
  };

  const displayArchiveOrRestore = (
    archiveStatus: string,
    notOverdueStatus: boolean,
  ) => {
    switch (notOverdueStatus) {
      case true:
        if (archiveStatus === 'archived') {
          return [
            {
              content: `Delete ${goalFramework}`,
              id: 'delete',
            },
            {
              content: 'Unarchive',
              id: 'restore',
            },
          ];
        } else {
          const edit = {
            content: `Edit ${goalFramework}`,
            id: 'edit',
          };

          const options = [
            {
              content: `Duplicate ${goalFramework}`,
              id: 'duplicate',
            },
            {
              content: `End ${goalFramework}`,
              id: 'end',
            },
            {
              content: `Delete ${goalFramework}`,
              id: 'delete',
            },
            {
              content: `Archive ${goalFramework}`,
              id: 'archive',
            },
          ];
          if (auth.user.role !== 'admin') {
            switch (canMemberEditGoal) {
              case 'forever':
                options.unshift(edit);
                break;
              case '24 hours':
                if (
                  Math.abs(
                    dayjs(goal?.createdAt).diff(dayjs(Date.now()), 'hours'),
                  ) < 24
                ) {
                  options.unshift(edit);
                }

                break;
              case '48 hours':
                if (
                  Math.abs(
                    dayjs(goal?.createdAt).diff(dayjs(Date.now()), 'hours'),
                  ) < 48
                ) {
                  options.unshift(edit);
                }

                break;

              default:
                break;
            }
          } else {
            options.unshift(edit);
          }

          return options;
        }
      case false:
        if (archiveStatus === 'archived') {
          return [
            {
              content: `Delete ${goalFramework}`,
              id: 'delete',
            },
            {
              content: `Restore ${goalFramework}`,
              id: 'restore',
            },
          ];
        } else {
          const options = [
            {
              content: `Delete ${goalFramework}`,
              id: 'delete',
            },
          ];
          if (
            auth?.user?.workspace?.settings?.autoEndGoals === 'enabled' &&
            auth?.user?.role === 'admin' &&
            variant === 'all-objectives'
          ) {
            options.push({
              content: `Re-open ${goalFramework}`,
              id: 're-open',
            });
          }

          if (
            (goal?.status === StatusType.INCOMPLETED ||
              goal?.status === StatusType.COMPLETED) &&
            auth?.user?.role === 'admin'
          ) {
            options.unshift({
              content: `Duplicate ${goalFramework}`,
              id: 'duplicate',
            });
          }

          if (goal?.status !== 'completed' && goal?.status === 'in_complete') {
            options.push({
              content: `Archive ${goalFramework}`,
              id: 'archive',
            });
          }
          return options;
        }
      default:
        return [];
    }
  };

  const computeShowSaveChanges = () => {
    if (
      progress?.status === StatusType.COMPLETED ||
      progress?.status === StatusType.INCOMPLETED
    ) {
      if (isUpdate) {
        return false;
      } else {
        return true;
      }
    } else return true;
  };

  const getLastActivity = goal.activities?.[goal.activities.length - 1];

  const getLastUpdatedDay = dayjs(dayjs().format()).diff(
    dayjs(goal?.updatedAt).format(),
    'days',
  );

  const formatColor = (value: number) => {
    if (value > 0) {
      return 'green300';
    }
    if (value < 0) return 'red400';
    return 'textMedium';
  };
  return {
    computeShowSaveChanges,
    handleEditAndDelete,
    displayArchiveOrRestore,
    isUpdated,
    showEditModal,
    isUpdate,
    revertChanges,
    setShowEditModal,
    setIsUpdate,
    formatColor,
    goalFramework,
    getLastActivity,
    updateEditing,
    getLastUpdatedDay,
    setInitialGoalPerformance,
    setShowDuplicateGoalModal,
    changesSaved,
    setIsUpdated,
    setChangesSaved,
    open,
    setOpen,
    showDuplicateGoalModal,
    initialGoalPerformance,
    handleManagerEdit,
    updateGoal,
    endGoal,
  };
};
