import React, {ReactNode, useState, useCallback} from 'react';
import {Button} from '@ui/atoms/button';
import {Checkbox} from '@ui/atoms/checkbox';
import dayjs from 'dayjs';
import {ModalCard} from '@ui/layouts/modal-card';
import {Modal} from '@ui/molecules/modal';
import {ModalWrapper} from '../view-meetings/styles';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Body2, Headline2} from '@ui/atoms/typography';
import {ArrowHeadDownIcon, CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {TextField} from '@ui/molecules/field/textfield';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {RadioSm} from '@ui/atoms/radio';
import {Calendar} from '@ui/molecules/calendar';

type RecurrenceType = 'daily' | 'weekly' | 'monthly' | 'yearly';
type MonthlyType = 'dayOfMonth' | 'dayOfWeek';
type EndType = 'never' | 'on' | 'after';

interface WeekDay {
  index: number;
  name: string;
  shortName: string;
}

export interface RecurrenceRule {
  type: RecurrenceType;
  interval: number;
  weeklyDays: number[];
  monthlyType: MonthlyType;
  end: {
    type: EndType;
    date: string;
    occurrences: number;
  };
}

interface CustomRecurrenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (rule: RecurrenceRule) => void;
  initialDate: string;
  initialRule?: RecurrenceRule;
  value?: RecurrenceRule;
}

const Card = ({title, children}: {title: string; children: ReactNode}) => {
  return (
    <div className="border border-borderLight rounded-lg mb-4">
      <div className="py-3 px-4 border-b border-borderLight">
        <Body2 weight="bold">{title}</Body2>
      </div>
      <div className="p-4">{children}</div>
    </div>
  );
};

const weekDays: WeekDay[] = [
  {index: 0, name: 'Sunday', shortName: 'Sun'},
  {index: 1, name: 'Monday', shortName: 'Mon'},
  {index: 2, name: 'Tuesday', shortName: 'Tue'},
  {index: 3, name: 'Wednesday', shortName: 'Wed'},
  {index: 4, name: 'Thursday', shortName: 'Thu'},
  {index: 5, name: 'Friday', shortName: 'Fri'},
  {index: 6, name: 'Saturday', shortName: 'Sat'},
];

const weekNumbers = ['First', 'Second', 'Third', 'Fourth', 'Last'] as const;

const CustomRecurrenceModal: React.FC<CustomRecurrenceModalProps> = ({
  isOpen,
  onClose,
  onSave,
  initialDate,
  initialRule,
  value,
}) => {
  const [recurrenceType, setRecurrenceType] = useState<RecurrenceType>(
    value?.type || initialRule?.type || 'daily',
  );
  const [interval, setInterval] = useState<number>(
    value?.interval || initialRule?.interval || 1,
  );
  const [weeklyDays, setWeeklyDays] = useState<number[]>(
    value?.weeklyDays || initialRule?.weeklyDays || [dayjs(initialDate).day()],
  );
  const [monthlyType, setMonthlyType] = useState<MonthlyType>(
    value?.monthlyType || initialRule?.monthlyType || 'dayOfMonth',
  );
  const [endType, setEndType] = useState<EndType>(
    value?.end.type || initialRule?.end.type || 'never',
  );
  const [endDate, setEndDate] = useState<string>(
    value?.end.date ||
      dayjs(initialDate).add(1, 'year').format('DD-MM-YY') ||
      '',
  );

  const [endOccurrences, setEndOccurrences] = useState<number>(
    value?.end.occurrences || initialRule?.end.occurrences || 1,
  );

  const handleWeeklyDayToggle = (day: number): void => {
    setWeeklyDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day],
    );
  };

  const getMonthlyDayText = useCallback((): string => {
    const date = dayjs(initialDate);

    const dayOfMonth = date.date();
    const weekNum = Math.ceil(dayOfMonth / 7);

    const dayName = weekDays[date.day()].name;

    return monthlyType === 'dayOfMonth'
      ? `Monthly on day ${dayOfMonth}`
      : `Monthly on ${weekNumbers[weekNum - 1]} ${dayName}`;
  }, [initialDate, monthlyType]);

  const handleIntervalChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value: any = parseInt(e.target.value) || '';

    if (!isNaN(value)) {
      setInterval(value);
    }
  };

  const getRecurrenceTypeText = (): string => {
    switch (recurrenceType) {
      case 'daily':
        return 'Day';
      case 'weekly':
        return 'Week';
      case 'monthly':
        return 'Month';
      case 'yearly':
        return 'Year';
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalWrapper>
        <ModalCard title="" modalStyle={{padding: '24px'}}>
          <div>
            <FlexRowSpaceBetween>
              <Headline2>Custom recurrence</Headline2>
              <button
                onClick={() => {
                  onSave({
                    type: recurrenceType,
                    interval,
                    weeklyDays,
                    monthlyType,
                    end: {
                      type: endType,
                      date: endDate,
                      occurrences: endOccurrences,
                    },
                  });

                  onClose();
                }}>
                <CancelIcon style={{cursor: 'pointer'}} />
              </button>
            </FlexRowSpaceBetween>

            <VerticalSpacer size="24px" />

            <Card title="Repeat every">
              <div className="flex items-center gap-4">
                <TextField
                  type="number"
                  margin
                  value={interval}
                  onChange={handleIntervalChange}
                  inputStyle={{
                    width: '77px',
                    height: '43px',
                    borderRadius: '10px',
                  }}
                />

                <DropdownCustom
                  collapseOnClick={true}
                  menu={(handleClose: () => void) => (
                    <div>
                      {([
                        'daily',
                        'weekly',
                        'monthly',
                        'yearly',
                      ] as RecurrenceType[]).map((type) => (
                        <DropdownItem
                          key={type}
                          onClick={() => {
                            setRecurrenceType(type);

                            handleClose();
                          }}>
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </DropdownItem>
                      ))}
                    </div>
                  )}
                  customComponent={(
                    handleOpen: (event: any) => void,
                    open: boolean,
                  ) => (
                    <Button
                      onClick={handleOpen}
                      kind="secondary"
                      style={{
                        padding: '8px 16px',
                        minWidth: '140px',
                        display: 'flex',
                      }}
                      className="justify-between items-center">
                      {getRecurrenceTypeText()}

                      <ArrowHeadDownIcon
                        style={{
                          marginLeft: 4,
                          transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                          stroke: '#1E1E2F',
                        }}
                      />
                    </Button>
                  )}
                  customDropdownWrapperStyles={{
                    top: '130%',
                    right: '0px',
                    width: '160%',
                  }}
                />
              </div>
            </Card>

            {recurrenceType === 'weekly' && (
              <Card title="Repeat on">
                <div className="flex flex-wrap gap-4">
                  {weekDays.map((day) => (
                    <div
                      key={day.name}
                      className="gap-2 flex flex-col items-center">
                      <Body1 weight="bold">{day.shortName}</Body1>

                      <Checkbox
                        checked={weeklyDays.includes(day.index)}
                        onChange={() => handleWeeklyDayToggle(day.index)}
                      />
                    </div>
                  ))}
                </div>
              </Card>
            )}

            {recurrenceType === 'monthly' && (
              <Card title="Repeat on">
                <div>
                  <DropdownCustom
                    collapseOnClick={true}
                    menu={(handleClose: () => void) => (
                      <div>
                        <DropdownItem
                          onClick={() => {
                            setMonthlyType('dayOfMonth');
                            handleClose();
                          }}>
                          Monthly on day {dayjs(initialDate).date()}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setMonthlyType('dayOfWeek');
                            handleClose();
                          }}>
                          {`Monthly on ${
                            weekNumbers[
                              Math.ceil(dayjs(initialDate).date() / 7) - 1
                            ]
                          } ${weekDays[dayjs(initialDate).day()].name}`}
                        </DropdownItem>
                      </div>
                    )}
                    customComponent={(
                      handleOpen: (event: any) => void,
                      open: boolean,
                    ) => (
                      <Button
                        onClick={handleOpen}
                        kind="secondary"
                        style={{
                          padding: '8px 16px',
                          minWidth: '140px',
                          display: 'flex',
                        }}
                        className="justify-between items-center">
                        {getMonthlyDayText()}

                        <ArrowHeadDownIcon
                          style={{
                            marginLeft: 4,
                            transform: !open
                              ? 'rotate(0deg)'
                              : 'rotate(180deg)',
                            stroke: '#1E1E2F',
                          }}
                        />
                      </Button>
                    )}
                    customDropdownWrapperStyles={{
                      top: '130%',
                      left: '0px',
                      width: '50%',
                    }}
                  />
                </div>
              </Card>
            )}

            <Card title="Custom rule ends">
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-2">
                  <RadioSm
                    checked={endType === 'never'}
                    onChange={() => setEndType('never')}
                  />

                  <Body1>Never</Body1>
                </div>

                <div className="flex items-center justify-between gap-4">
                  <FlexRow className="gap-3">
                    <RadioSm
                      checked={endType === 'on'}
                      onChange={() => setEndType('on')}
                    />
                    <Body1>On</Body1>
                  </FlexRow>

                  <div className="flex items-center gap-3">
                    <CustomDateSelect
                      value={endDate}
                      onChange={(e) => setEndDate(e)}
                      disabledBefore={dayjs().format('YYYY-MM-DD')}
                    />
                  </div>

                  <div />
                </div>

                <div className="flex items-center justify-between gap-4">
                  <FlexRow className="gap-3">
                    <RadioSm
                      checked={endType === 'after'}
                      onChange={() => setEndType('after')}
                    />

                    <Body1>After</Body1>
                  </FlexRow>

                  <div className="flex items-center gap-3">
                    <TextField
                      type="number"
                      margin
                      value={endOccurrences}
                      onChange={(e) =>
                        setEndOccurrences(parseInt(e.target.value))
                      }
                      inputStyle={{
                        width: '77px',
                        height: '43px',
                        borderRadius: '10px',
                      }}
                    />

                    <Body1>Occurrence(s)</Body1>
                  </div>

                  <div />
                </div>
              </div>
            </Card>
          </div>
        </ModalCard>
      </ModalWrapper>
    </Modal>
  );
};

export default CustomRecurrenceModal;

interface CustomDateSelectProps {
  value: string;
  onChange: (value: string) => void;
  disabledBefore?: string;
  disabledAfter?: string;
}

const CustomDateSelect = ({
  value,
  onChange,
  disabledAfter,
  disabledBefore,
}: CustomDateSelectProps) => {
  return (
    <DropdownCustom
      collapseOnClick={true}
      menu={(handleClose: () => void) => (
        <div>
          <Calendar
            value={dayjs(value, 'DD-MM-YY').format()}
            disabledAfter={dayjs(disabledAfter, 'DD-MM-YY').format()}
            disabledBefore={dayjs(disabledBefore, 'DD-MM-YY').format()}
            onChange={(value) => {
              onChange(dayjs(value).format('DD-MM-YY'));
              handleClose();
            }}
          />
        </div>
      )}
      customComponent={(handleOpen: (event: any) => void, open: boolean) => (
        <>
          <button
            onClick={handleOpen}
            className="rounded-[10px] min-h-[40px] min-w-[177px] border bg-white w-full border-borderDark py-3 px-4 flex items-center justify-between">
            <Body2 kind="textBody">
              {' '}
              {value
                ? dayjs(value, 'DD-MM-YY').format('ddd. DD MMM. YYYY')
                : ''}
            </Body2>
          </button>
        </>
      )}
      customDropdownWrapperStyles={{
        top: '130%',
        right: '0px',
        background: 'none',
        padding: 0,
        boxShadow: '0px',
        border: 'none',
        width: '140%',
        minWidth: '260px',
      }}
    />
  );
};
