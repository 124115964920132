import styled from 'styled-components';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {cloudinary} from '@assets/images/cloudinary';
import {ReactNode, useCallback, useState, useEffect} from 'react';
import {Body2, Body1} from '@ui/atoms/typography';
import Lines from '../../../../../../../assets/images/line.png';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {RadioChildSm} from '@ui/atoms/radio/radio';
import {capitalize} from '@utils';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {sumArray} from '@utils/array';
import {cfv} from '@utils/framework';
import {GoalsRequestImpl} from '@api/goals-api/goals-request';
import {ButtonNavigation} from '@ui/molecules/button-nav';
import {GoalsController} from '@api/goals-api/goals-controller';
import {HorizontalSpacer} from '@ui/atoms/spacer';
import {useStoreContext} from '@store/store-context';
import {UserListCard} from '@ui/molecules/user/list-card';
import {parseCurrencyValue} from '@constants';
import {ChildGoal} from '../../../../../reporting/reporting-pages/admin-analytics/analytics-pages/analytics-status/analytics--status-graph';
import {MeasurementType} from '@hooks';
import {filterDuplicateItems} from '@utils/array';
const {emptyGoals: emptyGoal} = cloudinary;

const Wrapper = styled.div<{bodyPadding?: string}>`
  border: 1px solid #ededf2;
  margin-top: 8%;
  border-radius: 10px;
  scrollbar-width: none;
  div::-webkit-scrollbar {
    display: none;
  }

  .heading {
    padding: 16px;
  }
  .body {
    border-top: 1px solid #ededf2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: ${({bodyPadding}) => (bodyPadding ? bodyPadding : '16px')};
  }
`;
const CombinedWrapper = styled.div`
  border: solid #ededf2;
  border-width: 1px;
  margin-top: 8%;
  border-radius: 10px;
  .body {
    padding: 16px;
    border-top: 1px solid #ededf2;
    border-radius: 10px;
  }
`;
const ContributionWrapper = styled.div`
  background: #f6f6fe;
  border-radius: 8px;
  padding: 8px;
  margin-left: 4px;

  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
interface IContainer {
  title: string;
  children: ReactNode;
}

export const Container = ({title, children}: IContainer) => {
  return (
    <Wrapper>
      <div className="heading">
        <Body2 weight="bold"> {title}</Body2>
      </div>
      <div className="body">{children}</div>
    </Wrapper>
  );
};
interface IObj {
  childGoals: any[];
  goalAlignment?: any;
  goal: any;
}
const EmptyObj = {
  image: {
    alt: 'alt text',
    shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
    src: emptyGoal,
    style: {height: '30%', width: '30%'},
  },
  subtitle: '',
  size: 'empty',
  variant: 'default' as any,
  title: `You have no aligned ${cfv(undefined, true).g_oals}.`,
};
const Rule = styled.div`
  width: 100%;
  border-top: 1px solid #ededf2;
  margin: 15px 0px;
`;

export const ItemLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HintBlock = styled.div`
  background: #f6f6f8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  text-align: center;
  margin-bottom: 16px;
`;

const ImageWrapper = styled.div`
  .image {
    position: absolute;
    left: 11.7px;
    z-index: 0;
    top: 24px;
    height: calc(100% + 50px);
    width: 1px;
  }
`;

export const AlignedObjectives = ({
  childGoals = [],
  goalAlignment,
  goal,
}: IObj) => {
  const parentGoalLength = goalAlignment
    ? Object.keys(goalAlignment)?.length
    : 0;

  const [parentKeyResults, setParentkeyResults] = useState<any>([]);

  const isGoalCascaded = goal?.keyResults?.filter(
    (kr: any) =>
      kr.alignedKeyResults?.length > 0 || kr?.keyResultAlignment?.keyResult,
  );

  const {
    usersStore: {users},
  } = useStoreContext();

  const parentsKr = useCallback(async () => {
    return await Promise.all(
      isGoalCascaded?.map(async (kr: any) => {
        if (kr.keyResultAlignment?.keyResult) {
          const request = new GoalsRequestImpl();
          const controller = new GoalsController(request);
          const response = await controller.getKeyResult(
            kr.keyResultAlignment?.keyResult,
          );
          const _response = {
            ...response,
            assignee:
              typeof response.assignee === 'object'
                ? response.assignee
                : users.find((user) => user.id === response.assignee),
          };
          return _response;
        }
        return {};
      }),
    );
  }, [isGoalCascaded, users]);

  useEffect(() => {
    const fetchParentKr = goal?.keyResults.filter(
      (kr: any) => kr?.keyResultAlignment?.keyResult,
    );
    if (fetchParentKr.length > 0) {
      parentsKr().then((kr) => setParentkeyResults(kr));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal?.keyResults]);

  const hasChildKeyResult = goal?.keyResults?.filter(
    (kr: any) => kr.alignedKeyResults?.length > 0,
  );

  const [tabs, setActiveTabs] = useState({
    cascade: false,
    alignment: true,
  });

  const handleTabs = (value: boolean, field: keyof typeof tabs) => {
    setActiveTabs({
      alignment: false,
      cascade: false,
      [field]: value,
    });
  };

  const goalMeasurementType = (type: string) => {
    switch (type) {
      case 'key results':
        return 'average of only the parent key results';

      case 'children':
        return 'average of only child objectives';
      case 'all':
        return 'average of the parent key results and child objectives';

      default:
        break;
    }
  };

  const CascadeView = () => {
    return (
      <div>
        {hasChildKeyResult.length === 0 ? (
          <HintBlock>
            <Body2 kind="textBody" align="center">
              This objective has{' '}
              <span style={{fontWeight: 500}}>cascaded key result(s)</span>{' '}
              contributing to the progress of a parent key result.
            </Body2>
          </HintBlock>
        ) : (
          <HintBlock>
            <Body2 kind="textBody" align="center">
              This objective has a{' '}
              <span style={{fontWeight: 500}}>parent key result</span>{' '}
            </Body2>
          </HintBlock>
        )}

        {parentKeyResults.length > 0 &&
          hasChildKeyResult.length === 0 &&
          filterDuplicateItems(parentKeyResults, 'id').map(
            (parentKr: any, idx: number) => (
              <div
                key={parentKr.id}
                style={{
                  borderBottom: filterDuplicateItems(parentKeyResults, 'id')[
                    idx + 1
                  ]
                    ? '1px solid #ededf2'
                    : '',
                }}>
                <VerticalSpacer size="16px" />

                <Body2 weight="semibold">
                  Parent {cfv(undefined, true).k_r}(s)
                </Body2>
                <VerticalSpacer size="10px" />
                <ParentKeyResults parentKeyResult={parentKr} goal={goal} />
                <VerticalSpacer size="16px" />
                <>
                  <Body2 weight="semibold">
                    Cascaded {cfv(undefined, true).k_r}(s)
                  </Body2>
                  <VerticalSpacer size="10px" />
                  {parentKr.alignedKeyResults?.map(
                    (childKeyResult: any, index: number, goals: any) => (
                      <>
                        <ChildKeyResult
                          childGoal={childKeyResult}
                          goal={goal}
                          index={index + 1}
                          activeCascade={
                            !!goal.keyResults?.find(
                              (kr: any) => kr.id === childKeyResult.id,
                            )
                          }
                          alignmentTarget={parentKr.alignmentTarget}
                          measurement={parentKr.measurement}
                          size={goals[index + 1] ? true : undefined}
                          variant="member"
                        />
                      </>
                    ),
                  )}
                </>
              </div>
            ),
          )}

        {hasChildKeyResult.length > 0 && (
          <>
            <Body2 weight="semibold">
              Parent {capitalize(cfv(undefined, true).k_r)}(s)
            </Body2>
            <VerticalSpacer size="10px" />
            {hasChildKeyResult.map((childKeyResult: any) => (
              <ParentKeyResults parentKeyResult={childKeyResult} goal={goal} />
            ))}
          </>
        )}

        {hasChildKeyResult.length > 0 && (
          <>
            <Body2 weight="semibold">
              Cascaded {cfv(undefined, true).k_r}(s)
            </Body2>
            <VerticalSpacer size="10px" />

            {hasChildKeyResult.map((childKeyResult: any) => (
              <>
                {childKeyResult.alignedKeyResults.map(
                  (childKr: any, idx: number, goals: any) => (
                    <ChildKeyResult
                      childGoal={childKr}
                      goal={goal}
                      index={idx + 1}
                      alignmentTarget={childKeyResult.alignmentTarget}
                      measurement={childKeyResult.measurement}
                      size={goals[idx + 1] ? true : undefined}
                      variant="member"
                    />
                  ),
                )}
              </>
            ))}
          </>
        )}
      </div>
    );
  };

  const AlignmentView = () => {
    return (
      <div>
        {parentGoalLength === 0 && childGoals?.length === 0 && (
          <EmptyPlaceholder {...EmptyObj} />
        )}

        {goalAlignment?.id === goal.id && (
          <HintBlock>
            <Body2 kind="textBody" align="center">
              This objective’s progress depends on the
              <span style={{fontWeight: 500}}>
                {' '}
                {goalMeasurementType(goal?.goalMeasurement)}{' '}
              </span>
            </Body2>
          </HintBlock>
        )}

        {parentGoalLength > 0 && (
          <>
            {' '}
            <ParentObjective parentGoal={goalAlignment} goal={goal} />
          </>
        )}
        {parentGoalLength > 0 && childGoals.length > 0 && <Rule />}

        {childGoals.length > 0 && (
          <>
            <Body2 weight="semibold">
              Child {cfv(undefined, true).g_oal}(s)
            </Body2>
            <VerticalSpacer size="10px" />

            {childGoals.map((_goal, idx: number, goals) => (
              <ChildGoal
                childGoal={_goal}
                index={idx + 1}
                activeGoal={_goal.id === goal.id}
                size={childGoals.length}
                variant="member"
              />
            ))}
          </>
        )}
      </div>
    );
  };

  if ((childGoals?.length > 0 || goalAlignment) && isGoalCascaded?.length > 0) {
    const navigation = [
      {
        active: tabs.alignment,
        href: '#',
        id: '1',
        onClick: () => handleTabs(!tabs.alignment, 'alignment'),
        title: 'Alignment',
      },
      {
        active: tabs.cascade,
        href: '#',
        id: '2',
        onClick: () => handleTabs(!tabs.cascade, 'cascade'),
        title: 'Cascaded Key result',
      },
    ];

    return (
      <CombinedWrapper>
        <ButtonNavigation
          navigations={navigation}
          variant="peer"
          removeLastBorder
        />
        <div className="body">
          {tabs.cascade && <CascadeView />}
          {tabs.alignment && <AlignmentView />}
        </div>
      </CombinedWrapper>
    );
  }

  if (isGoalCascaded?.length > 0) {
    return (
      <Container title={'Cascaded Key result'}>
        <CascadeView />
      </Container>
    );
  }
  return (
    <Container title={`Alignment`}>
      <AlignmentView />{' '}
    </Container>
  );
};

export const ChildKeyResult = ({
  childGoal,
  size,
  measurement,
  alignmentTarget,
  activeCascade,
}: any) => {
  const {
    usersStore: {users},
  } = useStoreContext();

  return (
    <>
      <div style={{width: '100%', padding: '10px 5px'}}>
        <FlexRowSpaceBetween>
          <div style={{display: 'flex'}}>
            <div style={{position: 'relative'}}>
              <RadioChildSm checked bg="#084B8A" />
              {size ? (
                <ImageWrapper>
                  <img src={Lines} alt="" className="image" />
                </ImageWrapper>
              ) : null}
            </div>
            <div style={{width: '100%'}}>
              <Body1
                style={{marginLeft: '10px'}}
                weight={activeCascade ? 'semibold' : 'regular'}>
                {capitalize(childGoal.name)}
              </Body1>
            </div>
          </div>
          <ContributionWrapper>
            <Body2 style={{fontSize: '12px'}} kind="textDark">
              Contributing:{' '}
            </Body2>
            <Body2
              style={{
                fontSize: '12px',
                marginLeft: '5px',
                whiteSpace: 'nowrap',
              }}
              kind="textDark">
              {' '}
              {childGoal.keyResultAlignment.value || 0}
              {measurement?.unit === MeasurementType.PERCENTAGE
                ? '%'
                : measurement?.unit === MeasurementType.CURRENCY
                ? parseCurrencyValue(measurement.unit?.symbol)
                : ''}{' '}
              of {alignmentTarget}{' '}
              {measurement?.unit === MeasurementType.PERCENTAGE
                ? '%'
                : measurement?.unit === MeasurementType.CURRENCY
                ? parseCurrencyValue(measurement.unit?.symbol)
                : ''}{' '}
            </Body2>
          </ContributionWrapper>
        </FlexRowSpaceBetween>

        <div style={{margin: '6px 25px 5px 35px'}}>
          {users
            .filter((user) => user.id === childGoal.assignee)
            .map((user) => (
              <UserListCard
                kind="admin"
                type="aligned"
                tooltip={true}
                reviewer={user.reviewer}
                avatar={user.avatar ? user.avatar.url : ''}
                name={
                  user.firstName && user.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : ` ... (pending)`
                }
                userId={user.id}
              />
            ))}
        </div>
      </div>
    </>
  );
};

const ParentKeyResults = ({parentKeyResult, goal}: any) => {
  const getCascadedKeyResult = goal.keyResults?.find(
    (kr: any) => kr.id === parentKeyResult.id,
  );
  const getValues = sumArray(
    parentKeyResult.alignedKeyResults.map(
      (kr: any) => kr.keyResultAlignment?.value,
    ),
  );

  const parentContribution = parentKeyResult.alignmentTarget - getValues;

  return (
    <>
      <FlexRowSpaceBetween>
        <div
          style={{
            display: 'flex',
          }}>
          <div style={{position: 'relative'}}>
            <RadioChildSm checked bg="#084B8A" />
          </div>
          <HorizontalSpacer size="10px" />

          <Body1 weight={getCascadedKeyResult ? 'semibold' : 'regular'}>
            {capitalize(parentKeyResult.name)}
          </Body1>
        </div>
        <ContributionWrapper>
          <Body2 style={{fontSize: '12px'}} kind="textDark">
            Contributing:{' '}
          </Body2>
          <Body2
            style={{
              fontSize: '12px',
              marginLeft: '5px',
              whiteSpace: 'nowrap',
            }}
            kind="textDark">
            {' '}
            {parentContribution || 0}
            {parentKeyResult.measurement?.unit === MeasurementType.PERCENTAGE
              ? '%'
              : parentKeyResult.measurement?.unit === MeasurementType.CURRENCY
              ? parseCurrencyValue(parentKeyResult.measurement.unit?.symbol)
              : ''}{' '}
            of {parentKeyResult.alignmentTarget}{' '}
            {parentKeyResult.measurement?.unit === MeasurementType.PERCENTAGE
              ? '%'
              : parentKeyResult.measurement?.unit === MeasurementType.CURRENCY
              ? parseCurrencyValue(parentKeyResult.measurement.unit?.symbol)
              : ''}{' '}
          </Body2>
        </ContributionWrapper>
      </FlexRowSpaceBetween>

      <VerticalSpacer size="5px" />
      <div style={{margin: '0px 25px 10px 35px'}}>
        <UserListCard
          kind="admin"
          type="aligned"
          tooltip={true}
          reviewer={parentKeyResult?.assignee.reviewer}
          avatar={
            parentKeyResult?.assignee.avatar
              ? parentKeyResult?.assignee.avatar.url
              : ''
          }
          name={
            parentKeyResult?.assignee.firstName +
            '  ' +
            parentKeyResult?.assignee.lastName
          }
          userId={parentKeyResult?.assignee.id}
        />
      </div>
      <VerticalSpacer size="10px" />
    </>
  );
};

const ParentObjective = ({parentGoal, goal}: any) => {
  const {
    usersStore: {users},
  } = useStoreContext();

  const assignee =
    typeof parentGoal.assignee === 'object'
      ? parentGoal.assignee
      : users.find((user) => user.id === parentGoal.assignee);

  return (
    <>
      <Body2 weight="semibold">
        Parent {capitalize(cfv(undefined, true).g_oal)}
      </Body2>
      <VerticalSpacer size="10px" />
      <div
        style={{
          display: 'flex',
        }}>
        <div style={{position: 'relative'}}>
          <RadioChildSm checked bg="#1A9E68" />
        </div>
        <HorizontalSpacer size="10px" />

        <Body1 weight={goal.id === parentGoal.id ? 'semibold' : 'regular'}>
          {capitalize(parentGoal.name)}
        </Body1>
      </div>
      <VerticalSpacer size="5px" />
      <div style={{margin: '0px 25px 10px 35px'}}>
        <UserListCard
          kind="admin"
          type="aligned"
          tooltip={true}
          reviewer={assignee?.reviewer}
          avatar={assignee?.avatar ? assignee?.avatar.url : ''}
          name={assignee?.firstName + '  ' + assignee?.lastName}
          userId={assignee?.id}
        />
      </div>
    </>
  );
};
