import ReactSelect, {
  components,
  OptionsType,
  ValueType,
  ActionMeta,
  Props,
  IndicatorProps,
  OptionProps,
  ControlProps,
  MenuProps,
} from 'react-select';
import {MagnifyingGlassIcon} from '../../../atoms/icons/magnifying-glass';

import {
  IndicatorWrapper,
  getStyles,
  AvatarWrapper,
  ControlWrapper,
} from './styled';
import {HorizontalSpacer} from '../../../atoms/spacer';
import {Body2, CTA} from '../../../atoms/typography';
import {forwardRef, Ref, useMemo, useRef} from 'react';
import {Checkbox} from '../../../atoms/checkbox';
import {GreenWhitePlusIcon} from '@ui/atoms/icons/greenWhitePlus';

interface IOption {
  label: string;
}

const filterOptionsSelectOptions = (
  option: {data: IOption},
  rawInput: string,
) => {
  if (rawInput) {
    const label = option.data.label.toLowerCase();
    return rawInput
      .toLowerCase()
      .split(' ')
      .every((word) => label.includes(word));
  }
  return true;
};

const {
  DropdownIndicator: SelectDropdownIndicator,
  Option: SelectOption,
  Control: SelectControl,
  Menu: MenuOptions,
} = components;

export interface TOption {
  value: string;
  label: string;
}
export interface SelectFieldProps extends Props<any> {
  leadingNote?: string;
  options: OptionsType<any>;
  onChange?: (value: ValueType<any, false>, action: ActionMeta<any>) => void;
  loading?: boolean;
}

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <SelectDropdownIndicator {...props}>
      <IndicatorWrapper>
        <MagnifyingGlassIcon />
      </IndicatorWrapper>
    </SelectDropdownIndicator>
  );
};

const Control = (props: ControlProps<TOption, false>) => {
  return (
    <ControlWrapper>
      {props.selectProps.excludeSearchBox ? null : <SelectControl {...props} />}
    </ControlWrapper>
  );
};

const Option = (props: OptionProps<TOption, false>) => {
  const values: any = props.selectProps?.value;

  const res = values?.find((item: any) => item.label === props.label);

  const addCustomOption = props.label.toLowerCase() === 'custom';

  return (
    <>
      <SelectOption {...props}>
        {addCustomOption ? (
          <div
            className="flex items-center "
            style={{
              padding: '0px 8px',
            }}
            onClick={() => {
              props.selectProps?.onClickCustomOption();

              props.selectProps?.handleClose &&
                props.selectProps?.handleClose();
            }}>
            <GreenWhitePlusIcon /> <HorizontalSpacer size="16px" />{' '}
            <CTA kind="textDark" style={{fontWeight: 600}}>
              {props.selectProps.customOptionName || ''}
            </CTA>
          </div>
        ) : (
          <AvatarWrapper>
            {props.selectProps?.excludeCheckBox ? (
              <></>
            ) : (
              <>
                <Checkbox checked={props.isSelected} />
                <HorizontalSpacer size="8px" />
              </>
            )}
            <HorizontalSpacer size="8px" />

            <Body2
              kind={res?.label === props.label ? 'textDark' : 'textBody'}
              style={
                res?.label === props.label
                  ? {fontWeight: 500}
                  : {fontWeight: 400}
              }>
              {(props.label as unknown) as string}
            </Body2>
          </AvatarWrapper>
        )}
      </SelectOption>
    </>
  );
};
const Menu = (props: MenuProps<TOption, false>) => {
  return (
    <>
      <MenuOptions {...props}>
        <div>{props.children}</div>
      </MenuOptions>
    </>
  );
};

export const OptionsMultiSelect = forwardRef(
  (
    {
      options,
      onChange,
      value,
      excludeCheckBox,
      maxSelection,
      noOptionMessage,
      excludeSearchBox,
      searchPlaceholder,
      ...rest
    }: any,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);

    return (
      <ReactSelect
        {...rest}
        isMulti
        isClearable={false}
        excludeCheckBox={excludeCheckBox}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuIsOpen
        excludeSearchBox={excludeSearchBox}
        filterOption={filterOptionsSelectOptions}
        isSearchable
        value={value}
        isOptionDisabled={() =>
          maxSelection ? value.length >= maxSelection : false
        }
        noOptionsMessage={() => noOptionMessage}
        tabSelectsValue={false}
        placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
        onChange={onChange}
        ref={ref}
        maxMenuHeight={240}
        components={{
          DropdownIndicator,
          Option,
          Control,
          Menu,
          IndicatorSeparator: null,
        }}
        options={options}
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 40,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
export const OptionsMultiSelectAll = forwardRef(
  (
    {
      options,
      onChange,
      value,
      excludeCheckBox,
      maxSelection,
      noOptionMessage,
      searchPlaceholder,
      ...rest
    }: any,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);
    const valueRef = useRef(value);
    valueRef.current = value;

    const selectAllOption = {
      value: '<SELECT_ALL>',
      label: 'All ',
    };

    const isSelectAllSelected = () =>
      valueRef.current.length === options.length;

    const isOptionSelected = (option: {value: string; label: string}) =>
      valueRef.current.some(({value}: any) => value === option.value) ||
      isSelectAllSelected();

    const getOptions = () => [selectAllOption, ...options];

    const getValue = () => (isSelectAllSelected() ? [selectAllOption] : value);

    const onChangeAction = (newValue: any, actionMeta: any) => {
      const {action, option, removedValue} = actionMeta;

      if (
        action === 'select-option' &&
        option.value === selectAllOption.value
      ) {
        onChange(options, actionMeta);
      } else if (
        (action === 'deselect-option' &&
          option.value === selectAllOption.value) ||
        (action === 'remove-value' &&
          removedValue.value === selectAllOption.value)
      ) {
        onChange([], actionMeta);
      } else if (
        actionMeta.action === 'deselect-option' &&
        isSelectAllSelected()
      ) {
        onChange(
          options.filter(
            ({value}: {value: string; label: string}) => value !== option.value,
          ),
          actionMeta,
        );
      } else {
        onChange(newValue || [], actionMeta);
      }
    };

    return (
      <ReactSelect
        {...rest}
        isMulti
        isClearable={false}
        excludeCheckBox={excludeCheckBox}
        isOptionSelected={isOptionSelected}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuIsOpen
        filterOption={filterOptionsSelectOptions}
        // isSearchable
        isOptionDisabled={() =>
          maxSelection ? getValue().length >= maxSelection : false
        }
        value={getValue()}
        noOptionsMessage={() => noOptionMessage}
        tabSelectsValue={false}
        placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
        onChange={onChangeAction}
        ref={ref}
        maxMenuHeight={240}
        components={{
          DropdownIndicator,
          Option,
          Control,
          Menu,
          IndicatorSeparator: null,
        }}
        options={getOptions()}
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 40,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
