import {Body1, Body2} from '@ui/atoms/typography';
import {FlexRowEnd, FlexRowSpaceBetween} from '@ui/style/styles';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {PageLayout} from '../admin-workspace-styled';
import {AdminCheckins} from './admin-checkins/admin-checkins.page';
import {AdminFeedback} from '../admin-feedback/admin-feedback';
import styled from 'styled-components';
import {ContentBox} from '../admin-workspace-styled';
import {useAdminWorkspaceHook} from '../admin-workspace-hooks';
import {useIsRouteActive} from '@utils/route-href';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {VerticalSpacer} from '@ui/atoms/spacer';

const Wrapper = styled.div`
  padding: 22px 24px;
  border-radius: 10px;
  border: 1px solid var(--border-light, #ededf2);
`;

const isMeetingToggled =
  process.env.REACT_APP_COMPANY_MEETING_FEATURE_ENABLED === 'enabled';

const WorkspaceEngagement = () => {
  const {isRouteActive} = useIsRouteActive();

  const {handleOneOnOneMeetings} = useAdminWorkspaceHook();

  const {isOneOnOneEnabled} = useWorkspaceUseCase();

  if (isRouteActive('checkins-configure')) {
    return <AdminCheckins />;
  }

  return (
    <PageLayout>
      <Wrapper>
        <FlexRowSpaceBetween>
          <div>
            <Body1 weight="bold">Engagement </Body1>
            <Body2 kind="textBody">
              Use the option for engagement features, such as check-ins, 1:1
              meetings, etc.
            </Body2>
          </div>
        </FlexRowSpaceBetween>

        <VerticalSpacer size="24px" />

        <AdminCheckins />

        {isMeetingToggled && (
          <>
            <VerticalSpacer size="16px" />

            <ContentBox>
              <div className="flex">
                <Body1 weight="bold">1:1 meetings</Body1>
                <FlexRowEnd>
                  <SwitchToggle
                    checked={isOneOnOneEnabled}
                    onChange={() => {
                      handleOneOnOneMeetings(!isOneOnOneEnabled);
                    }}
                  />
                </FlexRowEnd>
              </div>
            </ContentBox>
          </>
        )}

        <VerticalSpacer size="16px" />
        <AdminFeedback />
      </Wrapper>
    </PageLayout>
  );
};

export default WorkspaceEngagement;
