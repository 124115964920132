import {Body2} from '@ui/atoms/typography';
import {Colors} from '@ui/interface';
import React, {FC, ReactNode, useState} from 'react';

interface HoverTooltipProps {
  children: ReactNode;
  tooltipText: string;
  tooltipTextKind?: Colors;
  tooltipTextSize?: string;
  show?: boolean;
  className?: string;
}
export const HoverTooltip: FC<HoverTooltipProps> = ({
  children,
  show,
  tooltipText,
  className,
  tooltipTextKind,
  tooltipTextSize,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  // Check if className includes a background color
  const hasBgColor = className?.includes('bg-');

  // Combine default and custom classes
  const tooltipClasses = `
    absolute top-9 -right-6 rounded-md
    ${hasBgColor ? '' : 'bg-gray-700'}
    ${className || 'text-white py-1 px-2'}
  `.trim();

  return (
    <div
      className="relative"
      onClick={() => setIsHovering(false)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}>
      <div className="z-10">{children}</div>
      {isHovering && (
        <div
          style={{zIndex: -4}}
          className={
            'bg-[#F6F6F8] -top-[50%] -left-[50%] z-0 w-full absolute px-4 py-4 rounded-full '
          }
        />
      )}

      {(isHovering || show) && (
        <div className={tooltipClasses}>
          <Body2
            kind={tooltipTextKind || 'white'}
            size={tooltipTextSize || '12px'}
            style={{whiteSpace: 'nowrap'}}>
            {tooltipText}
          </Body2>
        </div>
      )}
    </div>
  );
};
