import {Fragment, memo, SyntheticEvent, useCallback, useMemo} from 'react';
import {HorizontalSpacer, VerticalSpacer} from '../../atoms/spacer';
import {Headline2} from '../../atoms/typography/headline2';
import {Avatar} from '../../atoms/avatar';
import {TextTag2} from '../../atoms/tag';
import {ProfileLink} from '../../atoms/profile-link';
import {Container, Designation, Footer, Wrapper} from './styles';
import {NavLinks} from '../nav-links';
import {IGroupMembers} from '../../../hooks';
import {CTA} from '../../atoms/typography/cta';
import {Groups} from '../groups';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {useNavigate} from 'react-router';

export interface UserCommon {
  name: string;
  url: string;
  avatar: string;
  id: string;
}

export interface ProfileDropdownProps {
  user: UserCommon;
  reportee: UserCommon;
  designation: string;
  groups: IGroupMembers[];
  isAdmin: boolean;
  onLogout: () => void;
  onItemClick: (
    e: SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>,
    href: string,
  ) => void;
}

export const ProfileDropdown = memo(function ({
  onLogout,
  groups,
  isAdmin,
  designation,
  user,
  reportee,
}: ProfileDropdownProps) {
  const navigate = useNavigate();
  const renderItem = useMemo(() => {
    return (
      <Fragment>
        <FlexRowSpaceBetween>
          <Avatar
            name={user.name}
            alt="Image"
            tooltip={false}
            src={user.avatar}
            userId={user.id}
            size="xl"
          />
        </FlexRowSpaceBetween>
        <VerticalSpacer size="20px" />
        <div>
          <Headline2>{user.name}</Headline2>
        </div>
        <VerticalSpacer size="16px" />
        <div>
          <Designation>
            {designation}
            <HorizontalSpacer size="8px" as="span" />
            {isAdmin && (
              <TextTag2 style={{height: '20px', padding: '1.5px 8px 0 8px'}}>
                Admin
              </TextTag2>
            )}
          </Designation>
        </div>
        <VerticalSpacer size="16px" />
        <CTA>Group(s)</CTA>
        <VerticalSpacer size="8px" />
        <Groups items={groups} total={groups.length} />
        {/* <ProfileLink heading="Group(s)" content={department.name} /> */}
        <VerticalSpacer size="16px" />

        <ProfileLink
          heading="Manager"
          content={reportee.name}
          extra={
            <Avatar
              tooltip={false}
              alt={`${reportee.name} profile`}
              src={reportee.avatar}
              name={reportee.name}
              size="sm"
              userId={reportee.id}
            />
          }
          notlink
          href={reportee.url}
        />

        <VerticalSpacer size="12px" />
      </Fragment>
    );
  }, [
    user.name,
    user.avatar,
    user.id,
    designation,
    isAdmin,
    groups,
    reportee.name,
    reportee.avatar,
    reportee.id,
    reportee.url,
  ]);

  const handleHelpCenter = () => {
    window.location.assign('https://peoplebeam.myhcpage.com/en-us/');
  };

  const links = useMemo(() => {
    const workspaceNav = [
      {
        id: 'settings',
        title: 'Settings',
        onClick: () => {
          sessionStorage.setItem('prevurl', window.location.pathname);
          navigate('/edit');
        },
      },
      {
        id: 'app-integrations',
        title: 'App Integrations',
        onClick: () => {
          sessionStorage.setItem('prevurl', window.location.pathname);
          navigate('/edit/apps');
        },
      },

      {
        id: 'help-center',
        title: 'Help Center',
        onClick: handleHelpCenter,
      },
      {id: 'log-out', title: 'Log out', onClick: onLogout},
    ];

    return workspaceNav;
  }, [onLogout, navigate]);

  return (
    <Wrapper>
      <Container>{renderItem}</Container>
      <Footer>
        <NavLinks items={links} />
      </Footer>
    </Wrapper>
  );
});
ProfileDropdown.displayName = 'ProfileDropdown';
