import {FC, memo, useEffect, useMemo, useState} from 'react';
import {Insight} from '../interface';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Body2, Headline2, Headline3} from '@ui/atoms/typography';
import dayjs from 'dayjs';
import {Button} from '@ui/atoms/button';
import {ArrowLeftIcon, CancelIcon, ChevronDownIcon} from '@ui/atoms/icons';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {KpiInsight} from '../../dashboard/kpi-insight';
import {userName} from '@utils/user-name';
import {PenEditIcon} from '@ui/atoms/icons/pen-edit';
import {TextField} from '@ui/molecules/field/textfield';
import {TickIcon} from '@ui/atoms/icons/tick';
import {useEditInsight} from './edit-insight-hook';
import {EditInsightFilter} from './edit-insight-filter';
import {ActionItemCompletionRate} from '../action-items/action-items-completion';
import {ActionItemsDistribution} from '../action-items/action-items-distribution';
import {ActionItemCompleted} from '../action-items/action-items-completed';
import {ActionItemDistributionPerIndividual} from '../action-items/action-items-distribution-individual';
import {Avatar} from '@ui/atoms/avatar';
import {VerticalInsightConfig} from '../../dashboard/insight-config';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ObjectiveCompletion} from '../objectives/objective-completion';
import {ObjectiveStatus} from '../objectives/objective-status';
import {KeyResultPerformance} from '../objectives/key-result-performance';
import {IDashboard} from '../../dashboard/dashboard-hook';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {FiChevronDown} from 'react-icons/fi';
import {Checkbox} from '@ui/atoms/checkbox';

interface EditInsightProps extends Insight {
  onClose: () => void;
  firebaseId: string;
  dashboards: IDashboard[];
  canGoBack?: boolean;
  handleAddToDashboard: (value: string[], dashboardId: string) => void;
  onDuplicate?: () => void;
  updateEditInsight?: (id: string) => void;
}

export const EditInsight: FC<EditInsightProps> = memo(
  ({
    name,
    updatedAt,
    onClose,
    type,
    createdBy,
    firebaseId,
    id,
    dashboards = [],
    handleAddToDashboard,
    onDuplicate,
    updateEditInsight,
    canGoBack = false,
    section,
    config,
    ...rest
  }) => {
    const {
      isEditingName,
      updateCustomDateRange,
      KPIOptions,
      insightConfig,
      customDateRange,
      insightName,
      setInsightName,
      isCustomDateRange,
      updateInsight,
      nameText,
      setIsEditingName,
      configIsChanged,
      handleReset,
      setNameText,
      handleSaveName,
      additionalFilters,
      canAddFilter,
      findViewType,
      comparisonOptions,
      findUser,
      dateRangeOptions,
      isEditing,
      setIsEditing,
      dateRangeType,
      handleSave,
    } = useEditInsight({
      ...rest,
      name,
      section,
      id,
      config,
      firebaseId,
      type,
      onClose,
      updateEditInsight,
    });
    const [defaultName, setDefaultName] = useState('');

    useEffect(() => {
      document
        .getElementById('insight-modal')
        ?.scrollTo({behavior: 'smooth', top: 0});
    }, []);

    useEffect(() => {
      const findScrollParent = (
        element: HTMLElement | null,
      ): HTMLElement | null => {
        if (!element) return null;
        if (element.scrollHeight > element.clientHeight) return element;
        return findScrollParent(element.parentElement);
      };

      const element = document.getElementById('modal-card');
      const scrollParent = findScrollParent(element);
      if (scrollParent) {
        scrollParent.scrollTop = 0;
      }
    }, []);

    const isKeyResults = id === 'key-results-performance';

    const isKpi = type === 'kpi';

    const isKpiChart = insightConfig.view === 'chart';

    const mustAddSection = isKeyResults && !config.key_result;

    const canEditName =
      mustAddSection || (isEditing && isKpi) || isKeyResults || !!createdBy;

    const configureDisable = useMemo(() => {
      if (isKeyResults) {
        return !config.key_result ? false : !isEditing;
      }

      return !isEditing;
    }, [config.key_result, isEditing, isKeyResults]);

    const WidgetType = () => {
      if (type === 'kpi') {
        return (
          <div className={isKpiChart ? '' : 'max-w-[300px]'}>
            <KpiInsight
              {...rest}
              name={name}
              config={insightConfig}
              handleName={setDefaultName}
              createdBy={createdBy}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'objective-completion') {
        return (
          <div className="max-w-[300px]">
            <ObjectiveCompletion
              {...rest}
              name={name}
              config={insightConfig}
              createdBy={createdBy}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'objective-status') {
        return (
          <div className="">
            <ObjectiveStatus
              {...rest}
              name={name}
              config={insightConfig}
              createdBy={createdBy}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (isKeyResults) {
        return (
          <div className={insightConfig.key_result ? 'max-w-[300px]' : ''}>
            <KeyResultPerformance
              {...rest}
              name={insightName}
              config={insightConfig}
              handleName={(value) => {
                setDefaultName(value);

                if (mustAddSection) {
                  setInsightName(value);
                }
              }}
              createdBy={createdBy}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'action-items-completed') {
        return (
          <div className="max-w-[300px]">
            <ActionItemCompleted
              {...rest}
              name={name}
              createdBy={createdBy}
              config={insightConfig}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'action-items-completion-rate') {
        return (
          <div className="max-w-[300px]">
            <ActionItemCompletionRate
              {...rest}
              name={name}
              createdBy={createdBy}
              config={insightConfig}
              updatedAt={updatedAt}
              id={id}
              type={type}
            />
          </div>
        );
      }
      if (id === 'action-items-distribution') {
        return (
          <ActionItemsDistribution
            {...rest}
            name={name}
            createdBy={createdBy}
            config={insightConfig}
            updatedAt={updatedAt}
            id={id}
            type={type}
          />
        );
      }
      if (id === 'action-items-completion-per-individual') {
        return (
          <ActionItemDistributionPerIndividual
            {...rest}
            name={name}
            createdBy={createdBy}
            config={insightConfig}
            updatedAt={updatedAt}
            id={id}
            type={type}
          />
        );
      }
      return (
        <KpiInsight
          name={name}
          createdBy={createdBy}
          {...rest}
          config={config}
          updatedAt={updatedAt}
          id={id}
          type={type}
        />
      );
    };

    return (
      <div>
        {canGoBack && (
          <FlexRowSpaceBetween className="border-b border-b-borderLight pb-3 pt-2 px-16">
            <button onClick={onClose}>
              <FlexRow className="gap-3">
                <ArrowLeftIcon />

                <Body2 weight="semibold">Go back</Body2>
              </FlexRow>
            </button>
            <Headline3>Saved Insights</Headline3>
            <div />
          </FlexRowSpaceBetween>
        )}

        <div className="p-16">
          <FlexRowSpaceBetween>
            <div>
              <FlexRow className="gap-3 w-full">
                {isEditingName ? (
                  <>
                    <TextField
                      placeholder="Enter title (max 25 characters)"
                      margin
                      value={nameText}
                      onChange={(event) => setNameText(event.target.value)}
                      inputStyle={{width: '28vw'}}
                    />

                    <button
                      disabled={!nameText}
                      onClick={() => {
                        setInsightName(nameText);
                        handleSaveName(nameText);
                        setNameText('');
                        setIsEditingName(false);
                      }}>
                      <TickIcon fill={!nameText ? '#BFBFD4' : '#585ADF'} />
                    </button>

                    <button
                      onClick={() => {
                        setNameText('');
                        setIsEditingName(false);
                      }}>
                      <CancelIcon style={{width: '24px', height: '24px'}} />
                    </button>
                  </>
                ) : (
                  <>
                    <Headline2>{insightName}</Headline2>

                    {canEditName && (
                      <button
                        onClick={() => {
                          setIsEditingName(true);
                          setNameText(insightName);
                        }}>
                        <PenEditIcon
                          width={'16px'}
                          height={'16px'}
                          fill="#585ADF"
                        />
                      </button>
                    )}
                  </>
                )}
              </FlexRow>

              {defaultName && defaultName !== insightName && canEditName && (
                <button
                  className="mt-1"
                  onClick={() => {
                    setInsightName(defaultName);
                    handleSaveName(defaultName);
                    setNameText('');
                    setIsEditingName(false);
                  }}>
                  <Body2
                    className="underline"
                    kind="textBody"
                    style={{fontSize: '12px'}}>
                    Reset to default
                  </Body2>
                </button>
              )}
            </div>

            <FlexRow className="gap-4">
              {mustAddSection ? (
                <ButtonDropdown
                  options={[
                    {
                      label: 'Save, add to dashboard and continue editing',
                      value: 'continue-report',
                    },
                  ]}
                  buttonText="Save & add to dashboard"
                  onClick={() => {
                    handleSave();
                    onClose();
                  }}
                  isDisabled={
                    (insightConfig.type === 'custom' &&
                      (!customDateRange.starts || !customDateRange.ends)) ||
                    (isKeyResults && !insightConfig.key_result)
                  }
                  useCustomDropdown
                  onChange={() => {
                    handleSave(true);
                  }}
                />
              ) : (
                <>
                  {isEditing ? (
                    <>
                      <ButtonDropdown
                        options={[
                          {
                            label: 'Save as new report',
                            value: 'new-report',
                          },
                          {
                            label: 'Save & continue editing',
                            value: 'continue-editing',
                          },
                        ]}
                        buttonText="Save"
                        onClick={() => {
                          handleSave();
                        }}
                        isDisabled={
                          (insightConfig.type === 'custom' &&
                            (!customDateRange.starts ||
                              !customDateRange.ends)) ||
                          JSON.stringify(insightConfig) ===
                            JSON.stringify(config)
                        }
                        useCustomDropdown
                        onChange={(value) => {
                          if (value === 'new-report') {
                            handleSave(true);
                            onClose();
                            return;
                          }

                          handleSave();
                        }}
                      />

                      <Button
                        className="bg-backgroundLight"
                        kind="secondary"
                        onClick={() => setIsEditing(!isEditing)}
                        style={{
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                        }}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="bg-backgroundLight"
                        kind="secondary"
                        onClick={() => setIsEditing(!isEditing)}
                        style={{
                          padding: '8px 16px',
                          backgroundColor: '#FAFAFA',
                        }}>
                        Edit insight
                      </Button>

                      <ButtonDropdown
                        options={dashboards?.map((dashboard) => ({
                          label: dashboard.name,
                          value: dashboard.key,
                          isChecked: rest.dashboard
                            ?.split(',')
                            .includes(dashboard.key),
                        }))}
                        useCheckbox
                        buttonText="Dashboards"
                        onChange={(value) => {
                          handleAddToDashboard([firebaseId], value);
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </FlexRow>
          </FlexRowSpaceBetween>

          <div className="gap-6 mt-6 flex flex-row">
            <div className="min-w-[70%]">
              <div className="shadow-sm border border-borderLight py-4 px-6 rounded-[10px]">
                <div className="border-b border-b-borderLight pb-4 ">
                  <FlexRow className="mt-2 gap-2">
                    <Body2 kind="textBody">
                      Last modified on {dayjs(updatedAt).format("DD MMM. 'YY")}{' '}
                      by
                    </Body2>

                    <span className="mb-1">
                      <Avatar
                        tooltip={!!createdBy}
                        userId={createdBy}
                        name={
                          createdBy ? userName(findUser(createdBy)) : 'Beam'
                        }
                        size="xs"
                      />
                    </span>
                    <Body2 kind="textBody">
                      {createdBy ? userName(findUser(createdBy)) : 'Beam'}
                    </Body2>
                  </FlexRow>
                </div>

                <div className="flex items-center mt-4 justify-between">
                  <VerticalInsightConfig
                    config={insightConfig}
                    disableVerticalScroll
                    additionalFilters={additionalFilters}
                  />
                  {isKpi && (
                    <DropdownCustom
                      collapseOnClick={true}
                      menu={(handleClose: () => void) => (
                        <>
                          {KPIOptions.map((option) => (
                            <DropdownItem
                              key={option.key}
                              onClick={() => {
                                handleClose();
                                updateInsight('view', option.key);
                              }}>
                              {option.title}
                            </DropdownItem>
                          ))}
                        </>
                      )}
                      customComponent={(handleOpen: (event: any) => void) => (
                        <>
                          <div>
                            <Button
                              kind="secondary"
                              type="button"
                              onClick={handleOpen}
                              className="flex items-center justify-between"
                              style={{
                                minWidth: '125px',
                                height: '38px',
                                overflow: 'hidden',
                                padding: '6px 8px',
                              }}>
                              <FlexRowSpaceBetween>
                                <Body2 weight="bold">
                                  {findViewType(insightConfig?.view || '')}
                                </Body2>
                                <ChevronDownIcon
                                  style={{margin: '0px 5px 0px 5px'}}
                                  aria-hidden="true"
                                />
                              </FlexRowSpaceBetween>
                            </Button>
                          </div>
                        </>
                      )}
                      customDropdownWrapperStyles={{
                        top: '130%',
                        width: '200px',
                        left: '-50%',
                      }}
                    />
                  )}
                </div>
                <VerticalSpacer size="24px" />
                <WidgetType />
              </div>
            </div>

            <EditInsightFilter
              dateRangeOptions={dateRangeOptions}
              onChangeUsers={(value) => updateInsight('users', value)}
              onChangeList={(value) => updateInsight('list', value)}
              selectedLists={config.list}
              handleReset={handleReset}
              configIsChanged={configIsChanged}
              isKeyResults={isKeyResults}
              isDisabled={configureDisable}
              selectedUsers={config.users}
              type={type}
              insightConfig={insightConfig}
              comparisonOptions={comparisonOptions}
              canSelectObjective={id === 'objective-completion'}
              dateRangeType={dateRangeType}
              hasComparison={config.hasComparison}
              canAddFilter={canAddFilter}
              isCustomDateRange={isCustomDateRange}
              customDateRange={customDateRange}
              updateInsight={updateInsight}
              setCustomDateRange={(value) => {
                updateCustomDateRange(value);
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);

interface ButtonDropdownProps {
  onChange: (value: string) => void;
  onClick?: () => void;
  useCustomDropdown?: boolean;
  useCheckbox?: boolean;
  options: {value: string; label: string; isChecked?: boolean}[];
  isDisabled?: boolean;
  buttonText: string;
}

const ButtonDropdown = ({
  options,
  isDisabled,
  useCheckbox,
  useCustomDropdown,
  onClick,
  buttonText,
  onChange,
}: ButtonDropdownProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(() => {
    return options.filter((opt) => opt.isChecked).map((opt) => opt.value);
  });

  const handleItemClick = (value: string) => {
    let newSelection: string[];
    if (selectedItems.includes(value)) {
      newSelection = selectedItems.filter((item) => item !== value);
    } else {
      newSelection = [...selectedItems, value];
    }

    setSelectedItems(newSelection);

    // Convert to comma-separated string for backwards compatibility
    onChange(newSelection.join(','));
  };

  return (
    <>
      <DropdownCustom
        collapseOnClick
        customComponent={(handleOpen: (event: any) => void) => (
          <Button
            className="bg-backgroundLight flex flex-row items-center gap-1"
            kind="secondary"
            disabled={isDisabled}
            onClick={useCustomDropdown ? onClick : handleOpen}
            style={{padding: '8px 16px', backgroundColor: '#FAFAFA'}}>
            {buttonText}

            {useCustomDropdown && (
              <HorizontalSideRule
                size="16px"
                color={isDisabled ? '#BFBFD4' : '#CECEDE'}
              />
            )}

            <div onClick={handleOpen}>
              <FiChevronDown
                style={{
                  color: isDisabled ? '#BFBFD4' : undefined,
                }}
                aria-hidden="true"
              />
            </div>
          </Button>
        )}
        menu={(handleClose: () => void) => (
          <div>
            {options.map((option) => (
              <DropdownItem
                key={option.value}
                onClick={() => {
                  if (useCheckbox) {
                    return handleItemClick(option.value);
                  }

                  handleClose();
                  onChange(option.value);
                }}>
                <div className="flex gap-3 items-center">
                  {useCheckbox && (
                    <Checkbox checked={selectedItems.includes(option.value)} />
                  )}

                  {option.label}
                </div>
              </DropdownItem>
            ))}
          </div>
        )}
        customDropdownWrapperStyles={{
          top: '130%',
          right: '0px',
        }}
      />
    </>
  );
};
