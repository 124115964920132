import {
  ChangeEvent,
  ComponentPropsWithRef,
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useState,
} from 'react';
import styled, {css} from 'styled-components';
import {Helper} from '../../../atoms/helper';
import {BinaryType, InputState} from '../../../interface';
import {getColorFromTheme} from '../../../ui-utils';
import {VerticalSpacer} from '../../../atoms/spacer';
import {NumericFormat} from 'react-number-format';

import {Body2} from '../../../atoms/typography/body2';
import {PercentIcon} from '../../../atoms/icons/percent';
import {switchProp} from 'styled-tools';
const commaNumber = require('comma-number');

export interface PercentageUpdateFieldProps
  extends ComponentPropsWithRef<'input'> {
  label?: string;
  name?: string;
  filter?: boolean;
  state?: InputState;
  helper?: string | ReactElement;
  max?: string;
  setValue?: (value: BinaryType) => void;
}

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const IconWrapper = styled.span<{disabled?: boolean}>`
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getColorFromTheme('backgroundLight')};

  svg {
    width: 20px;
    height: 20px;
    stroke: ${getColorFromTheme('textBody')};
  }
`;

export const Input = styled.input`
  appearance: textfield;
  -webkit-appearance: textfield;
  -webkit-tap-highlight-color: transparent;
  -moz-appearance: textfield;
  padding: 5px 16px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: ${getColorFromTheme('textDark')};
  display: inline-block;
  height: 40px;
  width: 100px;
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: none;
  outline: none;
  border-right: solid 1px ${getColorFromTheme('borderLight')};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${getColorFromTheme('textMuted')};
  }
`;

const Container = styled.label<{state?: InputState}>`
  display: inline-flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  height: 40px;
  transition: 200ms ease all;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 0px 1px ${getColorFromTheme('borderLight')};
  background-color: ${getColorFromTheme('white')};

  &:focus-within {
    outline: 0;
    box-shadow: 0px 0px 0px 1px ${getColorFromTheme('purple300')},
      0 0 0 6px ${getColorFromTheme('purple200')};

    ${Input} {
      border-right: solid 1px ${getColorFromTheme('purple300')};
    }
  }

  &:hover:not(:focus-within) {
    box-shadow: 0 0 0 2px ${getColorFromTheme('purple200')};

    ${Input} {
      border-right: solid 1px ${getColorFromTheme('purple200')};
    }
  }

  ${switchProp('state', {
    error: css`
      box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')};

      ${Input} {
        color: ${getColorFromTheme('red400')};

        border-right: 1px solid ${getColorFromTheme('red400')};

        &::placeholder {
          color: ${getColorFromTheme('red400')};
        }
      }

      &:focus-within {
        box-shadow: 0px 0px 0px 1px ${getColorFromTheme('red400')},
          0 0 0 6px ${getColorFromTheme('red200')};

        ${Input} {
          border-right: solid 1px ${getColorFromTheme('red400')};
        }
      }

      &:hover:not(:focus-within) {
        box-shadow: 0 0 0 2px ${getColorFromTheme('red400')};

        ${Input} {
          border-right: solid 1px ${getColorFromTheme('red400')};
        }
      }
    `,
  })}
`;
type inputType = string | number | undefined;

export const PercentageUpdateField = forwardRef(
  ({
    state,
    helper,
    label,
    filter,
    name,
    value,
    setValue,
    defaultValue,
    max,
    id,
    ...rest
  }: PercentageUpdateFieldProps) => {
    const [innerValue, setInnerValue] = useState(value);

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        const number = value.replaceAll(',', '');

        setInnerValue(number as BinaryType);
        // TODO: Inner validation here
        if (setValue) {
          setValue(number as BinaryType);
        }
      },
      [setValue],
    );

    return (
      <Wrapper>
        <Body2 kind={filter ? 'textMuted' : 'textBody'} as="label">
          {label}
        </Body2>
        <VerticalSpacer size="8px" />
        <Container htmlFor={id} state={state}>
          <NumericFormat
            {...rest}
            name={name}
            onChange={onChange}
            type="text"
            value={commaNumber(innerValue) as inputType}
            defaultValue={defaultValue as inputType}
            id={id}
            customInput={Input}
            style={{
              color: filter ? '#bfbfd4' : '',
              minWidth: innerValue
                ? `${innerValue.toString().length + 5}ch`
                : '50px',
            }}
            // ref={ref}
            max={100}
            min={0}
            thousandSeparator=","
          />
          <span style={{marginLeft: 4, width: '100%'}}>of {max}</span>
          <IconWrapper>
            <PercentIcon />
          </IconWrapper>
        </Container>
        <Helper aria-label="helper" state={state} children={helper} />
      </Wrapper>
    );
  },
);
