import {HelpGuideIcon} from '@ui/atoms/icons';
import {Body2} from '@ui/atoms/typography';

export const UseGuide = () => {
  return (
    <button className="cursor-pointer">
      <div className="flex items-center gap-1">
        <HelpGuideIcon />
        <Body2 kind="textBody">How to use</Body2>
      </div>
    </button>
  );
};
