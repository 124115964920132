import {useStoreContext} from '@store/store-context';
import {BillingType} from './interface';

export const useWorkspaceUseCase = () => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const isBillingStandard =
    auth.user.workspace.billing.activeTrial &&
    !auth.user.workspace.billing.trailCompleted
      ? true
      : [
          BillingType.MONTHLY,
          BillingType.YEARLY,
          BillingType.LIFETIME,
        ].includes(auth.user.workspace.billing.plan);

  const isPerformanceActive = isBillingStandard;

  const isEngagementActive = isBillingStandard;

  const isCheckinsEnabled =
    auth.user.workspace.settings.checkInStatus === 'enabled';

  const isActionItemEnabled =
    auth.user.workspace.settings.actionItemStatus === 'enabled';

  const isGoalEnabled = auth.user.workspace.settings.goalStatus === 'enabled';

  const isFeedBackEnabled = auth.user.workspace.settings.feedback === 'enabled';

  const isOneOnOneEnabled =
    auth.user.workspace.settings.oneOnOne === 'enabled' &&
    process.env.REACT_APP_COMPANY_MEETING_FEATURE_ENABLED === 'enabled';

  return {
    isPerformanceActive,
    isEngagementActive,
    isCheckinsEnabled,
    isBillingStandard,
    isActionItemEnabled,
    isFeedBackEnabled,
    isOneOnOneEnabled,
    isGoalEnabled,
  };
};
