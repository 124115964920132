import styled from 'styled-components';
import {ifProp} from 'styled-tools';
import {getColorFromTheme} from '@ui/ui-utils';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {down, up} from 'styled-breakpoints';

export const FormWrapper = styled.div<{split?: boolean}>`
  width: 100%;
  display: flex;
  justify-content: ${({split}) => (split ? 'space-between' : 'center')};
  
  height: ${({split}) => (split ? '90vh' : '100%')};
  
  overflow: ${({split}) => (split ? 'hidden' : '100%')};
  
  margin-top: ${({split}) => (split ? '30px' : '0px')};
  
  .split-form {
    width: 50%;
    opacity: 0.5;
    padding: 3% 2% 0% ;
    border-right: 1px solid #ededf2;
    pointer-events: none;
 
    .summary-grid {
      grid-template-columns: 50% 50%;
    }

     @media (min-width: 1100px) and (max-width: 1320px) {
    padding: 3% 4% 0% ;
    
      width: 50%;
    }

    @media (min-width: 1000px) and (max-width: 1100px) {
    padding: 3% 3% 0% ;

      width: 50%;
    }
      @media (max-width: 1000px) {
      width: 100%;
    }
  }

 .form {
    width: 80%;

  
    margin-bottom: 30px;

    @media (min-width: 1100px) and (max-width: 1320px) {
      width: 85%;
    }

    @media (min-width: 768px) and (max-width: 1100px) {
      width: 90%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  
  }
`;

export const MagicPenIconWrapper = styled.button<{
  disabled?: boolean;
  color?: string;
}>`
  svg {
    path {
      stroke: ${({color}) => color};
    }
  }
`;

export const Wrapper = styled.div<{disabled?: boolean}>`
  border: 1px solid #cecede;
  background-color: ${ifProp('disabled', '#FAFAFA', 'white')};
  border-radius: 10px;
  .input {
    padding: 2px 0px;
    overflow: auto;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 16px;
    border-top: 1px solid #cecede;
  }
`;

export const ManagerViewUser = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  padding: 24px;
  @media (max-width: 768px) {
    padding: 12px;
  }
`;
export const CommentNotif = styled.div`
  position: relative;

  .dot {
    position: absolute;
    bottom: 16px;
    right: -5%;
  }
`;

export const StatusWrapper = styled.div`
  background-color: ${getColorFromTheme('backgroundDark')};
  border-radius: 8px;
  width: fit-content;
  margin-top: 4px;
  padding: 4px 8px;
`;

export const Note = styled.div`
  border: 1px solid #cecede;
  border-radius: 10px;
  padding: 16px;
  background: #fafafa;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const DateWrapper = styled.div`
  margin-top: 12px;
`;

export const ResponsiveFlexBetween = styled(FlexRowSpaceBetween)`
  width: 100%;
  @media (max-width: 868px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const ResponsiveFlexStart = styled(FlexRowSpaceBetween)`
  .manager-button {
    padding: 8px 16px;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    .manager-view {
      margin-top: 16px;
      .manager-button {
        padding: 3px 8px;
      }
    }
  }
`;

export const ResponsiveFlexRow = styled(FlexRow)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    .first-child {
      margin-bottom: 8px;
    }
  }
`;

export const UpcomingTag = styled.div<{bg: string; color: string}>`
  color: ${({color}) => color};
  background: ${({bg}) => bg};
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  margin-right: 8px;
  width: fit-content;
  border-radius: 8px;
`;

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 1000px) {
    display: block;
    width: 50%;

    height: 100%;
    padding-left: 32px;
    padding-top: 40px;
  }
`;

export const ModalWrapper = styled.div`
  max-width: 623px;
  width: 80vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 92vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;

export const AdditionalOptionsWrapper = styled.div<{display?: boolean}>`
  display: ${(props: any) => (props.display ? 'block' : 'none')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='rgba(206, 206, 222, 1)' strokeWidth='2' stroke-dasharray='14%2c 8' stroke-dashoffset='0' strokeLinecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  margin: 8px 0;
  width: 100%;
  height: 50px;
  padding: 16px 24px;
  background-color: rgba(250, 250, 250, 1);
`;

export const CopyNotification = styled.div`
  background: #ffffff;
  width: 116px;
  height: 48px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -15px;
  right: 30px;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(172, 172, 190, 0.24);
  border-radius: 7px;
  @media (max-width: 800px) {
    right: 110px;
    top: -70px;
  }
`;
