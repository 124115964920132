import {authStore} from '../../store/stores/auth-store';
import {storeAllObjectives} from '@store/stores/store-all-objectives';
import {storeYouGoals} from '../../store/stores/store-you-goals';
import {activateNotification} from '../../ui/molecules/notification/activate-notification';
import {capitalize} from '../../utils';
import {parseErrorContent} from '../../utils/error-handling';
import {beamMasteryTasks} from '@utils/firebase-request';
import {computeFrameworkVars} from '../../utils/framework';
import {GoalsRequestImpl} from './goals-request';
export class GoalsController {
  constructor(private readonly request: GoalsRequestImpl) {}

  async deleteGoal(goalId: string) {
    try {
      await this.request.deleteGoal(goalId);
      storeYouGoals.deleteGoalFromStore(goalId);

      activateNotification({
        title: `${capitalize(
          computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
            ?.g_oal,
        )} deleted`,
        content: `${capitalize(
          computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
            ?.g_oal,
        )} has been successfully deleted`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchGoals(
    pageNumber?: number,
    filter?: string,
    limit?: number,
    date?: {startDate: string; endDate: string},
    goalName?: string | null,
  ) {
    try {
      const response = await this.request.fetchGoals(
        pageNumber,
        filter,
        limit,
        date,
        goalName,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchAllGoals(pageNumber: number, filter?: any, pageLimit?: number) {
    try {
      const response = await this.request.fetchAllGoals(
        filter,
        pageNumber,
        pageLimit,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchAllKeyResults(
    pageNumber: number,
    filter?: any,
    pageLimit?: number,
  ) {
    try {
      const response = await this.request.fetchAllKeyResults(
        filter,
        pageNumber,
        pageLimit,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchActiveParentGoals(pageNumber?: number, searchTerm?: string) {
    try {
      const response = await this.request.fetchActiveParentGoals(
        pageNumber,
        searchTerm,
      );
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async fetchGoalById(goalId: string, hideError?: boolean) {
    try {
      const response = await this.request.fetchGoalById(goalId);
      return response;
    } catch (e: any) {
      !hideError &&
        activateNotification({
          title: 'Error',
          content: parseErrorContent(e),
          kind: 'error',
        });
      return false;
    }
  }
  async fetchGoalTasksById(goalId: string) {
    try {
      const response = await this.request.fetchGoalTasksById(goalId);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async updateGoalTasksById(goalId: string, data: any) {
    try {
      const response = await this.request.updateGoalTasksById(goalId, data);
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async updateGoal(data: any, goalId: string, hideNotif?: boolean) {
    try {
      const response = await this.request.updateGoal(data, goalId);
      // Uncomment this block to display update notificsation snackbar
      !hideNotif &&
        activateNotification({
          title: 'Success',
          content: `${capitalize(
            data?.isKpi
              ? 'KPI'
              : computeFrameworkVars(
                  authStore?.auth?.user?.workspace?.framework,
                )?.g_oal,
          )} has been successfully updated`,
          kind: 'success',
        });
      beamMasteryTasks('update_objective', true);
      if (response) return response;
    } catch (e: any) {
      let content;
      switch (e.response?.data.message) {
        case '"currentValue" must be a number':
          content = 'Please, enter a number.';
          break;
        default:
          content = e.response?.data.message;
      }
      activateNotification({
        title: 'Error',
        content,
        kind: 'error',
      });
      return false;
    }
  }
  async revertUpdate(goalId: string, hideNotif?: boolean) {
    try {
      const response = await this.request.revertUpdate(goalId);
      // Uncomment this block to display update notificsation snackbar
      !hideNotif &&
        activateNotification({
          title: 'Success',
          content: `Update has been successfully reverted`,
          kind: 'success',
        });

      if (response) return response;
    } catch (e: any) {
      let content = e.response?.data.message;

      activateNotification({
        title: 'Error',
        content,
        kind: 'error',
      });
      return false;
    }
  }

  async updateKeyResult(data: any, keyResultId: string) {
    try {
      const response = await this.request.updateKeyResult(data, keyResultId);
      // Uncomment this block to display update notificsation snackbar

      if (response) return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async getKeyResult(keyResultId: string) {
    try {
      const response = await this.request.getKeyResult(keyResultId);
      // Uncomment this block to display update notificsation snackbar

      if (response) return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async endGoal(data: any, goalId: string, name?: string) {
    try {
      await this.request.endGoal(data, goalId);

      activateNotification({
        title: 'Success',
        content: `${capitalize(
          name ||
            computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
              ?.g_oal,
        )} has been successfully ended.`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async archiveGoal(data: any, goalId: string) {
    try {
      await this.request.archiveGoal(data, goalId);
      storeYouGoals.archiveGoalInStore(goalId);
      activateNotification({
        title: 'Success',
        content: `${capitalize(
          computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
            ?.g_oal,
        )} has been successfully archived.`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async restoreGoal(data: any, goalId: string) {
    try {
      await this.request.restoreGoal(data, goalId);
      storeYouGoals.restoreGoalInStore(goalId);
      activateNotification({
        title: 'Success',
        content: `${capitalize(
          computeFrameworkVars(authStore?.auth?.user?.workspace?.framework)
            ?.g_oal,
        )} has been successfully restored.`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }
  async reactivateGoal(goalId: string, goalTypeName: string) {
    try {
      const response = await this.request.reactivateGoal(goalId);

      storeAllObjectives.updateEndedGoal(goalId, response);
      activateNotification({
        title: 'Success',
        content: `${capitalize(goalTypeName)} has been successfully re-opened.`,
        kind: 'success',
      });
      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
      return false;
    }
  }

  async sseGoalListener(stream: any) {
    try {
      const response = await this.request.sseGoalListener(stream);
      return response;
    } catch (e: any) {
      return false;
    }
  }
}
