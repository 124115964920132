import {down, up} from 'styled-breakpoints';
import styled from 'styled-components';
import {getColorFromTheme} from '@ui/ui-utils';

export const OuterWrapper = styled.form`
  padding-bottom: 24px;
`;
export const BackWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
  max-width: 1280px;
  ${down('sm')} {
    width: 92vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;

export const DelimitingWrapper = styled.div`
  border: 1px solid #ededf2;
  border-radius: 10px;
  max-width: 1280px;
  width: 80vw;
  margin: 0 auto;
  ${down('sm')} {
    width: 98vw;
  }

  ${up('sm')} {
    width: 95vw;
  }

  ${up('lg')} {
    width: 88vw;
  }

  ${up('xl')} {
    width: 78vw;
  }
`;
export const HistoricDelimitingWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  ${down('sm')} {
    width: 92vw;
    padding: 10px 0 10px 0;
    margin-bottom: 10px;
  }

  ${up('sm')} {
    width: 95vw;
    padding: 20px 0 20px 0;
    margin-bottom: 20px;
  }

  ${up('lg')} {
    width: 88vw;
    padding: 40px;
    margin-bottom: 40px;
  }

  ${up('xl')} {
    width: 78vw;
    padding: 40px;
    margin-bottom: 40px;
  }
`;
export const HorizontalRule = styled.div`
  border-bottom: 1px solid #ededf2;
  width: 100%;
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .form {
    width: 70%;
    @media (max-width: 500px) {
      width: 98%;
    }
  }
`;

export const HistoricWrapper = styled.div`
  width: 70%;
`;
export const Header = styled.div`
  margin-bottom: 2rem;

  ${up('md')} {
    margin: 0 0 0 -20px;
  }
`;
export const HorizontalHr = styled.div`
  border: 1px solid #ededf2;
  opacity: 0.5;
`;
export const MoodDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  max-width: 1200px;
  border-radius: 10px;
  div.titleDiv {
    padding: 18px 24px 16px;
  }
  div.contentDiv {
    display: flex;
    align-items: center;
    padding: 24px 40px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
  div.footerDiv {
    display: flex;
    align-items: center;
    padding: 12px 40px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

export const ProgressDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  max-width: 1200px;
  div.titleDiv {
    padding: 18px 24px 16px;
  }
  div.contentDiv {
    padding: 8px 8px 0 8px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

export const PrioritiesDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  max-width: 1200px;
  div.titleDiv {
    padding: 18px 24px 16px;
  }
  div.contentDiv {
    padding: 24px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

export const WorkLifeDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  max-width: 1200px;
  div.titleDiv {
    padding: 18px 24px 16px;
  }
  div.contentDiv {
    padding: 24px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;
export const LoadingWrapper = styled.div`
  width: 70vw;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LoadingDiv = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeedbackDiv = styled.div`
  border: 1px solid ${getColorFromTheme('borderLight')};
  border-radius: 10px;
  max-width: 1200px;
  div.titleDiv {
    padding: 18px 24px 16px;
  }
  div.contentDiv {
    padding: 24px;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid ${getColorFromTheme('borderLight')};
  }
`;

export const StatusWrapper = styled.div`
  background-color: ${getColorFromTheme('backgroundDark')};
  border-radius: 8px;
  width: fit-content;
  margin-top: 4px;
  padding: 4px 8px;
`;
