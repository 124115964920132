import {Body2, Headline2} from '@ui/atoms/typography';
import {FlexRow, FlexRowCenter, FlexRowSpaceBetween} from '@ui/style/styles';
import {CSSProperties, FC, memo, ReactNode, useCallback, useMemo} from 'react';
import {Insight} from '../insights/interface';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ArrowDownIcon} from '@ui/atoms/icons';
import {ArrowPointingUpIcon} from '@ui/atoms/icons/arrow-pointing-up';
import styled from 'styled-components';
import {CandleVIcon} from '@ui/atoms/icons/candle-v';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';
import {getInitialAdditionalFilters} from '../insights/edit-insight/edit-insight-filter';
import {VerticalInsightConfig} from './insight-config';
import {ItemLoader} from '@ui/organisms/item-loader';
import {Flash2Icon} from '@ui/atoms/icons/flash-icon';

interface InsightProps {
  showRangeType?: boolean;
  percentProgress?: number;
  showPercentProgress?: boolean;
  handleEditLayout?: (id: string) => void;
  value: number | string | ReactNode;
  config?: Insight['config'];
  name: string;
  showFlashIcon?: boolean;
  firebaseId?: string;
  disableEdit?: boolean;
  isLoading?: boolean;
  comparisonText?: string;
}

export const InsightCard: FC<InsightProps> = memo(
  ({
    config,
    name,
    value = 0,
    percentProgress,
    isLoading = false,
    showPercentProgress = false,
    showRangeType,
    showFlashIcon = false,
    handleEditLayout,
    comparisonText,
    disableEdit,
    firebaseId,
  }) => {
    const additionalFilters = useMemo(
      () => getInitialAdditionalFilters(config?.list, config?.users),
      [config?.list, config?.users],
    );

    const memoizedComparisonText = useMemo(() => comparisonText, [
      comparisonText,
    ]);

    const handleEdit = useCallback(() => {
      if (firebaseId && handleEditLayout) {
        handleEditLayout(firebaseId);
      }
    }, [firebaseId, handleEditLayout]);

    const MemoizedHeadline = memo<{value: string | number}>(({value}) => (
      <Headline2>{value}</Headline2>
    ));

    const MemoizedBody = memo<{name: string}>(({name}) => (
      <Body2 kind="textDark" className="mt-4">
        {name}
      </Body2>
    ));

    return (
      <>
        <div className="bg-white flex flex-col items-start shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] py-6 px-4 border h-full rounded-[10px] border-borderLight min-h-[150px]">
          {showRangeType && config && (
            <>
              <FlexRowSpaceBetween className="">
                <div className="flex items-center gap-2">
                  {Boolean(showFlashIcon) && <Flash2Icon />}

                  <VerticalInsightConfig
                    config={config}
                    additionalFilters={additionalFilters}
                    comparisonText={memoizedComparisonText}
                  />
                </div>
                {showRangeType && !disableEdit && (
                  <button onClick={handleEdit}>
                    <HoverTooltip tooltipText="Edit insight">
                      <CandleVIcon />
                    </HoverTooltip>
                  </button>
                )}
              </FlexRowSpaceBetween>
              <VerticalSpacer size="16px" />
            </>
          )}
          {isLoading ? (
            <FlexRowCenter className="w-full mt-4">
              <ItemLoader />
            </FlexRowCenter>
          ) : (
            <>
              {typeof value === 'string' || typeof value === 'number' ? (
                <MemoizedHeadline value={value} />
              ) : (
                value
              )}

              <MemoizedBody name={name} />
              <FlexRowSpaceBetween className="mt-6">
                {(showPercentProgress || showRangeType) && (
                  <FlexRow data-testid="percentage-progress-container">
                    {showPercentProgress && (
                      <PercentageProgress value={percentProgress} />
                    )}
                  </FlexRow>
                )}
              </FlexRowSpaceBetween>
            </>
          )}
        </div>
      </>
    );
  },
);

const PercentageChangeWrapper = styled.div<{
  color: string;
  svgHeight?: string;
  svgWidth?: string;
}>`
  margin-left: 2px;

  svg {
    stroke: ${({color}) => color};
    width: ${({svgWidth}) => svgWidth || '16px'};
    height: ${({svgHeight}) => svgHeight || '16px'};
  }
`;

export const PercentageProgress = ({
  value,
  textStyle,
  svgStyle,
}: {
  value?: number | string;
  textStyle?: CSSProperties;
  svgStyle?: {
    svgHeight: string;
    svgWidth: string;
  };
}) => {
  return (
    <div data-testid="insight-percentage-progress">
      {Number(value) > 0 && (
        <FlexRow data-testid="percentage-progress">
          <Body2 weight="semibold" kind={'green300'} style={textStyle}>
            +{value}%
          </Body2>

          <PercentageChangeWrapper color="#47B881" {...svgStyle}>
            <ArrowPointingUpIcon />
          </PercentageChangeWrapper>
        </FlexRow>
      )}

      {Number(value) < 0 && (
        <FlexRow data-testid="percentage-progress">
          <Body2 weight="semibold" kind={'red400'} style={textStyle}>
            {value}%
          </Body2>
          <PercentageChangeWrapper color="#D52A2A" {...svgStyle}>
            <ArrowDownIcon />
          </PercentageChangeWrapper>
        </FlexRow>
      )}

      {!value && (
        <FlexRow data-testid="percentage-progress">
          <Body2 weight="semibold" kind="textMuted" style={textStyle}>
            -
          </Body2>
        </FlexRow>
      )}
    </div>
  );
};
