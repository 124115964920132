import {memo} from 'react';

export const HashIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      d="M2.668 6h10.667M2.668 10h10.667M6.665 2L5.332 14M10.665 2L9.332 14"
    />
  </svg>
));

HashIcon.displayName = 'HashIcon';
