import {useState, useCallback, useMemo, useRef, useEffect} from 'react';
import {
  InputWrapper,
  InputWrapperHeader,
  Button,
  PopContainer,
  EmojiSuggestionWrapper,
} from '../comments-style';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
import {
  writeData,
  updateData,
  AddMentions,
  AddSubscribers,
} from '../../../../utils/firebase-request';
import {Entry} from './entry';
import {SmileyEmoji} from '../../../atoms/icons/smiley-emoji';
import {useStoreContext} from '../../../../store/store-context';
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import createEmojiPlugin, {defaultTheme} from '@draft-js-plugins/emoji';
import Editor from '@draft-js-plugins/editor';
import {ArrowLineRight} from '../../../atoms/icons/arrow-right-line';
import {FlexRow} from '@ui/style/styles';

const {hasCommandModifier} = KeyBindingUtil;

export function InputComment({
  pathId,
  editComments,
  handleEditComments,
  allMentionedUsers,
  allowedMentionedUsers,
  mentionsPath,
  isReadOnly,
  path,
  allGoalSubscribers,
  defaultSubscribers,
}: any) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [hashGoal] = useState(false);
  const [searchValue, setSearchValue] = useState<any>([]);
  const [, setGoalMention] = useState<any>();
  const ref = useRef<Editor>(null);
  const [open, setOpen] = useState(false);
  const inputElement: any = useRef(null);

  const {
    usersStore: {users},
    authStore: {auth},
    companyStore: {handleCommentRef},
  } = useStoreContext();
  const {
    MentionSuggestions,
    EmojiSuggestions,
    EmojiSelect,
    plugins,
  } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: {mention: 'mentionss'},
      mentionPrefix: '@',
      supportWhitespace: true,
    });

    // eslint-disable-next-line no-shadow
    const {MentionSuggestions} = mentionPlugin;

    const emojiPlugin = createEmojiPlugin({
      useNativeArt: true,
      positionSuggestions: () => {
        return {marginRight: '300px'};
      },

      selectButtonContent: (
        <>
          <div>
            <SmileyEmoji />
          </div>
        </>
      ),
      theme: {...defaultTheme, emojiSelectPopover: 'emojiSuggestion'},
      toneSelectOpenDelay: 0,
    });

    // eslint-disable-next-line no-shadow
    const {EmojiSuggestions, EmojiSelect} = emojiPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin];
    return {
      plugins,
      MentionSuggestions,
      EmojiSuggestions,
      EmojiSelect,
    };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const handleUsers = useCallback(() => {
    const formatUsers = (users: any[]) => {
      return users.map((user: any) => {
        return {
          name:
            user.firstName && user.lastName
              ? user.firstName + ' ' + user.lastName
              : user.email,
          id: user.id,
        };
      });
    };

    if (allowedMentionedUsers) {
      return formatUsers(
        users.filter((user) => allowedMentionedUsers.includes(user.id)),
      );
    }

    return formatUsers(users.filter((user) => user.id !== auth?.user?.id));
  }, [users, auth?.user?.id, allowedMentionedUsers]);

  const handleMentions = useCallback(() => {
    const formatUsers = (users: any[]) => {
      return users.map((user: any) => {
        return {
          name:
            user.firstName && user.lastName
              ? user.firstName + ' ' + user.lastName
              : user.email,
          id: user.id,
        };
      });
    };

    if (allowedMentionedUsers) {
      return formatUsers(
        users.filter((user) => allowedMentionedUsers.includes(user.id)),
      );
    }

    return setSearchValue(formatUsers(users));
  }, [users, allowedMentionedUsers]);

  useEffect(() => {
    handleMentions();
  }, [handleMentions]);

  useEffect(() => {
    if (editComments) {
      if (!editComments) {
        setEditorState(EditorState.createEmpty());
        handleEditComments(undefined);
      } else {
        const rawContent = {
          blocks: [
            {
              text: editComments[1].entities.blocks[0].text,
              type: 'unstyled',
              entityRanges: editComments[1].entities.blocks[0].entityRanges,
            },
          ],
          entityMap: editComments[1].entities.entityMap
            ? editComments[1].entities.entityMap
            : {},
        };
        const blocks = convertFromRaw(rawContent as any);
        const newContent = EditorState.createWithContent(blocks);
        setGoalMention(editComments[1].goalMentions);
        setEditorState(newContent);
      }
    }
  }, [editComments, handleEditComments]);

  const onChange = useCallback((_editorState: EditorState) => {
    setEditorState(_editorState);
  }, []);

  const onSearchChange = useCallback(
    ({value}: {value: string}) => {
      setSearchValue(defaultSuggestionsFilter(value, handleUsers()));
    },
    [handleUsers],
  );

  const handleSubmit = () => {
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    const text = raw.blocks[0].text;

    handleCommentRef('');

    if (text) {
      const res: any = raw.entityMap;
      const obj = Object.entries(res).map((item: any) => item[1]);
      const mentionUser = obj.map(
        (item: any) => item.type === 'mention' && item?.data?.mention,
      );

      const filterMention = mentionUser.filter((item: any) => item !== false);

      let result = text;

      filterMention?.forEach((item: any) => {
        result = result.replace(`@${item.name}`, `_(${item.id})_`);
      });

      setEditorState(EditorState.createEmpty());

      if (editComments) {
        updateData(
          editComments[0],
          {
            comment: result,
            commentBy: auth.user.id,
            createdAt: editComments[1].createdAt,
            edited: true,
            rawComment: raw,
            updatedAt: Date.now(),
          },
          path,
        );

        handleEditComments(undefined);
      } else {
        writeData(
          {
            comment: result,
            commentBy: auth.user.id,
            createdAt: Date.now(),
            edited: false,
            rawComment: raw,
            updatedAt: Date.now(),
          },
          path,
        );

        const mentions = filterMention.map((mention) => mention.id);
        const handleNewAddMentions = (data: any) => {
          data.forEach((id: string) => {
            AddMentions(
              id,
              pathId,
              {
                unreadTasks: 0,
                tasksMentionedBy: [],
                mentionedBy: mentions.includes(id) ? [auth.user.id] : [],
                unreadComment: id === auth.user.id ? 0 : 1,
              },
              mentionsPath,
            );
          });
        };
        if (allGoalSubscribers?.length) {
          const users = [...new Set([...allGoalSubscribers, ...mentions])];
          AddSubscribers(path, users);
          if (allMentionedUsers?.length) {
            allMentionedUsers.forEach((user: any) => {
              const id: any = Object.keys(user)[0];
              const getMentionedBy = user[id]?.mentionedBy || [];

              AddMentions(
                id,
                pathId,
                {
                  unreadTasks: user[id]?.unreadTasks || 0,
                  tasksMentionedBy: user[id]?.tasksMentionedBy || [],
                  mentionedBy: mentions.includes(id)
                    ? [...new Set([...getMentionedBy, auth.user.id])]
                    : [...new Set([...getMentionedBy])],
                  unreadComment:
                    user[id] === auth.user.id ? 0 : user[id]?.unreadComment + 1,
                },
                mentionsPath,
              );
            });
          } else {
            handleNewAddMentions(users);
          }
        } else {
          if (defaultSubscribers) {
            const users = [...new Set([...defaultSubscribers, ...mentions])];
            AddSubscribers(path, users);
            handleNewAddMentions(users);
          }
        }
      }
    }
  };

  function myKeyBindingFn(e: any): any {
    if (e.key === 'enter' && e.ctrlKey && hasCommandModifier(e)) {
      handleSubmit();
      return '';
    }
    return getDefaultKeyBinding(e);
  }
  return (
    <InputWrapper>
      <InputWrapperHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div
            className={'editor w-full '}
            style={{padding: '3px 5px'}}
            onClick={() => {
              hashGoal === false && ref.current!.focus();
            }}>
            <Editor
              editorKey={'editor'}
              editorState={editorState}
              handleKeyCommand={myKeyBindingFn}
              onChange={onChange}
              readOnly={isReadOnly}
              textAlignment="left"
              placeholder="You can comment here"
              ref={ref}
              plugins={plugins}
            />
            <EmojiSuggestionWrapper>
              <EmojiSuggestions />
            </EmojiSuggestionWrapper>
          </div>

          <FlexRow>
            <div className={'testing'}>
              <EmojiSelect />
            </div>
            <Button onClick={() => handleSubmit()} disabled={isReadOnly}>
              <ArrowLineRight color="white" />
            </Button>
          </FlexRow>
        </div>

        <div style={{position: 'relative'}}>
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={searchValue}
            onSearchChange={onSearchChange}
            entryComponent={Entry}
            popoverContainer={({children}) => (
              <PopContainer style={{zIndex: 5, position: 'absolute'}}>
                {' '}
                {children}
              </PopContainer>
            )}
          />
        </div>
        {hashGoal && (
          <>
            <div
              ref={inputElement}
              style={{
                background: 'white',
                position: 'absolute',
                top: '40px',
                zIndex: 5,
                width: '300px',
                borderRadius: '10px',
              }}></div>
          </>
        )}
      </InputWrapperHeader>
    </InputWrapper>
  );
}
