import {observer} from 'mobx-react-lite';
import {useMemo, useCallback} from 'react';
import {useStoreContext} from '@store/store-context';
import {Body1, Headline2} from '@ui/atoms/typography';
import styled from 'styled-components';
import {
  DropdownItem,
  DropdownWrapper,
  MenuButtonPrimary,
} from '../you/you-page.styles';
import {FlexRowSpaceBetween} from '@ui/style/styles';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {capitalize} from '@utils';
import {cfv} from '@utils/framework';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import {useNavigate} from 'react-router';
import {MyObjectivesPage} from './my-objective/myObjective';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {KpisPage} from './kpis/kpis-page';

const Wrapper = styled.div`
  width: 100%;
`;

export const ObjectivesPage = observer(() => {
  const navigate = useNavigate();

  const {
    storeDashboardRoutes: {ObjTabs, activateObjTabs},
    authStore: {auth},
    goalsState,
  } = useStoreContext();

  const navigations: any = useMemo(() => {
    const nav = [
      {
        active: ObjTabs.objective,
        disable: false,
        href: '/goals',
        id: '1',
        onClick: () => {
          activateObjTabs('objective');
        },
        iconSpacing: '',
        title: `Objectives`,
        icon: <></>,
      },
    ];

    if (auth.user.workspace.config.allowKpi) {
      nav.push({
        active: ObjTabs.kpi,
        disable: false,
        href: '/goals/kpi',
        id: '2',
        onClick: () => {
          activateObjTabs('kpi');
        },
        title: `KPIs`,
        iconSpacing: 'space-between',
        icon: <></>,
      });
    }
    return nav;
  }, [activateObjTabs, ObjTabs, auth.user.workspace.config.allowKpi]);

  const showAlignment = auth.user.workspace.settings.goalAlignment;

  const handleCreateGoalClick = useCallback(
    (type: string) => {
      switch (type) {
        case 'scratch':
          goalsState.setShowGoalForm(true);
          goalsState.setEditing(false);
          navigate('/create-goal', {
            state: undefined,
          });
          break;
        case 'template':
          navigate('/explore-templates');
          break;
        default:
          goalsState.setShowGoalForm(true);
          goalsState.setEditing(false);

          navigate('/create-goal', {
            state: undefined,
          });
          break;
      }
    },
    [goalsState, navigate],
  );

  const CreateGoalElement = () => (
    <DropdownWrapper>
      <DropdownCustom
        collapseOnClick={true}
        menu={() => (
          <div>
            <div>
              <DropdownItem hoverColor="#585ADF" style={{marginBottom: '5px'}}>
                <FlexRowSpaceBetween
                  style={{cursor: 'pointer'}}
                  onClick={() => handleCreateGoalClick('scratch')}>
                  Create {capitalize(cfv().g_oal)}
                </FlexRowSpaceBetween>
              </DropdownItem>
            </div>
            {auth.user.workspace.config.allowKpi && (
              <DropdownItem
                hoverColor="#585ADF"
                onClick={() => {
                  navigate('/create-goal?kpi=true');
                  goalsState.setEditing(false);
                  goalsState.setShowGoalForm(true);
                }}>
                Create KPI
              </DropdownItem>
            )}

            {showAlignment && (
              <DropdownItem
                hoverColor="#585ADF"
                onClick={() => {
                  navigate('/create-alignment');
                }}>
                Create alignment
              </DropdownItem>
            )}
            {showAlignment && (
              <DropdownItem
                hoverColor="#585ADF"
                onClick={() => {
                  navigate('/cascade-keyresult');
                }}>
                Cascade key results
              </DropdownItem>
            )}
          </div>
        )}
        customComponent={(handleOpen: (event: any) => void, open: boolean) => (
          <MenuButtonPrimary onClick={handleOpen} width="sm">
            Create
            <ArrowHeadDownIcon
              style={{
                marginLeft: 14,
                stroke: '#fff',
                transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            />
          </MenuButtonPrimary>
        )}
        customDropdownWrapperStyles={{
          top: '130%',
          right: 0,
          width: '190px',
        }}
      />
    </DropdownWrapper>
  );

  return (
    <Wrapper className="w-screen h-screen">
      <FlexRowSpaceBetween className="mb-6">
        <div className="">
          <Headline2>Goals</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Manage personal, team, and company-wide objectives, KPIs, and
              progress.
            </Body1>
          </div>
        </div>
        <CreateGoalElement />
      </FlexRowSpaceBetween>

      <TabNavigation navigations={navigations} />

      {ObjTabs.objective && <MyObjectivesPage />}

      {ObjTabs.kpi && <KpisPage />}

      <VerticalSpacer size="200px" />
    </Wrapper>
  );
});
