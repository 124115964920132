import React, {useState} from 'react';
import {SimpleCustomDropdown} from '@ui/molecules/dropdown-custom/simple-dropdown-custom';
import {Button} from '@ui/atoms/button';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import dayjs from 'dayjs';
import {DateRangeInput} from '@ui/molecules/date-range-input';
import {Body1} from '@ui/atoms/typography';
import {Flash2Icon} from '@ui/atoms/icons/flash-icon';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface DateStrings {
  starts: string;
  ends: string;
}

interface OverrideOption {
  label: string;
  value: DateRange | 'custom' | null;
}

interface DateRangeOverrideProps {
  onDateRangeChange: (range: DateRange) => void;
}

interface WrapperProps {
  children: React.ReactNode;
}

const DateWrapper: React.FC<WrapperProps> = ({children}) => (
  <div className="p-4 bg-white rounded-lg shadow-lg border border-[#fafafa]">
    {children}
  </div>
);

const ButtonWrapper: React.FC<WrapperProps> = ({children}) => (
  <div className="flex justify-end gap-2 mt-4 px-4 pb-4 bg-white">
    {children}
  </div>
);

const overrideOptions: OverrideOption[] = [
  {
    label: 'No date range overriden',
    value: null,
  },
  {
    label: 'Last 7 days',
    value: {
      startDate: dayjs().subtract(7, 'days').toDate(),
      endDate: dayjs().toDate(),
    },
  },
  {
    label: 'Last 14 days',
    value: {
      startDate: dayjs().subtract(14, 'days').toDate(),
      endDate: dayjs().toDate(),
    },
  },
  {
    label: 'Last 28 days',
    value: {
      startDate: dayjs().subtract(28, 'days').toDate(),
      endDate: dayjs().toDate(),
    },
  },
  {
    label: 'Last 30 days',
    value: {
      startDate: dayjs().subtract(30, 'days').toDate(),
      endDate: dayjs().toDate(),
    },
  },
  {
    label: 'Last 60 days',
    value: {
      startDate: dayjs().subtract(60, 'days').toDate(),
      endDate: dayjs().toDate(),
    },
  },
  {
    label: 'Last 90 days',
    value: {
      startDate: dayjs().subtract(90, 'days').toDate(),
      endDate: dayjs().toDate(),
    },
  },
  {
    label: 'Last 180 days',
    value: {
      startDate: dayjs().subtract(180, 'days').toDate(),
      endDate: dayjs().toDate(),
    },
  },
  {
    label: 'This month',
    value: {
      startDate: dayjs().startOf('month').toDate(),
      endDate: dayjs().endOf('month').toDate(),
    },
  },
  {
    label: 'This year',
    value: {
      startDate: dayjs().startOf('year').toDate(),
      endDate: dayjs().endOf('year').toDate(),
    },
  },
  {
    label: 'Custom timeline',
    value: 'custom',
  },
];

const DateRangeOverride: React.FC<DateRangeOverrideProps> = ({
  onDateRangeChange,
}) => {
  const [selectedOption, setSelectedOption] = useState<OverrideOption>(
    overrideOptions[0],
  );

  const [showCustomDateFilter, setShowCustomDateFilter] = useState<boolean>(
    false,
  );

  const [dates, setDates] = useState<DateStrings>({
    starts: dayjs().subtract(7, 'days').format('MM/DD/YYYY'),
    ends: dayjs().format('MM/DD/YYYY'),
  });

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: dayjs().subtract(7, 'days').toDate(),
    endDate: dayjs().toDate(),
  });

  const handleOptionSelect = (option: OverrideOption): void => {
    setSelectedOption(option);

    if (option.value === 'custom') {
      setShowCustomDateFilter(true);
    } else {
      setShowCustomDateFilter(false);

      if ((option.value as any) !== 'custom') {
        if (option.value) setDateRange(option.value);

        onDateRangeChange(option.value as any);
      }
    }
  };

  const handleApplyCustomDate = (): void => {
    const newDateRange: DateRange = {
      startDate: dayjs(dates.starts).toDate(),
      endDate: dayjs(dates.ends).toDate(),
    };
    setDateRange(newDateRange);
    setShowCustomDateFilter(false);
    onDateRangeChange(newDateRange);
  };

  const handleCancel = (): void => {
    setShowCustomDateFilter(false);
  };

  const isInvalidDateRange = (): boolean => {
    return new Date(dates.ends) < new Date(dates.starts);
  };

  return (
    <div>
      <SimpleCustomDropdown
        menu={(handleOpen) => (
          <Button
            onClick={handleOpen}
            width="sm"
            kind="secondary"
            style={{padding: '8px 16px'}}
            className="w-full sm:w-auto flex flex-row items-center gap-3 text-xs justify-center sm:justify-start">
            {Boolean(selectedOption.value) && <Flash2Icon />}

            {selectedOption.label}

            <ArrowHeadDownIcon
              style={{
                marginLeft: 14,
                stroke: '#242424',
              }}
            />
          </Button>
        )}
        canClose={(value) => value !== 'Custom timeline'}
        options={overrideOptions.map((option) => ({
          ...option,
          onClick: () => handleOptionSelect(option),
        }))}
        menuOptions={
          showCustomDateFilter ? (
            <>
              <DateWrapper>
                <div className="flex justify-between items-center">
                  <Body1 weight="semibold">Select date range</Body1>

                  <ButtonWrapper>
                    <Button
                      kind="primary"
                      onClick={handleApplyCustomDate}
                      style={{padding: '8px 16px'}}
                      disabled={isInvalidDateRange()}>
                      Apply
                    </Button>
                    <Button
                      kind="secondary"
                      style={{padding: '8px 16px'}}
                      onClick={handleCancel}>
                      Cancel
                    </Button>
                  </ButtonWrapper>
                </div>
                <DateRangeInput
                  name="date"
                  value={{starts: dates.starts, ends: dates.ends}}
                  block={true}
                  helper={
                    isInvalidDateRange()
                      ? 'Start date must be before end date'
                      : ''
                  }
                  state={isInvalidDateRange() ? 'error' : 'default'}
                  admin={true}
                  placeholder="Select Date"
                  setValue={(e: {starts: string; ends: string}) => {
                    setDates({
                      starts: dayjs(e.starts).format('MM/DD/YYYY'),
                      ends: dayjs(e.ends).format('MM/DD/YYYY'),
                    });
                  }}
                />
              </DateWrapper>
            </>
          ) : null
        }
      />
    </div>
  );
};

export default DateRangeOverride;
