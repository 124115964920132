import {useStoreContext} from '@store/store-context';
import {SecondaryNavigation} from '@ui/molecules/nav/navigation';
import {FlexRow} from '@ui/style/styles';
import {ReactNode, useMemo, useState, memo} from 'react';
import {GoalsWidget} from '../get-started/goals/goals';
import {Body1, Body2} from '@ui/atoms/typography';
import {HomeActionItemsWidget} from '../get-started/action-items/actionItems';
import {SimpleCustomDropdown} from '@ui/molecules/dropdown-custom/simple-dropdown-custom';
import {Button} from '@ui/atoms/button';
import {ArrowHeadDownIcon} from '@ui/atoms/icons';
import dayjs from 'dayjs';
import {HomeFeedbackWidget} from '../get-started/feedback/feedback';
import {userName} from '@utils/user-name';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {Avatars} from '@ui/molecules/avatars';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {ReportingBaseFilter} from '../reporting/reporting-filter/base-filter';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';

export const QuickInsightPage = memo(() => {
  const [activeTab, setActiveTab] = useState<'you' | 'team' | 'custom'>('you');

  const {
    authStore: {auth},
    usersStore: {users},
  } = useStoreContext();

  const [selectedDateRange, setSelectedDateRange] = useState('this-quarter');

  const {
    isFeedBackEnabled,
    isActionItemEnabled,
    isGoalEnabled,
  } = useWorkspaceUseCase();

  const [selectedGroups, setSelectedGroups] = useState<
    {label: string; value: string}[]
  >([]);

  const [selectedTeams, setSelectedTeams] = useState<
    {label: string; value: string}[]
  >([]);

  const [selectedMembers, setSelectedMembers] = useState<
    {label: string; value: string}[]
  >([]);

  const team = users
    .filter((user) => (user.reviewer?.id || user?.reviewer) === auth.user.id)
    .map((user) => ({
      name: userName(user),
      src: user.avatar?.url,
      id: user.id,
    }));

  const dateOptions = [
    {
      value: 'this-quarter',
      label: 'This quarter',
    },
    {
      value: 'last-30-days',
      label: 'Last 30 days',
    },
    {
      value: 'last-60-days',
      label: 'Last 60 days',
    },
    {
      value: 'last-90-days',
      label: 'Last 90 days',
    },
    {
      value: 'this-year',
      label: 'This year',
    },
  ];

  const navigations = useMemo(() => {
    const nav = [
      {
        active: activeTab === 'you',
        href: '',
        id: '2',
        onClick: () => {
          setActiveTab('you');
        },
        title: 'You',
      },
    ];
    if (auth.user.isReviewer) {
      nav.push({
        active: activeTab === 'team',
        href: '',
        id: '2',
        onClick: () => {
          setActiveTab('team');
        },
        title: 'Your Team',
      });
    }

    if (auth.user.role === 'admin') {
      nav.push({
        active: activeTab === 'custom',
        href: '',
        id: '2',
        onClick: () => {
          setActiveTab('custom');
        },
        title: 'Custom',
      });
    }
    return nav;
  }, [activeTab, auth.user.isReviewer, auth.user.role]);

  const range = getDateRanges(selectedDateRange);

  const comparisonText = `Compared to preceding period`;

  const selectedFilter = dateOptions.find(
    (option) => option.value === selectedDateRange,
  )?.label;

  const filteredUsers = useMemo(() => {
    const members: string[] = [];

    if (selectedGroups.length) {
      const formattedGroups = selectedGroups.map((group) => group.value);

      const usersInGroups = users
        .filter((user) =>
          user?.groups?.some((group) => formattedGroups.includes(group.id)),
        )
        .map((user) => user.id);

      if (usersInGroups.length) members.push(...usersInGroups);
    }

    if (selectedTeams.length) {
      const formattedTeams = selectedTeams.map((team) => team.value);
      const userInTeams = users
        .filter((user) => formattedTeams.includes(user.reviewer.id))
        .map((user) => user.id);

      if (userInTeams) members.push(...userInTeams);
    }
    if (selectedMembers.length) {
      const formattedMembers = selectedMembers.map((team) => team.value);
      if (formattedMembers) members.push(...formattedMembers);
    }
    return members;
  }, [selectedGroups, selectedMembers, selectedTeams, users]);

  const teams = selectedTeams.map((team) => team.value).join(',');

  const members = selectedMembers.map((member) => member.value).join(',');

  const groups = selectedGroups.map((group) => group.value).join(',');

  return (
    <main className="bg-white border border-borderLight p-6 rounded-[10px] mt-6">
      <div className="flex sm:flex-col flex-row justify-between gap-4 w-full">
        <SimpleCustomDropdown
          menu={(handleOpen) => (
            <Button
              onClick={handleOpen}
              width="sm"
              kind="secondary"
              style={{padding: '8px 16px'}}
              className="w-full sm:w-auto flex flex-row items-center text-xs justify-center sm:justify-start">
              {selectedFilter}

              <span className="font-normal ml-1">
                ({dayjs(range.current.startDate).format('DD MMM.')} -{' '}
                {dayjs(range.current.endDate).format("DD MMM. 'YY")})
              </span>
              <ArrowHeadDownIcon
                style={{
                  marginLeft: 14,
                  stroke: '#242424',
                }}
              />
            </Button>
          )}
          options={dateOptions.map((option) => ({
            ...option,
            onClick: () => setSelectedDateRange(option.value),
          }))}
        />

        <div className="flex sm:flex-col flex-row gap-4 ">
          <div>
            <SecondaryNavigation
              kind="details"
              navStyle={{
                padding: '4px 8px',
                height: '38px',
              }}
              type="button"
              navigations={navigations}
            />
          </div>

          {activeTab === 'team' && (
            <div className="flex items-center gap-4">
              <HorizontalSideRule className="sm:hidden block" size="25px" />
              <Avatars
                size="md"
                showCount={5}
                items={team}
                total={team.length}
                tooltip
              />
            </div>
          )}

          {activeTab === 'custom' && (
            <div className="flex items-center gap-4">
              <HorizontalSideRule className="sm:hidden block" size="25px" />
              <DropdownCustom
                collapseOnClick={true}
                menu={() => (
                  <ReportingBaseFilter
                    selectedGroups={selectedGroups}
                    onChangeTeams={setSelectedTeams}
                    children={<></>}
                    className="min-h-[300px]"
                    selectedTeams={selectedTeams}
                    onChangeMembers={setSelectedMembers}
                    hideAddFilter
                    selectedMembers={selectedMembers}
                    onChangeGroups={setSelectedGroups}
                  />
                )}
                customComponent={(
                  handleOpen: (event: any) => void,
                  open: boolean,
                ) => (
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      handleOpen(event);
                    }}
                    className="w-full sm:w-auto"
                    type="button">
                    <FlexRow className="gap-1 justify-center sm:justify-start">
                      <Body2 weight="bold" kind="purple300">
                        Filters
                      </Body2>
                      <ArrowHeadDownIcon
                        style={{
                          marginLeft: 14,
                          stroke: '#585ADF',
                          transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                      />
                    </FlexRow>
                  </button>
                )}
                customDropdownWrapperStyles={{
                  top: '130%',
                  boxShadow: '0px',
                  right: 0,
                  padding: 0,
                  overflowY: 'auto',
                  width: '280px',

                  background: 'none',
                  border: 0,
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-6 mt-4">
        {isGoalEnabled && (
          <WidgetLayout title="Goals">
            <GoalsWidget
              activeTab={activeTab}
              selectedTeams={teams}
              selectedGroups={groups}
              selectedMembers={members}
              range={{...range, comparisonType: comparisonText}}
            />
          </WidgetLayout>
        )}
        {isActionItemEnabled && (
          <WidgetLayout title="Action items">
            <HomeActionItemsWidget
              activeTab={activeTab}
              members={filteredUsers}
              range={{...range, comparisonType: comparisonText}}
            />
          </WidgetLayout>
        )}
        {isFeedBackEnabled && (
          <WidgetLayout title="Engagement">
            <HomeFeedbackWidget
              activeTab={activeTab}
              selectedTeams={teams}
              selectedMembers={groups ? filteredUsers.join(',') : members}
              range={{...range, comparisonType: comparisonText}}
            />{' '}
          </WidgetLayout>
        )}
      </div>
    </main>
  );
});

interface WidgetLayoutProps {
  title: string;
  children: ReactNode;
}
const WidgetLayout = ({title, children}: WidgetLayoutProps) => {
  return (
    <div className="border border-borderLight p-4 px-6 rounded-[10px] flex flex-col gap-6">
      <Body1 weight="bold">{title}</Body1>

      <div>{children}</div>
    </div>
  );
};

function getDateRanges(option: string) {
  const now = new Date();
  let startDate: any = '',
    endDate: any = '',
    comparisonStartDate: any = '',
    comparisonEndDate: any = '';

  switch (option) {
    case 'this-quarter': {
      const currentQuarter = Math.floor(now.getMonth() / 3);

      startDate = new Date(now.getFullYear(), currentQuarter * 3, 1);
      endDate = new Date(now.getFullYear(), (currentQuarter + 1) * 3, 0);

      // Previous quarter for comparison
      comparisonStartDate = new Date(startDate);

      comparisonStartDate.setMonth(startDate.getMonth() - 3);

      comparisonEndDate = new Date(endDate);

      comparisonEndDate.setMonth(endDate.getMonth() - 3);

      comparisonEndDate.setMinutes(comparisonEndDate.getMinutes() - 1);

      break;
    }

    case 'last-30-days': {
      endDate = new Date();
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);

      // Previous 30 days for comparison
      comparisonEndDate = new Date(startDate);
      comparisonStartDate = new Date(startDate);
      comparisonStartDate.setDate(comparisonStartDate.getDate() - 30);
      comparisonEndDate.setDate(comparisonEndDate.getDate() - 1);

      break;
    }
    case 'last-60-days': {
      endDate = new Date();

      startDate = new Date();

      startDate.setDate(startDate.getDate() - 60);

      // Previous 30 days for comparison
      comparisonEndDate = new Date(startDate);
      comparisonStartDate = new Date(startDate);
      comparisonStartDate.setDate(comparisonStartDate.getDate() - 60);
      comparisonEndDate.setDate(comparisonEndDate.getDate() - 1);

      break;
    }
    case 'last-90-days': {
      endDate = new Date();
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 90);

      // Previous 30 days for comparison
      comparisonEndDate = new Date(startDate);
      comparisonStartDate = new Date(startDate);
      comparisonStartDate.setDate(comparisonStartDate.getDate() - 90);
      comparisonEndDate.setDate(comparisonEndDate.getDate() - 1);

      break;
    }

    case 'this-year': {
      startDate = new Date(now.getFullYear(), 0, 1);
      endDate = new Date(now.getFullYear(), 11, 31);

      // Previous year for comparison
      comparisonStartDate = new Date(now.getFullYear() - 1, 0, 1);
      comparisonEndDate = new Date(now.getFullYear() - 1, 11, 31);

      break;
    }

    default:
      break;
  }

  return {
    current: {
      startDate: dayjs(startDate).format(),
      endDate: dayjs(endDate).format(),
    },
    comparison: {
      startDate: comparisonStartDate,
      endDate: comparisonEndDate,
    },
  };
}
