import {useCallback, useMemo, useState} from 'react';
import {MeetingRequestImpl} from '../meeting.request';
import {authStore} from '@store/stores/auth-store';
import {GoogleCalendarEvent, IAPIUserFormat} from '@hooks';
import {get} from 'idb-keyval';
import {FeedbackController} from '@pages/feedback/feedback-controller';
import {FeedbackRequestImpl} from '@pages/feedback/feedback-requests';
import {MeetingController} from '../meeting-controller';
import {IMeetingResponse} from '@hooks';
import {beamMasteryTasks} from '@utils/firebase-request';
import {usePostHogHook} from '@hooks/post-hog';
import {
  removeFirebaseData,
  updateFirebaseData,
  writeFirebaseData,
} from '@utils/firebase-handler';
import dayjs from 'dayjs';
import {removeEmptyStringFromObject} from '@utils/data-structure-algos';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {useNavigate, useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {useActionItems} from '@hooks/features-action-items';
import {useGCalenderHook} from '@hooks/gcalender';
import {checkinName} from '@utils/checkin-name';
import {useQueryClient} from 'react-query';
import {capitalize} from '@utils';
import {useStoreContext} from '@store/store-context';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {parseRecurrenceRule} from '@utils/recurrence';

export interface PatchMeetingFormData {
  participant: string;
  [x: string]: any;
  shareHostAgenda: boolean;
  hostAgenda: {text: string; completed: boolean}[];
  startDate: string;
  frequency: string;
  time: string;
  reminder: string[];
}

export const useViewMeetingHook = () => {
  const request = useMemo(() => new MeetingRequestImpl(), []);
  const controller = useMemo(() => new MeetingController(request), [request]);

  const [agendas, setAgendas] = useState({host: [], participant: []});

  const {
    usersStore: {users},
  } = useStoreContext();
  const {
    getCalendarEventById,
    isSigned,
    deleteEvent,
    updateEvent,
    getEmail,
  } = useGCalenderHook();

  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const fetchMeetingDetails = useCallback(
    async (
      id: string,
      startMeeting?: boolean,
    ): Promise<
      | (IMeetingResponse & {
          disableActionItems?: boolean;
          primaryMeeting?: {
            title: string;
            connectedCalendar: any[];
            description: string;
            id: string;
            meetingLink: string;
            enableBeamAI: boolean;
          };
          recurrence?: {
            pattern: string;
            frequency: string;
            interval?: number;
            until?: string;
            count?: number;
          };
        })
      | false
    > => {
      const users = await get(`${authStore.auth.user.workspace.id}/users`);

      const response = await controller.getMeeting(id, startMeeting);
      if (response) {
        const primaryDetails = {
          title: response.title,
          description: response.description,
          enableBeamAI: response.enableBeamAI,
          id: response.id,
          meetingLink: response.meetingLink,
          connectedCalendar: response.connectedCalendar,
          startDate: response.startDate,
          time: response.time,
        };

        // Get primary meeting details
        if (response.connectedCalendar) {
          const primaryMeeting = response.connectedCalendar.find(
            (calendar: {isPrimary: boolean}) => calendar.isPrimary,
          );

          if (primaryMeeting) {
            if (primaryMeeting.calendarId !== response.calendarId) {
              const _response_ = await controller.getMeetings({
                calendarId: primaryMeeting?.calendarId,
                startDate: primaryMeeting?.startDate,
              });

              if (_response_) {
                const item = _response_.results?.[0];

                if (item) {
                  response.primaryMeeting = {
                    title: item.title,
                    description: item.description,
                    enableBeamAI: item.enableBeamAI,
                    id: item.id,
                    connectedCalendar: item.connectedCalendar,
                    meetingLink: item.meetingLink,
                    startDate: _response_.startDate,
                    time: _response_.time,
                  };
                }
              }
            } else {
              response.primaryMeeting = primaryDetails;
            }
          } else {
            response.connectedCalendar = [
              {
                isPrimary: true,
                meetingId: response.id,
                calendarId: response.calendarId,
                startDate: response.startDate,
                title: response.title,
              },
            ];

            response.primaryMeeting = primaryDetails;
          }
        }

        const _response = {
          ...response,
          user: users.find((user: IAPIUserFormat) => user.id === response.user),
          participant: response.participant.map((participant: any) =>
            typeof participant === 'string'
              ? users.find((user: IAPIUserFormat) => user.id === participant) ||
                participant
              : participant,
          ),
          time: !response.time?.toLowerCase().includes('invalid')
            ? response.time
            : '',
        };

        const getMeetingWithCode = async () => {
          const request = new MeetingRequestImpl();
          const controller = new MeetingController(request);

          const meetings = await controller.getMeetings({
            meetingCode: response.meetingCode,
            limit: 20,
          });

          if (meetings && Array.isArray(meetings.results)) {
            // Sort meetings by startDate in descending order (latest first)
            const sortedMeetings = meetings.results.sort(
              (a: IMeetingResponse, b: IMeetingResponse) => {
                const dateA = new Date(a.startDate).getTime();
                const dateB = new Date(b.startDate).getTime();
                return dateB - dateA;
              },
            );

            // Find index of current meeting
            const currentMeetingIndex = sortedMeetings.findIndex(
              (meeting: IMeetingResponse) => meeting.id === id,
            );

            if (currentMeetingIndex > 0) {
              // Check all meetings before the current meeting for bringForwardActionItems
              const hasPreviousBringForward = sortedMeetings
                .slice(0, currentMeetingIndex)
                .some(
                  (meeting: IMeetingResponse) =>
                    meeting.meta?.bringForwardActionItems,
                );

              if (hasPreviousBringForward) {
                _response.disableActionItems = true;
              }
            }
          }
        };

        await getMeetingWithCode();

        if (response?.calendarId && isSigned) {
          await getCalendarEventById(response?.calendarId, 'primary').then(
            async function (resp: any) {
              const event = resp.result;

              if (event) {
                const allUsersEmail = users.map(
                  (user: {email: string}) => user.email,
                );

                if (event.recurrence) {
                  // This is the main recurring event
                  _response.recurrence = parseRecurrenceRule(
                    event.recurrence[0],
                  );
                } else if (event.recurringEventId) {
                  // This is an instance of a recurring event
                  _response.recurrence = {
                    isRecurringInstance: true,
                    recurringEventId: event.recurringEventId,
                    pattern: 'instance',
                    frequency: 'instance',
                  };

                  // Optionally fetch the main recurring event to get the pattern
                  try {
                    const mainEvent = await getCalendarEventById(
                      event.recurringEventId,
                      'primary',
                    );

                    if (mainEvent?.result?.recurrence) {
                      const mainRecurrence = parseRecurrenceRule(
                        mainEvent.result.recurrence[0],
                      );
                      _response.recurrence = {
                        ..._response.recurrence,
                        ...mainRecurrence,
                        isRecurringInstance: true,
                        recurringEventId: event.recurringEventId,
                      };
                    }
                  } catch (error) {
                    // Continue without the main event details if fetch fails
                    console.warn(
                      'Could not fetch main recurring event details',
                      error,
                    );
                  }
                }

                const isExistingParticipant = allUsersEmail.filter(
                  (user: string) => response.participant.includes(user),
                );

                const attendees =
                  event?.attendees?.filter(
                    (attendee: {email: string}) =>
                      !isExistingParticipant.includes(attendee.email),
                  ) || [];

                attendees.forEach((attendee: {email: string}) => {
                  if (allUsersEmail.includes(attendee.email)) {
                    const user = users.find(
                      (user: {email: string}) => user.email === attendee.email,
                    );

                    _response.participant.push(user);
                  }
                });

                _response.extraParticipant = attendees;

                _response.meetingLink = event?.hangoutLink;

                if (_response.primaryMeeting)
                  _response.primaryMeeting.meetingLink = event?.hangoutLink;

                _response.googleEventDetails = event;
              }
            },
            function () {},
          );
        }

        setAgendas({
          host: _response.hostAgenda,
          participant: _response.participantAgenda,
        });

        return _response;
      }
      return false;
    },
    [controller, getCalendarEventById, isSigned],
  );

  const fetchMeetingByCalendarId = useCallback(
    async (calendarId: string, startDate: string) => {
      const existingMeetings = await controller.getMeetings({
        calendarId,
        startDate,
      });

      if (existingMeetings) {
        const meeting = existingMeetings?.results[0];
        if (meeting) {
          return await fetchMeetingDetails(meeting.id);
        }
      }
    },
    [controller, fetchMeetingDetails],
  );

  const handleShareMeeting = async (
    participants: string[],
    calendarId: string,
    beamMeetingParticipants: string[],
    googleEventAttendees: any,
  ) => {
    const request = new MeetingRequestImpl();

    const controller = new MeetingController(request);

    const emails = users
      .filter((user) => participants.includes(user.id))
      .map((user) => user.email);

    const attendees = [
      ...emails.map((participant) => ({
        email: participant,
        organizer: false,
        self: false,
        responseStatus: 'needsAction',
      })),
    ];

    if (googleEventAttendees) {
      attendees.push(...googleEventAttendees);
    }

    if (calendarId)
      await updateEvent(
        {
          attendees,
        },
        calendarId,
        'primary',
      );

    const updatedParticipant = (beamMeetingParticipants as any)?.map(
      (participant: any) =>
        typeof participant === 'string' ? participant : participant?.id,
    );

    const meetingResponse = await controller.patchMeeting(
      removeEmptyStringFromObject({
        participant: [...updatedParticipant, ...participants],
      }),
      id,
    );
    return meetingResponse;
  };

  const handleUpdateMeeting = useCallback(
    async (id: string, data: PatchMeetingFormData): Promise<any> => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const _data = {
        ...data,
      };

      if (_data.participant)
        _data.participant = (_data.participant as any)?.map(
          (participant: any) =>
            typeof participant === 'string' ? participant : participant?.id,
        );

      const response = await controller.patchMeeting(
        removeEmptyStringFromObject(_data),
        id,
      );

      if (response) {
        setAgendas({
          host: response.hostAgenda,
          participant: response.participantAgenda,
        });
      }
      return response;
    },
    [],
  );

  const handleAddTopic = useCallback(
    async (title: string, isPrivate: boolean, id: string) => {
      const body: any = {
        title,
        isPrivate,
        id: generateRandomDigits(24),
      };

      writeFirebaseData(`meeting/${id}/topics`, body);
      return;
    },
    [],
  );

  const [submitting, setSubmitting] = useState(false);

  const isMeetingNotes = searchParams.get('type') === 'meeting-notes';

  const {postHogCapture} = usePostHogHook();

  const markAsCompleted = useCallback(
    async (
      id: string,
      name: string,
      feedbackValues: {
        feedbackValue: string;
        impression: string;
        feedbackSharingType: string;
        values: string[];
      },
      userId: string,
    ) => {
      const request = new FeedbackRequestImpl();
      const controller = new FeedbackController(request);
      const {
        feedbackValue,
        impression,
        feedbackSharingType,
        values,
      } = feedbackValues;

      setSubmitting(true);

      const submitMeeting = async (feedbackId?: string) => {
        const request = new MeetingRequestImpl();
        const controller = new MeetingController(request);
        const _data = {
          status: 'completed',
          feedback: feedbackId,
        };

        const meetingResponse = await controller.patchMeeting(_data, id);
        beamMasteryTasks('complete_meeting', true);

        setSubmitting(false);

        postHogCapture('frontend - 1:1 meeting conclusion');

        return meetingResponse;
      };
      setSubmitting(false);

      if (feedbackValue && impression && feedbackSharingType) {
        const response = await controller.postFeedback(
          {
            feedback: feedbackValue,
            impression: impression,
            subjectType: '1:1',
            subjectName: `1:1 with ${name}`,
            subjectId: id,
            visibility: feedbackSharingType,
            values: values,
          },
          userId,
        );
        if (response) {
          postHogCapture('frontend - feedback submission');

          return submitMeeting(response.id);
        }
      }
      return submitMeeting();
    },
    [postHogCapture],
  );

  const {id} = useParams();
  const navigate = useNavigate();

  const isGoogleMeeting = searchParams.get('meetingType') === 'google';

  const syncGoogleMeeting = useCallback(async () => {
    if (!isGoogleMeeting || !id || !isSigned) return;

    try {
      const request = new MeetingRequestImpl();

      const controller = new MeetingController(request);

      const existingMeetings = await controller.getMeetings({
        calendarId: id,
      });

      if (existingMeetings?.results?.length > 0) {
        // If we found an existing meeting, update the URL to use its ID
        const beamMeetingId = existingMeetings.results[0].id;
        navigate(`/view-meetings/${beamMeetingId}`);

        return beamMeetingId;
      }

      // If no existing meeting found, get the calendar event details
      const calendarEvent = await getCalendarEventById(id, 'primary');
      if (!calendarEvent?.result) return;

      const event = calendarEvent.result;

      // Create a new meeting using calendar event details
      const startDateTime = event.start.dateTime || event.start.date;
      const endDateTime = event.end.dateTime || event.end.date;

      const attendees =
        event.attendees?.map((attendee: {email: string}) => attendee.email) ||
        [];

      const users: IAPIUserFormat[] | undefined = await get(
        `${authStore.auth.user.workspace.id}/users`,
      );

      // Find workspace users from attendees
      const participantIds = users
        ?.filter((user) => attendees.includes(user.email))
        .map((user) => user.id);

      const newMeeting = {
        title: event.summary || 'Untitled Meeting',

        startDate: dayjs(startDateTime).format(),
        time: dayjs(startDateTime).format('hh:mm A'),
        endDate: dayjs(endDateTime).format(),
        participant: participantIds,

        calendarId: id,
        connectedCalendar: [
          {
            isPrimary: true,
            calendarId: id,
            title: event.summary,
            startDate: dayjs(startDateTime).format(),
          },
        ],
      };

      const response = await controller.postMeeting(newMeeting);

      if (response?.id) {
        navigate(`/view-meetings/${response.id}`);
        return response.id;
      }

      return true;
    } catch (error) {
      navigate('/meetings');
      return false;
    }
  }, [isGoogleMeeting, id, isSigned, getCalendarEventById, navigate]);

  const submitMeetingNotes = useCallback(
    () =>
      markAsCompleted(
        id,
        '',
        {
          feedbackSharingType: '',
          values: [],
          impression: '',
          feedbackValue: '',
        },
        '',
      ),
    [id, markAsCompleted],
  );

  const [loadingFeedback, setLoadingFeedback] = useState(false);

  const getUserFeedBack = useCallback(async (id: string, user: string) => {
    try {
      const request = new FeedbackRequestImpl();
      const controller = new FeedbackController(request);

      setLoadingFeedback(true);
      const response = await controller.getFeedback(
        {subjectId: id, subjectType: '1:1'},
        user,
      );
      setLoadingFeedback(false);
      return response;
    } catch {}
  }, []);

  const {
    isPerformanceActive,
    isEngagementActive,
    isCheckinsEnabled,
    isFeedBackEnabled,
  } = useWorkspaceUseCase();

  const managerViewUserOptions = useMemo(() => {
    const options = [];

    if (isPerformanceActive) {
      options.unshift({
        value: 'objective',
        label: 'Goals',
      });
    }

    if (isEngagementActive && isCheckinsEnabled) {
      options.push({
        value: 'check-in',
        label: capitalize(checkinName()),
      });
    }
    if (isEngagementActive && isFeedBackEnabled) {
      options.push({
        value: 'feedback',
        label: 'Feedback',
      });
    }
    options.push({
      value: '1:1',
      label: '1:1s',
    });
    return options;
  }, [
    isPerformanceActive,
    isEngagementActive,
    isFeedBackEnabled,
    isCheckinsEnabled,
  ]);

  const notesPath = `meeting/${id}/notes`;

  const topicsPath = `meeting/${id}/topics`;

  const {data: notes, isLoading: loadingNotes} = useFirebaseFetch(notesPath);

  const {data: topics} = useFirebaseFetch(topicsPath);

  const handleEditTopic = async (key: string, body: any) => {
    updateFirebaseData(`meeting/${id}/topics/${key}`, body);
  };

  const handleDeleteTopic = async (key: string) => {
    removeFirebaseData(`meeting/${id}/topics/${key}`);
  };

  const computedTopics = useMemo((): {
    title: string;
    id: string;
    key: string;
    isPrivate: boolean;
  }[] => {
    if (topics) {
      const _topics: any = Object.entries(topics).map(([key, value]: any) => ({
        ...value,
        key: key,
      }));
      return _topics;
    }
    return [];
  }, [topics]);

  const computeTopicNotes = useMemo(() => {
    if (notes) {
      let _groupByNotes: any = {};

      Object.entries(notes).forEach(([key, note]: any) => {
        if (!note.topic) return;

        const groupNotesExists = _groupByNotes?.[note.topic]
          ? _groupByNotes[note.topic]
          : [];

        _groupByNotes[note.topic] = [
          ...groupNotesExists,
          {
            ...note,
            firebaseId: key,
            shareHostNote: note.shareNote,
          },
        ];
      });

      return _groupByNotes;
    }
  }, [notes]);

  const computedNotes = useMemo(() => {
    if (notes) {
      let hostNote;
      let participantNote;

      for (let value in notes) {
        const note = notes[value];
        if (note.topic) return;

        if (note.author === 'hostNote') {
          hostNote = {
            ...note,
            firebaseId: value,
            shareHostNote: note.shareNote,
          };
        }
        if (note.author === 'participantNote') {
          participantNote = {
            ...notes[value],
            firebaseId: value,
            shareParticipantNote: note.shareParticipantNote,
          };
        }
      }
      return {
        hostNote,
        participantNote,
      };
    }
  }, [notes]);

  const computedTeamNotes = useMemo(() => {
    if (notes) {
      let _notes: any[] = [];

      Object.entries(notes).forEach(([key, note]: any) => {
        if (note.topic) return;

        const groupNotesExists = _notes;

        if (!note.shareNote && note.updatedBy !== authStore.auth.user.id)
          return;

        _notes = [
          ...groupNotesExists,
          {
            ...note,
            firebaseId: key,
            shareHostNote: note.shareNote,
          },
        ];
      });

      return _notes;
    }
  }, [notes]);

  const handleUpdateNotes = useCallback(
    async (
      value: string,
      _id: string,
      field: string,
      shareNote?: boolean,
      prevData?: {
        createdBy: string;
        createdAt: string;
        firebaseId: string;
      },
      topic?: string,
    ) => {
      const firebaseId = prevData?.firebaseId;

      const body: any = {
        updatedBy: authStore.auth.user.id,
        createdBy: prevData?.createdBy || authStore.auth.user.id,
        createdAt: prevData?.createdAt || dayjs().format(),
        updatedAt: dayjs().format(),
        author: field,
        topic: topic || '',
        note: value,
        shareNote: shareNote || false,
      };

      if (!firebaseId) {
        writeFirebaseData(`meeting/${id}/notes`, body);
        return;
      }

      updateFirebaseData(`meeting/${id}/notes/${firebaseId}`, body);
    },
    [id],
  );

  const {tasks} = useActionItems({
    source: 'meeting',
    userId: '',
    itemId: '',
    orderBy: {
      value: 'meeting',
      key: 'source',
    },
  });

  const handleCopyPreviousActionItems = (
    nextSteps: {text: string; completed: boolean}[],
    id: string,
    currentSteps: {text: string; completed: boolean}[],
  ) => {
    // Create a Set of currentSteps texts for quick lookup
    const currentStepsSet = new Set(currentSteps.map((step) => step.text));

    // Filter nextSteps to include only those not present in currentSteps
    const filteredNextSteps = nextSteps.filter(
      (step) => !currentStepsSet.has(step.text),
    );

    const getPreviousData = tasks.filter((task) =>
      filteredNextSteps.some(
        (step) => step.text === task.text && step.completed === task.completed,
      ),
    );

    // Copy the filtered steps to Firebase
    getPreviousData
      .filter((step) => !step.completed)
      .forEach((step) => {
        writeFirebaseData('action_items', {
          ...step,
          source: 'meeting',
          sourceId: id,
        });
      });
  };

  const handleBringForwardActionItems = async (meta: any) => {
    handleUpdateMeeting(id, {
      meta: {...meta, bringForwardActionItems: true},
    } as any).then(() => {
      queryClient.setQueryData(['view-meeting', id], (oldData: any) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          meta: {...meta, bringForwardActionItems: true},
        };
      });
    });
  };

  const handleBringForwardAgenda = async (meetingCode: string) => {
    const request = new MeetingRequestImpl();

    const controller = new MeetingController(request);

    const meetings = await controller.getMeetings({
      meetingCode,
    });

    if (meetings) {
      const currentMeeting = meetings.results[0];

      const previousMeeting = meetings.results[1];

      const prevHostAgenda = previousMeeting.hostAgenda?.filter(
        (agenda: {completed: boolean}) => !agenda.completed,
      );

      const prevParticipantAgenda = previousMeeting.participantAgenda?.filter(
        (agenda: {completed: boolean}) => !agenda.completed,
      );

      const isHost = currentMeeting?.user?.id === authStore.auth.user.id;

      handleUpdateMeeting(
        id,
        isHost
          ? {
              hostAgenda: [...prevHostAgenda, ...currentMeeting.hostAgenda],
              meta: {...currentMeeting.meta, bringForwardAgenda: true},
            }
          : ({
              participantAgenda: [
                ...prevParticipantAgenda,
                ...currentMeeting.participantAgenda,
              ],
              meta: {...currentMeeting.meta, bringForwardActionItems: true},
            } as any),
      ).then(() => {
        queryClient.setQueryData(['view-meeting', id], (oldData: any) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            meta: {...currentMeeting.meta, bringForwardAgenda: true},
            hostAgenda: isHost
              ? [...prevHostAgenda, ...currentMeeting.hostAgenda]
              : oldData.hostAgenda,
            participantAgenda: !isHost
              ? [...prevParticipantAgenda, ...currentMeeting.participantAgenda]
              : oldData.participantAgenda,
          };
        });
      });
    }
  };

  const [isCreatingNewNote, setIsCreatingNewNote] = useState(false);

  const handleCreateNewNote = async (
    meeting: IMeetingResponse & {
      primaryMeeting?: {title: string; id: string; connectedCalendar: any[]};
      googleEventDetails?: GoogleCalendarEvent;
    },
  ) => {
    const request = new MeetingRequestImpl();
    const controller = new MeetingController(request);

    setIsCreatingNewNote(true);

    try {
      if (!meeting) return;

      const currentDate = dayjs();
      const title = `${meeting.primaryMeeting?.title || meeting.title}`;

      // Base meeting data
      const baseMeetingData = {
        title,
        participant: [],
        type: meeting.type,
        shareAgenda: meeting.shareAgenda,
        shareHostAgenda: meeting.shareHostAgenda,
        shareNote: meeting.shareNote,
        emailParticipant: meeting.emailParticipant || [],
        reminder: meeting.reminder || [],
      };

      // Check if it's a recurring meeting by looking at Google Calendar event data
      const isRecurringMeeting =
        meeting.googleEventDetails?.recurrence ||
        meeting.googleEventDetails?.recurringEventId;

      if (
        isRecurringMeeting &&
        meeting.connectedCalendar &&
        meeting.googleEventDetails
      ) {
        let eventDetails;
        if (meeting.googleEventDetails?.recurrence) {
          eventDetails = meeting.googleEventDetails;
        } else if (meeting.googleEventDetails?.recurringEventId) {
          try {
            const mainEvent = await getCalendarEventById(
              meeting.googleEventDetails?.recurringEventId,
              'primary',
            );

            if (mainEvent?.result) {
              eventDetails = mainEvent.result;
            }
          } catch (error) {}
        }

        // Get the next occurrence based on Google Calendar event data
        const nextMeetingDate = getNextMeetingDateFromGoogleEvent(
          eventDetails,
          currentDate,
        );

        const newMeeting = {
          ...baseMeetingData,
          startDate: nextMeetingDate.format(),
          endDate: nextMeetingDate
            .add(getDurationFromMeeting(meeting), 'minute')
            .format(),
          time: meeting.time,
          calendarId: meeting.calendarId,
        };

        const response = await controller.postMeeting(newMeeting);
        if (response?.id) {
          if (meeting.primaryMeeting?.id) {
            const connectedCalendar = [
              ...meeting.primaryMeeting.connectedCalendar.map((calendar) => ({
                ...calendar,
                _id: undefined,
                id: undefined,
              })),
              {
                calendarId: meeting.calendarId,
                isPrimary: false,
                startDate: nextMeetingDate.format(),
                title,
              },
            ];

            await controller.patchMeeting(
              {
                connectedCalendar,
              },
              meeting.primaryMeeting?.id,
            );

            await controller.patchMeeting(
              {
                connectedCalendar,
              },
              response.id,
            );
          }
          navigate(`/view-meetings/${response.id}`);
        }
      } else {
        // For non-recurring meetings
        const newMeeting = {
          ...baseMeetingData,
          startDate: currentDate.format(),
          endDate: currentDate.add(60, 'minute').format(),
          time: currentDate.format('hh:mm A'),
        };

        const response = await controller.postMeeting(newMeeting);

        if (response?.id) {
          if (meeting.connectedCalendar && meeting.primaryMeeting?.id) {
            const connectedCalendar = [
              ...meeting.primaryMeeting.connectedCalendar.map((calendar) => ({
                ...calendar,
                _id: undefined,
              })),
              {
                meetingId: response.id,
                isPrimary: false,
                startDate: currentDate.format(),
                title,
              },
            ];

            await controller.patchMeeting(
              {
                connectedCalendar,
              },
              meeting.primaryMeeting?.id,
            );

            const primaryMeeting = meeting.connectedCalendar.find(
              (meeting) => meeting.isPrimary,
            );

            if (primaryMeeting)
              await controller.patchMeeting(
                {
                  connectedCalendar: [
                    primaryMeeting,
                    {
                      meetingId: response.id,
                      isPrimary: false,
                      startDate: currentDate.format(),
                      title,
                    },
                  ].map((calendar) => ({...calendar, _id: undefined})),
                },
                response.id,
              );
          }

          navigate(`/view-meetings/${response.id}`);
        }
      }
    } catch (error) {
      console.error('Error creating new note meeting:', error);
    } finally {
      setIsCreatingNewNote(false);
    }
  };

  // Helper function to get next meeting date based on Google Calendar event data
  const getNextMeetingDateFromGoogleEvent = (
    event: GoogleCalendarEvent,
    currentDate: dayjs.Dayjs,
  ) => {
    const recurrence = event.recurrence?.[0];
    if (!recurrence) return currentDate;

    // Parse RRULE from Google Calendar event
    const freq = recurrence.match(/FREQ=([^;]*)/)?.[1];
    const interval = parseInt(recurrence.match(/INTERVAL=([^;]*)/)?.[1] || '1');

    // Get the event start time
    const eventStartTime = dayjs(event.start.dateTime || event.start.date);

    // Calculate the next occurrence based on the recurrence pattern
    switch (freq) {
      case 'WEEKLY': {
        const byDay = recurrence.match(/BYDAY=([^;]*)/)?.[1];
        if (byDay) {
          // Handle specific weekday recurrence
          const weekdays = byDay.split(',');
          const nextDate = findNextWeekday(currentDate, weekdays);
          return nextDate
            .hour(eventStartTime.hour())
            .minute(eventStartTime.minute());
        }
        return currentDate
          .add(interval, 'week')
          .hour(eventStartTime.hour())
          .minute(eventStartTime.minute());
      }
      case 'MONTHLY': {
        const byMonthDay = recurrence.match(/BYMONTHDAY=([^;]*)/)?.[1];
        if (byMonthDay) {
          // Handle specific day of month recurrence
          const monthDay = parseInt(byMonthDay);
          const nextDate = findNextMonthDay(currentDate, monthDay);
          return nextDate
            .hour(eventStartTime.hour())
            .minute(eventStartTime.minute());
        }
        return currentDate
          .add(interval, 'month')
          .hour(eventStartTime.hour())
          .minute(eventStartTime.minute());
      }
      case 'DAILY':
        return currentDate
          .add(interval, 'day')
          .hour(eventStartTime.hour())
          .minute(eventStartTime.minute());
      default:
        return currentDate;
    }
  };

  // Helper function to find next occurrence of specified weekdays
  const findNextWeekday = (currentDate: dayjs.Dayjs, weekdays: string[]) => {
    let date = currentDate.clone();
    const weekdayMap: Record<string, number> = {
      MO: 1,
      TU: 2,
      WE: 3,
      TH: 4,
      FR: 5,
      SA: 6,
      SU: 7,
    };

    while (true) {
      date = date.add(1, 'day');
      const currentWeekday = date.day() || 7; // Convert Sunday from 0 to 7
      if (weekdays.some((day) => weekdayMap[day] === currentWeekday)) {
        return date;
      }
    }
  };

  // Helper function to find next occurrence of specified day of month
  const findNextMonthDay = (currentDate: dayjs.Dayjs, monthDay: number) => {
    let date = currentDate.clone();

    // If we've passed this month's occurrence, move to next month
    if (date.date() > monthDay) {
      date = date.add(1, 'month').date(1);
    }

    return date.date(monthDay);
  };

  // Helper function to get meeting duration in minutes
  const getDurationFromMeeting = (meeting: IMeetingResponse) => {
    if (meeting.startDate && meeting.endDate) {
      return dayjs(meeting.endDate).diff(dayjs(meeting.startDate), 'minute');
    }
    return 60; // Default duration changed to 60 minutes
  };

  const handleToggleBeamAI = async (
    meetingLink: string,
    isChecked: boolean,
    updateCheck: (value: boolean) => void,
  ) => {
    const botValue = isChecked;

    try {
      if (!botValue) {
        // Turning on BeamAI
        const response = await handleUpdateMeeting(id, {
          addBeamAI: true,
          meetingLink,
        } as any);
        if (response) {
          updateCheck(true);
        }
      } else {
        // For Google Calendar events, just remove BeamAI
        const response = await handleUpdateMeeting(id, {
          addBeamAI: false,
          meetingLink,
        } as any);
        if (response) {
          updateCheck(false);
        }
      }
    } catch (error) {
      updateCheck(botValue);
    }
  };

  const deleteMeeting = useCallback(
    async (
      calendarId?: string,
      id?: string,
      primaryMeeting?: string,
    ): Promise<any> => {
      if (!isSigned) return;

      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      try {
        if (calendarId) await deleteEvent(calendarId, 'primary');

        if (id) await controller.deleteMeeting(id);

        if (primaryMeeting) {
          const response = await controller.getMeeting(primaryMeeting);

          if (response) {
            const updatedCalendar = response.connectedCalendar.filter(
              (calendar: {meetingId: string}) => calendar.meetingId !== id,
            );
            await controller.patchMeeting(
              {
                connectedCalendar: updatedCalendar.map((calendar: any) => ({
                  ...calendar,
                  _id: undefined,
                })),
              },
              primaryMeeting,
            );
          }
        }
        activateNotification({
          kind: 'success',
          content: 'Deleted successfully',
          title: 'Success',
        });

        // navigate to main meeting because only main meetings have a calendarId
        if (calendarId) {
          navigate('/meetings/');
          return;
        }

        if (primaryMeeting) {
          navigate(`/view-meetings/${primaryMeeting}`);
        } else {
          navigate('/meetings');
        }

        if (id) return {success: true};
      } catch (error) {
        activateNotification({
          kind: 'error',
          content: 'Error deleting event',
          title: 'Success',
        });
        throw error;
      }
    },
    [deleteEvent, isSigned, navigate],
  );

  return {
    fetchMeetingDetails,
    handleUpdateMeeting,
    markAsCompleted,
    handleUpdateNotes,
    loadingFeedback,
    submitMeetingNotes,
    controller,
    computeTopicNotes,
    isGoogleMeeting,
    computedTopics,
    isMeetingNotes,
    handleCopyPreviousActionItems,
    handleBringForwardActionItems,
    loadingNotes,
    agendas,
    id,
    syncGoogleMeeting,
    computedTeamNotes,
    managerViewUserOptions,
    isSigned,
    deleteMeeting,
    handleToggleBeamAI,
    handleEditTopic,
    handleShareMeeting,
    handleCreateNewNote,
    handleDeleteTopic,
    fetchMeetingByCalendarId,
    handleBringForwardAgenda,
    computedNotes,
    getEmail,
    getUserFeedBack,
    isCreatingNewNote,
    handleAddTopic,
    submitting,
  };
};
