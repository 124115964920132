import React, {FC, useCallback, useMemo} from 'react';
import {InsightCard} from '../../dashboard/insight-card';
import {Insight} from '../interface';
import {formatConfigDate} from '../insight-options';
import {useQuery} from 'react-query';
import {AnalyticsController} from '../../reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../reporting-pages/admin-analytics/admin-analytics.request';
import {Headline2} from '@ui/atoms/typography';
import {goalDueDate} from '@utils/date';

interface ObjectiveCompletionRateProps extends Insight {
  overallDateRange?: null | {startDate: string; endDate: string};
  showRangeType?: boolean;
}

const formatTextColor = (state: string, endDate?: string) => {
  if (endDate === 'Past due') return 'red300';

  switch (state) {
    case 'at_risk':
      return 'red300';
    case 'on_track':
      return 'green300';
    case 'behind':
      return 'orange300';
    default:
      return 'red300';
  }
};

export const ObjectiveCompletion: FC<ObjectiveCompletionRateProps> = ({
  overallDateRange,
  ...rest
}) => {
  const config = rest.config;

  const formatDate = useMemo(
    () =>
      overallDateRange ? overallDateRange : formatConfigDate(config) || {},
    [config, overallDateRange],
  );

  const fetchSpecifiedGoals = useCallback(
    async (filters: any, view?: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);

      const specifiedGoalsResponse = await controller.fetchSpecifiedGoals(
        filters,
        view,
      );

      return specifiedGoalsResponse;
    },
    [],
  );

  const {data, isLoading} = useQuery(['objective-completion', config], () =>
    fetchSpecifiedGoals({
      startDate: formatDate.startDate,
      endDate: formatDate.endDate,
      newReport: true,
      goalType: config.objectiveType,
      goalIds: config.objectives?.join(','),
      isKpi: false,
      department: config.groups?.join(','),
    }),
  );

  const selectedObjectives =
    config.objectives?.filter((value) => Boolean(value)) || [];

  const selectedGoal = data?.goals?.[0];

  return (
    <InsightCard
      isLoading={isLoading}
      showFlashIcon={Boolean(overallDateRange)}
      {...rest}
      value={
        <Headline2
          kind={
            selectedObjectives.length === 1
              ? formatTextColor(
                  selectedGoal?.performance,
                  goalDueDate(selectedGoal?.endDate || ''),
                )
              : 'textDark'
          }>
          {data?.summary?.goals?.fulfillment || 0}%
        </Headline2>
      }
      showPercentProgress={false}
    />
  );
};
