import ReactSelect, {
  components,
  OptionsType,
  ValueType,
  ActionMeta,
  Props,
  IndicatorProps,
  OptionProps,
  PlaceholderProps,
  ControlProps,
} from 'react-select';
import {MagnifyingGlassIcon} from '../../../atoms/icons/magnifying-glass';
import {IndicatorWrapper, getStyles, ControlWrapper} from './styled';
import {HorizontalSpacer} from '../../../atoms/spacer';
import {Body2} from '../../../atoms/typography';
import {GoalTypeTag} from '../user-select-3/styled';
import {FlexRow} from '@ui/style/styles';
import {capitalizeGoalType} from '@utils';
import {Avatar} from '@ui/atoms/avatar';
import {TLabel} from '../user-select-3/user-select-3';
import {VerticalSpacer} from '../../../atoms/spacer';
import {forwardRef, Ref, useMemo, useState} from 'react';
import {Checkbox} from '../../../atoms/checkbox';

interface IOption {
  label: {
    goalName: string;
    goalType: string;
    assigneeAvatar: {name: string};
  };
}

const filterOptionsSelectOptions = (
  option: {data: IOption},
  rawInput: string,
) => {
  if (rawInput) {
    const label = option.data.label.goalType.toLowerCase();
    const assigneeLabel = option.data.label.assigneeAvatar?.name.toLowerCase();
    const goalsNameLabel = option.data.label.goalName?.toLowerCase();

    return rawInput
      .toLowerCase()
      .split(' ')
      .every(
        (word) =>
          label.includes(word) ||
          assigneeLabel.includes(word) ||
          goalsNameLabel.includes(word),
      );
  }
  return true;
};

const {
  DropdownIndicator: SelectDropdownIndicator,
  Option: SelectOption,
  Placeholder: SelectPlaceholder,
  Control: SelectControl,
} = components;

export interface TOption {
  value: string;
  label: string;
}
export interface SelectFieldProps extends Props<any> {
  leadingNote?: string;
  options: OptionsType<any>;
  onChange?: (value: ValueType<any, false>, action: ActionMeta<any>) => void;
  loading?: boolean;
}

const DropdownIndicator = (props: IndicatorProps<TOption, false>) => {
  return (
    <SelectDropdownIndicator {...props}>
      <IndicatorWrapper>
        <MagnifyingGlassIcon />
      </IndicatorWrapper>
    </SelectDropdownIndicator>
  );
};

const Placeholder = (props: PlaceholderProps<TOption, false>) => {
  return (
    <SelectPlaceholder {...props}>
      <Body2 kind={props.isDisabled ? 'textMuted' : 'textBody'}>
        {props.children}
      </Body2>
    </SelectPlaceholder>
  );
};
const Control = (props: ControlProps<TOption, false>) => {
  return (
    <ControlWrapper>
      <SelectControl {...props} />
    </ControlWrapper>
  );
};

const Option = (props: OptionProps<TOption, false>) => {
  return (
    <SelectOption {...props}>
      <FlexRow>
        <Checkbox checked={props.isSelected} />
        <HorizontalSpacer size="16px" />
        <div style={{width: '100%'}}>
          <GoalTypeTag>
            {props?.label &&
              capitalizeGoalType(((props.label as unknown) as TLabel).goalType)}
          </GoalTypeTag>
          <VerticalSpacer size="8px" />
          <Body2 kind="textDark" weight="semibold">
            {props?.label && ((props.label as unknown) as TLabel).goalName}
          </Body2>
          <VerticalSpacer size="8px" />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <FlexRow>
              <Avatar
                src={
                  props?.label
                    ? ((props.label as unknown) as TLabel).assigneeAvatar?.src
                    : ''
                }
                name={
                  props?.label
                    ? ((props.label as unknown) as TLabel).assigneeAvatar?.name
                    : ''
                }
                userId={
                  props?.label
                    ? ((props.label as unknown) as any).assigneeAvatar?.id
                    : ''
                }
                size="sm"
                tooltip={props?.label ? true : false}
              />

              <HorizontalSpacer size="8px" />
              <Body2>
                {props?.label &&
                  ((props.label as unknown) as TLabel).assigneeName}
              </Body2>
            </FlexRow>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span style={{fontSize: '14px', color: '#5f5f8c'}}>
                Created:&nbsp;
              </span>
              <Body2>
                {props?.label && ((props.label as unknown) as TLabel).date}
              </Body2>
            </div>
          </div>
        </div>
      </FlexRow>
    </SelectOption>
  );
};

export const GoalsMultiSelect = forwardRef(
  (
    {
      options,
      onChange,
      value,
      noOptionsMessage,
      searchPlaceholder,
      ...rest
    }: any,
    ref: Ref<ReactSelect<TOption>>,
  ) => {
    const styles = useMemo(() => getStyles<TOption, false>(), []);

    const [searchValue, setSearchValue] = useState('');

    const actions = ['set-value', 'menu-close', 'input-blur'];

    return (
      <ReactSelect
        {...rest}
        isMulti
        isClearable={false}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuIsOpen
        filterOption={filterOptionsSelectOptions}
        isSearchable
        value={value}
        inputValue={searchValue}
        onInputChange={(value: string, action) => {
          if (!actions.includes(action.action)) {
            setSearchValue(value);
          }
        }}
        noOptionsMessage={() => noOptionsMessage || `No KPI found`}
        tabSelectsValue={false}
        placeholder={searchPlaceholder || 'Search group or member'}
        onChange={onChange}
        ref={ref}
        maxMenuHeight={240}
        components={{
          DropdownIndicator,
          Option,
          Placeholder,
          Control,
          IndicatorSeparator: null,
        }}
        options={options}
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          spacing: {
            baseUnit: 6,
            controlHeight: 40,
            menuGutter: 6,
          },
        })}
      />
    );
  },
);
