import {useStoreContext} from '@store/store-context';
import {Body1, Body2} from '@ui/atoms/typography';
import {getGroupTypeAndGroup} from '@utils/get-group-grouptype';
import React, {memo, useCallback, useMemo, useState} from 'react';
import {GoalTypeOptions} from '../../filter-components/objectives';
import {CustomFilterMultiSelect} from '../../reporting-filter/custom-multi-select';
import {capitalize} from '@utils';
import {cfv} from '@utils/framework';
import {CustomDropDown} from '../edit-insight/edit-insight-filter';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {UserSelect3} from '@ui/molecules/select/user-select-3';
import {CancelIcon, MagnifyingGlassIcon} from '@ui/atoms/icons';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import styled, {css} from 'styled-components';
import {getColorFromTheme} from '@ui/ui-utils';
import {ifProp} from 'styled-tools';
import {
  transformKeyResults,
  transformOptions,
} from '../../reporting-pages/reporting-overview/components/kpi-board';
import {useQuery} from 'react-query';
import {AnalyticsRequestImpl} from '../../reporting-pages/admin-analytics/admin-analytics.request';
import {AnalyticsController} from '../../reporting-pages/admin-analytics/admin-analytics.controller';
import {Tooltip} from '@ui/molecules/tooltip';
import {FlexRowCenter} from '@ui/style/styles';
import {ItemLoader} from '@ui/organisms/item-loader';
import {Button} from '@ui/atoms/button';

interface ObjectiveFilterProps {
  startDate: string;
  isKeyResults?: boolean;
  endDate: string;
  handleChange: (
    field: 'objectiveType' | 'groups' | 'goal' | 'key_result',
    value: any,
  ) => void;
  objectiveType?: string;
  canAddObjective?: boolean;
  isDisabled?: boolean;
  keyResult?: string;
  objectives?: string[];
  groups?: string[];
}

export const ObjectiveFilter = memo(
  ({
    startDate,
    endDate,
    objectiveType = '',
    canAddObjective,
    keyResult = '',
    isDisabled,
    handleChange,
    isKeyResults,
    objectives = [],
    groups = [],
  }: ObjectiveFilterProps) => {
    const [selectedType, setSelectedType] = useState(objectiveType);

    const {
      groupStore: {groups: allGroups},
      groupTypeStore: {groupType},
    } = useStoreContext();

    const [selectedGroups, setSelectedGroups] = useState<
      {value: string; label: string}[]
    >(
      allGroups
        .filter((group) => groups.includes(group.id))
        .map((group) => ({value: group.id, label: group.name})),
    );

    const fetchGoals = useCallback(
      async (filters: any, view?: string) => {
        const request = new AnalyticsRequestImpl();
        const controller = new AnalyticsController(request);

        if (isKeyResults) {
          const key_results = await controller.fetchSpecifiedKpis(
            filters,
            true,
          );

          if (key_results) {
            return key_results.map((keyResult: any) =>
              transformKeyResults(keyResult),
            );
          }
          return [];
        }

        const response: {goals: any[]} = await controller.fetchSpecifiedGoals(
          filters,
          view,
        );

        const formatGoal = {
          ...response,
        };

        return formatGoal.goals.map((goal) =>
          transformOptions(goal, allGroups, allGroups),
        );
      },
      [allGroups, isKeyResults],
    );

    const {data = [], isLoading} = useQuery(
      ['goals', selectedType, selectedGroups, isKeyResults, startDate, endDate],
      () =>
        fetchGoals({
          startDate,
          endDate,
          goalType: selectedType,
          department: selectedGroups.map((group) => group.value).join(','),
        }),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    );

    const computeSelectedGroups = useMemo(() => {
      return selectedGroups.map((group) => ({
        ...group,
        ...getGroupTypeAndGroup(allGroups, groupType, group.value),
      }));
    }, [selectedGroups, groupType, allGroups]);

    const computeGroups = useMemo(() => {
      return groupType.map((grpType) => ({
        name: grpType.name,
        data: allGroups
          .filter((group) => group.groupType === grpType.id)
          .map((group) => ({
            label: group.name,
            value: group.id,
          })),
      }));
    }, [allGroups, groupType]);

    return (
      <>
        {!isKeyResults && (
          <>
            <div className="border-t border-t-borderLight mt-4 pt-5">
              <Body2 weight="bold">{capitalize(cfv().g_oal)} type</Body2>

              <VerticalSpacer size="16px" />

              <CustomDropDown
                options={[{label: 'All', value: ''}, ...GoalTypeOptions]}
                isDisabled={isDisabled}
                onChange={(value) => {
                  setSelectedType(value);

                  handleChange('objectiveType', value);
                }}
                value={selectedType}
              />
            </div>
          </>
        )}

        {selectedType.toLowerCase() === 'group' && (
          <div className="my-3">
            <CustomFilterMultiSelect
              selectedOptionStyle={{background: 'white'}}
              options={computeGroups}
              disabled={isDisabled}
              onChange={(data: {value: string; label: string}[]) => {
                handleChange(
                  'groups',
                  data.map((group) => group.value),
                );

                setSelectedGroups(data);
              }}
              placeholderTerm="group"
              type="group"
              selectedOptions={computeSelectedGroups}
            />
          </div>
        )}

        {canAddObjective && (
          <div className="border-t border-t-borderLight mt-4 pt-5">
            <Body2 weight="bold">{capitalize(cfv().g_oal)}</Body2>
            <VerticalSpacer size="16px" />

            <div>
              <GoalMultiSelect
                defaultValue={objectives}
                isLoading={isLoading}
                disabled={isDisabled}
                onChange={(value) => handleChange('goal', value)}
                goalOptions={[
                  {label: `All ${cfv().g_oals}`, value: ''},
                  ...data,
                ]}
                label={`Search ${cfv().g_oals}`}
              />
            </div>
          </div>
        )}

        {isKeyResults && (
          <>
            <div className="border-t border-t-borderLight mt-4 pt-5">
              <Body2 weight="bold">{capitalize(cfv().k_r)}</Body2>
              <VerticalSpacer size="16px" />

              <div>
                <GoalMultiSelect
                  disabled={isDisabled}
                  isKeyResult
                  defaultValue={[keyResult]}
                  onChange={(value) => {
                    handleChange('key_result', value || '');
                  }}
                  goalOptions={[...data]}
                  label={`Search ${cfv().k_r}`}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  },
);

interface GoalMultiSelectProps {
  goalOptions: any[];
  onChange: (option: string[] | string) => void;
  label: string;
  isLoading?: boolean;
  isKeyResult?: boolean;
  disabled?: boolean;
  defaultValue: string[];
}
export const GoalMultiSelect = memo(
  ({
    goalOptions,
    label,
    defaultValue,
    isLoading,
    disabled,
    onChange,
    isKeyResult,
  }: GoalMultiSelectProps) => {
    const selectedOptions = goalOptions.filter((goal) =>
      defaultValue.includes(goal.value),
    );

    const selectedOption = selectedOptions[0];

    const handleChange = (goalId: string) => {
      if (goalId === '') {
        onChange(isKeyResult ? goalId : ['']);
        return;
      }

      const updatedOption = defaultValue.includes(goalId)
        ? defaultValue.filter((goal) => goal !== goalId)
        : [...defaultValue, goalId].filter((goal) => goal !== '');

      onChange(isKeyResult ? goalId : updatedOption);
    };

    return (
      <>
        <DropdownCustom
          collapseOnClick
          customComponent={(
            handleOpen: (event: any) => void,
            open: boolean,
          ) => (
            <Button
              onClick={handleOpen}
              disabled={disabled}
              kind="secondary"
              style={{padding: '12px 16px', fontWeight: 400}}
              className="rounded-[10px]  min-h-[46px] border bg-white w-full border-borderDark py-3 px-4 flex items-center justify-between">
              {isKeyResult ? (
                selectedOption?.value ? (
                  <Tooltip
                    BodyTextNodeType={Body1}
                    text={
                      selectedOption.label?.goalName || selectedOption.label
                    }
                    tooltipBody={
                      selectedOption.label?.goalName || selectedOption.label
                    }
                    withEllipsis
                    maxLength={20}
                  />
                ) : (
                  <Body2 kind="textBody">{label}</Body2>
                )
              ) : (
                <Body2 kind="textBody">{label}</Body2>
              )}

              <IconWrapper>
                {isKeyResult && selectedOption?.value ? (
                  <button onClick={() => handleChange('')}>
                    <CancelIcon />
                  </button>
                ) : (
                  <MagnifyingGlassIcon />
                )}
              </IconWrapper>
            </Button>
          )}
          menu={(handleClose: () => void) => (
            <div>
              <div className=" bg-white  border border-borderLight z-10 rounded-lg">
                {isLoading ? (
                  <FlexRowCenter className="py-4">
                    <ItemLoader />
                  </FlexRowCenter>
                ) : (
                  <UserSelect3
                    onChange={(value: any) => {
                      handleClose();
                      handleChange(value.value);
                    }}
                    useAllOptions
                    placeholder={`Search ${
                      isKeyResult ? cfv().k_r : cfv().g_oal
                    }`}
                    options={goalOptions}
                  />
                )}
              </div>
            </div>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            boxShadow: '0px',
            right: 0,
            padding: 0,
            overflowY: 'auto',
            width: '100%',
            background: 'none',
            border: 0,
          }}
        />
        {!isKeyResult &&
          selectedOptions.map((option) => (
            <div
              key={option.value}
              className={`py-1 px-2 ${
                option.groupType ? 'w-full' : 'w-fit'
              } bg-white flex flex-row items-center justify-between rounded-[6px] mt-4 mr-3 `}>
              <Body2>{option.label?.goalName || option.label}</Body2>

              <button
                className="cursor-pointer"
                onClick={() => {
                  handleChange(option.value);
                }}>
                <CancelIcon
                  style={{
                    width: '12px',
                    height: '12px',
                    strokeWidth: 1.5,
                    stroke: '#BFBFD4',
                    marginLeft: '15px',
                  }}
                />
              </button>
            </div>
          ))}
      </>
    );
  },
);

export const IconWrapper = styled.span<{disabled?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    width: 20px;
    height: 20px;
    ${ifProp(
      'disabled',
      css`
        stroke: #bfbfd4;
      `,
    )};
  }
`;
