import {CSSProperties, ComponentPropsWithRef, ComponentType, memo} from 'react';
import {ImageVariant} from '../../interface';

export interface TImageProps extends ComponentPropsWithRef<'img'> {
  src?: string;
  extension?: string;
  url?: string;
  pictureStyle?: CSSProperties;
  variants?: ImageVariant[];
  sizes?: string;
  component?: ComponentType<any>;
}

const getVariantSrc = (variant: ImageVariant) =>
  `${variant.url || variant.src} ${variant.size}`;

export const Image = memo(
  ({
    url,
    sizes,
    variants,
    pictureStyle,
    alt,
    src,
    component: ImageComponent,
    ...rest
  }: TImageProps) => {
    const vars = variants || [];

    const hasVariants = vars.length > 0;

    const std = vars.filter((v) => v.url || v.src);

    const srcSet = std.map(getVariantSrc).join(', ');

    let originalFileExtension = 'jpeg';
    if (std.length > 0 && url) {
      const matches = std[0].url?.match(/\.([^.]+)$/);
      // Access the group using index 1 instead of a name

      const {groups} = matches as RegExpMatchArray;

      originalFileExtension = groups?.name || 'jpeg';
      // Provide correct mime type for jpg
      if (originalFileExtension === 'jpg') {
        originalFileExtension = 'jpeg';
      }
    }

    // Ensure fallback src for older browsers
    const source =
      url || src || (hasVariants ? std[0]?.url || std[0]?.src : undefined);

    return (
      <picture style={pictureStyle}>
        {srcSet.length > 0 && (
          <source
            srcSet={srcSet}
            type={`image/${originalFileExtension}`}
            sizes={sizes}
          />
        )}
        {ImageComponent ? (
          <ImageComponent src={source} sizes={sizes} {...rest} alt={alt} />
        ) : (
          <img src={source} sizes={sizes} {...rest} alt={alt} />
        )}
      </picture>
    );
  },
);

Image.displayName = 'Image';
