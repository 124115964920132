import {CustomColorIcon} from '@ui/atoms/color-icons';
import {RoundedPieChart} from '@ui/atoms/pie-chart/pie';
import {Body1, Body2} from '@ui/atoms/typography';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Card, Value} from './styles';
import {useMemo} from 'react';

interface OverviewProps {
  showGraph?: boolean;
  isBoards?: boolean;
  excludeCompletionRate?: boolean;
  pieTitle?: string;
  extraPieSection?: {
    name: string;
    color: string;
    value: number;
    title: string;
    percent: string;
    key: string;
  }[];
  summary: {
    assigned: number;
    overdue: number;
    completed: number;
    undated?: number;
    active: number;
    completionRate: number;
    total: number | string;
  };
}

export const AnalyticsActionItemsOverviewPage = ({
  summary,
  pieTitle,
  isBoards,
  extraPieSection = [],
  excludeCompletionRate,
}: OverviewProps) => {
  const cardSummary = useMemo(() => {
    const options = [
      {
        title: 'Completion rate',
        key: 'completionRate',
        type: 'percent',
        color: 'transparent',
      },
    ];

    options.push({
      title: isBoards ? 'Open' : `Assigned items`,
      key: isBoards ? 'active' : 'assigned',
      type: '',
      color: 'transparent',
    });

    if (isBoards) {
      options.push({
        title: 'Past due',
        key: 'overdue',
        type: '',
        color: 'transparent',
      });
    }

    return options;
  }, [isBoards]);

  const chartSummary = [
    {
      title: 'Open',
      percent: 'activePercent',
      key: 'active',
      color: '#E4E5FB',
    },
    {
      title: `Completed`,
      percent: 'completedPercent',
      key: 'completed',
      color: '#585ADF',
    },
    {
      title: `Overdue`,
      percent: 'overduePercent',
      key: 'overdue',
      color: '#28284D',
    },
    ...extraPieSection,
  ];
  const hasValue =
    summary.active +
    summary.completed +
    summary.overdue +
    (summary?.undated || 0);

  return (
    <div className="heading w-full">
      <div className=" flex flex-col lg:flex-row lg:items-center items-start  rounded-lg justify-between gap-3">
        {isBoards ? null : (
          <div
            className={`${
              excludeCompletionRate ? 'w-full' : 'lg:w-[50%] '
            } w-full flex sm:flex-col flex-row gap-3 items-center`}>
            <div className="w-full">
              <RoundedPieChart
                // style={{height: '146px', width: '146px'}}
                // className="StatusPie"
                chartStyle={{
                  width: 210,
                  height: 210,
                  margin: {top: 20, left: 20},
                }}
                // pieStyle={{width: '120px'}}
                pieTitle={pieTitle}
                totalValue={summary.total}
                data={
                  hasValue
                    ? [
                        {
                          name: 'open',
                          value: summary.active,
                          color: '#E4E5FB',
                        },
                        {
                          name: 'Completed',
                          value: summary?.completed,
                          color: '#585ADF',
                        },
                        {
                          name: 'Overdue',
                          value: summary.overdue,
                          color: '#28284D',
                        },
                        ...extraPieSection,
                      ]
                    : [
                        {
                          name: 'empty',
                          value: 100,
                          color: '#bfbfd4',
                        },
                      ]
                }
              />
            </div>

            <div className="w-full">
              {chartSummary.map((_summary) => (
                <FlexRowSpaceBetween
                  key={_summary.key}
                  className="border-b border-b-[#EDEDF2] pb-4 pt-3 w-full">
                  <FlexRow className="gap-2">
                    <CustomColorIcon
                      color={_summary.color}
                      width="8px"
                      height="8px"
                      margin={0}
                    />
                    <Body2>{_summary.title}</Body2>
                  </FlexRow>
                  <Body2>
                    {(summary as any)[_summary.key]}{' '}
                    <span className="text-[12px] text-[#5F5F8C]">
                      (
                      {typeof (summary as any)[_summary.percent] === 'object'
                        ? (summary as any)[_summary.percent]?.current
                        : (summary as any)[_summary.percent]}
                      %)
                    </span>
                  </Body2>
                </FlexRowSpaceBetween>
              ))}
            </div>
          </div>
        )}

        {!excludeCompletionRate && (
          <div
            className={`w-full flex ${
              isBoards
                ? 'flex-row  gap-3 justify-between lg:w-full'
                : 'flex-col lg:w-[50%] lg:max-w-[300px]'
            }  max-w-full`}>
            {cardSummary.map((sum) => (
              <Card
                bg={sum.color}
                className={`mb-2 ${isBoards ? 'w-[33%]' : ' min-w-[50%] '} `}>
                <div className="card-wrapper">
                  <Body1 weight="semibold">{sum.title}</Body1>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Value bg={sum.color}>
                      <Body1 weight="semibold" kind={'textDark'}>
                        {(summary as any)[sum.key]}
                        {sum.type === 'percent' && '%'}
                      </Body1>
                    </Value>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
