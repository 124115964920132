import {ItemLoader} from '@ui/organisms/item-loader';
import {FlexRowCenter, FlexRow} from '@ui/style/styles';
import {useQuery} from 'react-query';
import {userName} from '@utils/user-name';
import {Body1, Headline3} from '@ui/atoms/typography';
import {PlainButton} from '@ui/atoms/plain-button/plain-button';
import {useMemo, useState} from 'react';
import {ViewUser} from './components/view-user';
import {Avatar} from '@ui/atoms/avatar';
import {useSearchParams} from 'react-router-dom';
import {Notes} from './components/notes';
import {FeedbackItem} from '@pages/feedback/feedback-item';
import {MeetingAgenda} from './components/agenda';
import {RescheduleMeeting} from '../reschedule-meeting/reschedule-meeting';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body2} from '@ui/atoms/typography';
import {Feedback} from './components/feedback';
import {useFeedbackHook} from './components/feedback-hook';
import dayjs from 'dayjs';
import {useViewMeetingHook} from './view-meeting-hook';
import Timezone from 'dayjs/plugin/timezone';
import {authStore} from '@store/stores/auth-store';
import {SectionCard} from '@ui/layouts/section-card';
import {useBreakpoints} from '@utils/use-breakpoints';
import {useStoreContext} from '@store/store-context';
import {observer} from 'mobx-react';
import {
  CancelIcon,
  MagicPenIcon,
  MoreVerticalIcon,
  PlusIcon,
} from '@ui/atoms/icons';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {useDebouncedCallback} from 'use-debounce';
import {AdditionalOptionsWrapper, ModalWrapper} from './styles';
import {ThickPlusIcon} from '@ui/atoms/icons/thick-plus';
import {
  CreateNoteTopic,
  DeleteNote,
  DeleteTopic,
  EditTopic,
  LinkToMeeting,
  ShareMeeting,
} from './modals';
import {NextSteps} from './components/next-steps';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {MeetingHeader} from './components/meeting-header';
import {MeetingController} from '../meeting-controller';
import {MeetingRequestImpl} from '../meeting.request';
import {Modal} from '@ui/molecules/modal';
import AiSummaryPage from './ai-summary';
import {ModalCard} from '@ui/layouts/modal-card';
import {BackAction} from '@ui/molecules/back-action';

dayjs.extend(Timezone);

interface ViewMeetingsProps {
  handleSeriesSettings?: () => void;
  connectedGoogleEmail: string;
}
export const ViewMeetings = observer(
  ({handleSeriesSettings, connectedGoogleEmail}: ViewMeetingsProps) => {
    const {
      fetchMeetingDetails,
      handleUpdateMeeting,
      markAsCompleted,
      handleAddTopic,
      computeTopicNotes,
      computedTeamNotes,
      deleteMeeting,
      managerViewUserOptions,
      computedTopics,
      handleDeleteTopic,
      agendas,
      handleEditTopic,
      id,
      handleShareMeeting,
      handleBringForwardActionItems,
      computedNotes,
      handleBringForwardAgenda,
      handleToggleBeamAI,
      loadingNotes,
      isGoogleMeeting,
      submitting,
      handleUpdateNotes,
      getUserFeedBack,
    } = useViewMeetingHook();

    const [searchParams] = useSearchParams();

    const request = new MeetingRequestImpl();

    const controller = new MeetingController(request);

    const {
      feedbackValues,
      handleFeedbackFormValueChange,
      feedbackError,
    } = useFeedbackHook();

    const [leaveFeedback, setLeaveFeedback] = useState(false);
    const [showAiSummary, setShowAiSummary] = useState(false);
    const [showLinkMeeting, setShowLinkMeeting] = useState(false);

    const [showDeleteMeeting, setShowDeleteMeeting] = useState(false);

    const startMeeting = searchParams.get('start');

    const {
      usersStore: {users},
    } = useStoreContext();

    const queryKey = useMemo(() => ['view-meeting', id], [id]);

    const queryFn = useMemo(
      () => () => fetchMeetingDetails(id, !!startMeeting),
      [fetchMeetingDetails, id, startMeeting],
    );

    const {data, isLoading, refetch} = useQuery(queryKey, queryFn, {
      enabled: !isGoogleMeeting,
      refetchOnWindowFocus: false,
    });

    const isManagerView = searchParams.get('manager');

    const [openRescheduleMeeting, setOpenRescheduleMeeting] = useState(false);

    const getStatus = (status: string) => {
      return data && data.status === status;
    };

    const beamAiIsEnabled = data && !!data.meta?.botId;

    useQuery(
      ['view-meeting-bot-response', id],
      () => controller.getMeetingBotResponse(id),
      {
        enabled: beamAiIsEnabled,
      },
    );

    const {data: botData} = useQuery(
      ['view-meeting-bot-data', id],
      () => controller.getMeetingBotData(id),
      {
        enabled: beamAiIsEnabled,
      },
    );

    const summaryInProgress =
      botData && botData.status_changes
        ? botData.status_changes[botData.status_changes.length - 1]?.code ===
          'in_call_recording'
        : false;

    const isMeetingNote = searchParams.get('type') === 'meeting-notes';

    const isMeetingUpcoming = getStatus('upcoming') || isMeetingNote;

    const isMeetingCanceled = getStatus('cancelled');

    const isMeetingActive = getStatus('active');

    const isMeetingMissed = getStatus('missed');

    const isMeetingCompleted = getStatus('completed') && !isMeetingNote;

    const {data: feedback = [], isLoading: loadingFeedback} = useQuery(
      ['view-userFeedback', id],
      () => getUserFeedBack(id, data ? data?.user?.id : ''),
      {
        enabled: data && !!data?.feedback && !!data?.user.id,
      },
    );

    const [showAddTopic, setShowAddTopic] = useState(false);

    const [editTopic, setEditTopic] = useState(false);
    const [shareMeeting, setShareMeeting] = useState(false);

    const [deleteTopic, setDeleteTopic] = useState(false);

    const [selectedTopic, setSelectedTopic] = useState<{
      key: string;
      title: string;
    } | null>(null);

    const feedbackData = feedback[0];

    const [showViewUser, setShowViewUser] = useState<
      'objective' | 'check-in' | 'feedback' | '' | '1:1'
    >('');

    const {isXs} = useBreakpoints();

    const updateNotes = useDebouncedCallback(
      (value, id, field, shareNote, firebaseId, topic?: string) => {
        handleUpdateNotes(value, id, field, shareNote, firebaseId, topic);
      },
      1500,
    );

    if (isLoading) {
      return (
        <FlexRowCenter style={{width: '100%', height: '80vh'}}>
          <ItemLoader />
        </FlexRowCenter>
      );
    }

    if (!data) {
      return null;
    }

    const computeParticipants = data.participant;

    const participants = [...computeParticipants, data.user].map(
      (participant) => ({
        name: userName(participant),
        src: participant.avatar?.url || '',
        id: participant.id,
      }),
    );

    const canViewSections =
      isMeetingActive ||
      isMeetingCompleted ||
      isMeetingUpcoming ||
      isMeetingMissed;

    const isTeamsMeeting = data.type !== 'team';

    const isSingleParticipant = users.find((user) => {
      const participant =
        typeof data.participant[0] === 'string'
          ? data.participant[0]
          : data.participant[0]?.id;

      return user.id === participant;
    });

    const reviewee: any = data
      ? participants.includes(authStore.auth.user.id)
        ? data.user
        : isSingleParticipant
      : null;

    const lastUpdatedBy = users.find((user) => user.id === data?.updatedBy);

    const filterComputedTopics =
      computedTopics &&
      computedTopics.filter((value) =>
        value.isPrivate ? data.user.id === authStore.auth.user.id : true,
      );

    const userIsHost = data && data.user?.id === authStore.auth.user.id;

    const patchData = (field: string, value: any) => {
      return {
        participant: data.participant,
        shareHostAgenda: data.shareHostAgenda,
        shareParticipantAgenda: data.shareParticipantAgenda,
        [field]: value,
        startDate: data.startDate,
        frequency: data.frequency,
        time: data.time,
        reminder: data.reminder,
      };
    };

    return (
      <div>
        <div>
          <MeetingHeader
            isManagerView={!!isManagerView}
            data={data}
            participants={participants}
            handleLinkMeeting={() => setShowLinkMeeting(true)}
            allParticipants={computeParticipants}
            handleSeriesSettings={handleSeriesSettings}
            isMeetingActive={isMeetingActive}
            handleDeleteNote={() => {
              setShowDeleteMeeting(!showDeleteMeeting);
            }}
            handleAddBeamAi={handleToggleBeamAI}
            connectedGoogleEmail={connectedGoogleEmail}
            handleShareMeeting={() => {
              setShareMeeting(true);
            }}
            refetch={refetch}
            feedbackValues={feedbackValues}
            markAsCompleted={markAsCompleted}
            isMeetingNote={isMeetingNote}
            isMeetingCanceled={isMeetingCanceled}
            handleBringForward={(type) => {
              if (type === 'agenda') {
                handleBringForwardAgenda(data.meetingCode);
                return;
              }
              handleBringForwardActionItems(data.meta);
            }}
            id={id}
            setOpenRescheduleMeeting={setOpenRescheduleMeeting}
            managerViewUserOptions={managerViewUserOptions}
            reviewee={reviewee}
            setShowViewUser={setShowViewUser}
            isSingleParticipant={isSingleParticipant}
            isMeetingMissed={isMeetingMissed}
            submitting={submitting}
            isMeetingUpcoming={isMeetingUpcoming}
          />

          {beamAiIsEnabled && (
            <div className="bg-backgroundLight mt-6 px-4 py-[18px] rounded-[10px] flex items-center justify-between">
              <div>
                <Body1 weight="bold">
                  {summaryInProgress
                    ? 'Meeting recap in progress'
                    : 'Meeting recap'}
                </Body1>

                <Body2 kind="textBody">
                  {summaryInProgress
                    ? 'Your AI-powered meeting is being analysed by BeamAI to create a smart summary. Stay tuned!'
                    : 'Your Beam AI meeting highlights are ready for viewing here.'}
                </Body2>
              </div>
              <button
                disabled={summaryInProgress}
                onClick={() => {
                  setShowAiSummary(true);
                }}
                className="flex bg-[#E7E7EE] p-2 pr-4 rounded-full items-center gap-3">
                <div
                  className={`${
                    summaryInProgress
                      ? 'bg-textMuted text-borderLight'
                      : 'bg-purple300 text-white'
                  } p-1.5 rounded-full `}>
                  <MagicPenIcon />
                </div>
                <Body2
                  weight="bold"
                  kind={summaryInProgress ? 'textMuted' : 'textDark'}>
                  View Meeting recap
                </Body2>
              </button>
            </div>
          )}
          <VerticalSpacer size="24px" />

          <MeetingAgenda
            meetingId={data.id}
            isReadOnly={isMeetingCanceled}
            isMeetingActive={isMeetingActive}
            isHostSharing={data.shareHostAgenda}
            users={{
              user: data.user?.id,
              participant: computeParticipants.map(
                (participant: {id: string}) => participant.id,
              ),
            }}
            participantSharing={data.shareParticipantAgenda}
            updateMeeting={async (value, field) => {
              const _value = value.filter(
                (agenda: {text: string}) => !!agenda.text,
              );

              await handleUpdateMeeting(
                id,
                patchData(field, _value) as any,
              ).then(() => {
                refetch();
              });
            }}
            agendas={{
              host: {
                user: data.user,
                agenda: agendas.host,
              },
              participant: {
                user: participants as any,
                agenda: agendas.participant,
              },
            }}
          />

          {canViewSections && (
            <div>
              <VerticalSpacer size="24px" />

              <>
                <NextSteps
                  steps={data.nextSteps}
                  meetingId={data.id}
                  isReadOnly={data?.disableActionItems}
                  canCopyItems={data.frequency !== 'once'}
                  bringPreviousItems={data.meta?.bringForwardActionItems}
                  showSource={data.meta?.bringForwardActionItems}
                  meetingCode={data.meetingCode}
                  users={[
                    data.user.id,
                    ...participants.map((participant) => participant.id),
                  ]}
                  updateMeeting={async (data) => {
                    await handleUpdateMeeting(
                      id,
                      patchData('nextSteps', data) as any,
                    );
                  }}
                />
              </>

              <VerticalSpacer size="24px" />

              {canViewSections && !loadingNotes && (
                <Notes
                  user={
                    data.user.id === authStore.auth.user.id
                      ? 'user'
                      : 'participant'
                  }
                  isHostSharing={computedNotes?.hostNote?.shareHostNote}
                  isParticipantSharing={
                    computedNotes?.participantNote?.shareParticipantNote
                  }
                  notes={computedTeamNotes}
                  note={
                    data.user.id === authStore.auth.user.id
                      ? computedNotes?.hostNote?.note || ''
                      : computedNotes?.participantNote?.note || ''
                  }
                  updateMeeting={(value, field, id) => {
                    if (
                      ['hostNote', 'participantNote'].includes(field) &&
                      typeof value === 'string'
                    ) {
                      const prevData = (computedNotes as any)?.[field];

                      updateNotes(
                        value,
                        id,
                        field,
                        prevData?.shareNote,
                        prevData || {firebaseId: id},
                      );
                    } else {
                      const noteType =
                        data.user.id === authStore.auth.user.id
                          ? 'hostNote'
                          : 'participantNote';

                      const prevData = computedNotes?.[noteType];
                      if (isTeamsMeeting) {
                        const _prevData = computedTeamNotes?.find(
                          (note: any) => note.firebaseId === id,
                        );

                        updateNotes(
                          _prevData?.note || '',
                          id,
                          noteType,
                          value,
                          prevData || {firebaseId: id},
                          '',
                        );
                      } else {
                        updateNotes(
                          prevData?.note || '',
                          id,
                          noteType,
                          value,
                          prevData || {firebaseId: id},
                          '',
                        );
                      }
                    }
                  }}
                />
              )}

              {filterComputedTopics &&
                filterComputedTopics.map((topic) => (
                  <>
                    <VerticalSpacer size="24px" />

                    <Notes
                      key={topic.id}
                      title={topic.title}
                      isPrivate={topic.isPrivate}
                      dropdownComponent={
                        <div>
                          <DropdownVertical
                            customIcon={<MoreVerticalIcon />}
                            dropdownWrapperStyle={{
                              right: '-83%',
                            }}
                            menu={
                              <div>
                                <DropdownItem
                                  onClick={() => {
                                    setEditTopic(true);
                                    setSelectedTopic(topic);
                                  }}>
                                  Edit title
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setDeleteTopic(true);
                                    setSelectedTopic(topic);
                                  }}>
                                  Delete
                                </DropdownItem>
                              </div>
                            }
                          />
                        </div>
                      }
                      excludeSharing
                      note=""
                      user={
                        data.user.id === authStore.auth.user.id
                          ? 'user'
                          : 'participant'
                      }
                      notes={computeTopicNotes?.[topic.id]}
                      updateMeeting={(value, field, id) => {
                        updateNotes(
                          value,
                          id,
                          field,
                          topic?.isPrivate,
                          {
                            firebaseId: id,
                          },
                          topic.id,
                        );
                      }}
                    />
                    <VerticalSpacer size="24px" />
                  </>
                ))}

              {isManagerView && isMeetingActive && isTeamsMeeting && (
                <>
                  <VerticalSpacer size="20px" />
                  {!leaveFeedback && (
                    <div className="border border-borderLight rounded-lg p-6 flex items-center justify-between">
                      <Headline3>Leave Feedback</Headline3>
                      <PlainButton onClick={() => setLeaveFeedback(true)}>
                        <FlexRow>
                          <PlusIcon styles={{stroke: '#585ADF'}} />

                          <Body2
                            weight="bold"
                            kind="purple300"
                            className="ml-2">
                            Add feedback
                          </Body2>
                        </FlexRow>
                      </PlainButton>
                    </div>
                  )}
                  {leaveFeedback && (
                    <SectionCard
                      headerPadding={isXs ? 'small' : undefined}
                      contentStyling={{padding: isXs ? '12px' : '24px'}}
                      CustomHeaderTitle={<Headline3>Leave feedback</Headline3>}>
                      <div>
                        <Feedback
                          handleFeedbackFormValueChange={
                            handleFeedbackFormValueChange
                          }
                          feedbackValues={feedbackValues}
                          feedbackError={feedbackError}
                        />
                      </div>
                    </SectionCard>
                  )}
                </>
              )}
            </div>
          )}

          {data.feedback ? (
            <div className="mt-6">
              <SectionCard
                headerPadding={isXs ? 'small' : undefined}
                contentStyling={{
                  padding: isXs ? '12px' : '24px 24px 10px 24px',
                }}
                CustomHeaderTitle={<Headline3>Manager’s feedback</Headline3>}>
                <div>
                  {loadingFeedback ? (
                    <FlexRowCenter>
                      <ItemLoader />
                    </FlexRowCenter>
                  ) : (
                    <FeedbackItem {...feedbackData} excludeSubject />
                  )}
                </div>
              </SectionCard>
            </div>
          ) : null}

          {data?.reasonForCancellation && (
            <div className="mt-6">
              <SectionCard
                headerPadding={isXs ? 'small' : undefined}
                contentStyling={{
                  padding: isXs ? '12px' : '24px ',
                }}
                CustomHeaderTitle={<Headline3>Cancellation reason</Headline3>}>
                <div>
                  <div className="rounded-[10px] border border-[#CECEDE] bg-[#FAFAFA] p-4 flex justify-between items-center">
                    <Body1>{data.reasonForCancellation}</Body1>

                    <Avatar
                      src={lastUpdatedBy?.avatar?.url}
                      name={userName(lastUpdatedBy)}
                      isUserDeleted={!!lastUpdatedBy?.deletedAt}
                      userId={lastUpdatedBy?.id || ''}
                      tooltip={true}
                    />
                  </div>
                </div>
              </SectionCard>
            </div>
          )}

          {authStore.auth.user.id === data.user.id && (
            <>
              <VerticalSpacer size="16px" />
              <AdditionalOptionsWrapper
                display
                className="cursor-pointer mt-6"
                onClick={() => setShowAddTopic(true)}>
                <div className="flex items-center justify-center gap-4">
                  <ThickPlusIcon color={'#47B881'} />

                  <Body2 weight="semibold" kind="textBody">
                    Add to topic
                  </Body2>
                </div>
              </AdditionalOptionsWrapper>
            </>
          )}
        </div>

        {showAddTopic && (
          <CreateNoteTopic
            handleCreate={(_data) => {
              handleAddTopic(_data.title, _data.private, data.id);
              setShowAddTopic(false);
            }}
            onClose={() => setShowAddTopic(false)}
          />
        )}

        {editTopic && (
          <EditTopic
            handleEdit={(_data) => {
              handleEditTopic(selectedTopic?.key || '', {
                ...selectedTopic,
                title: _data.title,
              });
              setEditTopic(false);
            }}
            onClose={() => setEditTopic(false)}
          />
        )}

        {showLinkMeeting && (
          <LinkToMeeting
            handleSave={() => {
              refetch();
            }}
            currentMeeting={data as any}
            currentMeetingId={id}
            onClose={() => setShowLinkMeeting(false)}
          />
        )}

        {shareMeeting && (
          <ShareMeeting
            handleAdd={async (_data) => {
              await handleShareMeeting(
                _data,
                data.calendarId,
                data.participant,
                data.googleEventDetails?.attendees,
              );
              await refetch();
            }}
            meeting={{
              title: data.title || '',
              startDate: data.startDate,
              endDate: data.endDate,
              time: data.time,
            }}
            onClose={() => setShareMeeting(false)}
          />
        )}

        {deleteTopic && (
          <DeleteTopic
            topic={selectedTopic?.title || ''}
            handleDelete={() => {
              handleDeleteTopic(selectedTopic?.key || '');
              setDeleteTopic(false);
            }}
            onClose={() => setDeleteTopic(false)}
          />
        )}
        {showDeleteMeeting && (
          <DeleteNote
            handleDelete={() =>
              deleteMeeting(data.calendarId, data.id, data.primaryMeeting?.id)
            }
            onClose={() => setShowDeleteMeeting(false)}
          />
        )}

        {!!showViewUser && (
          <ViewUser
            open={!!showViewUser}
            name={userName(reviewee)}
            user={reviewee?.id || ''}
            type={showViewUser}
            onClose={() => setShowViewUser('')}
          />
        )}

        {openRescheduleMeeting && (
          <RescheduleMeeting
            onUpdated={refetch}
            open={openRescheduleMeeting}
            handleShowDeleteModal={() => setShowDeleteMeeting(true)}
            onClose={() => setOpenRescheduleMeeting(false)}
            meeting={data}
          />
        )}

        {showAiSummary && (
          <Modal open={true} onClose={() => setShowAiSummary(false)}>
            <ModalWrapper>
              <ModalCard
                title=""
                modalStyle={{padding: '24px', overflow: 'hidden'}}>
                <div>
                  <div className="flex justify-end mb-3">
                    <BackAction
                      icon={<CancelIcon />}
                      title="Close"
                      onClick={() => setShowAiSummary(false)}
                      width="md"
                    />
                  </div>
                  <div className="overflow-scroll h-[80vh]">
                    <AiSummaryPage
                      host={data.user?.id || ''}
                      inProgress={summaryInProgress}
                      agendaType={
                        userIsHost ? 'hostAgenda' : 'participantAgenda'
                      }
                      agenda={
                        userIsHost ? data.hostAgenda : data.participantAgenda
                      }
                      meetingTitle={data.title || `1:1 with`}
                      meetingId={data.id}
                      participants={data.participant}
                    />
                  </div>
                </div>
              </ModalCard>
            </ModalWrapper>
          </Modal>
        )}
      </div>
    );
  },
);
