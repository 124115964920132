import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {useCallback, useState} from 'react';
import {MainWrapper} from './admin-analytics-style';
import {observer} from 'mobx-react-lite';
import {AnalyticsTodayDate} from '../../../../../../../utils/date';
import {ItemLoaderWrapper} from '../../analytics-pages/analytics-status/analytics-status.styles';
import {Body2} from '../../../../../../../ui/atoms/typography';
import {usePeriodHook} from '@hooks/period-hook';
import {ObjSummary} from '../../../reporting-overview/obj-summary';
import {AnalyticsRequestImpl} from '../../admin-analytics.request';
import {AnalyticsController} from '../../admin-analytics.controller';
import {useQuery} from 'react-query';
import nextId from 'react-id-generator';
import {getPercentage} from '@utils/proportions';
import {useStoreContext} from '@store/store-context';
import {ItemLoader} from '@ui/organisms/item-loader';

dayjs.extend(customParseFormat);

type Date = {starts: string; ends: string; period?: string};
interface AnalyticsDetailsProps {
  date: Date;
  summary?: any;
  showObjectiveStatus?: boolean;
  onChangeDateRange: (date: Date) => void;
}

const AnalyticsDetails = observer(
  ({
    date,
    onChangeDateRange,
    summary,
    showObjectiveStatus,
  }: AnalyticsDetailsProps) => {
    const {currentMonthRange, isPeriodEnabled} = usePeriodHook();

    const [_dates] = useState<any>({
      starts: date.starts
        ? date.starts
        : isPeriodEnabled
        ? currentMonthRange()?.starts
        : AnalyticsTodayDate().AnalyticsDefaultStartDate,
      ends: date.ends
        ? date.ends
        : isPeriodEnabled
        ? currentMonthRange()?.ends
        : AnalyticsTodayDate().AnalyticsDefaultEndDate,
      period: date?.period
        ? date.period
        : (isPeriodEnabled && currentMonthRange()?.period) || '',
    });

    const createDataObject = (
      data: any,
      names: {name: string; color: string; label: string}[],
      highestValue: number,
    ): any => ({
      ...data,
      label: dayjs(data.startDate).format('DD MMM.'),
      data: names.map((status) => ({
        ...data,
        name: status.label,
        color: status.color,
        id: nextId('bar-id'),
        startDate: data.startDate,
        count: data[status.name],
        key: status.name,
        endDate: data.endDate,
        percentage: getPercentage(data[status.name], highestValue),
      })),
    });

    const {
      analyticsStore: {searchable, filterStatus, goalsData},
      storeDashboardRoutes: {statusTabs},
      authStore: {auth},
    } = useStoreContext();

    const getObjectivesReport = useCallback(async () => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      try {
        const response = await controller.fetchReportObjectives({
          startDate: _dates.starts,
          endDate: _dates.ends,
          department: searchable.department,
          departmentMembers: searchable.departmentMembers,
          manager: searchable.managers
            ? searchable.managers
            : auth?.user?.role !== 'admin' && auth?.user?.isReviewer === true
            ? auth?.user?.id
            : undefined,
          member: searchable.members,
        });

        if (response) {
          const options = [
            {name: 'onTrack', color: '#D3EEE3', label: 'On Track'},
            {name: 'behind', color: '#FFE3C8', label: 'Behind'},
            {name: 'noStatus', color: '#5F5F8C', label: 'No Status'},
            {name: 'atRisk', color: '#F39C9A', label: 'At Risk'},
          ];
          const result = response.results.map((data: any) => {
            return createDataObject(
              data,
              options,
              response.summary?.highestValue,
            );
          });

          return {
            ...response,
            labels:
              goalsData?.goals?.length === 1
                ? result.map((status: any) => ({
                    name: status.name,
                    color: status.color,
                  }))
                : [],
            result,
          };
        }
      } catch (error) {}
    }, [
      _dates.ends,
      _dates.starts,
      goalsData?.goals?.length,
      auth?.user?.id,
      auth?.user?.isReviewer,
      auth?.user?.role,
      searchable.department,
      searchable.departmentMembers,
      searchable.managers,
      searchable.members,
    ]);

    const {isLoading, data} = useQuery(
      [
        'getObjectivesReport',
        _dates.ends,
        _dates.starts,
        auth?.user?.id,
        auth?.user?.isReviewer,
        auth?.user?.role,
        searchable.department,
        searchable.departmentMembers,
        searchable.managers,
        filterStatus.goals,
        searchable.members,
      ],
      () => getObjectivesReport(),
    );

    return (
      <MainWrapper
        style={{marginBottom: '20px'}}
        className="bg-white sm:mt-4 mt-0">
        <div className="wrapper">
          <Body2 kind="textDark" weight="bold">
            Overview
          </Body2>
        </div>
        {statusTabs.goal && (
          <div className="pt-3 border-t border-t-[#ededf2] mt-4 ">
            {isLoading && (
              <ItemLoaderWrapper>
                <ItemLoader />
              </ItemLoaderWrapper>
            )}

            {data && (
              <div className=" border border-borderLight py-6 px-4  rounded-[10px] ">
                <ObjSummary
                  showGraph={showObjectiveStatus}
                  showCards
                  data={summary}
                  state={filterStatus.goals?.split(',')}
                />
              </div>
            )}
          </div>
        )}
      </MainWrapper>
    );
  },
);
export default AnalyticsDetails;
