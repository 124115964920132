import {capitalize, capitalizeGoalType} from '@utils';
import {StackViewGoal} from '@ui/organisms/stack-view/stack-view';
import {GoalTypeTag} from '@ui/molecules/goal/editable-title/style';
import {useStoreContext} from '@store/store-context';
import {GoalViewInsights} from '../view-icon/view-icon';
import {FlexRow, FlexRowCenter} from '@ui/style/styles';
import {memo, SetStateAction, useState} from 'react';
import {useViewInsight} from '@hooks/insights';
import {Body2} from '../typography';
import {goalDueDate} from '@utils/date';
import {Avatars} from '@ui/molecules/avatars';
import {RenderKeyResultValue} from '../key-result-values/keyresult-values';
import {Avatar} from '../avatar';
import {FiChevronDown} from 'react-icons/fi';
import {QuestionIconWrapper} from '@pages/create-goal/create-goal.styles';
import {ChevronGoalIcon} from '../icons/chevron-goal-down-icon';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {useNavigate} from 'react-router';
import {AlertBadge} from '../icons/alert-badge';
import {MoreVerticalIcon} from '../icons/more-vertical';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {userName} from '@utils/user-name';
import {Metric} from '@ui/organisms/stack-view/stack-view';
import {ProgressBar} from '@ui/molecules/progress-bar';
import {getPercentCompletedKeyResult} from '@utils/proportions';
import {GreenLine} from '../icons/align-line';
import {Body1} from '../typography';
import {
  CountWrapper,
  Wrapper,
  GoalWrapper,
  KeyResultCount,
  KeyResultItem,
  KeyResultWrapper,
  Rule,
} from './styles';
import {Tooltip} from '@ui/molecules/tooltip';
import {ProgressTracker} from '@ui/molecules/progress-tracker';
import {getGroupTypeAndGroup} from '@utils/get-group-grouptype';
import {ListGoals} from '@utils/goal-owners';
import {FC, Dispatch} from 'react';

interface IStackBox {
  goal: StackViewGoal;
  level: number;
  type?: 'parent' | 'child';
  lastGoal?: boolean;
  parentGoalId?: string;
  isOpen: any;
  setIsOpen: Dispatch<SetStateAction<any>>;
  parentKr?: Metric[];
  stackedGoals?: StackViewGoal[];
  cascaded?: boolean;
  goalIndex?: number;
  allGoals?: StackViewGoal[];
}

export const StackViewBox: FC<IStackBox> = memo(
  ({
    goal,
    level,
    lastGoal,
    parentKr,
    cascaded,
    isOpen,
    setIsOpen,
    parentGoalId,
    stackedGoals,
    type,
    allGoals,
  }) => {
    const genShowAlignee = () => {
      if (isOpen && isOpen[goal.id]) {
        return isOpen[goal.id]?.show;
      } else {
        return false;
      }
    };
    const handleShowAlignee = () => {
      const isChild = type === 'child';
      const show = !isOpen?.[goal.id]?.show ?? isChild;

      setIsOpen({
        ...(isChild ? isOpen : {}),
        [goal.id]: {
          show,
        },
      });
    };

    function getAlignedKrs() {
      const keyResultIds = goal.keyResults
        .filter((result: any) => result.alignedKeyResults?.length > 0)
        .flatMap((result: any) =>
          result.alignedKeyResults.map((_kr: any) => _kr.id),
        );

      const filteredGoals =
        allGoals &&
        allGoals?.filter((goal) =>
          goal.keyResults.some((kr: any) => keyResultIds.includes(kr.id)),
        );

      return filteredGoals;
    }

    const isKeyResultCascaded = goal.keyResults?.find(
      (keyResult: any) => keyResult.alignedKeyResults?.length > 0,
    );

    const navigate = useNavigate();

    const [edit, setEdit] = useState({
      alignment: false,
      cascade: false,
      goal: false,
    });

    const [cascadeId, setCascadeId] = useState<any>();

    const updateEdit = (status: boolean, field: keyof typeof edit) => {
      setEdit({...edit, [field]: status});
    };

    const {
      groupStore: {groups},
      groupTypeStore: {groupType},
      storeYouGoals: {youGoalsFilter},
    } = useStoreContext();

    const groupDetails = getGroupTypeAndGroup(
      groups,
      groupType,
      goal.group || '',
    );

    const viewGoalInsight = useViewInsight();

    const alignedGoalLength = goal.alignedGoals?.length;

    const calcLineHeight = (id: string) => {
      const count = {
        goal: 0,
        kr: 0,
      };
      let stop = false;

      stackedGoals?.forEach((goal: any) => {
        if (!stop) {
          count.goal += 1;
          goal.keyResults.forEach((kr: any) => {
            if (!stop) {
              if (kr.id === id) {
                stop = true;
                return;
              } else {
                count.kr += 1;
              }
            }
          });
        }
      });
      return count;
    };

    const goalGroups =
      goal.goalType === 'group'
        ? groups.filter((group) => goal.group?.includes(group.id))
        : null;

    return (
      <div style={{position: 'relative'}}>
        {type === 'child' && goal.alignedGoals?.length > 0 && !lastGoal && (
          <Rule
            bg={'#1A9E68'}
            height="100%"
            left="25px"
            style={{top: '37px'}}
          />
        )}
        <Wrapper level={level}>
          {type === 'child' && (
            <>
              {(cascaded ? !genShowAlignee() : !cascaded) && (
                <div style={{position: 'absolute', top: '39px', left: '-17px'}}>
                  <GreenLine color={cascaded ? '#084B8A' : '#1A9E68'} />
                </div>
              )}

              {(cascaded ? !genShowAlignee() : !cascaded) && (
                <Rule
                  bg={cascaded ? '#084B8A' : '#1A9E68'}
                  height="30px"
                  left={cascaded ? '-28px' : '-36px'}
                  style={{top: '16px'}}
                />
              )}

              {(cascaded ? !genShowAlignee() : !cascaded) && (
                <Rule
                  bg={cascaded ? '#084B8A' : '#1A9E68'}
                  width="20px"
                  height="2px"
                  style={{left: cascaded ? '-28px' : '-36px', top: '44px'}}
                />
              )}

              {!lastGoal && (
                <Rule
                  bg={cascaded ? '#084B8A' : '#1A9E68'}
                  height="100%"
                  left={cascaded ? '-28px' : '-36px'}
                  top={'44px'}
                />
              )}
            </>
          )}

          <GoalWrapper>
            <div style={{width: '35%'}}>
              {goalGroups ? (
                <FlexRow>
                  {goalGroups.map((group) => (
                    <GoalTypeTag className="mr-3" key={group?.id}>
                      {capitalizeGoalType(group?.name || '')}
                    </GoalTypeTag>
                  ))}
                </FlexRow>
              ) : (
                <GoalTypeTag>
                  {goal.goalType === 'company'
                    ? 'Company-wide'
                    : capitalize(goal.goalType)}
                </GoalTypeTag>
              )}

              <FlexRow
                style={{marginTop: '10px', cursor: 'pointer'}}
                onClick={() => handleShowAlignee()}>
                {(!!alignedGoalLength && !cascaded) ||
                (isKeyResultCascaded && !genShowAlignee()) ? (
                  <div>
                    {' '}
                    <CountWrapper
                      bg={isKeyResultCascaded ? '#084B8A' : '#1a9e68'}>
                      {genShowAlignee() ? (
                        <FiChevronDown stroke="white" style={{zIndex: 99}} />
                      ) : isKeyResultCascaded ? (
                        isKeyResultCascaded?.alignedKeyResults?.length
                      ) : (
                        alignedGoalLength
                      )}
                    </CountWrapper>
                  </div>
                ) : (
                  !isKeyResultCascaded && (
                    <div
                      style={{
                        transform: genShowAlignee() ? '' : 'rotate(-90deg)',
                      }}>
                      <ChevronGoalIcon />
                    </div>
                  )
                )}

                <Body2 weight={'bold'} style={{marginLeft: '8px'}}>
                  {goal.name}
                </Body2>
              </FlexRow>
            </div>
            {genShowAlignee() && !!alignedGoalLength && !cascaded && (
              <Rule bg="#1A9E68" top="75px" left="24px" height="95%" />
            )}
            <Avatars
              items={ListGoals(goal)}
              total={goal.keyResults?.length > 0 ? ListGoals(goal).length : 1}
            />
            <FlexRow>
              <GoalViewInsights
                onClick={() => viewGoalInsight(goal.id, goal.isKpi)}
                onHover
              />
              <FlexRowCenter
                style={{
                  paddingLeft: '5px',
                  marginRight: 10,
                }}>
                <ProgressTracker
                  due={goalDueDate(goal?.endDate)}
                  percent={goal?.percentageProgress}
                  state={`${goal?.percentageProgress}%`}
                  // @ts-ignore
                  type={goal?.performance}
                  status={goal?.status}
                  endDate={goal?.endDate}
                  archived={youGoalsFilter === 'archived'}
                  upcoming={youGoalsFilter === 'upcoming'}
                />
              </FlexRowCenter>
              <DropdownVertical
                customIcon={<MoreVerticalIcon />}
                dropdownWrapperStyle={{
                  right: '-83%',
                  minWidth: 214,
                  maxWidth: 214,
                  overflow: 'initial',
                }}
                menu={
                  <div>
                    <DropdownItem
                      onClick={() => {
                        updateEdit(true, 'goal');
                      }}>
                      Edit objective
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        updateEdit(
                          true,
                          cascaded || isKeyResultCascaded
                            ? 'cascade'
                            : 'alignment',
                        );

                        if (type !== 'child') {
                          const getAlignmentId = goal.keyResults.find(
                            (kr: any) => kr.alignedKeyResults?.length > 0,
                          )?.id;
                          setCascadeId({
                            keyResult: getAlignmentId,
                            goal: goal.id,
                          });
                          return;
                        }

                        const getId =
                          parentKr &&
                          parentKr.find(
                            (kr: any) => kr.alignedKeyResults?.length > 0,
                          )?.id;

                        const getAlignmentId = goal.keyResults.find(
                          (kr: any) => kr.keyResultAlignment?.keyResult,
                        )?.id;

                        setCascadeId({
                          keyResult: getId || getAlignmentId,
                          goal: parentGoalId,
                        });
                      }}>
                      {cascaded || isKeyResultCascaded
                        ? `Edit cascade key result`
                        : ` Edit objective alignment`}
                    </DropdownItem>

                    <DropdownItem disabled={true} onClick={() => {}}>
                      Delete objective{' '}
                      <QuestionIconWrapper tooltip={true}>
                        <AlertBadge />
                        <span
                          className="tooltip"
                          style={{top: '200%', width: '450px', zIndex: 9999}}>
                          {cascaded || isKeyResultCascaded ? (
                            <span>
                              This objective has cascaded key results. To
                              delete, <br /> edit the objective alignment and
                              remove the objective.
                            </span>
                          ) : type === 'child' ? (
                            <span>
                              This objective is aligned to a parent objective.
                              To delete, <br /> edit the objective alignment and
                              remove the objective.
                            </span>
                          ) : (
                            <span>
                              This objective has child objectives. To delete,{' '}
                              <br />
                              edit the objective alignment and remove the
                              objective.
                            </span>
                          )}
                        </span>
                      </QuestionIconWrapper>
                    </DropdownItem>
                  </div>
                }
              />
            </FlexRow>
          </GoalWrapper>
          {genShowAlignee() && (
            <>
              <KeyResultWrapper>
                {goal.keyResults.map((keyResult, idx) => (
                  <div key={keyResult.id} style={{position: 'relative'}}>
                    <KeyResultCount>
                      {keyResult?.alignedKeyResults?.length > 0 && (
                        <CountWrapper
                          bg={'#084B8A'}
                          onClick={() => handleShowAlignee()}>
                          {genShowAlignee() ? (
                            <FiChevronDown stroke="white" />
                          ) : (
                            isKeyResultCascaded?.alignedKeyResults?.length
                          )}
                        </CountWrapper>
                      )}
                    </KeyResultCount>
                    {keyResult?.alignedKeyResults?.length > 0 && (
                      <Rule
                        bg={'#084B8A'}
                        height={`${(goal.keyResults?.length - idx) * 96}px`}
                        style={{
                          left: '-28px',
                          top: '31px',
                        }}
                      />
                    )}
                    {type === 'child' &&
                      cascaded &&
                      keyResult?.keyResultAlignment?.keyResult && (
                        <>
                          <div
                            style={{
                              position: 'absolute',
                              top: '15px',
                              left: '-77px',
                            }}>
                            <GreenLine color="#084B8A" />
                          </div>
                          <Rule
                            bg={'#084B8A'}
                            height={`${
                              calcLineHeight(keyResult.id)?.goal * 96 +
                              calcLineHeight(keyResult.id)?.kr * 62
                            }px`}
                            left="-88px"
                            style={{bottom: 24}}
                          />
                          <Rule
                            bg={'#084B8A'}
                            width="20px"
                            height="2px"
                            style={{left: '-87px', top: '22px'}}
                          />
                        </>
                      )}
                    <KeyResultItem
                      key={keyResult.id}
                      cascadeBg={
                        (keyResult?.alignedKeyResults?.length > 0 ||
                          keyResult?.keyResultAlignment?.keyResult) &&
                        (cascaded || isKeyResultCascaded)
                      }
                      style={{marginTop: !idx ? '0px' : '8px'}}>
                      <div>
                        <Tooltip
                          text={keyResult.name}
                          BodyTextNodeType={Body2}
                          maxLength={37}
                          withEllipsis
                          bodyTextNodeKind="textBody"
                          tooltipBody={keyResult.name}
                        />
                      </div>
                      <div>
                        <Avatar
                          name={userName(keyResult?.assignee)}
                          userId={keyResult?.assignee?.id}
                          isUserDeleted={!!keyResult.assignee?.deletedAt}
                          tooltip={true}
                          size="md"
                          src={keyResult?.assignee?.avatar?.url}
                        />
                      </div>
                      <FlexRow style={{justifyContent: 'flex-end'}}>
                        <ProgressBar
                          state={
                            getPercentCompletedKeyResult(
                              keyResult.startValue,
                              keyResult.targetValue,
                              keyResult.currentValue,
                            ) + '%'
                          }
                          percent={getPercentCompletedKeyResult(
                            keyResult.startValue,
                            keyResult.targetValue,
                            keyResult.currentValue,
                          )}
                          measurement={keyResult.measurement?.unit}
                          kpi={{
                            current: keyResult?.currentValue,
                            base: keyResult?.targetValue,
                            good: keyResult?.goodValue,
                            stretch: keyResult?.stretchValue,
                          }}
                        />
                        <div style={{marginLeft: '8px'}} />
                        <RenderKeyResultValue
                          measurement={keyResult?.measurement?.unit}
                          targetValue={keyResult?.targetValue}
                          currencySymbol={keyResult?.measurement?.symbol || ''}
                          currentValue={keyResult?.currentValue}
                        />
                      </FlexRow>
                    </KeyResultItem>
                  </div>
                ))}
              </KeyResultWrapper>
            </>
          )}
        </Wrapper>

        {goal.alignedGoals &&
          genShowAlignee() &&
          !cascaded &&
          goal.alignedGoals.map((alignedGoal, idx) => (
            <StackViewBox
              goal={alignedGoal}
              key={alignedGoal.id}
              parentGoalId={goal.id}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              level={level + 1}
              goalIndex={idx}
              type="child"
              lastGoal={idx === goal.alignedGoals.length - 1}
            />
          ))}
        {genShowAlignee() &&
          getAlignedKrs() &&
          getAlignedKrs()?.map((alignedKr, idx, aligneeGoals) => (
            <StackViewBox
              goal={alignedKr}
              key={alignedKr.id}
              level={level + 1}
              parentGoalId={goal.id}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              parentKr={goal.keyResults}
              goalIndex={idx}
              type="child"
              stackedGoals={getAlignedKrs()}
              cascaded
              lastGoal={idx === aligneeGoals.length - 1}
            />
          ))}
        <CustomIconModal
          open={Object.values(edit).some((edit) => edit)}
          onClose={() => {
            setEdit({
              goal: false,
              alignment: false,
              cascade: false,
            });
          }}
          body={
            <Body1 kind="textBody" align="center">
              Click{' '}
              <span style={{fontWeight: 700, color: 'black'}}>“confirm”</span>{' '}
              to continue.
            </Body1>
          }
          onClick={() =>
            edit.goal
              ? navigate(`/edit-goal`, {
                  replace: true,
                  state: {
                    id: goal.id,
                    data: JSON.stringify(goal),
                  },
                })
              : edit.alignment
              ? navigate(`/create-alignment?edit=${parentGoalId}`)
              : navigate(
                  `/cascade-keyresult?edit=${cascadeId.keyResult}&goalId=${cascadeId.goal}`,
                )
          }
          title={
            edit.goal
              ? `Edit objective?`
              : edit.alignment
              ? `Edit objective alignment?`
              : `Edit cascaded key result?`
          }
          description={
            edit.goal
              ? ` This will remove current update made on the objective
              progress`
              : edit.alignment
              ? ` This will remove the current update made on the parent objective from the child objective(s).`
              : `This will remove the current contribution made on the parent key result from the sub key result(s).`
          }
        />
      </div>
    );
  },
);
