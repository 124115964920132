import dayjs from 'dayjs';
import {memo} from 'react';
import {Checkbox} from '../../atoms/checkbox';
import {
  CalendarWrapper,
  Year,
  MonthBox,
  MonthCell,
  OuterBox,
  DateList,
  DateSpan,
} from './styles';

export interface UserCommon {
  name: string;
  url: string;
  avatar: string;
  id: string;
}

export interface PerformanceDateDropdownProps {
  dates: string[];
  year: number;
  currentMonth: number;
  selectMonth: (index: number) => void;
  prevYear: () => void;
  nextYear: () => void;
  selectedMonths: number[];
  handleQuadrantFetch: (endDate: string) => void;
}
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const PerformanceDateDropdown = memo(function ({
  dates,
  year,
  selectMonth,
  prevYear,
  nextYear,
  selectedMonths,
  handleQuadrantFetch,
}: PerformanceDateDropdownProps) {
  const greaterThanSvg = (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 9L5 5L1 1"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const lessThanSvg = (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 9L1 5L5 1"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <CalendarWrapper className="test">
      {dates.length > 0 && (
        <DateList>
          {dates.map((date: string, index: number) => (
            <DateSpan key={index}>{date}</DateSpan>
          ))}
        </DateList>
      )}

      <OuterBox>
        <Year>
          <span className="nav" onClick={prevYear}>
            {lessThanSvg}
          </span>
          <p>{year}</p>
          <span className="nav" onClick={nextYear}>
            {greaterThanSvg}
          </span>
        </Year>
        <MonthBox>
          {months.map((calMonth: string, index: number) => (
            <MonthCell key={index}>
              <p
                style={{
                  whiteSpace: 'nowrap',
                  marginRight: 10,
                }}>{`${calMonth} ${year}`}</p>
              <Checkbox
                defaultChecked={selectedMonths.includes(index + 1)}
                onChange={() => {
                  const arbD = `${year}-${index + 1}-01`;
                  const daysInMonth = dayjs(arbD).daysInMonth();
                  selectMonth(index);
                  handleQuadrantFetch(`${daysInMonth}-${index + 1}-${year}`);
                }}
                disabled={
                  selectedMonths.length === 2 &&
                  !selectedMonths.includes(index + 1)
                }
              />
            </MonthCell>
          ))}
        </MonthBox>
      </OuterBox>
    </CalendarWrapper>
  );
});
PerformanceDateDropdown.displayName = 'PerformanceDateDropdown';
