import {
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactSelect from 'react-select';
import {useDropDown} from '../../../../hooks';
import {motion} from 'framer-motion';
import {Label} from '../../../atoms/label';
import {FieldWrapper} from '../../../atoms/field-wrapper';
import {Helper} from '../../../atoms/helper';
import {Avatar} from '@ui/atoms/avatar';
import {
  Wrapper,
  DropdownWrapper,
  DropWrapper,
  DropdownHandler,
  HandlerWrapper,
  GroupArrowWrapper,
  ArrowIconWrapper,
  OptionsWrapper,
  OptionWrapper,
} from './styled';
import {MagnifyingGlassIcon} from '../../../atoms/icons';
import {Tooltip} from '@ui/molecules/tooltip';
import {ArrowHeadDownIcon} from '../../../atoms/icons/arrow-head-down';
import {SearchBox2, SearchBox} from '../../search-box';
import {Checkbox} from '../../../atoms/checkbox';
import {SelectFieldProps} from '../user-multi-select/user-multi-select';
import {useStoreContext} from '@store/store-context';
import {HorizontalSpacer, VerticalSpacer} from '../../../atoms/spacer';
import {FlexRow} from '@ui/style/styles';
import {InputState} from '../../../interface';
import {Flex} from '@ui/style/styles';
import {
  OptionsMultiSelect,
  OptionsMultiSelectAll,
} from '../options-multi-select/options-multi-select';
import {Groups} from '../../groups';
import {DropdownCard} from '../../dropdown-card';
import {GreenWhitePlusIcon} from '@ui/atoms/icons/greenWhitePlus';
import {Body1, Body2, CTA} from '../../../atoms/typography';
import {InputNoBorder} from '@ui/atoms/input/input';

export interface TOptionsSelectFieldProps extends SelectFieldProps {
  label?: string;
  placeholder?: string;
  filterGroupReporting?: boolean;
  reviewer?: boolean;
  groupType?: any;
  isCheck?: any;
  handleName?: any;
  handleGroupTypeName?: any;
  handleGroupSelect?: any;
  groupDisplayLength?: number;
  clearOptions?: any;
  handleCheckboxcheck: any;
  noOptionMessage: string;
  performance?: boolean;
  removeSelectedFromDropdown?: boolean;
  height?: any;
  cancelSelection?: (e: any[]) => void;
  showSearchIcon?: boolean;
  showCancelIcon?: any;
  setValue?: any;
  setClearAll?: any;
  reportingStyling?: boolean;
  state?: InputState;
  helper?: string | ReactElement;
  clearManagers?: any;
}
const AvatarWrapper = ({items}: any) => {
  const {
    usersStore: {users},
  } = useStoreContext();

  return (
    <>
      {users
        .filter((user) => user.id === items[0].value)
        .map((user) => (
          <>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Avatar
                src={user.avatar?.url}
                name={
                  user?.firstName
                    ? user?.firstName + ' ' + user?.lastName
                    : user?.email
                }
                size="lg"
                userId={user.id}
                tooltip={true}
              />

              <Tooltip
                text={
                  user?.firstName
                    ? user?.firstName + ' ' + user?.lastName
                    : user?.email
                }
                bodyStyle={{marginLeft: '6px', fontSize: '16px'}}
                maxLength={13}
                withEllipsis={true}
                tooltipBody={
                  user?.firstName
                    ? user?.firstName + ' ' + user?.lastName
                    : user?.email
                }
              />
            </div>
          </>
        ))}
      {items.length > 1 && (
        <Body2 weight="semibold" kind="purple300" style={{marginLeft: '6px'}}>
          + {items.length - 1} more
        </Body2>
      )}
    </>
  );
};
export const MultiOptionsSelectField = forwardRef(
  (
    {
      label,
      state,
      helper,
      excludeCheckBox,
      placeholder,
      options,
      groupType,
      selectAllOption,
      showCancelIcon,
      type,
      height,
      maxSelection,
      margin,
      excludeSearchBox,
      useMainSearchAsDropdown,
      showSearchIcon,
      performance,
      groupDisplayLength,
      searchPlaceholder,
      isCheck,
      setClearAll,
      cancelSelection,
      handleCheckboxcheck,
      onChange,
      handleGroupSelect,
      clearOptions,
      reportingStyling,
      showSelections,
      noOptionMessage,
      customOptionName,
      filterGroupReporting,
      customOption,
      onClickCustomOption,
      defaultValue,
      disabled,
      clearManagers,
      ...rest
    }: SelectFieldProps,
    ref: Ref<ReactSelect<any>>,
  ) => {
    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();

    const getValue = useMemo(() => {
      return options?.filter((option: any) => {
        if (defaultValue) {
          return defaultValue
            .map((val: any) => val.value)
            .includes(option.value);
        }
        return null;
      });
    }, [defaultValue, options]);

    const [selectedOptions, setSelectedOptions] = useState<any>([]);

    useEffect(() => {
      setSelectedOptions(getValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const [groupTypeName, setGroupTypeName] = useState<any>([]);

    const [search, setSearch] = useState('');

    const handleChange = useCallback(
      (opts: any) => {
        const options = opts.filter(
          (option: {value: string}) => option.value !== 'custom',
        );

        setSelectedOptions(options);
        if (onChange) {
          onChange(options);
        }
      },
      [onChange],
    );

    const [isGroupCheckID, setIsGroupCheckID] = useState<any>([]);

    const [isGroupCheck, setIsGroupCheck] = useState<any>([]);
    const handleSearch = (e: any) => {
      setSearch(e.target.value);
    };

    const handleCancelFx = useCallback(
      (value: string, clearAll?: any) => {
        const options =
          selectedOptions &&
          selectedOptions.filter((option: any) => option.value !== value);
        if (clearAll) {
          setSelectedOptions([]);
          if (onChange) onChange([]);
          setIsGroupCheckID([]);
          setClearAll(false);
        } else {
          setSelectedOptions(options);
          if (onChange) onChange(options);
          if (cancelSelection) cancelSelection(options);
          if (filterGroupReporting) setIsGroupCheckID(options);
          !!clearManagers &&
            clearManagers(options?.map((opt: any) => opt?.value)?.join(','));
        }
      },
      [
        selectedOptions,
        onChange,
        setClearAll,
        cancelSelection,
        filterGroupReporting,
        clearManagers,
      ],
    );

    const handleGroupTypeName = (name: any) => {
      if (groupTypeName?.includes(name)) {
        return;
      } else {
        setGroupTypeName([...groupTypeName, name]);
      }
    };

    useEffect(() => {
      if (clearOptions) {
        handleCancelFx('', true);
      }
    }, [clearOptions, handleCancelFx]);

    const handleCheck = (data: any, check: any) => {
      setIsGroupCheckID([...isGroupCheckID, data.value]);
      if (check === true) {
        const item = isGroupCheck?.filter(
          (item: any) => item.value !== data?.value,
        );
        const itemValue = isGroupCheckID.filter(
          (item: any) => item !== data?.value,
        );
        setIsGroupCheck(item);
        setIsGroupCheckID(itemValue);
        setSelectedOptions(item);
        if (onChange) onChange(item);
      } else {
        setIsGroupCheck([...isGroupCheck, data]);
        setIsGroupCheckID([...isGroupCheckID, data.value]);
        if (onChange) onChange([...selectedOptions, data]);
        setSelectedOptions([...selectedOptions, data]);
      }
    };
    const DropdownComponent = selectAllOption
      ? OptionsMultiSelectAll
      : OptionsMultiSelect;

    const [customSearchValue, setCustomSearchValue] = useState('');

    const computeOption = useMemo(() => {
      const getOptions = customSearchValue
        ? options.filter((option) =>
            option.label.toLowerCase().includes(customSearchValue),
          )
        : options;

      if (type === 'users') return getOptions;

      return getOptions?.filter(
        (item: any) =>
          !selectedOptions
            ?.map((option: any) => option.value)
            .includes(item.value),
      );
    }, [customSearchValue, options, selectedOptions, type]);

    const CustomOption = () => {
      if (!customOption) return null;

      return (
        <FlexRow
          onClick={onClickCustomOption}
          style={{cursor: 'pointer', margin: '6px 0px 6px 10px'}}>
          <GreenWhitePlusIcon /> <HorizontalSpacer size="12px" />{' '}
          <CTA kind="textDark" style={{fontWeight: 600}}>
            {customOptionName || 'Manage groups'}
          </CTA>
        </FlexRow>
      );
    };

    return (
      <FieldWrapper margin={margin}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              performance={performance}
              state={state}
              height={height}
              reportingStyling={reportingStyling}
              disabled={disabled}>
              <HandlerWrapper>
                <OptionsWrapper disabled={disabled}>
                  {selectedOptions?.length > 0 && showSelections !== false ? (
                    <>
                      {type === 'users' ? (
                        <AvatarWrapper items={selectedOptions} />
                      ) : (
                        <>
                          {filterGroupReporting && (
                            <>
                              {groupTypeName?.length > 0 ? (
                                <Body2
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  {groupTypeName[0]}{' '}
                                  {groupTypeName?.length === 1 && ':'}
                                </Body2>
                              ) : null}
                            </>
                          )}

                          <Groups
                            total={selectedOptions?.length}
                            items={selectedOptions.map(
                              (item: {
                                label: string;
                                value: string;
                                disabled?: boolean;
                              }) => ({
                                name: item.label,
                                value: item.value,
                                disabled: item.disabled,
                              }),
                            )}
                            showCancelIcon={
                              showCancelIcon ? showCancelIcon : true
                            }
                            groupDisplayLength={groupDisplayLength}
                            disabled={disabled}
                            cancelFx={!disabled ? handleCancelFx : undefined}
                          />
                        </>
                      )}
                    </>
                  ) : useMainSearchAsDropdown ? (
                    <InputNoBorder
                      placeholder={searchPlaceholder}
                      value={customSearchValue}
                      onChange={(event) =>
                        setCustomSearchValue(event.target.value)
                      }
                      style={{fontSize: '14px', padding: '5px '}}
                    />
                  ) : (
                    <p className="greyOut">{placeholder}</p>
                  )}
                </OptionsWrapper>
                <ArrowIconWrapper disabled={disabled}>
                  {!disabled ? (
                    <>
                      {showSearchIcon ? (
                        <MagnifyingGlassIcon />
                      ) : (
                        <motion.span
                          initial={{rotate: 0}}
                          animate={{
                            rotate: open ? 180 : 0,
                            marginTop: open ? '-6px' : '0px',
                          }}
                          transition={{
                            duration: 0.3,
                          }}
                          key="user-select-toggled">
                          <ArrowHeadDownIcon />
                        </motion.span>
                      )}
                    </>
                  ) : (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: 0,
                        marginTop: '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      {showSearchIcon ? (
                        <MagnifyingGlassIcon />
                      ) : (
                        <ArrowHeadDownIcon />
                      )}
                    </motion.span>
                  )}
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            {open && !filterGroupReporting && (
              <DropdownWrapper>
                <DropdownComponent
                  {...rest}
                  onChange={handleChange}
                  value={selectedOptions}
                  defaultValue={defaultValue}
                  noOptionMessage={noOptionMessage}
                  maxSelection={maxSelection}
                  handleClose={handleClose}
                  customOptionName={customOptionName}
                  onClickCustomOption={onClickCustomOption}
                  excludeSearchBox={excludeSearchBox}
                  ref={ref}
                  excludeCheckBox={excludeCheckBox}
                  placeholder={placeholder}
                  options={computeOption}
                  disabled={disabled}
                />
              </DropdownWrapper>
            )}
            {filterGroupReporting && open && (
              <>
                <DropdownWrapper>
                  <div style={{padding: '5px 8px'}}>
                    <SearchBox
                      placeholder="Search group"
                      onChange={handleSearch}
                      style={{height: '44px'}}
                    />
                    <div>
                      {groupType?.map((grp: any) => (
                        <GroupsSelect
                          key={grp.name}
                          groups={grp}
                          disabled={disabled}
                          handleGroupSelect={handleGroupSelect}
                          isGroupCheck={isGroupCheck}
                          setIsGroupCheck={setIsGroupCheck}
                          handleCheck={handleCheck}
                          isGroupCheckID={isGroupCheckID}
                          setSelectedOptions={setSelectedOptions}
                          selectedOptions={selectedOptions}
                          search={search}
                          handleGroupTypeName={handleGroupTypeName}
                          handleChange={handleChange}
                          isCheck={isCheck}
                          handleCheckboxcheck={handleCheckboxcheck}
                        />
                      ))}
                    </div>
                    <CustomOption />
                  </div>
                </DropdownWrapper>
              </>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
const GroupsSelect = ({
  groups,
  disabled,
  search,
  isGroupCheckID,
  handleGroupSelect,
  handleGroupTypeName,
  handleCheck,
  handleCheckboxcheck,
}: any) => {
  const [openGroupType, setOpenGroupType] = useState(false);

  return (
    <>
      <div
        onClick={() => setOpenGroupType(!openGroupType)}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '8px 8px',
        }}>
        <Body2
          kind={!openGroupType ? 'textBody' : 'textDark'}
          style={openGroupType ? {fontWeight: 500} : {fontWeight: 400}}>
          {groups?.name}
        </Body2>
        <ArrowIconWrapper disabled={disabled}>
          {!disabled ? (
            <motion.span
              initial={{rotate: 0}}
              animate={{
                rotate: openGroupType ? 180 : 0,
                marginTop: openGroupType ? '-6px' : '0px',
              }}
              transition={{
                duration: 0.3,
              }}
              key="user-select-toggled">
              <ArrowHeadDownIcon />
            </motion.span>
          ) : (
            <motion.span
              initial={{rotate: 0}}
              animate={{
                rotate: 0,
                marginTop: '0px',
              }}
              transition={{
                duration: 0.3,
              }}
              key="user-select-toggled">
              <ArrowHeadDownIcon />
            </motion.span>
          )}
        </ArrowIconWrapper>
      </div>
      {openGroupType && (
        <>
          <div
            style={{
              background: '#FAFAFA',
              borderRadius: '0px 0px 8px 8px',
            }}>
            {groups?.groups
              ?.filter((item: any) =>
                search !== ' '
                  ? item.name.toLowerCase().includes(search.toLowerCase())
                  : item,
              )
              .map((item: any) => (
                <div
                  key={item?.id}
                  style={{display: 'flex', padding: '8px', cursor: 'pointer'}}
                  onClick={(e: any) => {
                    handleGroupTypeName(groups?.name);
                    handleCheckboxcheck(e);
                    handleGroupSelect({label: item?.name, value: item.id});
                    handleCheck(
                      {label: item?.name, value: item.id},
                      isGroupCheckID?.includes(item?.id) ? true : false,
                    );
                  }}>
                  <Checkbox
                    kind="import"
                    id={item?.id}
                    checked={isGroupCheckID?.includes(item?.id)}
                  />
                  <Body2 kind="textBody" style={{marginLeft: '8px'}}>
                    {item?.name}
                  </Body2>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export const MultiOptionsSelectField2 = forwardRef(
  ({
    label,
    state,
    helper,
    placeholder,
    options,
    onChange,
    defaultValue,
    disabled,
    searchPlaceholder,
    NoDataPlaceholder,
  }: SelectFieldProps) => {
    const {handleOpen, ref: dropRef, open} = useDropDown();
    const [searchText, setsearchText] = useState('');
    const filterOptions = useMemo(() => {
      if (searchText.trim() === '') return options;
      else
        return options.filter((options) =>
          options.label.toLowerCase().includes(searchText.toLowerCase()),
        );
    }, [options, searchText]);

    const getValue = useMemo(() => {
      return options.filter((option: any) => {
        if (defaultValue) {
          return defaultValue
            .map((val: any) => val.value)
            .includes(option.value);
        }
        return null;
      });
    }, [defaultValue, options]);

    const [selectedOptions, setSelectedOptions] = useState<any>([]);

    useEffect(() => {
      setSelectedOptions(getValue);
    }, [getValue]);

    const handleChange = (opt: any) => {
      if (
        selectedOptions.filter((select: any) => select.value === opt.value)
          .length < 1
      ) {
        if (onChange) onChange([...selectedOptions, opt]);
        setSelectedOptions((prev: any) => [...prev, opt]);
      } else {
        const filterData = selectedOptions.filter(
          (select: any) => select.value !== opt.value,
        );
        setSelectedOptions(filterData);
        if (onChange) onChange(filterData);
      }
    };

    const handleCancelFx = useCallback(
      (value: string) => {
        const options = selectedOptions.filter(
          (option: any) => option.value !== value,
        );
        setSelectedOptions(options);
        if (onChange) onChange(options);
      },
      [selectedOptions, onChange],
    );

    return (
      <FieldWrapper margin={true}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              disabled={disabled}>
              <HandlerWrapper>
                <OptionsWrapper disabled={disabled}>
                  {selectedOptions?.length > 0 ? (
                    <Groups
                      total={selectedOptions.length}
                      items={selectedOptions.map(
                        (item: {label: string; value: string}) => ({
                          name: item.label,
                          value: item.value,
                        }),
                      )}
                      disabled={disabled}
                      cancelFx={!disabled ? handleCancelFx : undefined}
                    />
                  ) : (
                    <p className="greyOut">{placeholder}</p>
                  )}
                </OptionsWrapper>
                <ArrowIconWrapper disabled={disabled}>
                  {!disabled ? (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: open ? 180 : 0,
                        marginTop: open ? '-6px' : '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  ) : (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: 0,
                        marginTop: '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  )}
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            <VerticalSpacer size="16px" />
            {open && (
              <DropdownCard
                disableFooter={true}
                disableHeader={true}
                width="100%"
                placeholder={searchPlaceholder ? searchPlaceholder : ''}
                onClickCancel={handleOpen}
                onSubmit={handleOpen}
                handleSearch={(e) => setsearchText(e.target.value)}
                title="Group">
                <>
                  {filterOptions.length > 0 ? (
                    filterOptions.map((option) => (
                      <OptionWrapper
                        key={option.value}
                        onClick={() => handleChange(option)}
                        active={
                          selectedOptions.filter((opts: any) => {
                            return opts.value === option.value;
                          }).length > 0
                        }>
                        <Body2>{option.label}</Body2>
                      </OptionWrapper>
                    ))
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '12px',
                      }}>
                      <Body1 kind="textDark">{NoDataPlaceholder}</Body1>
                    </div>
                  )}
                </>
              </DropdownCard>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);

export const MultiOptionsSelectFieldGroup = forwardRef(
  ({
    label,
    state,
    helper,
    placeholder,
    options,
    onChange,
    theme,
    showSelections,
    defaultValue,
    customOption,
    onClickCustomOption,
    groupDisplayLength,
    showSearchIcon,
    maxSelection,
    singleLabel,
    height,
    enableSelectAll,
    customOptionName,
    handleOnClick,
    removeCheckbox,
    disabled,
    searchPlaceholder,
    performance,
  }: SelectFieldProps) => {
    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();

    const [searchValue, setSearchValue] = useState('');

    const selectedOptionsInitialState: Array<{
      label: string;
      value: string;
    }> = [];

    const [selectedOptions, setSelectedOptions] = useState(
      selectedOptionsInitialState,
    );

    const filterOption = useMemo(() => {
      if (!options) return [];
      const filterOption = options
        .map((option) => ({
          ...option,
          data: option.data.filter(
            (group: any) =>
              !selectedOptions.some((opt) => opt.value === group.value),
          ),
        }))
        .filter((option) => option.data.length > 0);

      if (searchValue.length > 0) {
        return filterOption
          .map((option) => ({
            ...option,
            data: option.data.filter((d: any) =>
              d.label.toLowerCase().includes(searchValue.toLowerCase()),
            ),
          }))
          .filter((option) => option.data.length > 0);
      } else {
        return filterOption;
      }
    }, [options, searchValue, selectedOptions]);

    useEffect(() => {
      if (defaultValue) setSelectedOptions(defaultValue);
    }, [defaultValue]);

    const handleChange = (option: any) => {
      const updatedSelectedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((opt) => opt.value !== option.value)
        : [...selectedOptions, option];

      setSelectedOptions(updatedSelectedOptions);
      if (onChange) onChange(updatedSelectedOptions);
    };

    const handleSelectAllOptions = (options: any[], addOptions: boolean) => {
      const updatedSelectedOptions = addOptions
        ? selectedOptions.filter((selectedOption) =>
            options.every((opt) => opt.value !== selectedOption.value),
          )
        : options;

      setSelectedOptions(updatedSelectedOptions);
      if (onChange) onChange(updatedSelectedOptions);
    };

    const handleCancelFx = useCallback(
      (value: string) => {
        const updatedSelectedOptions = selectedOptions.filter(
          (option) => option.value !== value,
        );
        setSelectedOptions(updatedSelectedOptions);
        if (onChange) onChange(updatedSelectedOptions);
      },
      [selectedOptions, onChange],
    );

    useEffect(() => {
      if (handleOnClick) {
        handleOnClick(open);
      }
    }, [open, handleOnClick]);

    const opts = filterOption.filter((opt: any) => opt.data.length > 0);

    return (
      <FieldWrapper margin>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              height={height}
              performance={performance}
              disabled={disabled}>
              <HandlerWrapper>
                <OptionsWrapper disabled={disabled}>
                  {(selectedOptions?.length > 0 || singleLabel) &&
                  showSelections !== false ? (
                    singleLabel ? (
                      <Body1>{singleLabel}</Body1>
                    ) : (
                      <Groups
                        total={selectedOptions.length}
                        items={
                          selectedOptions.map((item) => ({
                            name: item.label,
                            value: item.value,
                          })) as any
                        }
                        showCancelIcon={!disabled}
                        groupDisplayLength={groupDisplayLength}
                        disabled={disabled}
                        cancelFx={!disabled ? handleCancelFx : undefined}
                      />
                    )
                  ) : (
                    <p className="greyOut">{placeholder}</p>
                  )}
                </OptionsWrapper>
                <ArrowIconWrapper disabled={disabled}>
                  {showSearchIcon ? (
                    <MagnifyingGlassIcon />
                  ) : (
                    <motion.span
                      initial={{rotate: 0, marginTop: '0px'}}
                      animate={{
                        rotate: open ? 180 : 0,
                        marginTop: open ? '-6px' : '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  )}
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>

            {open && (
              <div style={{position: 'relative'}}>
                <DropdownWrapper>
                  <div style={{padding: '10px ', zIndex: 5}}>
                    <SearchBox
                      placeholder={searchPlaceholder}
                      onChange={(e) => setSearchValue(e.target.value)}
                      style={{height: '44px', marginBottom: '16px'}}
                    />
                    <div className="overflow-hidden">
                      <div className="max-h-[350px] overflow-auto">
                        {filterOption.length > 0 && opts.length > 0 ? (
                          opts.map((option, i) => (
                            <div
                              style={{marginTop: i === 0 ? '12px' : '4px'}}
                              key={i}>
                              <MultiGroup
                                theme={theme}
                                option={option}
                                handleChange={
                                  selectedOptions.length >= maxSelection
                                    ? () => null
                                    : handleChange
                                }
                                enableSelectAll={enableSelectAll}
                                handleSelectAllOptions={handleSelectAllOptions}
                                removeCheckbox={removeCheckbox}
                                disabled={disabled}
                                selectedOptions={selectedOptions}
                              />
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '12px',
                            }}>
                            <Body1 kind="textDark">No group found</Body1>
                          </div>
                        )}
                      </div>
                    </div>

                    {customOption && (
                      <FlexRow
                        onClick={() => {
                          onClickCustomOption();
                          handleClose();
                        }}
                        style={{marginTop: '6px', cursor: 'pointer'}}>
                        <GreenWhitePlusIcon /> <HorizontalSpacer size="12px" />{' '}
                        <CTA kind="textDark" style={{fontWeight: 600}}>
                          {customOptionName || 'Manage groups'}
                        </CTA>
                      </FlexRow>
                    )}
                  </div>
                </DropdownWrapper>
              </div>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);
export const MultiGroup = ({
  option,
  disabled,
  selectedOptions,
  theme,
  removeCheckbox,
  handleSelectAllOptions,
  handleChange,
  enableSelectAll,
}: any) => {
  const isUsers = theme === 'users';
  const [showOptions, setshowOptions] = useState(isUsers);

  const isAllSelected = option?.data.every(
    (option: {label: string; value: string}) =>
      selectedOptions
        ?.map((item: {label: string; value: string}) => item.value)
        .includes(option.value),
  );

  return (
    <div style={{margin: '0 2px'}}>
      {option?.data?.length > 0 && (
        <>
          {isUsers && (
            <div className="bg-[#FAFAFA] py-2 px-2 rounded-lg ">
              <Body2
                kind={!showOptions ? 'textBody' : 'textDark'}
                weight="bold">
                {option?.name}
              </Body2>
            </div>
          )}
          {!isUsers && (
            <div
              onClick={() => setshowOptions(!showOptions)}
              className="flex justify-between cursor-pointer py-[2px] px-[3px]">
              <Flex>
                {enableSelectAll && (
                  <Checkbox
                    kind="import"
                    checked={isAllSelected}
                    onChange={() => {
                      setshowOptions(true);
                      handleSelectAllOptions(option?.data, isAllSelected);
                    }}
                  />
                )}
                <Body2
                  kind={!showOptions ? 'textBody' : 'textDark'}
                  style={showOptions ? {fontWeight: 500} : {fontWeight: 400}}>
                  {option?.name}
                </Body2>
              </Flex>
              <GroupArrowWrapper disabled={disabled}>
                {!disabled ? (
                  <motion.span
                    initial={{rotate: 0}}
                    animate={{
                      rotate: showOptions ? 180 : 0,
                      marginTop: showOptions ? '-6px' : '0px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                    key="user-select-toggled">
                    <ArrowHeadDownIcon />
                  </motion.span>
                ) : (
                  <motion.span
                    initial={{rotate: 0}}
                    animate={{
                      rotate: 0,
                      marginTop: '0px',
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                    key="user-select-toggled">
                    <ArrowHeadDownIcon />
                  </motion.span>
                )}
              </GroupArrowWrapper>
            </div>
          )}
        </>
      )}
      {showOptions && option?.data?.length > 0 && (
        <>
          <div
            style={{
              background: isUsers ? 'white' : '#FAFAFA',
              borderRadius: '0px 0px 8px 8px',
              borderTop: isUsers ? '0' : '1px solid #ededf2',
            }}>
            {option?.data?.map((item: any) => (
              <div
                key={item.value}
                style={{
                  display: 'flex',
                  padding: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleChange(item);
                }}>
                {removeCheckbox && (
                  <Checkbox
                    kind="import"
                    id={item?.id}
                    checked={
                      selectedOptions?.filter((opts: any) => {
                        return opts.value === item.value;
                      })?.length > 0
                    }
                    onChange={() => handleChange(item)}
                  />
                )}

                <Body2 kind="textBody" style={{marginLeft: '8px'}}>
                  {item?.label}
                </Body2>
              </div>
            ))}
          </div>
        </>
      )}

      <VerticalSpacer size="16px" />
    </div>
  );
};

export const OptionsSelectFieldGroup = forwardRef(
  ({
    label,
    state,
    helper,
    placeholder,
    options,
    onChange,
    defaultValue,
    disabled,
    searchPlaceholder,
  }: SelectFieldProps) => {
    const {handleOpen, ref: dropRef, open} = useDropDown();
    const [searchValue, setsearchValue] = useState('');

    const filterOption = useMemo(() => {
      if (searchValue.length > 0) {
        return options.map((option) => {
          return {
            ...option,
            data: option.data.filter((d: any) =>
              d.label.toLowerCase().includes(searchValue.toLowerCase()),
            ),
          };
        });
      } else return options;
    }, [options, searchValue]);

    const getValue = defaultValue;

    const [selectedOptions, setSelectedOptions] = useState<any>(() =>
      defaultValue ? getValue : [],
    );

    const handleChange = (option: any) => {
      if (
        selectedOptions.filter((opt: any) => opt.value === option.value) < 1
      ) {
        setSelectedOptions([...selectedOptions, option]);
        if (onChange) onChange([...selectedOptions, option]);
      } else {
        const filterSelect = selectedOptions.filter(
          (opt: any) => opt.value !== option.value,
        );
        setSelectedOptions(filterSelect);
        if (onChange) onChange(filterSelect);
      }
    };

    const handleCancelFx = useCallback(
      (value: string) => {
        const options = selectedOptions.filter(
          (option: any) => option.value !== value,
        );
        setSelectedOptions(options);
        if (onChange) onChange(options);
      },
      [selectedOptions, onChange],
    );

    return (
      <FieldWrapper margin={true}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              onClick={handleOpen}
              disabled={disabled}>
              <HandlerWrapper>
                <OptionsWrapper disabled={disabled}>
                  {selectedOptions?.length > 0 ? (
                    <Groups
                      total={selectedOptions.length}
                      items={selectedOptions.map(
                        (item: {label: string; value: string}) => ({
                          name: item.label,
                          value: item.value,
                        }),
                      )}
                      disabled={disabled}
                      cancelFx={!disabled ? handleCancelFx : undefined}
                    />
                  ) : (
                    <p className="greyOut">{placeholder}</p>
                  )}
                </OptionsWrapper>
                <ArrowIconWrapper disabled={disabled}>
                  {!disabled ? (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: open ? 180 : 0,
                        marginTop: open ? '-6px' : '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  ) : (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: 0,
                        marginTop: '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  )}
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            {open && (
              <DropdownWrapper>
                <DropdownCard
                  disableFooter={true}
                  disableHeader={true}
                  width="100%"
                  placeholder={searchPlaceholder}
                  onClickCancel={handleOpen}
                  onSubmit={handleOpen}
                  handleSearch={(e) => setsearchValue(e.target.value)}
                  title="Group">
                  {filterOption.length &&
                  filterOption.filter((opt: any) => opt?.data?.length > 0)
                    .length > 0 ? (
                    filterOption.map((option: any, i: any) => {
                      return (
                        <>
                          <Body2 style={{fontWeight: 500}} key={i}>
                            {option.name}
                          </Body2>

                          <>
                            {option?.data?.map((opt: any, index: any) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    border: '1px solid rgba(206, 206, 222, 1)',
                                    borderRadius: '6px',
                                    display: 'flex',
                                    padding: ' 16px',
                                    marginTop: '8px',
                                  }}>
                                  <Checkbox
                                    kind="import"
                                    name={opt.value}
                                    checked={
                                      selectedOptions.filter((opts: any) => {
                                        return opts.value === opt.value;
                                      }).length > 0
                                    }
                                    onChange={() => handleChange(opt)}
                                  />
                                  <Body2
                                    kind="textBody"
                                    style={{marginLeft: '5px'}}>
                                    {opt.label}
                                  </Body2>
                                </div>
                              );
                            })}{' '}
                            <VerticalSpacer size="24px" />
                          </>
                        </>
                      );
                    })
                  ) : (
                    <>Group not found</>
                  )}
                </DropdownCard>
              </DropdownWrapper>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);

export const OptionsSelectField = forwardRef(
  ({
    label,
    state,
    helper,
    placeholder,
    options,
    onChange,
    defaultValue,
    disabled,
  }: SelectFieldProps) => {
    const {handleOpen, ref: dropRef, open, handleClose} = useDropDown();
    const [searchValue, setsearchValue] = useState('');

    const filterOption = useMemo(() => {
      if (searchValue.length > 0) {
        return options.map((option) => {
          return {
            ...option,
            data: option.data.filter((d: any) =>
              d.label.toLowerCase().includes(searchValue.toLowerCase()),
            ),
          };
        });
      } else return options;
    }, [options, searchValue]);

    const getValue = defaultValue;

    const [selectedOptions, setSelectedOptions] = useState<any>(() =>
      defaultValue
        ? getValue
        : {
            value: '',
            label: '',
          },
    );

    const handleChange = (option: any) => {
      setSelectedOptions(option);
      handleClose();
      if (onChange) onChange(option);
    };

    return (
      <FieldWrapper margin={true}>
        <Wrapper>
          <Label aria-label="label">{label}</Label>
          <DropWrapper ref={dropRef}>
            <DropdownHandler
              open={open}
              performance={true}
              onClick={handleOpen}
              disabled={disabled}>
              <HandlerWrapper>
                <OptionsWrapper disabled={disabled}>
                  {getValue?.label?.length > 0 && !disabled ? (
                    <Body2>{getValue?.label}</Body2>
                  ) : (
                    <p className="greyOut">{placeholder}</p>
                  )}
                </OptionsWrapper>
                <ArrowIconWrapper disabled={disabled}>
                  {!disabled ? (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: open ? 180 : 0,
                        marginTop: open ? '-6px' : '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  ) : (
                    <motion.span
                      initial={{rotate: 0}}
                      animate={{
                        rotate: 0,
                        marginTop: '0px',
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                      key="user-select-toggled">
                      <ArrowHeadDownIcon />
                    </motion.span>
                  )}
                </ArrowIconWrapper>
              </HandlerWrapper>
            </DropdownHandler>
            {open && (
              <DropdownWrapper
                style={{
                  padding: '8px',
                  maxHeight: '300px',
                  overflow: 'scroll',
                }}>
                <SearchBox2
                  placeholder={'Search report'}
                  value={searchValue}
                  onChange={(e) => setsearchValue(e.target.value)}
                  handleCancel={() => setsearchValue('')}
                  style={{height: '44px'}}
                />
                {filterOption.length &&
                filterOption.filter((opt: any) => opt?.data?.length > 0)
                  .length > 0 ? (
                  filterOption.map((option: any, i: any) => {
                    return (
                      <>
                        <SelectOpts
                          option={option}
                          searchValue={searchValue}
                          handleChange={handleChange}
                          selectedOption={selectedOptions}
                          key={i}
                        />
                      </>
                    );
                  })
                ) : (
                  <div>
                    <Body2
                      align="center"
                      kind="textBody"
                      style={{margin: '10px 0px'}}>
                      Report not found
                    </Body2>
                  </div>
                )}
              </DropdownWrapper>
            )}
          </DropWrapper>
          <Helper aria-label="helper" state={state} children={helper} />
        </Wrapper>
      </FieldWrapper>
    );
  },
);

const SelectOpts = ({
  option,
  handleChange,
  selectedOption,
  searchValue,
}: any) => {
  const [open, setOpen] = useState(false);
  useMemo(() => {
    if (searchValue !== '' && !open) {
      setOpen(true);
    }
  }, [searchValue, open]);
  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '14px',
          padding: '5px 8px',
        }}>
        <Body2
          style={{fontWeight: open ? 500 : 400}}
          kind={open ? 'textDark' : 'textBody'}>
          {option.name}
        </Body2>
        <ArrowIconWrapper>
          <motion.span
            initial={{rotate: 0}}
            animate={{
              rotate: open ? 180 : 0,
              marginTop: open ? '-6px' : '0px',
            }}
            transition={{
              duration: 0.3,
            }}
            key="user-select-toggled">
            <ArrowHeadDownIcon />
          </motion.span>
        </ArrowIconWrapper>
      </div>
      {open && (
        <>
          <div
            style={{
              background: '#fafafa',
              borderTop: '1px solid #ededf2',
              borderRadius: '0px 0px 10px 10px',
            }}>
            {option?.data?.map((opt: any, index: any) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    handleChange(opt);
                  }}
                  style={{
                    display: 'flex',
                    padding: ' 16px 0px',
                    cursor: 'pointer',
                  }}>
                  <Body2
                    kind={
                      selectedOption?.label === opt.label
                        ? 'textDark'
                        : 'textBody'
                    }
                    style={{marginLeft: '5px'}}>
                    {opt.label}
                  </Body2>
                </div>
              );
            })}{' '}
          </div>
          <VerticalSpacer size="24px" />
        </>
      )}
    </>
  );
};
