import React, {FC, useMemo} from 'react';
import {GoalSection} from '../you/you-components/insights/goal/goal-card';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {KeyResultSection} from '../you/you-components/insights/keyresults/key-results-cards';
import {MemberActivity} from '../you/you-components/insights/activity/activity';
import {IMeetingResponse, TGoalResponse} from '@hooks';
import {MeetingAgenda} from '@pages/1:1s/view-meetings/components/agenda';
import {NextSteps} from '@pages/1:1s/view-meetings/components/next-steps';
import {SectionCard} from '@ui/layouts/section-card';
import {Body2, Headline3} from '@ui/atoms/typography';
import {DrawerModal} from '@ui/organisms/modal/drawer-modal';
import {Note} from '@pages/1:1s/view-meetings/styles';
import {authStore} from '@store/stores/auth-store';
import {userName} from '@utils/user-name';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import dayjs from 'dayjs';
import {getTime} from '@utils/date';
import {timezones} from '@constants';
import {FlexRow} from '@ui/style/styles';
import {RedirectIcon} from '@ui/atoms/icons/redirect';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';

interface ViewSourceProps {
  onClose: () => void;
  meeting?: IMeetingResponse;
  type: string;
  userName: string;
  goal?: TGoalResponse;
}
export const ViewActionItemsSource: FC<ViewSourceProps> = ({
  onClose,
  meeting,
  userName,
  goal,
  type,
}) => {
  return (
    <div>
      <DrawerModal open onClose={onClose}>
        {!['goal', 'meeting'].includes(type) && (
          <Headline3 className="mb-4">
            {`View ${userName}'s ${type}s`}
          </Headline3>
        )}

        {meeting && type === 'meeting' && <MeetingView meeting={meeting} />}

        {goal && type === 'goal' && <ObjectiveView goal={goal} />}
      </DrawerModal>
    </div>
  );
};

interface IMeetingView {
  meeting: IMeetingResponse;
}

const MeetingView: FC<IMeetingView> = ({meeting}) => {
  const ShowHostNote = () => {
    if (
      meeting &&
      (meeting.hostNote || authStore.auth.user.id === meeting.user.id)
    ) {
      return (
        <>
          <SectionCard
            contentStyling={{padding: '24px'}}
            CustomHeaderTitle={
              <Headline3>{userName(meeting.user)}'s notes</Headline3>
            }>
            <div>
              <Note>{meeting.hostNote}</Note>
            </div>
          </SectionCard>
          <VerticalSpacer size="16px" />
        </>
      );
    }
    return null;
  };

  const participant = Array.isArray(meeting.participant)
    ? meeting.participant.map((participant) =>
        typeof participant === 'string' ? participant : participant.id,
      )
    : meeting.participant.id;

  const {targetHour, targetMinute} = useMemo(
    () => getTime(meeting?.time || ''),
    [meeting?.time],
  );

  const getTimeZone = useMemo(
    () =>
      timezones.find(
        (timezone) => timezone.value === authStore.auth.user.timezone,
      ),
    [],
  );

  const updatedDate = useMemo(
    () =>
      dayjs(meeting.startDate)
        .set('hour', targetHour)
        .set('minute', targetMinute)
        .tz(getTimeZone?.label || '', true),
    [getTimeZone?.label, meeting.startDate, targetHour, targetMinute],
  );

  const isRecurring = meeting.frequency !== 'once';

  const participantName = Array.isArray(meeting.participant)
    ? meeting.participant[0]
    : meeting.participant;

  return (
    <>
      <FlexRow className="gap-2">
        <Headline3>
          {meeting?.title || `1:1 with ${userName(participantName)}`}
        </Headline3>

        <a
          href={`/view-meetings/${meeting.id}`}
          target="_blank"
          rel="noreferrer">
          <HoverTooltip
            className="bg-[#F6F6F8] border border-[#EDEDF2] p-2 text-[#5F5F8C]"
            tooltipTextKind="textBody"
            tooltipTextSize="14px"
            tooltipText="Open link">
            <RedirectIcon />
          </HoverTooltip>
        </a>
      </FlexRow>

      <div className="flex items-center gap-3 pb-4 mt-2">
        <Body2 kind="textBody">
          {dayjs(meeting.startDate).format("ddd. D MMM. 'YY")}
        </Body2>

        {meeting?.time && !meeting?.title?.includes('Notes with') && (
          <>
            <CustomColorIcon
              height="8px"
              width="8px"
              margin={7}
              color="#D9D9D9"
            />
            <Body2 kind="textBody">
              {' '}
              {dayjs(updatedDate).format('hh:mm a')}{' '}
              {meeting?.endDate
                ? `- ${dayjs(meeting.endDate).format('hh:mm a')}`
                : null}
            </Body2>
          </>
        )}

        {isRecurring && (
          <>
            <CustomColorIcon
              height="8px"
              width="8px"
              margin={7}
              color="#D9D9D9"
            />

            <Body2 className="capitalize" kind="textBody">
              {meeting.frequency}
            </Body2>
          </>
        )}
      </div>

      <MeetingAgenda
        isReadOnly
        isHostSharing={meeting.shareHostAgenda}
        users={{
          user: meeting.user?.id,
          participant: participant,
        }}
        participantSharing={meeting.shareParticipantAgenda}
        updateMeeting={async (value, field) => {}}
        agendas={{
          host: {
            user: meeting.user,
            agenda: meeting.hostAgenda,
          },
          participant: {
            user: meeting.participant,
            agenda: meeting.participantAgenda,
          },
        }}
      />

      <div>
        <VerticalSpacer size="24px" />

        <NextSteps
          isReadOnly
          meetingCode={meeting.meetingCode}
          bringPreviousItems={meeting.meta?.bringForwardActionItems}
          steps={meeting.nextSteps}
          meetingId={meeting.id}
          users={[meeting.user?.id, ...participant]}
          updateMeeting={async (data) => {}}
        />

        <VerticalSpacer size="24px" />

        <ShowHostNote />
      </div>
    </>
  );
};

interface ObjectiveViewProps {
  goal: TGoalResponse;
}

const ObjectiveView: FC<ObjectiveViewProps> = ({goal}) => {
  return (
    <>
      <GoalSection goal={goal} excludeCopyLink />

      <VerticalSpacer size="24px" />

      {goal.keyResults.length > 0 && (
        <KeyResultSection keyResult={goal.keyResults} />
      )}
      <VerticalSpacer size="34px" />
      <MemberActivity goal={goal as any} isReadOnly />
    </>
  );
};
