import {authStore} from '@store/stores/auth-store';
import {integrationStore} from '@store/stores/integration-store';
import {updateGoogleTokens} from '@utils/firebase-request';
import axios from 'axios';

export interface ConfigApiCalendar {
  clientId: string;
  clientSecret: string;
  apiKey: string;
  scope: string;
  discoveryDocs: string[];
  hosted_domain?: string;
}

export interface TimeCalendarType {
  dateTime?: string;
  timeZone: string;
}

// import {ConfigApiCalendar, TimeCalendarType} from './type';

const scriptSrcGoogle = 'https://accounts.google.com/gsi/client';
const scriptSrcGapi = 'https://apis.google.com/js/api.js';

interface ExtendedTokenClient extends google.accounts.oauth2.TokenClient {
  callback?: (resp: any) => void;
  error_callback?: (resp: any) => void;
}

class ApiCalendar {
  tokenClient: ExtendedTokenClient | null | any = null;
  onLoadCallback: any = null;
  calendar: string = 'primary';

  constructor(public config: ConfigApiCalendar) {
    try {
      this.initGapiClient = this.initGapiClient.bind(this);
      this.handleSignoutClick = this.handleSignoutClick.bind(this);
      this.handleAuthClick = this.handleAuthClick.bind(this);
      this.createEvent = this.createEvent.bind(this);
      this.listUpcomingEvents = this.listUpcomingEvents.bind(this);
      this.getEvent = this.getEvent.bind(this);
      this.setToken = this.setToken.bind(this);
      this.onLoad = this.onLoad.bind(this);
      this.setCalendar = this.setCalendar.bind(this);
      this.updateEvent = this.updateEvent.bind(this);
      this.deleteEvent = this.deleteEvent.bind(this);

      this.handleClientLoad();
    } catch (e) {}
  }

  get sign(): boolean {
    return !!this.tokenClient;
  }

  /**
   * Auth to the google Api.
   */
  private initGapiClient(): void {
    gapi.client
      .init({
        apiKey: this.config.apiKey,
        discoveryDocs: this.config.discoveryDocs,
        hosted_domain: this.config.hosted_domain,
      })
      .then((): void => {
        integrationStore.updateGapiStatus();

        if (this.onLoadCallback) {
          this.onLoadCallback();
        }
      })
      .catch((e: any): void => {});
  }

  /**
   * Init Google Api
   * And create gapi in global
   */
  private handleClientLoad(): void {
    const scriptGoogle = document.createElement('script');
    const scriptGapi = document.createElement('script');
    scriptGoogle.src = scriptSrcGoogle;
    scriptGoogle.async = true;
    scriptGoogle.defer = true;
    scriptGapi.src = scriptSrcGapi;
    scriptGapi.async = true;
    scriptGapi.defer = true;
    document.body.appendChild(scriptGapi);
    document.body.appendChild(scriptGoogle);
    scriptGapi.onload = (): void => {
      gapi.load('client', this.initGapiClient);
    };
    scriptGoogle.onload = async (): Promise<void> => {
      this.tokenClient = await google.accounts.oauth2.initCodeClient({
        client_id: this.config.clientId,
        scope: this.config.scope,
        //   prompt: '',
        callback: (response): void => {},
      });
    };
  }

  exchangeCodeForTokens = async (
    authorizationCode: string,
    clientId: string,
    clientSecret: string,
    redirectUri: string,
  ) => {
    try {
      const response = await axios.post('https://oauth2.googleapis.com/token', {
        code: authorizationCode,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: 'authorization_code',
      });

      const {access_token, refresh_token} = response.data;

      // gapi.auth.
      return {access_token, refresh_token};
    } catch (error) {
      console.error('Error exchanging code for tokens:', error);
      throw error;
    }
  };

  /**
   * Sign in Google user account
   * @returns {Promise<void>} Promise resolved if authentication is successful, rejected if unsuccessful.
   */
  public async handleAuthClick(): Promise<void> {
    if (gapi && this.tokenClient) {
      return new Promise<void>(
        async (
          resolve: (resp: any) => void,
          reject: (resp: any) => void,
        ): Promise<void> => {
          this.tokenClient!.callback = async (resp: any): Promise<void> => {
            if (resp.error) {
              reject(resp);
            } else {
              let _response;

              const response = await this.exchangeCodeForTokens(
                resp.code,
                this.config.clientId,
                this.config.clientSecret,
                window.location.origin,
              );

              if (response) {
                updateGoogleTokens(authStore.auth.user.id, {
                  refresh_token: response.refresh_token,
                });

                gapi.client.setToken(response);

                resolve({...resp, ...response});
                _response = response;
              }

              resolve(_response || resp);
            }
          };
          this.tokenClient!.error_callback = (resp: any): void => {
            reject(resp);
          };

          //    this.tokenClient!.
          if (gapi.client.getToken() === null) {
            this.tokenClient!.requestCode();
          } else {
          }
          try {
          } catch (error) {}
        },
      );
    } else {
      console.error('Error: this.gapi not loaded');
      return Promise.reject(new Error('Error: this.gapi not loaded'));
    }
  }

  /**
   * Set the default attribute calendar
   * @param {string} newCalendar
   */
  public setCalendar(newCalendar: string): void {
    this.calendar = newCalendar;
  }

  public setToken(token: any): void {
    if (gapi) {
      gapi.client.setToken(token);
    }
  }

  /**
   * Execute the callback function when gapi is loaded
   * @param callback
   */
  public onLoad(callback: any): void {
    if (gapi) {
      callback();
    } else {
      this.onLoadCallback = callback;
    }
  }

  public async getEmail(): Promise<string | undefined> {
    const token = await gapi.client.getToken();

    if (!token || !token.access_token) {
      return;
    }

    try {
      const response = await axios.get(
        'https://www.googleapis.com/oauth2/v2/userinfo',
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        },
      );

      const email = response.data.email;
      if (!email) {
        return;
      }

      return email;
    } catch (error) {
      return;
    }
  }

  /**
   * Sign out user google account
   */
  public handleSignoutClick(): void {
    if (gapi) {
      const token = gapi.client.getToken();
      if (token !== null) {
        google.accounts.id.disableAutoSelect();
        google.accounts.oauth2.revoke(token.access_token, (): void => {});
        gapi.client.setToken(null);
      }
    } else {
      console.error('Error: this.gapi not loaded');
    }
  }

  /**
   * List all events in the calendar
   * @param {number} maxResults to see
   * @param {string} calendarId to see by default use the calendar attribute
   * @returns {any}
   */
  public listUpcomingEvents(
    maxResults: number,
    startDate: string,
    endDate: string,
    calendarId: string = this.calendar,
    showCanceledMeetings?: boolean,
  ): any {
    if (gapi.client.getToken()) {
      return (gapi.client as any).calendar.events.list({
        calendarId: calendarId,
        timeMin: startDate,
        timeMax: endDate,
        showDeleted: showCanceledMeetings ?? true,
        singleEvents: true,
        maxResults: maxResults,
        orderBy: 'startTime',
      });
    } else {
      console.error('Error: this.gapi not loaded');
      return false;
    }
  }

  public getEvent(eventId: string, calendarId: string = this.calendar): any {
    if (gapi.client.getToken()) {
      return (gapi.client as any).calendar.events.get({
        calendarId,
        eventId,
      });
    } else {
      console.error('Error: this.gapi not loaded');
      return false;
    }
  }

  /**
   * List all events in the calendar queried by custom query options
   * See all available options here https://developers.google.com/calendar/v3/reference/events/list
   * @param {object} queryOptions to see
   * @param {string} calendarId to see by default use the calendar attribute
   * @returns {any}
   */
  //

  /**
   * Create Calendar event
   * @param {string} calendarId for the event.
   * @param {object} event with start and end dateTime
   * @param {string} sendUpdates Acceptable values are: "all", "externalOnly", "none"
   * @returns {any}
   */
  public createEvent(
    event: {
      end: TimeCalendarType;
      start: TimeCalendarType;
      summary: string;
      description: string;
      reminders: object;
      attendees: {
        email: string;
        organizer: boolean;
        self: boolean;
        responseStatus: string;
      }[];
    },
    calendarId: string = this.calendar,
    sendUpdates: 'all' | 'externalOnly' | 'none' = 'none',
  ): any {
    if (gapi.client.getToken()) {
      return (gapi.client as any).calendar.events.insert({
        calendarId: calendarId,
        resource: {
          ...event,
          conferenceData: {
            createRequest: {
              requestId: (crypto as any).randomUUID(),
              conferenceSolutionKey: {
                type: 'hangoutsMeet',
              },
            },
          },
        },
        //@ts-ignore the @types/gapi.calendar package is not up to date(https://developers.google.com/calendar/api/v3/reference/events/insert)
        sendUpdates,
        conferenceDataVersion: 1,
      });
    } else {
      console.error('Error: this.gapi not loaded');
      return false;
    }
  }

  /**
   * Create Calendar event with video conference
   * @param {string} calendarId for the event.
   * @param {object} event with start and end dateTime
   * @param {string} sendUpdates Acceptable values are: "all", "externalOnly", "none"
   * @returns {any}
   */
  public createEventWithVideoConference(
    event: any,
    calendarId: string = this.calendar,
    sendUpdates: 'all' | 'externalOnly' | 'none' = 'none',
  ): any {
    return this.createEvent(
      {
        ...event,
        conferenceData: {
          createRequest: {
            requestId: (crypto as any).randomUUID(),
            conferenceSolutionKey: {
              type: 'hangoutsMeet',
            },
          },
        },
      },
      calendarId,
      sendUpdates,
    );
  }

  /**
   * Delete an event in the calendar.
   * @param {string} eventId of the event to delete.
   * @param {string} calendarId where the event is.
   * @returns {any} Promise resolved when the event is deleted.
   */
  deleteEvent(eventId: string, calendarId: string = this.calendar): any {
    if (gapi) {
      return (gapi.client as any).calendar.events.delete({
        calendarId: calendarId,
        eventId: eventId,
      });
    } else {
      console.error('Error: gapi is not loaded use onLoad before please.');
      return null;
    }
  }

  /**
   * Update Calendar event
   * @param {string} calendarId for the event.
   * @param {string} eventId of the event.
   * @param {object} event with details to update, e.g. summary
   * @param {string} sendUpdates Acceptable values are: "all", "externalOnly", "none"
   * @returns {any}
   */
  updateEvent(
    event: object,
    eventId: string,
    calendarId: string = this.calendar,
    sendUpdates: string = 'none',
  ): any {
    if (gapi) {
      //@ts-ignore the @types/gapi.calendar package is not up to date(https://developers.google.com/calendar/api/v3/reference/events/patch)
      return gapi.client.calendar.events.patch({
        calendarId: calendarId,
        eventId: eventId,
        resource: event,
        sendUpdates: sendUpdates,
      });
    } else {
      console.error('Error: gapi is not loaded use onLoad before please.');
      return null;
    }
  }

  /**
   * Get Calendar event
   * @param {string} calendarId for the event.
   * @param {string} eventId specifies individual event
   * @returns {any}
   */
}

export default ApiCalendar;
