import {
  CSSProperties,
  memo,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
} from 'react';

import styled from 'styled-components';
import {ifProp} from 'styled-tools';
import {motion} from 'framer-motion';

import {Typography} from '../typography/typography';
import {Body2} from '../typography';
import {getColorFromTheme} from '../../ui-utils';
import {NavLink} from 'react-router-dom';
import styledMap from 'styled-map';
import {css} from 'styled-components';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {useDropDown} from '@hooks';

const NavItem = styled(NavLink)<{$active?: boolean; disabled?: boolean}>`
  ${Typography};
  padding: 10px 16px;
  margin: 0;
  font-weight: ${ifProp('$active', 600, 500)};
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props: any) => {
    if (props.disabled) {
      return getColorFromTheme('textMuted');
    } else if (props.$active) {
      return getColorFromTheme('textDark');
    } else {
      return getColorFromTheme('textBody');
    }
  }};
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  pointer-events: ${ifProp('disabled', 'none', 'auto')};
  text-decoration: none;
  position: relative;
  border: ${ifProp('$active', `1px solid #3436A2`, '0px')};
  border-radius: 12px;
  flex-shrink: 0;
  min-width: 190px;

  @media (min-width: 768px) and (max-width: 1200px) {
    min-width: fit-content;
    width: fit-content;
  }

  svg {
    path {
      stroke: ${(props) => {
        if (props.$active) {
          return getColorFromTheme('purple300');
        }
        return getColorFromTheme('textBody');
      }};
      strokewidth: 1.5px;
    }
  }

  &:hover {
    color: ${getColorFromTheme('textDark')};
  }

  @media (max-width: 390px) {
    padding: 8px 24px;
  }
`;

const SharedSecondaryNavIem = css<{variant?: string; kind?: string}>`
  ${Typography};
  width: ${(props) => props.variant === 'templates' && '50%'};
  text-align: ${(props) => props.variant === 'templates' && 'center'};
  padding: ${ifProp('icons', '4px 6px 9px 4px', '9px 8px')};
  font-weight: ${ifProp('$active', 500, 400)};

  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${ifProp(
    '$active',
    getColorFromTheme('purple300'),
    getColorFromTheme('textBody'),
  )};
  text-decoration: none;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    color: ${getColorFromTheme('purple300')};
  }

  @media (max-width: 390px) {
    padding: 8px 24px;
  }

  @media (max-width: 500px) {
    padding: ${styledMap('kind', {
      details: '3px 6px 6px',
      default: '6px',
    })};
  }

  > span > span > svg {
    stroke: ${ifProp(
      '$active',
      getColorFromTheme('textDark'),
      getColorFromTheme('textBody'),
    )};

    &:hover {
      stroke: ${getColorFromTheme('purple300')};
    }
  }
`;

const NavItemSecondary = styled(NavLink)<{
  $active?: boolean;
  icons?: boolean;
  variant?: string;
}>`
  ${SharedSecondaryNavIem}
`;

const ButtonItemSecondary = styled.button<{
  $active?: boolean;
  icons?: boolean;
  variant?: string;
}>`
  ${SharedSecondaryNavIem}
`;

const MobileDropDown = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const DropdownCustomWrapper = styled.div`
  background: #ffffff;
  position: absolute;
  top: 53px;
  left: 0%;
  border: 1px solid #cecede;
  padding: 8px;
  box-shadow: 0px 2px 5px rgba(172, 172, 190, 0.24);
  border-radius: 7px;
`;

const NavItemSecondaryBackground = styled(motion.span).withConfig({
  shouldForwardProp: () => true,
})<{kind?: string; icons?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${getColorFromTheme('white')};
  z-index: 1;
  border-radius: 5px;
  border: ${ifProp('icons', '1px solid #585ADF', '')};
  width: 100%;

  height: ${styledMap('kind', {
    details: '75%',
    default: '84%',
  })};

  margin-top: ${styledMap('kind', {
    details: '5px',
    default: '0px',
  })};

  box-shadow: ${({theme}) => theme.elevation.level1};
  @media (max-width: 500px) {
    height: ${styledMap('kind', {
      details: '75%',
      default: '90%',
    })};

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${styledMap('kind', {
      details: '5px',
      default: '2px',
    })};
  }
  @media (max-width: 500px) {
    height: ${styledMap('kind', {
      details: '65%',
      default: '90%',
    })};

    margin-top: ${styledMap('kind', {
      details: '7px',
      default: '2px',
    })};
  }
`;

const NavItemContent = styled.span<{kind?: string}>`
  z-index: 1;
  display: flex;
  align-items: center;

  white-space: nowrap;

  position: relative;
  @media (max-width: 400px) {
    margin-top: ${({kind}) => (kind === 'details' ? '6px' : '0px')};
  }
`;

interface IChildNavigation {
  href: string;
  title: string | ReactNode;
  icon?: ReactNode;
  children: IChildNavigation[];
  active: boolean;
  id: string;
  onClick: (e: SyntheticEvent<HTMLAnchorElement>) => void;
}
interface INavigationProps {
  active: boolean;
  href?: string;
  kind?: string;
  setOpen?: any;
  as?: string;
  activeStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  title?: string;
  disabled?: boolean;
  variant?: any;
  icons?: boolean;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement>) => void;
  type?: 'button' | 'link';

  childNavigations?: IChildNavigation[];
}

export interface HeaderNavProps extends PropsWithChildren<INavigationProps> {}

export const HeaderNav = memo(function ({
  href,
  active,
  disabled,
  childNavigations,
  children,
  onClick,
}: HeaderNavProps) {
  const {handleOpen, ref, open, handleClose} = useDropDown();

  return (
    <NavItem
      to={href ?? '#'}
      onClick={
        childNavigations?.length
          ? ((handleOpen as unknown) as MouseEventHandler<HTMLAnchorElement>)
          : onClick
      }
      disabled={disabled}
      $active={active}>
      <NavItemContent tabIndex={-1}>{children}</NavItemContent>

      {open && (
        <DropdownCustomWrapper onClick={handleClose} ref={ref}>
          <div style={{width: '180px'}}>
            {childNavigations &&
              childNavigations.map((nav) => (
                <NavLink
                  to={nav.href ?? '#'}
                  onClick={nav.onClick}
                  key={nav.id}>
                  <DropdownItem>{nav.title}</DropdownItem>
                </NavLink>
              ))}
          </div>
        </DropdownCustomWrapper>
      )}
    </NavItem>
  );
});

export const HeaderSecondaryNav = memo(function ({
  href,
  active,
  children,
  activeStyle,
  kind,
  icons,
  bodyStyle,
  type,
  variant,
  onClick,
}: HeaderNavProps) {
  const TabComponent =
    type === 'button' ? ButtonItemSecondary : (NavItemSecondary as any);

  return (
    <TabComponent
      to={href ?? '#'}
      onClick={onClick}
      $active={active}
      style={bodyStyle}
      variant={variant}
      kind={kind}
      icons={icons}>
      {active && (
        <NavItemSecondaryBackground
          layoutId="header-nav-item-background"
          initial={true}
          key="icon"
          kind={kind}
          icons={icons}
          style={activeStyle}
          transition={{
            duration: 0,
            layoutX: {duration: 0},
            layoutY: {delay: 0, duration: 0},
          }}
        />
      )}
      <NavItemContent tabIndex={-1} kind={kind}>
        {children}
      </NavItemContent>
    </TabComponent>
  );
});

HeaderNav.displayName = 'HeaderNav';

export const MobileSecondaryNav = memo(function ({
  href,
  active,
  title,
  setOpen,
  onClick,
}: HeaderNavProps) {
  return (
    <MobileDropDown>
      <NavLink to={href ?? '#'} onClick={onClick}>
        <Body2
          onClick={() => setOpen(false)}
          style={{
            color: active ? '#585ADF' : '#5F5F8C',
            fontWeight: active ? 500 : 400,
          }}>
          {!active && title}
        </Body2>
      </NavLink>
    </MobileDropDown>
  );
});
