import {useState} from 'react';
import {useStoreContext} from '../../../../../../../../../store/store-context';
import {Button} from '../../../../../../../../../ui/atoms/button';
import {ArrowHeadLeftIcon} from '../../../../../../../../../ui/atoms/icons';
import {VerticalSpacer} from '../../../../../../../../../ui/atoms/spacer';
import {useNavigate} from 'react-router';
import {Body2, Headline4} from '../../../../../../../../../ui/atoms/typography';
import {TextField} from '../../../../../../../../../ui/molecules/field/textfield';

import {SeatDiv, SeatsDiv} from '../billing-not-active.styles';

interface IBillingSeatsComponent {
  //   loader: boolean;
  //   plans: IBillingPlan[];
  //   setShowStarterPlanCTA: Dispatch<SetStateAction<boolean>>;
}
// eslint-disable-next-line no-empty-pattern
export const BillingSeats = ({}: //   loader,
//   plans,
//   setShowStarterPlanCTA,
IBillingSeatsComponent) => {
  const {
    billingRoutesStore: {activateInactiveBillingTabs},
    billingsStore: {setNumSeats},
    usersStore: {users},
  } = useStoreContext();
  const [seats, setSeats] = useState<number>(users?.length);
  const [error, setError] = useState<string>();
  const handleSetSeats = (e: any) => {
    setSeats(e.target.value);
  };
  const navigate = useNavigate();

  const reserveSeats = () => {
    if (seats < users?.length) {
      setError(
        'You cannot add a number less than the number of users in your workspace',
      );
    } else {
      setNumSeats(seats);
      // activateInactiveBillingTabs('billingCycle');
      navigate('/workspace-settings/billing-cycle');
    }
  };
  return (
    <SeatsDiv>
      <SeatDiv>
        <div className="header">
          <span
            onClick={() => {
              activateInactiveBillingTabs('plans');
            }}>
            <ArrowHeadLeftIcon />
          </span>
          <Body2 kind="textBody">Choose a plan</Body2>
        </div>
        <div className="content">
          <Headline4 align="center">How many seats?</Headline4>
          <VerticalSpacer size="8px" />
          <Body2 kind="textBody" align="center">
            Kindly enter the amount below
          </Body2>
          <VerticalSpacer size="25px" />
          <TextField
            placeholder="Enter here"
            type="number"
            value={seats}
            onChange={handleSetSeats}
            helper={error || ''}
            state={error ? 'error' : 'default'}
          />
          <VerticalSpacer size="32px" />
          <Button width="full" onClick={reserveSeats}>
            Continue
          </Button>
        </div>
      </SeatDiv>
    </SeatsDiv>
  );
};
