import {useCallback, useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Resolver} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router';
import {
  WorkspaceInviteCsvData,
  WorkspaceInviteFormData,
} from './workspace-invite-interface';
import {
  WorkspaceInviteCsvValidator,
  WorkspaceInviteValidator,
} from './workspace-invite-validator';
import {WorkspaceInviteController} from './workspace-invite-controller';
import {WorkspaceInviteRequestImpl} from './workspace-invite-request';
import {useStoreContext} from '../../../../../store/store-context';
import {database} from '../../../../../utils/firebase-request';
import {groupRequestImpl} from '../admin-people/admin-group/admin-group-request';
import {groupController} from '../admin-people/admin-group/admin-group-controller';
import {usePostHog} from 'posthog-js/react';
import {onValue, ref} from 'firebase/database';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {useApiCalls} from '@hooks/init-calls';
import {AnalyticsRequestImpl} from '../../../reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {AnalyticsController} from '../../../reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {WorkspaceAPIController} from '../../../../../api/workspaces-api/workspaces-api-controller';
import {WorkspaceAPIRequestImpl} from '../../../../../api/workspaces-api/workspaces-api-request';
import {authStore} from '@store/stores/auth-store';
import {setFirebaseData} from '@utils/firebase-handler';

export function useNewEmailHook() {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
  } = useForm<WorkspaceInviteFormData>({
    resolver: yupResolver(WorkspaceInviteValidator) as Resolver<
      WorkspaceInviteFormData,
      object
    >,
    mode: 'onBlur',
    defaultValues: {
      emails: [],
    },
  });
  const {initGroups} = useApiCalls();

  const {
    handleSubmit: csvHandleSubmit,

    setValue: csvSetValue,
    control: csvControl,
    watch: csvWatch,
  } = useForm<WorkspaceInviteCsvData>({
    resolver: yupResolver(WorkspaceInviteCsvValidator) as Resolver<
      WorkspaceInviteCsvData,
      object
    >,
    mode: 'onBlur',
    defaultValues: {
      inviteFile: '',
    },
  });
  const [showModal, setShowModal] = useState(false);
  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const hasError = useMemo(() => {
    return formState.submitCount > 0 && formState.errors;
  }, [formState.errors, formState.submitCount]);

  const updateEmails = useCallback(
    (values: any) => {
      setValue('emails', values);
    },
    [setValue],
  );

  const postHog = usePostHog();

  const [invitedPersons, setInvitedPersons] = useState<any>();

  const {
    authStore: {
      setInitResponseAsAuth,
      closeGlobalUserInvitationModal,
      setFeatureLimitStatus,
    },
    analyticsStore: {filterGroup: group, setFilterGroup},
    groupTypeStore: {groupType},
    usersStore: {currentUser},
  } = useStoreContext();

  const {search} = useLocation();
  const accessToken = search.slice(search.indexOf('=') + 1);
  // Fetch Feature Limit Status

  const fetchFeatureLimitStatus = useCallback(async () => {
    const request = new WorkspaceAPIRequestImpl();
    const controller = new WorkspaceAPIController(request);
    const response: any = await controller.fetchFeatureLimitStatus();

    response && setFeatureLimitStatus(response);
  }, [setFeatureLimitStatus]);
  // Initialize groups
  const fetchGroups = useCallback(async () => {
    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const groupsResponse = await controller.fetchGroups();
    setFilterGroup(
      groupsResponse &&
        // eslint-disable-next-line array-callback-return
        groupsResponse.results.map((group: any) => {
          if (group) {
            return {
              label:
                group.name === 'General'
                  ? `General (${group.groupType?.name})`
                  : group.name,
              value: group.id,
            };
          }
        }),
    );
  }, [setFilterGroup]);
  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);
  //
  const [newGroup, setNewGroup] = useState('');

  const request = new WorkspaceInviteRequestImpl();
  const controller = new WorkspaceInviteController(request);
  const navigate = useNavigate();

  const [csvFile, setCSVFile] = useState<any>({});

  const handleCSVUpload = (e: any) => {
    const file = e.target.files[0];
    const reader: any = new FileReader();
    setCSVFile({
      ...csvFile,
      fakeProgress: true,
      name: file.name,
    });
    setTimeout(() => {
      setCSVFile({
        ...csvFile,
        fakeProgress: false,
      });
      if (e.target.files[0]) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (reader.result) {
            const base64String = reader.result
              .replace('data:', '')
              .replace(/^.+,/, '');

            setCSVFile({
              base64: base64String,
              name: file.name,
            });
            csvSetValue('inviteFile', base64String);
          }
        };
      }
    }, 2000);
  };
  const clearCSVFile = () => {
    setCSVFile({});
    csvSetValue('inviteFile', '');
  };

  const handleMixPanel = async () => {
    onValue(
      ref(database, `onboarding/${currentUser?.id}/step/invite-user`),
      (snapshot: any) => {
        const val = snapshot?.val();

        if (val !== true) {
          postHog?.capture(
            'frontend_onboarding-  Invite users and reviewers to your workspace (1 of 3)',
          );
        }
        navigate('/home', {replace: true});
        navigate('/invite-user', {replace: true});
      },
    );
  };

  const submitInvitationsCsv = async (data: WorkspaceInviteCsvData) => {
    const response = await controller.newWorkspaceInviteCsv(data, accessToken);

    setInitResponseAsAuth();
    closeGlobalUserInvitationModal();
    response && fetchFeatureLimitStatus();

    response && handleMixPanel();
  };

  const submitInvitations = async (data: WorkspaceInviteFormData) => {
    const allEmails: any = [];

    const errorMessages = [
      'must be a valid email',
      'there are issues with your payment method',
      'card number is incorrect',
      'your card has insufficient funds.',
    ];

    const handleInvitations = async (data: any) => {
      const response = await controller.newWorkspaceInvite(
        {emails: data},
        accessToken,
        errorMessages,
      );

      response && fetchFeatureLimitStatus();

      const emailRegex = /[\w.-]+@[\w.-]+\.\w+/g;
      const existingEmails = response?.data?.message.match(emailRegex) || [];

      const computedData = data.filter(
        (email: any) => !existingEmails.includes(email.email),
      );

      const errorMessage = response?.data?.message?.toLowerCase();

      const isError =
        errorMessage &&
        errorMessages.some((message) =>
          errorMessage.includes(message.toLowerCase()),
        );

      if (isError) {
        return setInvitedPersons({
          response: response,
          allEmails: computedData,
          error: errorMessage.includes(errorMessages[0])
            ? 'There seems to be a problem with the email address. Please check the format and try again.'
            : 'We encountered an issue while attempting to process payment for the additional user(s). Please update your payment information and try again.',
        });
      }

      setInvitedPersons({
        response: response,
        emails: computedData,
        existingEmails,
      });

      setInitResponseAsAuth();

      closeGlobalUserInvitationModal();
    };
    const emails = await Promise.all(
      data.emails.map(async (emailValue: any) => {
        if (emailValue.newGroup) {
          const request = new groupRequestImpl();

          const controller = new groupController(request);

          const department = groupType.find(
            (grpType) => grpType.name === 'Department',
          );

          const grpResponse: any = await controller.newCreateGroup(
            {
              name: emailValue.newGroup,
              groupAdmin: authStore.auth.user.id,
              groupType: department?.id || '',
            },
            true,
          );
          setFirebaseData(`updates/group`, generateRandomDigits(16));

          initGroups();

          const newEmails = emailValue.email.map((em: any) => ({
            email: em,
            manager: emailValue.manager,
            group: grpResponse ? [grpResponse?.id] : emailValue.group,
          }));

          allEmails.push(...newEmails);

          return newEmails;
        }

        const existingEmails = emailValue.email.map((em: any) => ({
          email: em,
          manager: emailValue.manager,
          group: emailValue.group,
        }));

        return existingEmails;
      }),
    );

    await handleInvitations(emails.flatMap((value) => value));
  };

  const [disabledWorkspaceInvite, setDisabledWorkspaceInvite] = useState(true);
  const {emails} = watch();

  const {inviteFile} = csvWatch();

  useMemo(() => {
    const trimmed = emails.filter(
      (email: any) =>
        email &&
        email.email &&
        email.email?.length > 0 &&
        (email.group?.length > 0 || email.newGroup?.length > 0) &&
        email.manager,
    );
    const withGroupsNoEmail = emails.filter(
      (email: any) => email?.email === undefined,
    );
    trimmed.length > 0 || inviteFile
      ? setDisabledWorkspaceInvite(false)
      : withGroupsNoEmail.length > 0
      ? setDisabledWorkspaceInvite(true)
      : setDisabledWorkspaceInvite(true);
  }, [emails, inviteFile]);
  const [activeMode, setActiveMode] = useState<any>('email');

  useMemo(() => {
    const trimmed = emails.filter((email: string) => email !== '');
    trimmed.length > 0
      ? setActiveMode('email')
      : Object.keys(csvFile).length > 0
      ? setActiveMode('csv')
      : setActiveMode('default');
  }, [csvFile, emails]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return {
    updateEmails,
    register,
    errors,
    isSubmitting,
    submitInvitations,
    submitInvitationsCsv,
    setNewGroup,
    newGroup,
    handleSubmit,
    control,
    hasError,
    group,
    disabledWorkspaceInvite,
    handleCSVUpload,
    showModal,
    csvFile,
    clearCSVFile,
    invitedPersons,
    setInvitedPersons,
    csvHandleSubmit,
    csvControl,
    handleModalClose,
    activeMode,
    emails,
  };
}
