import styled from 'styled-components';
import {Body1} from '../../../../../../../ui/atoms/typography';
import {FC, useState} from 'react';
import {Button} from '@ui/atoms/button';
import {SetupWorkspaceController} from '@pages/admin/setup-workspace/setup-workspace-controller';
import {SetupWorkspaceRequestImpl} from '@pages/admin/setup-workspace/setup-workspace-request';
import {PerformanceSettingsEditForm} from './culture-competence-settings';
import {FlexRowEnd} from '@ui/style/styles';
import {SecondaryBreadcrumb} from '@ui/molecules/breadcrumb';
import {down} from 'styled-breakpoints';
import {Route, Routes, Navigate} from 'react-router';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {illustrationList} from '@ui/assets/illustrations';
import {LockIcon} from '@ui/atoms/icons';
import {useFreeTierHook} from '@hooks/free-tier';
import FreeTierModal from '@ui/organisms/free-tier/free-tier-modal';
import {ContentBox} from '../../admin-workspace-styled';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {useStoreContext} from '../../../../../../../store/store-context';
import {activateNotification} from '../../../../../../../ui/molecules/notification/activate-notification';
import {useNavigate} from 'react-router';
import {SwitchToggle} from '@ui/molecules/toggle-switch/toggle-switch';
import {setFirebaseData} from '@utils/firebase-handler';

const RedirectTo = ({redirectTo}: {redirectTo: string}) => (
  <Navigate to={redirectTo} replace />
);

const Layout = styled.div`
  padding: 32px;
  width: 90%;

  ${down('md')} {
    padding: 16px;
  }

  ${down('sm')} {
    width: 100%;
    padding: 0px;
  }
`;

const BreadcrumbWrapper = styled.div`
  border-bottom: 1px solid #ededf2;
  width: 78vw;
  margin: 10px 0 0;
  padding-left: 16px;
  @media (max-width: 500px) {
    width: 90vw;
    border-top: 1px solid #ededf2;
    margin: 0px;
    padding: 20px 16px 0px;
  }
`;

interface IPerformanceSettings {}

export const PerformanceSettings: FC<IPerformanceSettings> = () => {
  const {
    authStore: {auth},
  } = useStoreContext();

  const [collapse, setCollapse] = useState(true);
  const [disablePerformance, setDisablePerformance] = useState(
    auth.user.workspace.settings.ccStatus !== 'enabled',
  );

  const {
    isAccountFreeTier,
    showFreeTierModal,
    handleShowFreeTierModal,
  } = useFreeTierHook();

  const DisplayFreeTierModal = (
    <FreeTierModal
      open={showFreeTierModal}
      image={illustrationList.cuttingWood}
      onClose={() => handleShowFreeTierModal(false)}
      description={`Obtain a thorough examination and understanding of your team's cultural and skill-based strengths and weaknesses, which will allow you to pinpoint opportunities for enhancing performance.`}
      title={`Activate Modern Performance Reviews with Culture & Competence`}
    />
  );

  const navigate = useNavigate();

  const breadCrumbNav = [
    {
      href: '#',
      id: '1',
      onClick: () => {
        setCollapse(true);
        navigate('');
      },
      title: 'Performance',
    },
    {
      href: '',
      id: '2',
      isCurrent: true,
      onClick: function noRefCheck() {},
      title: 'Culture & Competence settings',
    },
  ];

  const handlePerformanceStatus = async (status: boolean) => {
    if (isAccountFreeTier) return handleShowFreeTierModal(true);

    const request = new SetupWorkspaceRequestImpl();

    const controller = new SetupWorkspaceController(request);

    const response = await controller.workspaceEdit(
      auth?.user?.workspace?.id,
      {
        settings: {
          ...auth?.user?.workspace?.settings,
          ccStatus: status ? 'enabled' : 'disabled',
        },
      },
      auth?.tokens?.access?.token,
    );

    response &&
      !status &&
      activateNotification({
        title: 'Success',
        content: 'Culture and competence disabled',
        kind: 'success',
      });
    response &&
      status &&
      activateNotification({
        title: 'Success',
        content: 'Culture and competence enabled',
        kind: 'success',
      });

    response && setFirebaseData(`updates/settings`, generateRandomDigits(16));

    setDisablePerformance(!disablePerformance);
  };

  return (
    <>
      {DisplayFreeTierModal}

      <Routes>
        <Route
          path=""
          element={
            <>
              <ContentBox>
                <div className="flex">
                  <Body1 kind="textDark" weight="bold">
                    {isAccountFreeTier && (
                      <span>
                        <LockIcon />
                      </span>
                    )}{' '}
                    C&C Review
                  </Body1>
                  <FlexRowEnd>
                    <SwitchToggle
                      checked={!disablePerformance}
                      onChange={() =>
                        handlePerformanceStatus(disablePerformance)
                      }
                    />

                    <div style={{margin: '0 10px'}}>
                      <HorizontalSideRule size="32px" />
                    </div>
                    <Button
                      style={{padding: '8px 16px'}}
                      kind="secondary"
                      disabled={disablePerformance}
                      onClick={() => {
                        !disablePerformance && setCollapse(!collapse);
                        navigate('cc-view-settings');
                      }}>
                      Configure
                    </Button>
                  </FlexRowEnd>
                </div>
              </ContentBox>
            </>
          }
        />
        <Route
          path="cc-view-settings"
          element={
            !disablePerformance ? (
              <div>
                <BreadcrumbWrapper>
                  <SecondaryBreadcrumb navigation={breadCrumbNav} />
                </BreadcrumbWrapper>
                <Layout>
                  <PerformanceSettingsEditForm />
                </Layout>
              </div>
            ) : (
              <RedirectTo redirectTo="/workspace-settings/performance" />
            )
          }
        />
      </Routes>
    </>
  );
};
