import styled, {css} from 'styled-components';
import {switchProp} from 'styled-tools';
import {Button} from '../button';
import {ComponentPropsWithRef, memo} from 'react';
import {ButtonKind, ButtonSize} from '../../interface';

export interface LinkProps extends ComponentPropsWithRef<'a'> {
  kind?: ButtonKind;
  width?: ButtonSize;
  isLoading?: boolean;
}

const fullWidthStyle = css`
  width: 100%;
`;

const mdStyle = css`
  padding: 16px 24px;
`;

const smStyle = css`
  padding: 8px 16px;
`;

export const width = css`
  ${switchProp(
    'width',
    {
      sm: smStyle,
      md: mdStyle,
      full: fullWidthStyle,
    },
    mdStyle,
  )}
`;

const CustomButton = styled(Button)<LinkProps>`
  display: inline-flex;
  flex-direction: row;
  appearance: none;
  align-items: center;
  justify-content: center;

  ${width};
`;

export const ButtonLink = memo(function ({
  onClick,
  children,
  href,
  ...rest
}: LinkProps) {
  return (
    <CustomButton
      {...rest}
      href={href}
      // @ts-ignore
      onClick={onClick}>
      {children}
    </CustomButton>
  );
});

export const RawButtonLink = memo(function ({
  target,
  children,
  href,
  ...rest
}: LinkProps) {
  return (
    // @ts-ignore
    <CustomButton {...rest} as="a" target={target} href={href}>
      {children}
    </CustomButton>
  );
});
