import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useStoreContext} from '@store/store-context';
import {cfv} from '@utils/framework';
import {SecondaryNavigation} from '@ui/molecules/nav/navigation';
import {TreeViewIcon, FlatViewIcon, NestedViewIcon} from '@ui/atoms/icons';
import {useState, useMemo} from 'react';

export const QuestionIconWrapper = styled.span<{tooltip?: boolean}>`
  position: relative;

  .tooltip {
    visibility: hidden;
    width: 250px;
    background-color: white;
    font-size: 14px;
    color: #5f5f8c;
    text-align: center;
    padding: 16px;
    border: 1px solid #e4e5fb;
    border-radius: 5px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    white-space: prewrap;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 99999;
    left: -130px;
    margin-top: -110px;
  }
  &:hover .tooltip {
    visibility: ${(props: any) => (props.tooltip === true ? 'visible' : '')};
    opacity: 1;
  }

  .tooltip::before {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 69.5%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: #e4e5fb transparent transparent transparent;
  }

  .tooltip::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 69.7%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #fafafa transparent transparent transparent;
  }
`;

export const GoalViewNavigation = observer(() => {
  const [isTooltipFlat, setIsTooltipFlat] = useState(false);
  const [isTooltipTree, setIsTooltipTree] = useState(false);
  const {
    storeDashboardRoutes: {GoalViewTabs, activateGoalViewTab},
    storeYouGoals: {setYouGoalsFilter},
  } = useStoreContext();

  const Navigations = useMemo(() => {
    const nav = [
      {
        active: GoalViewTabs.nested,
        href: '#',
        id: '2',
        onClick: () => {
          setYouGoalsFilter('');

          activateGoalViewTab('nested');
        },
        title: (
          <QuestionIconWrapper
            onMouseEnter={() => setIsTooltipFlat(true)}
            onMouseLeave={() => setIsTooltipFlat(false)}
            tooltip={isTooltipFlat}>
            <NestedViewIcon width={16} height={16} />
            <span className="tooltip">
              You can view your {cfv().g_oals} in stacked view.
            </span>
          </QuestionIconWrapper>
        ),
      },
      {
        active: GoalViewTabs.flat,
        href: '#',
        id: '2',
        onClick: () => {
          activateGoalViewTab('flat');
          setYouGoalsFilter('active');
        },
        title: (
          <QuestionIconWrapper
            onMouseEnter={() => setIsTooltipFlat(true)}
            onMouseLeave={() => setIsTooltipFlat(false)}
            tooltip={isTooltipFlat}>
            <FlatViewIcon width={16} height={16} />
            <span className="tooltip">
              You can view your {cfv().g_oals} in flat view.
            </span>
          </QuestionIconWrapper>
        ),
      },

      {
        active: GoalViewTabs.tree,
        href: '#',
        id: '3',
        onClick: () => {
          activateGoalViewTab('tree');
          setYouGoalsFilter('');
        },
        title: (
          <>
            <QuestionIconWrapper
              onMouseEnter={() => setIsTooltipTree(true)}
              onMouseLeave={() => setIsTooltipTree(false)}
              tooltip={isTooltipTree}>
              <TreeViewIcon width={18} height={18} />
              <span
                className="tooltip"
                style={{marginTop: '-150px', left: '-153px'}}>
                You can view your {cfv().g_oals} in tree view. Where it is
                visible to see how your children {cfv().g_oals} are aligned to a
                parent {cfv().g_oal}
              </span>
            </QuestionIconWrapper>
          </>
        ),
      },
    ];
    return nav;
  }, [
    GoalViewTabs.flat,
    GoalViewTabs.tree,
    activateGoalViewTab,
    isTooltipFlat,
    GoalViewTabs.nested,

    setYouGoalsFilter,
    isTooltipTree,
  ]);

  return (
    <SecondaryNavigation
      // kind="details"
      icons={true}
      navigations={Navigations}
      navStyle={{
        border: 0,
        padding: '4px 12px 0px',
      }}
    />
  );
});
