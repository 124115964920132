import {Avatar} from '@ui/atoms/avatar';
import {Button} from '@ui/atoms/button';
import {CustomColorIcon} from '@ui/atoms/color-icons';
import {ArrowHeadDownIcon, MoreVerticalIcon} from '@ui/atoms/icons';
import {HorizontalSpacer, VerticalSpacer} from '@ui/atoms/spacer';
import {Body2, Body1, CTA} from '@ui/atoms/typography';
import {Avatars} from '@ui/molecules/avatars';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {userName} from '@utils/user-name';
import dayjs from 'dayjs';
import {
  ResponsiveFlexBetween,
  ManagerViewUser,
  ResponsiveFlexStart,
  ResponsiveFlexRow,
} from '../styles';
import {AveragePulse} from './average-pulse';
import {GoalsProgress} from './goals';
import {IAPIUserFormat, IMeetingResponse} from '@hooks';
import {timezones} from '@constants';
import {authStore} from '@store/stores/auth-store';
import {getTime} from '@utils/date';
import {useCallback, useMemo, useState} from 'react';
import {filterDuplicateItems} from '@utils/array';
import {FiChevronDown} from 'react-icons/fi';
import {ToggleSwitch} from '@ui/atoms/toggle-switch';
import {observer} from 'mobx-react';
import MeetingRSVP from './meeting-rsvp';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {useBeamMeetingAIConfiguration} from '@pages/1:1s/reschedule-meeting/reschedule-meeting-hook';
import nextId from 'react-id-generator';
import {RotatingLines} from 'react-loader-spinner';
import {RecurringMeeting} from '@ui/atoms/icons/recurring';

interface MeetingHeaderProps {
  data: IMeetingResponse;
  participants: {name: string; id: string; src: string}[];
  isMeetingActive?: boolean;
  isMeetingMissed?: boolean;
  isMeetingNote: boolean;
  isMeetingCanceled?: boolean;
  isMeetingUpcoming: boolean;
  handleLinkMeeting: () => void;
  isSingleParticipant: any;
  allParticipants?: IAPIUserFormat[];
  handleBringForward: (type: string) => void;
  feedbackValues: any;
  isManagerView: boolean;
  handleSeriesSettings?: () => void;
  handleDeleteNote: () => void;
  managerViewUserOptions: any[];
  markAsCompleted: any;
  setShowViewUser: any;
  handleAddBeamAi: (
    meetingLink: string,
    value: boolean,
    updateCheck: (value: boolean) => void,
  ) => Promise<void>;
  handleShareMeeting: () => void;
  refetch: any;
  setOpenRescheduleMeeting: any;
  id: string;
  connectedGoogleEmail: string;
  submitting: boolean;
  reviewee: IAPIUserFormat;
}

export const MeetingHeader = observer(
  ({
    data,
    participants,
    isMeetingActive,
    isMeetingNote,
    allParticipants,
    handleDeleteNote,
    handleLinkMeeting,
    managerViewUserOptions,
    setOpenRescheduleMeeting,
    handleSeriesSettings,
    reviewee,
    setShowViewUser,
    isManagerView,
    connectedGoogleEmail,
    handleShareMeeting,
    handleAddBeamAi,
    isMeetingUpcoming,
  }: MeetingHeaderProps) => {
    const {
      shouldAutoEnableAndDisable,
      disableUpdateBeamAI,
      isBotChecked,
      setIsBotChecked,
    } = useBeamMeetingAIConfiguration(data);

    const {targetHour, targetMinute} = useMemo(
      () => getTime(data?.time || ''),
      [data?.time],
    );
    const [isTogglingBeamAI, setIsTogglingBeamAI] = useState(false);

    const [isTooltipFilter, setIsTooltipFilter] = useState(false);

    const getTimeZone = useMemo(
      () =>
        timezones.find(
          (timezone) => timezone.value === authStore.auth.user.timezone,
        ),
      [],
    );

    const avatarParticipant = useMemo(
      () =>
        filterDuplicateItems(
          data.extraParticipant
            ? [
                ...participants,
                ...data.emailParticipant.map((email) => ({
                  name: email,
                  src: '',
                  id: email,
                })),
                ...data.extraParticipant
                  .filter(
                    (participant) =>
                      !allParticipants
                        ?.map((user) => user.email)
                        .includes(participant.email),
                  )
                  .map((participant) => ({
                    name: participant.email,
                    src: '',
                    id: participant.email,
                  })),
              ]
            : [
                ...participants,
                ...data.emailParticipant.map((email) => ({
                  name: email,
                  src: '',
                  id: email,
                })),
              ],
          'id',
        ),
      [
        allParticipants,
        data.emailParticipant,
        data.extraParticipant,
        participants,
      ],
    );

    const updatedDate = useMemo(
      () =>
        dayjs(data.startDate)
          .set('hour', targetHour)
          .set('minute', targetMinute)
          .tz(getTimeZone?.label || '', true),
      [data.startDate, getTimeZone?.label, targetHour, targetMinute],
    );

    const [showDetails, setShowDetails] = useState(false);

    const event = data.googleEventDetails;

    const canShowInfo = !!data.calendarId;

    const isAttendee = event?.attendees?.find(
      (a) => a.email === connectedGoogleEmail,
    );

    const showEndDate =
      data.endDate && dayjs(data.endDate).diff(dayjs(updatedDate), 'day') > 0;

    const isCreator = event?.creator?.email === connectedGoogleEmail;
    const disableBeamAitoggle =
      disableUpdateBeamAI || !data.googleEventDetails?.hangoutLink;

    const isRecurring = data.recurrence?.formatted;
    const handleUpdateBeamAI = useCallback(async () => {
      try {
        setIsTogglingBeamAI(true);
        await handleAddBeamAi(
          data.googleEventDetails?.hangoutLink || '',
          isBotChecked,
          setIsBotChecked,
        );
      } catch (error) {
      } finally {
        setIsTogglingBeamAI(false);
      }
    }, [
      data.googleEventDetails?.hangoutLink,
      handleAddBeamAi,
      isBotChecked,
      setIsBotChecked,
    ]);

    return (
      <div>
        <ResponsiveFlexBetween className="w-full">
          <div className="w-full">
            <div className="p-4 mt-4 border border-borderLight rounded-[10px]  w-full">
              <div className=" flex flex-row sm:flex-col sm:items-start items-center  justify-between gap-2">
                <div className="gap-4 flex  items-start flex-wrap ">
                  <div className="flex items-center min-w-fit  border border-borderDark rounded-[10px] py-3 px-4 gap-3 ">
                    <Body2 kind="textBody">
                      {dayjs(data.startDate).format("ddd. D MMM. 'YY")}{' '}
                      {showEndDate &&
                        `- ${dayjs(data.endDate).format('ddd. D MMM.')} `}
                    </Body2>

                    {data?.time &&
                      !isMeetingNote &&
                      !data?.title?.includes('Notes with') && (
                        <>
                          <CustomColorIcon
                            height="8px"
                            width="8px"
                            margin={0}
                            color="#D9D9D9"
                          />
                          <Body2 kind="textBody">
                            {' '}
                            {dayjs(updatedDate).format('hh:mm a')}{' '}
                            {data?.endDate
                              ? `- ${dayjs(data.endDate).format('hh:mm a')}`
                              : null}
                          </Body2>
                        </>
                      )}

                    {isRecurring && (
                      <>
                        <RecurringMeeting />
                      </>
                    )}
                  </div>

                  {canShowInfo && (
                    <FlexRow className="border border-borderDark rounded-[10px] py-[10px] px-4 gap-2">
                      {isTogglingBeamAI && (
                        <RotatingLines width="16" strokeColor="#47B881" />
                      )}

                      {shouldAutoEnableAndDisable ? (
                        <CustomColorIcon
                          color="#47B881"
                          width="12.8px"
                          height="12.8px"
                          margin={0}
                        />
                      ) : (
                        <ToggleSwitch
                          checked={isBotChecked}
                          onChange={handleUpdateBeamAI}
                          switchId={data?.id || (nextId() as any)}
                          disabled={disableBeamAitoggle}
                        />
                      )}

                      <CTA>
                        {shouldAutoEnableAndDisable
                          ? 'BeamAI will join'
                          : 'Invite BeamAI to join'}
                      </CTA>
                    </FlexRow>
                  )}

                  <div className="bg-[#FAFAFA] py-[10px] flex items-center gap-3 px-4 border border-[#F6F6F8] rounded-[10px] relative">
                    <div
                      onMouseEnter={() =>
                        setIsTooltipFilter(isTooltipFilter ? false : true)
                      }
                      onMouseLeave={() => setIsTooltipFilter(false)}>
                      <Avatars
                        showCount={4}
                        tooltip={false}
                        items={avatarParticipant}
                        total={avatarParticipant?.length}
                      />
                    </div>

                    <button onClick={handleShareMeeting}>
                      <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.47563 6.00372C5.92209 6.00372 7.09468 4.83113 7.09468 3.38467C7.09468 1.93821 5.92209 0.765625 4.47563 0.765625C3.02917 0.765625 1.85658 1.93821 1.85658 3.38467C1.85658 4.83113 3.02917 6.00372 4.47563 6.00372ZM4.47563 5.21801C5.48815 5.21801 6.30897 4.39719 6.30897 3.38467C6.30897 2.37215 5.48815 1.55134 4.47563 1.55134C3.46311 1.55134 2.6423 2.37215 2.6423 3.38467C2.6423 4.39719 3.46311 5.21801 4.47563 5.21801Z"
                          fill="#5F5F8C"
                        />
                        <path
                          d="M8.66611 4.03943C8.88308 4.03943 9.05897 4.21532 9.05897 4.43229V5.08705H9.71373C9.9307 5.08705 10.1066 5.26294 10.1066 5.47991C10.1066 5.69688 9.9307 5.87277 9.71373 5.87277H9.05897V6.52753C9.05897 6.7445 8.88308 6.92039 8.66611 6.92039C8.44914 6.92039 8.27325 6.7445 8.27325 6.52753V5.87277H7.61849C7.40152 5.87277 7.22563 5.69688 7.22563 5.47991C7.22563 5.26294 7.40152 5.08705 7.61849 5.08705H8.27325V4.43229C8.27325 4.21532 8.44914 4.03943 8.66611 4.03943Z"
                          fill="#5F5F8C"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.66611 8.88467C8.66611 10.1865 6.78997 11.2418 4.47563 11.2418C2.1613 11.2418 0.285156 10.1865 0.285156 8.88467C0.285156 7.58286 2.1613 6.52753 4.47563 6.52753C6.78997 6.52753 8.66611 7.58286 8.66611 8.88467ZM7.88039 8.88467C7.88039 9.12565 7.69846 9.50385 7.05354 9.86661C6.43325 10.2155 5.52342 10.4561 4.47563 10.4561C3.42785 10.4561 2.51802 10.2155 1.89772 9.86661C1.2528 9.50385 1.07087 9.12565 1.07087 8.88467C1.07087 8.64369 1.2528 8.2655 1.89772 7.90273C2.51802 7.55381 3.42785 7.31324 4.47563 7.31324C5.52342 7.31324 6.43325 7.55381 7.05354 7.90273C7.69846 8.2655 7.88039 8.64369 7.88039 8.88467Z"
                          fill="#5F5F8C"
                        />
                      </svg>
                    </button>

                    {isTooltipFilter && (
                      <div className="absolute z-10 gap-2 flex flex-col bg-[#3A4050] rounded-[4px] min-w-[160px] bottom-full py-4 px-6 border border-[#CECEDE]">
                        {avatarParticipant.map((participant) => (
                          <div key={participant.id} className="">
                            <Body2 className="text-[12px]" kind="white">
                              {participant.name}
                            </Body2>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <DropdownVertical
                  collapseOnClick
                  dropdownWrapperStyle={{
                    top: '130%',
                    right: '0px',
                    width: '250px',
                  }}
                  menu={(handleClose: () => void) => (
                    <>
                      {!canShowInfo && (
                        <DropdownItem
                          onClick={() => {
                            handleDeleteNote();

                            handleClose();
                          }}>
                          Delete Note
                        </DropdownItem>
                      )}

                      {canShowInfo && isCreator && (
                        <DropdownItem
                          onClick={() => {
                            setOpenRescheduleMeeting(true);

                            handleClose();
                          }}>
                          Edit Event
                        </DropdownItem>
                      )}

                      <DropdownItem
                        onClick={() => {
                          handleSeriesSettings?.();

                          handleClose();
                        }}>
                        Series Settings
                      </DropdownItem>
                    </>
                  )}
                  customIcon={<MoreVerticalIcon />}
                />
              </div>

              <button
                onClick={() =>
                  !canShowInfo
                    ? handleLinkMeeting()
                    : setShowDetails(!showDetails)
                }
                className="flex items-center text-[#585adf] mt-2">
                <Body2 kind="purple300" weight="bold">
                  {!canShowInfo ? 'Link to a meeting' : 'Show meeting info'}{' '}
                </Body2>
                <FiChevronDown
                  style={{
                    margin: '0px 5px 0px 0px',
                    rotate: !canShowInfo
                      ? '-90deg'
                      : showDetails
                      ? '180deg'
                      : '0deg',
                  }}
                  aria-hidden="true"
                />
              </button>

              {showDetails && event && (
                <div className="space-y-4 text-gray-700 mt-4">
                  {Boolean(data.recurrence?.formatted) && (
                    <Body2
                      className=""
                      kind="textBody"
                      style={{fontSize: '12px'}}>
                      {data.recurrence?.formatted}
                    </Body2>
                  )}
                  {event?.conferenceData && (
                    <div className="flex items-start space-x-2">
                      {/* <Video size={16} className="mt-1" /> */}
                      <div>
                        <Body2 weight="bold">Join video call</Body2>
                        <a href={event.hangoutLink} className="mt-2 break-all">
                          <Body2 kind="purple300">
                            {event.conferenceData.entryPoints[0].label}
                          </Body2>
                        </a>
                      </div>
                    </div>
                  )}

                  {event?.conferenceData?.entryPoints?.find(
                    (ep) => ep.entryPointType === 'phone',
                  ) && (
                    <div className="flex items-start space-x-2">
                      {/* <Phone size={16} className="mt-1" /> */}
                      <div>
                        <Body2 weight="bold">Join by phone</Body2>
                        <div className="text-sm">
                          {
                            event.conferenceData.entryPoints.find(
                              (ep) => ep.entryPointType === 'phone',
                            )?.label
                          }
                        </div>
                        {event.conferenceData.conferenceId && (
                          <div className="text-sm">
                            PIN: {event.conferenceData.conferenceId}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="flex items-start space-x-2">
                    {/* <Users size={16} className="mt-1" /> */}
                    <div>
                      <Body2 weight="bold">
                        {event.attendees?.length || 0} guests
                      </Body2>
                      <Body2 className="text-xs" kind="textBody">
                        {event.attendees?.filter(
                          (a) => a.responseStatus === 'accepted',
                        ).length || 0}{' '}
                        yes,{'  '}
                        {event.attendees?.filter(
                          (a) => a.responseStatus === 'needsAction',
                        ).length || 0}{' '}
                        awaiting
                      </Body2>
                    </div>
                  </div>

                  {!!isAttendee && (
                    <div>
                      <MeetingRSVP
                        calendarId={data.calendarId}
                        attendees={data.googleEventDetails?.attendees}
                        connectedEmail={connectedGoogleEmail}
                        initialStatus={
                          (isAttendee.responseStatus as any) ||
                          ('needsAction' as const)
                        }
                        onStatusChange={() => {}}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </ResponsiveFlexBetween>

        {isManagerView && data.type !== 'team' && (
          <>
            <VerticalSpacer size="24px" />
            <ManagerViewUser className="bg-white">
              <ResponsiveFlexStart>
                <ResponsiveFlexRow>
                  <FlexRow className="first-child">
                    <Avatar
                      src=""
                      name={userName(reviewee)}
                      isUserDeleted={!!reviewee?.deletedAt}
                      userId={reviewee?.id || ''}
                      tooltip={true}
                    />
                    <HorizontalSpacer size="16px" />
                    <Body1 kind="textDark" weight="bold">
                      {reviewee?.firstName}`s growth journey
                    </Body1>
                  </FlexRow>
                </ResponsiveFlexRow>

                <DropdownCustom
                  collapseOnClick={true}
                  menu={() => (
                    <>
                      {managerViewUserOptions.map((options, idx) => (
                        <DropdownItem
                          key={idx}
                          onClick={() => {
                            setShowViewUser(options.value as any);
                          }}
                          style={{marginRight: '12px'}}>
                          {options.label}
                        </DropdownItem>
                      ))}
                    </>
                  )}
                  customComponent={(
                    handleOpen: (event: any) => void,
                    open: boolean,
                  ) => (
                    <>
                      <Button
                        kind="secondary"
                        onClick={(event) => {
                          handleOpen(event as any);
                        }}
                        style={{padding: '8px 16px'}}>
                        <FlexRowSpaceBetween style={{width: '100%'}}>
                          <Body2 weight="bold">View history</Body2>

                          <ArrowHeadDownIcon
                            style={{
                              marginLeft: 14,
                              stroke: 'black',
                              transform: !open
                                ? 'rotate(0deg)'
                                : 'rotate(180deg)',
                            }}
                          />
                        </FlexRowSpaceBetween>
                      </Button>
                    </>
                  )}
                  customDropdownWrapperStyles={{
                    top: '130%',
                    right: 0,
                    width: '100%',
                  }}
                />
              </ResponsiveFlexStart>
            </ManagerViewUser>

            {(isMeetingUpcoming || isMeetingActive) && (
              <>
                <VerticalSpacer size="24px" />
                <AveragePulse userId={reviewee?.id || ''} />
                <VerticalSpacer size="24px" />

                <GoalsProgress userId={reviewee?.id || ''} />
              </>
            )}
          </>
        )}
      </div>
    );
  },
);
