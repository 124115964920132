import {filterArrayByWeek} from '@utils/date';
import {useCheckinsCalendar} from '@hooks/checkins-calendar';
import {CompanyCheckinsRequestImpl} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.request';
import {CompanyCheckinsController} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.controller';
import {useState, useCallback} from 'react';
import {useStoreContext} from '@store/store-context';
import {AnalyticsController} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/admin-analytics.request';
import {
  ICheckinsQuestionResponseData,
  ICheckinsPrioritiesResponseData,
  ICheckinsPulseResponseData,
} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.interface';

interface ICheckinResponse {
  params?: string[];
  feedback?: null;
  year?: number;
  isActive?: boolean;
  isReviewed?: boolean;
  dueDay?: string;
  weekOfYear?: number;
  workspace?: string;
  user?: string;
  id?: string;
  responses: {
    questions: ICheckinsQuestionResponseData;
    priorities: ICheckinsPrioritiesResponseData;
    pulse: ICheckinsPulseResponseData;
  };
}
export const useCheckinsSearch = (
  date: {starts: string; ends: string},
  user: string,
) => {
  const calendarData = useCheckinsCalendar();
  const [loadingCheckins, setLoadingCheckins] = useState(false);

  const [checkinsOptions, setCheckinsOptions] = useState<
    {value: string; label: string}[]
  >();

  const {
    groupStore: {groups},
  } = useStoreContext();
  const fetchCheckinWeekList = useCallback(async () => {
    const request = new CompanyCheckinsRequestImpl();
    const controller = new CompanyCheckinsController(request);

    const response = await controller.getCheckinsCalendarWeekList({
      user,
    });
    if (response) {
      const formatResponse = filterArrayByWeek(
        response,
        date.starts,
        date.ends,
      );

      const activeCalWeeks = calendarData.currentMonthWeeks
        .map((week) => {
          if (
            formatResponse
              ?.map((calWk: any) => calWk.year)
              .includes(week?.year) &&
            formatResponse
              ?.map((calWk: any) => calWk.weekOfYear)
              .includes(week?.weekOfYear)
          ) {
            return {...week, isCheckins: true};
          } else {
            return week;
          }
        })
        .filter((week: any) => week?.isCheckins === true)
        .map((week: any) => {
          return {
            ...week,
            value: week.weekOfYear,
            label: week.title,
          };
        });
      setCheckinsOptions(activeCalWeeks);
    }
  }, [user, date.starts, date.ends, calendarData.currentMonthWeeks]);

  const [checkinInfo, setCheckinInfo] = useState<ICheckinResponse>();

  const initWorkspaceCheckins = useCallback(
    async (filter?: {weekOfYear?: number; year?: number}) => {
      setLoadingCheckins(true);
      const request = new CompanyCheckinsRequestImpl();
      const controller = new CompanyCheckinsController(request);

      const response = await controller.getCurrentCheckinsInfo(filter);
      response && setCheckinInfo(response as any);
      setLoadingCheckins(false);

      return response;
    },
    [],
  );
  const [loadingGoals, setLoadingGoals] = useState(false);
  const [checkinGoals, setCheckinGoals] = useState([]);

  const initCheckinGoals = useCallback(
    async (filter?: any) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);
      setLoadingGoals(true);
      const response = await controller.fetchSpecifiedGoals(filter, '', true);

      const includeGroups = async (goalsResponse: any) => {
        const groupsIncluded =
          goalsResponse &&
          goalsResponse?.map((goal: any) => {
            if (goal.goalType === 'group') {
              const group = goal.group.map((grp: string) => {
                return groups.find((_group) => _group.id === grp);
              });

              return {
                ...goal,
                group,
              };
            } else
              return {
                ...goal,
              };
          });
        return groupsIncluded;
      };

      if (response.goals) {
        const unfurledData = await includeGroups(response?.goals);
        setLoadingGoals(false);

        setCheckinGoals(unfurledData);
        return unfurledData;
      }
      setLoadingGoals(false);
    },
    [groups],
  );

  return {
    initWorkspaceCheckins,
    checkinInfo,
    initCheckinGoals,
    loadingCheckins,
    loadingGoals,
    checkinGoals,
    checkinsOptions,
    fetchCheckinWeekList,
  };
};
