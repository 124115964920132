import {memo, useCallback, useMemo, Fragment} from 'react';
import {VerticalSpacer} from '../../../atoms/spacer';
import {Wrapper, Container, CurrentWorkspaceWrapper} from './styles';
import {useStoreContext} from '../../../../store/store-context';
import {CurrentWorkspace} from '../current';
import {NavLinks} from '../../nav-links';
import {useNavigate} from 'react-router';

interface Workspace {
  name: string;
  email: string;
  link: string;
  logo?: string;
  id: string;
}

export interface WorkspaceDropdownProps {
  workspaces: Workspace[];
  workspace: Workspace;
  onLogout: () => void;
  onLogoutAll: () => void;
  onOpenModal: () => void;
  onClose: () => void;
  isAdmin: boolean;
  isInviteDisabled?: boolean;
}

export const WorkspaceDropdown = memo(function ({
  workspace,
  onClose,
  onOpenModal,
  isAdmin,
}: WorkspaceDropdownProps) {
  const navigate = useNavigate();

  //TODO: This Block commented out because not required in MVP. Remove requested. Would uncomment as WIP for full version.
  // const handleLogout = useCallback(() => {
  //   onLogout();
  //   onClose();
  // }, [onClose, onLogout]);
  const {
    storeDashboardRoutes: {activateWorkspaceTab},
  } = useStoreContext();

  const handleWorkspaceSettings = useCallback(() => {
    sessionStorage.setItem('prevurl', window.location.pathname);
    navigate('/workspace-settings/profile');
    activateWorkspaceTab('profile');
    onClose();
  }, [navigate, onClose, activateWorkspaceTab]);

  const handleInviteMember = useCallback(() => {
    onOpenModal();
    onClose();
  }, [onClose, onOpenModal]);

  const links = useMemo(() => {
    const workspaceNav: any = [
      {
        id: 'invite-member',
        title: `Invite people to ${workspace.name}`,
        onClick: handleInviteMember,
        icon: <></>,
      },
    ];
    if (isAdmin) {
      workspaceNav.push({
        id: 'workspace-settings',
        title: 'Workspace settings',
        onClick: handleWorkspaceSettings,
      });
    }
    return workspaceNav;
  }, [handleInviteMember, handleWorkspaceSettings, isAdmin, workspace.name]);

  //TODO: This Block commented out because not required in MVP. Remove requested. Would uncomment as WIP for full version.
  // const footerNavLinks = useMemo(() => {
  //   return [
  //     { // TODO: WIP for Full Version. Remove requested for MVP.
  //       id: 'switch-space',
  //       title: 'Switch company spaces',
  //       item: (
  //         <WorkspacesDropdown workspaces={workspaces} addNewAccountLink="#" />
  //       ),
  //     },
  //     {
  //       id: 'log-out-me',
  //       title: `Log out of ${workspace.name}`,
  //       onClick: handleLogout,
  //     },
  //     {
  //       id: 'log-out-all',
  //       title: 'Log out of all accounts',
  //       onClick: onLogoutAll,
  //     },
  //   ];
  // }, [workspaces, workspace.name, handleLogout, onLogoutAll]);

  const renderItem = useMemo(() => {
    return (
      <Fragment>
        <CurrentWorkspaceWrapper>
          <CurrentWorkspace
            email={workspace.email}
            logo={workspace.logo}
            name={workspace.name}
          />
        </CurrentWorkspaceWrapper>
        <VerticalSpacer size="12px" />
        <NavLinks items={links} />
      </Fragment>
    );
  }, [workspace, links]);

  return (
    <Wrapper>
      <Container>{renderItem}</Container>
      {/* TODO: This Block commented out because not required in MVP. Remove requested. Would uncomment as WIP for full version. */}
      {/* <Footer>
        <NavLinks items={footerNavLinks} />
      </Footer> */}
    </Wrapper>
  );
});
WorkspaceDropdown.displayName = 'WorkspaceDropdown';
