import {observer} from 'mobx-react-lite';
import {
  DelimitingWrapper,
  OuterWrapper,
  Wrapper,
  ButtonWrapper,
  MoodDiv,
  ProgressDiv,
  PrioritiesDiv,
  WorkLifeDiv,
  FeedbackDiv,
  ItemLoaderWrapper,
  MobileWrapper,
  ReviewerBoxMobile,
  EmptyDiv,
} from './you-checkins.styles';
import {Body2, CTA, Headline3, Body1} from '../../../../../ui/atoms/typography';
import {useCallback, useState} from 'react';
import {Button} from '../../../../../ui/atoms/button';
import {HorizontalSpacer, VerticalSpacer} from '../../../../../ui/atoms/spacer';
import {Image} from '../../../../../ui/atoms/image';
import {MultiField} from '../../../../../ui/molecules/field/multifield';
import {TextField} from '../../../../../ui/molecules/field/textfield';
import {YouHightLight} from '../you-hightligts';
import {useYouHook} from '../../you-hook';
import {emojiList, IEmoji} from '../../../../../ui/assets/emojis';
import {UseCheckinGoals} from './you-checkins-goals';
import {TextTag2} from '../../../../../ui/atoms/tag';
import {useYouCheckinsHook} from './you-checkins.hooks';
import {Controller} from 'react-hook-form';
import {useStoreContext} from '../../../../../store/store-context';
import {ItemLoader} from '../../../../../ui/organisms/item-loader';
import {YouCalendar} from '../../../company-legacy/shared-components/calendars/calendar-you-variant';
import {Avatar} from '../../../../../ui/atoms/avatar';
import {EmptyPlaceholder} from '../../../../../ui/atoms/empty-placeholder';
import {cloudinary} from '../../../../../assets/images/cloudinary';
import {Dropdown} from '../../../../../ui/molecules/dropdown';
import {
  dateParamsToday,
  getDayOfWeekIndexFromString,
  getWeekOfYear,
} from '../../../../../utils/date';
import {Helper} from '../../../../../ui/atoms/helper';
import {checkinName} from '@utils/checkin-name';

interface IYouCheckins {}
export const parseName = (data: any) => {
  return data?.firstName && data?.lastName
    ? `${data?.firstName} ${data?.lastName}`
    : data?.email;
};

// eslint-disable-next-line no-empty-pattern
export const YouCheckins = observer(({}: IYouCheckins) => {
  useYouHook();
  const {
    handleSubmit,
    control,
    isSubmitting,
    submitForm,
    debounceUpdatePulse,
    debounceUpdatePriorities,
    debounceUpdateQuestions,
    handleFormValueChange,
    watch,
    loadingPage,
    isReadOnly,
    setIsReadOnly,
  } = useYouCheckinsHook();

  const {loadingGoals} = UseCheckinGoals();

  const {
    authStore,
    usersStore: {users},
    groupStore: {allGroups},
    checkinsStore: {
      workspaceCheckinsGoals,
      workspaceCheckins,
      setCheckinsDate,
      configuration,
      requiredUserQuestions,
      updateRequiredUserQuestionsError,
      pulseValidation,
      updatePulseValidationError,
    },
  } = useStoreContext();

  // 01. User's Mood - DONE 100%
  const MoodBox = useCallback(
    ({
      value,
    }: {
      value: {
        mood: string;
        feedback: string;
        id: string;
      };
    }) => {
      const [mood, setMood] = useState<null | string>(value?.mood);
      const handleMood = (mood_: string, pulseId: string) => {
        if (!isReadOnly) {
          if (mood !== null && mood === mood_.toLowerCase()) {
            setMood(null);

            handleFormValueChange({...value, mood: null}, 'pulse');
          } else {
            updatePulseValidationError(null);
            debounceUpdatePulse({
              pulse: {...value, mood: mood_.toLowerCase()},
              pulseId: value.id,
            });
            setMood(mood_.toLowerCase());
            handleFormValueChange(
              {...value, mood: mood_.toLowerCase()},
              'pulse',
            );
          }
        }
      };
      const moods = [
        {
          emoji: emojiList.drained,
          text: 'Drained',
        },
        {
          emoji: emojiList.sad,
          text: 'Sad',
        },
        {
          emoji: emojiList.meh,
          text: 'Meh!',
        },
        {
          emoji: emojiList.happy,
          text: 'Happy',
        },
        {
          emoji: emojiList.elated,
          text: 'Elated',
        },
      ];
      return (
        <>
          <MoodDiv activated={mood !== null} error={pulseValidation?.error}>
            <div className="titleDiv">
              <Headline3 kind="textDark">How was your week?</Headline3>
            </div>
            <div className="contentDiv">
              <div className="inner-content">
                {moods.map((mood__: {emoji: IEmoji; text: string}, index) => (
                  <div
                    key={index + mood__.text}
                    className="emoji"
                    onClick={() => handleMood(mood__.text, '')}>
                    <div
                      className="halo"
                      style={{
                        background:
                          mood !== null && mood__.text.toLowerCase() === mood
                            ? '#585ADF'
                            : undefined,
                      }}>
                      <div className="img">
                        <Image {...mood__.emoji} />
                      </div>
                    </div>
                    <CTA kind="textMedium">{mood__.text}</CTA>
                  </div>
                ))}
              </div>
              {mood !== null && mood !== undefined && (
                <>
                  <VerticalSpacer size="24px" />
                  <div className="second-inner-content">
                    <TextField
                      placeholder="Share you more information about your mood here."
                      label={`${
                        ['elated', 'happy'].includes(mood as any)
                          ? 'Yay 🎉 '
                          : ''
                      }What made you ${mood} this week?`}
                      value={value.feedback}
                      onChange={(e) => {
                        debounceUpdatePulse({
                          pulse: {
                            ...value,
                            feedback: e?.target?.value,
                          },
                          pulseId: value.id,
                        });

                        handleFormValueChange(
                          {...value, feedback: e?.target?.value},
                          'pulse',
                        );
                      }}
                      name="pulse.feedback"
                      key="feedback"
                      type="text"
                      readOnly={isReadOnly}
                      disabled={workspaceCheckins?.isReviewed || isReadOnly}
                    />
                  </div>
                </>
              )}
            </div>
          </MoodDiv>
          <Helper
            aria-label="helper"
            state={pulseValidation?.error ? 'error' : 'default'}
            children={pulseValidation?.error || ''}
          />
        </>
      );
    },
    [
      debounceUpdatePulse,
      handleFormValueChange,
      isReadOnly,
      pulseValidation?.error,
      updatePulseValidationError,
      workspaceCheckins?.isReviewed,
    ],
  );

  // 02. User's Progress -
  const ProgressOnObjectives = useCallback(() => {
    return (
      <>
        {!loadingGoals && workspaceCheckinsGoals?.length > 0 && (
          <ProgressDiv>
            <div className="titleDiv">
              <Headline3 kind="textDark">
                What’s the progress on your goals?
              </Headline3>
            </div>
            <div className="contentDiv">
              {!loadingGoals && workspaceCheckinsGoals?.length > 0 && (
                <YouHightLight
                  goals={
                    new Map(
                      workspaceCheckinsGoals?.map((item: any) => [
                        item?.id,
                        item,
                      ]),
                    )
                  }
                  kind="youcheckins"
                  youCheckinsKind={isReadOnly ? 'viewuser' : undefined}
                  isFetching={loadingGoals}
                />
              )}
              {loadingGoals && <ItemLoader />}
            </div>
          </ProgressDiv>
        )}
        {!loadingGoals && workspaceCheckinsGoals?.length === 0 && <></>}
      </>
    );
  }, [isReadOnly, loadingGoals, workspaceCheckinsGoals]);

  // 03. User's Priorities - DONE 100%
  const Priorities = useCallback(
    ({
      value,
    }: {
      value: {
        currentCheckin: {
          text?: string;
          completed?: boolean;
        }[];
        nextCheckin: {
          text?: string;
          completed?: boolean;
        }[];
        id: string;
      };
    }) => {
      const [moveStateController, setMoveStateController] = useState(false);

      const handleMoveAndRemove = (
        option: string,
        fieldId: string,
        onHandleRemove: any,
        value: {
          currentCheckin: {
            text?: string;
            completed?: boolean;
          }[];
          nextCheckin: {
            text?: string;
            completed?: boolean;
          }[];
          id: string;
        },
        indexOfFieldId: number,
      ) => {
        switch (option) {
          case 'moveToNext':
            handleFormValueChange(
              {
                ...value,
                nextCheckin: [
                  ...value?.nextCheckin,
                  ...value?.currentCheckin?.filter(
                    (_, index) => index === indexOfFieldId,
                  ),
                ],
                currentCheckin: [
                  ...value?.currentCheckin?.filter(
                    (_, index) => index !== indexOfFieldId,
                  ),
                ],
              },
              'priorities',
            );
            setMoveStateController(!moveStateController);
            debounceUpdatePriorities({
              priorities: {
                ...value,
                nextCheckin: [
                  ...value?.nextCheckin,
                  ...value?.currentCheckin?.filter(
                    (_, index) => index === indexOfFieldId,
                  ),
                ],
                currentCheckin: [
                  ...value?.currentCheckin?.filter(
                    (_, index) => index !== indexOfFieldId,
                  ),
                ],
              },
              prioritiesId: value.id,
            });
            break;
          case 'removeCurrent':
            onHandleRemove();

            debounceUpdatePriorities({
              priorities: {
                ...value,
                currentCheckin: [
                  ...value?.currentCheckin?.filter(
                    (_, index) => index !== indexOfFieldId,
                  ),
                ],
              },
              prioritiesId: value.id,
            });
            break;
          case 'removeNext':
            onHandleRemove();

            debounceUpdatePriorities({
              priorities: {
                ...value,
                nextCheckin: [
                  ...value?.nextCheckin?.filter(
                    (_, index) => index !== indexOfFieldId,
                  ),
                ],
              },
              prioritiesId: value.id,
            });
            break;
          default:
        }
      };

      const CurrentCheckinsPriorities = useCallback(
        ({value}) => (
          <MultiField
            checkedIndexes={[0]}
            label="Check off what you’ve achieved"
            value={value?.currentCheckin}
            updateValue={(value_?: {text?: string; completed?: boolean}[]) => {
              handleFormValueChange(
                {...value, currentCheckin: value_},
                'priorities',
              );
              debounceUpdatePriorities({
                priorities: {...value, currentCheckin: value_},
                prioritiesId: value.id,
              });
            }}
            id=""
            fontWeight={400}
            colorString="textMedium"
            variant="youcheckins"
            readonly={isReadOnly}
            disabled={workspaceCheckins?.isReviewed || isReadOnly}
            dropdownComponent={(
              onHandleRemove: () => void,
              fieldId: string,
              indexOfFieldId: number,
            ) => (
              <Dropdown
                dropdownItemStyle={{whiteSpace: 'nowrap'}}
                menu={[
                  {
                    content: `Move to next check-in`,
                    id: 'moveToNext',
                  },
                  {
                    content: `Delete`,
                    id: 'removeCurrent',
                  },
                ]}
                onClick={(option) => {
                  handleMoveAndRemove(
                    option,
                    fieldId,
                    onHandleRemove,
                    value,
                    indexOfFieldId,
                  );
                }}
                specificIndexDropdownItemStyles={[
                  {index: 1, style: {color: '#D52A2A'}},
                ]}
              />
            )}
          />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [moveStateController, isReadOnly, workspaceCheckins?.isReviewed],
      );

      const NextCheckinsPriorities = useCallback(
        ({value}) => (
          <MultiField
            checkedIndexes={[0]}
            showCheckbox={true}
            label={`List what you intend to achieve by next  ${checkinName()}`}
            value={value?.nextCheckin}
            updateValue={(value_?: {text?: string; completed?: boolean}[]) => {
              handleFormValueChange(
                {...value, nextCheckin: value_},
                'priorities',
              );
              debounceUpdatePriorities({
                priorities: {...value, nextCheckin: value_},
                prioritiesId: value.id,
              });
            }}
            id=""
            fontWeight={400}
            colorString="textMedium"
            variant="youcheckins"
            disabled={workspaceCheckins?.isReviewed || isReadOnly}
            readonly={isReadOnly}
            dropdownComponent={(
              onHandleRemove: () => void,
              fieldId: string,
              indexOfFieldId: number,
            ) => (
              <Dropdown
                dropdownItemStyle={{whiteSpace: 'nowrap'}}
                menu={[
                  {
                    content: `Delete`,
                    id: 'removeNext',
                  },
                ]}
                onClick={(option) => {
                  handleMoveAndRemove(
                    option,
                    fieldId,
                    onHandleRemove,
                    value,
                    indexOfFieldId,
                  );
                }}
                specificIndexDropdownItemStyles={[
                  {index: 0, style: {color: '#D52A2A'}},
                ]}
              />
            )}
          />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [moveStateController, isReadOnly, workspaceCheckins?.isReviewed],
      );
      return (
        <PrioritiesDiv>
          <div className="titleDiv">
            <Headline3 kind="textDark">
              How are you planning your priorities?
            </Headline3>
          </div>
          <div className="contentDiv">
            <CurrentCheckinsPriorities value={value} />
            <NextCheckinsPriorities value={value} />
          </div>
        </PrioritiesDiv>
      );
    },
    [
      debounceUpdatePriorities,
      handleFormValueChange,
      isReadOnly,
      workspaceCheckins?.isReviewed,
    ],
  );

  // 04. More about User's work life
  const MoreAboutWorkLife = useCallback(
    ({
      value,
    }: {
      value: {
        questions: {
          question: string;
          questionId: string;
          response?: null | string;
          target: {type: string; ids: string[]};
        }[];
        id: string;
      };
    }) => {
      const parseTarget = (target: {type: string; ids: string[]}) => {
        switch (target.type) {
          case 'individual':
            return 'Individual';
          case 'group':
            return `Group: ${target.ids
              ?.map((id: string) => allGroups.get(id)?.name)
              .join(', ')}`;
          default:
            return 'Company-wide';
        }
      };
      return (
        <>
          {value?.questions?.length > 0 && (
            <WorkLifeDiv>
              <div className="titleDiv">
                <Headline3 kind="textDark">
                  Share more information about your work-life?
                </Headline3>
                <span />
              </div>
              <div className="contentDiv">
                {value?.questions?.map((question, index) => (
                  <div key={question?.question + index}>
                    <TextTag2>{parseTarget(question?.target)}</TextTag2>
                    <VerticalSpacer size="8px" />
                    <TextField
                      placeholder={
                        workspaceCheckins?.isReviewed ||
                        workspaceCheckins?.isSubmitted
                          ? ''
                          : 'Your response here'
                      }
                      label={
                        (
                          <div>
                            <div style={{display: 'flex'}}>
                              {' '}
                              <Body2
                                style={{fontWeight: 600}}
                                kind="textDark">{`${question?.question} `}</Body2>
                              <HorizontalSpacer size="8px" />
                              <Body2 kind="textBody">(optional)</Body2>
                            </div>
                            <VerticalSpacer size="8px" />
                          </div>
                        ) as any
                      }
                      value={question?.response as string | undefined}
                      readOnly={isReadOnly}
                      disabled={workspaceCheckins?.isReviewed || isReadOnly}
                      helper={
                        requiredUserQuestions &&
                        requiredUserQuestions?.size > 0 &&
                        requiredUserQuestions?.get(question.questionId)?.error
                          ?.length > 1
                          ? requiredUserQuestions?.get(question.questionId)
                              ?.error
                          : ''
                      }
                      state={
                        requiredUserQuestions &&
                        requiredUserQuestions?.size > 0 &&
                        requiredUserQuestions?.get(question.questionId)?.error
                          ?.length > 1
                          ? 'error'
                          : 'default'
                      }
                      multiple
                      onChange={(e) => {
                        if (
                          !(question as any)?.optional &&
                          e?.target?.value?.length > 0
                        ) {
                          Array.from(requiredUserQuestions).map((q: any) =>
                            q?.response?.length > 0
                              ? q
                              : updateRequiredUserQuestionsError(q[0], true),
                          );
                        }
                        const parseQ = (
                          questions: {
                            question: string;
                            questionId: string;
                            response?: null | string;
                            target: {type: string; ids: string[]};
                          }[],
                          index: number,
                          response?: null | string,
                        ) => {
                          let newQ = questions;
                          newQ[index].response = response;
                          return newQ;
                        };
                        handleFormValueChange(
                          {
                            ...value,
                            questions: parseQ(
                              value.questions,
                              index,
                              e?.target?.value,
                            ),
                          },
                          'questions',
                        );

                        debounceUpdateQuestions({
                          questions: {
                            ...value,
                            questions: parseQ(
                              value.questions,
                              index,
                              e?.target?.value,
                            ),
                          },
                          questionId: value.id,
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            </WorkLifeDiv>
          )}
        </>
      );
    },
    [
      allGroups,
      debounceUpdateQuestions,
      handleFormValueChange,
      isReadOnly,
      requiredUserQuestions,
      updateRequiredUserQuestionsError,
      workspaceCheckins?.isReviewed,
      workspaceCheckins?.isSubmitted,
    ],
  );

  // 05. Manager Feedback
  const ManagerFeedback = useCallback(
    ({value}: {value: string}) => {
      return (
        <FeedbackDiv>
          <div className="titleDiv">
            <Headline3 kind="textDark">Manager feedback</Headline3>
          </div>
          <div className="contentDiv">
            {watch()?.feedback ? (
              <TextField
                multiple
                placeholder="Add a comment"
                value={value}
                readOnly
                disabled={
                  workspaceCheckins?.isReviewed ||
                  workspaceCheckins?.isSubmitted
                }
              />
            ) : (
              <Body1 kind="textBody"> No feedback given</Body1>
            )}
          </div>
        </FeedbackDiv>
      );
    },
    [workspaceCheckins?.isReviewed, workspaceCheckins?.isSubmitted, watch],
  );

  const noCheckinsData =
    workspaceCheckins?.responses?.questions === null &&
    workspaceCheckins?.responses?.pulse === null &&
    workspaceCheckins?.responses?.priorities === null &&
    (!workspaceCheckins?.responses?.goals ||
      workspaceCheckinsGoals?.length === 0);

  const EmptyResponse = {
    image: {
      alt: 'alt text',
      shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
      src: cloudinary.emptyGoals,
      style: {height: '50%', width: '50%'},
    },
    subtitle: noCheckinsData
      ? 'Checkins active for this week, however relevant checkins sections are disabled'
      : '',
    title: noCheckinsData
      ? 'Checkins active'
      : `You currently have no  ${checkinName()}s due.`,
  };
  return (
    <>
      {!loadingPage && !loadingGoals && (
        <OuterWrapper onSubmit={handleSubmit(submitForm)}>
          <DelimitingWrapper>
            <Wrapper>
              <MobileWrapper>
                <YouCalendar
                  systemType="mobile"
                  startYear={
                    configuration?.createdAt
                      ? new Date(configuration?.createdAt).getFullYear()
                      : new Date().getFullYear()
                  }
                  onClickWeek={(date) => {
                    setCheckinsDate({
                      starts: date.start,
                      ends: date.end,
                      weekOfYear: date.weekOfYear + 1,
                    });
                  }}
                />
                <VerticalSpacer size="32px" />
                <ReviewerBoxMobile>
                  <div className="title">
                    <Body2 kind="textDark" style={{fontWeight: 500}}>
                      You report to:
                    </Body2>
                  </div>
                  <div className="content-div">
                    <Avatar
                      src=""
                      name={parseName(
                        users?.filter(
                          (user) => user.id === authStore?.auth?.user?.reviewer,
                        )[0],
                      )}
                      userId={
                        users?.filter(
                          (user) => user.id === authStore?.auth?.user?.reviewer,
                        )[0]?.id
                      }
                      tooltip={true}
                    />
                    <HorizontalSpacer size="8px" />
                    <div>
                      <Body2 kind="textDark">
                        {parseName(
                          users?.filter(
                            (user) =>
                              user.id === authStore?.auth?.user?.reviewer,
                          )[0],
                        )}
                      </Body2>
                      <VerticalSpacer size="4px" />
                      <Body2 kind="textBody">
                        {(users?.filter(
                          (user) => user.id === authStore?.auth?.user?.reviewer,
                        )[0] as any)?.jobTitle
                          ? (users?.filter(
                              (user) =>
                                user.id === authStore?.auth?.user?.reviewer,
                            )[0] as any)?.jobTitle
                          : 'Owner'}
                      </Body2>
                    </div>
                  </div>
                </ReviewerBoxMobile>

                <VerticalSpacer size="32px" />
              </MobileWrapper>
              {Object.keys(workspaceCheckins).length > 0 && (
                <>
                  {workspaceCheckins?.responses?.pulse !== null && (
                    <>
                      <Controller
                        control={control}
                        name="pulse"
                        render={({value}) => MoodBox({value})}
                      />

                      <VerticalSpacer size="24px" />
                    </>
                  )}
                  {workspaceCheckins?.responses?.goals && (
                    <>
                      <ProgressOnObjectives />
                      <VerticalSpacer size="24px" />
                    </>
                  )}
                  {workspaceCheckins?.responses?.priorities !== null && (
                    <>
                      <Controller
                        control={control}
                        name="priorities"
                        render={({value}) => Priorities({value})}
                      />
                      <VerticalSpacer size="24px" />
                    </>
                  )}
                  {workspaceCheckins?.responses?.questions !== null && (
                    <Controller
                      control={control}
                      name="questions"
                      render={({value}) => MoreAboutWorkLife({value})}
                    />
                  )}
                  {workspaceCheckins?.isReviewed && (
                    <>
                      <VerticalSpacer size="24px" />
                      <Controller
                        control={control}
                        name="feedback"
                        render={({value}) => ManagerFeedback({value})}
                      />
                    </>
                  )}
                </>
              )}
              {(Object.keys(workspaceCheckins).length === 0 ||
                noCheckinsData) && (
                <EmptyDiv>
                  <EmptyPlaceholder {...EmptyResponse} />
                </EmptyDiv>
              )}
            </Wrapper>
          </DelimitingWrapper>
          {!workspaceCheckins?.isReviewed &&
            Object.keys(workspaceCheckins).length > 0 &&
            workspaceCheckins?.year === new Date().getFullYear() &&
            workspaceCheckins?.weekOfYear === getWeekOfYear() &&
            dateParamsToday().dayOfWeekIndex <=
              getDayOfWeekIndexFromString(workspaceCheckins?.dueDay) && (
              <ButtonWrapper>
                {isReadOnly && (
                  <Button
                    width="full"
                    type="button"
                    kind="secondary"
                    onClick={() => setIsReadOnly(false)}
                    //   disabled={}
                  >
                    Edit {checkinName()}s
                  </Button>
                )}
                {!isReadOnly && (
                  <Button
                    width="full"
                    type="submit"
                    //   disabled={}
                    data-form-action={true}
                    isLoading={isSubmitting}
                    aria-busy={isSubmitting}>
                    {workspaceCheckins?.isSubmitted
                      ? 'Save changes'
                      : 'Submit updates'}
                  </Button>
                )}
              </ButtonWrapper>
            )}
        </OuterWrapper>
      )}
      {(loadingPage || loadingGoals) && (
        <ItemLoaderWrapper>
          <ItemLoader />
        </ItemLoaderWrapper>
      )}
    </>
  );
});

YouCheckins.displayName = 'YouCheckins';
