import {useCallback, useEffect, useMemo, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Resolver} from 'react-hook-form';
import {useStoreContext} from '../../../../../../../../store/store-context';
import {groupTypeController} from '../../../../../../admin/admin-components/admin-people/admin-group-type/admin-group-type-controller';
import {groupTypeRequestImpl} from '../../../../../../admin/admin-components/admin-people/admin-group-type/admin-group-type-request';
import {groupController} from '../../../../../../admin/admin-components/admin-people/admin-group/admin-group-controller';
import {groupRequestImpl} from '../../../../../../admin/admin-components/admin-people/admin-group/admin-group-request';
import {
  ICreateQuestionForm,
  ICreateQuestionFormFormFormat,
  QuestionKind,
} from '../../../company-checkins.interface';
import {CreateQuestionsValidatorSchema} from '../../../company-checkins.validator';
import {CompanyCheckinsRequestImpl} from '../../../company-checkins.request';
import {CompanyCheckinsController} from '../../../company-checkins.controller';
import {useNavigate} from 'react-router';
import {parseQuestionFormData} from './create-question.install';
import {checkinName} from '@utils/checkin-name';

export enum FREQUENCY_TYPE {
  ONCE_PER_USER = 'once-per-user',
  ONCE_PER_WORKSPACE = 'once-per-workspace',
  RECURRING = 'recurring',
}
export function useCreateQuestionHook(
  onSubmit?: (data: ICreateQuestionForm) => Promise<void>,
  edit?: {
    mode?: boolean;
    batch?: string | null;
    type?: string | null;
  },
) {
  const request = useMemo(() => new CompanyCheckinsRequestImpl(), []);
  const controller = useMemo(() => new CompanyCheckinsController(request), [
    request,
  ]);
  const navigate = useNavigate();
  const {
    // usersStore,
    groupStore: {setGroupInStore, setGroupTypeNameInStore, setAllGroups},
    groupTypeStore: {groupTypeIdInStore},
    checkinsStore: {questions, setQuestionsTemplates, questionsTemplates},
  } = useStoreContext();
  const [
    isFetching,
    // setIsFetching
  ] = useState(false);
  const [
    editable,
    // setEditable
  ] = useState<ICreateQuestionForm>();
  const [
    isEditingDisabled,
    // setIsEditingDisabled
  ] = useState(true);
  // const {uiUsersFormat, deactivatedUsers} = usersStore;
  const [
    loader,
    // setLoader
  ] = useState<any>({});

  const initGroups = useCallback(async () => {
    const grpTypeRequest = new groupTypeRequestImpl();
    const grpTypeController = new groupTypeController(grpTypeRequest);
    const grpRequest = new groupRequestImpl();
    const grpController = new groupController(grpRequest);
    const grpTypeResponse: any = await grpTypeController.newfetchGroupType();
    const grpResponses: any = Promise.all(
      grpTypeResponse.map(async (grpType: any) => {
        const grpResponse: any = await grpController.newfetchGroup(grpType.id);
        grpResponse && setAllGroups(grpResponse.groups, grpType.name);
        return grpResponse.groups;
      }),
    );

    return grpResponses;
  }, [setAllGroups]);

  useEffect(() => {
    initGroups();
  }, [initGroups]);

  const FREQUENCY_OPTIONS = [
    {
      value: FREQUENCY_TYPE.ONCE_PER_USER,
      label: 'One time only',
    },
    {
      value: FREQUENCY_TYPE.RECURRING,
      label: `Repeats every ${checkinName()}`,
    },
  ];

  ////// 02. CREATE QUESTIONS FORM START
  const parseEditValues = useCallback(() => {
    if (edit?.mode && !!edit?.type && !!edit?.batch) {
      const batch__ = (questions as any).filter(
        (batch: any) => batch.batch === edit?.batch,
      )[0];
      const batchFrequency__ = (questions as any).filter(
        (batch: any) => batch.batch === edit?.batch,
      )[0].questions[0];

      return {
        questions: batch__.questions,
        type:
          batch__.target?.type === 'individual'
            ? QuestionKind.INDIVIDUAL
            : batch__.target?.type === 'group'
            ? QuestionKind.GROUP
            : QuestionKind.GENERAL,
        ids: batch__.target?.ids,
        frequency: batchFrequency__?.frequency,
      };
    }
  }, [edit?.batch, edit?.mode, edit?.type, questions]);

  const {
    register,
    handleSubmit,
    errors,
    formState,
    setValue,
    control,
    watch,
    clearErrors,
  } = useForm<ICreateQuestionFormFormFormat>({
    resolver: yupResolver(
      CreateQuestionsValidatorSchema.createQuestions,
    ) as Resolver<ICreateQuestionFormFormFormat, object>,
    mode: 'all',
    defaultValues: {
      target: {
        type:
          edit?.mode && !!edit?.type && !!edit?.batch
            ? parseEditValues()?.type
            : QuestionKind.INDIVIDUAL,
        ids:
          edit?.mode && !!edit?.type && !!edit?.batch
            ? {
                individual:
                  parseEditValues()?.type === QuestionKind.INDIVIDUAL
                    ? parseEditValues()?.ids
                    : undefined,
                group:
                  parseEditValues()?.type === QuestionKind.GROUP
                    ? parseEditValues()?.ids
                    : undefined,
              }
            : {
                individual: [],
                group: [],
              },
      },
      frequency:
        edit?.mode && !!edit?.type && !!edit?.batch
          ? parseEditValues()?.frequency
          : FREQUENCY_TYPE.ONCE_PER_USER,
      questions:
        edit?.mode && !!edit?.type && !!edit?.batch
          ? parseEditValues()?.questions
          : [
              {
                type: '',
                text: '',
                optional: true,
              },
            ],
    },
  });

  const submitForm = useCallback(
    (data) => {
      !!onSubmit && onSubmit(data);
    },
    [onSubmit],
  );
  const submitEditedForm = useCallback(
    async (data, callback) => {
      try {
        if (edit?.batch) {
          const response = await controller.updateCheckinBatch(
            parseQuestionFormData({...data, status: 'upcoming'}, true),
            edit?.batch,
          );
          response && callback();
          return response;
        }
      } catch (err: any) {
        throw new Error(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [controller, navigate],
  );

  const isSubmitting = useMemo(() => {
    return formState.isSubmitting;
  }, [formState]);

  const hasError = useMemo(() => {
    return formState.submitCount > 1 && formState.errors;
  }, [formState.errors, formState.submitCount]);

  const handleFormValueChange = useCallback(
    (value: string | {starts: string; ends: string}, name: string) => {
      setValue(name, value);
    },
    [setValue],
  );
  ////// CREATE QUESTION FORM END

  ////// 03.  GROUPS START
  const [isGroup, setIsGroup] = useState(false);
  const [isGroupDisable, setIsGroupDisable] = useState(true);

  const handleSelectGroup = (value: string) => {
    setValue('group', value);
  };
  const handleSelectGroupType = (value: string) => {
    setValue('groupType', value);
  };
  const [isFetchingGroupType, setIsFetchingGroupType] = useState(false);
  const [isFetchingGroup, setIsFetchingGroup] = useState(false);
  const fetchGroupType = useCallback(async () => {
    const request = new groupTypeRequestImpl();
    const controller = new groupTypeController(request);
    setIsFetchingGroupType(true);
    const response: any = await controller.newfetchGroupType();
    response && setIsFetchingGroupType(false);
  }, []);
  useEffect(() => {
    fetchGroupType();
  }, [fetchGroupType]);
  const fetchGroup = useCallback(
    async (groupTypeId: string) => {
      if (groupTypeIdInStore) {
        const request = new groupRequestImpl();
        const controller = new groupController(request);
        setIsFetchingGroup(true);
        const response: any = await controller.newfetchGroup(groupTypeId);
        response && setIsFetchingGroup(false);
        response && setGroupInStore(response);
        response && setGroupTypeNameInStore(response);
      }
    },
    [groupTypeIdInStore, setGroupInStore, setGroupTypeNameInStore],
  );
  useEffect(() => {
    fetchGroup(groupTypeIdInStore);
  }, [fetchGroup, groupTypeIdInStore]);

  const [loadingQuestionTemplates, setLoadingQuestionTemplates] = useState(
    false,
  );

  const questionTypesParse = useCallback(
    (questionsTemplates__) => {
      if (questionsTemplates__?.length > 0) {
        return [
          ...questionsTemplates?.map((q: any) => ({
            type: {
              label: q?.type,
              value: q?.type,
            },
            question: q?.questions?.map((qq: any, index: number) => ({
              label: qq,
              value: index + 1,
            })),
          })),
          {
            type: {
              label: 'Custom question',
              value: 'custom',
            },
            question: [
              {
                label: '',
                value: 1,
              },
            ],
          },
        ];
      } else return [];
    },
    [questionsTemplates],
  );

  const questionTypes = questionTypesParse(questionsTemplates);

  const [questions__, setQuestions__] = useState(questionTypes);
  useEffect(() => {
    if (
      JSON.stringify(questions__) !==
      JSON.stringify(questionTypesParse(questionsTemplates))
    ) {
      setQuestions__(questionTypesParse(questionsTemplates));
    }
  }, [
    questionTypes,
    questionTypesParse,
    questions,
    questionsTemplates,
    questions__,
  ]);
  const fetchQuestionsTemplates = useCallback(
    async (filter: {type?: string; getTypes?: string}) => {
      setLoadingQuestionTemplates(true);
      const response = await controller.getCheckinsQuestionsTemplates(filter);
      response && setQuestionsTemplates(response);
      setLoadingQuestionTemplates(false);
    },
    [controller, setQuestionsTemplates],
  );
  useEffect(() => {
    fetchQuestionsTemplates({});
  }, [fetchQuestionsTemplates]);
  ///// GROUPS END

  return {
    register,
    onSubmit,
    errors,
    isSubmitting,
    submitForm,

    handleSubmit,
    control,
    hasError,
    watch,
    handleFormValueChange,
    isFetching,
    editable,
    isGroup,
    setIsGroup,
    isGroupDisable,
    setIsGroupDisable,
    isFetchingGroup,
    isFetchingGroupType,
    fetchGroup,
    handleSelectGroup,
    handleSelectGroupType,
    setValue,
    isEditingDisabled,
    loader,
    clearErrors,
    submitEditedForm,
    loadingQuestionTemplates,
    questions__,
    FREQUENCY_OPTIONS,
    setQuestions__,
    questionTypes,
  };
}
