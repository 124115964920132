import styled, {css} from 'styled-components';
import {getColorFromTheme} from '../../../ui-utils';
import {ifProp} from 'styled-tools';

export const DropdownWrapper = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  top: 120%;
  border-radius: 8px;
  border: solid 1px ${getColorFromTheme('borderDark')};
  background-color: ${getColorFromTheme('white')};
  box-shadow: ${({theme}) => theme.elevation.level2};
  z-index: 3;
`;

export const DropWrapper = styled.div`
  position: relative;
`;

export const Wrapper = styled.div<{hasLabel?: boolean}>`
  position: relative;
  padding-top: ${({hasLabel}) => (hasLabel ? '26px' : '0')};
`;

export const ArrowIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: ${getColorFromTheme('textBody')};
    width: 20px;
    height: 20px;
  }
`;

export const AvatarWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DropdownHandler = styled.button<{open: boolean}>`
  outline: none;
  border-radius: 10px;
  padding: 5px 14px;
  border-radius: 10px;
  color: ${getColorFromTheme('textDark')};
  width: 100%;
  display: block;
  height: 52px;
  background-color: ${getColorFromTheme('white')};
  transition: 200ms ease all;
  border: 1px solid;
  border-color: ${getColorFromTheme('borderDark')};

  &:focus:not(:invalid) {
    outline: 0;
    border-color: ${getColorFromTheme('purple300')};
  }

  ${ifProp(
    'open',
    css`
      outline: 0;
      border-color: ${getColorFromTheme('purple300')};
    `,
  )};
`;

export const HandlerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
