import {lazy} from 'react';
import {observer} from 'mobx-react';
import {createRootRoutes} from './create-routes';
import {createHomeRoutes} from './home-router';
import {useStoreContext} from '../store/store-context';
import {authStore} from '../store/stores/auth-store';
import {Navigate} from 'react-router';

declare global {
  interface Window {
    dataLayer: any;
    pendo: any;
    gtag_report_conversion: any;
  }
}
const RoutesProvider = lazy(() =>
  import('./routes').then((module) => ({default: module.RoutesProvider})),
);

export const RedirectTo = ({redirectTo}: {redirectTo: string}) => (
  <Navigate to={redirectTo} replace />
);

export const installRoutes = () => {
  const {RootRoutes} = createRootRoutes();
  const {HomeRoutes} = createHomeRoutes();

  const RootRoutesComponent = observer(() => {
    const {auth, getAuthFromLocalStorage} = useStoreContext().authStore;
    if (process.env.REACT_APP_ENABLE_HOTJAR === 'enabled') {
      const {hotjar} = require('react-hotjar');
      const USER_ID = authStore.auth?.user?.id
        ? authStore.auth?.user?.id
        : 'Not logged in.';
      hotjar.identify(USER_ID, {
        user_name:
          authStore.auth?.user?.firstName && authStore.auth?.user?.lastName
            ? `${authStore.auth?.user?.firstName} ${authStore.auth?.user?.lastName}`
            : 'Not logged in.',
        userId: authStore.auth?.user?.id
          ? authStore.auth?.user?.id
          : 'Not logged in.',
        beam_workspace_name: authStore.auth?.user?.workspace?.name
          ? authStore.auth?.user?.workspace?.name
          : 'Not logged in.',
      });
    }
    window.dataLayer.push({
      event: 'event',
      eventProps: {
        category: 'category',
        action: 'action',
        label: 'label',
        value: 'value',
      },
    });

    if (auth === null && localStorage.getItem('auth') !== null) {
      getAuthFromLocalStorage();
    }

    const RenderRoute = auth ? HomeRoutes : RootRoutes;

    return <RenderRoute />;
  });

  const Routes = observer(() => (
    <RoutesProvider Routes={RootRoutesComponent} />
  ));

  return {
    Routes,
  };
};
