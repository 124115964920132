import {useForm, Resolver} from 'react-hook-form';
import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSearchParams} from 'react-router-dom';
import {useGCalenderHook} from '@hooks/gcalender';
import dayjs from 'dayjs';
import {splitTimeFormat} from '@utils/date';
import * as yup from 'yup';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {useStoreContext} from '@store/store-context';
import {MeetingRequestImpl} from '../meeting.request';
import {MeetingController} from '../meeting-controller';

// Updated validator with new fields
const createMeetingValidator = yup.object().shape({
  participant: yup.array().of(yup.string()).required(),
  title: yup.string().required('Title is required'),
  startDate: yup.string().required('Start date is required'),
  isAllDay: yup.boolean(),
  endDate: yup.string().when('isAllDay', {
    is: true,
    then: (schema) => schema.required('End date is required'),
  }),
  time: yup.string().when('isAllDay', {
    is: false,
    then: (schema) => schema.required('Start time is required'),
  }),
  endTime: yup.string().when('isAllDay', {
    is: false,
    then: (schema) => schema.required('End time is required'),
  }),
  frequency: yup.string().required('Frequency is required'),
  reminder: yup.array().of(yup.string()),
  emailParticipant: yup.array().of(yup.string()),
  label: yup.array().of(yup.string()),
  enableBeamAI: yup.boolean(),
  meta: yup.object().shape({
    recurrenceRule: yup.object().shape({
      type: yup.string(),
      interval: yup.number(),
      weeklyDays: yup.array().of(yup.number()),
      monthlyType: yup.string(),
      end: yup.object().shape({
        type: yup.string(),
        date: yup.string(),
        occurrences: yup.number(),
      }),
    }),
  }),
});

export interface CreateMeetingFormData {
  participant: string[];
  title: string;
  startDate: string;
  isAllDay?: boolean;
  endDate?: string;
  frequency: string;
  emailParticipant: string[];
  endTime: string;
  time: string;
  reminder: string[];
  label: string[];
  enableBeamAI?: boolean;
  meta?: {
    recurrenceRule?: {
      type: 'daily' | 'weekly' | 'monthly' | 'yearly';
      interval: number;
      weeklyDays: number[];
      monthlyType: 'dayOfMonth' | 'dayOfWeek';
      end: {
        type: 'never' | 'on' | 'after';
        date: string;
        occurrences: number;
      };
    };
  };
}

export const useCreateMeetingHook = () => {
  const [searchParams] = useSearchParams();
  const participant = searchParams.get('participant');
  const labelTag = searchParams.get('tag');
  const {
    usersStore: {users},
  } = useStoreContext();
  const allParticipant = participant ? participant?.split(',') : [];

  const {
    handleSubmit,
    errors,
    setValue,
    control,
    watch,
  } = useForm<CreateMeetingFormData>({
    resolver: yupResolver(createMeetingValidator) as Resolver<
      CreateMeetingFormData,
      object
    >,
    mode: 'all',
    defaultValues: {
      participant: allParticipant,
      title: '',
      startDate: '',
      isAllDay: false,
      frequency: 'once',
      time: '',
      endTime: '',
      reminder: ['24'],
      emailParticipant: [],
      label: labelTag ? [labelTag] : [],
      enableBeamAI: true,
    },
  });

  const {createEvent, isSigned, listCalendarEvents} = useGCalenderHook();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getGoogleCalendarTimeZone = async () => {
    const response = await listCalendarEvents(
      10,
      dayjs().format(),
      dayjs().format(),
      'primary',
    );

    if (response) {
      const timezone = response.result.timeZone || '';
      return timezone;
    }
    return '';
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [values, setValues] = useState<CreateMeetingFormData>({
    participant: allParticipant,
    title: '',
    startDate: '',
    isAllDay: false,
    frequency: 'once',
    emailParticipant: [],
    endTime: '',
    time: '',
    reminder: ['24'],
    label: labelTag ? [labelTag] : [],
    enableBeamAI: true,
  });

  const handleFormValueChange = useCallback(
    async (value: any, name: string) => {
      setValue(name, value);
      setValues((prev) => ({...prev, [name]: value}));
    },
    [setValue],
  );

  const onSubmit = useCallback(async () => {
    if (!isSigned) {
      alert('Please sign in to Google Calendar first');
      return;
    }

    if (isSubmitting) {
      return; // Prevent multiple submissions
    }

    setIsSubmitting(true);
    setLoading(true);

    try {
      let startDateTime, endDateTime;

      if (values.isAllDay) {
        startDateTime = dayjs(values.startDate).startOf('day');
        endDateTime = dayjs(values.endDate).endOf('day');
      } else {
        const {
          targetHour: startHour,
          targetMinute: startMinute,
          targetPeriod: startPeriod,
        } = splitTimeFormat(values.time);

        const {
          targetHour: endHour,
          targetMinute: endMinute,
          targetPeriod: endPeriod,
        } = splitTimeFormat(values.endTime);

        startDateTime = dayjs(values.startDate)
          .set('hour', startHour)
          .set('minute', startMinute);

        endDateTime = dayjs(values.startDate)
          .set('hour', endHour)
          .set('minute', endMinute);

        const start24Hour =
          startPeriod.toLowerCase() === 'pm' && startHour <= 12
            ? startHour + 12
            : startPeriod.toLowerCase() === 'am' && startHour === 12
            ? 0
            : startHour;

        const end24Hour =
          endPeriod.toLowerCase() === 'pm' && endHour <= 12
            ? endHour + 12
            : endPeriod.toLowerCase() === 'am' && endHour === 12
            ? 0
            : endHour;

        startDateTime = dayjs(values.startDate)
          .hour(start24Hour)
          .minute(startMinute);

        endDateTime = dayjs(values.startDate).hour(end24Hour).minute(endMinute);
      }

      const eventObject: any = {
        start: values.isAllDay
          ? {date: startDateTime.format('YYYY-MM-DD')}
          : {
              dateTime: startDateTime.format(),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        end: values.isAllDay
          ? {date: endDateTime.format('YYYY-MM-DD')}
          : {
              dateTime: endDateTime.format(),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        summary: values.title,
        description: '',
        reminders: {
          useDefault: false,
          overrides: values.reminder.map((reminder) => ({
            method: 'email',
            minutes: Number(reminder) * 60,
          })),
        },
        attendees: [
          ...values.emailParticipant.map((participant) => ({
            email: participant,
            organizer: false,
            self: false,
            responseStatus: 'needsAction',
          })),
          ...values.participant.map((participant) => ({
            email:
              users.find((user) => user.id === participant)?.email ||
              participant,
            organizer: false,
            self: false,
            responseStatus: 'needsAction',
          })),
        ],
      };

      if (values.frequency !== 'once') {
        const startDay = dayjs(values.startDate);
        const dayOfWeek = startDay.day();
        const weekOfMonth = Math.ceil(startDay.date() / 7);
        const dayNames = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

        let recurrenceRule;

        switch (values.frequency) {
          case 'daily':
            recurrenceRule = `FREQ=DAILY;INTERVAL=1`;
            break;
          case 'weekly':
            recurrenceRule = `FREQ=WEEKLY;INTERVAL=1;BYDAY=${dayNames[dayOfWeek]}`;
            break;
          case 'bi-weekly':
            recurrenceRule = `FREQ=WEEKLY;INTERVAL=2;BYDAY=${dayNames[dayOfWeek]}`;
            break;
          case 'monthly':
            recurrenceRule = `FREQ=MONTHLY;INTERVAL=1;BYDAY=${weekOfMonth}${dayNames[dayOfWeek]}`;

            break;
        }

        eventObject.recurrence = [`RRULE:${recurrenceRule}`];
      }
      // Add recurrence rule if it exists
      if (values.meta?.recurrenceRule && values.frequency !== 'once') {
        const rule = values.meta.recurrenceRule;
        let recurrenceRule = `FREQ=${rule.type.toUpperCase()};INTERVAL=${
          rule.interval
        }`;

        if (rule.type === 'weekly' && rule.weeklyDays.length > 0) {
          const days = rule.weeklyDays
            .map((day) => {
              const dayNames = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
              return dayNames[day];
            })
            .join(',');
          recurrenceRule += `;BYDAY=${days}`;
        }

        if (rule.end.type === 'on') {
          recurrenceRule += `;UNTIL=${dayjs(rule.end.date, 'DD-MM-YY').format(
            'YYYYMMDD',
          )}`;
        } else if (rule.end.type === 'after') {
          recurrenceRule += `;COUNT=${rule.end.occurrences}`;
        }

        eventObject.recurrence = [`RRULE:${recurrenceRule}`];
      }

      const response = await createEvent(eventObject, 'primary', 'all');

      if (response?.result) {
        if (values.label && values.label.length > 0) {
          try {
            const _data = {
              ...values,
              hostAgendaPrivate: undefined,
              shareHostAgenda: true,
              startDate: values.isAllDay
                ? startDateTime.format('YYYY-MM-DD')
                : startDateTime.format(),
              endDate: values.isAllDay
                ? endDateTime.format('YYYY-MM-DD')
                : endDateTime.format(),
              calendarId: response?.result.id,
              shareParticipantAgenda: undefined,
              enableBeamAI: undefined,
              endTime: undefined,
              isAllDay: undefined,
            };

            const request = new MeetingRequestImpl();
            const controller = new MeetingController(request);

            await controller.postMeeting(_data);
          } catch (beamError) {}
        }

        navigate('/meetings/');
        activateNotification({
          title: 'Success',
          kind: 'success',
          content: 'Meeting scheduled.',
        });
      }
    } catch (error) {
      activateNotification({
        title: 'Success',
        kind: 'error',
        content: 'Failed to schedule meeting. Please try again.',
      });
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  }, [createEvent, isSigned, isSubmitting, navigate, users, values]);

  const isDuplicate = searchParams.get('action') === 'duplicate';
  return {
    handleFormValueChange,
    getGoogleCalendarTimeZone,
    isSubmitting,
    control,
    loading,
    onSubmit,
    values,
    isDuplicate,
    isTeamMeetings: true,
    handleSubmit,
    errors,
    watch,
  };
};
