export const FlashIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.51334 9.54006H7.81834V12.5776C7.81834 13.0276 8.37334 13.2376 8.67334 12.9001L11.8683 9.27006C12.1458 8.95506 11.9208 8.46006 11.5008 8.46006H10.1958V5.42256C10.1958 4.97256 9.64084 4.76256 9.34084 5.10006L6.14584 8.73006C5.86834 9.04506 6.09334 9.54006 6.51334 9.54006Z"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.97656 16.5C13.1187 16.5 16.4766 13.1421 16.4766 9C16.4766 4.85786 13.1187 1.5 8.97656 1.5C4.83443 1.5 1.47656 4.85786 1.47656 9C1.47656 13.1421 4.83443 16.5 8.97656 16.5Z"
        stroke="#BFBFD4"
        strokeWidth="1.2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Flash2Icon = () => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50014 1.74864C5.50014 0.86929 4.31471 0.589448 3.92145 1.37597L0.794131 7.63061C0.517089 8.18469 0.920001 8.83662 1.53949 8.83662H4.50014V12.2579C4.50014 13.1373 5.68557 13.4171 6.07883 12.6306L9.20615 6.37597C9.48319 5.82188 9.08028 5.16995 8.4608 5.16995H5.50014V1.74864ZM1.80916 7.83662L4.50014 2.45465V5.33662C4.50014 5.79686 4.87324 6.16995 5.33347 6.16995H8.19112L5.50014 11.5519V8.66996C5.50014 8.20972 5.12705 7.83662 4.66681 7.83662H1.80916Z"
        fill="#363538"
      />
    </svg>
  );
};
