import {forwardRef, Ref, RefObject, useRef} from 'react';
import styled from 'styled-components';
import {useCombinedRefs} from '../../../hooks';
import {MagnifyingGlassIcon} from '../../atoms/icons/magnifying-glass';
import {InputAction} from '../../atoms/input-action';
import {TextFieldProps} from '../field/textfield/textfield';
import {Input} from '../../atoms/input';
import {CancelIcon} from '../../atoms/icons';

const Wrapper = styled.div`
  position: relative;

  ${Input} {
    padding-right: 36px;
  }
`;

const ActionWrapper = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
`;

export type SearchBoxProps = TextFieldProps;

export const SearchBox = forwardRef(
  (
    {label, placeholder, state, ...rest}: SearchBoxProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs<HTMLInputElement>(
      ref as RefObject<HTMLInputElement>,
      inputRef,
    );

    return (
      <Wrapper>
        <Input
          aria-label="input"
          placeholder={placeholder ?? label}
          ref={combinedRef}
          state={state}
          {...rest}
        />
        <ActionWrapper>
          <InputAction
            children={<MagnifyingGlassIcon />}
            kind="borderDark"
            onClick={() => {}}
          />
        </ActionWrapper>
      </Wrapper>
    );
  },
);

export const SearchBox2 = forwardRef(
  (
    {
      label,
      placeholder,
      state,

      handleCancel,
      inputStyle,
      ...rest
    }: SearchBoxProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs<HTMLInputElement>(
      ref as RefObject<HTMLInputElement>,
      inputRef,
    );

    return (
      <Wrapper>
        <Input
          aria-label="input"
          placeholder={placeholder ?? label}
          ref={combinedRef}
          state={state}
          {...rest}
          style={inputStyle || {height: '40px'}}
        />
        <ActionWrapper>
          <InputAction
            children={!rest.value ? <MagnifyingGlassIcon /> : <CancelIcon />}
            kind="borderDark"
            onClick={() => handleCancel && handleCancel()}
          />
        </ActionWrapper>
      </Wrapper>
    );
  },
);

export const SearchBoxForDropdown = forwardRef(
  (
    {
      label,
      placeholder,
      state,
      value,

      showCancel,
      handleCancel,
      ...rest
    }: SearchBoxProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs<HTMLInputElement>(
      ref as RefObject<HTMLInputElement>,
      inputRef,
    );

    return (
      <Wrapper>
        <Input
          aria-label="input"
          placeholder={placeholder ?? label}
          ref={combinedRef}
          state={state}
          {...rest}
          value={value}
        />
        <ActionWrapper>
          <InputAction
            children={
              <>
                {!showCancel ? (
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.08594 1L5.08594 5L9.08594 1"
                      stroke="#1E1E2F"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <CancelIcon />
                )}
              </>
            }
            kind="borderDark"
            onClick={handleCancel ? handleCancel : () => null}
          />
        </ActionWrapper>
      </Wrapper>
    );
  },
);
