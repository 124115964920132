import {
  TableContainer,
  SecondRowWrapper,
  HeadlineWrapperContainer,
  SubmittedHeadlineWrapper,
  ItemWrapperContainer,
  HeadlineItemWrapper,
  ItemLoaderWrapper,
  PaginationLi,
  SummaryWrapper,
  MentionIndicator,
  PaginationWrapper,
  Status,
  LinkButtonWrapper,
  PaginationUl,
  PaginationButton,
} from './styles';
import {UserListCard} from '@ui/molecules/user/list-card';
import {parseName} from '../my_checkins/my_checkins';
import {ItemLoaderLine} from '@ui/organisms/item-loader-line';
import {useNavigate} from 'react-router';
import {EmptyPlaceholder} from '@ui/atoms/empty-placeholder';
import {useMemo, useState} from 'react';
import {Button} from '@ui/atoms/button';
import {cloudinary} from '@assets/images/cloudinary';
import {ArrowHeadRightIcon} from '@ui/atoms/icons';
import {useCheckinsCalendar} from '@hooks/checkins-calendar';
import {ArrowHeadLeftIcon} from '@ui/atoms/icons';
import {useStoreContext} from '@store/store-context';
import {useCheckinsTeamsMention} from './checkins-mention-hook';
import {Body2, CTA} from '@ui/atoms/typography';
import {observer} from 'mobx-react';
import {
  checkinReminderTracker,
  updateCheckinReminder,
} from '@utils/firebase-request';
import {useFirebaseFetch} from '@hooks/query-hook';
import {useCountdown} from '@utils/countdown';
import {CompanyCheckinsRequestImpl} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.request';
import {CompanyCheckinsController} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.controller';

interface SummaryWeeksProps {
  activeWeeks?: any[];
}
export interface IAppProps extends SummaryWeeksProps {
  loader: boolean;
  loading: boolean;
}

export const CheckinsTeamSummary = observer(
  ({loader, loading, activeWeeks}: IAppProps) => {
    const {
      checkinsStore: {
        configuration,
        usersCheckinsPaginator,
        handleUsersCheckinsPaginatorNextbtn,
        handleUsersCheckinsPaginatorPageClick,
        handleUsersCheckinsPaginatorPrevbtn,
        usersCheckinsPages,
      },
      authStore: {auth},
    } = useStoreContext();

    const {getPendingReviews} = useCheckinsTeamsMention();

    const showReminderButton = configuration?.allowLateSubmission;

    const reminderId = `checkin/reminder`;

    const {data: managerReminder} = useFirebaseFetch(reminderId);

    const [isSendingReminder, setIsSendingReminder] = useState(false);

    const reminder = useMemo(() => {
      if (managerReminder) {
        const managers: {
          managerId: string;
          lastSent: number;
          id: string;
        }[] = Object.keys(managerReminder).map((key) => ({
          id: key,
          ...managerReminder[key],
        }));
        return managers.find((manager) => manager.managerId === auth.user.id);
      }
    }, [auth.user.id, managerReminder]);

    const {minutes, seconds} = useCountdown(reminder?.lastSent || 0);

    const handleResendReminders = async () => {
      setIsSendingReminder(true);
      const checkinsRequest = new CompanyCheckinsRequestImpl();

      const checkinsController = new CompanyCheckinsController(checkinsRequest);

      await checkinsController.checkinReminder();

      if (reminder) {
        updateCheckinReminder(auth.user.id, reminder.id);
      } else {
        checkinReminderTracker(auth.user.id);
      }

      setIsSendingReminder(false);
    };

    const disableReminder = minutes > 0 || seconds > 0;

    const renderPageNumbers = usersCheckinsPages().map((number: any) => {
      if (
        number < usersCheckinsPaginator.maxPageNumberLimit + 1 &&
        number > usersCheckinsPaginator.minPageNumberLimit
      ) {
        return (
          <PaginationLi
            key={number}
            id={number}
            onClick={handleUsersCheckinsPaginatorPageClick}
            className={
              usersCheckinsPaginator.page === number ? 'active' : undefined
            }>
            {number}
          </PaginationLi>
        );
      } else {
        return null;
      }
    });

    return (
      <div className="bg-white">
        <SummaryWrapper>
          <div className="table-summary">
            <div className="flex gap-1 items-center ">
              <Body2 weight="bold">Summary </Body2>
              {getPendingReviews?.length > 0 && (
                <MentionIndicator>{getPendingReviews?.length}</MentionIndicator>
              )}
            </div>

            {showReminderButton && (
              <Button
                onClick={handleResendReminders}
                disabled={disableReminder || isSendingReminder}
                isLoading={isSendingReminder}
                style={{padding: '8px 16px'}}>
                {disableReminder
                  ? `Retry reminders in ${minutes}:${seconds}`
                  : 'Resend reminders'}
              </Button>
            )}
          </div>

          <TableContainer>
            <TeamCheckins
              loader={loader}
              loading={loading}
              activeWeeks={activeWeeks}
            />

            {(loader || loading) && (
              <ItemWrapperContainer>
                <ItemLoaderWrapper>
                  <ItemLoaderLine />
                </ItemLoaderWrapper>
              </ItemWrapperContainer>
            )}
          </TableContainer>

          {usersCheckinsPaginator.totalResults > 10 ? (
            <PaginationWrapper>
              <PaginationUl>
                {usersCheckinsPaginator.minPageNumberLimit > 0 && (
                  <PaginationLi>
                    <PaginationButton
                      onClick={handleUsersCheckinsPaginatorPrevbtn}
                      disabled={
                        usersCheckinsPaginator.page === 1 ? true : false
                      }>
                      <ArrowHeadLeftIcon />
                    </PaginationButton>
                  </PaginationLi>
                )}

                {renderPageNumbers}

                {usersCheckinsPages().length !==
                  usersCheckinsPaginator.maxPageNumberLimit && (
                  <PaginationLi>
                    <PaginationButton
                      onClick={handleUsersCheckinsPaginatorNextbtn}
                      disabled={
                        usersCheckinsPaginator.page ===
                        usersCheckinsPaginator.totalPages
                          ? true
                          : false
                      }>
                      <ArrowHeadRightIcon />
                    </PaginationButton>
                  </PaginationLi>
                )}
              </PaginationUl>
            </PaginationWrapper>
          ) : null}
        </SummaryWrapper>
      </div>
    );
  },
);

const TeamCheckins = observer(({loader, loading, activeWeeks}: IAppProps) => {
  const {
    checkinsStore: {usersCheckins},
  } = useStoreContext();

  const EmptyGroupQuestions = useMemo(
    () => ({
      image: {
        alt: 'alt text',
        shadow: '-10px 44px 28px 0 rgba(19, 17, 17, 0.12)',
        src: cloudinary.emptyGoals,
        style: {height: '50%', width: '50%'},
      },
      subtitle: '',
      title: `You have no  reports for this time period`,
    }),
    [],
  );
  const checkinsData =
    usersCheckins?.data?.length > 0 ? usersCheckins?.data : [];

  return (
    <>
      <div style={{overflowX: 'scroll'}}>
        <SecondRowWrapper>
          <HeadlineWrapperContainer className="header">
            <SubmittedHeadlineWrapper>
              <Body2 kind="textBody">Name</Body2>
              <Body2 kind="textBody">Manager</Body2>
              <Body2 kind="textBody">Status</Body2>
              <Body2 kind="textBody">Actions</Body2>
            </SubmittedHeadlineWrapper>
          </HeadlineWrapperContainer>
          {!loader &&
            !loading &&
            checkinsData.map((report: any) => (
              <CheckinRow
                activeWeeks={activeWeeks}
                report={report}
                key={report?.id}
              />
            ))}
          {!loader && !loading && usersCheckins?.data?.length === 0 && (
            <div
              style={{
                textAlign: 'center',
              }}>
              <EmptyPlaceholder {...EmptyGroupQuestions} />
            </div>
          )}
        </SecondRowWrapper>
      </div>
    </>
  );
});

interface RowProps extends SummaryWeeksProps {
  report: {
    id: string;
    weekOfYear: number;
    user: any;
    isReviewed?: boolean;
    isSubmitted?: boolean;
  };
}
const CheckinRow = ({report}: RowProps) => {
  const {
    usersStore: {users},
    checkinsStore: {setCheckinsDate},
  } = useStoreContext();
  const navigate = useNavigate();

  const calendarData = useCheckinsCalendar();

  const handleView = () => {
    const getSelectedWeek = calendarData.currentMonthWeeks.find(
      (week) => week.weekOfYear === report.weekOfYear,
    );

    if (getSelectedWeek) {
      setCheckinsDate({
        weekOfYear: report.weekOfYear,
        starts: getSelectedWeek.dateStart,
        ends: getSelectedWeek.dateEnd,
        month: getSelectedWeek.month,
      });
    }
  };

  return (
    <>
      <ItemWrapperContainer>
        <HeadlineItemWrapper>
          <div>
            <UserListCard
              tooltip={true}
              userId={report?.user?.id}
              type="secondary"
              reviewer={parseName(
                users?.filter((user) => user?.id === report?.user?.reviewer),
              )}
              avatar={report?.user?.avatar?.url}
              name={parseName(report?.user)}
              TextComponent={CTA}
              textKind="textDark"
            />
          </div>
          <div>
            {users
              ?.filter((user) => user?.id === report?.user?.reviewer)
              .map((manager) => (
                <UserListCard
                  tooltip={true}
                  userId={manager?.id}
                  type="secondary"
                  reviewer={parseName(
                    users?.filter((user) => user?.id === manager?.reviewer),
                  )}
                  avatar={manager?.avatar?.url}
                  name={parseName(manager)}
                  TextComponent={CTA}
                  textKind="textDark"
                />
              ))}
          </div>

          <div>
            <Status>
              <Body2 weight="semibold" size="12px" kind="textBody">
                {!report.isSubmitted
                  ? 'Not submitted'
                  : report?.isReviewed
                  ? 'Reviewed'
                  : 'Pending review'}
              </Body2>
            </Status>
          </div>

          <LinkButtonWrapper>
            <Button
              disabled={!report.isSubmitted}
              onClick={() => {
                handleView();
                navigate(
                  {pathname: `/view-checkins/${report?.id}/direct`},
                  {state: {user: report?.user?.id}},
                );
              }}
              kind={
                !report?.isReviewed && report?.isSubmitted
                  ? 'primary'
                  : 'secondary'
              }
              style={{
                padding: `8px 16px`,
              }}>
              {!report?.isReviewed && report?.isSubmitted ? 'Review' : 'View'}
            </Button>
          </LinkButtonWrapper>
        </HeadlineItemWrapper>
      </ItemWrapperContainer>
    </>
  );
};
